import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Store } from 'components/common/types/Store.types';
import { FormatTypeEnum } from 'modules/I18N';
import { isCreatedAfterSummaryPanelChanges } from 'components/pages/DealWithLines/AdsDealLines/transformDealDetails';
import { handleValueFormat } from 'components/pages/DealWithLines/common/RightSidebar/utils';
import { DealSummaryCard } from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCard/DealSummaryCard';
import DealSummaryCardSection from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCardSection';
import { DealSummaryCardSectionLineType } from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCardSection/DealSummaryCardSection.types';
import { DeliveryCardProps } from './DeliveryCardProps.types';

export const DeliveryCard: React.FC<DeliveryCardProps> = ({
  currentRemainingImpressions,
  deliveredImpressions,
  isVisible,
}) => {
  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);

  return (
    <DealSummaryCard header="DELIVERY" isVisible={isVisible}>
      <DealSummaryCardSection
        lines={[
          {
            label: 'Remaining impressions',
            value: isCreatedAfterSummaryPanelChanges({
              dealType,
              lines,
            })
              ? handleValueFormat({
                  valueType: FormatTypeEnum.ROUNDED_NUMBER,
                  value: currentRemainingImpressions,
                  currencyCode,
                  localeCode,
                })
              : '-',
            lineType: DealSummaryCardSectionLineType.HEADER,
          },
          {
            label: 'Traded impressions',
            value: handleValueFormat({
              valueType: FormatTypeEnum.ROUNDED_NUMBER,
              value: deliveredImpressions,
              currencyCode,
              localeCode,
            }),
            lineType: DealSummaryCardSectionLineType.SUBHEADER_SECONDARY,
          },
        ]}
      />
    </DealSummaryCard>
  );
};
