import { LevelType } from 'consts/thoughtspot';
import { ReportType } from '../AllocationReport.types';

export interface AllocationReportsProps {
  handleAllocationModalClose: () => void;
  isAllocationReportOpen: boolean;
  reportType: ReportType;
  defaultLevelType?: LevelType;
}

export enum ActiveAllocationReportSolution {
  THOUGHTSPOT = 'THOUGHTSPOT',
  NATIVE = 'NATIVE',
}
