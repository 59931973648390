import { CodeNameModel } from 'components/common/types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getContentDealIdLookupData } from 'modules/api/lookups';
import Session from 'modules/Session';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { onFilterChange } from 'store/pages/contentManagement/creativeSetDetails/reducer';

type FilterContentDealIdProps = {
  placeholder?: string;
};

const FilterContentDealId: React.FC<FilterContentDealIdProps> = ({ placeholder = 'Select deal ID' }) => {
  const cancelFunctions = useCancelRequest();
  const dispatch = useDispatch();
  const dealId = useSelector((state: RootState) => state.pages.contentManagement.creativeSetDetails.filters.dealId);

  const onLoadDealIdLookupData = async (_: string, query: string): Promise<void | []> => {
    if (query.length < 2) return [];

    try {
      return await getContentDealIdLookupData(cancelFunctions, Session.getEnvironmentId(), query);
    } catch (error) {
      return [];
    }
  };

  return (
    <AsyncSelect
      dataTestId="filter-area-deal-id"
      name="dealId"
      loadData={onLoadDealIdLookupData}
      selectedValue={dealId}
      placeholder={placeholder}
      onSelect={(_: string, value: CodeNameModel) => {
        dispatch(onFilterChange({ dealId: value }));
      }}
    />
  );
};

export default FilterContentDealId;
