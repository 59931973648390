import { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import ReactAsyncSelect from 'react-select/async';
import { ShadowStyle } from 'consts/shadow';
import { getInputBorderStyle } from 'lib/BaseInput';
import { InputShape } from 'components/common/types/Input.types';
import styles from './AsyncSelect.pcss';

const cx = classNames.bind(styles);

export const ASYNC_SELECT_THEME = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

class AsyncSelect extends Component {
  timeout = null;

  loadOption = (inputValue) => {
    const { name, loadData, debounceTime } = this.props;
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    return new Promise((resolve) => {
      this.timeOut = setTimeout(async () => {
        const data = await loadData(name, inputValue);
        resolve(data);
      }, debounceTime * 1000);
    });
  };

  handleChange = (newValue) => {
    const { onSelect, name } = this.props;
    onSelect(name, newValue);
  };

  getOptionLabel = (option) => {
    const { textKey } = this.props;
    return option[textKey];
  };

  getOptionValue = (option) => {
    const { valueKey } = this.props;
    return option[valueKey];
  };

  getNoOptionsMessage = () => {
    return 'Type keyword to search';
  };

  getLoadingMessage = (options) => {
    return `Looking for '${options.inputValue}'`;
  };

  getStyles = () => {
    const { isMultiValueVisible, defaultOptions, theme } = this.props;
    const isAutoComplete = defaultOptions && defaultOptions.length > 0;

    const whiteColor = 'var(--neutral-100)';
    const themeStyles = {
      control: {
        [ASYNC_SELECT_THEME.LIGHT]: {
          border: '0',
          backgroundColor: 'transparent',
        },
        [ASYNC_SELECT_THEME.DARK]: {
          border: '1px solid var(--neutral-100-opacity-5)',
          borderLeftColor: whiteColor,
          backgroundColor: 'var(--neutral-100-opacity-10)',
          '&:hover': {
            borderColor: 'var(--neutral-100-opacity-5)',
            borderLeftColor: whiteColor,
            backgroundColor: 'var(--neutral-100-opacity-5)',
          },
        },
      },
      input: {
        [ASYNC_SELECT_THEME.LIGHT]: {
          color: 'var(--neutral-950-opacity-80)',
        },
        [ASYNC_SELECT_THEME.DARK]: {
          color: whiteColor,
        },
      },
    };

    const groupHeadingStyles = {
      backgroundColor: 'var(--neutral-50)',
      color: 'var(--neutral-950-opacity-60)',
      fontWeight: 700,
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
      fontSize: '9px',
      lineHeight: '.75rem',
      padding: '0.5em 1em 0.2em 1.4em',
    };

    return {
      control: (base) => ({
        ...base,
        ...themeStyles.control[theme],
      }),
      input: (base) => ({
        ...base,
        ...themeStyles.input[theme],
      }),
      multiValue: (base) => ({
        ...base,
        display: isMultiValueVisible ? base.display : 'none',
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: isAutoComplete ? base.display : 'none',
      }),
      dropdownIndicator: (base) => ({
        ...base,
        display: isAutoComplete ? base.display : 'none',
      }),
      groupHeading: (base) => ({
        ...base,
        ...groupHeadingStyles,
      }),
    };
  };

  render() {
    const {
      multiple,
      isClearable,
      placeholder,
      selectedValue,
      isDisabled,
      hideSelectedOptions,
      defaultOptions,
      isMultiValueVisible,
      menuPosition,
      menuPlacement,
      onMenuScrollToBottom,
      closeMenuOnSelect,
      name,
      customComponents,
      dataTestId,
      shadow,
      shape,
    } = this.props;

    return (
      <div
        className={getInputBorderStyle({
          isDisabled,
          shape,
        })}
        data-test-id={dataTestId}
      >
        <ReactAsyncSelect
          isMulti={multiple}
          isClearable={isClearable}
          defaultOptions={defaultOptions}
          loadOptions={this.loadOption}
          onChange={this.handleChange}
          getOptionLabel={this.getOptionLabel}
          getOptionValue={this.getOptionValue}
          placeholder={placeholder}
          onMenuScrollToBottom={onMenuScrollToBottom}
          value={selectedValue}
          className={cx('async-select', 'w-full', { 'async-select-shadow': shadow === ShadowStyle.SMALL })}
          id={`react-select-${name}`}
          classNamePrefix="react-select"
          isDisabled={isDisabled}
          noOptionsMessage={this.getNoOptionsMessage}
          loadingMessage={this.getLoadingMessage}
          hideSelectedOptions={hideSelectedOptions}
          styles={this.getStyles()}
          backspaceRemovesValue={isMultiValueVisible}
          menuPosition={menuPosition}
          menuPlacement={menuPlacement}
          closeMenuOnSelect={closeMenuOnSelect}
          components={customComponents}
        />
      </div>
    );
  }
}

AsyncSelect.propTypes = {
  dataTestId: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  name: PropTypes.string,
  loadData: PropTypes.func,
  onSelect: PropTypes.func,
  textKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  valueKey: PropTypes.string,
  placeholder: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  isDisabled: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isMultiValueVisible: PropTypes.bool,
  shadow: PropTypes.oneOf(Object.values(ShadowStyle)),
  menuPosition: PropTypes.oneOf(['absolute', 'fixed']),
  onMenuScrollToBottom: PropTypes.func,
  debounceTime: PropTypes.number,
  closeMenuOnSelect: PropTypes.bool,
  customComponents: PropTypes.shape({
    SingleValue: PropTypes.func,
    Option: PropTypes.func,
  }),
  theme: PropTypes.string,
  menuPlacement: PropTypes.string,
  shape: PropTypes.oneOf(Object.values(InputShape)),
};

AsyncSelect.defaultProps = {
  dataTestId: '',
  multiple: false,
  isClearable: true,
  name: '',
  loadData: () => undefined,
  onSelect: () => undefined,
  textKey: 'name',
  valueKey: 'code',
  placeholder: 'Select...',
  selectedValue: null,
  isDisabled: false,
  hideSelectedOptions: true,
  defaultOptions: [],
  isMultiValueVisible: true,
  shadow: ShadowStyle.NONE,
  menuPosition: 'absolute',
  debounceTime: 1,
  closeMenuOnSelect: true,
  customComponents: {},
  onMenuScrollToBottom: () => {},
  theme: ASYNC_SELECT_THEME.LIGHT,
  menuPlacement: 'bottom',
  shape: InputShape.ROUNDED,
};

export default AsyncSelect;
