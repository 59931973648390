import PropTypes from 'prop-types';

export const FrameBackground = {
  DARK: 'hover:bg-neutral-100-opacity-5',
  LIGHT: 'hover:bg-neutral-950-opacity-10',
};

const FrameDetailsRenderer = ({ theme, data: { visualUnitIds } }) => {
  return (
    <button
      type="button"
      data-test-id="preview-frames"
      onClick={() => null}
      className={`p-2.5 text-center rounded-md ${theme}`}
    >
      <span>{visualUnitIds?.length}</span>
    </button>
  );
};

FrameDetailsRenderer.propTypes = {
  theme: PropTypes.string,
  data: PropTypes.shape({
    visualUnitIds: PropTypes.arrayOf(PropTypes.string),
  }),
};

FrameDetailsRenderer.defaultProps = {
  data: {},
  theme: FrameBackground.LIGHT,
};
export default FrameDetailsRenderer;
