import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { addWeeks, isAfter, endOfDay, getUnixTime, startOfDay } from 'date-fns';
import { HostEvent, RuntimeFilterOp } from '@thoughtspot/visual-embed-sdk';
import { TsEmbed } from '@thoughtspot/visual-embed-sdk/lib/src/embed/ts-embed';
import { useEmbedRef } from '@thoughtspot/visual-embed-sdk/lib/src/react';
import { zonedTimeToUtc } from 'date-fns-tz';

import { thoughtspot } from 'config';
import { LEVEL_TYPE_VISUAL_IDS, LevelType } from 'consts/thoughtspot';
import { DateFromTo } from 'components/common/types/DateRange.type';
import LiveboardVisuals from 'components/patterns/LiveboardVisuals';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { Store } from 'components/common/types/Store.types';
import { ToggleAllocationReportView } from 'components/common/AllocationReport/AllocationReportModal/ToggleAllocationReportView';
import { getIsAllocationReportFeatureEnabled } from 'components/common/AllocationReport/selectors';

import { initialiseThoughtspot } from 'utils/thoughtspotInit';
import Overlay from 'lib/Overlay';
import { RootState } from 'store';
import { ThoughtSpotAllocationReportProps, DealLineType, ReportType } from './ThoughtSpotAllocationReport.types';
import { getDealLinesIds } from './utils';
import Filters from './Filters';

const ThoughtSpotAllocationReport: React.FC<ThoughtSpotAllocationReportProps> = ({
  openModal,
  closeModal,
  reportType = ReportType.PROPOSAL,
  defaultLevelType = LevelType.LINE,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const embedRef = useEmbedRef() as React.MutableRefObject<TsEmbed>;
  const cancelFunctions = useCancelRequest();

  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const existingDealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const internalId = useSelector((state: Store) => state.dealManagement.commonDeal.internalId);
  const startDate = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.startDate);
  const endDate = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.endDate);
  const temporaryDealId = useSelector((state: Store) => state.dealManagement.temporaryDealId);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const localeCode = useSelector((state: RootState) => state.publisher.configuration.localeCode);
  const isAllocationReportFeatureEnabled = useSelector(getIsAllocationReportFeatureEnabled);

  const [listOfVisuals, setListOfVisuals] = useState<{ id: string; name: string }[]>([]);
  const [selectedVisualId, setSelectedVisualId] = useState('');
  const [listOfLineIds, setListOfLineIds] = useState<DealLineType[]>([]);
  const [selectedLineId, setSelectedLineId] = useState('');
  const [selectedLevelType, setSelectedLevelType] = useState(defaultLevelType);
  const [dateFilter, setDateFilter] = useState<DateFromTo>({
    from: new Date(startDate ?? ''),
    to: addWeeks(new Date(startDate ?? ''), 2),
  });

  const isDealSelected = selectedLevelType === LevelType.DEAL;
  const isProposal = reportType === ReportType.PROPOSAL;

  const dealId = useMemo(() => {
    if (reportType === ReportType.FINAL) {
      return existingDealId;
    }

    return temporaryDealId || existingDealId;
  }, [reportType, temporaryDealId, existingDealId]);

  const getDealLinesDetails = (dealLines: DealLineType[]): void => {
    const dealLinesList = getDealLinesIds(dealLines);

    setListOfLineIds(dealLinesList);
    setSelectedLineId(dealLinesList[0].lineId);
  };

  const getSavedDealLinesDetails = (): void => {
    if (lines && dealId) {
      getDealLinesDetails(lines);
    }
  };

  const onChangeVisual = (visualId: string): void => {
    setSelectedVisualId(visualId);
    embedRef.current?.trigger(HostEvent.SetVisibleVizs, [visualId]);
  };

  initialiseThoughtspot(cancelFunctions, localeCode);

  useEffect(() => {
    if (!isNewDeal || !(temporaryDealId && reportType === ReportType.PROPOSAL)) {
      getSavedDealLinesDetails();
    }
  }, []);

  useEffect(() => {
    const visuals =
      selectedLevelType === LevelType.DEAL
        ? LEVEL_TYPE_VISUAL_IDS.DEAL[dealType]
        : LEVEL_TYPE_VISUAL_IDS.LINE[dealType];

    setListOfVisuals(visuals);
    setSelectedVisualId(visuals.length > 0 ? visuals[0].id : '');
  }, [selectedLevelType]);

  useEffect(() => {
    const endDateFilter = new Date(endDate ?? '');

    if (isAfter(dateFilter.to, endDateFilter)) {
      setDateFilter((prev) => ({
        from: prev.from,
        to: endDateFilter,
      }));
    }
  }, [dateFilter]);

  const lineIdDealIdCombinedFilter = [
    {
      columnName: 'Deal ID',
      operator: RuntimeFilterOp.EQ,
      values: [dealId],
    },
    {
      columnName: 'Line ID',
      operator: RuntimeFilterOp.EQ,
      values: [selectedLineId],
    },
  ];

  const filterByDealId = [
    {
      columnName: 'Deal ID',
      operator: RuntimeFilterOp.EQ,
      values: [dealId],
    },
  ];

  const dealSelectionFilter = isDealSelected ? filterByDealId : lineIdDealIdCombinedFilter;

  const filterOptions = [
    ...(isProposal ? filterByDealId : dealSelectionFilter),
    {
      columnName: 'Play Hour Local',
      operator: RuntimeFilterOp.BW_INC,
      values: dateFilter
        ? [
            getUnixTime(zonedTimeToUtc(startOfDay(dateFilter.from), 'UTC')),
            getUnixTime(zonedTimeToUtc(endOfDay(dateFilter.to), 'UTC')),
          ]
        : [],
    },
  ];

  return (
    <Overlay
      isOpen={openModal}
      onClose={closeModal}
      header={
        <header className="flex w-full justify-between items-center pr-6">
          <Filters
            listOfLineIds={isDealSelected ? [{ id: internalId, lineId: dealId }] : listOfLineIds}
            listOfVisuals={listOfVisuals}
            dealLineId={isDealSelected ? dealId : selectedLineId}
            visualId={selectedVisualId}
            onChangeDealLines={setSelectedLineId}
            onChangeVisuals={onChangeVisual}
            onChangeLevelType={setSelectedLevelType}
            isDisabled={isNewDeal || isProposal}
            startDate={new Date(startDate ?? '')}
            endDate={new Date(endDate ?? '')}
            dateFilter={dateFilter}
            onDateChange={setDateFilter}
            selectedLevelType={selectedLevelType}
          />
          {isAllocationReportFeatureEnabled && <ToggleAllocationReportView />}
        </header>
      }
    >
      <LiveboardVisuals
        filterByOptions={filterOptions}
        visualId={selectedVisualId}
        liveboardId={thoughtspot.allocationReportId}
      />
    </Overlay>
  );
};

export default ThoughtSpotAllocationReport;
