import { Asset } from 'components/common/types/Asset.types';

export enum ButtonColor {
  PRIMARY = 'bg-primary-600-opacity-10 text-primary-600 sub-header-base rounded-full w-full py-2 hover:bg-primary-600-opacity-20 disabled:bg-neutral-950-opacity-20 disabled:bg-opacity-5 disabled:text-neutral-950-opacity-30',
  SECONDARY = 'bg-transparent border border-solid border-neutral-300 border- text-neutral-950-opacity-60 sub-header-base rounded-full w-full py-2 hover:bg-primary-600-opacity-5 hover:bg-opacity-10 disabled:bg-neutral-300 disabled:bg-opacity-5 disabled:text-neutral-950-opacity-30',
}

export interface DealSummaryFrameListProps {
  actionButton?: (onClick: () => void) => React.ReactNode;
  assets: Asset[];
  buttonColor?: ButtonColor;
  isDisabled?: boolean;
}
