import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import Card from 'components/patterns/Card';
import infoSvg from 'assets/icons/info.svg';
import Tooltip, { TooltipDirection } from 'components/patterns/Tooltip';
import { BorderTheme, CardTheme } from 'components/patterns/Card/Card.types';

export const INFO_TYPE = {
  CARD: 'CARD',
  TOOLTIP: 'TOOLTIP',
};

const InfoMessage = ({ children, type, icon, direction, borderTheme, cardTheme }) => {
  if (type === INFO_TYPE.TOOLTIP) {
    return (
      <Tooltip tooltip={children} direction={direction}>
        {icon}
      </Tooltip>
    );
  }

  return (
    <Card borderTheme={borderTheme} cardTheme={cardTheme}>
      <div className="flex">
        <div className="pr-2">{icon}</div>
        <div className="body-base text-neutral-950-opacity-80">{children}</div>
      </div>
    </Card>
  );
};

InfoMessage.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.values(INFO_TYPE)),
  icon: PropTypes.node,
  direction: PropTypes.oneOf(Object.values(TooltipDirection)),
  borderTheme: PropTypes.oneOf(Object.values(BorderTheme)),
  cardTheme: PropTypes.oneOf(Object.values(CardTheme)),
};

InfoMessage.defaultProps = {
  children: null,
  type: INFO_TYPE.CARD,
  icon: <SVG src={infoSvg} className="fill-current text-primary-600" />,
  direction: TooltipDirection.TOP,
  borderTheme: BorderTheme.BASIC,
  cardTheme: CardTheme.LIGHT,
};

export default InfoMessage;
