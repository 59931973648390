import { Line } from 'components/common/types/Deal.types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDealData, changeIsCpmCampaignLevel } from 'store/dealManagement/reducer';
import { Store } from 'components/common/types/Store.types';
import { getLineLevelCpmSettings } from 'components/pages/DealWithLines/common/Main/Deal/selectors';

export const useCpmCampaignLevel = (): void => {
  const dispatch = useDispatch();
  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const { isLineLevelCpmVisible } = useSelector(getLineLevelCpmSettings);

  const handleAreAllLineCpmEqual = (dealLines: Line[]): boolean => {
    return dealLines.every((line) => dealLines[0].cpm === line.cpm);
  };

  useEffect(() => {
    const isLineCpmEqual = handleAreAllLineCpmEqual(lines);

    if (isLineLevelCpmVisible && !isLineCpmEqual) {
      dispatch(changeDealData({ cpm: 0 }));
      dispatch(changeIsCpmCampaignLevel(false));
    }

    return () => {
      dispatch(changeIsCpmCampaignLevel(true));
    };
  }, [lines]);
};
