import { createSlice } from '@reduxjs/toolkit';
import { Advertiser, BrandWithProductCategory, Dsp } from 'components/common/types/Deal.types';

export interface CreativeSetsFilters {
  creativeSetCode: string;
  dsp: Dsp | null;
  advertiser: Advertiser | null;
  brand: BrandWithProductCategory | null;
  visualUnitCode: string;
}

interface CreativeSets {
  filters: CreativeSetsFilters;
}

const initialState: CreativeSets = {
  filters: {
    creativeSetCode: '',
    dsp: null,
    advertiser: null,
    brand: null,
    visualUnitCode: '',
  },
};

const creativeSetsSlice = createSlice({
  name: 'contentManagementCreativeSets',
  initialState,
  reducers: {
    updateCreativeSetsFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    },
    resetCreativeSetsFilter: (state) => {
      return {
        ...state,
        filters: { ...initialState.filters },
      };
    },
    resetCreativeSetsState: () => {
      return { ...initialState };
    },
  },
});

export { CreativeSets };
export const { updateCreativeSetsFilter, resetCreativeSetsFilter, resetCreativeSetsState } = creativeSetsSlice.actions;
export default creativeSetsSlice.reducer;
