import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { changeDealInfo, changeProgrammaticDealInfo } from 'store/dealManagement/actions';
import { changeDealData } from 'store/dealManagement/reducer';
import * as lookups from 'modules/api/lookups';
import { isReadOnly } from 'utils/isReadOnly';

import { useCancelRequest } from 'customHooks/useCancelRequest';
import AsyncSelect, { ASYNC_SELECT_THEME } from 'components/patterns/AsyncSelect';
import Input from 'lib/Input';
import { DealType, DealStatus, DealPriorityLabel } from 'components/common/types/Deal.types';
import { codeNameDealPriorityOptions } from 'consts/dealPriority';
import { SOURCE_SYSTEM } from 'consts/sourceSystem';
import CreativeBidStream from 'components/common/Deal/CreativeBidStream';
import RequiredAsterisk from 'components/common/RequiredAsterisk';
import DspSync from 'components/common/Deal/DspSync';
import OpenMarketplace from 'components/common/Deal/OpenMarketplace';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { getIsReseller, getIsAdServer } from 'store/publisher/selectors';
import { ShadowStyle } from 'consts/shadow';
import { useDspSettings } from 'customHooks/useDspSettings';
import BaseSelect from 'lib/BaseSelect';
import { FilterDsp } from 'components/common/FilterArea/Filters';

// eslint-disable-next-line sonarjs/cognitive-complexity
const DealInfo = () => {
  const dispatch = useDispatch();

  const isReseller = useSelector(getIsReseller);
  const isNewDeal = useSelector((state) => state.dealManagement.isNewDeal);
  const isEditingDisabled = useSelector((state) => state.dealManagement.isEditingDisabled);
  const meta = useSelector((state) => state.dealManagement.meta);
  const dsp = useSelector((state) => state.dealManagement.programmatic.dsp);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);
  const sourceSystem = useSelector((state) => state.dealManagement.commonDeal.sourceSystem);
  const advertiser = useSelector((state) => state.dealManagement.commonDeal.advertiser);
  const brand = useSelector((state) => state.dealManagement.commonDeal.brand);
  const productCategory = useSelector((state) => state.dealManagement.commonDeal.productCategory);
  const agency = useSelector((state) => state.dealManagement.commonDeal.agency);
  const specialist = useSelector((state) => state.dealManagement.commonDeal.specialist);
  const salesPerson = useSelector((state) => state.dealManagement.commonDeal.salesPerson);
  const salesTeam = useSelector((state) => state.dealManagement.commonDeal.salesTeam);
  const externalReference = useSelector((state) => state.dealManagement.commonDeal.externalReference);
  const overriddenCampaignId = useSelector((state) => state.dealManagement.commonDeal.overriddenCampaignId);
  const dealType = useSelector((state) => state.dealManagement.commonDeal.dealType);
  const dealPriority = useSelector((state) => state.dealManagement.commonDeal.dealPriority);

  const [advertisers, setAdvertisers] = useState([]);
  const [brands, setBrands] = useState([]);
  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled) || DealStatus.LIVE === bookingStatusCode;
  const isOverriddenCampaignIdVisible =
    useHasFeatureAccess(FeatureFlags.OVERRIDE_DEAL_ID) && isNewDeal && dealType === DealType.GUARANTEED;
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;

  const { isDspSettingsActive } = useDspSettings();
  const cancelFunctions = useCancelRequest();
  const isAdServerMarket = useSelector(getIsAdServer);

  const fetchOptions = async (dropdownName, query) => {
    try {
      return await lookups.getLookupData({ cancelFunctions, lookupURLName: dropdownName, query });
    } catch {
      return [];
    }
  };

  const fetchBrandOptions = async (_, query) => {
    if (brands.length) {
      return brands.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()));
    }

    try {
      return await lookups.getBrandLookupData(cancelFunctions, query, advertiser?.code);
    } catch {
      return [];
    }
  };

  const fetchSalesPerson = async (_, query) => {
    if (query.length < 3) return [];

    try {
      return await lookups.getSalesPersonLookup(cancelFunctions, query);
    } catch {
      return [];
    }
  };

  const fetchSalesTeam = async (_, query) => {
    if (query.length < 3) return [];

    try {
      return await lookups.getSalesTeamLookup(cancelFunctions, query);
    } catch {
      return [];
    }
  };

  const onAdvertiserChange = (value) => {
    if (!value) {
      dispatch(changeDealInfo({ brand: null, productCategory: null }));
      setAdvertisers([]);
      setBrands([]);
    } else {
      const { brands: brandsData } = value;
      if (brandsData.length === 1) {
        dispatch(
          changeDealInfo({
            brand: brandsData[0],
            productCategory: brandsData[0].productCategory,
          }),
        );
      } else {
        dispatch(changeDealInfo({ brand: null, productCategory: null }));
      }

      setBrands(brandsData);
    }
  };

  const onBrandChange = (value) => {
    if (!value) {
      dispatch(changeDealInfo({ advertiser: null, productCategory: null }));
      setAdvertisers([]);
      setBrands([]);
    } else {
      if (value.advertisers) {
        dispatch(changeDealInfo({ advertiser: value.advertisers[0] }));
        setAdvertisers(value.advertisers);
      }

      dispatch(changeDealInfo({ productCategory: value.productCategory }));
    }
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-7 pr-28 grid grid-flow-row gap-11 content-start">
        {!isReseller && (
          <>
            <div>
              <p className="mb-2 sub-header-base text-neutral-950">
                Advertiser
                {sourceSystem !== SOURCE_SYSTEM.DV360 && <RequiredAsterisk />}
              </p>
              <AsyncSelect
                dataTestId="advertiser-input"
                name="advertiser"
                selectedValue={advertiser}
                loadData={fetchOptions}
                onSelect={(dropdownName, value) => {
                  dispatch(changeDealInfo({ [dropdownName]: value }));
                  onAdvertiserChange(value);
                }}
                defaultOptions={advertisers}
                placeholder="Search..."
                isDisabled={readOnly && !isPendingReservation}
                theme={ASYNC_SELECT_THEME.LIGHT}
                shadow={ShadowStyle.SMALL}
              />
            </div>
            <div>
              <p className="mb-2 sub-header-base text-neutral-950">
                Brand
                {sourceSystem !== SOURCE_SYSTEM.DV360 && <RequiredAsterisk />}
              </p>
              <AsyncSelect
                dataTestId="brand-input"
                name="brand"
                selectedValue={brand}
                loadData={fetchBrandOptions}
                onSelect={(dropdownName, value) => {
                  dispatch(changeDealInfo({ [dropdownName]: value }));
                  onBrandChange(value);
                }}
                defaultOptions={brands}
                placeholder="Search..."
                isDisabled={readOnly && !isPendingReservation}
                theme={ASYNC_SELECT_THEME.LIGHT}
                shadow={ShadowStyle.SMALL}
              />
            </div>
            <div>
              <p className="mb-2 sub-header-base text-neutral-950">
                Product category
                {sourceSystem !== SOURCE_SYSTEM.DV360 && <RequiredAsterisk />}
              </p>
              <BaseSelect
                name="productCategory"
                options={meta.productCategoryGroups
                  .map((productCategoryGroup) => productCategoryGroup.productCategories)
                  .flat()}
                selectedValue={{ code: productCategory?.code, name: productCategory?.name }}
                placeholder="Select product category"
                onSelect={(dropdownName, value) => dispatch(changeDealInfo({ [dropdownName]: value }))}
                isDisabled={readOnly && !isPendingReservation}
                dataTestId="deal-info-product-category-dropdown"
                shadow={ShadowStyle.SMALL}
              />
            </div>
          </>
        )}
        <div>
          <p className="mb-2 sub-header-base text-neutral-950">
            DSP
            <RequiredAsterisk />
          </p>
          <FilterDsp
            dataTestId="dsp-input"
            selectedValue={dsp}
            placeholder="Search..."
            onChange={(key, value) => dispatch(changeProgrammaticDealInfo({ [key]: value, syncWithDsp: false }))}
            isDisabled={readOnly || isPendingReservation}
            theme={ASYNC_SELECT_THEME.LIGHT}
            shadow={ShadowStyle.SMALL}
          />
        </div>
        {isDspSettingsActive && (
          <div className="relative border-t border-neutral-300 pt-6 mb-10">
            <p className="mb-2 sub-header-base text-neutral-950">DSP settings</p>
            <DspSync theme="LIGHT" />
            <CreativeBidStream theme="LIGHT" />
            <OpenMarketplace theme="LIGHT" />
          </div>
        )}
      </div>
      <div className="col-span-5 grid grid-flow-row gap-11 content-start">
        {!isReseller && (
          <>
            <div>
              <p className="mb-2 sub-header-base text-neutral-950">Agency</p>
              <AsyncSelect
                name="agency"
                selectedValue={agency}
                loadData={fetchOptions}
                onSelect={(key, value) => dispatch(changeDealInfo({ [key]: value }))}
                defaultOptions={agency}
                placeholder="Search..."
                isDisabled={isReadOnly(bookingStatusCode, isEditingDisabled)}
                theme={ASYNC_SELECT_THEME.LIGHT}
                dataTestId="agency-input"
                shadow={ShadowStyle.SMALL}
              />
            </div>
            <div>
              <p className="mb-2 sub-header-base text-neutral-950">Specialist</p>
              <AsyncSelect
                name="specialist"
                selectedValue={specialist}
                loadData={fetchOptions}
                onSelect={(key, value) => dispatch(changeDealInfo({ [key]: value }))}
                defaultOptions={specialist}
                placeholder="Search..."
                isDisabled={isReadOnly(bookingStatusCode, isEditingDisabled)}
                theme={ASYNC_SELECT_THEME.LIGHT}
                dataTestId="specialist-input"
                shadow={ShadowStyle.SMALL}
              />
            </div>
          </>
        )}
        <div>
          <p className="mb-2 sub-header-base text-neutral-950">External ref.</p>
          <Input
            name="externalReference"
            value={externalReference}
            placeholder="External reference"
            onChange={(value) => dispatch(changeDealInfo({ externalReference: value }))}
            debounceTime={1000}
            isDisabled={readOnly && !isPendingReservation}
            dataTestId="external-ref-input"
          />
        </div>
        {isOverriddenCampaignIdVisible && (
          <div>
            <p className="mb-2 sub-header-base text-neutral-950">Override deal ID</p>
            <Input
              name="overriddenCampaignId"
              value={overriddenCampaignId}
              placeholder="Override Deal id"
              onChange={(value) => dispatch(changeDealInfo({ overriddenCampaignId: value }))}
              dataTestId="overridden-campaign-input"
            />
          </div>
        )}

        {dealType !== DealType.GUARANTEED ? (
          <div>
            <p className="mb-2 sub-header-base text-neutral-950">Deal priority</p>
            <BaseSelect
              name="dealPriority"
              options={codeNameDealPriorityOptions}
              selectedValue={{
                code: dealPriority,
                name: DealPriorityLabel[dealPriority],
              }}
              onSelect={(name, value) => dispatch(changeDealData({ dealPriority: value.code }))}
              isDisabled={(isReadOnly(bookingStatusCode, isEditingDisabled) && !isPendingReservation) || isReseller}
              shadow={ShadowStyle.SMALL}
            />
          </div>
        ) : null}
        {isAdServerMarket && !isReseller ? (
          <>
            <div>
              <p className="mb-2 sub-header-base text-neutral-950">Sales person</p>
              <AsyncSelect
                name="salesPerson"
                selectedValue={salesPerson}
                loadData={fetchSalesPerson}
                onSelect={(key, value) => dispatch(changeDealInfo({ [key]: value }))}
                defaultOptions={salesPerson}
                placeholder="Search..."
                isDisabled={isReadOnly(bookingStatusCode, isEditingDisabled)}
                theme={ASYNC_SELECT_THEME.LIGHT}
                dataTestId="sales-person-input"
                shadow={ShadowStyle.SMALL}
              />
            </div>
            <div>
              <p className="mb-2 sub-header-base text-neutral-950">Sales team</p>
              <AsyncSelect
                name="salesTeam"
                selectedValue={salesTeam}
                loadData={fetchSalesTeam}
                onSelect={(key, value) => dispatch(changeDealInfo({ [key]: value }))}
                defaultOptions={salesTeam}
                placeholder="Search..."
                isDisabled={isReadOnly(bookingStatusCode, isEditingDisabled)}
                theme={ASYNC_SELECT_THEME.LIGHT}
                dataTestId="sales-team-input"
                shadow={ShadowStyle.SMALL}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DealInfo;
