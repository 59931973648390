import CreativeSetTable from './CreativeSetTable';
import Filters from './Filters';

const Main = () => {
  return (
    <>
      <Filters />
      <CreativeSetTable />
    </>
  );
};

export default Main;
