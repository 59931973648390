const DAY_PART = {
  fullDay: {
    id: -1,
    dayPartId: -1,
    startOffset: 0,
    endOffset: 86399,
    displayName: 'Full Day',
  },
  dayPart: {
    id: -2,
    startOffset: 0,
    endOffset: 86399,
    displayName: 'Day Part',
  },
  hours: [
    {
      id: 0,
      startOffset: 0,
      endOffset: 3599,
      startLabel: '00:00',
      endLabel: '00:59',
    },
    {
      id: 1,
      startOffset: 3600,
      endOffset: 7199,
      startLabel: '01:00',
      endLabel: '01:59',
    },
    {
      id: 2,
      startOffset: 7200,
      endOffset: 10799,
      startLabel: '02:00',
      endLabel: '02:59',
    },
    {
      id: 3,
      startOffset: 10800,
      endOffset: 14399,
      startLabel: '03:00',
      endLabel: '03:59',
    },
    {
      id: 4,
      startOffset: 14400,
      endOffset: 17999,
      startLabel: '04:00',
      endLabel: '04:59',
    },
    {
      id: 5,
      startOffset: 18000,
      endOffset: 21599,
      startLabel: '05:00',
      endLabel: '05:59',
    },
    {
      id: 6,
      startOffset: 21600,
      endOffset: 25199,
      startLabel: '06:00',
      endLabel: '06:59',
    },
    {
      id: 7,
      startOffset: 25200,
      endOffset: 28799,
      startLabel: '07:00',
      endLabel: '07:59',
    },
    {
      id: 8,
      startOffset: 28800,
      endOffset: 32399,
      startLabel: '08:00',
      endLabel: '08:59',
    },
    {
      id: 9,
      startOffset: 32400,
      endOffset: 35999,
      startLabel: '09:00',
      endLabel: '09:59',
    },
    {
      id: 10,
      startOffset: 36000,
      endOffset: 39599,
      startLabel: '10:00',
      endLabel: '10:59',
    },
    {
      id: 11,
      startOffset: 39600,
      endOffset: 43199,
      startLabel: '11:00',
      endLabel: '11:59',
    },
    {
      id: 12,
      startOffset: 43200,
      endOffset: 46799,
      startLabel: '12:00',
      endLabel: '12:59',
    },
    {
      id: 13,
      startOffset: 46800,
      endOffset: 50399,
      startLabel: '13:00',
      endLabel: '13:59',
    },
    {
      id: 14,
      startOffset: 50400,
      endOffset: 53999,
      startLabel: '14:00',
      endLabel: '14:59',
    },
    {
      id: 15,
      startOffset: 54000,
      endOffset: 57599,
      startLabel: '15:00',
      endLabel: '15:59',
    },
    {
      id: 16,
      startOffset: 57600,
      endOffset: 61199,
      startLabel: '16:00',
      endLabel: '16:59',
    },
    {
      id: 17,
      startOffset: 61200,
      endOffset: 64799,
      startLabel: '17:00',
      endLabel: '17:59',
    },
    {
      id: 18,
      startOffset: 64800,
      endOffset: 68399,
      startLabel: '18:00',
      endLabel: '18:59',
    },
    {
      id: 19,
      startOffset: 68400,
      endOffset: 71999,
      startLabel: '19:00',
      endLabel: '19:59',
    },
    {
      id: 20,
      startOffset: 72000,
      endOffset: 75599,
      startLabel: '20:00',
      endLabel: '20:59',
    },
    {
      id: 21,
      startOffset: 75600,
      endOffset: 79199,
      startLabel: '21:00',
      endLabel: '21:59',
    },
    {
      id: 22,
      startOffset: 79200,
      endOffset: 82799,
      startLabel: '22:00',
      endLabel: '22:59',
    },
    {
      id: 23,
      startOffset: 82800,
      endOffset: 86399,
      startLabel: '23:00',
      endLabel: '23:59',
    },
  ],
};

export default DAY_PART;
