import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteFrameTag, setFrameTag } from 'store/creative/reducer';

import StatusIcon from 'components/common/StatusIcon';
import Table, { TableHeight } from 'components/patterns/Table';
import { FrameTag } from 'components/common/types/FrameTag.types';

import { CreativeStatus } from 'components/common/types/Creative.types';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { createFrameTag, removeFrameTag, updateFrameTag } from 'modules/api/content';

import { Store } from 'components/common/types/Store.types';
import ActionRenderer from '../ActionRenderer';
import ViewRenderer from '../ViewRenderer';
import DeleteRenderer from '../DeleteRenderer';

interface Props {
  frameTags: FrameTag[];
  tableHeight: keyof typeof TableHeight;
  onTaggingRulesChange?: VoidFunction;
  marketId: string;
}

export const TaggingRulesTable: React.FC<Props> = ({ onTaggingRulesChange, frameTags, tableHeight, marketId }) => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();
  const selectedCreatives = useSelector((state: Store) => state.pages.contentManagement.creatives.selectedCreatives);

  const onTypeChange = async (frameTag: FrameTag): Promise<void> => {
    try {
      if (frameTag.marketTagId) {
        await updateFrameTag(cancelFunctions, frameTag.marketTagId, frameTag.type);

        dispatch(setFrameTag(frameTag));
      } else {
        const { id: marketTagId } = await createFrameTag(cancelFunctions, marketId, frameTag.id, frameTag.type);

        dispatch(setFrameTag({ ...frameTag, marketTagId }));
      }
    } catch {} // eslint-disable-line no-empty

    onTaggingRulesChange?.();
  };

  const onDeleteFrameTag = async (marketTagId: string): Promise<void> => {
    try {
      await removeFrameTag(cancelFunctions, marketTagId);
    } catch {} // eslint-disable-line no-empty

    dispatch(deleteFrameTag(marketTagId));
    onTaggingRulesChange?.();
  };

  const columnDefs = useRef([
    {
      headerName: 'Status',
      maxWidth: 100,
      field: 'frameCount',
      cellRenderer: (params: { value: unknown }) => (
        <StatusIcon statusCode={params.value ? CreativeStatus.APPROVED : CreativeStatus.INACTIVE} />
      ),
    },
    {
      headerName: 'Tag group',
      field: 'group',
      cellRenderer: (params: { value: { name: string } }) => `${params.value.name}`,
    },
    {
      headerName: 'Tag name',
      field: 'name',
    },
    {
      headerName: 'Tag ID',
      field: 'code',
    },
    {
      headerName: 'Frame Targeting',
      field: 'type',
      cellRenderer: ActionRenderer,
      cellRendererParams: {
        onTypeChange,
      },
      minWidth: 150,
      hide: !!selectedCreatives.length,
    },
    {
      headerName: 'Linked frames',
      field: 'frameCount',
      cellRenderer: ViewRenderer,
    },
    {
      cellRenderer: DeleteRenderer,
      cellRendererParams: {
        onDeleteFrameTag,
      },
      resizable: false,
      hide: !!selectedCreatives.length,
    },
  ]).current;

  return (
    <Table
      dataTestId="frame-tags-table"
      columnDefs={columnDefs}
      rowData={frameTags}
      tableSize={{ tableHeight }}
      extraGridOptions={{
        enableCellTextSelection: true,
      }}
    />
  );
};

TaggingRulesTable.defaultProps = {
  onTaggingRulesChange: undefined,
};
