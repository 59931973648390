import { CodeNameModel } from 'components/common/types';

export interface ChipProps {
  label: string;
  type?: Type;
  size?: Size;
  icon?: string;
  isRemovable?: boolean;
  onRemove?(label: string): void;
  isDisabled?: boolean;
  onIncludeExcludeChange?: (arg0: CodeNameModel, arg1: boolean) => void;
  item?: CodeNameModel;
}

export enum Type {
  NEUTRAL = 'bg-neutral-950-opacity-5 text-neutral-900 [&>button]:bg-transparent',
  BRAND = 'bg-primary-600-opacity-10 [&>p]:text-primary-600 [&>button]:bg-primary-600-opacity-20 [&>button]:text-primary-800',
}

export enum Size {
  SMALL = 'gap-1 body-sm px-1 rounded-full [&>SVG]:w-3 [&>SVG]:h-3 [&>button]:rounded-full [&>button]:w-3 [&>button]:h-3',
  EXTRA_LARGE = 'gap-1 text-sm pl-2 rounded-full [&>button]:rounded-full [&>button]:w-10 [&>button]:h-8',
}
