type Props = {
  spaceY?: number;
};

const SectionDivider: React.FC<Props> = ({ spaceY = 0 }) => <div className={`h-px bg-neutral-300 my-${spaceY}`} />;

SectionDivider.defaultProps = {
  spaceY: 0,
};

export default SectionDivider;
