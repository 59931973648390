import { DealType } from 'components/common/types/Deal.types';
import { formatISODateToDateObj } from 'utils/dateFormatUtil';
import { getDealPriority, getSweepKeyByCode } from 'utils/deal';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import {
  getListFrames,
  getTags,
  getLocationData,
  getSweepVisibility,
  getVisualUnitFrames,
  getProximity,
  getVenueTaxonomies,
  sortLines,
} from 'components/pages/DealWithLines/common/transformDealDetails';
import { transformPattern } from 'components/pages/DealWithLines/common/Main/Line/Schedule/Pattern/daysInfo';

const getDealSummary = (
  {
    startDate,
    endDate,
    allocatedFrames,
    availableFrames,
    allocatedImpressions,
    availableImpressions,
    impressions,
    budget,
    netMediaValue,
    grossMediaValue,
    distributionTypes,
    frames,
    sot,
    totalCost,
    includeFrames,
    excludeFrames,
    assets,
    assetCpmDistribution,
    environments,
    tags,
    countries,
    cities,
    streets,
    counties,
    postCodes,
    productFormats,
  },
  dealTypeCode,
  hasSweepTargetingEnabled,
  hasNonGuaranteedExtendedTargetEnabled,
) => ({
  startDate: formatISODateToDateObj(startDate, Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC)),
  endDate: formatISODateToDateObj(endDate, Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC)),
  availability: {
    allocatedFrames,
    availableFrames,
    allocatedImpressions,
    availableImpressions,
    totalCost,
    ...(netMediaValue ? { netMediaValue } : {}),
    ...(grossMediaValue ? { grossMediaValue } : {}),
    assets,
    assetCpmDistribution,
  },
  ...getListFrames(includeFrames, excludeFrames),
  ...getTags(tags),
  ...getLocationData(countries, cities, streets, counties, postCodes),
  ...(impressions ? { impressions } : {}),
  ...(budget ? { budget } : {}),
  ...(frames ? { frames } : {}),
  ...(sot ? { sot } : {}),
  ...(getSweepVisibility(dealTypeCode, hasSweepTargetingEnabled, hasNonGuaranteedExtendedTargetEnabled) &&
  distributionTypes
    ? { sweeps: distributionTypes }
    : {}),
  ...(environments ? { environments } : {}),
  ...(productFormats ? { productFormats } : {}),
});

const transformLine = (
  {
    id,
    impressions,
    budget,
    deliveredImpressions,
    frames,
    sot,
    sotFloor,
    sotCeiling,
    name,
    createdDate,
    startDate: startDateString,
    endDate,
    includeFrames,
    excludeFrames,
    includeVisualUnits,
    excludeVisualUnits,
    distributionType,
    pgDistributionType,
    terminated,
    lineSummary: {
      allocatedFrames,
      availableFrames,
      allocatedImpressions,
      availableImpressions,
      totalSot,
      totalSpans,
      totalCost,
      assets,
      assetCpmDistribution,
    },
    pattern,
    environments,
    productFormats,
    countries,
    cities,
    streets,
    counties,
    postCodes,
    tags,
    proximity,
    venueTaxonomyEnumerationIds,
  },
  dealTypeCode,
  config,
  hasSweepTargetingEnabled,
  hasNonGuaranteedExtendedTargetEnabled,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const startDate = formatISODateToDateObj(
    startDateString,
    Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC),
  );

  return {
    id,
    name,
    createdDate: formatISODateToDateObj(createdDate, Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC)),
    startDate,
    endDate: formatISODateToDateObj(endDate, Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC)),
    terminated,
    availability: {
      allocatedFrames,
      availableFrames,
      allocatedImpressions,
      availableImpressions,
      totalCost,
      assets,
      assetCpmDistribution,
    },
    preservedAvailability: {
      allocatedImpressions,
      totalCost,
      totalSot,
      totalSpans,
    },
    ...getListFrames(includeFrames, excludeFrames),
    ...getVisualUnitFrames(includeVisualUnits, excludeVisualUnits),
    ...getLocationData(countries, cities, streets, counties, postCodes),
    ...getTags(tags),
    ...getProximity(proximity),
    ...(getSweepVisibility(dealTypeCode, hasSweepTargetingEnabled, hasNonGuaranteedExtendedTargetEnabled) &&
    distributionType
      ? { sweep: getSweepKeyByCode(distributionType) }
      : {}),
    ...(getSweepVisibility(dealTypeCode, hasSweepTargetingEnabled, hasNonGuaranteedExtendedTargetEnabled) &&
    pgDistributionType
      ? { sweep: pgDistributionType }
      : {}),
    ...(impressions ? { impressions } : {}),
    ...(budget ? { budget } : {}),
    ...(deliveredImpressions ? { deliveredImpressions } : {}),
    ...(frames ? { frames } : {}),
    ...(sot ? { sot } : {}),
    ...(config.autoCalculateFloorAndCeiling && sotFloor ? { sotFloor } : {}),
    ...(config.autoCalculateFloorAndCeiling && sotCeiling ? { sotCeiling } : {}),
    ...(pattern
      ? { selectedDays: transformPattern(pattern.pattern, startDate), patternLength: pattern.patternLength }
      : {}),
    ...(environments ? { environments } : {}),
    ...(productFormats ? { productFormats } : {}),
    ...getVenueTaxonomies(venueTaxonomyEnumerationIds),
  };
};

export const transformMultiLineDealDetails = (
  {
    reference,
    dealId,
    internalId,
    dealTypeCode,
    advertiser,
    brand,
    productCategory,
    dsp,
    specialist,
    agency,
    dealPriority,
    auctionModel,
    dealName,
    externalReference,
    floorCPM,
    bookingStatusCode,
    dealSummary,
    dealLines,
    netMediaValue,
    grossMediaValue,
    sourceSystem,
    bids,
  },
  config,
  hasSweepTargetingEnabled,
  hasNonGuaranteedExtendedTargetEnabled,
) => {
  const commonDeal = {
    sbId: reference,
    dealId,
    internalId,
    dealType: dealTypeCode,
    advertiser,
    brand,
    productCategory,
    ...(sourceSystem ? { sourceSystem } : {}),
    ...(specialist ? { specialist } : {}),
    ...(agency ? { agency } : {}),
    dealPriority: getDealPriority(dealPriority, dealTypeCode),
    dealName,
    cpm: floorCPM,
    bookingStatusCode,
    ...(externalReference ? { externalReference } : {}),
    ...(dealTypeCode === DealType.NON_GUARANTEED_FLOOR_PRICE && auctionModel ? { auctionModel } : {}),
    summary: getDealSummary(
      {
        ...dealSummary,
        netMediaValue,
        grossMediaValue,
      },
      dealTypeCode,
      hasSweepTargetingEnabled,
      hasNonGuaranteedExtendedTargetEnabled,
    ),
    bids: bids
      ? {
          bidRequests: bids.bidRequests || bids.bidRequest,
          bidResponses: bids.bidResponses || bids.bidResponse,
          bookedImpressions: bids.bookedImpressions,
          fillRate: bids.fillRate,
          revenue: bids.revenue,
          soldImpressions: bids.soldImpressions,
          wonImpressions: bids.wonImpressions,
        }
      : undefined,
  };

  const lines = sortLines(
    dealLines.map((line) =>
      transformLine(line, dealTypeCode, config, hasSweepTargetingEnabled, hasNonGuaranteedExtendedTargetEnabled),
    ),
  );

  return {
    commonDeal: {
      ...commonDeal,
      currentLine: lines[lines.length - 1],
    },
    programmatic: {
      dsp,
    },
    backupFormData: {
      commonDeal,
      programmatic: {
        dsp,
      },
      lines,
    },
  };
};
