import classNames from 'classnames/bind';
import ReactBaseSelect, { StylesConfig } from 'react-select';
import { ShadowStyle } from 'consts/shadow';
import { getInputBorderStyle } from 'lib/BaseInput';
import { CodeNameModel } from 'components/common/types';
import { InputShape } from 'components/common/types/Input.types';
import styles from 'components/patterns/AsyncSelect/AsyncSelect.pcss';
import { BaseSelectProps, OptionObject } from '../BaseSelect.types';

const cx = classNames.bind(styles);

const BaseSelectWrapper = ({
  defaultOptions,
  options,
  name,
  placeholder,
  selectedValue,
  onSelect,
  dataTestId,
  isMultiple,
  customComponents = {},
  closeMenuOnSelect = true,
  isDisabled = false,
  isClearable = false,
  isSelectionVisible = true,
  menuPosition = 'absolute',
  menuPlacement = 'bottom',
  shadow = ShadowStyle.NONE,
  shape = InputShape.ROUNDED,
}: BaseSelectProps): React.ReactElement => {
  const handleChange = (newValue: CodeNameModel[] | CodeNameModel): void => {
    onSelect(name, newValue);
  };

  const getOptionLabel = (option: OptionObject): string => {
    return option.name;
  };

  const getOptionValue = (option: OptionObject): string => {
    return option.code;
  };

  const getStyles = (): StylesConfig => {
    const isAutoComplete = (defaultOptions && defaultOptions?.length) || options.length;

    return {
      dropdownIndicator: (base) => ({
        ...base,
        display: isAutoComplete ? base.display : 'none',
      }),
    };
  };

  return (
    <div
      className={cx(
        'shadow-inner-sm',
        getInputBorderStyle({
          isDisabled,
          shape,
        }),
      )}
      data-test-id={dataTestId}
    >
      <ReactBaseSelect
        id={`react-select-${name}`}
        aria-label={name}
        className={cx('async-select', 'w-full', 'py-1', { 'async-select-shadow': shadow === ShadowStyle.SMALL })}
        classNamePrefix="react-select"
        components={customComponents}
        controlShouldRenderValue={isSelectionVisible}
        options={options}
        value={selectedValue}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        hideSelectedOptions={false}
        isMulti={isMultiple}
        isDisabled={isDisabled}
        isSearchable={false}
        isClearable={isClearable}
        menuPosition={menuPosition}
        menuPlacement={menuPlacement}
        onChange={handleChange}
        placeholder={placeholder}
        styles={getStyles()}
        closeMenuOnSelect={closeMenuOnSelect}
      />
    </div>
  );
};

export default BaseSelectWrapper;
