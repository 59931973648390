import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { DOUBLE_DASH } from 'consts/placeholders';
import { formatDate } from 'modules/I18N';
import { TimelineItem } from 'components/patterns/Timeline';
import { TagModel } from 'components/common/prop-types/inventory';

const TagInfo = ({ tagGroup }) => {
  const { localeCode } = useSelector((state) => state.publisher.configuration);

  return (
    <TimelineItem
      title={
        <div className="grid grid-cols-3 gap-x-4 items-center">
          {tagGroup.map(({ tagUuid, tagName }) => (
            <p key={tagUuid} className="body-sm break-all">
              {tagName ?? DOUBLE_DASH}
            </p>
          ))}
        </div>
      }
    >
      <div className="grid grid-cols-3 gap-x-4">
        {tagGroup.map(({ tagUuid, startDate, endDate }) => (
          <p key={tagUuid} className="body-base text-neutral-950-opacity-60">{`${formatDate(
            startDate,
            localeCode,
          )}-${formatDate(endDate, localeCode)}`}</p>
        ))}
      </div>
    </TimelineItem>
  );
};

TagInfo.propTypes = {
  tagGroup: PropTypes.arrayOf(TagModel).isRequired,
};

export default TagInfo;
