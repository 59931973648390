import createRequest from 'modules/api/request';
import Session from 'modules/Session';
import { URLS, HTTP_METHODS } from 'modules/api/constants';
import { transformCreativeFrameTags } from 'utils/apiAdapters';
import queryString from 'query-string';

export const fetchCreatives = async (cancelFunctions, filtersData) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_CREATIVES(queryString.stringify(filtersData, { skipEmptyString: true, arrayFormat: 'comma' })),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  cancelFunctions.FETCH_CREATIVES = cancel;

  const result = await send();
  return result.data;
};

export const fetchCreativeDetails = async (cancelFunctions, creativeId) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_CREATIVES_DETAILS(creativeId),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  cancelFunctions.FETCH_CREATIVES_DETAILS = cancel;

  const result = await send();

  return result.data;
};

export const getCreativeCategories = async () => {
  const { send } = createRequest({
    url: URLS().CREATIVE_CATEGORIES(`environment=${Session.getEnvironmentId()}`),
    method: HTTP_METHODS.GET,
  });

  const result = await send();

  return result.data;
};

export const addCreativeCategory = async (cancelFunctions, marketId, categoryId) => {
  const { send, cancel } = createRequest({
    url: URLS().UPDATE_CREATIVE_CATEGORY(marketId, categoryId),
    method: HTTP_METHODS.POST,
    showLoader: true,
  });

  cancelFunctions.ADD_CREATIVE_CATEGORY = cancel;

  await send();
};

export const removeCreativeCategory = async (cancelFunctions, marketId, categoryId) => {
  const { send, cancel } = createRequest({
    url: URLS().UPDATE_CREATIVE_CATEGORY(marketId, categoryId),
    method: HTTP_METHODS.DELETE,
    showLoader: true,
  });

  cancelFunctions.REMOVE_CREATIVE_CATEGORY = cancel;

  await send();
};

export const addCreativeDeal = async (cancelFunctions, marketId, dealId) => {
  const { send, cancel } = createRequest({
    url: URLS().ADD_CREATIVE_DEALID(marketId),
    method: HTTP_METHODS.POST,
    data: { dealId },
    showLoader: true,
  });

  cancelFunctions.ADD_CREATIVE_DEALID = cancel;

  const result = await send();

  return result.data;
};

export const removeCreativeDeal = async (cancelFunctions, dealId) => {
  const { send, cancel } = createRequest({
    url: URLS().DELETE_CREATIVE_DEALID(dealId),
    method: HTTP_METHODS.DELETE,
  });

  cancelFunctions.DELETE_CREATIVE_DEALID = cancel;

  await send();
};

export const getCreativeFrameSummary = async (cancelFunctions, creativeId, environment) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATIVE_FRAME_SUMMARY(creativeId, environment),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  cancelFunctions.CREATIVE_FRAME_SUMMARY = cancel;

  const result = await send();

  return result.data;
};

export const exportFrameSummary = async (cancelFunctions, creativeId) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATIVE_EXPORT_FRAME_SUMMARY(creativeId, Session.getEnvironmentId()),
    method: HTTP_METHODS.GET,
    showLoader: true,
    responseType: 'blob',
  });

  cancelFunctions.CREATIVE_EXPORT_FRAME_SUMMARY = cancel;

  const result = await send();

  return result.data;
};

export const getAdvertisers = async (cancelFunctions, environment, name) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_ADVERTISERS(environment, name),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_ADVERTISERS) cancelFunctions.FETCH_ADVERTISERS();
  cancelFunctions.FETCH_ADVERTISERS = cancel;

  const result = await send();

  return result.data;
};

export const getAgency = async (cancelFunctions, environment, name) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_AGENCY(environment, name),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_AGENCY) cancelFunctions.FETCH_AGENCY();
  cancelFunctions.FETCH_AGENCY = cancel;

  const result = await send();

  return result.data;
};

export const addAgency = async (cancelFunctions, marketId, agencyId) => {
  const { send, cancel } = createRequest({
    url: URLS().UPDATE_AGENCY(marketId),
    method: HTTP_METHODS.POST,
    data: {
      agencyId,
    },
  });

  if (cancelFunctions.UPDATE_AGENCY) cancelFunctions.UPDATE_AGENCY();
  cancelFunctions.UPDATE_AGENCY = cancel;

  await send();
};

export const deleteAgency = async (cancelFunctions, marketId) => {
  const { send, cancel } = createRequest({
    url: URLS().UPDATE_AGENCY(marketId),
    method: HTTP_METHODS.DELETE,
  });

  if (cancelFunctions.UPDATE_AGENCY) cancelFunctions.UPDATE_AGENCY();
  cancelFunctions.UPDATE_AGENCY = cancel;

  await send();
};

export const getSpecialist = async (cancelFunctions, environment, name) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_SPECIALIST(environment, name),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_SPECIALIST) cancelFunctions.FETCH_SPECIALIST();
  cancelFunctions.FETCH_SPECIALIST = cancel;

  const result = await send();

  return result.data;
};

export const addSpecialist = async (cancelFunctions, marketId, specialistId) => {
  const { send, cancel } = createRequest({
    url: URLS().UPDATE_SPECIALIST(marketId),
    method: HTTP_METHODS.POST,
    data: {
      specialistId,
    },
  });

  if (cancelFunctions.UPDATE_SPECIALIST) cancelFunctions.UPDATE_SPECIALIST();
  cancelFunctions.UPDATE_SPECIALIST = cancel;

  await send();
};

export const deleteSpecialist = async (cancelFunctions, marketId) => {
  const { send, cancel } = createRequest({
    url: URLS().UPDATE_SPECIALIST(marketId),
    method: HTTP_METHODS.DELETE,
  });

  if (cancelFunctions.UPDATE_SPECIALIST) cancelFunctions.UPDATE_SPECIALIST();
  cancelFunctions.UPDATE_SPECIALIST = cancel;

  await send();
};

export const getFrameTags = async (cancelFunctions, query, marketId = '') => {
  const environment = Session.getEnvironmentId();
  const { send, cancel } = createRequest({
    url: marketId
      ? URLS().FETCH_CREATIVE_FRAME_TAGS(environment, query, marketId)
      : URLS().FETCH_CREATIVE_FRAME_TAGS(environment, query),
    method: HTTP_METHODS.GET,
  });

  cancelFunctions.FETCH_CREATIVE_FRAME_TAGS = cancel;

  const result = await send();

  return transformCreativeFrameTags(result.data);
};

export const getAllFrameTags = async (cancelFunctions, marketId) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_ALL_CREATIVE_FRAME_TAGS(marketId),
    method: HTTP_METHODS.GET,
  });

  cancelFunctions.FETCH_ALL_CREATIVE_FRAME_TAGS = cancel;

  const result = await send();

  return result.data;
};

export const deleteCreativeCommercial = async (cancelFunctions, commercialId) => {
  const { send, cancel } = createRequest({
    url: URLS().DELETE_CREATIVE_COMMERCIAL(commercialId),
    method: HTTP_METHODS.DELETE,
  });

  cancelFunctions.DELETE_CREATIVE_COMMERCIAL = cancel;

  await send();
};

export const createCreativeCommercial = async (cancelFunctions, marketId, advertiserId, brandId, productCategoryId) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATE_CREATIVE_COMMERCIAL(marketId),
    method: HTTP_METHODS.POST,
    data: {
      idAdvertiser: advertiserId,
      idBrand: brandId,
      idProductCategory: productCategoryId || null,
    },
  });

  cancelFunctions.CREATE_CREATIVE_COMMERCIAL = cancel;

  const result = await send();

  return result.data;
};

export const updateCreativeCommercial = async (
  cancelFunctions,
  commercialId,
  advertiserId,
  brandId,
  productCategoryId,
) => {
  const { send, cancel } = createRequest({
    url: URLS().UPDATE_CREATIVE_COMMERCIAL(commercialId),
    method: HTTP_METHODS.PUT,
    data: {
      idAdvertiser: advertiserId,
      idBrand: brandId,
      idProductCategory: productCategoryId || null,
    },
  });

  if (cancelFunctions.UPDATE_CREATIVE_COMMERCIAL) cancelFunctions.UPDATE_CREATIVE_COMMERCIAL();
  cancelFunctions.UPDATE_CREATIVE_COMMERCIAL = cancel;

  await send();
};

export const createFrameTag = async (cancelFunctions, marketId, tagId, type) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATE_CREATIVE_FRAME_TAG(marketId),
    method: HTTP_METHODS.POST,
    data: {
      tagId,
      type,
    },
  });

  cancelFunctions.CREATE_CREATIVE_FRAME_TAG = cancel;

  const result = await send();

  return result.data;
};

export const removeFrameTag = async (cancelFunctions, marketTagId) => {
  const { send, cancel } = createRequest({
    url: URLS().DELETE_CREATIVE_FRAME_TAG(marketTagId),
    method: HTTP_METHODS.DELETE,
  });

  cancelFunctions.DELETE_CREATIVE_FRAME_TAG = cancel;

  await send();
};

export const updateFrameTag = async (cancelFunctions, marketTagId, type) => {
  const { send, cancel } = createRequest({
    url: URLS().UPDATE_CREATIVE_FRAME_TAG(marketTagId),
    method: HTTP_METHODS.PATCH,
    data: {
      type,
    },
  });

  cancelFunctions.UPDATE_CREATIVE_FRAME_TAG = cancel;

  await send();
};

export const getTagFrameDetails = async (cancelFunctions, tagId) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_CREATIVE_TAG_FRAME_DETAILS(tagId),
    method: HTTP_METHODS.GET,
  });

  cancelFunctions.FETCH_CREATIVE_TAG_FRAME_DETAILS = cancel;

  const result = await send();

  return result.data;
};

export const getProductFormats = async (cancelFunctions, environment, query) => {
  const { send, cancel } = createRequest({
    url: URLS().GET_CREATIVE_PRODUCT_FORMAT(environment, query),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  cancelFunctions.GET_CREATIVE_PRODUCT_FORMAT = cancel;

  const result = await send();

  return result.data;
};

export const addProductFormat = async (cancelFunctions, productFormatId, marketId) => {
  const { send, cancel } = createRequest({
    url: URLS().ADD_CREATIVE_PRODUCT_FORMAT(productFormatId, marketId),
    method: HTTP_METHODS.POST,
    showLoader: true,
  });

  cancelFunctions.ADD_CREATIVE_PRODUCT_FORMAT = cancel;

  const result = await send();

  return result.data;
};

export const deleteProductFormat = async (cancelFunctions, productFormatId, marketId) => {
  const { send, cancel } = createRequest({
    url: URLS().REMOVE_CREATIVE_PRODUCT_FORMAT(productFormatId, marketId),
    method: HTTP_METHODS.DELETE,
    showLoader: true,
  });

  cancelFunctions.REMOVE_CREATIVE_PRODUCT_FORMAT = cancel;

  const result = await send();

  return result.data;
};

export const createCreative = async (cancelFunctions, formData) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATE_CREATIVE(),
    method: HTTP_METHODS.POST,
    data: formData,
    maxContentLength: Infinity,
  });

  if (cancelFunctions.CREATE_CREATIVE) cancelFunctions.CREATE_CREATIVE();
  cancelFunctions.CREATE_CREATIVE = cancel;

  const { data } = await send();

  return data;
};

export const deleteCreative = async (cancelFunctions, marketId) => {
  const { send, cancel } = createRequest({
    url: URLS().DELETE_CREATIVE(marketId),
    method: HTTP_METHODS.DELETE,
    showLoader: true,
  });

  if (cancelFunctions.DELETE_CREATIVE) cancelFunctions.DELETE_CREATIVE();
  cancelFunctions.DELETE_CREATIVE = cancel;

  const result = await send();

  return result.data;
};

export const restoreCreative = async (cancelFunctions, marketId) => {
  const { send, cancel } = createRequest({
    url: URLS().RESTORE_CREATIVE(marketId),
    method: HTTP_METHODS.PATCH,
    showLoader: true,
  });

  if (cancelFunctions.RESTORE_CREATIVE) cancelFunctions.RESTORE_CREATIVE();
  cancelFunctions.RESTORE_CREATIVE = cancel;

  const result = await send();

  return result.data;
};

export const getProductCategories = async (cancelFunctions, name) => {
  const query = queryString.stringify(
    { environment: Session.getEnvironmentId(), name },
    { skipEmptyString: true, arrayFormat: 'comma' },
  );

  const { send, cancel } = createRequest({
    url: URLS().FETCH_PRODUCT_CATEGORIES(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_PRODUCT_CATEGORIES) cancelFunctions.FETCH_PRODUCT_CATEGORIES();
  cancelFunctions.FETCH_PRODUCT_CATEGORIES = cancel;

  const result = await send();

  return result.data;
};

export const getBrands = async (cancelFunctions, name) => {
  const query = queryString.stringify(
    { environment: Session.getEnvironmentId(), name },
    { skipEmptyString: true, arrayFormat: 'comma' },
  );

  const { send, cancel } = createRequest({
    url: URLS().FETCH_BRANDS(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_BRANDS) cancelFunctions.FETCH_BRANDS();
  cancelFunctions.FETCH_BRANDS = cancel;

  const result = await send();

  return result.data;
};
