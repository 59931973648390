import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Session from 'modules/Session';
import {
  Creative,
  CreativeId,
  CreativeMetaOperation,
  CreativeMetaOperationsModel,
  CreativesMetaFormModel,
  CreativeStatus,
  UpdateCreativeResponseError,
} from 'components/common/types/Creative.types';
import { getCreativeError, getCreativesMotionType } from './reducerUtils';

export interface Creatives {
  selectedCreatives: Creative[];
  metadata: CreativesMetaFormModel;
  operations: CreativeMetaOperationsModel;
}

const initialState: Creatives = {
  selectedCreatives: [],
  metadata: {
    dealId: [],
    productFormat: [],
    advertiser: null,
    brand: null,
    productCategory: null,
    agency: null,
    specialist: null,
    movement: null,
  },
  operations: {
    dealId: CreativeMetaOperation.ADD,
    productFormat: CreativeMetaOperation.ADD,
  },
};

export const creativesSlice = createSlice({
  name: 'contentManagementCreatives',
  initialState,
  reducers: {
    selectCreatives: (state, action: PayloadAction<Partial<Creative>[]>) => {
      state.selectedCreatives = action.payload.map(
        (creative) =>
          ({
            ...creative,
            error: {},
          }) as Creative,
      );
      state.metadata.movement = getCreativesMotionType(action.payload);
    },
    resetCreativesState: () => {
      return { ...initialState };
    },
    removeSelectedCreative: (state, action: PayloadAction<CreativeId>) => {
      state.selectedCreatives = [...state.selectedCreatives.filter((s) => s.id !== action.payload)];
    },
    setCreativeMeta: (state, action: PayloadAction<Partial<CreativesMetaFormModel>>) => {
      state.metadata = {
        ...state.metadata,
        ...(action.payload ?? initialState.metadata),
      };
      state.selectedCreatives = state.selectedCreatives.map(
        (creative) =>
          ({
            ...creative,
            movement: action.payload.movement ?? creative.movement,
            market: creative.market.map((market) => ({
              ...market,
              ...(market.environment === Session.getEnvironmentId()
                ? {
                    commercial: {
                      ...market.commercial,
                      advertiser: action.payload.advertiser ?? market.commercial?.advertiser,
                      brand: action.payload.brand ?? market.commercial?.brand,
                    },
                  }
                : {}),
            })),
          }) as Creative,
      );
    },
    setCreativeMetaOperations: (state, action: PayloadAction<CreativeMetaOperationsModel>) => {
      state.operations = {
        ...state.operations,
        ...action.payload,
      };
    },
    updateCreativesStatus: (state, action: PayloadAction<CreativeStatus>) => {
      state.selectedCreatives = [
        ...state.selectedCreatives.map((creative) => ({
          ...creative,
          statusCode: action.payload,
        })),
      ];
    },
    clearCreativesMetaAndResetMotionType: (state) => {
      return {
        ...state,
        metadata: {
          ...initialState.metadata,
          movement: getCreativesMotionType(state.selectedCreatives),
        },
        operations: {
          ...initialState.operations,
        },
      };
    },
    updateCreativesErrorStatus: (state, action: PayloadAction<UpdateCreativeResponseError[]>) => {
      return {
        ...state,
        selectedCreatives: [
          ...state.selectedCreatives.map((creative) => ({
            ...creative,
            error: getCreativeError(creative, action.payload),
          })),
        ],
      };
    },
  },
});

export const {
  selectCreatives,
  resetCreativesState,
  removeSelectedCreative,
  setCreativeMeta,
  setCreativeMetaOperations,
  updateCreativesStatus,
  clearCreativesMetaAndResetMotionType,
  updateCreativesErrorStatus,
} = creativesSlice.actions;

export default creativesSlice.reducer;
