import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formatNumber, formatPercent } from 'modules/I18N';
import { transformColumnChartData } from 'utils/formatters';
import { SETTINGS } from 'components/patterns/HighChart';
import { datePercentChartOptions, calculateDealPercentChartOptions } from 'utils/chartOptions';
import HeroChart from '../HeroChart';

const WeightedWinRate = ({ weightedWinData, isDealSelected }) => {
  const { localeCode } = useSelector((state) => state.publisher.configuration);
  const isChartsOpen = useSelector((state) => state.dealDashboard.isChartsOpen);
  const { weightedWinRateGraph, respondedImpressions, soldImpressions, weightedWinRate } = weightedWinData;

  return (
    <HeroChart
      dataTestId="weighted-win-rate-chart"
      chartTitle="Weighted win rate"
      chartData={transformColumnChartData(weightedWinRateGraph, isDealSelected ? 'date' : 'dealId', 'weightedWinRate')}
      table={[
        {
          title: 'Responded imp.',
          titleFullText: 'Responded impressions',
          value: formatNumber({ value: respondedImpressions, localeCode }),
        },
        {
          title: 'Sold imp.',
          titleFullText: 'Sold impressions',
          value: formatNumber({ value: soldImpressions, localeCode }),
        },
        {
          title: 'Weighted win rate',
          value: formatPercent({ value: weightedWinRate / 100, localeCode }),
        },
      ]}
      seriesColor={SETTINGS.COLORS.primaryLight}
      isSplineChart={isDealSelected}
      chartOptions={
        isDealSelected
          ? datePercentChartOptions({ localeCode })
          : calculateDealPercentChartOptions(isChartsOpen, localeCode)
      }
    />
  );
};

WeightedWinRate.propTypes = {
  weightedWinData: PropTypes.object.isRequired,
  isDealSelected: PropTypes.bool,
};

WeightedWinRate.defaultProps = {
  isDealSelected: false,
};

export default WeightedWinRate;
