import classNames from 'classnames/bind';
import { Preloader } from './Preloader';
import styles from './PageLoader.pcss';

const cx = classNames.bind(styles);

export const PageLoader = () => (
  <div className={cx('loader-container')} id="loader-container" style={{ display: 'none' }}>
    <Preloader />
  </div>
);
