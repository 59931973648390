import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import Table, { TableHeight } from 'components/patterns/Table';
import { PAGE_SIZE_DEFAULT } from 'consts/pagination';
import * as userActions from 'modules/api/user';
import Modal, { ModalSize } from 'components/patterns/Modal';
import withCancelRequest from 'components/hocs/withCancelRequest';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import Card from 'components/patterns/Card';
import { CardTheme, CardType } from 'components/patterns/Card/Card.types';
import Search from 'components/patterns/Search';
import plusSvg from 'assets/icons/plus.svg';
import filterSvg from 'assets/icons/filter.svg';
import editSvg from 'assets/icons/edit.svg';
import binSvg from 'assets/icons/bin.svg';
import userSvg from 'assets/icons/user.svg';
import debounce from 'lodash/debounce';
import User from '../User';
import { ActionRenderer, StatusRenderer, ViewRenderer } from '../GridCellRenderer';

class Users extends React.Component {
  constructor() {
    super();

    this.getUsersDebounced = debounce(this.getUsers, 300);
  }

  userTableApi;

  state = {
    filterUserText: '',
    isEditUserDialogActive: false,
    isDeleteUserDialogActive: false,
    selectedUser: {
      userId: '',
      name: '',
    },
  };

  componentDidUpdate = (_prevProps, prevState) => {
    const { filterUserText } = this.state;
    const isUserQueryValid =
      filterUserText.length > 2 || (prevState.filterUserText.length > 2 && filterUserText.length <= 2);

    if (isUserQueryValid) {
      this.getUsersDebounced();
    }
  };

  setUserTableApi = (tableApi) => {
    this.userTableApi = tableApi;
    this.userTableApi.setDatasource(this.userTableDataSource());
  };

  userTableDataSource = () => {
    const { cancelFunctions } = this.props;
    const { filterUserText } = this.state;
    return {
      getRows: async (params) => {
        const pageNu = parseInt(params.startRow / PAGE_SIZE_DEFAULT);
        try {
          const userResponse = await userActions.getUsers(cancelFunctions, filterUserText, pageNu, PAGE_SIZE_DEFAULT);
          params.successCallback(userResponse.users, userResponse.total);
        } catch {} // eslint-disable-line no-empty
      },
    };
  };

  onStatusChange = async (value, userData) => {
    const { cancelFunctions, notifyError, notifySuccess } = this.props;
    try {
      const user = await userActions.changeUserStatus(cancelFunctions, userData.userId, !value);
      notifySuccess({ message: `User ${user.name}'s status updated successfully.` });
      this.updateUserList(user);
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  updateUserList(user) {
    const itemsToUpdate = [];
    this.userTableApi.forEachNode((rowNode) => {
      const item = rowNode.data;
      if (item.userId === user.userId) {
        item.roles = user.roles;
        item.blocked = user.blocked;
        itemsToUpdate.push(item);
      }
    });
    this.userTableApi.updateRowData({ update: itemsToUpdate });
  }

  onEditUser = (userData) => {
    this.setState({
      selectedUser: userData,
      isEditUserDialogActive: true,
    });
  };

  onViewUserRole = async (userData) => {
    const { cancelFunctions, notifyError } = this.props;
    try {
      const user = await userActions.getUserDetail(cancelFunctions, userData.userId);
      this.updateUserList(user);
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  onDeleteUser = (user) => {
    this.setState({
      selectedUser: user,
      isDeleteUserDialogActive: true,
    });
  };

  onConfirmDeleteUser = async (user) => {
    const { cancelFunctions, notifyError, notifySuccess } = this.props;
    try {
      await userActions.deleteUser(cancelFunctions, user.userId);
      notifySuccess({ message: `User deleted successfully.` });
      this.userTableApi.refreshInfiniteCache();

      this.setState({
        isDeleteUserDialogActive: false,
      });
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  columnDefs = [
    {
      headerName: '',
      field: 'userId',
      minWidth: 70,
      maxWidth: 70,
      headerTooltip: 'Action',
      resizable: false,
      cellRenderer: ActionRenderer,
      cellRendererParams: {
        onClick: this.onEditUser,
        icon: editSvg,
        hasPermission: Auth.hasPermission(PermissionsEnum.USERS_EDIT),
      },
      hide: !Auth.hasPermission(PermissionsEnum.USERS_EDIT),
    },
    {
      headerName: 'Status',
      field: 'blocked',
      minWidth: 100,
      headerTooltip: 'Status',
      cellRenderer: StatusRenderer,
      cellRendererParams: {
        onStatusChange: this.onStatusChange,
      },
    },
    { headerName: 'Username', field: 'name', minWidth: 200, headerTooltip: 'Username' },
    { headerName: 'Email', field: 'email', minWidth: 200, headerTooltip: 'Email' },
    {
      headerName: 'Role',
      field: 'roles',
      cellRenderer: ViewRenderer,
      minWidth: 300,
      headerTooltip: 'Role',
      cellRendererParams: {
        onBtnClick: this.onViewUserRole,
      },
      resizable: false,
    },
    {
      headerName: '',
      field: 'userId',
      minWidth: 70,
      maxWidth: 70,
      headerTooltip: 'Action',
      resizable: false,
      cellRenderer: ActionRenderer,
      cellRendererParams: {
        onClick: this.onDeleteUser,
        icon: binSvg,
        hasPermission: Auth.hasPermission(PermissionsEnum.USERS_DELETE),
      },
      hide: !Auth.hasPermission(PermissionsEnum.USERS_DELETE),
    },
  ];

  onCreateUser = () => {
    this.setState({
      selectedUser: {
        userId: '',
        name: '',
      },
      isEditUserDialogActive: true,
    });
  };

  onSaveUser = async (user) => {
    const { cancelFunctions, notifyError, notifySuccess } = this.props;
    try {
      const validMsg = this.isValidUser(user);
      if (validMsg !== '') {
        notifyError({ message: validMsg });
        return;
      }
      if (user.userId) {
        // update user
        await userActions.updateUser(cancelFunctions, user);
        this.userTableApi.refreshInfiniteCache();
        notifySuccess({ message: 'User details updated successfully.' });
      } else {
        // create user
        const newUserObj = await userActions.createUser(cancelFunctions, user);
        if (newUserObj) {
          this.getUsers();
          notifySuccess({ message: 'User created successfully.' });
        }
      }
      this.setState({
        isEditUserDialogActive: false,
      });
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  isValidUser = (user) => {
    let msg = '';
    const validEmailRegex = RegExp(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    );
    if (!user.name || !user.email || (!user.userId && !user.password)) {
      msg = 'Please enter mandatory details.';
    } else if (!validEmailRegex.test(user.email)) {
      msg = 'Please enter valid email address.';
    } else if (!user.userId && user.password.length < 8) {
      msg = 'Password must be at least 8 characters in length.';
    }
    return msg;
  };

  handleDialogToggle = (dialogName) => {
    const isDialogActive = this.state[dialogName];
    this.setState({
      [dialogName]: !isDialogActive,
    });
  };

  onFilterTextChange = (event) => {
    this.setState({ filterUserText: event.target.value });
  };

  getUsers = () => {
    this.userTableApi.setDatasource(this.userTableDataSource());
  };

  render() {
    const { filterUserText, isEditUserDialogActive, isDeleteUserDialogActive, selectedUser } = this.state;

    return (
      <>
        <Modal
          isOpen={isEditUserDialogActive}
          icon={<SVG src={userSvg} className="text-primary" />}
          title={selectedUser.userId ? 'Update user' : 'Create new user'}
          size={ModalSize.MEDIUM}
        >
          <User
            userId={selectedUser.userId}
            onSave={this.onSaveUser}
            onCancel={() => {
              this.handleDialogToggle('isEditUserDialogActive');
            }}
          />
        </Modal>
        <Modal
          isOpen={isDeleteUserDialogActive}
          icon={<SVG src={binSvg} className="text-pinkRed-500" />}
          title="Delete user?"
          actionButtons={
            <>
              <Button
                btnType={ButtonType.PRIMARY}
                btnShape={ButtonShape.NORMAL}
                onClick={() => {
                  this.handleDialogToggle('isDeleteUserDialogActive');
                }}
              >
                Cancel
              </Button>
              <Button
                btnType={ButtonType.DANGER}
                btnShape={ButtonShape.NORMAL}
                onClick={() => {
                  this.onConfirmDeleteUser(selectedUser);
                }}
              >
                Delete
              </Button>
            </>
          }
        >
          <p className="ml-8 body-base text-neutral-950-opacity-60">
            Are you sure you want to delete <strong>{selectedUser.name}</strong>?
          </p>
        </Modal>
        <Card cardType={CardType.FILTERS} cardTheme={CardTheme.WHITE}>
          <div className="row">
            <div className="col-1">
              <div className="filter-card horizontal-vertical-center">
                <SVG src={filterSvg} />
              </div>
            </div>
            <div className="col-9">
              <Search
                value={filterUserText}
                onChange={this.onFilterTextChange}
                placeholder="Search user (type 3 letters)"
              />
            </div>
            <div className="col-12 col-md-2 text-center">
              {Auth.hasPermission(PermissionsEnum.USERS_CREATE) && (
                <Button onClick={this.onCreateUser} btnShape={ButtonShape.NORMAL} btnType={ButtonType.PRIMARY}>
                  <SVG src={plusSvg} />
                  <span>Create user</span>
                </Button>
              )}
            </div>
          </div>
        </Card>
        <div className="row">
          <div className="col-12">
            <Table
              columnDefs={this.columnDefs}
              getTableApi={this.setUserTableApi}
              extraGridOptions={{
                suppressScrollOnNewData: true,
                rowModelType: 'infinite',
                rowHeight: 60,
                infiniteInitialRowCount: 1,
                maxBlocksInCache: 1,
                pagination: true,
                paginationPageSize: PAGE_SIZE_DEFAULT,
                cacheBlockSize: PAGE_SIZE_DEFAULT,
                domLayout: 'autoHeight',
                enableCellTextSelection: true,
              }}
              tableSize={{ tableHeight: TableHeight.AUTO }}
            />
          </div>
        </div>
      </>
    );
  }
}

Users.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  notifySuccess: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
};

export default withCancelRequest(Users);
