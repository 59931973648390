import Header from 'components/common/Header';
import SubNavigation from 'components/common/SubNavigation';
import { PermissionsEnum } from 'consts/permissions';
import Auth from 'modules/Auth';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { DealHealthLabsReport } from '../DealHealthLabsReport/DealHealthLabsReport';

const InsightsHeader: React.FC = () => {
  return (
    <Header
      title="Insights"
      subNavigation={
        <SubNavigation
          tabsConfig={[
            {
              id: 1,
              path: '/insights/dashboard',
              isVisible: true, // TODO: Permission TBD
              name: 'Deal dashboard',
            },
            ...(useHasFeatureAccess(FeatureFlags.PACING_DASHBOARD)
              ? [
                  {
                    id: 2,
                    path: '/insights/pacing',
                    isVisible: true,
                    name: 'Pacing',
                  },
                ]
              : []),
            {
              id: 3,
              path: '/insights/deal-performance',
              isVisible: Auth.hasPermission(PermissionsEnum.DEAL_LEVEL_PERFORMANCE_PAGE_ACCESS),
              name: 'Performance',
            },
            {
              id: 4,
              path: '/insights/revenue',
              isVisible: true, // TODO: Permission TBD
              name: 'Revenue',
            },
            {
              id: 5,
              path: '/insights/diagnostics',
              isVisible: Auth.hasPermission(PermissionsEnum.DIAGNOSTICS_PAGE_ACCESS),
              name: 'Diagnostics',
            },
            {
              id: 6,
              path: '/insights/labs',
              isVisible: useHasFeatureAccess(FeatureFlags.INSIGHTS_LABS),
              name: 'Labs',
            },
          ]}
        />
      }
      actionButton={useHasFeatureAccess(FeatureFlags.INSIGHTS_DEAL_HEALTH_LABS) ? <DealHealthLabsReport /> : null}
    />
  );
};

export default InsightsHeader;
