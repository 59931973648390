import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MotionType } from 'consts/motionType';
import { FrameTag } from 'components/common/types/FrameTag.types';
import { Advertiser, Agency, BrandWithProductCategory } from 'components/common/types/Deal.types';
import { CodeNameModel } from 'components/common/types';
import {
  ConvertStatus,
  Creative,
  CreativeMarket,
  CreativeStatus,
  CreativeType,
  FrameSummary,
} from 'components/common/types/Creative.types';

type ID = string;

export const EMPTY_PRODUCT = { id: '', code: '', name: '' };
export const EMPTY_PRODUCT_BRAND = { id: '', code: '', name: '', productCategory: { code: '', name: '' } };
export const DEFAULT_PRODUCT_CATEGORY = { id: '', code: '', name: 'None' };

export const initialState: Creative = {
  marketId: '',
  deleted: false,
  deletedAt: null,
  convertStatus: '' as ConvertStatus,
  statusCode: '' as CreativeStatus,
  externalId: '',
  dsp: {
    id: '',
    code: '',
    name: '',
  },
  downloadS3URL: '',
  width: 0,
  height: 0,
  productFormat: [],
  uploaded: 0,
  uploadedBy: '',
  uploadedDate: '',
  uploadedTime: '',
  movement: '' as MotionType,
  dealId: [],
  type: '' as CreativeType,
  contentURL: '',
  downloadURL: '',
  thumbnail: '',
  advertiser: { ...EMPTY_PRODUCT },
  brand: { ...EMPTY_PRODUCT_BRAND },
  productCategory: { ...EMPTY_PRODUCT },
  agency: { ...EMPTY_PRODUCT },
  specialist: { ...EMPTY_PRODUCT },
  externalAdvertiserName: '',
  clickThroughUrl: '',
  externalAgencyId: '',
  productCategoriesOptions: [DEFAULT_PRODUCT_CATEGORY],
  additionalProductCategories: [],
  additionalProductCategoriesOptions: [],
  allAdditionalProductCategoriesOptions: [],
  frameSummary: { inclusionListCount: 0, exclusionListCount: 0 },
  moderation: [],
  commercialId: '',
  frameTags: [],
  duration: 0,
  market: [] as CreativeMarket[],
  id: '',
  previewURL: '',
};

export const creativeSlice = createSlice({
  name: 'creative',
  initialState,
  reducers: {
    setDealId: (state, action: PayloadAction<CodeNameModel[]>) => {
      state.dealId = action.payload;
    },
    setFrameSummary: (state, action: PayloadAction<FrameSummary>) => {
      state.frameSummary = action.payload;
    },
    setMovement: (state, action: PayloadAction<MotionType>) => {
      state.movement = action.payload;
    },
    setCreativeDetails: (state, action: PayloadAction<Partial<Creative>>) => {
      return {
        ...state,
        marketId: action.payload.marketId,
        deleted: action.payload.deleted,
        statusCode: action.payload.statusCode,
        externalId: action.payload.externalId,
        dsp: { ...action.payload.dsp },
        width: action.payload.width || 0,
        height: action.payload.height || 0,
        productFormat: action.payload.productFormat,
        uploaded: action.payload.uploaded,
        uploadedBy: action.payload.uploadedBy,
        uploadedDate: action.payload.uploadedDate,
        uploadedTime: action.payload.uploadedTime,
        movement: action.payload.movement,
        dealId: action.payload.dealId,
        contentURL: action.payload.contentURL,
        downloadURL: action.payload.downloadURL,
        thumbnail: action.payload.thumbnail,
        moderation: action.payload.moderation,
        frameSummary: action.payload.frameSummary,
        type: action.payload.type,
        commercialId: action.payload.commercialId,
        externalAdvertiserName: action.payload.externalAdvertiserName || '',
        clickThroughUrl: action.payload.clickThroughUrl || '',
        externalAgencyId: action.payload.externalAgencyId || '',
        advertiser: action.payload.advertiser || { id: '', code: '', name: '' },
        brand: action.payload.brand || { id: '', code: '', name: '' },
        frameTags: action.payload.frameTags,
        productCategory: action.payload.productCategory || DEFAULT_PRODUCT_CATEGORY,
        productCategoriesOptions: action.payload.productCategoriesOptions
          ? [DEFAULT_PRODUCT_CATEGORY, ...action.payload.productCategoriesOptions]
          : [DEFAULT_PRODUCT_CATEGORY],
        agency: action.payload.agency,
        specialist: action.payload.specialist,
        additionalProductCategories: action.payload.additionalProductCategories,
        additionalProductCategoriesOptions: action.payload.additionalProductCategoriesOptions,
        allAdditionalProductCategoriesOptions: action.payload.allAdditionalProductCategoriesOptions,
        market: action.payload.market,
      } as unknown as Creative;
    },
    setAdvertiser: (state, action: PayloadAction<Advertiser>) => {
      state.advertiser = action.payload;
      state.brand = { ...EMPTY_PRODUCT_BRAND };
      state.productCategory = { ...EMPTY_PRODUCT };
      state.additionalProductCategoriesOptions = state.allAdditionalProductCategoriesOptions;
    },
    setBrand: (state, action: PayloadAction<BrandWithProductCategory>) => {
      state.brand = action.payload;
    },
    setAgency: (state, action: PayloadAction<Agency>) => {
      state.agency = action.payload;
    },
    removeAgency: (state) => {
      state.agency = initialState.agency;
    },
    setSpecialist: (state, action: PayloadAction<CodeNameModel>) => {
      state.specialist = action.payload;
    },
    removeSpecialist: (state) => {
      state.specialist = initialState.specialist;
    },
    setCreativeProductCategory: (
      state,
      action: PayloadAction<{
        productCategory: CodeNameModel;
        brandId: string;
      }>,
    ) => {
      state.productCategory = action.payload.productCategory || DEFAULT_PRODUCT_CATEGORY;
      state.additionalProductCategories = state.additionalProductCategories.find(
        (category) => category.id === action.payload.productCategory?.id,
      )
        ? state.additionalProductCategories.filter((category) => category.id !== action.payload.productCategory.id)
        : state.additionalProductCategories;
      state.productCategoriesOptions =
        action.payload.brandId && action.payload.productCategory
          ? [DEFAULT_PRODUCT_CATEGORY, action.payload.productCategory]
          : [DEFAULT_PRODUCT_CATEGORY];
      state.additionalProductCategoriesOptions = !action.payload.brandId
        ? state.allAdditionalProductCategoriesOptions
        : state.allAdditionalProductCategoriesOptions.filter(
            (category) => category.id !== action.payload.productCategory?.id,
          );
    },
    changeProductCategory: (state, action: PayloadAction<CodeNameModel>) => {
      state.productCategory = { ...action.payload };
      state.additionalProductCategories = state.additionalProductCategories.find(
        (category) => category.id === action.payload.id,
      )
        ? state.additionalProductCategories.filter((category) => category.id !== action.payload.id)
        : state.additionalProductCategories;
      state.additionalProductCategoriesOptions = state.allAdditionalProductCategoriesOptions.filter(
        (category) => category.id !== action.payload.id,
      );
    },
    setAdditionalProductCategories: (state, action: PayloadAction<CodeNameModel[]>) => {
      state.additionalProductCategories = action.payload;
    },
    setCommercialId: (state, action: PayloadAction<ID>) => {
      state.commercialId = action.payload;
    },
    addProductFormats: (state, action: PayloadAction<CodeNameModel>) => {
      state.productFormat = state.productFormat
        .filter((format: CodeNameModel) => format.id !== action.payload.id)
        .concat(action.payload);
    },
    deleteProductFormats: (state, action: PayloadAction<ID>) => {
      state.productFormat = state.productFormat.filter((format: CodeNameModel) => format.id !== action.payload);
    },
    setFrameTag: (state, action: PayloadAction<FrameTag>) => {
      state.frameTags = state.frameTags.filter((tag) => tag.id !== action.payload.id).concat(action.payload);
    },
    deleteFrameTag: (state, action: PayloadAction<ID>) => {
      state.frameTags = state.frameTags.filter((tag) => tag.marketTagId !== action.payload);
    },
    resetCreativeDetails: () => {
      return {
        ...initialState,
      };
    },
    resetCommercialId: (state) => {
      state.commercialId = '';
    },
    updateCreativeProperties: (state, action: PayloadAction<Partial<Creative>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  setDealId,
  setFrameSummary,
  setMovement,
  setCreativeDetails,
  setAdvertiser,
  setBrand,
  setAgency,
  removeAgency,
  setSpecialist,
  removeSpecialist,
  changeProductCategory,
  setCreativeProductCategory,
  setAdditionalProductCategories,
  setCommercialId,
  addProductFormats,
  deleteProductFormats,
  setFrameTag,
  deleteFrameTag,
  resetCreativeDetails,
  resetCommercialId,
  updateCreativeProperties,
} = creativeSlice.actions;

export default creativeSlice.reducer;
