import classNames from 'classnames/bind';

import styles from './ContentWithLeftSidebar.pcss';

const cx = classNames.bind(styles);

export enum ContentPadding {
  NO_PADDING = '',
  DEFAULT = 'pt-8 px-14',
}

interface ContentWithLeftSidebarProps {
  content: React.ReactElement;
  sidebar: React.ReactElement;
  contentPadding?: ContentPadding;
}

const ContentWithLeftSidebar: React.FC<ContentWithLeftSidebarProps> = ({ content, sidebar, contentPadding }) => {
  return (
    <div className={cx('layout')} data-test-id="content-with-left-sidebar">
      <div className="h-mainContent bg-neutral-100 border-l border-neutral-300">{sidebar}</div>
      <div className="h-mainContent overflow-auto">
        <div className={contentPadding}>{content}</div>
      </div>
    </div>
  );
};

ContentWithLeftSidebar.defaultProps = {
  contentPadding: ContentPadding.DEFAULT,
};

export default ContentWithLeftSidebar;
