import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import classNames from 'classnames/bind';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { formatNumber } from 'modules/I18N';
import styles from './Table.pcss';

const cx = classNames.bind(styles);

export const TableStyle = {
  NORMAL: 'ag-theme-alpine',
  DARK: 'ag-theme-alpine-dark',
};

export const TableHeight = {
  NORMAL: 'table-height--normal',
  SHORT: 'table-height--short',
  AUTO: 'table-height--auto',
  FIT_CONTAINER: 'table-height--fit',
  FIT_SCREEN: 'table-height--fit-screen',
};

export const TableRowHeight = {
  SMALL: 40,
  NORMAL: 60,
  LARGE: 80,
};

const Table = ({
  columnDefs,
  rowData,
  tableStyle,
  tableSize: { tableHeight = TableHeight.NORMAL, rowHeight = TableRowHeight.NORMAL },
  extraGridOptions,
  getTableApi,
  getColumnApi,
  dataTestId,
  id,
}) => {
  const { localeCode } = useSelector((state) => state.publisher.configuration);
  const [pagination, setPagination] = useState({});
  const [gridApi, setGridApi] = useState();

  const autoFitColumn = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    getTableApi(params.api);
    getColumnApi(params.columnApi);
    autoFitColumn(params);
  };

  const onPaginationChanged = (params) => {
    if (params.api) {
      const currentPage = params.api.paginationGetCurrentPage();
      const totalPages = params.api.paginationGetTotalPages();
      const totalResults = params.api.paginationGetRowCount();
      const pageSize = params.api.paginationGetPageSize();
      const startResult = currentPage * pageSize + 1;
      const endResult = Math.min(startResult + pageSize - 1, totalResults);

      setPagination({ currentPage, totalPages, totalResults, startResult, endResult });
    }
  };

  const baseGridOptions = {
    onGridReady,
    onGridSizeChanged: autoFitColumn,
    headerHeight: 50,
    suppressMovableColumns: true,
    defaultColDef: {
      resizable: true,
      wrapHeaderText: true,
    },
    suppressPaginationPanel: true,
    onPaginationChanged,
  };

  const gridOptions = {
    ...baseGridOptions,
    getRowHeight: () => rowHeight,
    ...extraGridOptions,
  };

  const goToFirstPage = () => {
    gridApi.paginationGoToFirstPage();
  };

  const goToLastPage = () => {
    gridApi.paginationGoToLastPage();
  };

  const goToNextPage = () => {
    gridApi.paginationGoToNextPage();
  };

  const goToPreviousPage = () => {
    gridApi.paginationGoToPreviousPage();
  };

  return (
    <div data-test-id={dataTestId} className={cx('table', tableStyle, tableHeight)} id={id}>
      <AgGridReact gridOptions={gridOptions} columnDefs={columnDefs} rowData={rowData} />
      {gridOptions.pagination && pagination.totalPages > 1 && (
        <div className="ag-paging-panel ag-unselectable">
          <span className="body-sm text-center">
            <span>{formatNumber({ value: pagination.startResult, localeCode })}</span>
            <span> to </span>
            <span>{formatNumber({ value: pagination.endResult, localeCode })}</span>
            <span> of </span>
            <span>{formatNumber({ value: pagination.totalResults, localeCode })}</span>
          </span>
          <span className="ag-paging-page-summary-panel" role="presentation">
            <div
              data-test-id="first-page"
              className={cx('ag-paging-button', { 'ag-disabled': pagination?.currentPage === 0 })}
              role="presentation"
              onClick={goToFirstPage}
            >
              <span className="ag-icon ag-icon-first" unselectable="on" />
            </div>
            <div
              data-test-id="previous-page"
              className={cx('ag-paging-button', { 'ag-disabled': pagination?.currentPage === 0 })}
              role="presentation"
              onClick={goToPreviousPage}
            >
              <span className="ag-icon ag-icon-previous" unselectable="on" />
            </div>
            <span className="body-sm text-center">
              <span> Page </span>
              <span>{formatNumber({ value: pagination.currentPage + 1, localeCode })}</span>
              <span> of </span>
              <span>{formatNumber({ value: pagination.totalPages, localeCode })}</span>
            </span>
            <div
              data-test-id="next-page"
              className={cx('ag-paging-button', {
                'ag-disabled': pagination?.currentPage === pagination?.totalPages - 1,
              })}
              role="presentation"
              onClick={goToNextPage}
            >
              <span className="ag-icon ag-icon-next" unselectable="on" />
            </div>
            <div
              data-test-id="last-page"
              className={cx('ag-paging-button', {
                'ag-disabled': pagination?.currentPage === pagination?.totalPages - 1,
              })}
              role="presentation"
              onClick={goToLastPage}
            >
              <span className="ag-icon ag-icon-last" unselectable="on" />
            </div>
          </span>
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  dataTestId: PropTypes.string,
  id: PropTypes.string,
  columnDefs: PropTypes.arrayOf(
    PropTypes.shape({
      headerName: PropTypes.string,
      field: PropTypes.string,
      headerTooltip: PropTypes.string,
      width: PropTypes.number,
      cellRenderer: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      cellRendererParams: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    }),
  ).isRequired,
  rowData: PropTypes.arrayOf(PropTypes.object),
  tableStyle: PropTypes.string,
  tableSize: PropTypes.shape({
    tableHeight: PropTypes.string,
    rowHeight: PropTypes.number,
  }),
  extraGridOptions: PropTypes.shape({}),
  getTableApi: PropTypes.func,
  getColumnApi: PropTypes.func,
};

Table.defaultProps = {
  dataTestId: '',
  id: '',
  rowData: [],
  tableStyle: TableStyle.NORMAL,
  tableSize: {
    tableHeight: TableHeight.NORMAL,
    rowHeight: TableRowHeight.NORMAL,
  },
  extraGridOptions: {},
  getTableApi: () => undefined,
  getColumnApi: () => undefined,
};

export default Table;
