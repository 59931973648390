import Input from 'lib/Input';
import { FilterDealNameOrIdProps } from './FilterDealNameOrId.types';

const FilterDealNameOrId: React.FC<FilterDealNameOrIdProps> = ({ changeFilters, selectedDealNameOrId }) => {
  return (
    <Input
      name="deal-name-or-id"
      value={selectedDealNameOrId}
      placeholder="Deal ID or name"
      onChange={(value) => changeFilters({ dealNameOrId: value?.toString() })}
      debounceTime={1000}
    />
  );
};

export default FilterDealNameOrId;
