import SingleContent from 'components/common/layout/SingleContent';
import SetupContent from './SetupContent';

const Setup = () => (
  <>
    <SingleContent content={<SetupContent />} />
  </>
);

export default Setup;
