import Pattern from './Pattern';

const Schedule = () => {
  return (
    <div data-test-id="range" className="lg:pl-12 pr-3 py-6">
      <Pattern />
    </div>
  );
};

export default Schedule;
