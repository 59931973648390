import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import infoSvg from 'assets/icons/info.svg';

import InfoMessage, { INFO_TYPE } from 'components/common/Deal/InfoMessage';
import { TooltipDirection } from 'components/patterns/Tooltip';

const FieldWithInfoMessage = ({ field, message, icon, direction, dataTestId }) => {
  return (
    <div className="relative" data-test-id={dataTestId}>
      {field}
      {message ? (
        <div className="absolute top-2 -right-8">
          <InfoMessage type={INFO_TYPE.TOOLTIP} direction={direction} icon={icon}>
            <div className="w-48">{message}</div>
          </InfoMessage>
        </div>
      ) : null}
    </div>
  );
};

FieldWithInfoMessage.propTypes = {
  field: PropTypes.element.isRequired,
  message: PropTypes.string.isRequired,
  icon: PropTypes.node,
  direction: PropTypes.oneOf(Object.values(TooltipDirection)),
  dataTestId: PropTypes.string,
};

FieldWithInfoMessage.defaultProps = {
  icon: <SVG src={infoSvg} className="fill-current text-primary-600" />,
  direction: TooltipDirection.TOP,
  dataTestId: '',
};

export default FieldWithInfoMessage;
