import { formatCurrency, formatNumber, formatPercent } from 'modules/I18N';
import { getDealTypeLabel } from 'utils/getDealTypeLabel';
import { PerformanceSidebarConfig, PerformanceSidebarResponse } from '../PerformanceSidebar.types';

type GetPerformanceSidebarConfig = {
  performanceData: PerformanceSidebarResponse;
  currencyCode: string;
  localeCode: string;
};

export const getPerformanceSidebarConfig = ({
  performanceData,
  currencyCode,
  localeCode,
}: GetPerformanceSidebarConfig): PerformanceSidebarConfig[] => {
  return [
    {
      dataTestId: 'deal-overview',
      title: 'Deal overview',
      data: [
        {
          label: 'Deal ID',
          value: performanceData.dealId,
        },
        {
          label: 'Deal type',
          value: getDealTypeLabel(performanceData.dealType),
        },
        {
          label: 'DSP name',
          value: performanceData.dspName,
        },
        {
          label: 'Trading model',
          value: performanceData.tradingModel,
        },
      ],
      isVisible: !!performanceData.dealId,
      isExpanded: true,
    },
    {
      dataTestId: 'revenue',
      title: 'Revenue',
      data: [
        {
          label: 'Total cost',
          value: formatCurrency({ value: performanceData.totalCost, currencyCode, localeCode }),
        },
        {
          label: 'Supply CPM',
          value: formatCurrency({ value: performanceData.supplyCpm, currencyCode, localeCode }),
        },
        {
          label: 'Demand CPM',
          value: formatCurrency({ value: performanceData.demandCpm, currencyCode, localeCode }),
        },
        {
          label: 'eCPM',
          value: formatCurrency({ value: performanceData.ecpm, currencyCode, localeCode }),
        },
        {
          label: 'Sold revenue',
          value: formatCurrency({ value: performanceData.soldRevenue, currencyCode, localeCode }),
        },
      ],
      isVisible: true,
      isExpanded: true,
    },
    {
      dataTestId: 'trading-effectiveness',
      title: 'Trading effectiveness',
      data: [
        {
          label: 'Bid requests',
          value: formatNumber({ value: performanceData.bidRequests, localeCode }),
        },
        {
          label: 'Bid responses',
          value: formatNumber({ value: performanceData.bidResponses, localeCode }),
        },
        {
          label: 'Bid rate',
          value: formatPercent({ value: performanceData.bidRate / 100, localeCode }),
        },
        {
          label: 'Total trades won',
          value: formatNumber({ value: performanceData.totalTradesWon, localeCode }),
        },
        {
          label: 'Trading win rate',
          value: formatPercent({ value: performanceData.tradedWinRate / 100, localeCode }),
        },
        {
          label: 'Weighted win rate',
          value: formatPercent({ value: performanceData.weightedWinRate / 100, localeCode }),
        },
        {
          label: 'Fill rate',
          value: formatPercent({ value: performanceData.fillRate / 100, localeCode }),
        },
        {
          label: 'Plays (total)',
          value: formatNumber({ value: performanceData.totalPlays, localeCode }),
        },
        {
          label: 'Booked impressions',
          value: formatNumber({ value: performanceData.bookedImpressions, localeCode }),
        },
        {
          label: 'Sold impressions',
          value: formatNumber({ value: performanceData.soldImpressions, localeCode }),
        },
        {
          label: 'Responded impressions',
          value: formatNumber({ value: performanceData.respondedImpressions, localeCode }),
        },
        {
          label: 'Offered impressions',
          value: formatNumber({ value: performanceData.offeredImpressions, localeCode }),
        },
        {
          label: 'Lost impressions',
          value: formatNumber({ value: performanceData.lostImpressions, localeCode }),
        },
        {
          label: 'Lost (plays)',
          value: formatNumber({ value: performanceData.lostPlays, localeCode }),
        },
        {
          label: 'Deal loss rate',
          value: formatPercent({ value: performanceData.dealLossRate / 100, localeCode }),
        },
      ],
      isVisible: true,
      isExpanded: false,
    },
    {
      dataTestId: 'expected-delivery',
      title: 'Expected delivery',
      data: [
        {
          label: 'Unsold trades',
          value: formatNumber({ value: performanceData.unsoldTrades, localeCode }),
        },
        {
          label: 'Unsold impressions',
          value: formatNumber({ value: performanceData.unsoldImpressions, localeCode }),
        },
        {
          label: 'Unsold revenue',
          value: formatNumber({ value: performanceData.unsoldRevenue, localeCode }),
        },
      ],
      isVisible: true,
      isExpanded: false,
    },
  ];
};
