import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux';

import closeSquareSvg from 'assets/icons/close_square.svg';
import Modal from 'components/patterns/Modal';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import { getIsAdServer } from 'store/publisher/selectors';

const TerminateDialog = ({ showTerminateModal, setShowTerminateModal, handleTerminateLine }) => {
  const name = useSelector((state) => state.dealManagement.commonDeal.currentLine.name);
  const sortOrderNumber = useSelector((state) => state.dealManagement.commonDeal.currentLine.sortOrderNumber);
  const isAdServerMarket = useSelector(getIsAdServer);

  const lineDesc = isAdServerMarket ? name : `line ${sortOrderNumber}`;

  return (
    <Modal
      isOpen={showTerminateModal}
      icon={<SVG src={closeSquareSvg} className="text-pinkRed-500" />}
      title={`Terminate ${lineDesc}?`}
      onConfirm={handleTerminateLine}
      actionButtons={
        <>
          <Button
            btnType={ButtonType.PRIMARY}
            btnShape={ButtonShape.NORMAL}
            onClick={() => setShowTerminateModal(false)}
          >
            Cancel
          </Button>
          <Button btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL} onClick={handleTerminateLine}>
            OK
          </Button>
        </>
      }
    >
      <div className="ml-8 body-base text-neutral-950-opacity-60">
        <p className="mb-2">
          Terminating a live line will stop all trading immediately, and you will be unable to undo this.
        </p>
        <p>
          Are you sure you want to terminate <strong>{lineDesc}</strong>?
        </p>
      </div>
    </Modal>
  );
};

TerminateDialog.propTypes = {
  showTerminateModal: PropTypes.bool.isRequired,
  setShowTerminateModal: PropTypes.func.isRequired,
  handleTerminateLine: PropTypes.func.isRequired,
};

export default TerminateDialog;
