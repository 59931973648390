import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { MasonryGallery } from 'components/common/MasonryGallery';
import Lightbox from 'components/patterns/Lightbox';
import { Creative, CreativeId } from 'components/common/types/Creative.types';
import { EmptyCreativesRedirect } from './EmptyCreativesRedirect';

import { CreativesGalleryDispatchToProps } from './CreativesGallery.types';
import { Card } from './Card';

const CreativesGallery: React.FC<CreativesGalleryDispatchToProps> = ({ removeSelectedCreative }) => {
  const selectedCreatives = useSelector((state: Store) => state.pages.contentManagement.creatives.selectedCreatives);
  const [creativeIndex, setCreativeIndex] = useState<number>(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState<boolean>(false);

  const onCloseLightbox = (): void => {
    setIsLightboxOpen(false);
  };

  const onSelectCreativeIndex = (creativeId: CreativeId): void => {
    const selectedCreativeIndex = selectedCreatives.findIndex((creative: Creative) => creative.id === creativeId);

    const isSelectedIndexNumber = selectedCreativeIndex !== -1 && !Number.isNaN(Number(selectedCreativeIndex));

    if (isSelectedIndexNumber) {
      setCreativeIndex(selectedCreativeIndex);
      setIsLightboxOpen(true);
    }
  };

  return (
    <EmptyCreativesRedirect isEmpty={selectedCreatives.length === 0}>
      <MasonryGallery>
        {selectedCreatives.map((creative: Creative) => (
          <div key={creative.id} data-test-id={`creative-${creative.externalId}`}>
            <Card
              creative={creative}
              onRemove={selectedCreatives?.length > 2 ? removeSelectedCreative : undefined}
              onClick={onSelectCreativeIndex}
            />
          </div>
        ))}
        <Lightbox
          isOpen={isLightboxOpen}
          creatives={selectedCreatives}
          creativeIndex={creativeIndex}
          onCreativeIndexChange={onSelectCreativeIndex}
          onClose={onCloseLightbox}
        />
      </MasonryGallery>
    </EmptyCreativesRedirect>
  );
};

export default CreativesGallery;
