import { Routes, Route, Navigate, Outlet } from 'react-router-dom';

import PrivateRoute from 'components/common/Auth/Private';
import ContentWithLeftSidebar, { ContentPadding } from 'components/common/layout/ContentWithLeftSidebar';
import { ChildRoute } from 'components/common/types/Route.types';
import NetworkGroups from './NetworkGroups';
import NetworkPriceDefinitions from './NetworkPriceDefinitions';
import Networks from './Networks';
import Sidebar from './Sidebar';

const ReferenceData: React.FC = () => {
  const childRoutes: ChildRoute[] = [
    {
      id: 1,
      path: 'network-groups',
      main: NetworkGroups,
      pageAccessPermission: undefined,
    },
    {
      id: 2,
      path: 'network-price-definitions',
      main: NetworkPriceDefinitions,
      pageAccessPermission: undefined,
    },
    {
      id: 3,
      path: 'networks',
      main: Networks,
      pageAccessPermission: undefined,
    },
    {
      id: 6,
      path: '*',
      main: () => <Navigate to="network-groups" />,
      pageAccessPermission: undefined,
    },
  ];

  return (
    <Routes>
      <Route
        element={
          <ContentWithLeftSidebar
            sidebar={<Sidebar />}
            content={<Outlet />}
            contentPadding={ContentPadding.NO_PADDING}
          />
        }
      >
        {childRoutes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={<PrivateRoute component={route.main} pageAccessPermission={route.pageAccessPermission} />}
          />
        ))}
      </Route>
    </Routes>
  );
};

export default ReferenceData;
