const getErrorMessageByCode = (code) => {
  switch (code) {
    case 'AU550':
      return 'Your frame target cannot be met. Please revise your chosen targets.';
    case 'AU554':
      return 'There are no frames available for the targeting criteria you have chosen. Please revise your chosen targets.';
    case 'AU500':
      return 'We cannot process your request at this moment. Please try again.';
    default:
      return '';
  }
};

export const apiErrorHandler = (response) => {
  const errorMsgs = [];

  if (response && response.data.errors && response.data.errors.length) {
    for (let i = 0; i < response.data.errors.length; i++) {
      const errorMessage = getErrorMessageByCode(response.data.errors[i].code);
      errorMsgs.push(<p key={i}>{errorMessage || response.data.errors[i].message}</p>);
    }
    return errorMsgs;
  }

  if (response?.status === 500 || response?.status === 503) {
    errorMsgs.push(<p>We cannot process your request at this moment. Please try again.</p>);
    return errorMsgs;
  }

  // ADS no availability error
  if (response?.data?.message) {
    errorMsgs.push(<p key={response.data.message}>{response.data.message}</p>);
  }

  return errorMsgs;
};

export const errorHandler = (error) => {
  const errorMsgs = [];
  if (error?.message) {
    errorMsgs.push(<p>{error.message}</p>);
  }

  return errorMsgs;
};
