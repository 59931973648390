import imageSvg from 'assets/icons/image.svg';
import videoSvg from 'assets/icons/video.svg';

export enum MotionType {
  MOVING = 'MOVING',
  STILL = 'STILL',
}

export const MOTION_TYPE_INFO = {
  STILL: {
    code: MotionType.STILL,
    name: 'Still',
    icon: imageSvg,
  },
  MOVING: {
    code: MotionType.MOVING,
    name: 'Motion',
    icon: videoSvg,
  },
};
