import SVG from 'react-inlinesvg';
import StatusIcon from 'components/common/StatusIcon';
import { getISOStringWithoutTime } from 'utils/dateFormatUtil';
import Tooltip, { TooltipDirection } from 'components/patterns/Tooltip';
import { CreativeType } from 'components/common/types/Creative.types';

import user from 'assets/icons/user.svg';
import upload from 'assets/icons/upload-secondary.svg';
import arrowLeft from 'assets/icons/chevron_left.svg';
import arrowRight from 'assets/icons/chevron_right.svg';
import close from 'assets/icons/close.svg';
import dimensions from 'assets/icons/dimensions.svg';
import zoomIn from 'assets/icons/zoom_in.svg';
import zoomOut from 'assets/icons/zoom_out.svg';

import { LightboxHeaderProps } from './LightboxHeader.types';

const LightboxHeader: React.FC<LightboxHeaderProps> = ({
  creative,
  onClose,
  onNext,
  onPrevious,
  onZoomIn,
  onZoomOut,
  isZoomedIn,
}) => {
  const { statusCode, externalId, uploadedBy, width, height, uploaded, type } = creative;

  const isZoomDisabled = type !== CreativeType.BANNER;
  const isZoomInDisabled = isZoomedIn || isZoomDisabled;
  const isZoomOutDisabled = !isZoomedIn || isZoomDisabled;
  const formattedUploaded = !uploaded || typeof uploaded === 'string' ? uploaded : getISOStringWithoutTime(uploaded);

  return (
    <div className="border-b border-b-neutral-900-opacity-20 rounded-t-md px-6 h-16 absolute w-full top-0 left-0 flex items-center text-neutral-100">
      <div className="w-3/4 flex items-center space-x-10">
        <div className="flex items-center justify-between space-x-3">
          <StatusIcon statusCode={statusCode} />
          <Tooltip tooltip={externalId} direction={TooltipDirection.TOP}>
            <h2 className="sub-header-base text-neutral-950">{externalId}</h2>
          </Tooltip>
        </div>
        {uploadedBy && (
          <div className="flex items-center space-x-1 text-neutral-900">
            <SVG src={user} className="h-4 w-4" />
            <Tooltip tooltip={uploadedBy} direction={TooltipDirection.TOP}>
              <p className="body-base">{uploadedBy}</p>
            </Tooltip>
          </div>
        )}
        <div className="flex items-center space-x-1 text-neutral-900">
          <SVG src={dimensions} className="h-4 w-4" />
          <p className="body-base">
            {width}x{height}
          </p>
        </div>
        {uploaded && (
          <div className="flex items-center space-x-1 text-neutral-900">
            <SVG src={upload} className="h-4 w-4" />
            <p className="body-base">{formattedUploaded}</p>
          </div>
        )}
      </div>
      <div className="w-1/4 flex justify-between">
        {onNext && onPrevious && (
          <div className="flex space-x-3 text-neutral-900">
            <button type="button" onClick={onPrevious}>
              <SVG src={arrowLeft} className="h-6 w-6" />
            </button>
            <button type="button" onClick={onNext}>
              <SVG src={arrowRight} className="h-6 w-6" />
            </button>
          </div>
        )}
        <div className="flex space-x-3 text-neutral-900">
          <button
            className={isZoomInDisabled ? 'opacity-25  cursor-default' : 'opacity-100'}
            type="button"
            onClick={onZoomIn}
            disabled={isZoomInDisabled}
          >
            <SVG src={zoomIn} className="h-6 w-6" />
          </button>
          <button
            className={isZoomOutDisabled ? 'opacity-25 cursor-default' : 'opacity-100'}
            type="button"
            onClick={onZoomOut}
            disabled={isZoomOutDisabled}
          >
            <SVG src={zoomOut} className="h-6 w-6" />
          </button>
        </div>
        <button type="button" onClick={onClose} aria-label="Close Lightbox">
          <SVG src={close} className="h-6 w-6 text-neutral-900" />
        </button>
      </div>
    </div>
  );
};

export default LightboxHeader;
