import { DealType } from 'components/common/types/Deal.types';
import { getPattern } from 'components/pages/DealWithLines/common/Main/Line/Schedule/Pattern/daysInfo';
import { getDateInRequestFormat } from 'utils/dateUtil';
import { getVisualUnitData } from 'utils/framesUtils';
import { transformSelectedSegmentData } from 'utils/transformSelectedSegmentData';
import {
  getFilesOfCurrentLine,
  getFilesToUpload,
  getTargetData,
  getCodes,
  getListData,
} from 'components/pages/DealWithLines/common/transformPostData';

const getProximityPostCodeData = ({ unit, distance, include, tags, files = [] }) => {
  const [file] = files;

  if (!distance || (!tags.length && !file)) return { postcode: null };

  return {
    postcode: {
      ...(tags.length ? { postcode: tags.map((item) => item.code) } : {}),
      ...(file ? { fileName: file.name } : {}),
      ...(file?.fileId ? { fileId: file.fileId } : {}),
      distance,
      unit,
      include,
    },
  };
};

const getProximityPOIData = ({ tags, dataSourceCode, attributeCode, distance, unit, include }) => {
  if (!distance || !dataSourceCode || !attributeCode || !tags.length) return { poi: null };

  return {
    poi: {
      ...(tags.length ? { selectedCodes: tags } : {}),
      dataSourceCode,
      attributeCode,
      distance,
      unit,
      include,
    },
  };
};

const getProximityPointsData = ({ unit, distance, include, latitude, longitude, files = [] }) => {
  const [file] = files;

  if (!distance || (!(latitude && longitude) && !file)) return { points: null };

  return {
    points: {
      ...(latitude && longitude ? { selected: [{ latitude: Number(latitude), longitude: Number(longitude) }] } : {}),
      ...(file ? { fileName: file.name } : {}),
      ...(file?.fileId ? { fileId: file.fileId } : {}),
      distance,
      unit,
      include,
    },
  };
};

export const getProximityData = (proximity) => {
  const proximityRequestData = {
    ...getProximityPostCodeData(proximity.postCode),
    ...getProximityPOIData(proximity.poi),
    ...getProximityPointsData(proximity.points),
  };

  return Object.keys(proximityRequestData).length
    ? { proximity: proximityRequestData }
    : { proximity: { poi: null, points: null, postcode: null } };
};

export const getPoiPointsData = (poiPoints) => {
  return poiPoints.map(
    ({
      geometry,
      id,
      name,
      properties: { address, categoryName, categoryType, dataProvider, mediaOwner, postalCode, visibility },
    }) => ({
      geometry,
      id,
      include: visibility.include,
      name,
      properties: [
        {
          address: address || '',
          categoryName: categoryName || '',
          categoryType: categoryType || '',
          dataProvider: dataProvider || '',
          latitude: geometry.coordinates[0],
          longtitude: geometry.coordinates[1],
          mediaOwner: mediaOwner || '',
          poiId: id,
          poiName: name,
          postalCode: postalCode || '',
        },
      ],
      radius: visibility.radius,
    }),
  );
};

export const getPlannerPoiData = (plannerPoi) => {
  return plannerPoi.map(({ categoryName, categoryType, poiPoints, visibility }) => ({
    categoryName,
    categoryType,
    include: visibility.include,
    poiPoints: getPoiPointsData(poiPoints),
    radius: visibility.radius,
  }));
};

export const getOpenStreetMapPoiData = (openStreetMapPoi) => {
  return openStreetMapPoi.map(({ name, poiPoints, visibility }) => ({
    name,
    include: visibility.include,
    poiPoints: getPoiPointsData(poiPoints),
    radius: visibility.radius,
  }));
};

export const getProximity = (proximityData) => {
  const proximity = {
    openStreetMapPoi: null,
    plannerPoi: null,
    poi: null,
    points: null,
    postcode: null,
  };

  if (proximityData.plannerPoi.length > 0) {
    proximity.plannerPoi = getPlannerPoiData(proximityData.plannerPoi);
  }

  if (proximityData.openStreetMapPoi.length > 0) {
    proximity.openStreetMapPoi = getOpenStreetMapPoiData(proximityData.openStreetMapPoi);
  }

  return {
    proximity: {
      ...proximity,
      ...getProximityData(proximityData).proximity,
    },
  };
};

/* eslint-disable sonarjs/cognitive-complexity */
export const getLineData = ({
  id,
  name,
  startDate,
  endDate,
  cpm,
  impressions,
  budget,
  sot,
  sotCeiling,
  sotFloor,
  sweep,
  frames,
  countries,
  counties,
  cities,
  streets,
  postCodes,
  environments,
  productFormats,
  selectedDays,
  patternLength,
  tags,
  listFiles,
  routeFrameCodes,
  visualUnitCodes,
  visualUnitFiles,
  proximity,
  venueTaxonomies,
  secondaryAudience,
  indexOptimisation,
  ceilingSot = null,
  floorSot = null,
  mediaOwners = null,
  isAdServerMarket,
  maxCPM = null,
}) => {
  return {
    ...(id ? { internalId: id } : {}),
    ...getTargetData({ impressions, budget, sot, sotCeiling, sotFloor, sweep, ceilingSot, floorSot, isAdServerMarket }),
    ...(frames ? { frameCount: frames } : {}),
    ...(countries?.length ? { countryCode: getCodes(countries) } : {}),
    ...(counties?.length ? { countyCode: getCodes(counties) } : {}),
    ...(cities?.length ? { townCode: getCodes(cities) } : {}),
    ...(streets?.length ? { address: getCodes(streets) } : {}),
    ...(postCodes?.length ? { postcode: getCodes(postCodes) } : {}),
    ...(environments.length ? { environmentCode: getCodes(environments) } : {}),
    ...(productFormats?.length ? { productFormatCode: getCodes(productFormats) } : {}),
    ...(Object.values(selectedDays).length
      ? { pattern: { patternLength, pattern: getPattern(selectedDays, startDate), patternType: 1 } }
      : {}),
    ...(tags.length ? { tags: tags.map(({ code: tagCode, include }) => ({ tagCode, include })) } : {}),
    ...getListData(routeFrameCodes, listFiles, true),
    ...getVisualUnitData(visualUnitCodes, visualUnitFiles, true),
    ...(name ? { dealName: name } : {}),
    startDate: getDateInRequestFormat(startDate, [0, 0, 0, 0]),
    endDate: getDateInRequestFormat(endDate, [23, 59, 59, 999]),
    ...(cpm && isAdServerMarket ? { floorCPM: cpm } : {}),
    ...getProximity(proximity),
    ...(venueTaxonomies.length ? { venueTaxonomyEnumerationIds: venueTaxonomies } : {}),
    ...(indexOptimisation?.length ? { indexOptimisation } : {}),
    ...(secondaryAudience?.length ? { secondaryAudience } : {}),
    ...(mediaOwners?.length ? { mediaOwners } : {}),
    ...(maxCPM ? { maxCPM } : {}),
  };
};
/* eslint-enable sonarjs/cognitive-complexity */

export const getDealData = (
  {
    internalId,
    overriddenCampaignId,
    dealName,
    cpm,
    dealType: dealTypeCode,
    dealPriority,
    auctionModel,
    bookingStatusCode,
    advertiser,
    brand,
    productCategory,
    dsp,
    agency,
    specialist,
    salesPerson,
    salesTeam,
    externalReference,
    syncWithDsp,
    dspSeatId,
    enableCreativeSubmissionInBidStream,
    enableOpenMarketplace,
    hasAdsDealLevelCPMEnabled,
  },
  isAdServerMarket,
) => {
  return {
    dealTypeCode,
    dealPriority,
    bookingStatusCode,
    syncWithDsp,
    enableCreativeSubmissionInBidStream,
    enableOpenMarketplace,
    advertiserCode: advertiser?.code,
    brandCode: brand?.code,
    productCategoryCode: productCategory?.code,
    ...(cpm && (hasAdsDealLevelCPMEnabled || !isAdServerMarket) ? { floorCPM: cpm } : {}),
    dspCode: dsp?.code,
    ...(internalId ? { internalId } : {}),
    ...(specialist?.code ? { specialistCode: specialist.code } : {}),
    ...(agency?.code ? { agencyCode: agency.code } : {}),
    ...(salesPerson ? { salesPersonName: salesPerson.name, salesPersonCode: salesPerson.code } : {}),
    ...(salesTeam ? { salesTeamName: salesTeam.name, salesTeamCode: salesTeam.code } : {}),
    ...(dealTypeCode === DealType.NON_GUARANTEED_FLOOR_PRICE ? { auctionModel } : {}),
    ...(dealName ? { name: dealName } : {}),
    ...(overriddenCampaignId ? { overriddenCampaignId } : {}),
    ...(externalReference ? { externalReference } : {}),
    ...(syncWithDsp && dspSeatId ? { dspSeatId } : {}),
  };
};

export const getDealWithLineFormData = (formData, isAdServerMarket) => {
  const filesToUpload = getFilesToUpload(getFilesOfCurrentLine(formData.currentLine));

  const requestData = new FormData();

  Object.entries(filesToUpload).forEach(([fileName, file]) => {
    requestData.append(fileName, file);
  });

  const segment = transformSelectedSegmentData(formData.currentLine.segment);

  const deal = getDealData(formData, isAdServerMarket);
  const dealLine = getLineData({
    ...formData.currentLine,
    cpm: deal.floorCPM ? null : formData.currentLine.cpm,
    secondaryAudience: segment ? [segment] : null,
    isAdServerMarket,
  });

  requestData.append(
    'data',
    JSON.stringify({
      ...deal,
      dealLine,
    }),
  );

  return requestData;
};

export const getLineFormData = (formData, isAdServerMarket, isCpmCampaignLevel) => {
  const filesToUpload = getFilesToUpload(getFilesOfCurrentLine(formData.currentLine));

  const requestData = new FormData();

  Object.entries(filesToUpload).forEach(([fileName, file]) => {
    requestData.append(fileName, file);
  });

  const segment = transformSelectedSegmentData(formData.currentLine.segment);

  requestData.append(
    'data',
    JSON.stringify({
      ...getLineData({
        ...formData.currentLine,
        cpm: isCpmCampaignLevel ? undefined : formData.currentLine.cpm,
        secondaryAudience: segment ? [segment] : null,
        isAdServerMarket,
      }),
    }),
  );

  return requestData;
};
