import { ICellRendererParams } from 'ag-grid-community';
import tableGroupLine from 'assets/icons/table-group-line.svg';
import SVG from 'react-inlinesvg';

type UnitCellRendererType = (params: ICellRendererParams) => JSX.Element;

const UnitCellRenderer: UnitCellRendererType = ({ value }) => {
  return (
    <>
      <SVG src={tableGroupLine} className="absolute left-6 -top-6.5" />
      <span className="text-ellipsis overflow-hidden">{value}</span>
    </>
  );
};

export default UnitCellRenderer;
