import { SWEEP_OPTIONS_CODES, SWEEP_OPTIONS_NAMES, SWEEP_OPTIONS_INFO } from 'consts/deal';
import { DealPriority, DealType, SweepOptions } from 'components/common/types/Deal.types';

const getParsedSweepOptions = () => {
  const result = {};

  Object.keys(SweepOptions).forEach((option) => {
    result[option] = {
      name: SWEEP_OPTIONS_NAMES[option],
      code: SWEEP_OPTIONS_CODES[option],
      info: SWEEP_OPTIONS_INFO[option],
    };
  });

  return result;
};

const sweepOptions = getParsedSweepOptions();

export const getSweepKeyByCode = (code) => Object.keys(sweepOptions).find((key) => sweepOptions[key]?.code === code);

export const getSweepOptions = (isAdServerMarket) => {
  const options = { ...sweepOptions };

  if (isAdServerMarket) {
    delete options[SweepOptions.SWEEP_TO_DAYPART];
  }

  if (!isAdServerMarket) {
    delete options[SweepOptions.SWEEP_WEIGHTED_DISTRIBUTION];
  }

  return options;
};

export const getDefaultSweep = (dealType, hasSweepTargetingEnabled, hasNonGuaranteedExtendedTargetEnabled) =>
  hasSweepTargetingEnabled && (dealType === DealType.GUARANTEED || hasNonGuaranteedExtendedTargetEnabled)
    ? SweepOptions.SWEEP_TO_FILL
    : '';

export const getDealPriority = (dealPriority, dealTypeCode) => {
  if (dealPriority) return dealPriority;

  return dealTypeCode === DealType.GUARANTEED ? DealPriority.PREFERRED : DealPriority.STANDARD;
};
