import Card from 'components/patterns/Card';
import { CardTheme, CardType } from 'components/patterns/Card/Card.types';
import { useSelector } from 'react-redux';
import Status from 'lib/Status';

import { Store } from 'components/common/types/Store.types';
import { Creative } from 'components/common/types/Creative.types';
import { NumberOfStatusesOfSelectedCreatives } from './CreativesStatusesSummary.types';

export const CreativesStatusesSummary: React.FC = () => {
  const selectedCreatives = useSelector((state: Store) => state.pages.contentManagement.creatives.selectedCreatives);

  const numberOfStatusesOfSelectedCreatives = selectedCreatives.reduce(
    (acc: NumberOfStatusesOfSelectedCreatives, curr: Creative) => {
      acc[curr.statusCode.toLowerCase() as keyof NumberOfStatusesOfSelectedCreatives] += 1;
      return acc;
    },
    {
      approved: 0,
      pending: 0,
      rejected: 0,
    },
  );

  return (
    <Card cardTheme={CardTheme.WHITE} cardType={CardType.TRANSPARENT}>
      <div data-test-id="statuses-summary" className="p-4 flex justify-evenly text-neutral-100">
        <div
          data-test-id="approved"
          className="relative flex flex-col items-center p-2.5 rounded-md bg-green-400-opacity-20"
        >
          <p aria-label="Approved" className="mb-1 sub-header-sm text-green">
            {numberOfStatusesOfSelectedCreatives.approved}
          </p>
          <Status initial="A" backgroundColour="bg-green" />
        </div>
        <span className="text-neutral-300 sub-header-lg self-center">&bull;</span>
        <div
          data-test-id="pending"
          className="relative flex flex-col items-center p-2.5 rounded-md bg-yellow-500-opacity-20"
        >
          <p aria-label="Pending" className="mb-1 sub-header-sm text-yellow">
            {numberOfStatusesOfSelectedCreatives.pending}
          </p>
          <Status initial="P" backgroundColour="bg-yellow" />
        </div>
        <span className="text-neutral-300 sub-header-lg self-center">&bull;</span>
        <div
          data-test-id="rejected"
          className="relative flex flex-col items-center p-2.5 rounded-md bg-pinkRed-600-opacity-20"
        >
          <p aria-label="Rejected" className="mb-1 sub-header-sm text-pinkRed-500">
            {numberOfStatusesOfSelectedCreatives.rejected}
          </p>
          <Status initial="R" backgroundColour="bg-pinkRed" />
        </div>
      </div>
    </Card>
  );
};
