import { createAsyncThunk } from '@reduxjs/toolkit';
import { createCreativeCommercial, updateCreativeCommercial } from 'modules/api/content';
import { CancelFunctions, CodeNameModel } from 'components/common/types';
import { ThunkApiConfig } from 'components/common/types/Redux.types';
import { setCommercialId, setCreativeProductCategory } from './reducer';

type SetProductCategoryProps = {
  cancelFunctions: CancelFunctions;
  brandId: string;
  productCategory: CodeNameModel;
};

export const setProductCategory = createAsyncThunk<void, SetProductCategoryProps, ThunkApiConfig>(
  'creative/setProductCategory',
  async ({ cancelFunctions, brandId, productCategory }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    if (!brandId) {
      dispatch(
        setCreativeProductCategory({
          productCategory: {
            id: '',
            code: '',
            name: '',
          },
          brandId,
        }),
      );

      return;
    }

    try {
      dispatch(
        setCreativeProductCategory({
          productCategory: productCategory || null,
          brandId,
        }),
      );

      const { commercialId, advertiser, marketId } = getState().creative;

      const advertiserId = advertiser ? advertiser.id : null;

      if (commercialId) {
        await updateCreativeCommercial(cancelFunctions, commercialId, advertiserId, brandId, productCategory?.id);
      } else {
        const { id } = await createCreativeCommercial(
          cancelFunctions,
          marketId,
          advertiserId,
          brandId,
          productCategory?.id,
        );

        dispatch(setCommercialId(id));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
);
