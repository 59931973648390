/* eslint-disable @typescript-eslint/ban-ts-comment */

type UrlFunction = (...args: unknown[]) => string;
interface UrlsConfig {
  [key: string]: UrlFunction;
}

export const mockURL = (url: string | Function): string => {
  if (typeof url === 'function') return mockURL(url());

  try {
    const parsedUrl = new URL(url);

    return `*${parsedUrl.pathname}`;
    // eslint-disable-next-line no-empty
  } catch {}

  return url.split('?').slice(0, 1).join();
};

export class MockURL {
  private readonly urls: UrlsConfig;

  constructor(URLS: () => UrlsConfig) {
    this.urls = URLS();
    this.initialize();
  }

  public get URLS(): UrlsConfig {
    return this.urls;
  }

  private initialize = (): void => {
    if (this.urls) {
      return;
    }

    Object.keys(this.urls).forEach((urlsKey: string) => {
      if (urlsKey) {
        // @ts-expect-error
        this[urlsKey] = () => mockURL(this.urls[urlsKey]());
      }
    });
  };
}

/* eslint-enable @typescript-eslint/ban-ts-comment */
