import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreativeStatus, CreativeType, Panel } from 'components/common/types/Creative.types';
import { Advertiser, BrandWithProductCategory, Dsp } from 'components/common/types/Deal.types';
import { FrameOrdering } from 'components/common/types/FrameOrdering.types';
import { VisualUnitGroup } from 'components/pages/ContentManagement/CreativeSetDetails/VisualUnitGroups/VisualUnitGroups.types';

type SelectedPanelId = string;
type IsEditingDisabled = boolean;
type Code = string;

type Filters = {
  dealId: { code: string; name: string } | null;
  environment: string;
  visualUnitCode: string;
  frameCode: string;
  location: string;
  framesOrder: FrameOrdering;
};

export type CreativeSetDetailsSlice = {
  id: string;
  code: Code;
  status: string;
  visualUnitIds: string[];
  panels: Panel[];
  dsp: Dsp | null;
  advertiser: Advertiser | null;
  brand: BrandWithProductCategory | null;
  filters: Filters;
  isPanelView: boolean;
  isNewSet: boolean;
  isEditingDisabled: IsEditingDisabled;
  selectedPanelId: SelectedPanelId;
};

type SelectedCreativePayload = {
  creative: {
    id: string;
    previewUrl: string;
    status: CreativeStatus;
    thumbnailUrl: string;
    type: CreativeType;
  };
  dsp: Dsp | null;
  advertiser: Advertiser | null;
  brand: BrandWithProductCategory | null;
};

export const initialState: CreativeSetDetailsSlice = {
  id: '',
  code: '',
  status: '',
  visualUnitIds: [],
  panels: [],
  isPanelView: false,
  isNewSet: true,
  isEditingDisabled: false,
  filters: {
    dealId: null,
    environment: '',
    visualUnitCode: '',
    frameCode: '',
    location: '',
    framesOrder: FrameOrdering.UNORDERED,
  },
  dsp: null,
  advertiser: null,
  brand: null,
  selectedPanelId: '',
};

export const creativeSetDetailsSlice = createSlice({
  name: 'creativeSetDetails',
  initialState,
  reducers: {
    onFilterChange: (state, action: PayloadAction<Partial<Filters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    selectVisualUnit: (state, action: PayloadAction<Partial<VisualUnitGroup>>) => {
      return {
        ...state,
        ...action.payload,
        isPanelView: true,
      };
    },
    resetCreativeSetDetails: () => {
      return { ...initialState };
    },
    changeSelectedPanelId: (state, action: PayloadAction<SelectedPanelId>) => {
      state.selectedPanelId = action.payload;
    },
    removeAllCreatives: (state) => {
      state.panels = state.panels.map(({ creative, ...panelInfo }) => panelInfo) as Panel[];
      state.dsp = null;
      state.advertiser = null;
      state.brand = null;
    },
    selectCreative: (state, action: PayloadAction<Partial<SelectedCreativePayload>>) => {
      state.panels = state.panels.map((panel) => ({
        ...panel,
        creative: panel.id === state.selectedPanelId ? action.payload.creative : panel.creative,
      })) as Panel[];
      state.selectedPanelId = '';
      state.dsp = action.payload.dsp ?? state.dsp;
      state.advertiser = action.payload.advertiser ?? state.advertiser;
      state.brand = action.payload.brand ?? state.brand;
    },
    changeIsEditingDisabled: (state, action: PayloadAction<IsEditingDisabled>) => {
      state.isEditingDisabled = action.payload;
    },
    fetchUpdateSetDetails: (state, action: PayloadAction<Partial<CreativeSetDetailsSlice>>) => {
      return { ...state, ...action.payload, isEditingDisabled: false, isNewSet: false, isPanelView: true };
    },
    changeCode: (state, action: PayloadAction<Code>) => {
      return { ...state, code: action.payload };
    },
  },
});

export const {
  fetchUpdateSetDetails,
  changeIsEditingDisabled,
  changeCode,
  onFilterChange,
  changeSelectedPanelId,
  selectCreative,
  selectVisualUnit,
  removeAllCreatives,
  resetCreativeSetDetails,
} = creativeSetDetailsSlice.actions;

export default creativeSetDetailsSlice.reducer;
