import PropTypes from 'prop-types';
import cx from 'classnames';

const SingleContent = ({ content, isOverflowHidden }) => {
  return (
    <div
      className={cx('py-4 px-14', {
        'overflow-hidden': isOverflowHidden,
      })}
    >
      {content}
    </div>
  );
};

SingleContent.propTypes = {
  content: PropTypes.element.isRequired,
  isOverflowHidden: PropTypes.bool,
};

SingleContent.defaultProps = {
  isOverflowHidden: false,
};

export default SingleContent;
