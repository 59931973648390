export enum DealSummaryCardSectionLineType {
  HEADER = 'header',
  SUBHEADER = 'subheader',
  SUBHEADER_SECONDARY = 'subheaderSecondary',
}

interface CardSectionLine {
  label: string;
  value: string | number;
  lineType: DealSummaryCardSectionLineType;
}

export interface DealSummaryCardSectionProps {
  id?: string;
  lines?: CardSectionLine[];
  children?: React.ReactNode;
  isReadOnly?: boolean;
}
