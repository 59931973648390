import SVG from 'react-inlinesvg';

import imageIcon from 'assets/icons/image.svg';
import videoIcon from 'assets/icons/video.svg';
import CreativeCountProps from './CreativeCount.types';

const CreativeCount: React.FC<CreativeCountProps> = ({ totalCreatives, creativeCountSummary, theme = 'DARK' }) => {
  const MAX_SELECTED_CREATIVES = 100;

  const textColor = {
    LIGHT: 'text-neutral-100',
    DARK: 'text-neutral-950-opacity-60',
  };

  const iconColor = {
    LIGHT: 'text-neutral-100',
    DARK: 'text-neutral-950-opacity-30',
  };

  return (
    <ul className={`flex space-x-2 justify-between items-center body-base ${textColor[theme]}`}>
      <li data-test-id="creatives-selected-count">
        {totalCreatives}/{MAX_SELECTED_CREATIVES} creatives selected
        <span className={`ml-2 ${iconColor[theme]}`}>&bull;</span>
      </li>
      <li className="flex items-center" data-test-id="image-count">
        <SVG className={`fill-current ${iconColor[theme]} mr-2 inline`} src={imageIcon} />
        <span>{creativeCountSummary.staticCreatives}</span>
      </li>
      <li className="flex items-center" data-test-id="video-count">
        <SVG className={`fill-current ${iconColor[theme]} mr-2 inline`} src={videoIcon} />
        <span>{creativeCountSummary.motionCreatives}</span>
      </li>
    </ul>
  );
};

export default CreativeCount;
