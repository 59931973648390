import { Fragment, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import cx from 'classnames';

import { notifyError } from 'store/notification/reducer';
import { changeSelectedDays } from 'store/dealManagement/actions';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import dayIcon from 'assets/icons/sun.svg';
import clockIcon from 'assets/icons/clock.svg';
import plusIcon from 'assets/icons/plus.svg';
import minusIcon from 'assets/icons/minus.svg';
import BlankCell from './BlankCell';
import {
  DAYS_IN_WEEK,
  LAST_ISO_WEEK_DAY,
  getISOWeekDay,
  handleSelectingDays,
  getDaysList,
  getVisibleDaysFromSelectedDays,
} from '../daysInfo';

const Title = ({ text }) => {
  return <p className="h-10 flex items-center justify-center sub-header-base text-neutral-900">{text}</p>;
};

Title.propTypes = {
  text: PropTypes.string.isRequired,
};

// eslint-disable-next-line  sonarjs/cognitive-complexity
const Days = ({ readOnly }) => {
  const dispatch = useDispatch();
  const [visibleDays, setVisibleDays] = useState([]);
  const startDate = useSelector((state) => state.dealManagement.commonDeal.currentLine.startDate);
  const endDate = useSelector((state) => state.dealManagement.commonDeal.currentLine.endDate);
  const selectedDays = useSelector((state) => state.dealManagement.commonDeal.currentLine.selectedDays);

  const listOfDays = useMemo(() => getDaysList(startDate, endDate), [startDate, endDate]);
  const listOfDaysIds = useMemo(() => listOfDays.map(({ id }) => id), [listOfDays]);

  const addDays = () => {
    if (visibleDays.length === listOfDays.length) {
      dispatch(notifyError({ message: 'Can not add more Patterns' }));
      return;
    }
    const endPosition = visibleDays.length + LAST_ISO_WEEK_DAY;
    setVisibleDays(listOfDays.slice(0, endPosition + 1));
  };

  const removeDays = () => {
    if (visibleDays.length <= DAYS_IN_WEEK) return;
    const weekDayOfLastDay = getISOWeekDay(visibleDays[visibleDays.length - 1].date);
    const daysInFirstWeek = LAST_ISO_WEEK_DAY - getISOWeekDay(startDate);
    const daysToRemove =
      getISOWeekDay(startDate) <= weekDayOfLastDay
        ? weekDayOfLastDay - getISOWeekDay(startDate)
        : weekDayOfLastDay + daysInFirstWeek + 1;
    setVisibleDays(listOfDays.slice(0, visibleDays.length - daysToRemove - 1));
  };

  const selectDay = (event, dayPosition) => {
    dispatch(changeSelectedDays(handleSelectingDays(event, selectedDays, listOfDays[dayPosition], readOnly)));
  };

  const getCellStyle = (id) => {
    const isSelected = Boolean(selectedDays[id]);
    const currentSelected = selectedDays[id]?.currentSelection;

    if (isSelected && currentSelected) {
      return 'flex justify-center items-center bg-primary text-neutral-50 rounded-md';
    }
    if (isSelected && !currentSelected) {
      return ' flex items-center justify-center bg-purple-600 rounded-md text-neutral-50';
    }
    return '';
  };

  const getCellText = (id, date) => {
    const isSelected = Boolean(selectedDays[id]);
    const cellDate = date.getDate().toString().padStart(2, 0);

    if (isSelected && selectedDays[id].isFullDay) {
      return (
        <>
          <span>{cellDate}</span>
          <SVG src={dayIcon} className="w-5 ml-1 mt-0.5" />
        </>
      );
    }
    if (isSelected) {
      return (
        <>
          <span>{cellDate}</span>
          <SVG src={clockIcon} className="w-4 ml-1" />
        </>
      );
    }
    return cellDate;
  };

  useEffect(() => {
    setVisibleDays(listOfDays.slice(0, DAYS_IN_WEEK));
  }, [JSON.stringify(listOfDaysIds)]);

  useEffect(() => {
    if (!visibleDays.length) return;
    const overLappingSelectedDays = {};
    visibleDays.forEach(({ id }) => {
      if (!selectedDays[id]) return;
      overLappingSelectedDays[id] = selectedDays[id];
    });
    dispatch(changeSelectedDays(overLappingSelectedDays));
  }, [visibleDays]);

  useEffect(() => {
    dispatch(
      changeDealCurrentLineData({
        patternLength: Math.ceil(
          getVisibleDaysFromSelectedDays(selectedDays, startDate, listOfDays).length / DAYS_IN_WEEK,
        ),
      }),
    );
  }, [selectedDays]);

  useEffect(() => {
    const initialVisibleDays = getVisibleDaysFromSelectedDays(selectedDays, startDate, listOfDays);
    if (!initialVisibleDays.length) return;
    setVisibleDays(initialVisibleDays);
  }, []);

  return (
    <div data-test-id="days-container" className={readOnly ? 'readOnly readOnlyOpacity' : ''}>
      <div className="grid gap-2 border border-b-0 border-neutral-300 rounded-t-md grid-cols-8 bg-neutral-200 px-2 ">
        <Title text="M" />
        <Title text="T" />
        <Title text="W" />
        <Title text="T" />
        <Title text="F" />
        <Title text="S" />
        <Title text="S" />
        <button
          type="button"
          data-test-id="days-add-button"
          className={cx(
            'w-7 h-7 place-self-center flex items-center justify-center text-neutral border border-neutral-300 rounded-md',
            {
              readOnly,
              readOnlyOpacity: readOnly,
            },
          )}
          onClick={readOnly ? null : addDays}
        >
          <SVG src={plusIcon} className="w-4 h-4 text-neutral-600" />
        </button>
      </div>

      <div className="grid grid-cols-8 gap-2 bg-neutral-50 text-center text-neutral-950-opacity-60 border gap-y-2 border-neutral-300 rounded-b-md py-2 px-2">
        <BlankCell span={getISOWeekDay(startDate)} />
        {visibleDays.map(({ date, id }, index) => {
          const isEndOfWeek = index !== 0 && (index + getISOWeekDay(startDate)) % DAYS_IN_WEEK === 0;
          return (
            <Fragment key={id}>
              {isEndOfWeek ? <p>&nbsp;</p> : null}
              <button
                data-test-id={`days-button-${id}`}
                type="button"
                onClick={(e) => {
                  selectDay(e, index);
                }}
                className={`${getCellStyle(id)} h-8 w-12 body-base place-self-center text-center`}
              >
                {getCellText(id, date)}
              </button>
            </Fragment>
          );
        })}
        <BlankCell span={LAST_ISO_WEEK_DAY - getISOWeekDay(visibleDays[visibleDays.length - 1]?.date)} />
        <button
          type="button"
          data-test-id="days-remove-button"
          className="bg-neutral-50 flex border items-center place-self-center justify-center text-neutral border-neutral-300 rounded-md w-7 h-7"
          onClick={readOnly ? null : removeDays}
        >
          <SVG src={minusIcon} className="text-neutral-600" />
        </button>
      </div>
    </div>
  );
};

Days.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};

export default Days;
