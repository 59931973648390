export const NotificationMessages = {
  INVALID_UPLOAD: 'Please select valid file.',
  DUPLICATE_UPLOAD: 'Duplicate file',
  MAX_FILE: 'Maximum 2 files to be uploaded',
};

export const NOTIFICATION_TIMEOUT = {
  DEFAULT: 2000,
  LONG: 10000,
  NEVER: 999999,
};
