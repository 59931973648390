import PropTypes from 'prop-types';

import { DOUBLE_DASH } from 'consts/placeholders';
import { NetworkModel } from 'components/common/prop-types/inventory';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';

import NetworkInfo from './NetworkInfo';
import { Button as ReadMoreButton } from '../ReadMoreSections';

const Networks = ({ networks, toggleReadMore, maxRecordsToShow }) => {
  return (
    <div>
      <h2 className="sub-header-base mb-8">Networks</h2>
      <div className="pl-2" data-test-id="network-container">
        <Timeline>
          {networks.length ? (
            networks
              .slice(0, maxRecordsToShow)
              .map((network) => <NetworkInfo key={network.networkUuid} network={network} />)
          ) : (
            <TimelineItem title={<p className="body-base">{DOUBLE_DASH}</p>} />
          )}
        </Timeline>
        {networks.length > 3 ? <ReadMoreButton onClick={toggleReadMore} /> : null}
      </div>
    </div>
  );
};

Networks.propTypes = {
  networks: PropTypes.arrayOf(NetworkModel),
  toggleReadMore: PropTypes.func,
  maxRecordsToShow: PropTypes.number.isRequired,
};

Networks.defaultProps = {
  networks: [],
  toggleReadMore: () => null,
};

export default Networks;
