import Input from 'lib/Input';
import { FilterInternalIdProps } from './FilterInternalId.types';

const FilterInternalId: React.FC<FilterInternalIdProps> = ({ changeFilters, selectedInternalId }) => {
  return (
    <Input
      name="internal-id"
      value={selectedInternalId}
      placeholder="Select internal ref"
      onChange={(value) => changeFilters({ internalId: value?.toString() })}
      debounceTime={1000}
    />
  );
};

export default FilterInternalId;
