import { CardProps, CardTheme } from './Card.types';

const CardThemeStyles = {
  primary: {
    headerText: 'text-neutral-50',
    card: 'bg-primary-950',
    border: 'border-primary-950',
    shadow: 'shadow-sm',
    rounded: 'rounded-xl',
    margin: 'm-0.5',
  },
  secondary: {
    headerText: 'text-primary-600',
    card: 'bg-primary-100',
    border: 'border-primary-100',
    shadow: 'shadow-sm',
    rounded: 'rounded-xl',
    margin: 'm-0.5',
  },
  default: {
    headerText: 'text-neutral-600',
    card: 'bg-neutral-100',
    border: 'border-neutral-300',
    shadow: 'shadow-sm',
    rounded: 'rounded-xl',
    margin: 'm-0.5',
  },
  default_not_rounded: {
    headerText: 'text-neutral-600',
    card: 'bg-neutral-100',
    border: 'border-neutral-300',
    shadow: 'shadow-none',
    rounded: 'rounded-none',
    margin: 'm-0',
  },
  transparent: {
    headerText: 'text-primary-600',
    card: 'bg-transparent',
    border: 'border-none',
    shadow: 'shadow-none',
    rounded: 'rounded-xl',
    margin: 'm-0.5',
  },
};

const Card: React.FC<CardProps> = ({
  id = '',
  header = '',
  footer,
  cardRef,
  children,
  theme = CardTheme.DEFAULT,
}: CardProps): React.ReactElement => {
  return (
    <div
      id={id}
      data-test-id={id}
      ref={cardRef}
      className={`relative border ${CardThemeStyles[theme].border} ${CardThemeStyles[theme].card} ${CardThemeStyles[theme].shadow} ${CardThemeStyles[theme].rounded} ${CardThemeStyles[theme].margin}`}
    >
      {header ? (
        <div className={`flex items-center self-stretch body-sm px-2 py-1 ${CardThemeStyles[theme].headerText}`}>
          {header}
        </div>
      ) : null}
      <div
        className={`flex-col items-center border border-t-0 -m-[1px] ${CardThemeStyles[theme].border} ${
          CardThemeStyles[theme].rounded
        } ${theme === CardTheme.TRANSPARENT ? 'bg-transparent' : 'bg-neutral-50'}`}
      >
        {children}
      </div>
      {footer ? (
        <div className={`flex items-center self-stretch body-sm p-2 ${CardThemeStyles[theme].headerText}`}>
          {footer}
        </div>
      ) : null}
    </div>
  );
};

export default Card;
