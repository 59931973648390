export const adServerMarkets = [
  'JCDECAUX_AU',
  'JCDECAUX_FR_AIRPORT',
  'JCDECAUX_FR_MU',
  'JCDECAUX_NZ',
  'VIOOH_EXTERNAL',
  'BRANDEDCITIES_US',
  'CATMOUSE_AU',
  'MEDIATRANSPORTS_FR',
  'JCDECAUX_BR',
  'VIOOH_E2E_ADSERVER',
  'MEDIAFRANKFURT_DE',
  'TONICMEDIA_AU',
  'JCDECAUX_NL',
  'JCDECAUX_FI',
  'JCDECAUX_DE',
  'DOOHYOULIKE_FR',
  'INSTOREMEDIA_FR',
  'JCDECAUX_MX',
  'JCDECAUX_IT',
  'JCDECAUX_SG',
  'FILLUPMEDIA_FR',
  'APG_CH',
  'FRAMEN_DE',
  'JCDECAUX_AT',
  'JCDECAUX_NL_HIVE',
  'JCDECAUX_NL_HIVE2',
  'JCDECAUX_NL_HIVE3',
  'JCDECAUX_BE',
  'JCDECAUX_ES',
  'JCDECAUX_ES_HIVE_2',
  'JCDECAUX_PT',
  'JCDECAUX_PT_HIVE',
  'JCDECAUX_PT_HIVE_3',
  'JCDECAUX_ZA',
  'JCDECAUX_CN_SH_METRO',
  'JCDECAUX_NO',
  'JCDECAUX_NO_HIVE',
  'HELLOO_BR',
  'MOP_PT',
  'BJ_METRO_CN',
  'JCDECAUX_CO',
  'JCDECAUX_HK_CITYSCAPE',
  'JCDECAUX_US',
  'VERTICALCITY',
];
