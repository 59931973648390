import SingleContent from 'components/common/layout/SingleContent';
import DealsHeader from 'components/pages/Deals/DealsHeader';
import DealsContent from 'components/pages/Deals/DealsContent';
import { PageWrapper } from 'components/common/layout/PageWrapper/PageWrapper';

const Deals = () => (
  <PageWrapper>
    <DealsHeader />
    <SingleContent content={<DealsContent />} />
  </PageWrapper>
);

export default Deals;
