import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SVG from 'react-inlinesvg';
import Auth from 'modules/Auth';
import withRouter from 'components/hocs/withRouter';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import Timezone from 'components/common/Header/Timezone';
import { formatMarket } from 'utils/formatters';
import globeSvg from 'assets/icons/globe.svg';
import arrowSvg from 'assets/icons/arrow_down.svg';

import styles from './Header.pcss';

const cx = classNames.bind(styles);

class Header extends React.Component {
  onChangeEnvironmentClick = () => {
    const { navigate } = this.props;
    navigate('/landing');
  };

  render() {
    const { environmentId, title, actionButton, onBackClick, subNavigation } = this.props;
    const { markets } = Auth.getData();
    const allowChangeEnv = markets?.length > 1;

    return (
      <header className={cx('header')}>
        <div className={cx('row', 'h-full')}>
          <div className="px-4 flex items-center">
            {onBackClick && (
              <span className="mr-4">
                <Button btnShape={ButtonShape.CIRCLE} btnType={ButtonType.PRIMARY} onClick={onBackClick} label="Back">
                  <SVG className="transform rotate-90 w-4" src={arrowSvg} />
                </Button>
              </span>
            )}
            <h2 data-test-id="page-title" title={title} className={cx('mr-4 header-xl text-neutral-900', 'page-title')}>
              {title}
            </h2>
          </div>
          <div className="self-end">{subNavigation}</div>
          <div className="flex ml-auto text-right self-center">
            <div className="mr-5 space-x-5 flex items-center">{actionButton}</div>
            <div className={cx('mr-5 mt-[1px]', 'timezone-container')}>
              <Timezone />
            </div>

            <div className={cx('mr-5', 'vertical-align-middle', 'environment-selection')}>
              <Button
                dataTestId="environment-selection"
                onClick={this.onChangeEnvironmentClick}
                btnShape={ButtonShape.ROUNDED}
                isDisabled={!allowChangeEnv}
              >
                <span className="mr-2">
                  <SVG src={globeSvg} />
                </span>
                <span className="text-neutral-500">{formatMarket(environmentId)}</span>
              </Button>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  environmentId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
  title: PropTypes.string,
  actionButton: PropTypes.element,
  onBackClick: PropTypes.func,
  subNavigation: PropTypes.node,
};

Header.defaultProps = {
  environmentId: '',
  actionButton: null,
  onBackClick: null,
  subNavigation: null,
  title: '',
};

export default withRouter(Header);
