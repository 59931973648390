import { useDispatch, useSelector } from 'react-redux';

import { DealStatus } from 'components/common/types/Deal.types';
import { NotificationMessages } from 'consts/notifications';
import FileUpload from 'components/patterns/FileUpload';
import { isReadOnly } from 'utils/isReadOnly';
import { FILE_SIZE_10MB } from 'modules/api/constants';
import { notifyError } from 'store/notification/reducer';
import { Store } from 'components/common/types/Store.types';
import { changeDealLineProximityPoints } from 'store/dealManagement/reducer';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox/MultipleSwitchBox';
import { DISTANCES } from 'consts/distances';
import { ACCEPTED_FILE_TYPE_XLS } from 'consts/fileType';
import RadioCheckBox, { types } from 'components/patterns/RadioCheckBox';
import Input from 'lib/Input';
import { InputType } from 'components/common/types/Input.types';
import { FileSuccess } from 'components/common/types/File.types';
import { FileRejection } from 'react-dropzone';
import Distance from '../Distance';

const Points: React.FC = () => {
  const dispatch = useDispatch();
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const terminated = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.terminated);
  const { latitude, longitude, files, distance, unit, include, isFileUploadActive } = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.proximity.points,
  );

  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled, terminated) && !isPendingReservation;

  const onFileChange = (acceptedFiles: FileSuccess[], rejectedFiles: FileRejection[]): void => {
    if (rejectedFiles.length) {
      dispatch(notifyError({ message: NotificationMessages.INVALID_UPLOAD }));
      return;
    }

    const [uploadedFile] = acceptedFiles;
    uploadedFile.file = uploadedFile;
    if (uploadedFile) {
      dispatch(changeDealLineProximityPoints({ files: [uploadedFile] }));
    }
  };

  return (
    <div data-test-id="points">
      <p className="sub-header-base text-neutral-900 mb-4">Latitude/Longitude</p>
      <div className="flex items-end gap-5">
        <div className="inline-flex flex-1 items-end">
          <div className="mb-2">
            <RadioCheckBox
              dataTestId="points-latlng-checkbox"
              type={types.RADIO}
              value="latLong"
              isChecked={!isFileUploadActive}
              groupName="latLong"
              onChange={() => {
                dispatch(
                  changeDealLineProximityPoints({ latitude: '', longitude: '', files: [], isFileUploadActive: false }),
                );
              }}
              isDisabled={readOnly}
            />
          </div>
          <div className="w-full flex gap-5">
            <div>
              <p className="sub-header-base text-neutral-950-opacity-60 mb-2">Latitude</p>
              <Input
                placeholder="Latitude"
                value={latitude}
                onChange={(value: string) => dispatch(changeDealLineProximityPoints({ latitude: value }))}
                isDisabled={readOnly || isFileUploadActive}
                dataTestId="points-latitude-input"
                type={InputType.NUMBER}
              />
            </div>
            <div>
              <p className="sub-header-base text-neutral-950-opacity-60 mb-2">Longitude</p>
              <Input
                placeholder="Longitude"
                value={longitude}
                onChange={(value: string) => dispatch(changeDealLineProximityPoints({ longitude: value }))}
                isDisabled={readOnly || isFileUploadActive}
                dataTestId="points-longitude-input"
                type={InputType.NUMBER}
              />
            </div>
          </div>
        </div>
        <div className="inline-flex flex-1 items-end">
          <div className="mb-2">
            <RadioCheckBox
              dataTestId="points-fileupload-checkbox"
              type={types.RADIO}
              value="fileUpload"
              isChecked={isFileUploadActive}
              groupName="latLong"
              onChange={() => {
                dispatch(
                  changeDealLineProximityPoints({ latitude: '', longitude: '', files: [], isFileUploadActive: true }),
                );
              }}
              isDisabled={readOnly}
            />
          </div>
          <div className="w-full">
            <p className="sub-header-base text-neutral-950-opacity-60 mb-2">List upload</p>
            <FileUpload
              acceptFileType={ACCEPTED_FILE_TYPE_XLS}
              maxFileSize={FILE_SIZE_10MB}
              placeholder={(files && files[0]?.name) || 'List name'}
              onFileChange={onFileChange}
              isDisabled={readOnly || !isFileUploadActive}
            />
          </div>
        </div>
        <Distance
          value={distance}
          dataTestId="points-distance"
          onChange={(value) => {
            dispatch(changeDealLineProximityPoints({ distance: value }));
          }}
          isDisabled={readOnly}
        />
        <MultipleSwitchBox
          source={Object.entries(DISTANCES).map(([name, code]) => ({ name, code }))}
          selectedValue={unit}
          onChange={(value) => dispatch(changeDealLineProximityPoints({ unit: value }))}
          isDisabled={readOnly}
          dataTestId="points-unit"
        />
        <MultipleSwitchBox
          source={[
            { code: 'include', name: 'Include' },
            { code: 'exclude', name: 'Exclude' },
          ]}
          dataTestId="points-include-exclude-switch"
          selectedValue={include ? 'include' : 'exclude'}
          onChange={(value) => dispatch(changeDealLineProximityPoints({ include: value === 'include' }))}
          isDisabled={readOnly}
        />
      </div>
    </div>
  );
};

export default Points;
