import range from 'lodash/range';
import intersection from 'lodash/intersection';

const getSelectedRowsOnCurrentPage = (selected, currentPage, pageSize) => {
  const selectedIndices = selected.map((row) => row.rowIndex);
  const allPossibleCurrentPageIndices = range(currentPage * pageSize, currentPage * pageSize + pageSize);

  return intersection(selectedIndices, allPossibleCurrentPageIndices);
};

const getNumberOfRowsOnLastPage = (totalNumberOfRows, pageSize, totalNumberOfPages) => {
  const numberOfRowsNotOnLastPage = (totalNumberOfPages - 1) * pageSize;

  return totalNumberOfRows - numberOfRowsNotOnLastPage;
};

export const getHeaderCheckboxStatus = (selected, currentPage, pageSize, totalNumberOfPages, totalNumberOfRows) => {
  const selectedRowsOnCurrentPage = getSelectedRowsOnCurrentPage(selected, currentPage, pageSize);

  if (!selectedRowsOnCurrentPage.length) {
    return { checked: false, isIndeterminate: false };
  }

  if (selectedRowsOnCurrentPage.length === pageSize) {
    return { checked: true, isIndeterminate: false };
  }

  const isLastPageAndAllRowsSelected =
    currentPage === totalNumberOfPages - 1 &&
    selectedRowsOnCurrentPage.length === getNumberOfRowsOnLastPage(totalNumberOfRows, pageSize, totalNumberOfPages);

  if (isLastPageAndAllRowsSelected) {
    return { checked: true, isIndeterminate: false };
  }

  return { checked: false, isIndeterminate: true };
};
