import { Component } from 'react';
import PropTypes from 'prop-types';
import { getStringFromObjectsList } from 'utils/formatters';
import Session from 'modules/Session';
import { fetchCreatives } from 'modules/api/content';
import { withPageFilters } from 'components/hocs/withPageFilters';
import withLoader from 'components/hocs/withLoader';
import withCancelRequest from 'components/hocs/withCancelRequest';
import { CreativeStatus } from 'components/common/types/Creative.types';
import { getISOStringWithoutSecAndMillisec } from 'utils/dateFormatUtil';
import Creatives from './Creatives';
import Filters from './Filters';

class Main extends Component {
  state = {
    bulkSelectedCreatives: [],
  };

  getDateFilters = (filterData) => {
    const fieldMappings = {
      APPROVED: 'Approved',
      REJECTED: 'Rejected',
      PENDING: 'Pending',
      FLAGGED: 'Flagged',
      UPLOADED: 'Upload',
    };

    const dateFilters = {};

    if (filterData?.status && filterData?.startDate) {
      const sDate = new Date(new Date(filterData.startDate).setHours(0, 0, 0, 0));
      dateFilters[`start${fieldMappings[filterData?.status]}Date`] = getISOStringWithoutSecAndMillisec(sDate);
    }

    if (filterData?.status && filterData?.endDate) {
      const eDate = new Date(new Date(filterData.endDate).setHours(23, 59, 59, 999));
      dateFilters[`end${fieldMappings[filterData?.status]}Date`] = getISOStringWithoutSecAndMillisec(eDate);
    }

    return dateFilters;
  };

  mapFiltersToRequestData = (filterData) => ({
    ...this.getDateFilters(filterData),
    status: filterData?.status === CreativeStatus.UPLOADED ? '' : filterData?.status,
    type: filterData?.creativeType,
    dspName: filterData.dsp?.name,
    productFormatName: filterData.productFormat?.name,
    externalIdContains: filterData?.externalId,
    width: filterData?.width,
    height: filterData?.height,
    dealId: filterData.dealIds?.map((deal) => deal.code).join(','),
    advertiserCode: filterData.advertiser?.code,
    brandCode: filterData.brand?.code,
    productCategoryCode: filterData.productCategory?.code,
    deleted: filterData.deleted,
  });

  handleExpiredDate = (deletedDate) => {
    if (!deletedDate) return null;

    const date = new Date(deletedDate);
    date.setDate(date.getDate() + 30);

    return date;
  };

  handleIsExpiredCreative = (expireData) => new Date() > expireData;

  prepareCreativesList = (creatives) => {
    return creatives.map((creative) => {
      const currentMarket = creative.market.find((market) => market.environment === Session.getEnvironmentId());

      const expireDate = this.handleExpiredDate(currentMarket?.deletedAt);
      const isExpired = !expireDate || this.handleIsExpiredCreative(expireDate);

      return {
        ...creative,
        environment: currentMarket?.environment,
        statusCode: currentMarket?.moderation[0]?.status ?? 'PENDING',
        dspName: creative?.dsp?.name,
        uploadedBy: creative.uploadedBy?.name,
        advertiser: currentMarket?.commercial?.advertiser?.name,
        brand: currentMarket?.commercial?.brand?.name,
        productCategory: currentMarket?.commercial?.productCategory?.name,
        formatName: getStringFromObjectsList(currentMarket?.productFormat),
        updatedBy: currentMarket?.moderation[0]?.updatedBy?.name,
        updated: currentMarket?.moderation[0]?.updateStamp,
        deletedAt: currentMarket?.deletedAt,
        expiredAt: expireDate?.toISOString(),
        isExpired,
      };
    });
  };

  onFetchCreatives = async (extraQuery) => {
    const { cancelFunctions, filters } = this.props;

    const { content, totalElements } = await fetchCreatives(cancelFunctions, {
      ...this.mapFiltersToRequestData(filters),
      ...extraQuery,
      environment: Session.getEnvironmentId(),
    });

    return {
      content: this.prepareCreativesList(content),
      totalElements,
    };
  };

  setSelectedCreatives = async (selectedCreatives) => {
    await this.setState({ bulkSelectedCreatives: selectedCreatives });
  };

  render() {
    const { bulkSelectedCreatives } = this.state;
    const { filters } = this.props;

    return (
      <>
        <div>
          <Filters filterData={filters} />
        </div>
        <div>
          <Creatives
            onFetchCreatives={this.onFetchCreatives}
            filterData={filters}
            setSelectedCreatives={this.setSelectedCreatives}
            isCreativesSelected={bulkSelectedCreatives.length > 1 && bulkSelectedCreatives.length <= 100}
          />
        </div>
      </>
    );
  }
}

Main.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  filters: PropTypes.object.isRequired,
};

export default withLoader(withCancelRequest(withPageFilters(Main)));
