import FilterArea from 'components/common/FilterArea';
import FilterAdvertiser from 'components/common/FilterArea/Filters/FilterAdvertiser';
import FilterBrand from 'components/common/FilterArea/Filters/FilterBrand';
import FilterCreativeId from 'components/common/FilterArea/Filters/FilterCreativeId';
import FilterDateRange from 'components/common/FilterArea/Filters/FilterDateRange';
import FilterDealId from 'components/common/FilterArea/Filters/FilterDealId';
import FilterDsp from 'components/common/FilterArea/Filters/FilterDsp';
import FilterTradingModel from 'components/common/FilterArea/Filters/FilterTradingModel';
import FilterTradingTime from 'components/common/FilterArea/Filters/FilterTradingTime';
import { DiagnosticsFilters } from 'components/common/types/InsightsDeal';
import { usePageFilters } from 'customHooks/usePageFilters';
import { useEffect } from 'react';
import FilterLossCode from './FilterLossCode';
import FilterLossType from './FilterLossType';

const Filters: React.FC = () => {
  const {
    filters: { deal, creative, startDate, endDate, lossType, lossCode, tradingModel, time, dsp, advertiser, brand },
    changeFilters,
  } = usePageFilters<DiagnosticsFilters>('insights/dashboard');

  useEffect(() => {
    return () => {
      changeFilters({ lossCode: undefined, lossType: undefined, creative: undefined });
    };
  }, []);

  return (
    <FilterArea>
      <div className="grid grid-cols-3 gap-x-4 mr-4">
        <div className="flex flex-col gap-y-4">
          <FilterDealId changeFilters={changeFilters} deal={deal} />
          <FilterLossCode changeFilters={changeFilters} lossCode={lossCode} lossType={lossType} />
          <FilterCreativeId changeFilters={changeFilters} creativeId={creative} />
        </div>
        <div className="flex flex-col gap-y-4">
          <FilterDsp onChange={(name, value) => changeFilters({ [name]: value })} selectedValue={dsp} />
          <FilterAdvertiser changeFilters={changeFilters} selectedAdvertiser={advertiser} />
        </div>
        <div className="flex flex-col gap-y-4">
          <FilterLossType changeFilters={changeFilters} lossType={lossType} />
          <FilterBrand changeFilters={changeFilters} brand={brand} />
        </div>
      </div>
      <div className="flex gap-x-4 justify-end">
        <div className="flex flex-col items-end">
          <FilterDateRange selectedStartDate={startDate} selectedEndDate={endDate} changeFilters={changeFilters} />
        </div>
        <div className="flex flex-col items-end gap-y-4">
          {deal ? null : <FilterTradingModel selectedTradingModel={tradingModel} changeFilters={changeFilters} />}
          <FilterTradingTime selectedTime={time} changeFilters={changeFilters} />
        </div>
      </div>
    </FilterArea>
  );
};

export default Filters;
