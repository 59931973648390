import { createSelector } from '@reduxjs/toolkit';
import { getAdServerDealConfigValue } from 'store/dealConfig/utils';
import { Store } from 'components/common/types/Store.types';
import { DealType } from '../types/Deal.types';

export const getIsAllocationReportFeatureEnabled = createSelector(
  (state: Store) => state.dealConfig,
  (state: Store) => state.dealManagement.commonDeal.dealType,
  (dealConfig, dealType) => {
    return (
      dealConfig.isAdServer &&
      dealType === DealType.GUARANTEED &&
      Boolean(getAdServerDealConfigValue(dealConfig, 'enableNativeAllocationReport'))
    );
  },
);
