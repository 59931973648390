import PropTypes from 'prop-types';
import { BaseInput } from 'lib/BaseInput';
import { InputSize } from 'components/common/types/Input.types';
import searchSvg from 'assets/icons/search.svg';

const Search = ({ value, placeholder, onChange }) => {
  return (
    <div className="relative group">
      <BaseInput
        size={InputSize.LARGE}
        id="search-input"
        startIconSvg={searchSvg}
        dataTestId="search-input"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

Search.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Search.defaultProps = {
  placeholder: '',
};

export default Search;
