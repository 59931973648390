import classNames from 'classnames';
import styles from './CardMedia.pcss';

const cx = classNames.bind(styles);

interface Props {
  previewURL: string;
}

export const CardMedia: React.FC<Props> = ({ previewURL }) => {
  return <img alt="creative" className={cx('rounded-lg w-full h-full', styles.cardImage)} src={previewURL} />;
};
