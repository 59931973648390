import queryString from 'query-string';

import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS } from 'modules/api/constants';

export const fetchCommercialEntitiesTable = async (cancelFunctions, data, queryObj) => {
  const query = queryString.stringify(queryObj, { skipEmptyString: true, arrayFormat: 'comma' });
  const { send, cancel } = createRequest({
    url: URLS().FETCH_COMMERCIAL_ENTITIES_TABLE(query),
    method: HTTP_METHODS.POST,
    data,
    showLoader: true,
  });

  if (cancelFunctions.FETCH_COMMERCIAL_ENTITIES_TABLE) cancelFunctions.FETCH_COMMERCIAL_ENTITIES_TABLE();
  cancelFunctions.FETCH_COMMERCIAL_ENTITIES_TABLE = cancel;

  const result = await send();

  const totalElements = parseInt(result.headers['total-elements']);

  return {
    data: result.data,
    totalElements: Number.isNaN(totalElements) ? result.data.length : totalElements,
  };
};

export const getPersonTypes = async (cancelFunctions) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_PERSON_TYPES(),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_PERSON_TYPES) cancelFunctions.FETCH_PERSON_TYPES();
  cancelFunctions.FETCH_PERSON_TYPES = cancel;

  const result = await send();

  return result.data;
};
