import { useCallback, useEffect, useMemo, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import debounce from 'lodash/debounce';

import { Point, Position } from 'components/common/types/Geometry';
import { getElementBoundingBox } from 'utils/getElementBoundingBox';
import { calculateGridPosition } from './utils';
import { ElementPositionsProps } from './MasonryGallery.types';
import { useElementsRefsHeights } from './useElementsRefsHeights';
import { useChildrenElementsRefs } from './useChildrenElementsRefs';

export const useMasonryGalleryPositioning = (children: React.ReactNode, columns?: number): ElementPositionsProps => {
  const { ref: containerRef, width: containerWidth = 1 } = useResizeObserver<HTMLDivElement>();
  const { elementsRefs } = useChildrenElementsRefs(children);
  const { elementsRefsHeights } = useElementsRefsHeights(elementsRefs);
  const [elementsPositions, setElementsPositions] = useState<Position[]>(elementsRefs.map(() => ({ top: 0, left: 0 })));

  const calculateElementPosition = useCallback(
    (currentElementIndex: number, previousElementsPositions: Position[]): Position => {
      const currentElementWidth: number = getElementBoundingBox(elementsRefs?.[currentElementIndex].current).width || 1;
      const maxElementsInRow = columns ?? Math.floor(containerWidth / currentElementWidth);
      const currentElementCoordinates: Point = calculateGridPosition(currentElementIndex, maxElementsInRow);
      const emptySpace: number = containerWidth - maxElementsInRow * currentElementWidth;
      const result = {
        left: Math.max(0, emptySpace / 2) + currentElementCoordinates.x * currentElementWidth,
        top: 0,
      };

      if (currentElementIndex >= maxElementsInRow) {
        const indexOfElementInPreviousRow = currentElementIndex - maxElementsInRow;
        const heightOfElementInPreviousRow = getElementBoundingBox(
          elementsRefs?.[indexOfElementInPreviousRow].current,
        ).height;
        const positionOfElementInPreviousRow = previousElementsPositions[indexOfElementInPreviousRow];

        result.top = positionOfElementInPreviousRow
          ? positionOfElementInPreviousRow.top + heightOfElementInPreviousRow
          : 0;
      }

      if (Number.isNaN(result.left)) {
        result.left = 0;
      }

      if (Number.isNaN(result.top)) {
        result.top = 0;
      }

      return result;
    },
    [columns, containerWidth, elementsRefs],
  );

  const recalculateElementsPositions = useCallback((): void => {
    const result: Position[] = [];

    elementsRefs.forEach((_, index) => {
      result.push(calculateElementPosition(index, result));
    });
    setElementsPositions(result);
  }, [calculateElementPosition, setElementsPositions]);

  const debouncedRecalculateElementsPositions = useMemo(
    () => debounce(recalculateElementsPositions, 100),
    [recalculateElementsPositions],
  );

  useEffect(() => {
    debouncedRecalculateElementsPositions();

    return () => {
      debouncedRecalculateElementsPositions.cancel();
    };
  }, [debouncedRecalculateElementsPositions, elementsRefsHeights]);

  return {
    containerRef,
    elementsRefs,
    elementsPositions,
  };
};
