import { URLS, HTTP_METHODS, HEADERS_CONTENT_TYPE } from 'modules/api/constants';
import handleRequest from './common';

export const getDealConfig = (cancelFunctions) => {
  const reqOptions = {
    url: URLS().GET_DEAL_CONFIG(),
    method: HTTP_METHODS.GET,
  };
  return handleRequest(reqOptions, cancelFunctions, 'GET_DEAL_CONFIG');
};

export const getMetaData = async ({ cancelFunctions, cancelable = true, envId = null }) => {
  const reqOptions = {
    url: URLS().META_DATA(),
    method: HTTP_METHODS.GET,
    cancelable,
    ...(envId ? { envId } : false),
  };
  return handleRequest(reqOptions, cancelFunctions, 'META_DATA');
};

export const createDeal = (data, cancelFunctions) => {
  const reqOptions = {
    url: URLS().SAVE_MAIN_DEAL(),
    method: HTTP_METHODS.POST,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_X_WWW_FORM_URLENCODED,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'SAVE_MAIN_DEAL_CREATE');
};

export const updateDeal = (data, cancelFunctions) => {
  const reqOptions = {
    url: URLS().SAVE_MAIN_DEAL(),
    method: HTTP_METHODS.PUT,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_X_WWW_FORM_URLENCODED,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'SAVE_MAIN_DEAL_UPDATE');
};

export const createLine = (data, cancelFunctions) => {
  const reqOptions = {
    url: URLS().LINE(),
    method: HTTP_METHODS.POST,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_X_WWW_FORM_URLENCODED,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'CREATE_LINE');
};

export const updateLine = (data, cancelFunctions) => {
  const reqOptions = {
    url: URLS().LINE(),
    method: HTTP_METHODS.PUT,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_X_WWW_FORM_URLENCODED,
    },
    notificationTimeout: 0,
  };
  return handleRequest(reqOptions, cancelFunctions, 'UPDATE_LINE');
};

export const updateLiveLine = (data, cancelFunctions) => {
  const reqOptions = {
    url: URLS().UPDATE_LIVE_LINE(),
    method: HTTP_METHODS.POST,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_X_WWW_FORM_URLENCODED,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'UPDATE_LIVE_LINE');
};

export const deleteLine = (data, cancelFunctions) => {
  const reqOptions = {
    url: URLS().LINE(true),
    method: HTTP_METHODS.DELETE,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_X_WWW_FORM_URLENCODED,
    },
    notificationTimeout: 0,
  };
  return handleRequest(reqOptions, cancelFunctions, 'DELETE_LINE');
};

export const terminateLine = (data, cancelFunctions) => {
  const reqOptions = {
    url: URLS().TERMINATE_LINE(),
    method: HTTP_METHODS.POST,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_X_WWW_FORM_URLENCODED,
    },
    notificationTimeout: 0,
  };
  return handleRequest(reqOptions, cancelFunctions, 'TERMINATE_LINE');
};

export const checkDealAvailability = (data, cancelFunctions) => {
  const reqOptions = {
    url: URLS().CHECK_DEAL_AVAILABILITY(),
    method: HTTP_METHODS.POST,
    data,
    showLoader: false,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_X_WWW_FORM_URLENCODED,
    },
    notificationTimeout: 0,
  };
  return handleRequest(reqOptions, cancelFunctions, 'CHECK_DEAL_AVAILABILITY');
};

export const checkLineAvailability = (data, cancelFunctions) => {
  const reqOptions = {
    url: URLS().CHECK_LINE_AVAILABILITY(),
    method: HTTP_METHODS.POST,
    data,
    showLoader: false,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_X_WWW_FORM_URLENCODED,
    },
    notificationTimeout: 0,
  };
  return handleRequest(reqOptions, cancelFunctions, 'CHECK_LINE_AVAILABILITY');
};

export const getDealLinesDetails = (id, cancelFunctions) => {
  const reqOptions = {
    url: URLS().DEAL_LINES_DETAIL(id),
    method: HTTP_METHODS.GET,
    showLoader: true,
  };
  return handleRequest(reqOptions, cancelFunctions, 'DEAL_LINES_DETAIL');
};

export const fetchDealBids = async (cancelFunctions, dealId) => {
  const reqOptions = {
    url: URLS().DEAL_LIST_BIDS_DATA_URL(),
    method: HTTP_METHODS.POST,
    data: JSON.stringify({ dealId: [dealId] }),
    showLoader: true,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
  };
  const result = await handleRequest(reqOptions, cancelFunctions, 'DEAL_LIST_BIDS_DATA_URL');
  const [bidData] = Object.values(result[dealId]);
  return bidData;
};
