import { useLocation, useNavigate, useParams } from 'react-router-dom';

/* eslint-disable react/jsx-props-no-spreading */
const withRouter = (WrappedComponent) => {
  return (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <WrappedComponent {...props} navigate={navigate} location={location} params={params} />;
  };
};
/* eslint-enable react/jsx-props-no-spreading */

export default withRouter;
