import { lazy, Suspense } from 'react';
import { DiagnosticsFilters } from 'components/common/types/InsightsDeal';
import { Preloader } from 'components/patterns/Loader';
import { usePageFilters } from 'customHooks/usePageFilters';
import Filters from './Filters';

const DiagnosticsCharts = lazy(() => import('./DiagnosticsCharts'));
const DealsTable = lazy(() => import('./DealsTable'));

const DiagnosticsContent: React.FC = () => {
  const { filters } = usePageFilters<DiagnosticsFilters>('insights/dashboard');

  return (
    <div data-test-id="diagnostics-wrapper">
      <Filters />
      <Suspense fallback={<Preloader />}>
        <DiagnosticsCharts />
      </Suspense>
      <Suspense fallback={<Preloader />}>
        <DealsTable filters={filters as DiagnosticsFilters} />
      </Suspense>
    </div>
  );
};

export default DiagnosticsContent;
