import { CancelFunctions } from 'components/common/types';
import { MtbExportResponse } from 'components/common/types/MtbExport';
import { HTTP_METHODS, URLS } from './constants';
import handleRequest from './common';

export const getMtbExport = (id: string, cancelFunctions: CancelFunctions): Promise<MtbExportResponse> => {
  const reqOptions = {
    url: URLS().MTB_EXPORT(id),
    method: HTTP_METHODS.GET,
    showLoader: true,
  };

  return handleRequest(reqOptions, cancelFunctions, 'MTB_EXPORT');
};
