import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import spacetime from 'spacetime';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { InputShape } from 'components/common/types/Input.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { MINUTES_IN_HOUR } from 'utils/dateFormatUtil';
import { setTimezone } from 'store/header/reducer';
import TIMEZONES from './timezones';
import { SingleValue, Option } from './AsyncSelectCustomComponents';

const formatHours = (hours) => {
  const minutes = `${Math.abs(hours * MINUTES_IN_HOUR) % MINUTES_IN_HOUR}`.padStart(2, '0');
  const hoursWithoutMin = parseInt(hours);
  const absHours = `${Math.abs(hoursWithoutMin)}`.padStart(2, '0');

  if (hours > 0) return `+${absHours}:${minutes}`;
  if (hours < 0) return `-${absHours}:${minutes}`;

  return `${absHours}:${minutes}`;
};

const Timezone = () => {
  const dispatch = useDispatch();
  const environmentId = useSelector((state) => state.environment.environmentId);
  const tz = useSelector((state) => state.header.timezone);
  const {
    iso2CountryCode,
    localeCode,
    timezone: code,
    flag: flagOverride,
  } = useSelector((state) => state.publisher.configuration);

  const timezoneAbr = (timezone) => {
    return new Intl.DateTimeFormat(localeCode, {
      timeZoneName: 'short',
      timeZone: timezone,
    })
      .formatToParts()
      .find(({ type }) => type === 'timeZoneName').value;
  };

  const formattedZones = useMemo(() => {
    const hasTimezone = TIMEZONES.find((config) => config.code === code && config.iso2CountryCode === iso2CountryCode);
    const timezones = !hasTimezone
      ? [...TIMEZONES, { iso2CountryCode, code, tzAbr: timezoneAbr(code), flagOverride }]
      : TIMEZONES;

    return timezones.map((zone) => {
      zone.flag = zone.flagOverride ? `${zone.flagOverride}.png` : `${zone.iso2CountryCode}.png`;
      const now = spacetime.now(zone.code);
      const {
        current: { offset: hours },
      } = now.timezone();
      return { ...zone, name: `UTC ${formatHours(hours)} | ${zone.tzAbr}` };
    });
  }, [TIMEZONES, localeCode]);

  const setDefaultTimezone = () => {
    const defaultTimezone = formattedZones.find((zone) => zone.code === code) || formattedZones[0];
    dispatch(setTimezone({ ...defaultTimezone, selectedPublisher: environmentId }));
  };

  const onLoadLookupData = (_dropdownName, qry) => {
    return formattedZones.filter((zone) => zone.name.toLowerCase().indexOf(qry.toLowerCase()) > -1);
  };

  const onLookupSelect = (_dropdownName, newValue) => {
    dispatch(setTimezone({ ...newValue, selectedPublisher: environmentId }));
  };

  useEffect(() => {
    if (tz.code === '' || tz.selectedPublisher !== environmentId) {
      setDefaultTimezone();
    }
  }, []);

  return (
    <AsyncSelect
      dataTestId="timezone"
      name="timezone"
      loadData={onLoadLookupData}
      selectedValue={tz.code ? tz : null}
      placeholder="Select Time zone"
      onSelect={onLookupSelect}
      isClearable={false}
      defaultOptions={formattedZones}
      debounceTime={0}
      customComponents={{ SingleValue, Option }}
      isDisabled={!Auth.hasPermission(PermissionsEnum.DASHBOARD_TIMEZONE_SELECT)}
      shape={InputShape.CIRCLE}
    />
  );
};

export default Timezone;
