import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeCode } from 'store/pages/contentManagement/creativeSetDetails/reducer';
import { saveSetDetails, deleteCreativeSet } from 'store/pages/contentManagement/creativeSetDetails/actions';

import SetDetails from './SetDetails';

const mapStateToProps = (state) => ({
  creativeSetDetails: state.pages.contentManagement.creativeSetDetails,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ saveSetDetails, changeCode, deleteCreativeSet }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SetDetails);
