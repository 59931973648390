import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Tooltip.pcss';

const cx = classNames.bind(styles);

export const TooltipDirection = {
  RIGHT: 'tooltip-right',
  LEFT: 'tooltip-left',
  TOP: 'tooltip-top',
  TOP_LEFT: 'tooltip-top-left',
  BOTTOM: 'tooltip-bottom',
  BOTTOM_LEFT: 'tooltip-bottom-left',
  BOTTOM_RIGHT: 'tooltip-bottom-right',
};

export const TooltipSize = {
  AUTO: 'w-auto',
  MAX: 'w-max',
  FULL: 'w-full',
};

export const TooltipTheme = {
  LIGHT: 'light',
  DARK: 'dark',
  TRANSPARENT: 'transparent',
};

const Tooltip = ({ children, tooltip, size, contentSize, direction, theme, delay }) => {
  if (!tooltip) return children;

  return (
    <div className={cx('tooltip', direction, size, theme, { delay })} data-test-id="tooltip">
      {children}
      {tooltip && (
        <div className={cx('tooltipWrapper')}>
          <div className={cx('body-sm', 'tooltipContent', contentSize, theme)} data-test-id="tooltip-content">
            {tooltip}
          </div>
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  tooltip: PropTypes.node,
  direction: PropTypes.oneOf(Object.values(TooltipDirection)),
  size: PropTypes.oneOf(Object.values(TooltipSize)),
  theme: PropTypes.oneOf(Object.values(TooltipTheme)),
  contentSize: PropTypes.oneOf(Object.values(TooltipSize)),
  delay: PropTypes.bool,
};

Tooltip.defaultProps = {
  children: null,
  tooltip: '',
  direction: TooltipDirection.RIGHT,
  size: TooltipSize.AUTO,
  contentSize: TooltipSize.AUTO,
  theme: TooltipTheme.LIGHT,
  delay: false,
};

export default Tooltip;
