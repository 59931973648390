import { TagCategory } from 'components/common/types/Tags.types';
import { FrontEndType } from 'components/common/types/Deal.types';

export const transformTagsData = (tags: TagCategory[], frontEndType: FrontEndType): TagCategory[] => {
  tags.forEach((item: TagCategory) => {
    item.tags.forEach((tag) => {
      tag.include = true;
      if (frontEndType === FrontEndType.PLANNER) {
        tag.category = item.name;
      }
    });
  });

  return tags;
};
