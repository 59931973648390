import { OptionType } from './BaseSelect.types';

interface OptionKeys<T> {
  codeKey: keyof T;
  nameKey: keyof T;
}

export const transformOptions = <T>(options: T[], keys: OptionKeys<T>): OptionType[] => {
  return options.map((option) => ({
    code: String(option[keys.codeKey] ?? ''),
    name: String(option[keys.nameKey] ?? ''),
  }));
};

export const findOption = (options: OptionType[], key: keyof OptionType, value?: string): OptionType | null => {
  if (!value) return null;

  return options.find((option) => option[key] === value) || null;
};
