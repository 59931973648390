import { URLS, HTTP_METHODS } from 'modules/api/constants';
import createRequest from 'modules/api/request';

export const getUsers = async (cancelFunctions, qryText, pageNu, pageSize) => {
  const { send, cancel } = createRequest({
    url: URLS().GET_USER_LIST(qryText, pageNu, pageSize),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.GET_USER_LIST) cancelFunctions.GET_USER_LIST();
  cancelFunctions.GET_USER_LIST = cancel;

  const result = await send();

  return result.data;
};

export const changeUserStatus = async (cancelFunctions, userId, blocked) => {
  const param = { blocked };

  const { send, cancel } = createRequest({
    url: URLS().CHANGE_USER_STATUS(userId),
    method: HTTP_METHODS.PATCH,
    data: param,
  });

  if (cancelFunctions.CHANGE_USER_STATUS) cancelFunctions.CHANGE_USER_STATUS();
  cancelFunctions.CHANGE_USER_STATUS = cancel;

  const result = await send();

  return result.data;
};

export const createUser = async (cancelFunctions, userObj) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATE_USER(),
    method: HTTP_METHODS.POST,
    data: userObj,
  });

  if (cancelFunctions.CREATE_USER) cancelFunctions.CREATE_USER();
  cancelFunctions.CREATE_USER = cancel;

  const result = await send();

  return result.data;
};

export const getUserDetail = async (cancelFunctions, userId) => {
  const { send, cancel } = createRequest({
    url: URLS().GET_USER_DETAIL(userId),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.GET_USER_DETAIL) cancelFunctions.GET_USER_DETAIL();
  cancelFunctions.GET_USER_DETAIL = cancel;

  const result = await send();

  return result.data;
};

export const updateUser = async (cancelFunctions, userObj) => {
  delete userObj.email; // don't send email id in update user request

  const { send, cancel } = createRequest({
    url: URLS().UPDATE_USER(userObj.userId),
    method: HTTP_METHODS.PATCH,
    data: userObj,
  });

  if (cancelFunctions.UPDATE_USER) cancelFunctions.UPDATE_USER();
  cancelFunctions.UPDATE_USER = cancel;

  const result = await send();

  return result.data;
};

export const deleteUser = async (cancelFunctions, userId) => {
  const { send, cancel } = createRequest({
    url: URLS().DELETE_USER(userId),
    method: HTTP_METHODS.DELETE,
  });

  if (cancelFunctions.DELETE_USER) cancelFunctions.DELETE_USER();
  cancelFunctions.DELETE_USER = cancel;

  const result = await send();

  return result.data;
};
