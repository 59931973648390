import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeSelectedPanelId, selectCreative } from 'store/pages/contentManagement/creativeSetDetails/reducer';

import AssignCreativeModal from './AssignCreativeModal';

const mapStateToProps = (state) => ({
  selectedPanelId: state.pages.contentManagement.creativeSetDetails.selectedPanelId,
  panels: state.pages.contentManagement.creativeSetDetails.panels,
  dsp: state.pages.contentManagement.creativeSetDetails.dsp,
  advertiser: state.pages.contentManagement.creativeSetDetails.advertiser,
  brand: state.pages.contentManagement.creativeSetDetails.brand,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeSelectedPanelId,
      selectCreative,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssignCreativeModal);
