import DynamicCreativePreview from 'components/common/DynamicCreativePreview';
import { CreativeType } from 'components/common/types/Creative.types';
import { LightboxContentProps } from './LightboxContent.types';
import ImagePreview from './ImagePreview';

const LightboxContent: React.FC<LightboxContentProps> = ({ creative, isZoomedIn }) => {
  const { id, previewURL, downloadURL, thumbnail, type, contentURL } = creative;

  return (
    <div key={id} className="h-full w-full overflow-hidden flex justify-center items-center">
      {type === CreativeType.BANNER && <ImagePreview creative={creative} isZoomedIn={isZoomedIn} />}
      {type === CreativeType.VIDEO && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video key={previewURL || contentURL} className="max-w-full max-h-full w-auto h-auto" controls autoPlay={false}>
          <source src={previewURL || contentURL} type="video/mp4" />
        </video>
      )}
      {type === CreativeType.DYNAMIC && (
        <DynamicCreativePreview thumbnail={thumbnail} downloadURL={downloadURL} readOnly={false} />
      )}
    </div>
  );
};

export default LightboxContent;
