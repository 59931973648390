import { FunctionComponent, useState, useEffect, ReactElement } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import LocaleUtils from 'react-day-picker/moment';
import { CaptionElementProps, DayPickerProps } from 'react-day-picker/types/Props';
import classNames from 'classnames/bind';
import SVG from 'react-inlinesvg';
import { getInputBorderStyle } from 'lib/BaseInput';
import chevronDown from 'assets/icons/chevron_down.svg';
import closeSvg from 'assets/icons/close.svg';
import calendar from 'assets/icons/calendar.svg';
import usePrevious from 'customHooks/usePrevious';
import YearMonthForm from 'components/patterns/YearMonthForm';
import styles from './DayPicker.pcss';

const cx = classNames.bind(styles);

const { formatDate, parseDate } = LocaleUtils;

type Props = {
  dataTestId?: string;
  disabledDays?: unknown[];
  format?: string;
  fromMonth?: Date;
  inputId?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  onDayChange: (arg0: unknown) => void;
  placeholder?: string;
  selectedDay?: Date;
  toMonth?: Date;
};

const DayPicker: FunctionComponent<Props> = ({
  dataTestId,
  disabledDays,
  format,
  fromMonth,
  inputId,
  isClearable,
  isDisabled,
  onDayChange,
  placeholder,
  selectedDay,
  toMonth,
}) => {
  const [month, setMonth] = useState(selectedDay || new Date());
  const prevSelectedDay = usePrevious(selectedDay);
  const inputProps = { className: 'text-input', readOnly: 'readonly', id: inputId };

  const onYearMonthChange = (newDate: Date): void => {
    setMonth(newDate);
  };

  const getCaptionElement = (obj: CaptionElementProps): ReactElement => {
    return (
      <YearMonthForm
        date={obj.date}
        localeUtils={obj.localeUtils}
        onChange={onYearMonthChange}
        fromMonth={fromMonth}
        toMonth={toMonth}
      />
    );
  };

  const dayPickerProps = {
    month,
    fromMonth,
    toMonth,
    disabledDays,
    selectedDays: selectedDay,
    captionElement: getCaptionElement,
  } as DayPickerProps;

  useEffect(() => {
    if (selectedDay && prevSelectedDay !== selectedDay) {
      onYearMonthChange(selectedDay);
    }
  });

  return (
    <div
      data-test-id={dataTestId}
      className={getInputBorderStyle({
        classNames: cx('dayPicker', { readOnly: isDisabled, readOnlyOpacity: isDisabled }),
      })}
    >
      <SVG src={calendar} className={cx('svg-calendar')} />
      <DayPickerInput
        inputProps={inputProps}
        onDayChange={onDayChange}
        dayPickerProps={dayPickerProps}
        placeholder={placeholder}
        formatDate={formatDate}
        parseDate={parseDate}
        format={format}
        value={selectedDay}
      />
      {isClearable && selectedDay ? (
        <SVG src={closeSvg} className={cx('icon-right', 'svg-close')} onClick={() => onDayChange(null)} />
      ) : (
        <SVG src={chevronDown} className={cx('icon-right', 'svg-chevronDown')} />
      )}
    </div>
  );
};

DayPicker.defaultProps = {
  dataTestId: '',
  inputId: undefined,
  fromMonth: undefined,
  toMonth: undefined,
  disabledDays: [],
  placeholder: '',
  format: undefined,
  selectedDay: undefined,
  isDisabled: false,
  isClearable: false,
};

export default DayPicker;
