import { useEffect, useState } from 'react';
import { GridApi, AgGridEvent, Column, ColumnApi } from 'ag-grid-community';
import cx from 'classnames';

import { getHeaderCheckboxStatus } from 'utils/headerCheckboxStatus';

type Props = {
  displayName: string;
  api: GridApi;
  columnApi: ColumnApi;
  column: Column;
  onSelectionChanged?: (payload: { api: GridApi }) => void;
};

enum CheckboxStatus {
  EMPTY,
  CHECKED,
  INDETERMINATE,
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const HeaderRenderer: React.FC<Props> = ({ api: tableApi, column, columnApi, onSelectionChanged, displayName }) => {
  const [checkboxStatus, setCheckboxStatus] = useState(CheckboxStatus.EMPTY);
  const colDef = column.getColDef();
  const currentSortDir = column.getSort();
  const nextOrder = !currentSortDir || currentSortDir === 'desc' ? 'asc' : 'desc';

  const onSortRequested = (): void => {
    const columnsState = columnApi.getColumnState().map((col) => {
      col.sort = col.colId !== column.getColId() ? null : nextOrder;
      return col;
    });
    columnApi.applyColumnState({ state: columnsState });
    tableApi.refreshHeader();
  };

  const sort =
    colDef.sortable && currentSortDir ? (
      <span
        onClick={onSortRequested}
        className="ag-header-icon ag-header-label-icon ag-sort-ascending-icon"
        aria-hidden="true"
      >
        <span className={`ag-icon ag-icon-${nextOrder}`} unselectable="on" role="presentation" />
      </span>
    ) : null;

  const setCheckboxStatusPerPage = (event: AgGridEvent): void => {
    try {
      const selected = event.api.getSelectedNodes();
      const currentPage = event.api.paginationGetCurrentPage();
      const pageSize = event.api.paginationGetPageSize();
      const totalNumberOfPages = event.api.paginationGetTotalPages();
      const totalNumberOfRows = event.api.paginationGetRowCount();

      const status = getHeaderCheckboxStatus(selected, currentPage, pageSize, totalNumberOfPages, totalNumberOfRows);

      setCheckboxStatus(() => {
        if (status.isIndeterminate) {
          return CheckboxStatus.INDETERMINATE;
        }
        return status.checked ? CheckboxStatus.CHECKED : CheckboxStatus.EMPTY;
      });
    } catch {} // eslint-disable-line no-empty
  };

  const selectAllRows = (checked: boolean): void => {
    tableApi.forEachNode((row) => {
      if (row.id) {
        tableApi.getRowNode(row.id)?.setSelected(checked);
      }
    });
  };

  const onChange = (): void => {
    if (checkboxStatus === CheckboxStatus.INDETERMINATE) {
      selectAllRows(true);
      setCheckboxStatus(CheckboxStatus.CHECKED);
    } else {
      selectAllRows(checkboxStatus !== CheckboxStatus.CHECKED);
      setCheckboxStatus((prev) => {
        return prev === CheckboxStatus.CHECKED ? CheckboxStatus.EMPTY : CheckboxStatus.CHECKED;
      });
    }

    onSelectionChanged?.({ api: tableApi });
  };

  const getCheckboxStatus = (): string =>
    ({
      [CheckboxStatus.INDETERMINATE]: 'ag-indeterminate',
      [CheckboxStatus.CHECKED]: 'ag-checked',
      [CheckboxStatus.EMPTY]: '',
    })[checkboxStatus];

  useEffect(() => {
    tableApi.addEventListener('selectionChanged', setCheckboxStatusPerPage);
    tableApi.addEventListener('paginationChanged', setCheckboxStatusPerPage);

    return () => {
      tableApi.removeEventListener('selectionChanged', setCheckboxStatusPerPage);
      tableApi.removeEventListener('paginationChanged', setCheckboxStatusPerPage);
    };
  }, []);

  return (
    <div>
      {colDef?.headerCheckboxSelection && (
        <div data-test-id="creatives-header-checkbox" role="presentation" onClick={onChange} className="relative">
          <div
            data-test-id="creatives-header-checkbox-status"
            className={cx(`ag-input-wrapper ag-checkbox-input-wrapper -top-[5px] ${getCheckboxStatus()}`)}
          />
        </div>
      )}
      {colDef.sortable ? (
        <div role="presentation" className="flex space-x-0" onClick={onSortRequested}>
          {displayName} {sort}
        </div>
      ) : (
        <div className="sub-header-sm absolute bottom-1 ml-1.5">{displayName}</div>
      )}
    </div>
  );
};

HeaderRenderer.defaultProps = {
  onSelectionChanged: () => null,
};

export default HeaderRenderer;
