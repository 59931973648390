import { useState, useEffect } from 'react';

import withCancelRequest from 'components/hocs/withCancelRequest';
import SummaryBar from 'components/common/SummaryBar';
import Card from 'components/patterns/Card';
import { CardType } from 'components/patterns/Card/Card.types';
import { TradingModel } from 'consts/tradingModel';
import { fetchStats } from 'modules/api/revenue';
import InfoMessage, { INFO_TYPE } from 'components/common/Deal/InfoMessage';
import { TooltipDirection } from 'components/patterns/Tooltip';
import { getHoursAndMinutesFromDate, getWeekdayNameFromDate } from 'utils/dateFormatUtil';
import { useSelector } from 'react-redux';
import { notifyError } from 'store/notification/reducer';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getFilterQueryString } from 'utils/reportingQueryString';
import { GeneralStats, PeriodicStats, RevenueStats, RootState } from './Stats.types';
import { getStats } from './utils';

const DEFAULT_GENERAL_STATS: GeneralStats = {
  bidRequests: 0,
  bidResponses: 0,
  revenue: 0,
  soldImpressions: 0,
  winAverageCPM: 0,
};

const DEFAULT_PERIODIC_STATS: PeriodicStats = {
  current: DEFAULT_GENERAL_STATS,
  previous: DEFAULT_GENERAL_STATS,
};

const DEFAULT_REVENUE_STATS: RevenueStats = {
  dailyStat: DEFAULT_PERIODIC_STATS,
  weeklyStat: DEFAULT_PERIODIC_STATS,
  tradingModel: TradingModel.PLAY,
};

const Stats: React.FC = () => {
  const [stats, setStats] = useState<RevenueStats>(DEFAULT_REVENUE_STATS);
  const timezone = useSelector((state: RootState) => state.header.timezone);
  const time = useSelector((state: RootState) => state.dealDashboard.filters.time);
  const cancelFunctions = useCancelRequest();

  const getHoursMinutesDateDescription = (): string => {
    const hoursMinutesDate = getHoursAndMinutesFromDate(new Date(), timezone.code);

    return `Comparing all deals revenue yesterday until ${hoursMinutesDate} to today's revenue until ${hoursMinutesDate}`;
  };

  const getShortWeekdayNameDescription = (): string => {
    const shortWeekdayName = getWeekdayNameFromDate(new Date(), timezone.code, true);

    return `Comparing all deals revenue this week Mon-${shortWeekdayName} to last weeks revenue until Mon-${shortWeekdayName}`;
  };

  useEffect(() => {
    const fetchStatsData = async (): Promise<void> => {
      try {
        const result: RevenueStats = await fetchStats(
          cancelFunctions,
          getFilterQueryString({ time } as any, timezone), // eslint-disable-line @typescript-eslint/no-explicit-any
        );
        setStats(result);
      } catch (error) {
        notifyError({ message: error.message });
        setStats(DEFAULT_REVENUE_STATS);
      }
    };

    fetchStatsData();
  }, [timezone, time]);

  return (
    <Card cardType={CardType.ELEVATED}>
      <div className="p-4">
        <div className="flex">
          <div className="mt-0 w-64 flex">
            <h3 className="body-base text-neutral-900">
              Today vs <span className="text-neutral-600">Yesterday</span>
            </h3>
            {timezone.code ? (
              <div className="pl-2">
                <InfoMessage type={INFO_TYPE.TOOLTIP} direction={TooltipDirection.BOTTOM_RIGHT}>
                  {getHoursMinutesDateDescription()}
                </InfoMessage>
              </div>
            ) : null}
          </div>
          <SummaryBar stats={getStats(stats.dailyStat, stats.tradingModel)} />
        </div>
      </div>
      <hr className="-mx-3.5 border-neutral-300" />
      <div className="p-4">
        <div className="flex">
          <div className="mt-0 w-64 flex">
            <h3 className="body-base text-neutral-900">
              Week vs <span className="text-neutral-600">Last week</span>
            </h3>
            {timezone.code ? (
              <div className="pl-2">
                <InfoMessage type={INFO_TYPE.TOOLTIP} direction={TooltipDirection.RIGHT}>
                  {getShortWeekdayNameDescription()}
                </InfoMessage>
              </div>
            ) : null}
          </div>
          <SummaryBar stats={getStats(stats.weeklyStat, stats.tradingModel)} noHeaders />
        </div>
      </div>
    </Card>
  );
};

export default withCancelRequest(Stats);
