import { useEffect, useMemo, useState } from 'react';
import { getPersonTypes } from 'modules/api/commercialEntities';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { OptionType } from 'lib/AsyncSelect/AsyncSelect.types';
import BaseSelect from 'lib/BaseSelect';
import { findOption, transformOptions } from 'lib/BaseSelect/utils';
import { FilterPersonTypeProps, PersonTypeResponse, PersonTypeSelectHandler } from './FilterPersonType.types';

const FilterPersonType: React.FC<FilterPersonTypeProps> = ({ changeFilters, value }) => {
  const cancelFunctions = useCancelRequest();
  const [personTypeOptions, setPersonTypeOptions] = useState<OptionType[]>([]);

  const selectedPersonType = useMemo(() => findOption(personTypeOptions, 'code', value), [personTypeOptions, value]);

  const handleSelectPersonType: PersonTypeSelectHandler = (_name, personType) => {
    changeFilters({ personType: personType?.code });
  };

  useEffect(() => {
    const fetchPersonTypes = async (): Promise<void> => {
      try {
        const response: PersonTypeResponse = await getPersonTypes(cancelFunctions);
        const personTypes = transformOptions(response, { codeKey: 'code', nameKey: 'code' });

        setPersonTypeOptions(personTypes);
      } catch {} // eslint-disable-line no-empty
    };

    fetchPersonTypes();
  }, []);

  return (
    <BaseSelect
      dataTestId="person-type-select"
      isClearable
      name="personType"
      onSelect={handleSelectPersonType}
      options={personTypeOptions}
      placeholder="Select person type"
      selectedValue={selectedPersonType}
    />
  );
};

export default FilterPersonType;
