import PropTypes from 'prop-types';

import { TimelineItem } from 'components/patterns/Timeline';
import ListWithDivider from '../ListWithDivider';

const Location = ({ countries, cities, streets, counties, postCodes }) => (
  <TimelineItem
    title={
      <p className="sub-header-base text-neutral-950-opacity-80" data-test-id="timeline-location-header">
        Location
      </p>
    }
  >
    <div
      className="grid grid-cols-2 gap-x-4 gap-y-1 body-sm text-neutral-950-opacity-80"
      data-test-id="timeline-location-list"
    >
      <p>Country</p>
      <ListWithDivider list={countries} dataTestId="location-countries-list" />
      <p>City</p>
      <ListWithDivider list={cities} dataTestId="location-cities-list" />
      <p>Street</p>
      <ListWithDivider list={streets} dataTestId="location-streets-list" />
      <p>County/State/Province</p>
      <ListWithDivider list={counties} dataTestId="location-counties-list" />
      <p>Postcode</p>
      <ListWithDivider list={postCodes} dataTestId="location-postcodes-list" />
    </div>
  </TimelineItem>
);

Location.propTypes = {
  countries: PropTypes.array,
  streets: PropTypes.array,
  cities: PropTypes.array,
  postCodes: PropTypes.array,
  counties: PropTypes.array,
};

Location.defaultProps = {
  countries: [],
  streets: [],
  cities: [],
  postCodes: [],
  counties: [],
};

export default Location;
