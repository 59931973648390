import { useLayoutEffect, useRef } from 'react';
import { getElementBoundingBox } from 'utils/getElementBoundingBox';

const MAX_ELEMENTS_IN_DROPDOWN = 8;
const HORIZONTAL_MARGIN = 12;
const DEFAULT_CONTENT_ITEM_HEIGHT = 30;

export interface DropdownItemContainerProps {
  dropdownId: string;
  optionName: string;
  depth: number;
}

export const DropdownItemContainer: React.FC<DropdownItemContainerProps> = ({
  dropdownId,
  optionName,
  depth = 0,
  children,
}) => {
  const subMenuRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const ariaLabelNameBase = `${dropdownId}-${optionName}`;

    const parentElement = document.querySelector(`[aria-label="${ariaLabelNameBase}-item-${depth}"]`) as HTMLElement;
    const containerElement = document.querySelector(
      `[aria-label="${ariaLabelNameBase}-container-${depth}"]`,
    ) as HTMLElement;
    const contentElement = document.querySelector(
      `[aria-label="${ariaLabelNameBase}-content-${depth}"]`,
    ) as HTMLElement;

    if (!containerElement || !contentElement || !parentElement) return;

    const pageHeaderHeight = getElementBoundingBox(document.querySelector('header')).height || 0;
    const topMargin = pageHeaderHeight + HORIZONTAL_MARGIN;
    const parentBox = getElementBoundingBox(parentElement);
    const contentBox = getElementBoundingBox(contentElement);
    const contentItemHeight =
      getElementBoundingBox(contentElement.querySelector('li')).height || DEFAULT_CONTENT_ITEM_HEIGHT;
    const maxContentHeight = Math.min(
      contentBox.height,
      window.innerHeight - topMargin - HORIZONTAL_MARGIN,
      Math.ceil(contentItemHeight) * MAX_ELEMENTS_IN_DROPDOWN + HORIZONTAL_MARGIN * 3,
    );

    const baseTopPosition = parentBox.top - HORIZONTAL_MARGIN;
    const bottomExceed = Math.max(0, baseTopPosition + maxContentHeight + HORIZONTAL_MARGIN - window.innerHeight);
    const elementTop = Math.max(topMargin, baseTopPosition - bottomExceed);
    const elementLeft = parentBox.left + parentBox.width;
    const elementBottom = bottomExceed > 0 ? HORIZONTAL_MARGIN : undefined;

    containerElement.style.top = `${elementTop}px`;
    containerElement.style.left = `${elementLeft}px`;
    containerElement.style.bottom = `${elementBottom}px`;
    containerElement.style.height = `${maxContentHeight}px`;
  });

  return (
    <div ref={subMenuRef} className="fixed z-45 -ml-1.5" aria-label={`${dropdownId}-${optionName}-container-${depth}`}>
      <div className="h-full" aria-label={`${dropdownId}-${optionName}-content-${depth}`}>
        <ul className="bg-neutral-100 rounded shadow-dropdownMenu border-primary border-l-2 py-3 min-w-50 overflow-y-auto h-full">
          {children}
        </ul>
      </div>
    </div>
  );
};
