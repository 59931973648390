const merge = require('lodash/merge');
const config = require('./config.common');

module.exports = merge({}, config, {
  environment: 'dev',
  isPublisherActive: true,
  app: {
    protocol: 'https',
    host: 'prog.dev.viooh.net.cn',
    port: null,
  },
  api: {
    BASE_URL: 'https://gateway-api.devel.viooh.net.cn/',
  },
  keycloak: {
    url: 'https://keycloak.devel.viooh.net.cn/',
    realm: 'dev-cn',
    clientId: 'trading-manager-ui-front',
  },
  authProvider: 'Keycloak',
  features: (market) => {
    return {
      ...config.features(market),
      GOOGLEMAPS: false,
    };
  },
});
