import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import arrowSvg from 'assets/icons/arrow_down.svg';
import labsSvg from 'assets/icons/labs.svg';

import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import { FrontEndType } from 'components/common/types/Deal.types';
import Tooltip, { TooltipDirection } from 'components/patterns/Tooltip/Tooltip';

const DealHeader = () => {
  const navigate = useNavigate();
  const isNewDeal = useSelector((state) => state.dealManagement.isNewDeal);
  const dealName = useSelector((state) => state.dealManagement.commonDeal.dealName);
  const frontEndType = useSelector((state) => state.dealManagement.commonDeal.currentLine.frontEndType);

  return (
    <header className="px-8 pt-4">
      <div className="flex items-center space-x-6">
        <Button
          btnShape={ButtonShape.CIRCLE}
          btnType={ButtonType.PRIMARY}
          onClick={() => navigate('/deals')}
          dataTestId="deals-button"
        >
          <SVG className="transform rotate-90 w-4" src={arrowSvg} />
        </Button>
        <div className="w-full h-min flex items-center justify-between" data-test-id="deal-header">
          <h2 className="header-xl text-neutral-900" data-test-id="deal-header-deal-name">
            {isNewDeal ? 'New deal' : dealName}
          </h2>
          {frontEndType === FrontEndType.PLANNER ? (
            <Tooltip
              tooltip={
                <div className="w-88 px-2 text-justify">
                  <p className="mb-2">This deal has been created using the Viooh Labs: Planner.</p>
                  <p>How does this affect my deal?</p>
                  <p>
                    As this deal was created in the Viooh Labs Planner, by editing this deal - the current allocation
                    may be affected as it may contain some audience index(s).
                  </p>
                </div>
              }
              direction={TooltipDirection.BOTTOM_LEFT}
            >
              <div
                className="flex items-center space-x-2 text-pinkRed sub-header-base border rounded-md py-1 px-4"
                data-test-id="planner-header-badge"
              >
                <SVG src={labsSvg} className="w-5 h-5" />
                <p>VIOOH Labs</p>
              </div>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default DealHeader;
