import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';

const SubNavigation = ({ tabsConfig }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const getActiveTab = (config, locationPathName) => {
    return config.find((val) => locationPathName.toLowerCase().includes(val.path.toLowerCase()) && val.isVisible);
  };

  const activeTab = getActiveTab(tabsConfig, location.pathname);

  return (
    <div>
      {tabsConfig.map((item) => {
        return item.isVisible ? (
          <Button
            btnShape={ButtonShape.NORMAL}
            btnType={activeTab?.id === item.id ? ButtonType.NAVIGATION_TAB_ACTIVE : ButtonType.NAVIGATION_TAB}
            onClick={() => {
              navigate(item.path);
            }}
            dataTestId="sub-navigation-tab"
            key={item.id}
          >
            {item.name}
          </Button>
        ) : null;
      })}
    </div>
  );
};

SubNavigation.propTypes = {
  tabsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      path: PropTypes.string,
      isVisible: PropTypes.bool,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default SubNavigation;
