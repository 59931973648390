import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export const handleReportCreationDateTime = (creationDateTime: string, creationTimezone: string): string => {
  const isCreationDateTimeUTC = creationDateTime.endsWith('+00:00');

  const creationDateTimeInUTC = isCreationDateTimeUTC
    ? new Date(creationDateTime)
    : zonedTimeToUtc(new Date(creationDateTime), creationTimezone);
  const currentDateTimeInUTC = zonedTimeToUtc(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone);

  const timeDifferenceInSeconds = differenceInSeconds(currentDateTimeInUTC, creationDateTimeInUTC);
  if (timeDifferenceInSeconds < 60) {
    return `Created ${timeDifferenceInSeconds} sec(s) ago`;
  }

  const timeDifferenceInMinutes = differenceInMinutes(currentDateTimeInUTC, creationDateTimeInUTC);
  if (timeDifferenceInMinutes < 60) {
    return `Created ${timeDifferenceInMinutes} min(s) ago`;
  }

  const timeDifferenceInHours = differenceInHours(currentDateTimeInUTC, creationDateTimeInUTC);
  if (timeDifferenceInHours < 24) {
    return `Created ${timeDifferenceInHours} hour(s) ago`;
  }

  const timeDifferenceInDays = differenceInDays(currentDateTimeInUTC, creationDateTimeInUTC);
  if (timeDifferenceInDays < 31) {
    return `Created ${timeDifferenceInDays} day(s) ago`;
  }

  const timeDifferenceInMonths = differenceInMonths(currentDateTimeInUTC, creationDateTimeInUTC);
  if (timeDifferenceInMonths < 12) {
    return `Created ${timeDifferenceInMonths} month(s) ago`;
  }

  const timeDifferenceInYears = differenceInYears(currentDateTimeInUTC, creationDateTimeInUTC);
  return `Created ${timeDifferenceInYears} year(s) ago`;
};
