import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DealStatus, DealProcessingStatuses } from 'components/common/types/Deal.types';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import {
  createDeal,
  approveDeal,
  rejectDeal,
  cancelDeal,
  reserveDeal,
  createLine,
  updateLine,
  stopLine,
  deleteLine,
  getDealProcessingStatus,
  checkAllocate,
  getAllocatedDeal,
  editDeal,
} from 'modules/api/adsDeal';
import { isReadOnly } from 'utils/isReadOnly';
import { notifySuccess, notifyInfo, notifyError, clearNotification } from 'store/notification/reducer';
import withCancelRequest from 'components/hocs/withCancelRequest';
import {
  getDealWithLineFormData,
  getLineFormData,
  getDealData,
} from 'components/pages/DealWithLines/AdsDealLines/transformPostData';
import {
  combineProposalAllocationAndDealSummary,
  transformAvailability,
} from 'components/pages/DealWithLines/AdsDealLines/transformDealDetails';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { transformBookingStatusCode } from 'components/pages/DealWithLines/common/transformPostData';
import DealActions from 'components/pages/DealWithLines/common/Main/ControllerBar/DealActions';
import LineActions from 'components/pages/DealWithLines/common/Main/ControllerBar/LineActions';
import { getDealDetails } from 'store/dealManagement/actions';
import {
  changeDealCurrentLineAvailabilityAssetsSot,
  changeDealCurrentLineData,
  changeDealData,
  changeDealLineAllocatedImpressions,
  changeFormParams,
  removeTemporaryDealId,
} from 'store/dealManagement/reducer';
import { getIsAdServer } from 'store/publisher/selectors';
import { useCheckProgrammaticLineLevelChange } from 'components/pages/Planner/hooks/useCheckProgrammaticLineLevelChange';
import { useCheckProgrammaticDealLevelChange } from 'components/pages/Planner/hooks/useCheckProgrammaticDealLevelChange';

// eslint-disable-next-line sonarjs/cognitive-complexity
const ControllerBar = ({ dealRef, cancelFunctions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isCpmCampaignLevel = useSelector((state) => state.dealManagement.isCpmCampaignLevel);
  const dealConfig = useSelector((state) => state.dealConfig);
  const lines = useSelector((state) => state.dealManagement.backupFormData.lines);
  const programmatic = useSelector((state) => state.dealManagement.programmatic);
  const commonDeal = useSelector((state) => state.dealManagement.commonDeal);
  const backupDealSummary = useSelector((state) => state.dealManagement.backupFormData.commonDeal.summary);
  const isNewDeal = useSelector((state) => state.dealManagement.isNewDeal);
  const { bookingStatusCode, cpm, currentLine, dealId, dealType } = commonDeal;

  const readOnly = isReadOnly(bookingStatusCode, false);
  const isAdServerMarket = useSelector(getIsAdServer);
  const isDealLevelChange = useCheckProgrammaticDealLevelChange();
  const isLineLevelChange = useCheckProgrammaticLineLevelChange();
  const hasAdsDealLevelCPMEnabled = useHasFeatureAccess(FeatureFlags.ADS_DEAL_LEVEL_CPM);
  const hasSweepTargetingEnabled = useHasFeatureAccess(FeatureFlags.SWEEP_TARGETING);
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);
  const hasOutOfChargeEnabled = useHasFeatureAccess(FeatureFlags.OUT_OF_CHARGE);

  const updateDealDetails = async (campaignID) => {
    if (dealId) {
      await dispatch(
        getDealDetails(
          dealId,
          cancelFunctions,
          navigate,
          dealConfig,
          hasAdsDealLevelCPMEnabled,
          hasSweepTargetingEnabled,
          hasNonGuaranteedExtendedTargetEnabled,
          isAdServerMarket,
        ),
      );
    } else if (campaignID) {
      navigate(`/deal/${campaignID}`, { replace: true });
    } else {
      navigate('/deals', { replace: true });
    }
  };

  const dealStatusComplete = async (result) => {
    dispatch(notifySuccess({ message: `Deal saved Successfully - ${result.campaignID}` }));

    dispatch(changeFormParams({ isForecastedAllocation: bookingStatusCode === DealStatus.PENDING_APPROVAL }));
    updateDealDetails(result.campaignID);
  };

  const lineStatusComplete = async (result) => {
    dispatch(notifySuccess({ message: `Success! Line ${currentLine.name} was saved` }));

    dispatch(changeFormParams({ isForecastedAllocation: bookingStatusCode === DealStatus.PENDING_APPROVAL }));
    dispatch(changeDealCurrentLineData({ isCurrentLineWithProposalAllocation: false }));
    updateDealDetails(result.dealId);
  };

  const handleMessages = (messages) => {
    const errors = messages.map(({ lineId, message }) => {
      const lineName = lines.find(({ id }) => id === lineId)?.name || currentLine.name;

      return (
        <p>
          {lineName}: {message}
        </p>
      );
    });

    dispatch(clearNotification());
    dispatch(notifyError({ message: errors, timeout: NOTIFICATION_TIMEOUT.LONG }));
  };

  const handleDealRequest = async (requestHandler, pollCompleteHandler, catchHandler = () => undefined) => {
    dispatch(changeFormParams({ isEditingDisabled: true }));
    dispatch(notifyInfo({ message: 'We are validating your solution', timeout: NOTIFICATION_TIMEOUT.NEVER }));

    try {
      const result = await requestHandler();
      const poll = getDealProcessingStatus(result.campaignID || dealId, cancelFunctions);

      poll(({ data: { processingStatus, messages, campaignID } }) => {
        if (processingStatus === DealProcessingStatuses.ERROR) {
          if (messages) handleMessages(messages);
          updateDealDetails(campaignID);
          dispatch(changeFormParams({ isEditingDisabled: false }));
          return false;
        }

        if (processingStatus === DealProcessingStatuses.STILL_PROCESSING) {
          return true;
        }

        pollCompleteHandler(result);
        if (messages.length) handleMessages(messages);
        dispatch(changeFormParams({ isEditingDisabled: false }));
        return false;
      }, 1000);
    } catch {
      catchHandler();
      dispatch(changeFormParams({ isEditingDisabled: false }));
    }

    dispatch(removeTemporaryDealId());
  };

  const editDealHandler = async (catchHandler) => {
    const data = {
      ...getDealData(
        {
          ...commonDeal,
          ...programmatic,
          cpm: isCpmCampaignLevel ? cpm : null,
          hasAdsDealLevelCPMEnabled,
        },
        isAdServerMarket,
      ),
      bookingStatusCode: undefined,
    };
    await handleDealRequest(() => editDeal(cancelFunctions, dealId, data), dealStatusComplete, catchHandler);
  };

  const approveDealHandlerRequest = async () => {
    await handleDealRequest(() => approveDeal(cancelFunctions, { dealId }), dealStatusComplete);
  };

  const updateDealAndApprove = async (catchHandler) => {
    const data = {
      ...getDealData(
        {
          ...commonDeal,
          ...programmatic,
          cpm: isCpmCampaignLevel ? cpm : null,
          hasAdsDealLevelCPMEnabled,
        },
        isAdServerMarket,
      ),
      bookingStatusCode: undefined,
    };

    await handleDealRequest(() => editDeal(cancelFunctions, dealId, data), approveDealHandlerRequest, catchHandler);
  };

  const updateLineAndApprove = async () => {
    const formData = getLineFormData(commonDeal, { isAdServerMarket }, isCpmCampaignLevel);

    await handleDealRequest(
      () =>
        updateLine(cancelFunctions, formData, {
          dealId,
          lineId: currentLine.id,
        }),
      approveDealHandlerRequest,
    );
  };

  const approveDealHandler = async (catchHandler) => {
    if (!isDealLevelChange && !isLineLevelChange) {
      await approveDealHandlerRequest();
    }

    if (!isDealLevelChange && isLineLevelChange) {
      await updateLineAndApprove();
    } else if (isDealLevelChange) {
      await updateDealAndApprove(catchHandler);
    }
  };

  const rejectDealHandler = async () => {
    await handleDealRequest(() => rejectDeal(cancelFunctions, { dealId }), dealStatusComplete);
  };

  const cancelDealHandler = async () => {
    await handleDealRequest(() => cancelDeal(cancelFunctions, { dealId }), dealStatusComplete);
  };

  const reserveDealHandler = () =>
    handleDealRequest(() => reserveDeal(cancelFunctions, { dealId }), dealStatusComplete);

  const submitNewDeal = async (bookingCode = '') => {
    const formData = getDealWithLineFormData(
      {
        ...commonDeal,
        ...programmatic,
        cpm: isCpmCampaignLevel ? cpm : null,
        bookingStatusCode: transformBookingStatusCode(bookingCode),
        hasAdsDealLevelCPMEnabled,
      },
      { isAdServerMarket },
    );
    await handleDealRequest(() => createDeal(cancelFunctions, formData), dealStatusComplete);
  };

  const saveDeal = (bookingCode, catchHandler = () => undefined) => {
    if (!dealId) {
      submitNewDeal(bookingCode);
      return;
    }

    switch (bookingCode) {
      case DealStatus.PENDING_APPROVAL:
      case DealStatus.APPROVED:
        approveDealHandler(catchHandler);
        break;
      case DealStatus.REJECTED:
        rejectDealHandler();
        break;
      case DealStatus.TERMINATED:
      case DealStatus.CANCELLED:
        cancelDealHandler();
        break;
      case DealStatus.RESERVED:
        reserveDealHandler();
        break;
      default:
        editDealHandler(catchHandler);
    }
  };

  const createLineHandler = async () => {
    const formData = getLineFormData(commonDeal, { isAdServerMarket }, isCpmCampaignLevel);

    await handleDealRequest(() => createLine(cancelFunctions, formData, { dealId }), lineStatusComplete);
  };

  const updateLineHandler = async () => {
    const formData = getLineFormData(commonDeal, { isAdServerMarket }, isCpmCampaignLevel);

    await handleDealRequest(
      // eslint-disable-next-line sonarjs/no-identical-functions
      () =>
        updateLine(cancelFunctions, formData, {
          dealId,
          lineId: currentLine.id,
        }),
      lineStatusComplete,
    );
  };

  const stopLineHandler = async () => {
    await handleDealRequest(() => stopLine(cancelFunctions, { dealId, lineId: currentLine.id }), lineStatusComplete);
  };

  const deleteLineHandler = async () => {
    await handleDealRequest(() => deleteLine(cancelFunctions, { dealId, lineId: currentLine.id }), lineStatusComplete);
  };

  const checkAllocatePollComplete = async (result) => {
    try {
      const currentLineAllocationProposal = await getAllocatedDeal(result.campaignID, cancelFunctions, dealType, lines);

      const summary = combineProposalAllocationAndDealSummary(
        isNewDeal,
        !currentLine.lineId,
        currentLine.preservedAllocation,
        currentLineAllocationProposal,
        commonDeal.summary,
        backupDealSummary,
        dealType,
        hasSweepTargetingEnabled,
        hasNonGuaranteedExtendedTargetEnabled,
      );

      dispatch(changeDealLineAllocatedImpressions(transformAvailability(currentLineAllocationProposal)));

      dispatch(
        changeDealData({
          summary,
        }),
      );
      dispatch(changeFormParams({ temporaryDealId: result.campaignID }));

      let adjustedSot = 0;
      if (currentLineAllocationProposal?.summary?.adjustedSots?.length === 1) {
        [adjustedSot] = currentLineAllocationProposal.summary.adjustedSots;
      }

      dispatch(
        changeDealCurrentLineData({
          deliveredImpressions: currentLineAllocationProposal.deliveredImpressions,
          isCurrentLineWithProposalAllocation: true,
          adjustedSot,
        }),
      );

      dispatch(
        changeDealCurrentLineAvailabilityAssetsSot(
          summary.availability.assets.map((asset) => ({
            frameId: asset.frameId,
            sot: asset.sot,
          })),
        ),
      );

      dispatch(
        notifySuccess({
          message: 'The calculated Qty is not yet reserved. Click Send for approval/Confirm/Save to proceed',
        }),
      );

      dispatch(changeFormParams({ isForecastedAllocation: true }));
    } catch {
      dispatch(clearNotification());
    }

    dispatch(changeFormParams({ isEditingDisabled: false }));
  };

  const checkAllocateHandler = async () => {
    const formData = getDealWithLineFormData(
      {
        ...commonDeal,
        ...programmatic,
        cpm: isCpmCampaignLevel ? cpm : null,
        bookingStatusCode: DealStatus.PROPOSAL,
        hasAdsDealLevelCPMEnabled,
      },
      { isAdServerMarket },
    );

    await handleDealRequest(() => checkAllocate(cancelFunctions, formData), checkAllocatePollComplete);
  };

  if (readOnly) return null;

  return (
    <div
      className="flex bg-neutral-50 py-2.5 rounded-t-xl border border-neutral-950-opacity-10 divide-x divide-neutral-950-opacity-10 sticky bottom-0 w-max m-auto shadow-lg"
      data-test-id="controller-bar"
    >
      <LineActions
        createLine={createLineHandler}
        updateLine={updateLineHandler}
        deleteLine={deleteLineHandler}
        terminateLine={stopLineHandler}
      />
      <DealActions
        saveDeal={saveDeal}
        checkAllocate={checkAllocateHandler}
        dealRef={dealRef}
        hasOutOfChargeEnabled={hasOutOfChargeEnabled}
      />
    </div>
  );
};

ControllerBar.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  dealRef: PropTypes.shape({ current: PropTypes.object }),
};

ControllerBar.defaultProps = {
  dealRef: null,
};

export default withCancelRequest(ControllerBar);
