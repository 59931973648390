import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formatNumber, formatPercent } from 'modules/I18N';
import { transformColumnChartData } from 'utils/formatters';
import { datePercentChartOptions, calculateDealPercentChartOptions } from 'utils/chartOptions';
import HeroChart from '../HeroChart';

const TradedWinRate = ({ tradedWinData, isDealSelected }) => {
  const { localeCode } = useSelector((state) => state.publisher.configuration);
  const isChartsOpen = useSelector((state) => state.dealDashboard.isChartsOpen);
  const { tradedWinRateGraph, totalBidResponses, soldTrades, tradedWinRate } = tradedWinData;

  return (
    <HeroChart
      dataTestId="traded-win-rate-chart"
      chartTitle="Traded win rate"
      chartData={transformColumnChartData(tradedWinRateGraph, isDealSelected ? 'date' : 'dealId', 'tradedWinRate')}
      table={[
        {
          title: 'Bid res.',
          titleFullText: 'Bid responses',
          value: formatNumber({ value: totalBidResponses, localeCode }),
        },
        {
          title: 'Sold bid res.',
          titleFullText: 'Sold Bid responses',
          value: formatNumber({ value: soldTrades, localeCode }),
        },
        { title: 'Traded win rate', value: formatPercent({ value: tradedWinRate / 100, localeCode }) },
      ]}
      isSplineChart={isDealSelected}
      chartOptions={
        isDealSelected ? datePercentChartOptions({ localeCode }) : calculateDealPercentChartOptions(isChartsOpen)
      }
    />
  );
};

TradedWinRate.propTypes = {
  tradedWinData: PropTypes.object.isRequired,
  isDealSelected: PropTypes.bool,
};

TradedWinRate.defaultProps = {
  isDealSelected: false,
};

export default TradedWinRate;
