export interface SectionButtonChipProps {
  label: string;
  value?: string;
  labelStyle?: ChipColors;
  valueStyle?: ChipColors;
  dataTestId?: string;
}

export enum ChipColors {
  LIGHT_PURPLE = 'bg-primary-50 text-primary-600',
  DARK_PURPLE = 'bg-primary-700 text-primary-50',
  LIGHT_YELLOW = 'bg-yellow-100 text-yellow-800',
  DARK_YELLOW = 'bg-yellow-500 text-yellow-50',
  GREEN = 'bg-green-500 text-green-50',
  NEUTRAL = 'bg-neutral-100 text-neutral-900',
}
