import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Store } from 'components/common/types/Store.types';
import { FormatTypeEnum, formatValue } from 'modules/I18N';
import { handleValueFormat } from 'components/pages/DealWithLines/common/RightSidebar/utils';
import listSvg from 'assets/icons/list.svg';
import Button, { Color, Size } from 'lib/Button';
import { DealSummaryFrameList } from 'components/pages/DealWithLines/common/RightSidebar/DealSummaryFrameList/DealSummaryFrameList';
import { DealSummaryCard } from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCard/DealSummaryCard';
import DealSummaryCardSection from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCardSection';
import { DealSummaryCardSectionLineType } from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCardSection/DealSummaryCardSection.types';
import { DealStatus, DealType } from 'components/common/types/Deal.types';
import { CardTheme } from 'lib/Card/Card.types';
import { LevelType } from 'consts/thoughtspot';
import { AvailabilityCardProps } from './AvailabilityCard.types';

export const AvailabilityCard: React.FC<AvailabilityCardProps> = ({
  totalCost,
  availableImpressions,
  availableFrames,
  assets,
  isVisible,
  defaultAllocationReportLevelType = LevelType.LINE,
}) => {
  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const isNonGuaranteed = dealType !== DealType.GUARANTEED;
  const currentLinebookingStatusCode = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.bookingStatusCode,
  );
  const isReadOnly =
    isNonGuaranteed &&
    currentLinebookingStatusCode &&
    [DealStatus.CANCELLED, DealStatus.TERMINATED, DealStatus.ENDED].includes(currentLinebookingStatusCode);

  return (
    <DealSummaryCard header="AVAILABILITY" isVisible={isVisible} theme={CardTheme.SECONDARY}>
      {isNonGuaranteed ? (
        <DealSummaryCardSection
          lines={[
            {
              label: currencyCode,
              value: formatValue(totalCost, FormatTypeEnum.CURRENCY, currencyCode, localeCode),
              lineType: DealSummaryCardSectionLineType.SUBHEADER,
            },
          ]}
          isReadOnly={isReadOnly}
        />
      ) : null}
      <DealSummaryCardSection
        lines={[
          {
            label: 'Impressions',
            value: handleValueFormat({
              valueType: FormatTypeEnum.ROUNDED_NUMBER,
              value: availableImpressions,
              currencyCode,
              localeCode,
            }),
            lineType: DealSummaryCardSectionLineType.SUBHEADER,
          },
        ]}
        isReadOnly={isReadOnly}
      />
      <DealSummaryCardSection
        lines={[
          {
            label: 'Frames',
            value: handleValueFormat({
              valueType: FormatTypeEnum.ROUNDED_NUMBER,
              value: availableFrames,
              currencyCode,
              localeCode,
            }),
            lineType: DealSummaryCardSectionLineType.SUBHEADER,
          },
        ]}
        isReadOnly={isReadOnly}
      >
        {defaultAllocationReportLevelType === LevelType.LINE ? (
          <DealSummaryFrameList
            assets={assets}
            actionButton={(onClick) => (
              <Button color={Color.TERTIARY} label="Frame list" size={Size.MEDIUM} svg={listSvg} onClick={onClick} />
            )}
          />
        ) : null}
      </DealSummaryCardSection>
    </DealSummaryCard>
  );
};
