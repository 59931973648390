import { ReactElement } from 'react';
import infoSvg from 'assets/icons/info.svg';
import warning from 'assets/icons/warning.svg';
import StatusIcon from 'components/patterns/StatusIcon/StatusIcon';
import { DealLinesIconProps, DealLinesStatus } from './DealLinesIcon.types';

const dealLineStatusIcon: Record<DealLinesStatus, { icon: string; iconColor: string }> = {
  [DealLinesStatus.BOOKING_STATUS_FAILED]: {
    icon: infoSvg,
    iconColor: 'text-pinkRed-500',
  },
  [DealLinesStatus.LINE_WITH_OUT_OF_CHARGE_FRAMES]: {
    icon: warning,
    iconColor: 'text-yellow',
  },
};

const DealLinesIcon: React.FC<DealLinesIconProps> = ({ status }): ReactElement => {
  return (
    <div className="p-2 flex">
      <StatusIcon
        icon={dealLineStatusIcon[status].icon}
        iconStyle="w-full"
        iconColor={dealLineStatusIcon[status].iconColor}
      />
    </div>
  );
};

export default DealLinesIcon;
