/* eslint-disable sonarjs/cognitive-complexity */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

import moneySvg from 'assets/icons/money.svg';
import Checkbox from 'components/patterns/Checkbox';
import { isReadOnly } from 'utils/isReadOnly';
import DealCpm from 'components/common/Deal/DealCpm';
import { changeDealCurrentLineData, changeDealData, changeIsCpmCampaignLevel } from 'store/dealManagement/reducer';
import { DealStatus } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import InfoMessage from 'components/common/Deal/InfoMessage';
import { BorderTheme, CardTheme } from 'components/patterns/Card/Card.types';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import RequiredAsterisk from 'components/common/RequiredAsterisk';
import { getLineLevelCpmSettings } from 'components/pages/DealWithLines/common/Main/Deal/selectors';
import { Theme } from 'components/patterns/Checkbox/Checkbox.types';

const AdsDealLevelCpm: React.FC = () => {
  const dispatch = useDispatch();
  const isReseller = useSelector(getIsReseller);
  const { isLineLevelCpmVisible, isLineLevelCpmEditable } = useSelector(getLineLevelCpmSettings);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const isCpmCampaignLevel = useSelector((state: Store) => state.dealManagement.isCpmCampaignLevel);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const cpm = useSelector((state: Store) => state.dealManagement.commonDeal.cpm);

  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled);
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;

  const onIsCpmCampaignLevelChange = useCallback(() => {
    const isNewCpmCampaignLevelEnabled = !isCpmCampaignLevel;

    if (!isNewCpmCampaignLevelEnabled) {
      dispatch(changeDealData({ cpm: 0 }));

      const line = document.getElementById('line-level');
      if (line) line.scrollIntoView({ behavior: 'smooth' });
    }

    dispatch(changeIsCpmCampaignLevel(isNewCpmCampaignLevelEnabled));
  }, [isCpmCampaignLevel]);

  const onCpmChange = useCallback(
    (newCpm: number) => {
      dispatch(changeDealData({ cpm: newCpm }));

      if (isCpmCampaignLevel && !isReseller) {
        dispatch(changeDealCurrentLineData({ cpm: newCpm }));
      }
    },
    [isCpmCampaignLevel],
  );

  const isAdServerMarket = useSelector(getIsAdServer);

  return (
    <>
      <hr className="border-t border-neutral-300 mt-6 mb-10" />
      <div className="flex justify-between mb-6 text-neutral-100-opacity-70" id="anch-deal-cpm">
        <div className="flex items-center">
          <SVG src={moneySvg} className="align-sub w-[18px] h-[18px] mr-3 text-neutral-600" />
          <span className="sub-header-lg text-neutral-950">CPM pricing</span>
          {isCpmCampaignLevel ? <RequiredAsterisk dataTestId="cpm-pricing-required" /> : null}
        </div>
        <div className="flex items-center space-x-4">
          {isLineLevelCpmVisible ? (
            <>
              <Checkbox
                id="ads-deal-level-cpm-checkbox"
                onChange={onIsCpmCampaignLevelChange}
                isChecked={isCpmCampaignLevel}
                theme={Theme.DARK}
                isDisabled={!isLineLevelCpmEditable}
              />
              <p className="body-base text-neutral-950">Add a CPM for your Campaign</p>
            </>
          ) : (
            <p className="body-sm text-neutral-600">Set up your pricing</p>
          )}
        </div>
      </div>
      <div className="leading-5 mb-4">
        <DealCpm
          id="deal-cpm"
          value={cpm}
          onChange={onCpmChange}
          isDisabled={(readOnly && !isPendingReservation) || (isAdServerMarket && !isCpmCampaignLevel)}
          isRequired={isCpmCampaignLevel}
        />
      </div>
      {isLineLevelCpmVisible ? (
        <div data-test-id="ads-deal-level-cpm-info-message" className="w-1/2 mt-2 ml-auto">
          <InfoMessage borderTheme={BorderTheme.DARK} cardTheme={CardTheme.PRIMARY}>
            <p className="mb-2 body-base text-primary-600">
              By deselecting CPM at Campaign level, you can set up different CPM’s for each of your deal-lines.
            </p>
            <p className="body-base text-primary-600">
              If a CPM is entered at Campaign level, then all lines will inherit the CPM set here.
            </p>
          </InfoMessage>
        </div>
      ) : null}
    </>
  );
};

export default AdsDealLevelCpm;
/* eslint-enable sonarjs/cognitive-complexity */
