import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import Tooltip from 'components/patterns/Tooltip';
import imageSvg from 'assets/icons/image.svg';
import scrollingSvg from 'assets/icons/scrolling.svg';
import videoSvg from 'assets/icons/video.svg';

const AssetType = ({ value }) => {
  switch (value) {
    case 'STATIC':
      return (
        <Tooltip tooltip="Static">
          <SVG src={imageSvg} className="fill-current text-neutral-600" />
        </Tooltip>
      );
    case 'SCROLLING':
      return (
        <Tooltip tooltip="Scrolling">
          <SVG src={scrollingSvg} className="fill-current text-neutral-600" />
        </Tooltip>
      );
    case 'DIGITAL':
      return (
        <Tooltip tooltip="Digital">
          <SVG src={videoSvg} className="fill-current text-neutral-600" />
        </Tooltip>
      );
    default:
      return value;
  }
};

AssetType.propTypes = {
  value: PropTypes.string,
};

AssetType.defaultProps = {
  value: '',
};

export default AssetType;
