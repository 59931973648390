import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import GoogleMaps from 'components/patterns/GoogleMaps';
import { getIsReseller } from 'store/publisher/selectors';

import FrameInfo from './FrameInfo';

const AssetsMap = ({ assets }) => {
  const { latitude, longitude } = useSelector((state) => state.publisher.configuration);
  const [markerData, setMarkerData] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: latitude,
    lng: longitude,
  });
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [frameInfo, setFrameInfo] = useState({});
  const isReseller = useSelector(getIsReseller);

  useEffect(() => {
    setMarkerData(assets.map((item) => ({ ...item, id: item.frameId, lat: item.latitude, lng: item.longitude })));
    setMapCenter({
      lat: assets[0]?.latitude ?? latitude,
      lng: assets[0]?.longitude ?? longitude,
    });
  }, [assets]);

  const onMarkerMouseOver = (frame) => {
    const { frameId, impressions, lat, lng, mediaOwner } = frame;

    setFrameInfo({ frameId, impressions, mediaOwner });
    setInfoWindowPosition({ lat, lng });
  };

  const onMarkerMouseOut = () => {
    setFrameInfo({});
    setInfoWindowPosition(null);
  };

  return (
    <GoogleMaps
      key={JSON.stringify(markerData)}
      markerData={markerData}
      center={mapCenter}
      infoWindowComponent={<FrameInfo config={frameInfo} isReseller={isReseller} />}
      onMarkerMouseOver={onMarkerMouseOver}
      onMarkerMouseOut={onMarkerMouseOut}
      infoWindowPosition={infoWindowPosition}
    />
  );
};

const AssetsModel = PropTypes.arrayOf(
  PropTypes.shape({
    frameId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    impressions: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
);

AssetsMap.propTypes = {
  assets: AssetsModel,
};

AssetsMap.defaultProps = {
  assets: [],
};

export default AssetsMap;
