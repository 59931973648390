import { DealPriority, DealPriorityLabel } from 'components/common/types/Deal.types';
import { CodeNameModel } from 'components/common/types';

export const dealPriorityOptions = [
  { label: DealPriorityLabel.STANDARD, value: DealPriority.STANDARD },
  { label: DealPriorityLabel.PREFERRED, value: DealPriority.PREFERRED },
];

export const codeNameDealPriorityOptions: CodeNameModel[] = [
  { code: DealPriority.STANDARD, name: DealPriorityLabel.STANDARD },
  { code: DealPriority.PREFERRED, name: DealPriorityLabel.PREFERRED },
];
