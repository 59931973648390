/* eslint-disable sonarjs/cognitive-complexity */

import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import plusSvg from 'assets/icons/plus.svg';
import imagesSvg from 'assets/icons/images-square.svg';
import infoSvg from 'assets/icons/info.svg';
import maximizeSvg from 'assets/icons/maximize.svg';
import { MotionType } from 'consts/motionType';
import { CREATIVE_STATUS_BADGE } from 'consts/creative';
import { BadgeSize } from 'components/patterns/Badge';
import Tooltip, { TooltipDirection } from 'components/patterns/Tooltip';
import StatusRenderer from 'components/common/StatusRenderer';
import { changeSelectedPanelId } from 'store/pages/contentManagement/creativeSetDetails/reducer';
import Lightbox from 'components/patterns/Lightbox';
import { Creative, CreativeType } from 'components/common/types/Creative.types';
import SectionButton from 'lib/SectionButton';
import { SectionButtonTheme } from 'lib/SectionButton/SectionButton.types';
import { PanelProps } from './Panel.types';
import MotionChip from './MotionChip';
import { FIXED_IMAGE_HEIGHT } from './consts';
import LinkChip from './LinkChip';

const Panel: React.FC<PanelProps> = ({
  dataTestId,
  frameIndex,
  isDisabled = false,
  isNewSet,
  panel,
}): React.ReactElement => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const creativeDetails: Partial<Creative> = {
    width: panel.width,
    height: panel.height,
    previewURL: panel.creative?.previewUrl,
    downloadURL: panel.creative?.previewUrl,
    type: panel.creative?.type,
    id: panel.creative?.id,
    statusCode: panel.creative?.status,
    thumbnail: panel.creative?.thumbnailUrl,
  };
  const isLinkedByDefault = frameIndex !== 0;
  const isFrameDisabled = isDisabled || isLinkedByDefault;
  const isPortrait = panel.height > panel.width;
  const adjustedWidth = (panel.width / panel.height) * FIXED_IMAGE_HEIGHT;
  const creativeHeightWidth = { height: `${FIXED_IMAGE_HEIGHT}px`, width: 'auto' };

  const onMouseOver = (): void => setIsHovering(true);
  const onMouseOut = (): void => setIsHovering(false);
  const toggleIsOpen = (): void => setIsOpen(!isOpen);

  const handleChangeSelectedPanelId = (): void => {
    dispatch(changeSelectedPanelId(panel.id));
  };

  const motionTag = (
    <>
      <div
        className="relative block cursor-pointer"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onFocus={onMouseOver}
        onBlur={onMouseOut}
        onClick={toggleIsOpen}
        role="presentation"
      >
        {panel.creative?.type === CreativeType.BANNER ? (
          <img
            src={panel.creative?.previewUrl}
            alt="creative"
            data-test-id="creative-sets-preview-banner"
            className="rounded-lg"
            style={creativeHeightWidth}
          />
        ) : (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video
            loop
            autoPlay
            className="rounded-lg"
            data-test-id="creative-sets-preview-video"
            style={creativeHeightWidth}
          >
            <source src={panel.creative?.previewUrl} type="video/mp4" />
          </video>
        )}

        {isHovering && (
          <div
            data-test-id="creative-preview-expand"
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 rounded-lg overflow-visible pointer-events-none"
          >
            <div className="absolute top-0 left-0 inset-y-1/2 w-full h-full flex justify-center items-center">
              <SVG src={maximizeSvg} className="text-neutral-100" />
            </div>
          </div>
        )}
      </div>
      <Lightbox isOpen={isOpen} creatives={[creativeDetails as Creative]} creativeIndex={0} onClose={toggleIsOpen} />
    </>
  );

  const frameId = useMemo(() => {
    if (!panel.order) return null;

    return panel.frameCodes?.join(', ');
  }, [panel.order, panel.frameCodes]);

  return (
    <div className="flex flex-col" data-test-id={dataTestId}>
      {isNewSet && <div className="flex h-6 ml-auto mr-0.5 mb-1">{isLinkedByDefault && <LinkChip />}</div>}
      <div
        className={cx('bg-neutral-50 border-2 rounded-xl p-4 flex-shrink-0', {
          'border-orange-600': isLinkedByDefault,
          'border-transparent': !isLinkedByDefault || !isNewSet,
          'opacity-90': isFrameDisabled,
        })}
      >
        {panel.order && (
          <div className="bg-gray-100 rounded-md w-32 mb-1">
            <div className="px-4 py-1">{`Order ${panel.order}`}</div>
          </div>
        )}
        <div
          className={cx('relative flex justify-center mb-4', {
            'border rounded-xl': !panel.creative,
          })}
          style={{
            width: `${adjustedWidth}px`,
            height: `${FIXED_IMAGE_HEIGHT}px`,
          }}
        >
          {panel.creative ? (
            motionTag
          ) : (
            <div className="flex flex-col items-center justify-center p-2 w-full">
              <div className="flex flex-1 items-center justify-center text-primary-600-opacity-10">
                <SVG src={imagesSvg} />
              </div>
              <SectionButton
                isDisabled={isFrameDisabled}
                icon={plusSvg}
                label="Add image"
                onClick={handleChangeSelectedPanelId}
                theme={SectionButtonTheme.DASHED}
              />
            </div>
          )}
          {!isFrameDisabled && isNewSet && panel.creative ? (
            <button
              data-test-id={`add-creative-${panel.count}`}
              type="button"
              className={`absolute top-0 h-full w-full rounded-lg flex justify-center items-center bg-black bg-opacity-0 hover:bg-opacity-50 text-neutral-100-opacity-50 hover:text-neutral-100 ${
                panel.creative?.previewUrl ? 'opacity-0 hover:opacity-100' : ''
              }`}
              onClick={handleChangeSelectedPanelId}
              name={`add creative ${panel.count}`}
            >
              <SVG src={plusSvg} className="h-11 w-11" />
            </button>
          ) : null}
          {panel.creative ? (
            <div className="absolute bottom-0 z-1 p-3 w-full flex justify-between items-center space-x-3">
              <p className="text-neutral-100 truncate bg-neutral-950-opacity-80 rounded-xl px-3 py-0.5">
                <span className="sub-header-base">ID:</span> <span className="body-base">{panel.creative?.id}</span>
              </p>
              <StatusRenderer
                value={panel.creative?.status}
                statuses={CREATIVE_STATUS_BADGE}
                badgeSize={BadgeSize.NORMAL}
                direction={TooltipDirection.TOP}
              />
            </div>
          ) : null}
        </div>
        <div className="text-neutral-950">
          <p className="flex flex-col gap-x-3  justify-start">
            <div className="sub-header-base">{`${isPortrait ? 'Portrait' : 'Landscape'} ${panel.width}x${
              panel.height
            }`}</div>
            {!!frameId && (
              <Tooltip tooltip={frameId} direction={TooltipDirection.TOP}>
                <div className="w-52 truncate body-sm">{frameId}</div>
              </Tooltip>
            )}
          </p>
          <div className="flex space-x-3 items-center justify-between mt-7">
            {panel.motionType === MotionType.STILL ? (
              <MotionChip motionType={panel.motionType} />
            ) : (
              <div className="flex space-x-3">
                <MotionChip motionType={MotionType.STILL} />
                <MotionChip motionType={MotionType.MOVING} />
              </div>
            )}
            {isFrameDisabled ? (
              <Tooltip
                direction={TooltipDirection.LEFT}
                tooltip={
                  <p className="w-48">
                    A creative can only be assigned once to all frame panels with a matching digital specification,
                    please link a creative to an active Frame panel.
                  </p>
                }
              >
                <SVG src={infoSvg} className="text-primary-700" />
              </Tooltip>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;

/* eslint-enable sonarjs/cognitive-complexity */
