import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import xlsx from 'json-as-xlsx';
import { RootState } from 'store';
import { formatNumber, formatPercent, FormatTypeEnum, formatValue } from 'modules/I18N';
import { getIsReseller } from 'store/publisher/selectors';
import { FrameDetailsModal } from '../../FrameDetailsModal/FrameDetailsModal';
import { ButtonColor, DealSummaryFrameListProps } from './DealSummaryFrameList.types';

export const DealSummaryFrameList: React.FC<DealSummaryFrameListProps> = ({
  assets = [],
  actionButton,
  buttonColor = ButtonColor.PRIMARY,
  isDisabled = false,
}) => {
  const [showFrameListModal, setShowFrameListModal] = useState(false);
  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const isReseller = useSelector(getIsReseller);

  const generateDealData = useCallback(() => {
    return [
      {
        sheet: 'DealSummary',
        columns: [
          ...(isReseller
            ? [
                {
                  label: 'Media owner',
                  value: 'mediaOwner',
                },
              ]
            : []),
          {
            label: 'Frame ID',
            value: 'frameId',
          },
          {
            label: 'Latitude',
            value: 'latitude',
          },
          {
            label: 'Longitude',
            value: 'longitude',
          },
          {
            label: 'Width',
            value: 'width',
          },
          {
            label: 'Height',
            value: 'height',
          },
          {
            label: 'Timezone',
            value: 'timezone',
          },
          {
            label: 'Visual unit code',
            value: 'visualUnitCode',
          },
          {
            label: 'Availability',
            value: 'availability',
          },
          {
            label: 'Street',
            value: 'street',
          },
          {
            label: 'PostCode',
            value: 'postCode',
          },
          {
            label: 'Route frame code',
            value: 'routeFrameCode',
          },
          {
            label: 'SOT',
            value: 'sot',
          },
          {
            label: 'Impressions',
            value: 'impressions',
          },
          {
            label: 'Price',
            value: 'price',
          },
          {
            label: 'CPM',
            value: 'cpm',
          },
        ],
        content:
          assets?.map(({ siteType, sot: assetSot, impressions: assetImpressions, price, cpm, ...exportData }) => ({
            ...exportData,
            sot: formatPercent({ value: assetSot / 100, localeCode }).replace(/\xA0/g, ' '),
            impressions: formatValue(assetImpressions, FormatTypeEnum.ROUNDED_NUMBER, currencyCode, localeCode),
            price: formatNumber({ value: price as number, localeCode }),
            cpm: formatNumber({ value: cpm as number, localeCode }),
          })) ?? [],
      },
    ];
  }, [assets]);

  const settings = {
    fileName: 'frames-details',
    extraLength: 3,
    writeOptions: {},
  };

  const onActionButtonClick = (): void => setShowFrameListModal(true);

  return (
    <>
      {actionButton?.(onActionButtonClick) ?? (
        <button
          type="button"
          className={`mt-2 ${buttonColor}`}
          onClick={onActionButtonClick}
          data-test-id="deal-summary-frame-list-btn"
          disabled={isDisabled}
        >
          Frame list
        </button>
      )}

      <FrameDetailsModal
        assets={assets}
        isOpen={showFrameListModal}
        onClose={() => setShowFrameListModal(false)}
        footer={
          <div className="text-right mt-4">
            <button
              type="button"
              onClick={() => xlsx(generateDealData(), settings)}
              className="sub-header-base"
              style={{
                padding: 'var(--spacing-xs) var(--spacing-lg)',
                lineHeight: 'var(--spacing-lg)',
                display: 'inline-block',
                verticalAlign: 'middle',
                backgroundColor: 'var(--primary-800)',
                border: '0',
                textAlign: 'center',
                borderRadius: '6px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
                color: 'var(--neutral-50)',
              }}
            >
              Export list
            </button>
          </div>
        }
      />
    </>
  );
};
