import { Store } from 'components/common/types/Store.types';
import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useCheckProgrammaticDealLevelChange = (): boolean => {
  const {
    backupFormData: { commonDeal: backupDeal, programmatic: backupProgrammatic },
    commonDeal,
    programmatic: { dsp },
    isNewDeal,
  } = useSelector((state: Store) => state.dealManagement);

  const {
    cpm: backupCpm,
    dealName: backupDealName,
    dealType: backupDealType,
    advertiser: backupAdvertiser,
    brand: backupBrand,
    productCategory: backupProductCategory,
    agency: backupAgency,
    specialist: backupSpecialist,
    dealPriority: backupDealPriority,
    salesPerson: backupSalesPerson,
    salesTeam: backupSalesTeam,
  } = backupDeal;
  const {
    cpm,
    dealName,
    dealPriority,
    dealType,
    advertiser,
    brand,
    agency,
    specialist,
    productCategory,
    salesPerson,
    salesTeam,
  } = commonDeal;
  const { dsp: backupDsp } = backupProgrammatic;

  return useMemo(
    () =>
      !isNewDeal &&
      !isEqual(
        {
          cpm: backupCpm,
          dealName: backupDealName,
          dsp: backupDsp,
          dealPriority: backupDealPriority,
          dealType: backupDealType,
          advertiser: backupAdvertiser,
          brand: backupBrand,
          agency: backupAgency,
          specialist: backupSpecialist,
          productCategory: backupProductCategory,
          salesPerson: backupSalesPerson,
          salesTeam: backupSalesTeam,
        },
        {
          cpm,
          dealName,
          dsp,
          dealPriority,
          dealType,
          advertiser,
          brand,
          agency,
          specialist,
          productCategory,
          salesPerson,
          salesTeam,
        },
      ),
    [backupDeal, commonDeal, dsp, backupDsp, isNewDeal],
  );
};
