import SVG from 'react-inlinesvg';
import { downloadSetupFile } from 'modules/api/setup';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import downloadSvg from 'assets/icons/download.svg';
import { download } from 'utils/download';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { DownloadRendererProps } from './DownloadRenderer.types';

const DownloadRenderer: React.FC<DownloadRendererProps> = ({ data }) => {
  const cancelFunctions = useCancelRequest();

  const handleDownloadFile = async (): Promise<void> => {
    try {
      const response = await downloadSetupFile(cancelFunctions, data.fileId);

      download(new Blob([response.data]), data.fileName);
    } catch {} // eslint-disable-line no-empty
  };

  return (
    <div className="table-action-button">
      <Button btnShape={ButtonShape.NORMAL} btnType={ButtonType.TERTIARY} onClick={handleDownloadFile}>
        <SVG src={downloadSvg} />
        <span>Download</span>
      </Button>
    </div>
  );
};

export default DownloadRenderer;
