import { useSelector } from 'react-redux';
import { formatNumber, formatPercent } from 'modules/I18N';
import { PieChart } from 'components/patterns/HighChart';
import EmptyPieChart from 'components/common/HeroCharts/EmptyPieChart';
import { Store } from 'components/common/types/Store.types';
import { ChartData, ImpressionsRendererProps } from './ImpressionsRenderer.types';

const ImpressionsRenderer: React.FC<ImpressionsRendererProps> = ({ value, data, onClick }) => {
  const localeCode = useSelector((state: Store) => state.publisher.configuration.localeCode);
  const onPieChartClick = (): void => {
    if (data) {
      onClick(data);
    }
  };

  if (!value?.sold && !value?.reserved) {
    return <EmptyPieChart onChartClick={onPieChartClick} />;
  }

  const chartOptions = {
    chart: {
      width: 60,
      height: 60,
      margin: [0, 0, 0, 0],
      backgroundColor: 'transparent',
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        events: {
          click: () => false,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const chartData: ChartData[] = [
    {
      name: 'Reserved',
      y: value.reserved,
    },
    {
      name: 'Sold',
      y: value.sold,
    },
  ];

  return (
    <div className="relative flex flex-row justify-center">
      <PieChart
        colors={['#f8bc1a', '#8066b5']}
        data={chartData}
        options={chartOptions}
        enableDataLabel={false}
        enableExporting={false}
        showInLegend={false}
        onClick={onPieChartClick}
      />
      <div className="relative w-52 flex flex-col justify-center mr-6">
        <div className="mb-1">
          <span className="inline-block h-2.5 w-2.5 mx-1 mt-1 bg-yellow" />
          {formatNumber({ value: value.reserved, localeCode })}&nbsp;{chartData[0].name}
        </div>
        <div>
          <span className="inline-block h-2.5 w-2.5 mx-1 mt-1 bg-purple-600" />
          {formatNumber({ value: value.sold, localeCode })}&nbsp;{chartData[1].name}
        </div>
      </div>
      <div className="relative w-40 flex flex-col justify-center border-l border-neutral-300 px-1.5 my-1 ">
        <div>
          {formatPercent({ value: value.pacing, localeCode })}
          &nbsp;Pacing
        </div>
      </div>
    </div>
  );
};

export default ImpressionsRenderer;
