import { TradingModel } from 'consts/tradingModel';
import { FormatTypeEnum } from 'modules/I18N';
import { PeriodicStats, StatsType } from './Stats.types';

export const getStats = (data: PeriodicStats, tradingModel: TradingModel): Array<StatsType> => [
  {
    id: 1,
    header: `Bid requests${tradingModel === TradingModel.PLAY ? ' (Plays)' : ''}`,
    currentValue: data.current.bidRequests,
    previousValue: data.previous.bidRequests,
    valueType: FormatTypeEnum.NUMBER,
  },
  {
    id: 2,
    header: `Bid responses${tradingModel === TradingModel.PLAY ? ' (Plays)' : ''}`,
    currentValue: data.current.bidResponses,
    previousValue: data.previous.bidResponses,
    valueType: FormatTypeEnum.NUMBER,
  },
  {
    id: 3,
    header: 'Impressions',
    currentValue: data.current.soldImpressions,
    previousValue: data.previous.soldImpressions,
    valueType: FormatTypeEnum.NUMBER,
  },
  {
    id: 4,
    header: 'Revenue',
    currentValue: data.current.revenue,
    previousValue: data.previous.revenue,
    valueType: FormatTypeEnum.CURRENCY,
  },
  {
    id: 5,
    header: 'Avg. win CPM',
    currentValue: data.current.winAverageCPM,
    previousValue: data.previous.winAverageCPM,
    valueType: FormatTypeEnum.CURRENCY,
  },
];
