import { useSelector } from 'react-redux';

import Auth from 'modules/Auth';
import { formatDate, formatNumber, formatPercent, formatDateNoUTC } from 'modules/I18N';
import { SWEEP_OPTIONS_NAMES } from 'consts/deal';
import { DASH_SLASH } from 'consts/placeholders';
import { PermissionsEnum } from 'consts/permissions';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';
import { Location } from 'components/common/Deal/TimelineItems';
import ListWithDivider from 'components/common/Deal/ListWithDivider';
import AssetsMap from 'components/common/AssetsMap';
import { isFormValid } from 'components/pages/DealWithLines/common/getActionConditions';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import DealSummary from '../DealSummary';
import CpmCard from '../CpmCard';
import { DealSummaryLineInfo } from './DealSummaryLineInfo';

// eslint-disable-next-line sonarjs/cognitive-complexity
const DealLineInfo = () => {
  const dealManagementState = useSelector((state) => state.dealManagement);
  const { localeCode } = useSelector((storeState) => storeState.publisher.configuration);
  const isReseller = useSelector(getIsReseller);
  const isAdServerMarket = useSelector(getIsAdServer);
  const {
    temporaryDealId,
    isNewDeal,
    isCpmCampaignLevel,
    commonDeal: {
      dealName,
      advertiser,
      brand,
      productCategory,
      dealType,
      cpm: dealCpm,
      currentLine: {
        cpm: lineCpm,
        startDate,
        endDate,
        availability,
        tags,
        countries,
        streets,
        cities,
        postCodes,
        counties,
        impressions,
        budget,
        frames,
        sot,
        sweep,
        productFormats,
      },
    },
    isForecastedAllocation,
  } = dealManagementState;

  const {
    assetCpmDistribution: { min, max, weightedMean },
  } = availability;

  const getFormattedDate = (date) =>
    Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC)
      ? formatDate(date, localeCode)
      : formatDateNoUTC(date, localeCode);

  const cpm = isCpmCampaignLevel ? dealCpm : lineCpm;

  return (
    <>
      {isAdServerMarket ? (
        <DealSummaryLineInfo />
      ) : (
        <DealSummary
          availability={availability}
          isAvailabilityReportDisabled={(isNewDeal && temporaryDealId === '') || !isFormValid(dealManagementState)}
          isForecastedAllocation={isForecastedAllocation}
          isFrameListDisabled={!isFormValid(dealManagementState, isReseller)}
          dealType={dealType}
        />
      )}
      <CpmCard currentValue={cpm} average={weightedMean} minDistribution={min} maxDistribution={max} />
      <Timeline>
        <TimelineItem title={<p className="sub-header-base text-neutral-950-opacity-80">Deal information</p>}>
          <div
            data-test-id="deal-line-info"
            className="grid grid-cols-2 gap-x-4 gap-y-1 body-sm text-neutral-950-opacity-80"
          >
            <p>Deal name</p>
            <p data-test-id="deal-line-deal-name">{dealName || DASH_SLASH}</p>
            <p>Advertiser</p>
            <p data-test-id="deal-line-advertiser">{advertiser?.name || DASH_SLASH}</p>
            <p>Brand</p>
            <p data-test-id="deal-line-brand">{brand?.name || DASH_SLASH}</p>
            <p>Product category</p>
            <p data-test-id="deal-line-product-category">{productCategory?.name || DASH_SLASH}</p>
          </div>
        </TimelineItem>
        <TimelineItem
          title={
            <p className="sub-header-base text-neutral-950-opacity-80" data-test-id="deal-line-schedule-header">
              Schedule
            </p>
          }
        >
          <span
            className="body-sm text-neutral-950-opacity-80"
            data-test-id="deal-line-schedule-range"
          >{`${getFormattedDate(startDate)} - ${getFormattedDate(endDate)}`}</span>
        </TimelineItem>
        <TimelineItem
          title={
            <p className="sub-header-base text-neutral-950-opacity-80" data-test-id="deal-line-format-header">
              Format
            </p>
          }
        >
          <ListWithDivider list={productFormats} dataTestId="deal-line-format-list" />
        </TimelineItem>
        <TimelineItem title={<p className="sub-header-base text-neutral-950-opacity-80">Target</p>}>
          <div
            className="grid grid-cols-2 gap-x-4 gap-y-1 body-sm text-neutral-950-opacity-80"
            data-test-id="deal-line-target"
          >
            {impressions ? (
              <>
                <p>Impressions</p>
                <p data-test-id="deal-line-target-impressions">
                  {impressions ? formatNumber({ value: impressions, localeCode }) : DASH_SLASH}
                </p>
              </>
            ) : (
              <>
                <p>Budget</p>
                <p data-test-id="deal-line-target-budget">
                  {budget ? formatNumber({ value: budget, localeCode }) : DASH_SLASH}
                </p>
              </>
            )}
            <p>Frames</p>
            <p data-test-id="deal-line-target-frames">
              {frames ? formatNumber({ value: frames, localeCode }) : DASH_SLASH}
            </p>
            <p>Sweep</p>
            <p data-test-id="deal-line-target-sweep">{SWEEP_OPTIONS_NAMES[sweep] || DASH_SLASH}</p>
            <p>SoT</p>
            <p data-test-id="deal-line-target-sot">
              {sot ? formatPercent({ value: sot / 100, localeCode }) : DASH_SLASH}
            </p>
          </div>
        </TimelineItem>
        <Location countries={countries} streets={streets} cities={cities} postCodes={postCodes} counties={counties} />
        <TimelineItem
          title={
            <p className="sub-header-base text-neutral-950-opacity-80" data-test-id="deal-line-tags-header">
              Tags
            </p>
          }
        >
          <ListWithDivider list={tags} dataTestId="deal-line-tags" />
        </TimelineItem>
      </Timeline>
      {useHasFeatureAccess(FeatureFlags.GOOGLEMAPS) ? (
        <div className="mt-5 mb-6 h-36" data-test-id="deal-line-map-container">
          <AssetsMap assets={availability.assets} />
        </div>
      ) : (
        <div className="mb-6" />
      )}
    </>
  );
};

export default DealLineInfo;
