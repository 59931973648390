import { getResellerMediaOwners } from 'modules/api/reseller';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getIsReseller } from 'store/publisher/selectors';
import { changeAvailableMediaOwners } from 'store/reseller/reducer';
import { getResellerDealMediaOwners } from 'store/reseller/selectors';

export const useResellerMediaOwners = (): void => {
  const dispatch = useDispatch();
  const { environmentId } = useSelector((state: RootState) => state.environment);
  const resellerMediaOwners = useSelector(getResellerDealMediaOwners);
  const isReseller = useSelector(getIsReseller);

  useEffect(() => {
    const getMediaOwners = async (): Promise<void> => {
      try {
        const result = await getResellerMediaOwners(environmentId, {});
        dispatch(changeAvailableMediaOwners(result.mediaOwners));
      } catch (error) {
        dispatch(changeAvailableMediaOwners([]));
      }
    };

    if (isReseller && environmentId && resellerMediaOwners?.length === 0) {
      getMediaOwners();
    }
  }, [environmentId]);
};
