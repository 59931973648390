import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import framePortraitSvg from 'assets/icons/frame-portrait.svg';
import frameLandscapeSvg from 'assets/icons/frame-landscape.svg';
import Modal, { ModalSize } from 'components/patterns/Modal';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import { CodeNameModel, PanelsModel } from 'components/common/prop-types/creativeSets';
import { FilterDsp } from 'components/common/FilterArea/Filters';
import AsyncSelect from 'components/patterns/AsyncSelect';
import withCancelRequest from 'components/hocs/withCancelRequest';
import BaseSelect from 'lib/BaseSelect';
import { CreativeType } from 'components/common/types/Creative.types';
import { MotionType } from 'consts/motionType';
import { getAdvertisers } from 'modules/api/content';
import Session from 'modules/Session';

import CreativesTable from './CreativesTable';

const creativeTypes = [
  {
    code: CreativeType.VIDEO,
    name: 'Video',
  },
  {
    code: CreativeType.BANNER,
    name: 'Banner',
  },
];

const AssignCreativeModal = ({
  cancelFunctions,
  panels,
  selectedPanelId,
  changeSelectedPanelId,
  selectCreative,
  dsp,
  advertiser,
  brand,
}) => {
  const panel = panels.find((p) => p.id === selectedPanelId);
  const isPortrait = panel?.height > panel?.width;
  const [selectedCreative, setSelectedCreative] = useState(null);
  const [filters, setFilters] = useState({ dsp: null, advertiser: null, brand: null, type: '' });

  useEffect(() => {
    setFilters({
      dsp,
      advertiser,
      brand,
      type: panel?.motionType === MotionType.STILL ? CreativeType.BANNER : null,
    });
  }, [selectedPanelId]);

  const onLoadAdvertisersLookupData = async (_, query) => {
    if (query.length < 2) return [];

    try {
      return await getAdvertisers(cancelFunctions, Session.getEnvironmentId(), query);
    } catch (error) {
      return [];
    }
  };

  const onLoadBrandLookupData = async (_, query) => {
    if (query.length < 2) return [];

    return filters.advertiser?.brands.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()));
  };

  const actionButtons = (
    <>
      <Button
        dataTestId="close-button"
        btnType={ButtonType.DANGER}
        btnShape={ButtonShape.NORMAL}
        onClick={() => {
          changeSelectedPanelId('');
        }}
      >
        Close
      </Button>
      <Button
        dataTestId="apply-button"
        btnType={ButtonType.PRIMARY}
        btnShape={ButtonShape.NORMAL}
        onClick={() => {
          selectCreative({
            creative: selectedCreative,
            ...selectedCreative,
          });
        }}
        isDisabled={!selectedCreative}
      >
        Apply
      </Button>
    </>
  );

  return (
    <Modal
      dataTestId="assign-creative-modal"
      isOpen={!!selectedPanelId}
      icon={<SVG src={isPortrait ? framePortraitSvg : frameLandscapeSvg} className="text-neutral-950-opacity-80" />}
      title={panel ? <p>{`${isPortrait ? 'Portrait' : 'Landscape'} ${panel?.width}x${panel?.height}`}</p> : null}
      size={ModalSize.LARGE}
      actionButtons={actionButtons}
    >
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 flex-1 mb-4">
        <FilterDsp
          dataTestId="dsp-select"
          selectedValue={filters.dsp}
          onChange={(_, value) => {
            setFilters((prevState) => ({ ...prevState, dsp: value }));
          }}
          isDisabled={!!dsp}
        />
        {Auth.hasPermission(PermissionsEnum.CONTENT_SEARCH) ? (
          <>
            <AsyncSelect
              dataTestId="advertiser-input"
              name="advertiser"
              loadData={onLoadAdvertisersLookupData}
              selectedValue={filters.advertiser}
              placeholder="Advertiser"
              onSelect={(_, value) => {
                setFilters((prevState) => ({ ...prevState, advertiser: value, brand: null }));
              }}
              isDisabled={!!advertiser}
            />
            <AsyncSelect
              dataTestId="brand-input"
              name="brand"
              loadData={onLoadBrandLookupData}
              selectedValue={filters.brand}
              placeholder="Brand"
              onSelect={(_, value) => {
                setFilters((prevState) => ({ ...prevState, brand: value }));
              }}
              defaultOptions={filters.advertiser?.brands || []}
              isDisabled={!filters.advertiser || !!brand}
            />
            <BaseSelect
              dataTestId="creative-types"
              name="type"
              options={creativeTypes}
              selectedValue={creativeTypes.find((creativeType) => creativeType.code === filters.type)}
              onSelect={(_, value) => {
                setFilters((prevState) => ({ ...prevState, type: value.code }));
              }}
              placeholder="Select creative type"
              isDisabled={panel?.motionType === MotionType.STILL}
            />
          </>
        ) : null}
      </div>
      <CreativesTable filters={filters} onSelectCreative={setSelectedCreative} panel={panel} />
    </Modal>
  );
};

AssignCreativeModal.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  panels: PanelsModel.isRequired,
  selectedPanelId: PropTypes.string,
  changeSelectedPanelId: PropTypes.func.isRequired,
  selectCreative: PropTypes.func.isRequired,
  dsp: CodeNameModel,
  advertiser: CodeNameModel,
  brand: CodeNameModel,
};

AssignCreativeModal.defaultProps = {
  selectedPanelId: '',
  dsp: null,
  advertiser: null,
  brand: null,
};

export default withCancelRequest(AssignCreativeModal);
