import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

const StatusIcon = ({ icon, iconColor, iconStyle }) => {
  return <SVG src={icon} className={`${iconStyle} ${iconColor}`} />;
};

StatusIcon.propTypes = {
  iconColor: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconStyle: PropTypes.string,
};
StatusIcon.defaultProps = {
  iconStyle: 'w-4 h-4',
};

export default StatusIcon;
