import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { DOUBLE_DASH } from 'consts/placeholders';
import { formatDate } from 'modules/I18N';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';

const Attributes = ({ asset }) => {
  const { localeCode } = useSelector((state) => state.publisher.configuration);
  return (
    <div>
      <h2 className="sub-header-base mb-8">Attributes</h2>
      <div className="pl-2">
        <Timeline>
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4">
                <p className="body-sm ">Products</p>
                <p className="body-sm col-span-2">Characteristics</p>
              </div>
            }
          >
            <div className="grid grid-cols-3 gap-x-4">
              <div className="space-y-2" data-test-id="asset-products">
                {asset?.products?.map(({ productUuid, productName, startDate, endDate }) => (
                  <div key={productUuid}>
                    <p className="body-base">{productName ?? DOUBLE_DASH}</p>
                    <span className="body-sm text-neutral-950-opacity-60">{`${formatDate(
                      startDate,
                      localeCode,
                    )} - ${formatDate(endDate, localeCode)}`}</span>
                  </div>
                ))}
              </div>
              <div className="space-y-2" data-test-id="asset-characteristics">
                {asset?.characteristics?.map(({ characteristicUuid, characteristicName, startDate, endDate }) => (
                  <div key={characteristicUuid}>
                    <p className="body-base">{characteristicName ?? DOUBLE_DASH}</p>
                    <span className="body-sm text-neutral-950-opacity-60">{`${formatDate(
                      startDate,
                      localeCode,
                    )} - ${formatDate(endDate, localeCode)}`}</span>
                  </div>
                ))}
              </div>
            </div>
          </TimelineItem>
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4">
                <p className="body-sm">DealApiEnabled</p>
                <p className="body-base col-span-2" data-test-id="asset-deal-api-enabled">{`${
                  asset?.dealApiEnabled ?? DOUBLE_DASH
                }`}</p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 items-center">
                <p className="body-sm">Venue taxonomy</p>
                <p className="body-base col-span-2 break-all" data-test-id="asset-venue-taxonomy">
                  {asset?.venueType ?? DOUBLE_DASH}
                </p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 items-center">
                <p className="body-sm">P. NetworkCode</p>
                <p className="body-base col-span-2 break-all" data-test-id="programmatic-network-uuid">
                  {asset?.programmaticNetworkUuid ?? DOUBLE_DASH}
                </p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 items-center">
                <p className="body-sm">Venue taxonomy enumeration ID </p>
                <p className="body-base col-span-2 break-all" data-test-id="venue-taxonomy-enumeration-id">
                  {asset?.venueTaxonomyEnumerationId ?? DOUBLE_DASH}
                </p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 items-center">
                <p className="body-sm">Venue taxonomy code</p>
                <p className="body-base col-span-2 break-all" data-test-id="venue-taxonomy-category-id">
                  {asset?.venueTaxonomyCategoryUuid ?? DOUBLE_DASH}
                </p>
              </div>
            }
          />
        </Timeline>
      </div>
    </div>
  );
};

Attributes.propTypes = {
  asset: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        productUuid: PropTypes.string.isRequired,
        productName: PropTypes.string,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
      }),
    ),
    characteristics: PropTypes.arrayOf(
      PropTypes.shape({
        characteristicUuid: PropTypes.string.isRequired,
        characteristicName: PropTypes.string,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
      }),
    ),
    dealApiEnabled: PropTypes.bool,
    venueType: PropTypes.string,
    programmaticNetworkUuid: PropTypes.string,
    venueTaxonomyEnumerationId: PropTypes.number,
    venueTaxonomyCategoryUuid: PropTypes.string,
  }),
};

Attributes.defaultProps = {
  asset: {},
};

export default Attributes;
