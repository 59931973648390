import { FileUploadStatusCode } from 'components/common/types/FileUploadStatusCode.types';
import { SetupDetails } from 'components/common/types/Setup.types';

import StatusIcon from 'components/patterns/StatusIcon';
import checkmarkIcon from 'assets/icons/checkmark.svg';
import warningIcon from 'assets/icons/warning.svg';
import alertIcon from 'assets/icons/info.svg';

import AssetDetails from './AssetDetails';

export interface FileDetailsProps {
  setupDetails: SetupDetails;
}

const StatusColor = {
  [FileUploadStatusCode.FAILED]: 'bg-pinkRed-500',
  [FileUploadStatusCode.ERROR]: 'bg-pinkRed-500',
  [FileUploadStatusCode.SUCCESS]: 'bg-green',
};

const FileDetails: React.FC<FileDetailsProps> = ({
  setupDetails: { hasErrors, fileName, statusCode, setupDetailResponse },
}) => {
  const fileStatusIcon =
    FileUploadStatusCode.FAILED || FileUploadStatusCode.ERROR === statusCode ? alertIcon : checkmarkIcon;

  const bgColor = hasErrors ? 'bg-yellow' : StatusColor[statusCode];

  return (
    <div className="pl-5">
      <div className="flex items-center pb-8">
        <span className={`flex justify-center rounded-full h-10 w-10 mr-6 ${bgColor}`}>
          <StatusIcon
            icon={hasErrors ? warningIcon : fileStatusIcon}
            iconColor="text-neutral-50"
            iconStyle="self-center "
          />
        </span>
        <p className="sub-header-lg">
          {fileName.replace(/\.xlsx|\.xls/g, '')}
          <span className="block body-base">
            File has been uploaded {statusCode === FileUploadStatusCode.SUCCESS ? 'successfully' : 'with errors'}
          </span>
        </p>
      </div>
      <div className="max-h-72 overflow-auto">
        {setupDetailResponse.length ? (
          setupDetailResponse.map(
            ({ newRecords, errorRecords, skippedRecords, totalRecords, updatedRecords, entityName }) => (
              <div key={entityName} className="grid gap-x-4 grid-cols-5 text-neutral-950-opacity-80 pb-8">
                <p className="sub-header-base col-span-2">{entityName}</p>
                <div className="space-y-3 col-span-3">
                  <AssetDetails
                    newRecords={newRecords}
                    errorRecords={errorRecords}
                    skippedRecords={skippedRecords}
                    totalRecords={totalRecords}
                    updatedRecords={updatedRecords}
                    isSuccess={FileUploadStatusCode.SUCCESS === statusCode}
                  />
                </div>
              </div>
            ),
          )
        ) : (
          <div className="grid space-y-3 place-items-center" data-test-id="empty-asset-details-component-wrapper">
            <AssetDetails />
          </div>
        )}
      </div>
    </div>
  );
};

export default FileDetails;
