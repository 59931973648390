export interface IconButtonProps {
  icon: string;
  name: string;
  isDisabled?: boolean;
  handleOnClick?: () => void;
  buttonSize?: ButtonSize;
  iconSize?: IconSize;
  theme?: Theme;
  dataTestId?: string;
}

/* Look to make this common as a refactor */
export enum ButtonSize {
  LARGE = 'w-9 h-9',
  MEDIUM = 'w-7 h-7',
  SMALL = 'w-6 h-6',
}

export enum IconSize {
  LARGE = 'w-6 h-6',
  MEDIUM = 'w-5 h-5',
  SMALL = 'w-4 h-4',
}

export enum Theme {
  PLAIN = 'PLAIN',
}
