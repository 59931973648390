import queryString from 'query-string';
import { Timezone } from 'store/header/reducer';
import { formatDateObjToIsoDateString } from 'utils/dateFormatUtil';
import { Filters } from './reportingQueryString.types';

const getQueryString = (filters: Filters, timezone: Timezone): string => {
  const filtersMap = {
    dealId: filters.deal?.code,
    dealType: filters.dealType,
    startDate: filters.startDate ? formatDateObjToIsoDateString(new Date(filters.startDate).setHours(0, 0, 0, 0)) : '',
    endDate: filters.endDate ? formatDateObjToIsoDateString(new Date(filters.endDate).setHours(23, 59, 59, 999)) : '',
    tradingModel: filters.tradingModel,
    time: filters.time,
    status: filters.status,
    creative: filters.creative,
    lossType: filters.lossType,
    lossCode: filters.lossCode,
    dspCode: filters.dsp?.code,
    brand: filters.brand?.name,
    advertiser: filters.advertiser?.name,
    agency: filters.agency?.name,
    specialist: filters.specialist?.name,
    timezone: timezone?.code,
  };

  return queryString.stringify(filtersMap, { skipEmptyString: true, arrayFormat: 'comma' });
};

export const getFilterQueryString = <T extends Filters>(filters: T, timezone: Timezone): string =>
  getQueryString(filters, timezone);
