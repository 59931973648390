import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import { isReadOnly } from 'utils/isReadOnly';
import { getLookupData } from 'modules/api/lookups';
import withCancelRequest from 'components/hocs/withCancelRequest';
import Chips from 'components/patterns/Chips';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { DealStatus } from 'components/common/types/Deal.types';

const Formats = ({ cancelFunctions }) => {
  const dispatch = useDispatch();
  const isEditingDisabled = useSelector((state) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);
  const environments = useSelector((state) => state.dealManagement.commonDeal.currentLine.environments);
  const productFormats = useSelector((state) => state.dealManagement.commonDeal.currentLine.productFormats);
  const terminated = useSelector((state) => state.dealManagement.commonDeal.currentLine.terminated);

  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled, terminated) && !isPendingReservation;

  const fetchProductFormat = async (dropdownName, query) => {
    try {
      const environmentCodes = environments.map((item) => item.code);
      return await getLookupData({ cancelFunctions, lookupURLName: dropdownName, query, environmentCodes });
    } catch {
      return [];
    }
  };

  return (
    <div className="space-y-4" data-test-id="formats">
      <div className="space-y-4 xl:w-4/12">
        <AsyncSelect
          dataTestId="product-format-select"
          name="productFormat"
          placeholder="Search formats"
          loadData={fetchProductFormat}
          selectedValue={productFormats}
          onSelect={(_, values) => {
            dispatch(changeDealCurrentLineData({ productFormats: values }));
          }}
          isClearable={false}
          multiple
          isMultiValueVisible={false}
          isDisabled={readOnly}
        />
      </div>
      {productFormats.length ? (
        <Chips
          dataTestId="formats-chips-list"
          dataList={productFormats}
          idKey="code"
          labelKey="name"
          isClearAllVisible={false}
          onRemove={(item) =>
            dispatch(
              changeDealCurrentLineData({
                productFormats: productFormats.filter((frameCode) => frameCode.code !== item.code),
              }),
            )
          }
          isDisabled={readOnly}
        />
      ) : null}
    </div>
  );
};

Formats.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
};

export default withCancelRequest(Formats);
