import { Creative, UpdateCreativeResponseError, UpdateCreativesError } from 'components/common/types/Creative.types';
import Session from 'modules/Session';
import { CodeNameModel } from 'components/common/types';
import { FrameTag } from 'components/common/types/FrameTag.types';
import {
  EditCreativesStatusChangeResponse,
  EditCreativesStatusChangeResponseData,
} from './ActionsPanel/ActionsPanel.types';

export const getIsInsideProductCategories = (categories: CodeNameModel[], categoryId?: string): boolean =>
  categories.some((category) => category.id === categoryId);

export const getIsInsideFrameTags = (frameTags: FrameTag[], frameTagId?: string): boolean =>
  frameTags.some((frameTag) => frameTag.id === frameTagId);

export const getActiveFrameTags = (frameTags: FrameTag[]): FrameTag[] =>
  frameTags.filter((frameTag) => frameTag.frameCount > 0 && frameTag.type);

const getResponsesErrorList = (responses: EditCreativesStatusChangeResponse[]): UpdateCreativeResponseError[] => {
  const checkHasError = (response: EditCreativesStatusChangeResponse): boolean =>
    response.result !== 'SUCCESS' && (response.errors ?? []).length > 0;

  return responses.reduce((errorList, response) => {
    if (checkHasError(response)) {
      errorList.push({
        id: response.id,
        result: response.result,
        reason: response.errors?.[0].reason || '',
      });
    }
    return errorList;
  }, [] as UpdateCreativeResponseError[]);
};

const getResponsesErrorMessage = (responses: UpdateCreativeResponseError[]): string => {
  return [...new Set(responses.map((error) => error.reason))].join(', ');
};

export const getCreativeMarketId = (creative: Creative): string =>
  creative?.market?.find((market) => market.environment === Session.getEnvironmentId())?.id ?? '';

export const checkResponseResultErrors = (result: EditCreativesStatusChangeResponseData): void => {
  const errorList = getResponsesErrorList(result.responses);

  if (errorList.length > 0) {
    const errorMessage = getResponsesErrorMessage(errorList);
    throw new UpdateCreativesError(errorMessage, errorList);
  }
};
