import { createCartoSlice } from '@carto/react-redux';
import { VOYAGER } from '@carto/react-basemaps';
import { API_VERSIONS } from '@deck.gl/carto/typed';

const cartoSlice = createCartoSlice({
  viewState: {
    latitude: 0,
    longitude: 0,
    zoom: 5,
    pitch: 0,
    width: 0,
    height: 0,
    bearing: 0,
    dragRotate: false,
  },
  basemap: VOYAGER,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: '',
    accessToken: '',
  },
  googleApiKey: '',
  googleMapId: '',
});

export default cartoSlice;
