/* eslint-disable sonarjs/cognitive-complexity */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { changeDealCurrentLineData, resetCurrentLineProperty } from 'store/dealManagement/reducer';
import InputNumber from 'lib/InputNumber';
import FieldWithInfoMessage from 'components/patterns/FieldWithInfoMessage';
import InfoMessage from 'components/common/Deal/InfoMessage';
import { TooltipDirection } from 'components/patterns/Tooltip';
import { DealType, DealStatus } from 'components/common/types/Deal.types';
import ToggleSwitch from 'components/patterns/ToggleSwitch';
import { formatUserInput, formatUserPercentageInput } from 'utils/formatters';
import { isEqual } from 'lodash';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { getIsAdServer } from 'store/publisher/selectors';
import { CardTheme } from 'components/patterns/Card/Card.types';
import { getAutomationDealConfigValue } from 'store/dealConfig/utils';

type ShareOfTimeProps = {
  autoCalculateFloorAndCeiling: () => void;
  disabled: boolean;
};
interface SotType {
  SOT: 'sot';
  FLOOR: 'floorSot' | 'sotFloor';
  CEILING: 'ceilingSot' | 'sotCeiling';
}
type SotTypes = SotType[keyof SotType];

const ShareOfTime: React.FC<ShareOfTimeProps> = ({ autoCalculateFloorAndCeiling, disabled }) => {
  const dispatch = useDispatch();
  const [isToggled, setToggled] = useState(false);
  const [randomFloorKey, setRandomFloorKey] = useState(Math.random());
  const [randomCeilingKey, setRandomCeilingKey] = useState(Math.random());
  const localeCode = useSelector((state: Store) => state.publisher.configuration.localeCode);
  const dealConfig = useSelector((state: Store) => state.dealConfig);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const ceilingSot = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.ceilingSot);
  const floorSot = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.floorSot);
  const sot = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.sot);
  const sotCeiling = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.sotCeiling);
  const sotFloor = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.sotFloor);
  const impressions = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.impressions);
  const budget = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.budget);
  const hasFloorCeilingAutomationEnabled = useHasFeatureAccess(FeatureFlags.FLOOR_CEILING_SOT_AUTOMATION);
  const isAdServerMarket = useSelector(getIsAdServer);
  const isDealTypeGuaranteed = dealType === DealType.GUARANTEED;

  const sotType: SotType = {
    SOT: 'sot',
    FLOOR: isAdServerMarket ? 'floorSot' : 'sotFloor',
    CEILING: isAdServerMarket ? 'ceilingSot' : 'sotCeiling',
  };
  const ceilingSotValue = isAdServerMarket ? ceilingSot : sotCeiling;
  const floorSotValue = isAdServerMarket ? floorSot : sotFloor;

  const isDisabled = disabled && bookingStatusCode !== DealStatus.PENDING_RESERVATION;
  const hasRange =
    hasFloorCeilingAutomationEnabled || isAdServerMarket ? !!(ceilingSotValue || floorSotValue || isToggled) : false;
  const labelStyling = 'sub-header-base text-neutral-950-opacity-60 mb-2 inline-block';

  const amendValueToConstraint = useCallback(
    (type: SotTypes, value: string): number => {
      const formattedUserInput = formatUserPercentageInput(value, localeCode);

      if (type === sotType.FLOOR && ceilingSotValue && formattedUserInput > ceilingSotValue) {
        setRandomFloorKey(Math.random());
        return formatUserPercentageInput(ceilingSotValue, localeCode);
      }
      if (type === sotType.CEILING && floorSotValue && formattedUserInput < floorSotValue) {
        setRandomCeilingKey(Math.random());
        return formatUserPercentageInput(floorSotValue, localeCode);
      }
      return formattedUserInput;
    },
    [floorSotValue, ceilingSotValue],
  );

  const setSot = (type: SotTypes, value: string): void => {
    if (formatUserInput(value, localeCode) === 0 || (type !== sotType.SOT && !isToggled)) {
      dispatch(resetCurrentLineProperty(type));
      dispatch(resetCurrentLineProperty(sotType.CEILING));
      dispatch(resetCurrentLineProperty(sotType.FLOOR));
    } else {
      dispatch(changeDealCurrentLineData({ [type]: amendValueToConstraint(type, value) }));
    }
  };

  const onBlur = (): void => {
    const isAutoCalculateFloorAndCeiling = getAutomationDealConfigValue(dealConfig, 'autoCalculateFloorAndCeiling');

    // TODO: Add sot changed check if availability check is required. https://github.com/VIOOH/programmatic-platform-frontend/commit/fc8f7816e1711d3deefff0f66757568813a784e6
    if (isAutoCalculateFloorAndCeiling) {
      autoCalculateFloorAndCeiling();
    }
  };

  const handleToggle = (value: boolean): void => {
    if (!value) {
      dispatch(resetCurrentLineProperty(sotType.CEILING));
      dispatch(resetCurrentLineProperty(sotType.FLOOR));
    } else {
      dispatch(resetCurrentLineProperty(sotType.SOT));
    }
    setToggled(value);
  };

  const invalidImpressions = !impressions || impressions === 0;
  const invalidBudget = !budget || budget === 0;

  const isAdsGuaranteedAndRequiresImpressionOrBudget = isDealTypeGuaranteed
    ? invalidImpressions && invalidBudget
    : false;
  const isAutomationNGRequiresOnlyImpression = invalidImpressions;
  const isAutomationFloorCeilingAndRequiresImpressionsOrBudget =
    hasFloorCeilingAutomationEnabled && isDealTypeGuaranteed
      ? invalidImpressions && invalidBudget
      : isAutomationNGRequiresOnlyImpression;

  const isFloorCeilingSotDisabled =
    isDisabled ||
    (isAdServerMarket
      ? isAdsGuaranteedAndRequiresImpressionOrBudget
      : isAutomationFloorCeilingAndRequiresImpressionsOrBudget);

  useEffect(() => {
    if (!ceilingSotValue && !floorSotValue) {
      setToggled(false);
    } else if (!isEqual(floorSotValue, ceilingSotValue)) {
      setToggled(true);
    } else if (isFloorCeilingSotDisabled) {
      setToggled(false);
    } else {
      setToggled(true);
    }
  }, [ceilingSotValue, floorSotValue, impressions, budget]);

  return (
    <div data-test-id="sot">
      <label htmlFor="sot" className={labelStyling} data-test-id="sot-header">
        Share of time
      </label>
      <FieldWithInfoMessage
        dataTestId="sot-field-info-message"
        field={
          <InputNumber
            name="sot"
            value={sot}
            onChange={(value: string) => setSot(sotType.SOT, value)}
            onBlur={onBlur}
            placeholder="Add %"
            max={100}
            icon="%"
            isDisabled={hasRange || isDisabled}
            dataTestId="sot-input"
          />
        }
        message={
          sot
            ? `We’ve found all frames with availability matching your share of time of ${sot}%.`
            : 'To view your Share of time across your frames, please view your frame export.'
        }
        direction={TooltipDirection.TOP_LEFT}
      />
      {(hasFloorCeilingAutomationEnabled || isAdServerMarket) && (
        <>
          <div className="my-4">
            <FieldWithInfoMessage
              field={
                <ToggleSwitch
                  label="Add floor to ceiling SOT range"
                  isChecked={isToggled}
                  onChange={handleToggle}
                  isDisabled={isFloorCeilingSotDisabled}
                  dataTestId="sot-range-toggle"
                />
              }
              message={
                (isDealTypeGuaranteed && isAdServerMarket) || hasFloorCeilingAutomationEnabled
                  ? 'Impressions or budget must be entered as a goal to use the SOT range'
                  : 'Set floor to ceiling SOT'
              }
              direction={TooltipDirection.TOP_LEFT}
            />
          </div>
          {isToggled && (!isEqual(floorSotValue, ceilingSotValue) || !isFloorCeilingSotDisabled) && (
            <div className="flex">
              <div className="pr-2">
                <label htmlFor="floor-sot" className={labelStyling}>
                  Floor share of time
                </label>
                <InputNumber
                  key={randomFloorKey}
                  name="floor-sot"
                  value={floorSotValue}
                  onBlur={(value: string) => setSot(sotType.FLOOR, value)}
                  placeholder="%"
                  max={100}
                  icon="%"
                  maximumFractionDigits={2}
                  dataTestId="floor-sot"
                  isDisabled={isDisabled}
                />
              </div>
              <div className="pl-2">
                <label htmlFor="ceiling-sot" className={labelStyling}>
                  Ceiling share of time
                </label>
                <InputNumber
                  key={randomCeilingKey}
                  name="ceiling-sot"
                  value={ceilingSotValue}
                  onBlur={(value: string) => setSot(sotType.CEILING, value)}
                  placeholder="%"
                  max={100}
                  icon="%"
                  maximumFractionDigits={2}
                  dataTestId="ceiling-sot"
                  isDisabled={isDisabled}
                />
              </div>
            </div>
          )}
        </>
      )}
      <div className="mt-4">
        <InfoMessage cardTheme={CardTheme.PRIMARY}>
          <span className="body-base text-primary-600">
            The default share of time will be allotted to the frames in your deal unless outlined here.
          </span>
        </InfoMessage>
      </div>
    </div>
  );
};

export default ShareOfTime;
/* eslint-enable sonarjs/cognitive-complexity */
