import Session from 'modules/Session';
import { MotionType } from 'consts/motionType';
import { Creative, CreativeError, UpdateCreativeResponseError } from 'components/common/types/Creative.types';

export const getCreativesMotionType = (selectedCreatives: Partial<Creative>[]): MotionType | null => {
  if (selectedCreatives.length === 0) {
    return null;
  }

  const isOnlyStillCreatives = selectedCreatives.every(({ movement }) => movement === MotionType.STILL)
    ? MotionType.STILL
    : null;

  const isOnlyMovingCreatives = selectedCreatives.every(({ movement }) => movement === MotionType.MOVING)
    ? MotionType.MOVING
    : null;

  return isOnlyStillCreatives || isOnlyMovingCreatives;
};

export const getCreativeError = (
  creative: Creative,
  errors: UpdateCreativeResponseError[] = [],
): CreativeError | undefined => {
  const getCreativeMarketId =
    creative.market.find((market) => market.environment === Session.getEnvironmentId())?.id ?? '';

  const foundError = errors?.find((error) => error.id === getCreativeMarketId);

  return foundError
    ? {
        result: foundError.result,
        reason: foundError.reason,
      }
    : undefined;
};
