import { CreativeStatus } from 'components/common/types/Creative.types';
import { createSelector } from '@reduxjs/toolkit';

export const getAllSelectedCreativesPendingStatus = createSelector(
  (state) => state.pages.contentManagement.creatives.selectedCreatives,
  (creatives) => creatives.every((creative) => creative.statusCode === CreativeStatus.PENDING),
);

export const getAllSelectedCreativesPendingAndOrRejected = createSelector(
  (state) => state.pages.contentManagement.creatives.selectedCreatives,
  (creatives) =>
    creatives.every((creative) => [CreativeStatus.PENDING, CreativeStatus.REJECTED].includes(creative.statusCode)),
);
