import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Chips from 'components/patterns/Chips';
import FileUpload from 'components/patterns/FileUpload';
import { NotificationMessages } from 'consts/notifications';
import { ACCEPTED_FILE_TYPE_XLS } from 'consts/fileType';
import { FILE_SIZE_20MB } from 'modules/api/constants';
import { notifyError } from 'store/notification/reducer';

const VisualUnitFilesUpload = ({ readOnly, visualUnitFiles, onChangeVisualUnitFiles }) => {
  const dispatch = useDispatch();

  const onFileChange = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      dispatch(notifyError({ message: NotificationMessages.INVALID_UPLOAD }));
      return;
    }

    const isDuplicateFile = acceptedFiles.find((file) => visualUnitFiles.find((item) => item.name === file.name));
    if (isDuplicateFile) {
      dispatch(notifyError({ message: NotificationMessages.DUPLICATE_UPLOAD }));
      return;
    }

    const files = [...visualUnitFiles];
    acceptedFiles.forEach((file) => files.push({ include: true, file, name: file.name }));
    onChangeVisualUnitFiles(files);
  };

  return (
    <div data-test-id="visual-unit-files">
      <p className="mb-2 sub-header-base text-neutral-950-opacity-60">Visual unit list</p>
      <div className="mb-1.5">
        <FileUpload
          acceptFileType={ACCEPTED_FILE_TYPE_XLS}
          maxFileSize={FILE_SIZE_20MB}
          placeholder="Upload VU list"
          onFileChange={onFileChange}
          isDisabled={readOnly}
        />
      </div>
      {visualUnitFiles.length ? (
        <Chips
          dataList={visualUnitFiles}
          idKey="name"
          labelKey="name"
          onRemoveAll={() => onChangeVisualUnitFiles([])}
          onRemove={(item) => onChangeVisualUnitFiles(visualUnitFiles.filter((file) => file.name !== item.name))}
          excludeKey="include"
          onIncludeExcludeChange={(item, include) => {
            onChangeVisualUnitFiles(
              visualUnitFiles.map((file) => (file.name === item.name ? { ...file, include } : file)),
            );
          }}
          isDisabled={readOnly}
        />
      ) : null}
    </div>
  );
};

VisualUnitFilesUpload.propTypes = {
  readOnly: PropTypes.bool,
  visualUnitFiles: PropTypes.array,
  onChangeVisualUnitFiles: PropTypes.func,
};

VisualUnitFilesUpload.defaultProps = {
  readOnly: false,
  visualUnitFiles: [],
  onChangeVisualUnitFiles: () => undefined,
};

export default VisualUnitFilesUpload;
