import { FrameOrdering, FrameOrderingLabels } from 'components/common/types/FrameOrdering.types';
import Dropdown from 'lib/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { onFilterChange } from 'store/pages/contentManagement/creativeSetDetails/reducer';

const frameOrderingList = [
  {
    name: FrameOrderingLabels.ORDERED,
    value: FrameOrdering.ORDERED,
  },
  {
    name: FrameOrderingLabels.UNORDERED,
    value: FrameOrdering.UNORDERED,
  },
];

const FilterFrameOrdering: React.FC = () => {
  const dispatch = useDispatch();
  const framesOrder = useSelector(
    (state: RootState) => state.pages.contentManagement.creativeSetDetails.filters.framesOrder,
  );

  return (
    <Dropdown
      dataTestId="frame-ordering-select"
      name="frame-ordering"
      dataList={frameOrderingList}
      value={framesOrder}
      onChange={(value: FrameOrdering) => {
        dispatch(onFilterChange({ framesOrder: value }));
      }}
      textKey="name"
      valueKey="value"
    />
  );
};

export default FilterFrameOrdering;
