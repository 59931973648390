import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import { TradingTimeModel } from 'consts/tradingModel';
import { FilterTradingTimeProps, TradingTimeFilters } from './FilterTradingTime.types';

const tradingTimeOptions = [
  { label: 'Play time', value: TradingTimeModel.PLAY },
  { label: 'Trade time', value: TradingTimeModel.TRADE },
];

const FilterTradingTime = <T extends TradingTimeFilters>({
  changeFilters,
  selectedTime,
}: FilterTradingTimeProps<T>): React.ReactElement => {
  return (
    <div className="w-min whitespace-nowrap">
      <MultipleSwitchBox
        source={tradingTimeOptions}
        selectedValue={selectedTime}
        textKey="label"
        valueKey="value"
        onChange={(value: TradingTimeModel) => changeFilters({ time: value } as Partial<T>)}
      />
    </div>
  );
};

export default FilterTradingTime;
