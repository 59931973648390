import classNames from 'classnames/bind';
import SVG from 'react-inlinesvg';
import RequiredAsterisk from 'components/common/RequiredAsterisk';
import styles from './Card.pcss';
import { BorderTheme, CardProps, CardTheme, CardType } from './Card.types';

const cx = classNames.bind(styles);

const Card: React.FC<CardProps> = ({
  icon,
  header,
  info = '',
  children,
  footer,
  cardType = CardType.REGULAR,
  mandatory = false,
  id = '',
  cardTheme = CardTheme.LIGHT,
  dataTestId = '',
  borderTheme = BorderTheme.BASIC,
}) => {
  return (
    <>
      {header && (
        <div className={cx('card-header', cardType, cardTheme)} id={id} data-test-id={dataTestId}>
          <div className="flex items-center">
            <span className={cx('card-icon', 'mr-3')}>{icon && <SVG src={icon} />}</span>
            <span className={cx('sub-header-lg', 'card-title')}>{header}</span>
            {mandatory ? <RequiredAsterisk /> : null}
          </div>
          <div>
            <span className={cx('body-sm', 'card-info')}>{info}</span>
          </div>
        </div>
      )}
      <div className={cx('card', 'border', cardType, cardTheme, borderTheme)}>
        <div className={cx('card-body')}>{children}</div>
        {footer && <div className={cx('card-footer')}>{footer}</div>}
      </div>
    </>
  );
};

export default Card;
