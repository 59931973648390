export enum OpenStreetMapCountries {
  UK = 'United Kingdom',
  US = 'United States',
  NL = 'Netherlands',
  FI = 'Finland',
  ES = 'Spain',
  DE = 'Germany',
  HK = 'Hong Kong',
  SG = 'Singapore',
  BE = 'Belgium',
  FR = 'France',
  AU = 'Australia',
  NZ = 'New Zealand',
  NO = 'Norway',
  BR = 'Brazil',
  DK = 'Denmark',
  AT = 'Austria',
  MX = 'Mexico',
  CH = 'Switzerland',
  PT = 'Portugal',
  ZA = 'South Africa',
  CN = 'China',
  IT = 'Italy',
  CO = 'Colombia',
}
