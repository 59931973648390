import { lazy, Suspense } from 'react';

import { usePageFilters } from 'customHooks/usePageFilters';
import {
  fetchRevenueChart,
  fetchBidRateChart,
  fetchTradedWinRateChart,
  fetchWeightedWinRateChart,
  fetchFillRateChart,
} from 'modules/api/dealDashboard';
import HeroCharts from 'components/common/HeroCharts';
import { getFilterQueryString } from 'utils/reportingQueryString';
import Filters from './Filters';
import { DealDashboardFilters } from './Filters/Filters.types';

const DealDashboardTable = lazy(() => import('components/common/insights/DealDashboardTable'));

const DealDashboardContent: React.FC = () => {
  const { filters } = usePageFilters<Partial<DealDashboardFilters>>();

  return (
    <>
      <Filters />
      <HeroCharts
        filters={filters}
        getQueryString={getFilterQueryString}
        fetchRevenueChart={fetchRevenueChart}
        fetchBidRateChart={fetchBidRateChart}
        fetchTradedWinRateChart={fetchTradedWinRateChart}
        fetchWeightedWinRateChart={fetchWeightedWinRateChart}
        fetchFillRateChart={fetchFillRateChart}
        isCarousel
      />
      <Suspense fallback={<></>}>
        <DealDashboardTable />
      </Suspense>
    </>
  );
};

export default DealDashboardContent;
