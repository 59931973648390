import { useMemo, useState } from 'react';
import { isArray } from 'lodash';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import SectionButtonChip from '../SectionButtonChip/SectionButtonChip';
import { ChipColors } from '../SectionButtonChip/SectionButtonChip.types';
import { ListProps, SectionButtonChipListProps } from './SectionButtonChipList.types';

const ExpandedList: React.FC<ListProps> = ({ category, chips }) => {
  return (
    <>
      <SectionButtonChip label={category} value={chips[0].name} />
      {chips.slice(1).map((chip) => (
        <SectionButtonChip key={chip.name} label={chip.name} labelStyle={ChipColors.DARK_PURPLE} />
      ))}
    </>
  );
};

const CollapsedList: React.FC<ListProps> = ({ category, chips }) => {
  const [isTooltip, setIsTooltip] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div onMouseOver={() => setIsTooltip(true)} onMouseOut={() => setIsTooltip(false)}>
      <Popover
        isOpen={isTooltip}
        align="center"
        positions={['bottom']}
        onClickOutside={() => setIsTooltip(false)}
        containerClassName="z-50"
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="var(--neutral-950)"
            arrowSize={8}
          >
            <div className="flex flex-col gap-y-1 bg-neutral-950 text-neutral-50 rounded-md px-4 py-2">
              {chips.map((value) => (
                <span>{value.name}</span>
              ))}
            </div>
          </ArrowContainer>
        )}
      >
        <div>
          <SectionButtonChip label={category} value={chips.length.toString()} />
        </div>
      </Popover>
    </div>
  );
};

const SectionButtonChipList: React.FC<SectionButtonChipListProps> = ({ selected, maxSelected = 5 }) => {
  const content = useMemo(() => {
    if (isArray(selected)) return selected.map((value) => <SectionButtonChip key={value} label={value} />);

    return Object.keys(selected).map((category) => {
      if (selected[category].length <= maxSelected)
        return <ExpandedList key={category} category={category} chips={selected[category]} />;

      return <CollapsedList key={category} category={category} chips={selected[category]} />;
    });
  }, [selected]);

  return <div className="flex w-full flex-col align-middle items-end gap-1">{content}</div>;
};

export default SectionButtonChipList;
