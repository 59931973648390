import { Component } from 'react';
import PropTypes from 'prop-types';
import ToggleSwitch from 'components/patterns/ToggleSwitch/ToggleSwitch';

class StatusRenderer extends Component {
  onStatusChange = (value) => {
    const { onStatusChange, data } = this.props;
    onStatusChange(value, data);
  };

  render() {
    const { value } = this.props;
    return <ToggleSwitch isChecked={!value} onChange={this.onStatusChange} />;
  }
}

StatusRenderer.propTypes = {
  value: PropTypes.bool,
  onStatusChange: PropTypes.func,
  data: PropTypes.shape({}),
};

StatusRenderer.defaultProps = {
  value: false,
  data: {},
  onStatusChange: () => undefined,
};

export default StatusRenderer;
