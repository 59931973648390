import cx from 'classnames';
import SVG from 'react-inlinesvg';
import check from 'assets/icons/check.svg';
import Checkbox from 'lib/Checkbox';
import { BaseSelectOptionProps } from '../BaseSelect.types';

const BaseSelectOption: React.FC<BaseSelectOptionProps> = ({
  isSelected,
  innerRef,
  isMultiple,
  innerProps: { id, onClick },
  children,
}: BaseSelectOptionProps) => {
  return (
    <div
      onClick={onClick}
      role="presentation"
      className={cx('flex rounded-sm p-2 mx-1 my-0.5 hover:bg-neutral-100 cursor-pointer', {
        'bg-neutral-950-opacity-5 sub-header-base text-primary': isSelected && !isMultiple,
        'hover:bg-primary-600-opacity-5 hover:text-primary': !isSelected && !isMultiple,
      })}
    >
      <div className="flex w-full items-center pl-1 py-1" ref={innerRef} id={id}>
        <div className="w-4 h-4">
          {isMultiple ? (
            <div className="w-4 h-4">
              <Checkbox isSelected={isSelected} />
            </div>
          ) : (
            <SVG
              src={check}
              className={cx('text-primary-600 block', {
                visible: isSelected,
                hidden: !isSelected,
              })}
            />
          )}
        </div>
        <div className="pl-2">{children}</div>
      </div>
    </div>
  );
};

export default BaseSelectOption;
