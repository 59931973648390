type ProvidedData = {
  value?: string;
  code?: number | string | null;
  children?: ProvidedData[];
};

export const deepFind = <T extends ProvidedData>(data: T[], value: string | number): T | null => {
  if (!Array.isArray(data) || !value) return null;

  let foundData = null;

  const findDataRecursively = (currentData: ProvidedData[], targetValue: string | number): void => {
    currentData.forEach((item) => {
      if (item?.value === targetValue || item?.code === targetValue) {
        foundData = item;
        return;
      }

      if (item.children?.length) {
        findDataRecursively(item.children, targetValue);
      }
    });
  };

  findDataRecursively(data, value);
  return foundData;
};
