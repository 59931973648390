/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useState, useMemo } from 'react';
import cx from 'classnames';
import SVG from 'react-inlinesvg';

import { InputType } from 'components/common/types/Input.types';
import eyeSvg from 'assets/icons/eye.svg';
import Input, { InputProps } from '../Input';

export const InputPassword: React.FC<InputProps> = ({ errorMessage, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setShowPassword(false);
  }, []);

  const togglePasswordVisibility = (): void => {
    setShowPassword((prev) => !prev);
  };

  const inputType = useMemo(() => {
    if (showPassword) return InputType.TEXT;
    return InputType.PASSWORD;
  }, [showPassword]);

  const endComponent = useMemo((): React.ReactNode | null => {
    return (
      <button
        type="button"
        aria-label="Toggle password input"
        data-test-id="toggle-password-icon"
        onClick={togglePasswordVisibility}
      >
        <SVG
          src={eyeSvg}
          className={cx('mr-2 ml-1', {
            'text-neutral-400': !showPassword && !errorMessage,
            'text-pinkRed-300': !showPassword && errorMessage,
            'text-neutral-600': showPassword && !errorMessage,
            'text-pinkRed-600': showPassword && errorMessage,
          })}
        />
      </button>
    );
  }, [showPassword, errorMessage]);

  return <Input {...props} errorMessage={errorMessage} type={inputType} endComponent={endComponent} />;
};

/* eslint-enable react/jsx-props-no-spreading */
