import { DealFilter } from 'components/common/types/PageFilters';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getDealIdLookupData } from 'modules/api/lookups';
import React from 'react';
import { useSelector } from 'react-redux';
import { getIsAdServer } from 'store/publisher/selectors';
import { DealIdFilters, FilterDealIdProps } from 'components/common/FilterArea/Filters/FilterDealId/FilterDealId.types';
import onDealIdChange from 'components/pages/Insights/utils';

const FilterDealId = <T extends DealIdFilters>({ changeFilters, deal }: FilterDealIdProps<T>): React.ReactElement => {
  const cancelFunctions = useCancelRequest();
  const isAdServerMarket = useSelector(getIsAdServer);

  const onLoadDeals = async (_name: string, query: string): Promise<[]> => {
    try {
      return query.length > 1
        ? await getDealIdLookupData({ cancelFunctions, lookupName: query, isAdServerMarket })
        : [];
    } catch (error) {
      return [];
    }
  };

  return (
    <AsyncSelect
      dataTestId="deal-search"
      name="deal"
      textKey="code"
      loadData={onLoadDeals}
      onSelect={(name: string, value: DealFilter) => onDealIdChange(name, value, changeFilters)}
      placeholder="Deal ID"
      selectedValue={deal}
    />
  );
};

export default FilterDealId;
