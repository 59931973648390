import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import isEqual from 'lodash/isEqual';
import cx from 'classnames';

import { getAdvertisers, getAgency, getSpecialist, getProductFormats } from 'modules/api/content';
import Session from 'modules/Session';
import { getContentDealIdLookupData } from 'modules/api/lookups';
import { Store } from 'components/common/types/Store.types';
import { updateIsAdditionalDataIncluded } from 'store/pages/contentManagement/creativesAdditionalData/reducer';
import withCancelRequest from 'components/hocs/withCancelRequest';
import BaseSelect from 'lib/BaseSelect';
import AsyncSelect from 'components/patterns/AsyncSelect';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import { CodeNameModel } from 'components/common/types';
import Button, { ButtonShape, ButtonSize, ButtonType } from 'components/patterns/Button';
import { BrandWithProductCategory } from 'components/common/types/Deal.types';
import { Creative, CreativeMetaOperation } from 'components/common/types/Creative.types';
import chevronDown from 'assets/icons/chevron_down.svg';
import plusSvg from 'assets/icons/plus.svg';
import crossSvg from 'assets/icons/close.svg';
import { getIsReseller } from 'store/publisher/selectors';
import { SingleFieldSet, MultipleFieldSet } from './FieldSet';
import MotionType from './MotionType';
import { CreativesMetaFormProps } from './CreativesMetaForm.types';
import AdditionalData from './AdditionalData';

const CreativesMetaForm: React.FC<CreativesMetaFormProps> = ({
  formMetadata,
  formMetadataOperations,
  isDisabled,
  onFormChange,
  onFormOperationChange,
  cancelFunctions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const dispatch = useDispatch();
  const selectedCreatives = useSelector((state: Store) => state.pages.contentManagement.creatives.selectedCreatives);
  const isAdditionalDataIncluded = useSelector(
    (state: Store) => state.pages.contentManagement.creativesAdditionalData.isAdditionalDataIncluded,
  );
  const isReseller = useSelector(getIsReseller);
  const [productCategoryOptions, setProductCategoryOptions] = useState<CodeNameModel[]>([]);
  const areAllSelectedCreativesWithSameDSP = useMemo(
    () => selectedCreatives.every((creative: Creative) => isEqual(creative.dsp, selectedCreatives[0].dsp)),
    [selectedCreatives],
  );

  const onLoadAdvertisersLookupData = async (_dropdownName: string, query: string): Promise<[]> => {
    if (query.length < 2) return [];

    try {
      return await getAdvertisers(cancelFunctions, Session.getEnvironmentId(), query);
    } catch (error) {
      return [];
    }
  };

  const onLoadDealIdLookupData = async (_dropdownName: string, query: string): Promise<[]> => {
    if (query.length < 2) return [];

    try {
      return await getContentDealIdLookupData(cancelFunctions, Session.getEnvironmentId(), query);
    } catch (error) {
      return [];
    }
  };

  const onLoadBrandLookupData = (_: string, query: string): BrandWithProductCategory[] => {
    return (
      formMetadata.advertiser?.brands.filter(({ name }: CodeNameModel) =>
        name.toLowerCase().includes(query.toLowerCase()),
      ) ?? []
    );
  };

  const onLoadAgencyLookupData = async (_dropdownName: string, query: string): Promise<[]> => {
    if (query.length < 2) return [];

    try {
      return await getAgency(cancelFunctions, Session.getEnvironmentId(), query);
    } catch (error) {
      return [];
    }
  };

  const onLoadSpecialistLookupData = async (_dropdownName: string, query: string): Promise<[]> => {
    if (query.length < 2) return [];

    try {
      return await getSpecialist(cancelFunctions, Session.getEnvironmentId(), query);
    } catch (error) {
      return [];
    }
  };

  const onLoadProductFormatLookupData = async (_dropdownName: string, query: string): Promise<[]> => {
    if (query.length < 2) return [];

    try {
      return await getProductFormats(cancelFunctions, Session.getEnvironmentId(), query);
    } catch (error) {
      return [];
    }
  };

  const onProductCategoryChange = (fieldName: string, selectedObj: CodeNameModel): void => {
    onFormChange(fieldName, selectedObj);
  };

  const onBrandSelect = (fieldName: string, value: BrandWithProductCategory | null): void => {
    onFormChange(fieldName, value);

    if (value === null) {
      onFormChange('productCategory', null);

      return;
    }

    setProductCategoryOptions([value.productCategory]);
    onFormChange('productCategory', value.productCategory);
  };

  const onAdvertiserChange = (fieldName: string, value: CodeNameModel): void => {
    onFormChange(fieldName, value);

    if (value === null) {
      onBrandSelect('brand', null);
    }
  };

  return (
    <div data-test-id="creatives-meta-form" className="mb-32">
      <h3 className="my-4 sub-header-lg text-neutral-950-opacity-80">Edit creatives</h3>
      <MotionType value={formMetadata.movement} onChange={onFormChange} isDisabled={isDisabled} />
      <SingleFieldSet label="Deal ID" id="dealId" isSingleFieldSet>
        <div className="space-y-2">
          <AsyncSelect
            dataTestId="dealId-input"
            name="dealId"
            loadData={onLoadDealIdLookupData}
            selectedValue={formMetadata.dealId}
            placeholder="Type here..."
            onSelect={onFormChange}
            isDisabled={isDisabled || !areAllSelectedCreativesWithSameDSP}
            multiple
          />
          <div className="flex justify-start">
            <MultipleSwitchBox
              source={[
                { code: CreativeMetaOperation.ADD, name: 'Add', icon: plusSvg },
                { code: CreativeMetaOperation.REPLACE, name: 'Replace', icon: crossSvg },
              ]}
              selectedValue={formMetadataOperations.dealId}
              textKey="name"
              valueKey="code"
              onChange={onFormOperationChange('dealId')}
              isDisabled={isDisabled || !areAllSelectedCreativesWithSameDSP}
              hideBorder
              dataTestId="dealId-select"
              isFullWidth={false}
            />
          </div>
        </div>
      </SingleFieldSet>
      {!isReseller && (
        <>
          <MultipleFieldSet>
            <SingleFieldSet label="Advertiser" id="advertiser" isRequired>
              <AsyncSelect
                dataTestId="advertiser-input"
                name="advertiser"
                loadData={onLoadAdvertisersLookupData}
                placeholder="Type here..."
                selectedValue={formMetadata.advertiser}
                onSelect={onAdvertiserChange}
                isDisabled={isDisabled}
              />
            </SingleFieldSet>
            <SingleFieldSet label="Brand" id="brand" isRequired>
              <AsyncSelect
                dataTestId="brands-input"
                name="brand"
                loadData={onLoadBrandLookupData}
                placeholder="Type here..."
                selectedValue={formMetadata.brand}
                onSelect={onBrandSelect}
                defaultOptions={formMetadata.advertiser?.brands || []}
                isDisabled={isDisabled || !formMetadata.advertiser}
              />
            </SingleFieldSet>
            <SingleFieldSet label="Product category" id="product-category">
              <BaseSelect
                dataTestId="product-category-select"
                placeholder="Select product category"
                name="productCategory"
                options={productCategoryOptions}
                selectedValue={formMetadata.productCategory}
                onSelect={onProductCategoryChange}
                isDisabled={!formMetadata.brand?.code || isDisabled}
                isClearable
              />
            </SingleFieldSet>
          </MultipleFieldSet>
          <MultipleFieldSet>
            <SingleFieldSet label="Agency" id="agency">
              <AsyncSelect
                dataTestId="agency-input"
                name="agency"
                loadData={onLoadAgencyLookupData}
                placeholder="Type here..."
                selectedValue={formMetadata.agency}
                onSelect={onFormChange}
                isDisabled={isDisabled}
              />
            </SingleFieldSet>
            <SingleFieldSet label="Specialist" id="specialist">
              <AsyncSelect
                dataTestId="specialist-input"
                name="specialist"
                loadData={onLoadSpecialistLookupData}
                placeholder="Type here..."
                selectedValue={formMetadata.specialist}
                onSelect={onFormChange}
                isDisabled={isDisabled}
              />
            </SingleFieldSet>
            <SingleFieldSet label="Product format" id="productFormat">
              <div className="space-y-2">
                <AsyncSelect
                  dataTestId="product-format-input"
                  name="productFormat"
                  loadData={onLoadProductFormatLookupData}
                  selectedValue={formMetadata.productFormat}
                  placeholder="Select a product format"
                  onSelect={onFormChange}
                  isDisabled={isDisabled}
                  multiple
                />
                <div className="flex justify-start">
                  <MultipleSwitchBox
                    source={[
                      { code: CreativeMetaOperation.ADD, name: 'Add', icon: plusSvg },
                      { code: CreativeMetaOperation.REPLACE, name: 'Replace', icon: crossSvg },
                    ]}
                    selectedValue={formMetadataOperations.productFormat}
                    textKey="name"
                    valueKey="code"
                    onChange={onFormOperationChange('productFormat')}
                    isDisabled={isDisabled}
                    hideBorder
                    dataTestId="productFormat-select"
                  />
                </div>
              </div>
            </SingleFieldSet>
          </MultipleFieldSet>
        </>
      )}
      {isAdditionalDataIncluded && !isReseller && <AdditionalData />}
      {!isReseller && (
        <div className="mt-4">
          <Button
            btnShape={ButtonShape.ROUNDED}
            btnSize={ButtonSize.FULL_FLEX}
            btnType={ButtonType.TRANSPARENT_WITH_BORDER}
            onClick={() => dispatch(updateIsAdditionalDataIncluded(!isAdditionalDataIncluded))}
          >
            <div />
            Additional data
            <SVG
              src={chevronDown}
              className={cx('h-5 w-5', {
                'rotate-180': isAdditionalDataIncluded,
              })}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default withCancelRequest(CreativesMetaForm);
