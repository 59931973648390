import { PropsWithChildren, useState } from 'react';
import SVG from 'react-inlinesvg';

import chevronDownSvg from 'assets/icons/chevron_down.svg';
import chevronRightSvg from 'assets/icons/chevron_right.svg';
import AccordionProps from './Accordion.types';

const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({ expanded, title, children, dataTestId }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  return (
    <div data-test-id={dataTestId} className="odd:border-b even:border-b first:border-t border-neutral-300">
      <div
        className={`flex align-center items-center pt-2 pb-2 sub-header-base bg-neutral-100 hover:cursor-pointer hover:text-neutral-900 ${
          isExpanded ? 'text-neutral-900' : 'text-neutral-600'
        }`}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        role="presentation"
      >
        <div className="flex justify-center w-10">
          <SVG src={isExpanded ? chevronDownSvg : chevronRightSvg} className="h-3.5 w-3.5" />
        </div>
        {title}
      </div>
      {isExpanded && (
        <div className="bg-neutral-200 pt-3 pb-3 pl-10 odd:border-t even:border-t border-neutral-300">{children}</div>
      )}
    </div>
  );
};

export default Accordion;
