import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';

export enum NotificationType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

export interface NotificationState {
  message: string;
  timeout: number;
  type: NotificationType | null;
}

export const initialState: NotificationState = {
  message: '',
  type: null,
  timeout: 0,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notifyError: (state, action: PayloadAction<{ message: string; timeout?: number }>) => {
      const timeout = action.payload.timeout === undefined ? NOTIFICATION_TIMEOUT.DEFAULT : action.payload.timeout;

      state.message = action.payload.message;
      state.timeout = timeout;
      state.type = NotificationType.ERROR;
    },
    notifyInfo: (state, action: PayloadAction<{ message: string; timeout?: number }>) => {
      const timeout = action.payload.timeout === undefined ? NOTIFICATION_TIMEOUT.DEFAULT : action.payload.timeout;

      state.message = action.payload.message;
      state.timeout = timeout;
      state.type = NotificationType.INFO;
    },
    notifySuccess: (state, action: PayloadAction<{ message: string; timeout?: number }>) => {
      const timeout = action.payload.timeout === undefined ? NOTIFICATION_TIMEOUT.DEFAULT : action.payload.timeout;

      state.message = action.payload.message;
      state.timeout = timeout;
      state.type = NotificationType.SUCCESS;
    },
    clearNotification: () => {
      return initialState;
    },
  },
});

export const { notifyError, notifyInfo, notifySuccess, clearNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
