import PropTypes from 'prop-types';

import { DOUBLE_DASH } from 'consts/placeholders';
import { PriceModel } from 'components/common/prop-types/inventory';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';

import PriceInfo from './PriceInfo';
import { Button as ReadMoreButton } from '../ReadMoreSections';

const Prices = ({ assetPrices, toggleReadMore, maxRecordsToShow }) => {
  return (
    <div>
      <h2 className="mb-8 sub-header-base">Prices</h2>
      <div className="pl-2" data-test-id="asset-prices">
        <Timeline>
          {assetPrices.length ? (
            assetPrices
              .slice(0, maxRecordsToShow)
              .map((price) => <PriceInfo key={`${price.startDate}${price.startOffset}`} price={price} />)
          ) : (
            <TimelineItem title={<p className="body-base">{DOUBLE_DASH}</p>} />
          )}
        </Timeline>
        {assetPrices.length > 3 ? <ReadMoreButton onClick={toggleReadMore} /> : null}
      </div>
    </div>
  );
};

Prices.propTypes = {
  assetPrices: PropTypes.arrayOf(PriceModel),
  toggleReadMore: PropTypes.func,
  maxRecordsToShow: PropTypes.number.isRequired,
};

Prices.defaultProps = {
  assetPrices: [],
  toggleReadMore: () => null,
};

export default Prices;
