/* eslint-disable eslint-comments/disable-enable-pair */
import { thoughtspot } from 'config';
import { DealType } from 'components/common/types/Deal.types';

export enum LevelType {
  DEAL = 'DEAL',
  LINE = 'LINE',
}

const VISUAL_IDS_LIST_TEST = {
  DEAL: {
    [DealType.GUARANTEED]: [
      { name: 'Assets', id: '93d712ab-ad26-4fc9-b858-f00a6feb1028' },
      { name: 'Impressions', id: '4447a9d3-1588-42cd-acb4-70bf81cb3f87' },
      { name: 'SOT', id: '8508dd6c-c3d0-480e-8969-0fddd7abcf9d' },
      { name: 'Price', id: 'dfa53834-bb1f-46ba-80d0-4f941db92c55' },
    ],
    [DealType.NON_GUARANTEED_FLOOR_PRICE]: [
      { name: 'Available assets', id: '93d712ab-ad26-4fc9-b858-f00a6feb1028' },
      { name: 'Max impressions', id: '4447a9d3-1588-42cd-acb4-70bf81cb3f87' },
      { name: 'Available SOT', id: '8508dd6c-c3d0-480e-8969-0fddd7abcf9d' },
      { name: 'Price', id: 'dfa53834-bb1f-46ba-80d0-4f941db92c55' },
    ],
    [DealType.NON_GUARANTEED_FIXED_PRICE]: [
      { name: 'Available assets', id: '93d712ab-ad26-4fc9-b858-f00a6feb1028' },
      { name: 'Max impressions', id: '4447a9d3-1588-42cd-acb4-70bf81cb3f87' },
      { name: 'Available SOT', id: '8508dd6c-c3d0-480e-8969-0fddd7abcf9d' },
      { name: 'Price', id: 'dfa53834-bb1f-46ba-80d0-4f941db92c55' },
    ],
  },
  LINE: {
    [DealType.GUARANTEED]: [
      { name: 'Assets', id: '096faffd-059b-4942-872e-05112cf3643b' },
      { name: 'Impressions', id: '61e1dfcf-901b-429e-a48f-f52a651b23a5' },
      { name: 'SOT', id: '7bb83f99-8079-45e5-b35e-b35d60923326' },
      { name: 'Price', id: 'ab93e2d5-cf8e-4acb-afa0-e384a22411cd' },
    ],
    [DealType.NON_GUARANTEED_FLOOR_PRICE]: [
      { name: 'Available assets', id: '096faffd-059b-4942-872e-05112cf3643b' },
      { name: 'Max impressions', id: '61e1dfcf-901b-429e-a48f-f52a651b23a5' },
      { name: 'Available SOT', id: '7bb83f99-8079-45e5-b35e-b35d60923326' },
      { name: 'Price', id: 'ab93e2d5-cf8e-4acb-afa0-e384a22411cd' },
    ],
    [DealType.NON_GUARANTEED_FIXED_PRICE]: [
      { name: 'Available assets', id: '096faffd-059b-4942-872e-05112cf3643b' },
      { name: 'Max impressions', id: '61e1dfcf-901b-429e-a48f-f52a651b23a5' },
      { name: 'Available SOT', id: '7bb83f99-8079-45e5-b35e-b35d60923326' },
      { name: 'Price', id: 'ab93e2d5-cf8e-4acb-afa0-e384a22411cd' },
    ],
  },
};

const VISUAL_IDS_LIST_PRODUCTION = {
  DEAL: {
    [DealType.GUARANTEED]: [
      { name: 'Assets', id: '5ffbef53-d4a4-4ca4-94d9-1cf4ef98b40d' },
      { name: 'Impressions', id: '934939ae-47eb-4627-95fc-c29a0ab477e9' },
      { name: 'SOT', id: '9f85e6da-4de6-45a2-993d-1ae52f21019c' },
      { name: 'Price', id: 'ae140134-b02f-4e6a-8090-3d9c2ac7cef9' },
    ],
    [DealType.NON_GUARANTEED_FLOOR_PRICE]: [
      { name: 'Available assets', id: '5ffbef53-d4a4-4ca4-94d9-1cf4ef98b40d' },
      { name: 'Max impressions', id: '934939ae-47eb-4627-95fc-c29a0ab477e9' },
      { name: 'Available SOT', id: '9f85e6da-4de6-45a2-993d-1ae52f21019c' },
      { name: 'Price', id: 'ae140134-b02f-4e6a-8090-3d9c2ac7cef9' },
    ],
    [DealType.NON_GUARANTEED_FIXED_PRICE]: [
      { name: 'Available assets', id: '5ffbef53-d4a4-4ca4-94d9-1cf4ef98b40d' },
      { name: 'Max impressions', id: '934939ae-47eb-4627-95fc-c29a0ab477e9' },
      { name: 'Available SOT', id: '9f85e6da-4de6-45a2-993d-1ae52f21019c' },
      { name: 'Price', id: 'ae140134-b02f-4e6a-8090-3d9c2ac7cef9' },
    ],
  },
  LINE: {
    [DealType.GUARANTEED]: [
      { name: 'Assets', id: '7e4b40fe-308a-457f-bb95-a2cff2100838' },
      { name: 'Impressions', id: '7175b361-4916-44a5-97ce-c76fdbc20029' },
      { name: 'SOT', id: '980c1777-2639-4877-a0d5-dc1378b0a371' },
      { name: 'Price', id: 'bdd4acdd-3ed6-4f1c-a9f9-44fe6d7ec896' },
    ],
    [DealType.NON_GUARANTEED_FLOOR_PRICE]: [
      { name: 'Available assets', id: '7e4b40fe-308a-457f-bb95-a2cff2100838' },
      { name: 'Max impressions', id: '7175b361-4916-44a5-97ce-c76fdbc20029' },
      { name: 'Available SOT', id: '980c1777-2639-4877-a0d5-dc1378b0a371' },
      { name: 'Price', id: 'bdd4acdd-3ed6-4f1c-a9f9-44fe6d7ec896' },
    ],
    [DealType.NON_GUARANTEED_FIXED_PRICE]: [
      { name: 'Available assets', id: '7e4b40fe-308a-457f-bb95-a2cff2100838' },
      { name: 'Max impressions', id: '7175b361-4916-44a5-97ce-c76fdbc20029' },
      { name: 'Available SOT', id: '980c1777-2639-4877-a0d5-dc1378b0a371' },
      { name: 'Price', id: 'bdd4acdd-3ed6-4f1c-a9f9-44fe6d7ec896' },
    ],
  },
};

export const LEVEL_TYPE_VISUAL_IDS =
  thoughtspot.identifier === 'production' ? VISUAL_IDS_LIST_PRODUCTION : VISUAL_IDS_LIST_TEST;
