import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import { PageWrapper } from 'components/common/layout/PageWrapper/PageWrapper';
import PrivateRoute from 'components/common/Auth/Private';
import { PermissionsEnum } from 'consts/permissions';
import { resetCreativeSetsState } from 'store/pages/contentManagement/creativeSets/reducer';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import Creatives from './Creatives';
import UploadCreative from './UploadCreative';
import CreativeSets from './CreativeSets';
import CreativeSetDetails from './CreativeSetDetails';
import EditCreatives from './EditCreatives';
import Preview from './Preview';

const ContentManagement = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetCreativeSetsState());
    };
  }, []);

  const childRoutes = [
    {
      id: 1,
      path: 'creatives',
      main: Creatives,
      pageAccessPermission: undefined,
    },
    {
      id: 2,
      path: 'uploadCreative',
      main: UploadCreative,
      pageAccessPermission: PermissionsEnum.CONTENT_UPLOAD,
    },
    {
      id: 3,
      path: 'previewCreative/:id',
      main: Preview,
      pageAccessPermission: PermissionsEnum.CONTENT_REVIEW,
    },
    ...(useHasFeatureAccess(FeatureFlags.CREATIVE_SETS)
      ? [
          {
            id: 4,
            path: 'creative-sets',
            main: CreativeSets,
            pageAccessPermission: undefined,
          },
          {
            id: 5,
            path: 'creative-set-details',
            main: CreativeSetDetails,
            pageAccessPermission: PermissionsEnum.CONTENT_APPROVE,
          },
          {
            id: 6,
            path: 'creative-set-details/:id',
            main: CreativeSetDetails,
            pageAccessPermission: undefined,
          },
        ]
      : []),
    {
      id: 7,
      path: 'edit-creatives',
      main: EditCreatives,
      pageAccessPermission: undefined,
    },
    {
      id: 8,
      path: '*',
      main: () => <Navigate to="creatives" />,
      pageAccessPermission: undefined,
    },
  ];

  return (
    <PageWrapper>
      <Routes>
        {childRoutes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={<PrivateRoute component={route.main} pageAccessPermission={route.pageAccessPermission} />}
          />
        ))}
      </Routes>
    </PageWrapper>
  );
};
export default ContentManagement;
