import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { PopupAlign, PopupPosition } from './Popup.types';

export const ARROW_COLOR = {
  LIGHT: 'var(--neutral-100)',
  PURPLE: 'var(--primary-800)',
};

const Popup = forwardRef(
  (
    {
      children,
      isOpen,
      content,
      onClickOutside,
      containerPosition,
      alignContent,
      arrowColor,
      parentElementRef,
      containerClassName,
    },
    ref,
  ) => {
    return (
      <Popover
        ref={ref}
        parentElement={parentElementRef}
        isOpen={isOpen}
        onClickOutside={onClickOutside}
        positions={[containerPosition]}
        align={alignContent}
        reposition
        containerClassName={containerClassName}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={arrowColor}
            arrowSize={8}
          >
            {content}
          </ArrowContainer>
        )}
      >
        {children}
      </Popover>
    );
  },
);

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  parentElementRef: PropTypes.shape({ current: PropTypes.object }),
  onClickOutside: PropTypes.func,
  containerPosition: PropTypes.oneOf(Object.values(PopupPosition)),
  alignContent: PropTypes.oneOf(Object.values(PopupAlign)),
  arrowColor: PropTypes.string,
  containerClassName: PropTypes.string,
};

Popup.defaultProps = {
  onClickOutside: () => undefined,
  parentElementRef: undefined,
  containerPosition: PopupPosition.BOTTOM,
  alignContent: PopupAlign.CENTER,
  arrowColor: ARROW_COLOR.LIGHT,
  containerClassName: '',
};

export default Popup;
