import queryString from 'query-string';
import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS, HEADERS_CONTENT_TYPE } from 'modules/api/constants';
import { DealType } from 'components/common/types/Deal.types';

const defaultBids = {
  bidRequest: 0,
  bidResponse: 0,
  wonImpressions: 0,
  bookedImpressions: 0,
  soldImpressions: 0,
  fillRate: 0,
};

const getFilterRequestData = (filters = {}, isAdServerMarket) => {
  const requestData = {};

  if (filters.dsp?.code) {
    if (isAdServerMarket) {
      requestData.dspCode = filters.dsp.code;
    } else {
      requestData.dspName = filters.dsp.name;
    }
  }

  if (filters?.dismountedSearch) {
    requestData.dismountedSearch = filters.dismountedSearch;
  }

  if (filters.dealNameOrId) {
    requestData.dealNameOrId = filters.dealNameOrId;
  }

  if (filters.advertiser?.code) {
    requestData.advertiserName = filters.advertiser.name;
  }

  if (filters.status) {
    requestData.bookingStatusCode = filters.status;
  }

  if (filters.internalId) {
    requestData.internalId = filters.internalId;
  }

  if (filters.dealType) {
    requestData.dealType = filters.dealType;
  }

  if (!isAdServerMarket) {
    requestData.sortDir = filters.sortDir;
    requestData.sortBy = filters.sortBy;
  }

  return requestData;
};

const assignAllocatedImpressions = (deals, isAdServerMarket) => {
  return deals.map((deal) => {
    const {
      dealTypeCode,
      impressions,
      totalImpressions,
      bids: { bookedImpressions: reserved },
    } = deal;

    if (dealTypeCode !== DealType.GUARANTEED || !reserved) {
      return deal;
    }

    const sold = isAdServerMarket ? Number(totalImpressions) : Number(impressions);

    return {
      ...deal,
      allocatedImpressions: {
        sold,
        reserved,
        pacing: sold / reserved,
      },
    };
  });
};

const fetchDealsBids = async (cancelFunctions, deals, isAdServerMarket) => {
  const { send, cancel: cancelSend } = createRequest({
    url: URLS().DEAL_LIST_BIDS_DATA_URL(),
    method: HTTP_METHODS.POST,
    data: JSON.stringify({
      dealId: deals.map((item) => item.dealId),
    }),
    showLoader: true,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
  });

  if (cancelFunctions.DEAL_LIST_BIDS_DATA_URL) cancelFunctions.DEAL_LIST_BIDS_DATA_URL();
  cancelFunctions.DEAL_LIST_BIDS_DATA_URL = cancelSend;

  const result = await send();
  const newBids = result.data;

  return deals.map((deal) => {
    const dealBids = newBids[deal.dealId] ? Object.values(newBids[deal.dealId])[0] : { ...defaultBids };
    const dealData = {
      ...deal,
      bids: dealBids,
    };

    if (deal.dealTypeCode !== DealType.GUARANTEED || !dealBids.bookedImpressions) return dealData;

    const soldImpressions = isAdServerMarket ? Number(deal.totalImpressions) : Number(deal.impressions);

    return {
      ...dealData,
      remainingImpressions: dealBids.bookedImpressions - soldImpressions,
    };
  });
};

const getAdserverMultiLineDeals = async (
  cancelFunctions,
  filters = {},
  { page, size, sortBy, sortDir },
  isAdServerMarket,
) => {
  const query = queryString.stringify(
    { ...getFilterRequestData(filters, isAdServerMarket), page, size, sortBy, sortDir },
    { skipEmptyString: true, arrayFormat: 'comma' },
  );

  const { send, cancel } = createRequest({
    url: URLS().GET_DEALS(query),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  if (cancelFunctions.GET_DEALS) cancelFunctions.GET_DEALS();
  cancelFunctions.GET_DEALS = cancel;

  return send();
};

const getDeals = async ({ cancelFunctions, filters = {}, page, size, isAdServerMarket }) => {
  const query = queryString.stringify({ page, size, updateStamp: 0 }, { skipEmptyString: true, arrayFormat: 'comma' });

  const { send, cancel } = createRequest({
    url: URLS().DEAL_LIST_DATA_URL(query, isAdServerMarket),
    method: HTTP_METHODS.POST,
    data: getFilterRequestData(filters, isAdServerMarket),
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    showLoader: true,
  });

  if (cancelFunctions.DEAL_LIST_DATA_URL) cancelFunctions.DEAL_LIST_DATA_URL();
  cancelFunctions.DEAL_LIST_DATA_URL = cancel;

  return send();
};

export const getDealsWithBids = async ({ cancelFunctions, filters = {}, page, size, sort, isAdServerMarket }) => {
  const { sortBy, sortDir } = sort;
  const result = isAdServerMarket
    ? await getAdserverMultiLineDeals(cancelFunctions, filters, { page, size, sortBy, sortDir }, isAdServerMarket)
    : await getDeals({ cancelFunctions, filters, sortBy, sortDir, page, size, isAdServerMarket });

  const dealsWithDefaultBids = result.data.deals.content.map((deal) => ({
    ...deal,
    internalId: deal.internalId.toString(),
    bids: {
      ...defaultBids,
    },
  }));

  const dealsWithBids = await fetchDealsBids(cancelFunctions, dealsWithDefaultBids, isAdServerMarket);
  const dealsWithAllocatedImpressions = assignAllocatedImpressions(dealsWithBids, isAdServerMarket);

  return {
    ...result.data.deals,
    content: dealsWithAllocatedImpressions,
  };
};
