import { useDispatch, useSelector } from 'react-redux';
import ToggleSwitch, { ToggleTheme } from 'components/patterns/ToggleSwitch';
import { changeProgrammaticDealInfo } from 'store/dealManagement/actions';
import { Store } from 'components/common/types/Store.types';
import { DealStatus } from 'components/common/types/Deal.types';
import DspChip from 'components/common/Deal/DspChip';
import { DspSettings } from 'components/common/types/DspSettings.types';
import { useDspSettings } from 'customHooks/useDspSettings';

const CreativeBidStream: React.FC<DspSettings> = ({ theme = 'LIGHT' }) => {
  const dispatch = useDispatch();
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const isCreativeSubmissionInBidStreamEnabled = useSelector(
    (state: Store) => state.dealManagement.programmatic.enableCreativeSubmissionInBidStream,
  );
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const { isSelectedDspSupportingCreativeSubmissionInBidStream } = useDspSettings();

  if (!isSelectedDspSupportingCreativeSubmissionInBidStream) return null;

  const isToggleDisabled =
    !isNewDeal && [DealStatus.ENDED, DealStatus.TERMINATED, DealStatus.CANCELLED].includes(bookingStatusCode);

  return (
    <div className="flex justify-between items-center h-8">
      {isCreativeSubmissionInBidStreamEnabled ? (
        <DspChip theme={theme} label="Creatives submitted" testId="creative-bid-stream" />
      ) : (
        <p className="body-base">Creatives submission by bid stream</p>
      )}
      <ToggleSwitch
        onChange={() =>
          dispatch(
            changeProgrammaticDealInfo({
              enableCreativeSubmissionInBidStream: !isCreativeSubmissionInBidStreamEnabled,
            }),
          )
        }
        isChecked={isCreativeSubmissionInBidStreamEnabled}
        isDisabled={isToggleDisabled}
        dataTestId="creative-bid-stream-switch"
        theme={ToggleTheme[theme]}
      />
    </div>
  );
};

export default CreativeBidStream;
