import * as React from 'react';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from 'modules/Auth';
import Session from 'modules/Session';
import { PermissionsEnum } from 'consts/permissions';

interface Props {
  component: React.ComponentType;
  pageAccessPermission?: PermissionsEnum;
}

const Private = <P extends Props>(props: Props): ReactElement => {
  const navigate = useNavigate();
  const { pageAccessPermission, component: Component } = props;

  useEffect(() => {
    const hasViewPermission = pageAccessPermission ? Auth.hasPermission(pageAccessPermission) : true;

    const checkIsAuthenticated = async (): Promise<void> => {
      const authenticated = await Auth.isAuthenticated();

      if (!hasViewPermission || !authenticated) {
        navigate('/accessdenied');
      } else if (hasViewPermission && !Session.getEnvironmentId()) {
        navigate('/landing');
      }
    };

    checkIsAuthenticated();
  }, [pageAccessPermission]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...(props as P)} />;
};

export default Private;
