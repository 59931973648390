import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import groupBy from 'lodash/groupBy';
import Fuse from 'fuse.js';

import Popup, { PopupAlign, PopupPosition } from 'components/patterns/Popup';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import Search from 'components/patterns/Search';

import plusSvg from 'assets/icons/plus.svg';
import selectListSvg from 'assets/icons/select_list.svg';

const sortAndSelectItems = (items, selectedItems) =>
  items
    .map((item) => {
      if (selectedItems.find((s) => s.code === item.code)) {
        return { ...item, selected: true };
      }

      return { ...item, selected: false };
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1));

const groupItemsAlphabetically = (items) => {
  const alphabeticalGroup = groupBy(items, (i) => i.name.substring(0, 1).toLowerCase());

  return Object.entries(alphabeticalGroup);
};

const MultiSelect = ({ dataTestId, items, selectedItems, onSelect, onClearAll, isDisabled, containerPosition }) => {
  const [allItems, setAllItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [search, setSearch] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const parentRef = useRef();

  useEffect(() => {
    setAllItems(sortAndSelectItems(items, selectedItems));
  }, [items, selectedItems]);

  useEffect(() => {
    const fuse = new Fuse(allItems, { keys: ['name'] });
    const results = fuse.search(search).map((i) => i.item);
    setFilteredItems(results.length ? results : allItems);
  }, [search, allItems]);

  const handleSelect = (e) => {
    const { value } = e.target;

    let selectedValueToReturn = [...selectedItems];

    if (selectedValueToReturn.find((i) => i.code === value)) {
      selectedValueToReturn = selectedValueToReturn.filter((i) => i.code !== value);
    } else {
      selectedValueToReturn = [...selectedValueToReturn, items.find((i) => i.code === value)];
    }

    onSelect(selectedValueToReturn);
  };

  const getLabelClassNames = (item) => {
    const classNames = [];

    if (!item.disabled) classNames.push('cursor-pointer');

    if (item.selected) {
      classNames.push('bg-primary text-neutral-100 hover:opacity-80');
    } else if (item.disabled) {
      classNames.push('opacity-60 hover:bg-transparent cursor-not-allowed');
    } else {
      classNames.push('hover:bg-neutral-200 hover:text-primary-950');
    }

    return classNames.join(' ');
  };

  return (
    <div ref={parentRef} className="relative">
      <Popup
        parentElementRef={parentRef.current}
        isOpen={isPopupOpen}
        alignContent={PopupAlign.START}
        onClickOutside={() => setIsPopupOpen(false)}
        containerClassName="z-1"
        containerPosition={containerPosition}
        content={
          <div className="bg-neutral-50 rounded-xl shadow-xl p-4">
            <div className="flex mb-3">
              <Search value={search} placeholder="Search..." onChange={(e) => setSearch(e.target.value)} />
              <span className="ml-2">
                <Button
                  dataTestId="multiselect-clear-button"
                  btnShape={ButtonShape.CIRCLE}
                  btnType={ButtonType.ICON}
                  onClick={onClearAll}
                >
                  <SVG className="fill-current text-neutral-400 hover:text-primary" src={selectListSvg} />
                </Button>
              </span>
            </div>
            <div className="h-80 overflow-scroll" aria-label="multi-select-list">
              {groupItemsAlphabetically(filteredItems).map((group) => (
                <div key={group[0]}>
                  {!search && <p className="p-2 text-neutral-900 section-header">{group[0].toUpperCase()}</p>}

                  {group[1].map((item) => (
                    <label
                      key={item.code}
                      className={`block p-2 mb-0.5 body-base rounded-md ${getLabelClassNames(item)}`}
                    >
                      <input
                        data-test-id={`multiselect-input-${item.code}`}
                        className="hidden"
                        type="checkbox"
                        onClick={handleSelect}
                        value={item.code}
                        disabled={!!item.disabled}
                      />
                      <span>{item.name}</span>
                    </label>
                  ))}
                </div>
              ))}
            </div>
          </div>
        }
      >
        <button
          data-test-id={dataTestId}
          type="button"
          className="border border-solid text-center border-neutral-300 rounded-md p-2 hover:bg-neutral-100"
          onClick={() => setIsPopupOpen((prevState) => !prevState)}
          disabled={isDisabled}
        >
          <SVG className="fill-current text-neutral-950-opacity-50 w-4 h-4" src={plusSvg} />
        </button>
      </Popup>
    </div>
  );
};

MultiSelect.propTypes = {
  dataTestId: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({ code: PropTypes.string, name: PropTypes.string, disabled: PropTypes.bool }),
  ),
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  onSelect: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  containerPosition: PropTypes.oneOf(Object.values(PopupPosition)),
};

MultiSelect.defaultProps = {
  items: [],
  dataTestId: '',
  selectedItems: [],
  isDisabled: false,
  containerPosition: PopupPosition.BOTTOM,
};

export default MultiSelect;
