import Input from 'lib/Input';
import { FilterPersonNameProps } from './FilterPersonName.types';

const FilterPersonName: React.FC<FilterPersonNameProps> = ({ changeFilters, value }) => {
  return (
    <Input
      name="personName"
      value={value}
      placeholder="Type person name"
      onChange={(userInput: string) => changeFilters({ personName: userInput })}
      debounceTime={300}
    />
  );
};

export default FilterPersonName;
