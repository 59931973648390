import editSvg from 'assets/icons/edit.svg';
import { FrontEndType } from 'components/common/types/Deal.types';
import { DealItemData } from 'components/common/types/DealItem';
import { FeatureFlags } from 'components/common/types/Features.types';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import SVG from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';

type ActionRendererProps = {
  data?: DealItemData;
};

const ActionRenderer: React.FC<ActionRendererProps> = ({ data = {} }) => {
  const hasPlannerNativeEnabled = useHasFeatureAccess(FeatureFlags.PLANNER_NATIVE);
  const hasDealLinesEnabled = useHasFeatureAccess(FeatureFlags.DEAL_LINES);
  const navigate = useNavigate();

  if (!data.internalId) return null;

  const onClick = (): void => {
    if (hasPlannerNativeEnabled && data.frontEndType === FrontEndType.PLANNER) {
      navigate(`/planner/programmatic/${data.internalId}`);

      return;
    }

    if (hasDealLinesEnabled) {
      navigate(`/deal/${data.internalId}`);
    } else {
      navigate(`/deal/details/${data.internalId}`);
    }
  };

  return (
    <span className="table-action-button">
      <Button
        dataTestId={`edit-${data.bookingStatusCode}`}
        btnShape={ButtonShape.CIRCLE}
        btnType={ButtonType.ICON}
        onClick={onClick}
      >
        <SVG src={editSvg} />
      </Button>
    </span>
  );
};

export default ActionRenderer;
