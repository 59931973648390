import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

import locationSvg from 'assets/icons/location.svg';
import withCancelRequest from 'components/hocs/withCancelRequest';
import { Store } from 'components/common/types/Store.types';
import AssetsMap from 'components/common/AssetsMap';
import { DetailedInventoryAsset } from 'components/common/types/DetailedInventoryAsset.types';
import {
  AssetSidebarProps,
  SidebarSections,
} from 'components/pages/InventoryManagement/AssetList/AssetSidebar/AssetSidebar.types';
import { DOUBLE_DASH } from 'consts/placeholders';
import { getAssetById } from 'modules/api/asset';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import Prices from './Prices';
import AssetForm from './AssetForm';
import Prohibitions from './Prohibitions';
import Schedule from './Schedule';
import TimeModels from './TimeModels';
import Format from './Format';
import Attributes from './Attributes';
import Networks from './Networks';
import Tags from './Tags';
import Restrictions from './Restrictions';
import ReadMoreSections from './ReadMoreSections';

const AssetSidebar: React.FC<AssetSidebarProps> = ({ cancelFunctions }: AssetSidebarProps) => {
  const MAX_RECORDS_TO_SHOW = 3;
  const [asset, setAsset] = useState<DetailedInventoryAsset | null>(null);
  const [openSections, setOpenSections] = useState<SidebarSections>({
    prices: false,
    prohibitions: false,
    timeModels: false,
    networks: false,
    tags: false,
  });
  const frameCode = useSelector((state: Store) => state.pages.inventoryManagement.assetList.frameCode);

  useEffect(() => {
    const getAsset = async (): Promise<void> => {
      try {
        const newAsset: DetailedInventoryAsset = await getAssetById(cancelFunctions, frameCode);
        setAsset(newAsset);
      } catch {} // eslint-disable-line no-empty
    };

    if (frameCode) getAsset();
  }, [frameCode]);

  const toggleReadMoreSection = (sectionKey: string): void =>
    setOpenSections((prev: SidebarSections) => ({ ...prev, [sectionKey]: !prev[sectionKey as keyof SidebarSections] }));

  const mapData =
    asset?.latitude && asset?.longitude
      ? [{ frameId: asset?.routeFrameCode, latitude: asset?.latitude, longitude: asset?.longitude }]
      : [];

  return (
    <>
      <div
        className="text-neutral-950-opacity-80 bg-neutral-200 h-full overflow-y-scroll py-4 px-8"
        data-test-id="inventory-asset-sidebar"
      >
        <div className="px-2 flex justify-between items-end mb-6">
          <div>
            <p className="body-sm">Route frame code</p>
            <p className="body-base" data-test-id="route-frame-code">
              {asset?.routeFrameCode ?? DOUBLE_DASH}
            </p>
          </div>
          <p className="body-sm">
            Frame code <span className="body-base">{frameCode || DOUBLE_DASH}</span>
          </p>
        </div>
        {useHasFeatureAccess(FeatureFlags.GOOGLEMAPS) ? (
          <div className="bg-neutral-100 p-3.5 rounded-xl">
            <div className="h-48 rounded-xl overflow-hidden">
              <AssetsMap assets={mapData} />
            </div>

            {asset?.addressDetail1 ? (
              <p className="flex items-center px-3 mt-4">
                <SVG src={locationSvg} className="text-primary mr-3" />
                {`${asset?.addressDetail1}${asset?.addressDetail2 ? `, ${asset?.addressDetail2}` : ''}`}
              </p>
            ) : null}
          </div>
        ) : null}
        <div className="mt-8 px-4 space-y-4">
          <AssetForm asset={asset} />
          <Prices
            assetPrices={asset?.prices}
            toggleReadMore={() => toggleReadMoreSection('prices')}
            maxRecordsToShow={MAX_RECORDS_TO_SHOW}
          />
          <Prohibitions
            prohibitions={asset?.prohibitions}
            toggleReadMore={() => toggleReadMoreSection('prohibitions')}
            maxRecordsToShow={MAX_RECORDS_TO_SHOW}
          />
          <Schedule asset={asset} />
          <TimeModels
            timeModels={asset?.timeModels}
            toggleReadMore={() => toggleReadMoreSection('timeModels')}
            maxRecordsToShow={MAX_RECORDS_TO_SHOW}
          />
          <Format asset={asset} />
          <Attributes asset={asset} />
          <Networks
            networks={asset?.networks}
            toggleReadMore={() => toggleReadMoreSection('networks')}
            maxRecordsToShow={MAX_RECORDS_TO_SHOW}
          />
          <Tags
            tags={asset?.tags}
            toggleReadMore={() => toggleReadMoreSection('tags')}
            maxRecordsToShow={MAX_RECORDS_TO_SHOW}
          />
          <Restrictions asset={asset} />
        </div>
      </div>
      <ReadMoreSections asset={asset} toggleReadMoreSection={toggleReadMoreSection} openSections={openSections} />
    </>
  );
};

export default withCancelRequest(AssetSidebar);
