import { LabelProps } from './Label.types';

export const Label: React.FC<LabelProps> = ({ id, dataTestId, label, isRequired }) => {
  return (
    <label
      htmlFor={id}
      data-test-id={dataTestId}
      className="sub-header-base text-neutral-950-opacity-90 mb-1 flex justify-between"
    >
      {label}
      {isRequired ? (
        <div
          className="text-primary-600 font-normal"
          data-test-id={`required-indicator-for-${label.replaceAll(' ', '-').toLowerCase()}`}
        >
          Required
        </div>
      ) : null}
    </label>
  );
};
