import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Dropdown from 'lib/Dropdown';
import styles from './ActionRenderer.pcss';

const cx = classNames.bind(styles);

class ActionRenderer extends Component {
  state = {
    dropdownOptions: [
      {
        code: '',
        name: 'Set rule',
      },
      {
        code: 'inclusion',
        name: 'Include',
      },
      {
        code: 'exclusion',
        name: 'Exclude',
      },
    ],
  };

  componentDidMount() {
    const { data } = this.props;
    const { dropdownOptions } = this.state;

    if (data.type) {
      this.setState({ dropdownOptions: dropdownOptions.slice(1) });
    }
  }

  onDropdownChange = (_newValue, _dropdownName, selectedObj) => {
    const { onTypeChange, data } = this.props;

    onTypeChange({ ...data, type: selectedObj.code });
  };

  render() {
    const { data } = this.props;
    const { dropdownOptions } = this.state;

    return (
      <div className={cx('frame-tags')}>
        <Dropdown
          name="type"
          dataList={dropdownOptions}
          value={data?.type ?? ''}
          onChange={this.onDropdownChange}
          textKey="name"
          valueKey="code"
        />
      </div>
    );
  }
}

ActionRenderer.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  onTypeChange: PropTypes.func,
};

ActionRenderer.defaultProps = {
  onTypeChange: () => undefined,
};

export default ActionRenderer;
