import { Label } from 'lib/Label';

type FilterWithLabelProps = {
  label: string;
};

const FilterWithLabel: React.FC<FilterWithLabelProps> = ({ label, children }) => {
  return (
    <div className="flex flex-col">
      <Label label={label} />
      {children}
    </div>
  );
};

export default FilterWithLabel;
