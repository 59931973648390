const ASSET_STATUS = {
  MOUNTED: 'MOUNTED',
  DISMOUNTED: 'DISMOUNTED',
};

export const ASSET_STATUS_BADGE = [
  {
    code: ASSET_STATUS.MOUNTED,
    name: 'Mounted',
    initials: 'M',
    color: 'bg-green',
  },
  {
    code: ASSET_STATUS.DISMOUNTED,
    name: 'Dismounted',
    initials: 'D',
    color: 'bg-yellow',
  },
];

export default ASSET_STATUS;
