import { usePageFilters } from 'customHooks/usePageFilters';
import FilterArea from './FilterArea';
import AssetTable from './AssetTable';

const AssetContent: React.FC = () => {
  const { filters } = usePageFilters();

  return (
    <>
      <FilterArea />
      <AssetTable filters={filters} />
    </>
  );
};

export default AssetContent;
