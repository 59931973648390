import { RuntimeFilterOp } from '@thoughtspot/visual-embed-sdk';
import LiveboardVisuals from 'components/patterns/LiveboardVisuals';
import { thoughtspot } from 'config';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { initialiseThoughtspot } from 'utils/thoughtspotInit';

const Pacing: React.FC = () => {
  const localeCode = useSelector((state: RootState) => state.publisher.configuration.localeCode);
  const marketId = useSelector((state: RootState) => state.publisher.configuration.marketId);
  const cancelFunctions = useCancelRequest();

  initialiseThoughtspot(cancelFunctions, localeCode);

  const filterByMarketId = [
    {
      columnName: 'Market',
      operator: RuntimeFilterOp.CONTAINS,
      values: [marketId || ''],
    },
  ];

  return (
    <div className="pt-4 h-full">
      <LiveboardVisuals
        liveboardId={thoughtspot.pacingBoardId}
        filterByOptions={filterByMarketId}
        customizations={{
          style: {
            customCSS: {
              rules_UNSTABLE: {
                "[data-tooltip-content*='Column name: Market']": {
                  display: 'none',
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default Pacing;
