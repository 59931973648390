import { useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import creativesSvg from 'assets/icons/creatives.svg';

import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import SingleContent from 'components/common/layout/SingleContent';

import ContentManagementHeader from '../ContentManagementHeader';
import Main from './Content';

const Creatives = () => {
  const navigate = useNavigate();

  const actionButton = Auth.hasPermission(PermissionsEnum.CONTENT_UPLOAD) ? (
    <Button
      onClick={() => navigate('/content-management/uploadCreative')}
      btnType={ButtonType.PRIMARY}
      btnShape={ButtonShape.NORMAL}
    >
      <SVG src={creativesSvg} />
      <span>Upload creative</span>
    </Button>
  ) : null;

  return (
    <>
      <ContentManagementHeader actionButton={actionButton} />
      <SingleContent content={<Main />} />
    </>
  );
};

export default Creatives;
