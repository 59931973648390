import { PlannerPoi } from '../components/common/types/Planner.types';

export interface POIRawResponse {
  geometry: {
    coordinates: [number, number];
    type: string;
  };
  properties: {
    address: string;
    categoryName: string;
    categoryType: string;
    dataProvider: string;
    latitude: number;
    longitude: number;
    mediaOwner: string;
    poiId: string;
    poiName: string;
    postalCode: string;
  };
  type: string;
}

export const transformPoiRawData = (poiRawData: POIRawResponse[]): PlannerPoi[] =>
  poiRawData.map((rawPoi) => ({
    id: rawPoi.properties.poiId,
    name: rawPoi.properties.poiName,
    geometry: {
      coordinates: [rawPoi.properties.longitude, rawPoi.properties.latitude],
    },
    properties: {
      categoryName: rawPoi.properties?.categoryName || '',
      categoryType: rawPoi.properties?.categoryType || '',
      address: rawPoi.properties?.address || '',
      postalCode: rawPoi.properties?.postalCode || '',
      dataProvider: rawPoi.properties?.dataProvider || '',
      mediaOwner: rawPoi.properties?.mediaOwner || '',
      type: rawPoi.geometry.type || '',
      visibility: {
        include: true,
        radius: 0,
      },
    },
  }));
