import { AxiosRequestConfig } from 'axios';
import createRequest from 'modules/api/request';
import { CancelFunctions } from '../../components/common/types';

export interface ReqOptions extends AxiosRequestConfig {
  headers?: { [key: string]: string };
  showLoader?: boolean;
  notificationTimeout?: number;
  cancelable?: boolean;
}

const handleRequest = async <T>(
  reqOptions: ReqOptions,
  cancelFunctions: CancelFunctions,
  cancelName: string,
): Promise<T> => {
  const { send, cancel } = createRequest(reqOptions);

  if (cancelFunctions[cancelName]) cancelFunctions[cancelName]();
  cancelFunctions[cancelName] = cancel;

  const result = await send();

  return result.data;
};

export default handleRequest;
