import { Children, PropsWithChildren } from 'react';

import { useMasonryGalleryPositioning } from './useMasonryGalleryPositioning';
import { MasonryGalleryProps } from './MasonryGallery.types';

export const MasonryGallery: React.FC<PropsWithChildren<MasonryGalleryProps>> = ({ columns, children }) => {
  const { containerRef, elementsRefs, elementsPositions } = useMasonryGalleryPositioning(children, columns);

  return (
    <div ref={containerRef} className="relative">
      {Children.map(children, (child, i) => (
        <div
          ref={elementsRefs[i]}
          className="absolute block box-content transition-all ease-in-out duration-200"
          style={elementsPositions[i]}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
