import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Auth from 'modules/Auth';
import withRouter from 'components/hocs/withRouter';

type OwnProps = {
  location: Location;
};
type DispatchToPropsType = {};
type Props = OwnProps & DispatchToPropsType;

class Callback extends React.Component<Props> {
  state = {
    authorized: null,
  };

  componentDidMount(): void {
    this.authenticate();
  }

  authenticate = async (): Promise<void> => {
    const { location } = this.props;

    if (location.pathname.includes('auth-callback')) {
      try {
        await Auth.authenticate();
        this.setState({ authorized: true });
      } catch (error) {
        this.setState({ authorized: false });
        throw error;
      }
    }
  };

  render(): React.ReactNode {
    const { authorized } = this.state;
    if (!authorized) {
      return 'Checking authentication';
    }

    return <Navigate to="/" />;
  }
}

export default withRouter(Callback);
