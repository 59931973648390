import SingleContent from 'components/common/layout/SingleContent';
import UserManagementHeader from 'components/pages/UserManagement/UserManagementHeader';
import UserManagementContent from 'components/pages/UserManagement/UserManagementContent';
import { PageWrapper } from 'components/common/layout/PageWrapper/PageWrapper';

const UserManagement = () => (
  <PageWrapper>
    <UserManagementHeader />
    <SingleContent content={<UserManagementContent />} />
  </PageWrapper>
);

export default UserManagement;
