import { useLayoutEffect, useState } from 'react';

interface UseDropdownButtonPositionArgs {
  triggerElement: React.RefObject<HTMLElement>;
  popoverElement: React.RefObject<HTMLElement>;
  mainContainerId: string;
}

interface UseDropdownButtonPositionReturn {
  dropdownPopoverPositionTop: number;
}

export const useDropdownButtonPosition = ({
  triggerElement,
  popoverElement,
  mainContainerId,
}: UseDropdownButtonPositionArgs): UseDropdownButtonPositionReturn => {
  const [triggerElementPosition, setTriggerElementPosition] = useState(0);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const mainContainer: HTMLElement | null = document.getElementById(mainContainerId);

  const getTriggerPosition = (): void => {
    if (!triggerElement.current || !mainContainer) {
      return;
    }

    let offsetAmountToOpenUpDirection = 0;

    const triggerElementPositionBottom = triggerElement.current.getBoundingClientRect().bottom + 55;
    const containerPositionTop = mainContainer.getBoundingClientRect().top;

    if (
      popoverElement.current &&
      triggerElementPositionBottom - containerPositionTop + popoverElement.current.getBoundingClientRect().height >
        screenHeight
    ) {
      offsetAmountToOpenUpDirection =
        popoverElement.current.getBoundingClientRect().height +
        triggerElement.current.getBoundingClientRect().height +
        10;
    }

    setTriggerElementPosition(triggerElementPositionBottom - containerPositionTop - offsetAmountToOpenUpDirection);
  };

  useLayoutEffect(() => {
    const handleHeight = (): void => setScreenHeight(window.innerHeight);

    window.addEventListener('resize', handleHeight);

    return () => {
      window.removeEventListener('resize', handleHeight);
    };
  });

  useLayoutEffect(() => {
    mainContainer?.addEventListener('scroll', getTriggerPosition);
    triggerElement.current?.addEventListener('click', getTriggerPosition);

    return () => {
      mainContainer?.removeEventListener('scroll', getTriggerPosition);
      triggerElement.current?.removeEventListener('click', getTriggerPosition);
    };
  }, [mainContainer, triggerElement, popoverElement]);

  return {
    dropdownPopoverPositionTop: triggerElementPosition,
  };
};
