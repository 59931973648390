import cx from 'classnames';
import SVG from 'react-inlinesvg';

import Tooltip from 'components/patterns/Tooltip';
import { MenuItemType } from './MenuItem.types';

const MenuItem: React.FC<MenuItemType> = ({ item, onNavigate, isActive, itemStyle = 'hover:bg-primary' }) => {
  return (
    <li data-test-id="menu-item" className="text-center relative mb-5">
      <Tooltip tooltip={item.tooltip} delay>
        <button
          type="button"
          onClick={onNavigate}
          disabled={item.isDisabled}
          className={cx(
            'inline-block p-2 leading-[9px] transition-all ease-in-out duration-300 outline-none rounded-md cursor-pointer',
            itemStyle,
          )}
        >
          {item.icon && <SVG className="text-neutral-50 w-4.5 h-4.5" src={item.icon} />}
        </button>
      </Tooltip>
      <div
        data-test-id="active-token"
        className={cx('inline-block absolute top-[9px] right-0 w-1 h-4 rounded-l-[4px] bg-yellow', {
          visible: isActive,
          invisible: !isActive,
        })}
      />
    </li>
  );
};

export default MenuItem;
