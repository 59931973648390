import { MotionType } from 'consts/motionType';
import uniq from 'lodash/uniq';

type MotionTypesRendererProps = {
  motionTypes: MotionType[];
};
const MotionTypesRenderer: React.FC<MotionTypesRendererProps> = ({ motionTypes }) => {
  const motionOptions = uniq(motionTypes);
  return (
    <div className="flex space-x-2">
      {motionOptions.map((item) => (
        <span>{item === MotionType.MOVING ? 'Video' : 'Still'}</span>
      ))}
    </div>
  );
};
export default MotionTypesRenderer;
