import PropTypes from 'prop-types';

import { DOUBLE_DASH } from 'consts/placeholders';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';

const Restrictions = ({ asset }) => {
  const prohibitionCounters =
    asset?.prohibitionCounters?.map(({ productCategoryName }) => productCategoryName ?? DOUBLE_DASH).join(', ') ||
    DOUBLE_DASH;

  const blacklists =
    asset?.blacklists?.map(({ organisationUuid }) => organisationUuid ?? DOUBLE_DASH).join(', ') || DOUBLE_DASH;

  return (
    <div>
      <h2 className="sub-header-base mb-8">Restrictions</h2>
      <div className="pl-2">
        <Timeline>
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 items-center">
                <p className="body-sm">Counters prohibition</p>
                <p className="body-base break-all">{prohibitionCounters}</p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 items-center">
                <p className="body-sm">Blacklist</p>
                <p className="body-base">{blacklists}</p>
              </div>
            }
          />
        </Timeline>
      </div>
    </div>
  );
};

Restrictions.propTypes = {
  asset: PropTypes.shape({
    prohibitionCounters: PropTypes.arrayOf(PropTypes.shape({ productCategoryName: PropTypes.string })),
    blacklists: PropTypes.arrayOf(PropTypes.shape({ organisationUuid: PropTypes.string })),
  }),
};

Restrictions.defaultProps = {
  asset: {},
};

export default Restrictions;
