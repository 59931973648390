import { useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';

import copyIcon from 'assets/icons/copy.svg';
import creativesIcon from 'assets/icons/creatives.svg';
import userSvg from 'assets/icons/user.svg';
import warningIcon from 'assets/icons/warning.svg';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import Card from 'components/patterns/Card';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import Modal from 'components/patterns/Modal';
import Input from 'lib/Input';
import { PanelsModel, CodeNameModel } from 'components/common/prop-types/creativeSets';
import withCancelRequest from 'components/hocs/withCancelRequest';

import VisualUnitDetails from './VisualUnitDetails';
import CreativeDetails from './CreativeDetails';

const SetDetails = ({ creativeSetDetails, saveSetDetails, changeCode, deleteCreativeSet, cancelFunctions }) => {
  const navigate = useNavigate();
  const [isSetNameOpen, setSetNameOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const isSaveDisabled =
    !creativeSetDetails.panels.every((panel) => !!panel.creative?.id) ||
    creativeSetDetails.isEditingDisabled ||
    !creativeSetDetails.isPanelView;

  const onSuccessCreate = () => {
    setSetNameOpen(false);
    navigate('/content-management/creative-sets');
  };

  const saveSet = () =>
    saveSetDetails({
      cancelFunctions,
      data: creativeSetDetails,
      id: creativeSetDetails.id,
      onSuccess: onSuccessCreate,
    });

  const saveActionButtons = (
    <>
      <Button
        dataTestId="cancel-button"
        btnType={ButtonType.DANGER}
        btnShape={ButtonShape.NORMAL}
        onClick={() => setSetNameOpen(false)}
        isDisabled={creativeSetDetails.isEditingDisabled}
        label="Cancel save set"
      >
        Cancel
      </Button>
      <Button
        dataTestId="save-button"
        btnType={ButtonType.PRIMARY}
        btnShape={ButtonShape.NORMAL}
        onClick={saveSet}
        isDisabled={creativeSetDetails.isEditingDisabled || creativeSetDetails.code === ''}
        label="Save set"
      >
        Save
      </Button>
    </>
  );

  const deleteActionButtons = (
    <>
      <Button
        btnType={ButtonType.DANGER}
        btnShape={ButtonShape.NORMAL}
        onClick={() => setDeleteOpen(false)}
        isDisabled={creativeSetDetails.isEditingDisabled}
        dataTestId="cancel-button"
        label="Cancel delete"
      >
        Cancel
      </Button>
      <Button
        btnType={ButtonType.PRIMARY}
        btnShape={ButtonShape.NORMAL}
        onClick={() =>
          deleteCreativeSet({
            cancelFunctions,
            data: creativeSetDetails,
            onSuccess: () => navigate('/content-management/creative-sets/'),
          })
        }
        isDisabled={creativeSetDetails.isEditingDisabled}
        dataTestId="continue-button"
        label="Continue delete"
      >
        Continue
      </Button>
    </>
  );

  return (
    <>
      <div data-test-id="set-details" className="grid md:col-auto lg:grid-cols-12 gap-6">
        <div className="lg:col-span-8">
          <Card header="Visual units" info="Visual unit group description" icon={copyIcon}>
            <VisualUnitDetails panels={creativeSetDetails.panels} visualUnitIds={creativeSetDetails.visualUnitIds} />
          </Card>
        </div>
        <div className="lg:col-span-4">
          <Card header="Creatives" info="Link your creatives" icon={creativesIcon}>
            <CreativeDetails creativeDetails={creativeSetDetails} />
          </Card>
        </div>
      </div>
      <div className={`flex ${creativeSetDetails.isNewSet ? 'justify-end' : 'justify-between'}`}>
        {Auth.hasPermission(PermissionsEnum.CONTENT_REVIEW) && !creativeSetDetails.isNewSet ? (
          <Button
            dataTestId="delete-button"
            btnShape={ButtonShape.NORMAL}
            btnType={ButtonType.SECONDARY}
            onClick={() => setDeleteOpen(true)}
            isDisabled={creativeSetDetails.isEditingDisabled}
            label="Delete"
          >
            Delete
          </Button>
        ) : null}
        {Auth.hasPermission(PermissionsEnum.CONTENT_APPROVE) && creativeSetDetails.isNewSet ? (
          <Button
            dataTestId="save-set-button"
            btnShape={ButtonShape.NORMAL}
            btnType={ButtonType.PRIMARY}
            onClick={creativeSetDetails.id ? saveSet : () => setSetNameOpen(true)}
            isDisabled={isSaveDisabled}
            label="Save set"
          >
            Save set
          </Button>
        ) : null}
      </div>
      <Modal
        isOpen={isSetNameOpen}
        icon={<SVG src={userSvg} className="text-primary" />}
        title="Save Creative Set"
        actionButtons={saveActionButtons}
        dataTestId="set-name-modal"
      >
        <p className="sub-header-base text-neutral-950-opacity-60 mb-2.5">Set Name</p>
        <Input
          dataTestId="set-name-input"
          placeholder="Type your set name..."
          onChange={(value) => changeCode(value.trim())}
          value={creativeSetDetails.code}
          isDisabled={creativeSetDetails.isEditingDisabled}
        />
      </Modal>
      <Modal
        isOpen={isDeleteOpen}
        icon={<SVG src={warningIcon} className="h-5 w-5 text-yellow" />}
        title="Delete creative set"
        actionButtons={deleteActionButtons}
        dataTestId="delete-modal"
      >
        <div className="ml-8 body-base text-neutral-950-opacity-60 space-y-3">
          <p>Are you sure you want to delete?</p>
          <p>This creative set will be removed from all linked Visual units.</p>

          <p>Please note that this action could impact live deals.</p>
        </div>
      </Modal>
    </>
  );
};

SetDetails.propTypes = {
  creativeSetDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isNewSet: PropTypes.bool.isRequired,
    isEditingDisabled: PropTypes.bool.isRequired,
    isPanelView: PropTypes.bool.isRequired,
    code: PropTypes.string.isRequired,
    visualUnitIds: PropTypes.arrayOf(PropTypes.string),
    panels: PanelsModel.isRequired,
    dsp: CodeNameModel,
    brand: CodeNameModel,
    advertiser: CodeNameModel,
    status: PropTypes.string.isRequired,
  }).isRequired,
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  saveSetDetails: PropTypes.func.isRequired,
  changeCode: PropTypes.func.isRequired,
  deleteCreativeSet: PropTypes.func.isRequired,
};

export default withCancelRequest(SetDetails);
