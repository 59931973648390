import { Brand } from 'components/common/types/Deal.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { FilterBrandProps } from './FilterBrand.types';
import { useLoadLookup } from '../useLoadLookup';

const FilterBrand: React.FC<FilterBrandProps> = ({ brand, changeFilters }) => {
  const { onLoadBrands } = useLoadLookup();

  const handleSelectBrand = (_name: string, value: Brand): void => {
    changeFilters({ brand: value });
  };

  return (
    <AsyncSelect
      name="brand"
      selectedValue={brand}
      loadData={onLoadBrands}
      onSelect={handleSelectBrand}
      placeholder="Search brand"
      dataTestId="brand-filter"
      textKey="name"
    />
  );
};

export default FilterBrand;
