import { useMemo } from 'react';
import { DATE_FORMAT } from 'modules/api/constants';
import { LevelType } from 'consts/thoughtspot';
import BaseSelect from 'lib/BaseSelect';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import DayPicker from 'components/patterns/DayPicker';
import { CodeNameModel } from 'components/common/types';
import { findOption, transformOptions } from 'lib/BaseSelect/utils';
import { FiltersProps } from './Filters.types';

const Filters: React.FC<FiltersProps> = ({
  listOfLineIds,
  listOfVisuals,
  dealLineId,
  visualId,
  onChangeDealLines,
  onChangeVisuals,
  onChangeLevelType,
  isDisabled,
  startDate,
  endDate,
  dateFilter,
  onDateChange,
  selectedLevelType,
}) => {
  const linesOptions = useMemo(
    () => transformOptions(listOfLineIds, { codeKey: 'id', nameKey: 'lineId' }),
    [listOfLineIds],
  );
  const visualOptions = useMemo(
    () => transformOptions(listOfVisuals, { codeKey: 'id', nameKey: 'name' }),
    [listOfVisuals],
  );
  const selectedLine = useMemo(() => findOption(linesOptions, 'name', dealLineId), [dealLineId, linesOptions]);
  const selectedVisual = useMemo(() => findOption(visualOptions, 'code', visualId), [visualId, visualOptions]);

  return (
    <div className="flex pr-3 items-start space-x-4">
      <div className="mr-6">
        <MultipleSwitchBox
          source={[
            { label: 'Deal', value: LevelType.DEAL },
            { label: 'Line', value: LevelType.LINE },
          ]}
          selectedValue={selectedLevelType}
          textKey="label"
          valueKey="value"
          onChange={onChangeLevelType}
          isDisabled={isDisabled}
          dataTestId="level-type"
        />
      </div>
      <BaseSelect
        name="select-deal-lines"
        dataTestId="select-line-ids"
        placeholder="Show Lines"
        options={linesOptions}
        selectedValue={selectedLine}
        onSelect={(_, value: CodeNameModel) => onChangeDealLines(value.name)}
        isDisabled={selectedLevelType !== LevelType.LINE || isDisabled}
      />
      <BaseSelect
        name="select-visuals"
        placeholder="Show metrics"
        selectedValue={selectedVisual}
        options={visualOptions}
        dataTestId="select-visuals"
        onSelect={(_, value: CodeNameModel) => onChangeVisuals(value.code)}
      />
      <DayPicker
        dataTestId="start-date-picker"
        format={DATE_FORMAT}
        selectedDay={dateFilter.from}
        disabledDays={[{ before: startDate, after: dateFilter.to }]}
        onDayChange={(value: Date) =>
          onDateChange({
            from: value,
            to: dateFilter.to,
          })
        }
      />
      <DayPicker
        dataTestId="end-date-picker"
        format={DATE_FORMAT}
        selectedDay={dateFilter.to}
        disabledDays={[{ before: dateFilter.from, after: endDate }]}
        onDayChange={(value: Date) => {
          onDateChange({
            from: dateFilter.from,
            to: value,
          });
        }}
      />
    </div>
  );
};

export default Filters;
