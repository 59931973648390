import { Suspense } from 'react';
import SVG from 'react-inlinesvg';

import Card from 'components/patterns/Card';
import dealBubbleSvg from 'assets/img/deal-bubble.svg';

import { Preloader } from 'components/patterns/Loader';
import { TableFilters } from 'components/common/types/Table.types';
import { usePageFilters } from 'customHooks/usePageFilters';
import ImpressionsTradedChart from './Charts/ImpressionsTradedChart';
import PerformanceTable from './PerformanceTable';
import TradingBehaviourChart from './Charts/TradingBehaviourChart/TradingBehaviourChart';
import RevenueChart from './Charts/RevenueChart';
import Filters from './Filters';

const PerformanceContent: React.FC = () => {
  const { filters } = usePageFilters<TableFilters>('insights/dashboard');

  return (
    <>
      <div className="mb-4">
        <Filters />
      </div>
      <Suspense fallback={<Preloader />}>
        {filters.deal ? (
          <>
            <Card>
              <TradingBehaviourChart />
            </Card>
            <Card>
              <ImpressionsTradedChart />
            </Card>
            <Card>
              <RevenueChart />
            </Card>
            <PerformanceTable />
          </>
        ) : (
          <Card>
            <div className="flex flex-col items-center my-16">
              <SVG src={dealBubbleSvg} />
              <div className="text-neutral-900 body-base mt-10">Please select a deal to view performance insights</div>
            </div>
          </Card>
        )}
      </Suspense>
    </>
  );
};

export default PerformanceContent;
