import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getLookupData } from 'modules/api/lookups';
import withCancelRequest from 'components/hocs/withCancelRequest';
import Chips from 'components/patterns/Chips';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { getIsReseller } from 'store/publisher/selectors';
import { getResellerDealMediaOwners } from 'store/reseller/selectors';
import multiPublisherRequest from 'modules/api/multiPublisherRequest';

import VisualUnitFilesUpload from './VisualUnitFilesUpload';

const VisualUnits = ({
  readOnly,
  cancelFunctions,
  visualUnitCodes,
  onChangeVisualUnitCodes,
  visualUnitFiles,
  onChangeVisualUnitFiles,
}) => {
  const resellerMediaOwners = useSelector(getResellerDealMediaOwners);
  const isReseller = useSelector(getIsReseller);
  const { environmentId } = useSelector((state) => state.environment);
  const [routeVisualUnitCodesWithLabels, setVisualUnitCodesWithLabels] = useState([]);

  const fetchVisualUnitCodes = async (dropdownName, lookupCode) => {
    const requestArguments = { cancelFunctions, lookupURLName: dropdownName, query: '', lookupCode };
    try {
      if (isReseller) {
        const response = await multiPublisherRequest(resellerMediaOwners, getLookupData, {
          ...requestArguments,
        });
        return response.map(({ publisher, code }) => ({
          code,
          mediaOwner: publisher,
          label: `${publisher} : ${code}`,
        }));
      }
      const response = await getLookupData({ ...requestArguments });
      return response.map(({ code }) => ({ code, mediaOwner: environmentId, label: code }));
    } catch {
      return [];
    }
  };

  const onIncludeExcludeChange = (item, include) => {
    onChangeVisualUnitCodes(
      visualUnitCodes.map((visualUnitCode) =>
        visualUnitCode.code === item.code ? { ...visualUnitCode, include } : visualUnitCode,
      ),
    );
  };

  useEffect(() => {
    setVisualUnitCodesWithLabels(
      visualUnitCodes?.map((visualUnit) => ({
        ...visualUnit,
        label: isReseller ? `${visualUnit.mediaOwner} : ${visualUnit.code}` : visualUnit.code,
      })) || [],
    );
  }, [visualUnitCodes]);

  return (
    <div className="grid grid-cols-2 gap-x-7 xl:gap-x-24 px-12 py-6" data-test-id="visualUnits">
      <div>
        <p className="mb-2 sub-header-base text-neutral-950-opacity-60">Visual units ID</p>
        <div className="mb-1.5">
          <AsyncSelect
            name="visualunitcodes"
            dataTestId="visualunitcodes"
            loadData={fetchVisualUnitCodes}
            selectedValue={routeVisualUnitCodesWithLabels}
            placeholder="VU code"
            onSelect={(_, value) =>
              onChangeVisualUnitCodes(value.map((item) => ({ ...item, include: item.include !== false })))
            }
            isClearable={false}
            multiple
            isMultiValueVisible={false}
            valueKey="code"
            textKey="label"
            isDisabled={readOnly}
          />
        </div>
        {visualUnitCodes.length ? (
          <Chips
            dataTestId="visualunitcodes-chips"
            dataList={routeVisualUnitCodesWithLabels}
            idKey="code"
            labelKey="label"
            onRemoveAll={() => onChangeVisualUnitCodes([])}
            onRemove={(item) => onChangeVisualUnitCodes(visualUnitCodes.filter(({ code }) => code !== item.code))}
            excludeKey="include"
            onIncludeExcludeChange={onIncludeExcludeChange}
            isDisabled={readOnly}
          />
        ) : null}
      </div>
      <VisualUnitFilesUpload
        readOnly={readOnly}
        visualUnitFiles={visualUnitFiles}
        onChangeVisualUnitFiles={onChangeVisualUnitFiles}
      />
    </div>
  );
};

VisualUnits.propTypes = {
  readOnly: PropTypes.bool,
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  visualUnitCodes: PropTypes.array,
  onChangeVisualUnitCodes: PropTypes.func,
  visualUnitFiles: PropTypes.array,
  onChangeVisualUnitFiles: PropTypes.func,
};

VisualUnits.defaultProps = {
  readOnly: false,
  visualUnitCodes: [],
  onChangeVisualUnitCodes: () => undefined,
  visualUnitFiles: [],
  onChangeVisualUnitFiles: () => undefined,
};

export default withCancelRequest(VisualUnits);
