import FilterArea from 'components/common/FilterArea';
import FilterAllOrInUseToggle from 'components/common/FilterArea/Filters/FilterAllOrInUseToggle';
import { FiltersProps } from './Filters.types';
import FilterPersonCode from './FilterPersonCode';
import FilterPersonName from './FilterPersonName';
import FilterPersonType from './FilterPersonType';

const FiltersArea: React.FC<FiltersProps> = ({ filters, onFilterChange }) => {
  return (
    <FilterArea customWrapperStyles="flex w-full gap-x-3 [&>*]:flex-1 items-center">
      <FilterPersonCode changeFilters={onFilterChange} value={filters.personCode} />
      <FilterPersonName changeFilters={onFilterChange} value={filters.personName} />
      <FilterPersonType changeFilters={onFilterChange} value={filters.personType} />
      <FilterAllOrInUseToggle changeFilters={onFilterChange} value={filters.inUse} />
    </FilterArea>
  );
};
export default FiltersArea;
