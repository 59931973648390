import { useState } from 'react';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

import dealsSvg from 'assets/icons/deals.svg';
import selectListSvg from 'assets/icons/select_list.svg';
import Button, { ButtonType, ButtonSize } from 'components/patterns/Button';

import DealInfo from './DealInfo';
import DealLineInfo from './DealLineInfo';

const RightSidebar = () => {
  const [activeTab, setActiveTab] = useState(2);
  const dealName = useSelector((state) => state.dealManagement.commonDeal.dealName);
  const name = useSelector((state) => state.dealManagement.commonDeal.currentLine.name);

  const tabs = [
    {
      id: 1,
      icon: dealsSvg,
      content: <DealInfo />,
      title: dealName,
    },
    {
      id: 2,
      icon: selectListSvg,
      content: <DealLineInfo />,
      title: name,
    },
  ];
  const selectedTab = tabs.find((tab) => tab.id === activeTab);

  return (
    <div data-test-id="deal-availability" className="pt-4 px-8 overflow-x-hidden h-full relative">
      <div className="flex items-center justify-between mb-[12px]">
        <p
          className="body-base text-neutral-900 whitespace-nowrap overflow-hidden overflow-ellipsis"
          title={selectedTab.title}
          data-test-id="right-sidebar-title"
        >
          {selectedTab.title}
        </p>
        <div className="flex justify-end items-center" data-test-id="right-sidebar-switch">
          {tabs.map((tab) =>
            tab.isHidden ? null : (
              <Button
                key={tab.id}
                label={`${tab.title}`}
                onClick={() => setActiveTab(tab.id)}
                btnType={ButtonType.TRANSPARENT}
                btnSize={ButtonSize.SMALL}
              >
                <SVG src={tab.icon} className={activeTab === tab.id ? 'text-primary' : 'text-neutral'} />
              </Button>
            ),
          )}
        </div>
      </div>
      {selectedTab?.content}
    </div>
  );
};

export default RightSidebar;
