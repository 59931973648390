import { useRef, useState } from 'react';
import SVG from 'react-inlinesvg';

import { DASH_SLASH } from 'consts/placeholders';
import playSvg from 'assets/icons/play.svg';
import { formatSecondsToDuration } from 'utils/formatSecondsToDuration';
import { CardVideoDurationProps } from './CardVideoDuration.types';

export const CardVideoDuration: React.FC<CardVideoDurationProps> = ({ previewURL }) => {
  const [durationText, setDurationText] = useState(DASH_SLASH);
  const videoRef = useRef<HTMLVideoElement>(null);

  const onLoadedMetadata = (): void => {
    if (!videoRef.current) {
      return;
    }

    setDurationText(formatSecondsToDuration(videoRef.current.duration));
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video className="hidden" ref={videoRef} onLoadedMetadata={onLoadedMetadata}>
        <source src={previewURL} type="video/mp4" />
      </video>

      <div className="bg-neutral-950-opacity-50 px-2 py-1 rounded-2xl flex flex-row justify-between items-center">
        <SVG src={playSvg} color="text-neutral-100" />
        <div className="body-sm flex flex-col justify-center">{durationText}</div>
      </div>
    </>
  );
};
