import * as classNames from 'classnames/bind';
import withLoader from 'components/hocs/withLoader';
import { CONTACT_EMAIL } from 'constants/accessDenied';
import RESTRICTED_ACCESS_LOGO from 'assets/img/restricted_access.png';
import styles from './AccessDenied.pcss';

const cx = classNames.bind(styles);

const AccessDenied = () => {
  return (
    <div className={cx('container-fluid', 'login-page')}>
      <div className={cx('row', 'h-100')}>
        <div className={cx('col-sm-6', 'col-md-6', 'col-12 mx-auto mt-4 self-center')}>
          <div className={cx('row', 'h-100')}>
            <div className="col-12 self-center">
              <div className={cx('form-signin', 'text-center')}>
                <img className="mx-auto" src={RESTRICTED_ACCESS_LOGO} alt="VIOOH" width="150px" />
                <br />
                <br />
                <h1 data-test-id="page-title" className="header-lg text-primary">
                  Permissions Restricted
                </h1>
                <p className="body-base">You do not have access to any environment.</p>
                <p className="body-base">
                  Please <a href={`mailto:${CONTACT_EMAIL}`}>contact us</a> to gain access
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLoader(AccessDenied);
