import { AdServerDealConfig, AutomationDealConfig, DealConfig, DealConfigStore } from './dealConfig.types';

export const isAdServerDealConfig = (config: DealConfig): config is AdServerDealConfig => {
  return config.isAdServer;
};

export const isAutomationDealConfig = (config: DealConfig): config is AutomationDealConfig => {
  return !config.isAdServer;
};

export const getAutomationDealConfigValue = <K extends keyof AutomationDealConfig>(
  config: DealConfigStore,
  configKey: K,
): AutomationDealConfig[K] | undefined => {
  if (isAutomationDealConfig(config)) {
    return config[configKey];
  }

  return undefined;
};

export const getAdServerDealConfigValue = <K extends keyof AdServerDealConfig>(
  config: DealConfigStore,
  configKey: K,
): AdServerDealConfig[K] | undefined => {
  if (isAdServerDealConfig(config)) {
    return config[configKey];
  }

  return undefined;
};
