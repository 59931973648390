import { getProductFormats } from 'modules/api/inventory';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { usePageFilters } from 'customHooks/usePageFilters';

import Input from 'lib/Input';
import { AssetListFilters, ProductFormat } from 'components/common/types/AssetList.types';
import FiltersCard from 'components/patterns/FiltersCard';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { formatFilterOptionsName } from 'utils/formatters';
import AdvancedFilters from './AdvancedFilters';

const FilterArea: React.FC = () => {
  const {
    filters: { frameCode, productFormat, routeFrameCode },
    changeFilters,
  } = usePageFilters<AssetListFilters>();
  const cancelFunctions = useCancelRequest();

  const fetchProductFormats = async (query: string): Promise<ProductFormat[]> => {
    if (query.length >= 3) {
      try {
        return await getProductFormats(cancelFunctions, query);
      } catch (error) {
        return [];
      }
    }
    return [];
  };

  return (
    <FiltersCard advancedFilters={<AdvancedFilters />}>
      <div className="grid grid-cols-4 gap-4">
        <Input
          data-test-id="route-frame"
          name="routeFrameCode"
          value={routeFrameCode || ''}
          placeholder="Route frame code"
          onChange={(value: string) => {
            changeFilters({ routeFrameCode: value });
          }}
        />
        <Input
          data-test-id="frame"
          name="frameCode"
          value={frameCode || ''}
          placeholder="Frame code"
          onChange={(value: string) => {
            changeFilters({ frameCode: value });
          }}
        />
        <div className="col-span-2">
          <AsyncSelect
            dataTestId="product-format"
            name="productFormat"
            loadData={async (_name: string, query: string) => formatFilterOptionsName(await fetchProductFormats(query))}
            onSelect={(_name: string, value: ProductFormat) => {
              changeFilters({ productFormat: value });
            }}
            placeholder="PFCode01 - Product format name"
            selectedValue={productFormat}
          />
        </div>
      </div>
    </FiltersCard>
  );
};

export default FilterArea;
