import PropTypes from 'prop-types';

import { DASH_SLASH } from 'consts/placeholders';

const ListWithDivider = ({ list, dataTestId }) => (
  <p
    className="flex flex-wrap divide-x space-x-1.5 items-center body-sm text-neutral-950-opacity-80"
    data-test-id={dataTestId}
  >
    {list.length
      ? list.map((item) => (
          <span key={item.code} className="block pl-1 mb-0.5">
            {item.name}
          </span>
        ))
      : DASH_SLASH}
  </p>
);

ListWithDivider.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataTestId: PropTypes.string,
};

ListWithDivider.defaultProps = {
  dataTestId: '',
};

export default ListWithDivider;
