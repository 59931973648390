import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import chevron_down from 'assets/icons/chevron_down.svg';
import { toggleAllCharts } from 'store/dealDashboard/reducer';

type RootState = {
  dealDashboard: {
    isChartsOpen: boolean;
  };
};

const CollapseButton = (): JSX.Element => {
  const isChartsOpen = useSelector((state: RootState) => state.dealDashboard.isChartsOpen);
  const dispatch = useDispatch();

  const handleToggleAllCharts = (): void => {
    dispatch(toggleAllCharts());
  };

  return (
    <div className="flex justify-end mb-2.5">
      <Button onClick={handleToggleAllCharts} btnShape={ButtonShape.NORMAL} btnType={ButtonType.HOVER_LIGHTER_INK}>
        <span className="sub-header-base text-primary">{isChartsOpen ? 'Hide graphs' : 'Show Graphs'}</span>
        {isChartsOpen ? (
          <SVG src={chevron_down} className="w-4 h-4 ml-2 transform rotate-180" />
        ) : (
          <SVG src={chevron_down} className="w-4 h-4 ml-2" />
        )}
      </Button>
    </div>
  );
};

export default CollapseButton;
