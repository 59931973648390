import { useEffect, useState, useRef } from 'react';
import Draggable from 'components/patterns/Draggable';
import { DEFAULT_DRAGGABLE_BOUNDS, DEFAULT_DRAGGABLE_POSITION } from 'consts/draggable';
import { CreativeSize, ImagePreviewProps } from './ImagePreview.types';

const DEFAULT_CREATIVE_SIZE: CreativeSize = { width: 0, height: 0 };

const ImagePreview: React.FC<ImagePreviewProps> = ({ creative, isZoomedIn }) => {
  const { downloadURL, width, height } = creative;

  const creativeRef = useRef<HTMLImageElement>(null);
  const draggableRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState<typeof DEFAULT_DRAGGABLE_POSITION>(DEFAULT_DRAGGABLE_POSITION);
  const [bounds, setBounds] = useState<typeof DEFAULT_DRAGGABLE_BOUNDS>(DEFAULT_DRAGGABLE_BOUNDS);
  const [creativeSize, setCreativeSize] = useState<CreativeSize>(DEFAULT_CREATIVE_SIZE);

  useEffect(() => {
    if (creativeRef.current) {
      setCreativeSize({ width: creativeRef.current.clientWidth, height: creativeRef.current.clientHeight });
    }
  }, []);

  useEffect(() => {
    if (!creativeRef.current || !isZoomedIn) {
      setBounds(DEFAULT_DRAGGABLE_BOUNDS);
      return;
    }

    const { clientWidth, clientHeight } = creativeRef.current;

    setBounds({
      top: -clientHeight / 2,
      left: -clientWidth / 2,
      right: clientWidth / 2,
      bottom: clientHeight / 2,
    });
  }, [isZoomedIn, creativeRef.current]);

  useEffect(() => {
    if (!isZoomedIn) setPosition(DEFAULT_DRAGGABLE_POSITION);

    if (creativeRef.current && draggableRef.current) {
      const zoom = isZoomedIn ? 2 : 1;

      creativeRef.current.style.width = `${creativeSize.width * zoom}px`;
      creativeRef.current.style.height = `${creativeSize.height * zoom}px`;
      draggableRef.current.style.width = `${creativeSize.width * zoom}px`;
      draggableRef.current.style.height = `${creativeSize.height * zoom}px`;
    }
  }, [creativeSize, isZoomedIn]);

  const isPortrait = height >= width;
  const cursorClassNames = isZoomedIn ? 'hover:cursor-grab active:cursor-grabbing' : '';

  return (
    <div className={isPortrait ? `${cursorClassNames} h-full w-full flex justify-center items-center` : ''}>
      <Draggable
        onPositionChange={(_, data) => setPosition(data)}
        className={`${cursorClassNames} h-full w-full flex justify-center items-center`}
        draggedElementPosition={position}
        containerBounds={bounds}
      >
        <div ref={draggableRef}>
          <img
            ref={creativeRef}
            src={downloadURL}
            alt="lightbox"
            className="max-h-full max-w-full transform h-auto w-auto pointer-events-none"
          />
        </div>
      </Draggable>
    </div>
  );
};

export default ImagePreview;
