import { connect } from 'react-redux';
import { bindActionCreators, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { changeConfiguration, changeFeatures } from 'store/publisher/reducer';
import App, { StateToPropsType, DispatchToPropsType } from './App';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any): StateToPropsType => ({
  environmentId: state.environment.environmentId,
  publisherConfiguration: state.publisher.configuration,
  publisherFeatures: state.publisher.publisherFeatures,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchToPropsType =>
  bindActionCreators({ changeConfiguration, changeFeatures }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
