import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { LineAllocationData } from 'components/common/types/Deal.types';
import { CancelFunctions } from 'components/common/types';
import {
  changeDisclaimer,
  clearAllocationInputs,
  clearDisclaimer,
  DisclaimerObjectives,
} from 'store/dealManagement/reducer';
import { ColorCode } from 'components/common/types/ColorCode.types';

interface UseCommonPlannerActions {
  cancelFunctions: CancelFunctions;
  percentageDifference: (userInput: number, target: number) => number;
  assignColorCodeBasedOnProximity: ((userInput: number, target: number) => ColorCode) | null;
  handleObjectives: (lineData: LineAllocationData) => void;
  onClearData: VoidFunction;
}

const useCommonPlannerActions = (cancelFunctions: CancelFunctions = {}): UseCommonPlannerActions => {
  const dispatch = useDispatch();

  const impressions = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.impressions);
  const frames = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frames);
  const budget = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.budget);

  const colorCodeToDisclaimerLevel = (code: ColorCode): keyof DisclaimerObjectives => {
    const colorCodeMap: Record<ColorCode, keyof DisclaimerObjectives> = {
      [ColorCode.DEFAULT]: 'default',
      [ColorCode.GREEN]: 'success',
      [ColorCode.AMBER]: 'warning',
      [ColorCode.RED]: 'danger',
    };

    if (colorCodeMap[code]) {
      return colorCodeMap[code];
    }

    throw new Error('Invalid color code');
  };

  const percentageDifference = (userInput: number, target: number): number => {
    if (target === 0) return 0;
    return Math.abs((userInput - target) / target) * 100;
  };

  const assignColorCodeBasedOnProximity = (value: number, target: number): ColorCode => {
    if (value === 0) {
      return ColorCode.DEFAULT;
    }

    if (target === 0) {
      return ColorCode.RED;
    }

    const difference = percentageDifference(value, target);

    if (difference <= 5) {
      return ColorCode.GREEN;
    }

    if (difference > 5 && difference <= 10) {
      return ColorCode.AMBER;
    }

    return ColorCode.RED;
  };

  const handleObjectives = useCallback(
    (lineData: LineAllocationData): void => {
      const { totalFrames, totalImpressions, totalCost } = lineData;

      const colorCodeLabels = [
        { colorCode: assignColorCodeBasedOnProximity(frames || 0, totalFrames), label: 'Frames' },
        { colorCode: assignColorCodeBasedOnProximity(impressions || 0, totalImpressions), label: 'Impressions' },
        { colorCode: assignColorCodeBasedOnProximity(budget || 0, totalCost), label: 'Budget' },
        { colorCode: ColorCode.DEFAULT, label: 'SoT' },
      ];

      const updatedObjectives = colorCodeLabels.reduce<{ [K in keyof DisclaimerObjectives]: string[] }>(
        (acc, item) => {
          const level = colorCodeToDisclaimerLevel(item.colorCode);
          return {
            ...acc,
            [level]: [...(acc[level] || []), item.label],
          };
        },
        { success: [], warning: [], danger: [], default: [] },
      );

      dispatch(changeDisclaimer(updatedObjectives));
    },
    [frames, impressions],
  );

  const onClearData = (): void => {
    dispatch(clearAllocationInputs());
    dispatch(clearDisclaimer());
  };

  return {
    cancelFunctions,
    percentageDifference,
    assignColorCodeBasedOnProximity,
    handleObjectives,
    onClearData,
  };
};

export default useCommonPlannerActions;
