import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { BaseInput } from 'lib/BaseInput';
import validate from 'utils/validationRules';
import styles from './InputWithValidation.pcss';

const cx = classNames.bind(styles);

const InputWithValidation = ({ inputConfig, onChange, onBlur, dataTestId }) => {
  const changeHandler = (event) => {
    const rules = {};
    Object.entries(inputConfig.validations).forEach(([key, item]) => {
      rules[key] = item.value;
    });

    const fieldValidity = validate(event.target.value, rules);

    const updatedValidations = {};
    Object.entries(inputConfig.validations).forEach(([key, item]) => {
      updatedValidations[key] = {
        ...item,
        error: fieldValidity.errors[key],
      };
    });

    const changedConfig = {
      ...inputConfig,
      elementConfig: {
        ...inputConfig.elementConfig,
      },
      value: event.target.value,
      valid: fieldValidity.isValid,
      validations: updatedValidations,
      touched: true,
    };

    onChange(event, changedConfig, fieldValidity.errors);
  };

  const getInputElement = () => {
    return (
      <BaseInput
        {...inputConfig.elementConfig} // eslint-disable-line react/jsx-props-no-spreading
        data-test-id={dataTestId}
        value={inputConfig.value}
        onChange={changeHandler}
        onBlur={onBlur}
      />
    );
  };

  const getMessages = () => {
    if (inputConfig.touched && !inputConfig.valid) {
      const messages = [];
      Object.entries(inputConfig.validations).forEach(([key, validation]) => {
        if (validation.error) {
          messages.push(
            <span key={key} className={cx('text-danger', 'body-sm')}>
              {validation.message}
            </span>,
          );
        }
      });
      return messages;
    }
    return [];
  };

  return (
    <div className={cx('form-group')}>
      {inputConfig.label && inputConfig.label.length ? (
        <span className={cx('sub-header-base', 'form-label')}>{inputConfig.label}</span>
      ) : null}
      {getInputElement()}
      {getMessages()}
    </div>
  );
};

InputWithValidation.propTypes = {
  dataTestId: PropTypes.string,
  inputConfig: PropTypes.shape({
    elementConfig: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    validations: PropTypes.objectOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        error: PropTypes.bool,
        message: PropTypes.string,
      }),
    ),
    valid: PropTypes.bool,
    touched: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

InputWithValidation.defaultProps = {
  dataTestId: '',
  inputConfig: {
    elementConfig: {
      type: 'text',
    },
    value: '',
    label: '',
    validations: {},
    valid: false,
    touched: false,
  },
  onChange: () => undefined,
  onBlur: () => undefined,
};

export default InputWithValidation;
