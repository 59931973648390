import cx from 'classnames';
import { ChipColors, SectionButtonChipProps } from './SectionButtonChip.types';

const SectionButtonChip: React.FC<SectionButtonChipProps> = ({
  dataTestId,
  label,
  value,
  labelStyle = ChipColors.LIGHT_PURPLE,
  valueStyle = ChipColors.DARK_PURPLE,
}) => {
  return (
    <div data-test-id={dataTestId} className={cx('flex rounded-full max-w-[200px]', labelStyle)}>
      <span
        className={cx('text-sm px-2 py-0.5 whitespace-nowrap', {
          'truncate ': !value,
        })}
      >
        {label}
      </span>
      {value ? <span className={cx('rounded-full px-2 py-0.5 text-sm truncate', valueStyle)}>{value}</span> : null}
    </div>
  );
};

export default SectionButtonChip;
