import { useSelector } from 'react-redux';

import { DOUBLE_DASH } from 'consts/placeholders';
import { formatDate, formatNumber } from 'modules/I18N';
import { NetworkModel } from 'components/common/prop-types/inventory';
import { TimelineItem } from 'components/patterns/Timeline';

const NetworkInfo = ({ network }) => {
  const { localeCode } = useSelector((state) => state.publisher.configuration);
  return (
    <TimelineItem
      title={
        <div className="grid grid-cols-3 gap-x-4">
          <p className="body-sm">Network code</p>
          <p className="body-sm">Network name</p>
        </div>
      }
    >
      <div className="grid grid-cols-3 gap-x-4 gap-y-2">
        <p className="body-base">
          {network.networkUuid}
          <br />
          <span className="body-sm text-neutral-950-opacity-60">{`${formatDate(
            network.startDate,
            localeCode,
          )} - ${formatDate(network.endDate, localeCode)}`}</span>
        </p>
        <p className="body-base col-span-2">{network.networkName ?? DOUBLE_DASH}</p>
        <div>
          <p className="body-sm">Price Factor</p>
          <p className="body-base mt-2 mb-0.5">{formatNumber({ value: network.priceFactor, localeCode })}</p>
        </div>
        <div className="col-span-2">
          <p className="body-sm">Network group</p>
          <p className="body-base mt-2">{network.networkGroupName ?? DOUBLE_DASH}</p>
        </div>
      </div>
    </TimelineItem>
  );
};

NetworkInfo.propTypes = {
  network: NetworkModel,
};

NetworkInfo.defaultProps = {
  network: {},
};

export default NetworkInfo;
