import queryString from 'query-string';

import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS } from 'modules/api/constants';

export const fetchNetworkGroupsTable = async (cancelFunctions, data, queryObj) => {
  const query = queryString.stringify(queryObj, { skipEmptyString: true, arrayFormat: 'comma' });
  const { send, cancel } = createRequest({
    url: URLS().FETCH_NETWORK_GROUPS_TABLE(query),
    method: HTTP_METHODS.POST,
    data,
    showLoader: true,
  });

  if (cancelFunctions.FETCH_NETWORK_GROUPS_TABLE) cancelFunctions.FETCH_NETWORK_GROUPS_TABLE();
  cancelFunctions.FETCH_NETWORK_GROUPS_TABLE = cancel;

  const result = await send();

  // eslint-disable-next-line sonarjs/no-duplicate-string
  const totalElements = parseInt(result.headers['total-elements']);

  return {
    data: result.data,
    totalElements: Number.isNaN(totalElements) ? result.data.length : totalElements,
  };
};

export const getNetworkGroups = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_NETWORK_GROUPS(),
    method: HTTP_METHODS.POST,
    data: { lookupName: query },
  });

  if (cancelFunctions.FETCH_NETWORK_GROUPS) cancelFunctions.FETCH_NETWORK_GROUPS();
  cancelFunctions.FETCH_NETWORK_GROUPS = cancel;

  const result = await send();

  return result.data;
};

export const fetchNetworksTable = async (cancelFunctions, data, queryObj) => {
  const query = queryString.stringify(queryObj, { skipEmptyString: true, arrayFormat: 'comma' });
  const { send, cancel } = createRequest({
    url: URLS().FETCH_NETWORKS_TABLE(query),
    method: HTTP_METHODS.POST,
    data,
    showLoader: true,
  });

  if (cancelFunctions.FETCH_NETWORKS_TABLE) cancelFunctions.FETCH_NETWORKS_TABLE();
  cancelFunctions.FETCH_NETWORKS_TABLE = cancel;

  const result = await send();
  const totalElements = parseInt(result.headers['total-elements']);

  return {
    data: result.data,
    totalElements: Number.isNaN(totalElements) ? result.data.length : totalElements,
  };
};

export const fetchNetworkPriceDefinitionsTable = async (cancelFunctions, data, queryObj) => {
  const query = queryString.stringify(queryObj, { skipEmptyString: true, arrayFormat: 'comma' });
  const { send, cancel } = createRequest({
    url: URLS().FETCH_NETWORK_PRICE_DEFINITIONS_TABLE(query),
    method: HTTP_METHODS.POST,
    data,
    showLoader: true,
  });

  if (cancelFunctions.FETCH_NETWORK_PRICE_DEFINITIONS_TABLE) cancelFunctions.FETCH_NETWORK_PRICE_DEFINITIONS_TABLE();
  cancelFunctions.FETCH_NETWORK_PRICE_DEFINITIONS_TABLE = cancel;

  const result = await send();
  const totalElements = parseInt(result.headers['total-elements']);

  return {
    data: result.data,
    totalElements: Number.isNaN(totalElements) ? result.data.length : totalElements,
  };
};

export const getNetworkPriceDefinition = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_NETWORK_PRICE_DEFINITION(),
    method: HTTP_METHODS.POST,
    data: { lookupName: query },
  });

  if (cancelFunctions.FETCH_NETWORK_PRICE_DEFINITION) cancelFunctions.FETCH_NETWORK_PRICE_DEFINITION();
  cancelFunctions.FETCH_NETWORK_PRICE_DEFINITION = cancel;

  const result = await send();

  return result.data;
};
