import { CreativeSetFilters } from 'components/common/types/CreativeSetDetails.types';
import { FrameOrdering } from 'components/common/types/FrameOrdering.types';
import { getStringFromObjectsList } from 'utils/formatters';
import { CustomSortComparator, FiltersToRequestData } from './VisualUnitTable.types';

export const mapFiltersToRequestData = ({
  dealId,
  environment,
  frameCode,
  location,
  visualUnitCode,
  framesOrder,
}: CreativeSetFilters): FiltersToRequestData => {
  return {
    ...(dealId ? { dealId: dealId.code } : {}),
    ...(environment ? { channel: environment } : {}),
    ...(frameCode ? { frameCode } : {}),
    ...(location ? { city: location } : {}),
    ...(visualUnitCode ? { visualUnitCode } : {}),
    ...(framesOrder === FrameOrdering.ORDERED ? { ordered: true } : { ordered: false }),
  };
};

export const customSortComparator: CustomSortComparator = (nodeA, nodeB, variant) => {
  if (!!nodeA.parent?.key || !!nodeB.parent?.key) return 0;

  const valueA =
    variant === 'channels' ? getStringFromObjectsList(nodeA.data.panels, 'channels') : nodeA.data.panels[0].width;
  const valueB =
    variant === 'channels' ? getStringFromObjectsList(nodeB.data.panels, 'channels') : nodeB.data.panels[0].width;

  if (valueA === valueB) return 0;
  return valueA > valueB ? 1 : -1;
};
