import {
  CellClassParams,
  CellClickedEvent,
  CheckboxSelectionCallbackParams,
  ColDef,
  GridOptions,
  GridSizeChangedEvent,
  ICellRendererParams,
  RowClassParams,
  RowSelectedEvent,
} from 'ag-grid-community';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TableHeader } from 'components/common/TableHeader';
import { HeaderBackgroundColor, TextColor } from 'components/common/TableHeader/TableHeader.types';
import { CreativeSetPanel } from 'components/common/types/CreativeSetDetails.types';
import { transformVisualUnitData } from 'components/pages/ContentManagement/transformRequestData';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { map } from 'lodash';
import { fetchVisualUnitGroups } from 'modules/api/creativeSets';
import { notifyError } from 'store/notification/reducer';
import { getStringFromObjectsList } from 'utils/formatters';
import { VisualUnitGroup } from '../VisualUnitGroups.types';
import GroupCellRenderer from './GroupCellRenderer';
import MotionTypesRenderer from './MotionTypesRenderer';
import PanelsRenderer from './PanelsRenderer';
import UnitCellRenderer from './UnitCellRenderer';
import { PagesStoreProps, UseVisualUnitTable } from './VisualUnitTable.types';
import { customSortComparator, mapFiltersToRequestData } from './utils';

type SelectedData = VisualUnitGroup & { isNoChildren?: boolean };

const initialUnitRefData: VisualUnitGroup = {
  checksum: '',
  panels: [],
  visualUnitIds: [],
};

export const useVisualUnitTable: UseVisualUnitTable = ({ getSelectedRow }) => {
  const cancelFunctions = useCancelRequest();
  const selectedDataRef = useRef<VisualUnitGroup>(initialUnitRefData);
  const [tableData, setTableData] = useState([]);
  const filters = useSelector((state: PagesStoreProps) => state.pages.contentManagement.creativeSetDetails.filters);
  const dispatch = useDispatch();

  const onSelectionChanged = (event: RowSelectedEvent): void => {
    const data: SelectedData[] | [] = event.api.getSelectedRows();
    const selectedData = data.filter((row) => (row.isNoChildren ? row : row.checksum.length > 1));

    if (!selectedData.length) {
      getSelectedRow(null);
      return;
    }

    selectedDataRef.current = initialUnitRefData;

    selectedData.forEach((unit) => {
      selectedDataRef.current = {
        checksum: unit.checksum[0],
        panels: unit.panels,
        visualUnitIds: selectedDataRef.current
          ? [...selectedDataRef.current?.visualUnitIds, ...unit.visualUnitIds]
          : unit.visualUnitIds,
      };
    });

    getSelectedRow(selectedDataRef.current);
  };

  const onRowSelected = (event: RowSelectedEvent): void => {
    const selectedRows = event.api.getSelectedRows();

    if (!event.node.isSelected() || !selectedRows.length) return;

    const filteredRows = selectedRows.filter((row) => (row.isNoChildren ? row : row.checksum.length > 1));

    const lastIdx = filteredRows.length - 1;
    const firstNodeChecksum = filteredRows[0].checksum[0];
    const lastNodeChecksum = filteredRows[lastIdx].checksum[0];

    if (firstNodeChecksum !== event.data.checksum[0] || lastNodeChecksum !== event.data.checksum[0]) {
      event.api.deselectAll();
      event.node.setSelected(true);
    }
  };

  useEffect(() => {
    const fetchTableData = async (): Promise<void> => {
      try {
        const data = await fetchVisualUnitGroups(cancelFunctions, mapFiltersToRequestData(filters));
        setTableData(transformVisualUnitData(data));
      } catch {
        dispatch(notifyError({ message: 'Error occurred' })); // TODO: need to be defined
      }
    };
    fetchTableData();
  }, [filters]);

  const gridOptions: GridOptions = {
    treeData: true,
    groupDisplayType: 'custom',
    enableCellTextSelection: true,
    rowSelection: 'multiple',
    onSelectionChanged,
    suppressRowClickSelection: true,
    suppressRowDeselection: true,
    groupSelectsChildren: true,
    onRowSelected,
    getDataPath: (data) => data.checksum,
    onCellClicked: (params: CellClickedEvent) => {
      if (params.colDef?.colId === 'visualUnitCode' && !params.data.isNoChildren) return;
      params.node.setSelected(!params.node.isSelected());
    },
    onGridSizeChanged: (params: GridSizeChangedEvent) => params.api.sizeColumnsToFit(),
    headerHeight: 36,
    suppressMovableColumns: true,
    defaultColDef: {
      resizable: false,
      wrapHeaderText: true,
      suppressMenu: true,
      cellStyle: { paddingLeft: 14, paddingRight: 12 },
      flex: 1,
    },
    suppressPaginationPanel: true,
    getRowClass: (params: RowClassParams) => {
      if (params.data.isParent) return '';
      return params.data.isLastRow ? ['expandedRow', 'expandedRowLast'] : 'expandedRow';
    },
  };

  const columnDefs: ColDef[] = [
    {
      field: 'checksum',
      hide: true,
      rowGroup: true,
      colId: 'checksum',
    },
    {
      field: 'visualUnitCode',
      maxWidth: 180,
      colId: 'visualUnitCode',
      checkboxSelection: (params: CheckboxSelectionCallbackParams) => !params.data.isParent,
      cellClass: (params: CellClassParams) =>
        params.data.isFirstRow ? ['checkboxFrame', 'firstRow'] : 'checkboxFrame',
      showRowGroup: 'checksum',
      cellRenderer: (params: ICellRendererParams) =>
        params.data.isParent ? GroupCellRenderer(params) : UnitCellRenderer(params),
      headerComponent: TableHeader,
      headerComponentParams: {
        label: 'No. Visual Units',
        textColor: TextColor.SECONDARY,
        backgroundColor: HeaderBackgroundColor.TRANSPARENT,
      },
    },
    {
      field: 'panels',
      maxWidth: 150,
      cellRenderer: (params: ICellRendererParams) =>
        params.data?.panels ? PanelsRenderer({ panels: params.data.panels }) : '',
      colId: 'panels',
      headerComponent: TableHeader,
      headerComponentParams: {
        label: 'Panels',
        textColor: TextColor.SECONDARY,
        backgroundColor: HeaderBackgroundColor.TRANSPARENT,
      },
    },
    {
      field: 'sizes',
      maxWidth: 250,
      type: 'rightAligned',
      cellRenderer: (params: ICellRendererParams) =>
        params.data?.panels
          ? params.data.panels.map((item: CreativeSetPanel) => `${item.width}x${item.height}`).join(', ')
          : '',
      headerComponent: TableHeader,
      headerComponentParams: {
        label: 'Size (HxW)',
        textColor: TextColor.SECONDARY,
        backgroundColor: HeaderBackgroundColor.TRANSPARENT,
        sortable: true,
      },
      sortable: true,
      comparator: (_A, _B, nodeA, nodeB) => customSortComparator(nodeA, nodeB, 'sizes'),
    },
    {
      field: 'channels',
      minWidth: 150,
      cellRenderer: (params: ICellRendererParams) =>
        params.data?.panels && params.data?.isParent ? getStringFromObjectsList(params.data.panels, 'channels') : '',
      colId: 'channels',
      headerComponent: TableHeader,
      headerComponentParams: {
        label: 'Environment',
        textColor: TextColor.SECONDARY,
        backgroundColor: HeaderBackgroundColor.TRANSPARENT,
        sortable: true,
      },
      sortable: true,
      comparator: (_A, _B, nodeA, nodeB) => customSortComparator(nodeA, nodeB, 'channels'),
    },
    {
      field: 'motionType',
      maxWidth: 150,
      cellRenderer: (params: ICellRendererParams) =>
        params.data?.panels && params.data?.isParent
          ? MotionTypesRenderer({ motionTypes: map(params.data.panels, 'motionType') })
          : '',
      colId: 'motionType',
      headerComponent: TableHeader,
      headerComponentParams: {
        label: 'Motion type',
        textColor: TextColor.SECONDARY,
        backgroundColor: HeaderBackgroundColor.TRANSPARENT,
      },
    },
    {
      field: 'cities',
      minWidth: 150,
      cellRenderer: (params: ICellRendererParams) =>
        params.data?.panels && params.data?.isParent ? getStringFromObjectsList(params.data.panels, 'cities') : '',
      colId: 'cities',
      headerComponent: TableHeader,
      headerComponentParams: {
        label: 'City (location)',
        textColor: TextColor.SECONDARY,
        backgroundColor: HeaderBackgroundColor.TRANSPARENT,
      },
    },
  ];

  return {
    columnDefs,
    tableData,
    gridOptions,
  };
};
