import { useRef, useEffect } from 'react';
import { IGetRowsParams, GridApi, IDatasource } from 'ag-grid-community';

import { usePageFilters } from 'customHooks/usePageFilters';
import withCancelRequest from 'components/hocs/withCancelRequest';
import { fetchNetworkPriceDefinitionsTable } from 'modules/api/referenceData';
import StatusRenderer from 'components/common/StatusRenderer';
import { IN_USE_STATUS_BADGE } from 'consts/inUseStatus';
import { PAGE_SIZE_LARGE } from 'consts/pagination';
import Table, { TableHeight, TableRowHeight } from 'components/patterns/Table';
import { CancelFunctions } from 'components/common/types';

import FilterArea from './FilterArea';
import type { Filters } from './FilterArea';

interface ContentProps {
  cancelFunctions: CancelFunctions;
}

const columnDefs = [
  {
    headerName: '',
    minWidth: 40,
    maxWidth: 40,
    headerTooltip: '',
    resizable: false,
  },
  {
    headerName: 'In use',
    field: 'inUse',
    minWidth: 90,
    maxWidth: 90,
    headerTooltip: 'In use',
    cellRenderer: StatusRenderer,
    cellRendererParams: { statuses: IN_USE_STATUS_BADGE },
    colId: 'status',
  },
  {
    headerName: 'Price definition code',
    field: 'networkPriceDefinitionCode',
    minWidth: 150,
    headerTooltip: 'Price definition code',
  },
  {
    headerName: 'Price definition name',
    field: 'networkPriceDefinitionName',
    minWidth: 150,
    headerTooltip: 'Price definition name',
    resizable: false,
  },
];

const Content: React.FC<ContentProps> = ({ cancelFunctions }) => {
  const tableApiRef = useRef<GridApi>();
  const { filters, changeFilters } = usePageFilters<Filters>();

  const getDataSource = (): IDatasource => ({
    getRows: async (params: IGetRowsParams) => {
      const { data, totalElements } = await fetchNetworkPriceDefinitionsTable(
        cancelFunctions,
        {
          networkPriceDefinitionCode: filters.networkPriceDefinitionCode,
          networkPriceDefinitionName: filters.networkPriceDefinition?.name,
          inUse: filters.inUse,
        },
        {
          pageNumber: params.startRow % PAGE_SIZE_LARGE,
          pageSize: PAGE_SIZE_LARGE,
        },
      );

      if (totalElements === 0) {
        tableApiRef.current?.showNoRowsOverlay();
        params.successCallback(data, totalElements);
        return;
      }

      tableApiRef.current?.hideOverlay();
      params.successCallback(data, totalElements);
    },
  });

  const getTableApi = (tableApi: GridApi): void => {
    tableApiRef.current = tableApi;
    tableApiRef.current.setDatasource(getDataSource());
  };

  useEffect(() => {
    tableApiRef.current?.setDatasource(getDataSource());
  }, [filters]);

  return (
    <>
      <FilterArea onFilterChange={changeFilters} filters={filters} />
      <Table
        dataTestId="network-price-definitions-table"
        columnDefs={columnDefs}
        getTableApi={getTableApi}
        extraGridOptions={{
          pagination: true,
          paginationPageSize: PAGE_SIZE_LARGE,
          cacheBlockSize: PAGE_SIZE_LARGE,
          enableCellTextSelection: true,
          domLayout: 'autoHeight',
          rowModelType: 'infinite',
          sortingOrder: ['desc', 'asc'],
          rowHeight: TableRowHeight.NORMAL,
        }}
        tableSize={{ tableHeight: TableHeight.AUTO }}
      />
    </>
  );
};

export default withCancelRequest(Content);
