import { useSelector } from 'react-redux';
import { RootState } from 'store';
import capitalize from 'lodash/capitalize';

import { DOUBLE_DASH } from 'consts/placeholders';
import { formatDate } from 'modules/I18N';
import { TimelineItem } from 'components/patterns/Timeline';
import { secondsToHHmm } from 'utils/dateFormatUtil';
import { TimeModelInfoProps } from './TimeModels.types';

const TimeModelInfo: React.FC<TimeModelInfoProps> = ({
  model: { startDate, endDate, days = [], startOffset, endOffset },
}) => {
  const { localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const startLabel = secondsToHHmm(startOffset);
  const endLabel = secondsToHHmm(endOffset);

  return (
    <TimelineItem
      title={
        <div className="grid grid-cols-3 gap-x-4">
          <p className="body-sm">Time</p>
          <p className="body-sm">Days</p>
        </div>
      }
    >
      <div className="grid grid-cols-3 gap-x-4 gap-y-2">
        <p className="body-base" data-test-id="time-model-start-end-label">{`${startLabel ?? DOUBLE_DASH}-${
          endLabel ?? DOUBLE_DASH
        }`}</p>
        <p className="body-base col-span-2" data-test-id="time-model-days">
          {days.map((day) => capitalize(day)).join(', ')}
        </p>
        <div>
          <p className="body-sm">Duration</p>
          <p className="body-base mt-2" data-test-id="time-model-start-end-date">{`${formatDate(
            startDate,
            localeCode,
          )} - ${formatDate(endDate, localeCode)}`}</p>
        </div>
      </div>
    </TimelineItem>
  );
};

export default TimeModelInfo;
