import { useMemo } from 'react';
import { LossType } from 'consts/lossType';
import BaseSelect from 'lib/BaseSelect';
import { findOption } from 'lib/BaseSelect/utils';
import { FilterLossTypeProps, LossTypeOption } from './FilterLossType.types';

const lossTypeSelectOptions: LossTypeOption[] = [
  { name: 'Bid', code: LossType.BID },
  { name: 'Creative', code: LossType.CREATIVE },
  { name: 'Other', code: LossType.OTHER },
];

const FilterLossType: React.FC<FilterLossTypeProps> = ({ changeFilters, lossType }) => {
  const selectedLossType = useMemo(
    () => findOption(lossTypeSelectOptions, 'code', lossType),
    [lossType, lossTypeSelectOptions],
  );

  return (
    <BaseSelect
      dataTestId="lossType-select"
      name="lossType"
      options={lossTypeSelectOptions}
      selectedValue={selectedLossType}
      onSelect={(_, value: LossTypeOption) => {
        changeFilters({ lossType: value?.code, lossCode: '' });
      }}
      placeholder="Select loss type"
      isClearable
    />
  );
};

export default FilterLossType;
