import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import {
  createDeal as createDealRequest,
  updateDeal as updateDealRequest,
  createLine as createLineRequest,
  updateLine as updateLineRequest,
  updateLiveLine as updateLiveLineRequest,
  deleteLine as deleteLineRequest,
  terminateLine as terminateLineRequest,
  checkDealAvailability,
  checkLineAvailability,
} from 'modules/api/deal';
import { isReadOnly } from 'utils/isReadOnly';
import { notifySuccess, notifyInfo, notifyError, clearNotification } from 'store/notification/reducer';
import withCancelRequest from 'components/hocs/withCancelRequest';
import {
  getDealWithLineFormData,
  getDealFormData,
  getLineFormData,
  getLiveLineFormData,
  getLineIdFormData,
} from 'components/pages/DealWithLines/DealLines/transformPostData';
import { transformBookingStatusCode } from 'components/pages/DealWithLines/common/transformPostData';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { getDealDetails } from 'store/dealManagement/actions';
import {
  changeCurrentLine,
  changeFormParams,
  changeDealLineAvailableImpressions,
  resetDealLineAvailability,
} from 'store/dealManagement/reducer';
import { getIsValidAvailabilityRequest } from 'store/dealManagement/selectors';
import DealActions from 'components/pages/DealWithLines/common/Main/ControllerBar/DealActions';
import LineActions from 'components/pages/DealWithLines/common/Main/ControllerBar/LineActions';
import { getIsAdServer } from 'store/publisher/selectors';

// eslint-disable-next-line sonarjs/cognitive-complexity
const ControllerBar = ({ dealRef, cancelFunctions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isNewDeal = useSelector((state) => state.dealManagement.isNewDeal);
  const config = useSelector((state) => state.dealManagement.config);
  const lines = useSelector((state) => state.dealManagement.backupFormData.lines);
  const programmatic = useSelector((state) => state.dealManagement.programmatic);
  const commonDeal = useSelector((state) => state.dealManagement.commonDeal);
  const { bookingStatusCode, currentLine, internalId } = commonDeal;

  const hasAdsDealLevelCPMEnabled = useHasFeatureAccess(FeatureFlags.ADS_DEAL_LEVEL_CPM);
  const hasSweepTargetingEnabled = useHasFeatureAccess(FeatureFlags.SWEEP_TARGETING);
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);
  const isAdServerMarket = useSelector(getIsAdServer);
  const isValidAvailabilityRequest = useSelector(getIsValidAvailabilityRequest);

  const [shouldSwitchCurrentLine, setShouldSwitchCurrentLine] = useState(false);
  const readOnly = isReadOnly(bookingStatusCode, false);

  useEffect(() => {
    if (shouldSwitchCurrentLine) {
      dispatch(changeCurrentLine(lines.at(-1).id));

      setShouldSwitchCurrentLine(false);
    }
  }, [shouldSwitchCurrentLine, lines]);

  const handleDealRequest = async (formData, requestHandler, catchHandler = () => undefined) => {
    dispatch(changeFormParams({ isEditingDisabled: true }));
    dispatch(notifyInfo({ message: 'We are validating your solution', timeout: NOTIFICATION_TIMEOUT.NEVER }));

    try {
      const result = await requestHandler(formData, cancelFunctions);

      const dealSavedMsg = `Deal saved Successfully - ${result.dealId ? result.dealId : result.reference}`;
      const dealLineSavedMsg = result.reference ? dealSavedMsg : `Success! Line ${currentLine.name} was saved`;

      dispatch(clearNotification());
      dispatch(
        result.plannerResponseMessage
          ? notifyError({ message: result.plannerResponseMessage, timeout: NOTIFICATION_TIMEOUT.LONG })
          : notifySuccess({ message: dealLineSavedMsg }),
      );

      if (internalId) {
        await dispatch(
          getDealDetails(
            internalId,
            cancelFunctions,
            navigate,
            config,
            hasAdsDealLevelCPMEnabled,
            hasSweepTargetingEnabled,
            hasNonGuaranteedExtendedTargetEnabled,
            isAdServerMarket,
          ),
        );
      } else if (result.internalId) {
        navigate(`/deal/${result.internalId}`, { replace: true });
      } else {
        navigate('/deals', { replace: true });
      }
    } catch {
      catchHandler();
    }

    dispatch(changeFormParams({ isEditingDisabled: false }));
  };

  const submitNewDeal = async (bookingCode = '') => {
    const formData = getDealWithLineFormData({
      ...commonDeal,
      ...programmatic,
      bookingStatusCode: bookingCode,
      hasAdsDealLevelCPMEnabled,
    });
    await handleDealRequest(formData, createDealRequest);
  };

  const updateDeal = async (bookingCode, catchHandler = () => undefined) => {
    const formData = getDealFormData({
      ...commonDeal,
      ...programmatic,
      ...(bookingCode ? { bookingStatusCode: bookingCode } : {}),
    });
    await handleDealRequest(formData, updateDealRequest, catchHandler);
  };

  const createLine = async () => {
    const formData = getLineFormData(commonDeal);
    await handleDealRequest(formData, createLineRequest);
  };

  const updateLine = async () => {
    const formData = getLineFormData(commonDeal);
    await handleDealRequest(formData, updateLineRequest);
  };

  const updateLiveLine = async () => {
    const formData = getLiveLineFormData(commonDeal);
    await handleDealRequest(formData, updateLiveLineRequest);
    setShouldSwitchCurrentLine(true);
  };

  const terminateLine = async () => {
    const formData = getLineIdFormData(currentLine.id);
    await handleDealRequest(formData, terminateLineRequest);
  };

  const deleteLine = async () => {
    const formData = getLineIdFormData(currentLine.id);
    await handleDealRequest(formData, deleteLineRequest);
  };

  const saveDeal = (bookingCode, catchHandler = () => undefined) => {
    const statusCode = transformBookingStatusCode(bookingCode || bookingStatusCode);

    if (isNewDeal) {
      submitNewDeal(statusCode);
      return;
    }
    updateDeal(statusCode, catchHandler);
  };

  const getAvailability = async (formData, requestHandler) => {
    if (isValidAvailabilityRequest) {
      dispatch(clearNotification());
      dispatch(changeFormParams({ isFetchingAvailability: true }));
      try {
        const data = await requestHandler(formData, cancelFunctions);
        if (!data.assets.length) {
          const message =
            'There are no frames available for the targeting criteria you have chosen. Please revise your targeting selection.';
          dispatch(notifyError({ message, timeout: NOTIFICATION_TIMEOUT.LONG }));
          throw new Error(message);
        }

        dispatch(changeDealLineAvailableImpressions(data));
      } catch {
        dispatch(resetDealLineAvailability());
      }
      dispatch(changeFormParams({ isFetchingAvailability: false }));
      return;
    }

    dispatch(resetDealLineAvailability());
  };

  const checkAllocateHandler = () => {
    if (isNewDeal) {
      getAvailability(
        getDealWithLineFormData({
          ...commonDeal,
          ...programmatic,
          hasAdsDealLevelCPMEnabled,
        }),
        checkDealAvailability,
      );
      return;
    }

    getAvailability(getLineFormData(commonDeal), checkLineAvailability);
  };

  if (readOnly) return null;

  return (
    <div
      className="flex bg-neutral-50 py-2.5 rounded-t-xl border border-neutral-950-opacity-10 divide-x divide-neutral-950-opacity-10 sticky bottom-0 w-max m-auto shadow-lg"
      data-test-id="deal-controller-bar"
    >
      <LineActions
        createLine={createLine}
        updateLine={updateLine}
        updateLiveLine={updateLiveLine}
        deleteLine={deleteLine}
        terminateLine={terminateLine}
      />
      <DealActions checkAllocate={checkAllocateHandler} saveDeal={saveDeal} dealRef={dealRef} />
    </div>
  );
};

ControllerBar.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  dealRef: PropTypes.shape({ current: PropTypes.object }),
};

ControllerBar.defaultProps = {
  dealRef: null,
};

export default withCancelRequest(ControllerBar);
