import { CreativeStatus } from 'components/common/types/Creative.types';

interface CreativeStatusBadge {
  code: CreativeStatus;
  name: string;
  initials: string;
  color: string;
}

export const CREATIVE_STATUS_BADGE: CreativeStatusBadge[] = [
  {
    code: CreativeStatus.APPROVED,
    name: 'Approved',
    initials: 'A',
    color: 'bg-green',
  },
  {
    code: CreativeStatus.REJECTED,
    name: 'Rejected',
    initials: 'R',
    color: 'bg-pinkRed',
  },
  {
    code: CreativeStatus.PENDING,
    name: 'Pending approval',
    initials: 'PA',
    color: 'bg-yellow',
  },
  {
    code: CreativeStatus.FLAGGED,
    name: 'Flagged',
    initials: 'F',
    color: 'bg-yellow',
  },
  {
    code: CreativeStatus.UPLOADED,
    name: 'Uploaded',
    initials: 'U',
    color: 'bg-primary',
  },
  {
    code: CreativeStatus.DELETED,
    name: 'Deleted',
    initials: 'D',
    color: 'bg-pinkRed',
  },
  {
    code: CreativeStatus.RESTORED,
    name: 'Restored',
    initials: 'R',
    color: 'bg-green',
  },
];
