import { Component } from 'react';
import PropTypes from 'prop-types';
import withCancelRequest from 'components/hocs/withCancelRequest';
import AsyncSelect from 'components/patterns/AsyncSelect';
import Chips from 'components/patterns/Chips';
import { getProductFormats, addProductFormat, deleteProductFormat } from 'modules/api/content';
import Session from 'modules/Session';
import differenceBy from 'lodash/differenceBy';

class ProductFormats extends Component {
  onLoadLookupData = async (dropdownName, query) => {
    const { cancelFunctions, productFormat } = this.props;
    try {
      const productFormatOptions = await getProductFormats(cancelFunctions, Session.getEnvironmentId(), query);
      return differenceBy(productFormatOptions, productFormat, 'id');
    } catch (error) {
      return [];
    }
  };

  onProductFormatChange = async (newValue) => {
    const { cancelFunctions, marketId, addProductFormats, notifyError, onTaggingRulesChange } = this.props;
    try {
      await addProductFormat(cancelFunctions, marketId, newValue[0].id);
      addProductFormats(newValue);
      onTaggingRulesChange();
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  removeProductFormat = async (item) => {
    const { cancelFunctions, marketId, deleteProductFormats, notifyError, onTaggingRulesChange } = this.props;
    try {
      await deleteProductFormat(cancelFunctions, marketId, item.id);
      deleteProductFormats(item.id);
      onTaggingRulesChange();
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  getSelectedChip = (dataList, idKey, labelKey, removeProductFormat, isDisabled) => (
    <Chips
      dataList={dataList}
      idKey={idKey}
      labelKey={labelKey}
      isClearAllVisible={false}
      onRemove={removeProductFormat}
      isDisabled={isDisabled}
    />
  );

  render() {
    const { productFormat, isDisabled } = this.props;
    return (
      <div>
        <div className={`${productFormat ? 'mb-3' : ''}`} data-test-id="product-format">
          <AsyncSelect
            dataTestId="product-format-input"
            name="productFormat"
            loadData={this.onLoadLookupData}
            selectedValue={null}
            placeholder="Select a product format"
            onSelect={(dropdownName, newValue) => this.onProductFormatChange(newValue, dropdownName)}
            multiple
            isClearable={false}
            isMultiValueVisible={false}
            isDisabled={isDisabled}
          />
        </div>
        <div>
          {productFormat.length > 0
            ? this.getSelectedChip(productFormat, 'code', 'name', this.removeProductFormat, isDisabled)
            : null}
        </div>
      </div>
    );
  }
}

ProductFormats.propTypes = {
  productFormat: PropTypes.arrayOf(PropTypes.shape({})),
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  notifyError: PropTypes.func.isRequired,
  addProductFormats: PropTypes.func.isRequired,
  onTaggingRulesChange: PropTypes.func.isRequired,
  deleteProductFormats: PropTypes.func.isRequired,
  marketId: PropTypes.string,
  isDisabled: PropTypes.bool,
};

ProductFormats.defaultProps = {
  productFormat: [],
  isDisabled: false,
  marketId: '',
};

export default withCancelRequest(ProductFormats);
