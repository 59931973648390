import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import cx from 'classnames';

import Tooltip, { TooltipDirection } from 'components/patterns/Tooltip';

const IconButton = ({ dataTestId, icon, tooltipText, disabled, onClick }) => {
  return (
    <Tooltip tooltip={<p className="w-max">{tooltipText}</p>} direction={TooltipDirection.TOP} delay>
      <button
        data-test-id={dataTestId}
        type="button"
        onClick={disabled ? null : onClick}
        className={cx(
          'p-1.5 text-primary-600 rounded-md bg-primary-600-opacity-5 hover:bg-primary-600-opacity-10 disabled:text-neutral-950-opacity-30 disabled:bg-neutral-950-opacity-5',
          { readOnly: disabled },
        )}
        disabled={disabled}
      >
        <SVG src={icon} className="h-4 w-4" />
      </button>
    </Tooltip>
  );
};

IconButton.propTypes = {
  dataTestId: PropTypes.string,
  icon: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  dataTestId: '',
  disabled: false,
  onClick: () => null,
};

export default IconButton;
