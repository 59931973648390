import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { changeFilterValue, resetPageFilters } from '../store/pagesFilters/reducer';
import { Filter, UsePageFilters } from '../store/pagesFilters/types';
import { RootState } from '../store';

export const usePageFilters = <T>(pageName: string | null = null): UsePageFilters<T> => {
  const location = useLocation();
  const dispatch = useDispatch();

  const filterPageName = useMemo(
    () => (pageName || location.pathname || '').replace(/(^\/|\/$)/g, ''),
    [pageName, location.pathname],
  );

  const pageFilters = useSelector((state: RootState) => state.pagesFilters[filterPageName]);

  const changeFilters = (newFilters: Partial<Filter<T>>): void => {
    dispatch(
      changeFilterValue({
        [filterPageName]: newFilters,
      }),
    );
  };

  const resetFilters = (): void => {
    dispatch(resetPageFilters(filterPageName));
  };

  useEffect(() => {
    if (!pageFilters) resetFilters();
  }, [pageFilters]);

  return { filters: pageFilters || {}, changeFilters, resetFilters };
};
