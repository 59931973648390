import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS, HEADERS_CONTENT_TYPE } from 'modules/api/constants';

export const editCreativesMetaDetails = async (cancelFunctions, data) => {
  const { send, cancel } = createRequest({
    url: URLS().BULK_EDIT_CREATIVES_META(),
    method: HTTP_METHODS.PUT,
    data,
  });

  if (cancelFunctions.BULK_EDIT_CREATIVES_META) cancelFunctions.BULK_EDIT_CREATIVES_META();
  cancelFunctions.BULK_EDIT_CREATIVES_META = cancel;

  const result = await send();

  return result.data;
};

export const editCreativesStatusChange = async (cancelFunctions, data) => {
  const { send, cancel } = createRequest({
    url: URLS().BULK_EDIT_STATUS_CHANGE(),
    method: HTTP_METHODS.PUT,
    data,
  });

  if (cancelFunctions.BULK_EDIT_STATUS_CHANGE) cancelFunctions.BULK_EDIT_STATUS_CHANGE();
  cancelFunctions.BULK_EDIT_STATUS_CHANGE = cancel;

  const result = await send();

  return result.data;
};

export const restoreCreatives = async (cancelFunctions, data) => {
  const { send, cancel } = createRequest({
    url: URLS().BULK_RESTORE_CREATIVES(),
    method: HTTP_METHODS.PATCH,
    showLoader: true,
    data,
  });

  if (cancelFunctions.BULK_RESTORE_CREATIVES) cancelFunctions.BULK_RESTORE_CREATIVES();
  cancelFunctions.BULK_RESTORE_CREATIVES = cancel;

  const result = await send();

  return result.data;
};

export const editCreativesDeleteSelected = async (cancelFunctions, data) => {
  const { send, cancel } = createRequest({
    url: URLS().BULK_EDIT_DELETE_SELECTED(),
    method: HTTP_METHODS.PATCH,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    data,
  });

  if (cancelFunctions.BULK_EDIT_DELETE_SELECTED) cancelFunctions.BULK_EDIT_DELETE_SELECTED();
  cancelFunctions.BULK_EDIT_DELETE_SELECTED = cancel;

  const result = await send();

  return result.data;
};
