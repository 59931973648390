import { Rectangle } from 'components/common/types/Geometry';

type Nullable<T> = T | null;

export const getElementBoundingBox = <T extends HTMLElement>(element?: Nullable<T>): Rectangle => {
  if (!element) {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    };
  }

  const { top, left, right, bottom, width, height } = element.getBoundingClientRect();

  return {
    top: Math.round(top),
    left: Math.round(left),
    width: Math.round(Math.max(width, element.offsetWidth, element.clientWidth, right - left)),
    height: Math.round(Math.max(height, element.offsetHeight, element.clientHeight, bottom - top)),
  };
};
