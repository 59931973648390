import { Store } from 'components/common/types/Store.types';
import ToggleSwitch from 'components/patterns/ToggleSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { changeFormParams } from 'store/dealManagement/reducer';
import { ActiveAllocationReportSolution } from '../../AllocationReports/AllocationReports.types';

export const ToggleAllocationReportView: React.FC = () => {
  const dispatch = useDispatch();
  const activeAllocationReportSolution = useSelector(
    (state: Store) => state.dealManagement.activeAllocationReportSolution,
  );

  return (
    <ToggleSwitch
      isChecked={activeAllocationReportSolution === ActiveAllocationReportSolution.NATIVE}
      onChange={() => {
        if (activeAllocationReportSolution === ActiveAllocationReportSolution.NATIVE) {
          dispatch(changeFormParams({ activeAllocationReportSolution: ActiveAllocationReportSolution.THOUGHTSPOT }));
        } else if (activeAllocationReportSolution === ActiveAllocationReportSolution.THOUGHTSPOT) {
          dispatch(changeFormParams({ activeAllocationReportSolution: ActiveAllocationReportSolution.NATIVE }));
        }
      }}
      label="Try new Allocation report"
      ddActionName={`Allocation report / ${
        activeAllocationReportSolution === `NATIVE` ? 'Switch to ThoughtSpot report' : 'Switch to New Allocation Report'
      }`}
    />
  );
};
