import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import Countdown from 'react-countdown';
import clockSvg from 'assets/icons/clock.svg';

const CountdownTimer = ({ date, onComplete }) => (
  <div className="bg-purple-700 rounded flex text-neutral-100 items-center body-base py-px px-1.5">
    <SVG src={clockSvg} className="w-3 mr-1" />
    <div className="mt-px">
      <Countdown date={date} daysInHours onComplete={onComplete} />
    </div>
  </div>
);

CountdownTimer.propTypes = {
  date: PropTypes.instanceOf(Date),
  onComplete: PropTypes.func,
};

CountdownTimer.defaultProps = {
  date: null,
  onComplete: () => null,
};

export default CountdownTimer;
