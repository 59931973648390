import SVG from 'react-inlinesvg';
import Session from 'modules/Session';
import { DASH_SLASH } from 'consts/placeholders';
import warningSvg from 'assets/icons/warning.svg';
import infoSvg from 'assets/icons/info.svg';

import { CreativeErrorType, Creative } from 'components/common/types/Creative.types';
import Tooltip, { TooltipDirection } from 'components/patterns/Tooltip';
import { getISOStringWithoutTime } from 'utils/dateFormatUtil';

interface Props {
  creative: Creative;
}

export const CardSummary: React.FC<Props> = ({ creative }) => {
  const currentEnvironmentMarket = creative.market.find(
    ({ environment }) => environment === Session.getEnvironmentId(),
  );

  const showValueOrDefault = (id: string, value?: string): JSX.Element => {
    if (!value) {
      return (
        <div className="relative overflow-hidden overflow-ellipsis whitespace-nowrap" data-test-id={id}>
          {DASH_SLASH}
        </div>
      );
    }

    return (
      <Tooltip tooltip={value} direction={TooltipDirection.TOP}>
        <div className="relative overflow-hidden overflow-ellipsis whitespace-nowrap" data-test-id={id}>
          {value}
        </div>
      </Tooltip>
    );
  };

  const errorIcon: { [key in CreativeErrorType as string]: string } = {
    [CreativeErrorType.PARTIAL_SUCCESS]: warningSvg,
    [CreativeErrorType.FAILURE]: infoSvg,
  };

  return (
    <div className="text-neutral-950 relative flex flex-col p-4">
      <div className="flex flex-row w-full justify-between mb-3">
        <div className="w-2/3">
          <Tooltip tooltip={creative.externalId} direction={TooltipDirection.TOP}>
            <p
              className="w-28 sub-header-sm relative overflow-hidden overflow-ellipsis whitespace-nowrap"
              data-test-id="creative-id"
            >
              {creative.externalId}
            </p>
          </Tooltip>
        </div>
        <p className="min-w-max body-sm">{getISOStringWithoutTime(creative.uploaded)}</p>
      </div>
      <div className="space-y-0.5 flex flex-col body-sm text-neutral-600">
        {showValueOrDefault('dsp', creative.dsp.name)}
        {showValueOrDefault('advertiser', currentEnvironmentMarket?.commercial?.advertiser?.name)}
        {showValueOrDefault('brand', currentEnvironmentMarket?.commercial?.brand?.name)}

        {creative.error && (
          <div className="absolute bottom-0 right-0">
            <Tooltip tooltip={creative.error.reason} direction={TooltipDirection.TOP}>
              <SVG src={errorIcon[creative.error.result]} />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};
