import { useEffect, useState } from 'react';

const useScheduledDaysLeft = (scheduledEndDate: string): number => {
  const [scheduledDaysLeft, setScheduledDaysLeft] = useState<number>(0);

  useEffect(() => {
    const getScheduledDaysLeft = (): number => {
      if (!scheduledEndDate) return 0;

      const endDate = new Date(scheduledEndDate);
      const currentDate = new Date();

      if (currentDate > endDate) return 0;

      const msBetween = Math.abs(endDate.getTime() - currentDate.getTime());

      return Math.ceil(msBetween / (1000 * 60 * 60 * 24));
    };

    const daysDiff = getScheduledDaysLeft();
    setScheduledDaysLeft(daysDiff);

    const timer = setInterval(() => {
      const daysLeft = getScheduledDaysLeft();
      setScheduledDaysLeft(daysLeft);
    }, 1800000);

    return () => clearInterval(timer);
  }, []);

  return scheduledDaysLeft;
};

export default useScheduledDaysLeft;
