import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import framePortraitSvg from 'assets/icons/frame-portrait.svg';
import Modal, { ModalSize } from 'components/patterns/Modal';
import Table, { TableHeight } from 'components/patterns/Table';
import NumberRenderer from 'components/common/NumberRenderer';
import CurrencyRenderer from 'components/common/CurrencyRenderer';
import { Points } from 'components/common/types/Deal.types';
import { FrameIdRenderer } from 'components/common/FrameIdRenderer/FrameIdRenderer';
import { percentageCellRenderer } from 'utils/cellRenderer';
import { Store } from 'components/common/types/Store.types';
import { ICellRendererParams } from 'ag-grid-community';
import { Asset } from 'components/common/types/Asset.types';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';

const getColumnDefs = (
  currencyCode: string,
  localeCode: string,
  hasVisualUnitsEnabled: boolean,
  isReseller: boolean,
): Object[] => [
  ...(isReseller
    ? [
        {
          headerName: 'Media owner',
          field: 'mediaOwner',
          headerToolTip: 'Media owner',
          suppressSizeToFit: true,
        },
      ]
    : []),
  {
    headerName: 'Frame ID',
    field: 'frameId',
    headerTooltip: 'Frame ID',
    cellRenderer: (props: { data: { frameId: string; isDischarged: boolean } }) => (
      <FrameIdRenderer frameId={props.data.frameId} isDischarged={props.data.isDischarged} />
    ),
    suppressSizeToFit: true,
  },
  ...(hasVisualUnitsEnabled
    ? [
        {
          headerName: 'Visual unit ID',
          field: 'visualUnitCode',
          headerTooltip: 'Visual unit ID',
        },
      ]
    : []),
  {
    headerName: 'Address',
    field: 'street',
    headerTooltip: 'Address',
  },
  {
    headerName: 'Postcode',
    field: 'postCode',
    headerTooltip: 'Postcode',
  },
  {
    headerName: 'Coordinates',
    field: 'longitude',
    headerTooltip: 'Coordinates',
    cellRenderer: ({ data }: { data: Points }) => {
      return (
        <p className="ag-cell-value">
          <span className="text-ellipsis overflow-hidden block">{data.latitude} Lat</span>
          <span className="text-ellipsis overflow-hidden block">{data.longitude} Lon</span>
        </p>
      );
    },
  },
  {
    headerName: 'Availability',
    field: 'availability',
    headerTooltip: 'Availability',
  },
  {
    headerName: 'Impressions',
    field: 'impressions',
    headerTooltip: 'Impressions',
    cellRenderer: ({ value }: ICellRendererParams) => NumberRenderer({ value, localeCode }),
  },
  {
    headerName: 'Price',
    field: 'price',
    headerTooltip: 'Price',
    cellRenderer: ({ value }: ICellRendererParams) => CurrencyRenderer({ value, currencyCode, localeCode }),
  },
  {
    headerName: 'Status',
    field: 'status',
    headerTooltip: 'Status',
    cellRenderer: ({ data: { isDischarged } }: { data: { frameId: string; isDischarged: boolean } }) => {
      return isDischarged ? 'Dismounted' : 'Mounted';
    },
    resizable: false,
  },
  {
    headerName: 'Share of time',
    field: 'sot',
    headerTooltip: 'Share of time',
    cellRenderer: (params: ICellRendererParams) => percentageCellRenderer(params, localeCode),
    resizable: false,
  },
];

type Props = {
  assets: Asset[];
  isOpen: boolean;
  onClose: VoidFunction;
  footer?: JSX.Element | null;
};

export const FrameDetailsModal: React.FC<Props> = ({ assets, isOpen = false, onClose, footer: Footer = null }) => {
  const { localeCode, currencyCode } = useSelector((state: Store) => state.publisher.configuration);
  const dischargedFrameIds = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.dischargedFrameIds,
  );
  const isAdServerMarket = useSelector(getIsAdServer);
  const isReseller = useSelector(getIsReseller);
  const hasVisualUnitsEnabled = useHasFeatureAccess(FeatureFlags.VISUAL_UNITS) && isAdServerMarket;
  const isDischargedFrameId = (frameId: string): boolean => dischargedFrameIds?.includes(frameId);
  const assetsWithOOC = assets.map((asset) => ({ ...asset, isDischarged: isDischargedFrameId(asset.frameId) }));

  return (
    <Modal
      dataTestId="frame-details-modal"
      isOpen={isOpen}
      icon={<SVG src={framePortraitSvg} className="text-primary" />}
      title="Frame details"
      size={ModalSize.LARGE}
      showCloseButton
      onClose={onClose}
    >
      <Table
        extraGridOptions={{
          enableCellTextSelection: true,
        }}
        columnDefs={getColumnDefs(currencyCode, localeCode, hasVisualUnitsEnabled, isReseller)}
        rowData={assetsWithOOC}
        tableSize={{
          tableHeight: TableHeight.NORMAL,
        }}
      />
      {Footer}
    </Modal>
  );
};

FrameDetailsModal.defaultProps = {
  footer: null,
};
