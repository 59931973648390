import createRequest from 'modules/api/request';
import queryString from 'query-string';

import { URLS, HTTP_METHODS } from 'modules/api/constants';
import { CancelFunctions } from 'components/common/types';

export const getAuthenticationToken = async (
  cancelFunctions: CancelFunctions,
  preferredLocale: string,
): Promise<string> => {
  const query = queryString.stringify({ preferredLocale }, { skipEmptyString: true, arrayFormat: 'comma' });
  const { send, cancel } = createRequest({
    url: URLS().GET_AUTHENTICATION_TOKEN(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.GET_AUTHENTICATION_TOKEN) cancelFunctions.GET_AUTHENTICATION_TOKEN();
  cancelFunctions.GET_AUTHENTICATION_TOKEN = cancel;

  const result = await send();

  return result.data;
};
