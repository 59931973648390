import { useState } from 'react';

export interface UseToggleResult {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export const useToggle = (initiallyOpen = false): UseToggleResult => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);

  const close = (): void => {
    setIsOpen(false);
  };

  const open = (): void => {
    setIsOpen(true);
  };

  const toggle = (): void => {
    setIsOpen(!isOpen);
  };

  return {
    isOpen,
    open,
    close,
    toggle,
  };
};
