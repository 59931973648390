import SVG from 'react-inlinesvg';
import logo from 'assets/icons/logo.svg';
import Tooltip, { TooltipDirection } from 'components/patterns/Tooltip';

const YEAR = new Date().getFullYear();

const Logo: React.FC = () => {
  return (
    <div data-test-id="logo" className="block text-center">
      <Tooltip
        tooltip={
          <div className="w-max">
            {/* VERSION is a global variable injected during build by webpack */}
            {/* eslint-disable-next-line prettier/prettier */}
            {/* @ts-expect-error build-time defined global variable */}
            {/* eslint-disable-line no-undef */}© VIOOH {YEAR} - v{VERSION}
          </div>
        }
        direction={TooltipDirection.RIGHT}
      >
        <SVG src={logo} />
      </Tooltip>
    </div>
  );
};

export default Logo;
