import * as React from 'react';
import { Suspense } from 'react';
import classNames from 'classnames/bind';
import * as Modal from 'react-modal';
import { Routes, Route, Navigate, NavigateFunction } from 'react-router-dom';
import { isEmpty } from 'lodash';

import Auth from 'modules/Auth';
import getRoutes, { publicRoutes } from 'routes';

import withRouter from 'components/hocs/withRouter';
import withCancelRequest from 'components/hocs/withCancelRequest';
import PrivateRoute from 'components/common/Auth/Private';
import AuthCallback from 'components/common/Auth/Callback';
import SidebarMenu from 'components/common/SidebarMenu';
import Notifications from 'components/common/Notifications';
import { Preloader } from 'components/patterns/Loader';
import { URLS } from 'modules/api/constants';
import { getPublisherConfiguration, getPublisherFeatures } from 'modules/api/publisher';
import { CancelFunctions, PublisherConfigurationResponse } from 'components/common/types';
import { mergePublisherAndEnvironmentFeatures } from 'utils/mergePublisherAndEnvironmentFeatures';
import { dataDogLogExternalPublisherId } from 'modules/DataDog';
import styles from './App.pcss';
import { PublisherConfiguration, PublisherFeatures } from '../types/Publisher.types';

Modal.setAppElement('#root');

const cx = classNames.bind(styles);

type OwnPropsType = {
  cancelFunctions: CancelFunctions;
};

export type StateToPropsType = {
  environmentId: string;
  publisherConfiguration: PublisherConfiguration | {};
  publisherFeatures: PublisherFeatures | {};
};

export type DispatchToPropsType = {
  changeConfiguration: (configuration: PublisherConfigurationResponse) => void;
  changeFeatures: (features: PublisherFeatures) => void;
};

type HOCPropsType = {
  location: Location;
  navigate: NavigateFunction;
};

type PropsType = OwnPropsType & StateToPropsType & DispatchToPropsType & HOCPropsType;

class App extends React.Component<PropsType> {
  isGetMetaDataCalled = false;

  plannerUrl = URLS().PLANNER();

  postMessageToPlanner = (eventSource: MessageEventSource): void => {
    const { environmentId } = this.props;
    eventSource.postMessage({ type: 'env_response', id: environmentId }, this.plannerUrl);
  };

  async componentDidMount(): Promise<void> {
    const { environmentId, location, navigate } = this.props;

    window.addEventListener('message', (e) => {
      if (!e.source || e.origin !== this.plannerUrl || e.data !== 'env_request') return;
      this.postMessageToPlanner(e.source);
    });

    const isAuthenticated = await Auth.isAuthenticated();

    if (!isAuthenticated && !location.pathname.includes('accessdenied')) {
      await Auth.redirectToUniversalLoginPage();
    }

    if (!environmentId) {
      navigate('/landing');
    }

    if (environmentId && isAuthenticated) {
      this.getConfiguration();
    }
  }

  async componentDidUpdate(): Promise<void> {
    dataDogLogExternalPublisherId();
  }

  getConfiguration = async (): Promise<void> => {
    const { changeConfiguration, changeFeatures, cancelFunctions, environmentId, location } = this.props;
    const isAuthenticated = await Auth.isAuthenticated();

    try {
      if (isAuthenticated && environmentId && !location.pathname.includes('/landing')) {
        const configuration = await getPublisherConfiguration(environmentId, cancelFunctions);
        changeConfiguration(configuration);

        const publisherFeatures = await getPublisherFeatures(environmentId, cancelFunctions);
        const features = mergePublisherAndEnvironmentFeatures(environmentId, publisherFeatures);
        changeFeatures(features);
      }
    } catch {} // eslint-disable-line no-empty
  };

  render(): React.ReactNode {
    const { environmentId, location, publisherConfiguration, publisherFeatures } = this.props;

    const isPublisherData = !isEmpty(publisherConfiguration) && !isEmpty(publisherFeatures);
    const isComponentReadyToRender = isPublisherData || location.pathname.includes('/landing');

    const routes = isComponentReadyToRender ? getRoutes() : [];

    return (
      <div className={cx('body-base font-inter')}>
        <SidebarMenu isRequiredMarketData={Boolean(isPublisherData && environmentId)} />
        <div className={cx('wrapper')}>
          <Suspense fallback={<Preloader />}>
            <Routes>
              <Route path="/" element={<Navigate to="/landing" />} />
              <Route path="/auth-callback" element={<AuthCallback />} />
              {publicRoutes.map((route) => (
                <Route key={route.id} path={route.path} element={<route.main />} />
              ))}
              {routes.map((route) => (
                <Route
                  key={route.id}
                  path={route.path}
                  element={
                    <PrivateRoute
                      component={route.main as React.ComponentType}
                      pageAccessPermission={route.pageAccessPermission}
                    />
                  }
                />
              ))}
            </Routes>
          </Suspense>
        </div>
        <Notifications />
      </div>
    );
  }
}

export default withRouter(withCancelRequest(App));
