export const IN_USE_STATUS = {
  TRUE: true,
  FALSE: false,
};

export const IN_USE_STATUS_BADGE = [
  {
    code: IN_USE_STATUS.TRUE,
    name: 'Use',
    initials: 'USE',
    color: 'bg-sky',
  },
  {
    code: IN_USE_STATUS.FALSE,
    name: 'Not in use',
    initials: 'USE',
    color: 'bg-neutral-300',
  },
];
