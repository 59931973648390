import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import Button, { ButtonShape, ButtonSize, ButtonType } from 'components/patterns/Button';

import styles from './MultipleSwitchBox.pcss';

const cx = classNames.bind(styles);

export const MultipleSwitchBoxTheme = {
  LIGHT: 'container--light',
  DARK: 'container--dark',
};

const MultipleSwitchBox = ({
  source,
  onChange,
  selectedValue,
  textKey,
  valueKey,
  icon,
  theme,
  isDisabled,
  dataTestId,
  isFullWidth,
  hideBorder,
}) => {
  const activeButtonType = theme === MultipleSwitchBoxTheme.DARK ? ButtonType.ACTIVE : ButtonType.LIGHT;
  const btnSize = isFullWidth ? ButtonSize.MEDIUM : ButtonSize.FULL_FLEX;

  return (
    <div
      className={cx('multipleSwitchBox', 'flex-row justify-evenly', {
        readOnly: isDisabled,
        'full-width': isFullWidth,
      })}
      data-test-id={dataTestId}
    >
      <div
        className={cx('container', theme, {
          'border-neutral-300': !hideBorder,
          'border-none': hideBorder,
          'w-full': isFullWidth,
          'w-min': !isFullWidth,
        })}
      >
        {icon && <SVG src={icon} className={cx('ml-2 mr-2', 'main-icon', { disabled: isDisabled })} />}
        {source.map((option) => (
          <span
            className={cx('mr-1 text-neutral-900', {
              active: option[valueKey] === selectedValue,
            })}
            key={option[valueKey]}
          >
            <Button
              type="button"
              key={option[valueKey]}
              onClick={() => onChange(option[valueKey])}
              btnType={option[valueKey] === selectedValue ? activeButtonType : ButtonType.TRANSPARENT}
              btnShape={ButtonShape.NORMAL}
              isDisabled={option[valueKey] === selectedValue && isDisabled}
              dataTestId={`${dataTestId}-${option[textKey].replace(' ', '').toLowerCase()}`}
              btnSize={btnSize}
            >
              <SVG src={option.icon} />
              <span className="body-base">{option[textKey]}</span>
            </Button>
          </span>
        ))}
      </div>
    </div>
  );
};

MultipleSwitchBox.propTypes = {
  source: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  textKey: PropTypes.string,
  valueKey: PropTypes.string,
  dataTestId: PropTypes.string,
  icon: PropTypes.node,
  theme: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  hideBorder: PropTypes.bool,
};

MultipleSwitchBox.defaultProps = {
  onChange: () => undefined,
  selectedValue: undefined,
  dataTestId: '',
  textKey: 'name',
  valueKey: 'code',
  icon: null,
  theme: MultipleSwitchBoxTheme.LIGHT,
  isDisabled: false,
  isFullWidth: false,
  hideBorder: false,
};

export default MultipleSwitchBox;
