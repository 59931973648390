import AsyncSelect from 'components/patterns/AsyncSelect';
import { ReactElement } from 'react';
import { Advertiser } from 'components/common/types/Deal.types';
import { AdvertiserFilters, FilterAdvertiserProps } from './FilterAdvertiser.types';
import { useLoadLookup } from '../useLoadLookup';

const FilterAdvertiser = <T extends AdvertiserFilters>({
  changeFilters,
  selectedAdvertiser,
}: FilterAdvertiserProps<T>): ReactElement => {
  const { onLoadLookup } = useLoadLookup();

  const onSelect = (_name: string, advertiser: Advertiser): void => {
    const newFilters: Partial<T> = {};
    newFilters.advertiser = advertiser;
    changeFilters(newFilters);
  };

  return (
    <AsyncSelect
      dataTestId="advertiser-filter"
      name="advertiser"
      loadData={onLoadLookup}
      onSelect={onSelect}
      selectedValue={selectedAdvertiser}
      placeholder="Search advertiser"
    />
  );
};

export default FilterAdvertiser;
