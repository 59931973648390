import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { CreativeStatus } from 'components/common/types/Creative.types';
import { ButtonRendererProps } from './ButtonRenderer.types';
import { DeleteCreative } from './DeleteCreative';
import { RestoreCreative } from './RestoreCreative';

const ButtonRenderer: React.FC<ButtonRendererProps> = ({ onStatusUpdate, data }) => {
  const shouldRenderDeleteButton =
    data &&
    !data.deletedAt &&
    Auth.hasPermission(PermissionsEnum.ADMINISTRATION_CREATIVE_DELETE) &&
    (data.statusCode === CreativeStatus.PENDING || data.statusCode === CreativeStatus.REJECTED);

  const shouldRenderRestoreButton =
    !!data && !!data.deletedAt && Auth.hasPermission(PermissionsEnum.ADMINISTRATION_CREATIVE_RESTORE);

  return (
    <span className="table-action-button creative-action-button">
      {shouldRenderDeleteButton && <DeleteCreative data={data} onComplete={onStatusUpdate} />}
      {shouldRenderRestoreButton && <RestoreCreative data={data} onComplete={onStatusUpdate} />}
    </span>
  );
};

export default ButtonRenderer;
