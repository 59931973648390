import { useEffect, useRef } from 'react';

import { CancelFunctions } from 'components/common/types';

export const useCancelRequest = (): CancelFunctions => {
  const cancelFunctions = useRef<CancelFunctions>({}).current;

  useEffect(() => {
    return () => {
      Object.values(cancelFunctions).forEach((cancel) => {
        cancel();
      });
    };
  }, []);

  return cancelFunctions;
};
