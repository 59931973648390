import { getDaysDiff } from 'utils/dateFormatUtil';
import cloneDeep from 'lodash/cloneDeep';
import DAY_PART from 'consts/dayPart';

export const DAYS_IN_WEEK = 7;
export const LAST_ISO_WEEK_DAY = 6;

const getDefaultSelectedDay = (id, date) => {
  const defaultDate = new Date();
  defaultDate.setHours(0, 0, 0, 0);

  return {
    id,
    date: date || defaultDate,
    currentSelection: true,
    isFullDay: true,
    dayParts: [DAY_PART.fullDay],
    tempCustomHours: {},
  };
};

export const getISOWeekDay = (date) => {
  if (!date) return null;
  // ISO week date weeks start on monday
  return (date.getDay() + LAST_ISO_WEEK_DAY) % DAYS_IN_WEEK;
};

export const getDaysList = (startDate, endDate) => {
  const days = [];
  const date = new Date(startDate);
  const totalDays = getDaysDiff(startDate, endDate);

  date.setHours(0, 0, 0, 0);

  for (let i = 0; i <= totalDays; i++) {
    const dayDate = new Date(date);
    days.push({ id: dayDate.toISOString(), date: dayDate });
    date.setDate(date.getDate() + 1);
  }

  return days;
};

export const transformPattern = (pattern, startDate) => {
  const transformedPattern = {};
  let dayPartCount = 0;

  const getPartId = (date, isFullDay) => (isFullDay ? DAY_PART.fullDay.id : date.getTime() + dayPartCount++);

  pattern?.forEach((day) => {
    const isFullDay = !!day.dayPart.find(({ dayPartId }) => dayPartId === DAY_PART.fullDay.id);
    const dayDate = new Date(startDate);
    dayDate.setHours(0, 0, 0, 0);
    // getISOWeekDay is only used here to support viewing in automation. It must be kept in sync with the getPattern function.
    dayDate.setDate(startDate.getDate() + (day.dayId - getISOWeekDay(startDate)));
    const id = dayDate.toISOString();

    const dayParts = day.dayPart.map((part) => ({
      ...part,
      id: getPartId(dayDate, part.dayPartId === DAY_PART.fullDay.id),
    }));

    transformedPattern[id] = {
      ...getDefaultSelectedDay(id, dayDate),
      dayId: day.dayId,
      currentSelection: false,
      isFullDay,
      dayParts,
    };
  });

  return transformedPattern;
};

export const getPattern = (selectedDays, startDate) => {
  const newDate = startDate instanceof Date ? startDate : new Date(startDate);

  return Object.values(selectedDays).map(({ date, dayId, dayParts }) => ({
    // getISOWeekDay is only used here to support viewing in automation. It must be kept in sync with the transformPattern function.
    dayId: dayId !== undefined ? dayId : Math.ceil(getDaysDiff(newDate, date)) + getISOWeekDay(newDate),
    dayPart: dayParts,
  }));
};

export const getVisibleDaysFromSelectedDays = (pattern, startDate, listOfDays) => {
  const furthestSelectedDay = Math.max(...Object.values(pattern).map(({ date }) => getDaysDiff(startDate, date)));

  let endPosition = listOfDays.length > DAYS_IN_WEEK ? DAYS_IN_WEEK : listOfDays.length;

  if (furthestSelectedDay >= endPosition) {
    endPosition = DAYS_IN_WEEK * Math.ceil(furthestSelectedDay / LAST_ISO_WEEK_DAY);
  }

  if (endPosition === 0) {
    endPosition = DAYS_IN_WEEK;
  }

  return listOfDays.slice(0, endPosition);
};

export const handleSelectingDays = (event, prevState, selectedDay, readOnly) => {
  const prevSelectedDays = cloneDeep(prevState);

  if (!event.ctrlKey && !event.metaKey) {
    Object.values(prevSelectedDays).forEach((day) => {
      if (day.id === selectedDay.id) return;
      day.currentSelection = false;
    });
  }

  const prevSelectedDay = prevSelectedDays[selectedDay.id];
  if (prevSelectedDay) {
    prevSelectedDays[selectedDay.id].currentSelection = !prevSelectedDay.currentSelection;
    prevSelectedDays[selectedDay.id].tempCustomHours = {};
  } else if (!readOnly) {
    prevSelectedDays[selectedDay.id] = getDefaultSelectedDay(selectedDay.id, selectedDay.date);
  }

  return prevSelectedDays;
};
