import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import SVG from 'react-inlinesvg';
import { GridApi, ICellRendererParams, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { FileRejection } from 'react-dropzone';

import { RootState } from 'store';
import { notifyError, notifySuccess } from 'store/notification/reducer';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { formatDateTime } from 'modules/I18N';
import Auth from 'modules/Auth';
import Session from 'modules/Session';
import { FILE_SIZE_10MB } from 'modules/api/constants';
import { ACCEPTED_FILE_TYPE_XLS } from 'consts/fileType';
import { uploadSetupFile, fetchSetupFileDetails, fetchSetupList } from 'modules/api/setup';
import { PermissionsEnum } from 'consts/permissions';
import { getValidPageSize } from 'utils/pageSize';
import { NotificationMessages } from 'consts/notifications';

import withLoader from 'components/hocs/withLoader';
import FileUpload from 'components/patterns/FileUpload';
import Table, { TableHeight, TableRowHeight } from 'components/patterns/Table';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import { BadgeColor } from 'components/patterns/Badge';
import Card from 'components/patterns/Card';
import { CardTheme } from 'components/patterns/Card/Card.types';
import Modal from 'components/patterns/Modal';
import PageSize from 'components/patterns/PageSize';
import uploadSvg from 'assets/icons/upload.svg';
import { FileUploadStatusCode } from 'components/common/types/FileUploadStatusCode.types';
import { FileSuccess } from 'components/common/types/File.types';
import { SetupDetailedFrameInformation, SetupDetails } from 'components/common/types/Setup.types';

import DownloadRenderer from './DownloadRenderer';
import StatusRenderer from './StatusRenderer';
import FileDetails from './FileDetails';

const STATUSES = [
  {
    code: FileUploadStatusCode.SUCCESS,
    name: 'Success',
    initials: 'S',
    color: BadgeColor.GREEN,
  },
  {
    code: FileUploadStatusCode.FAILED,
    name: 'Failed',
    initials: 'F',
    color: BadgeColor.RED,
  },
  {
    code: FileUploadStatusCode.ERROR,
    name: 'Error',
    initials: 'E',
    color: BadgeColor.RED,
  },
  {
    code: FileUploadStatusCode.WAITING,
    name: 'Waiting',
    initials: 'W',
    color: BadgeColor.YELLOW,
  },
  {
    code: FileUploadStatusCode.PROCESSING,
    name: 'Processing',
    initials: 'P',
    color: BadgeColor.YELLOW,
  },
];

const pageSizeKey = 'setup';

const SetupContent: React.FC = () => {
  const [fileList, setFileList] = useState<FileSuccess[]>([]);
  const [fileName, setFileName] = useState('');
  const [setupDetails, setSetupDetails] = useState<SetupDetails>({
    hasErrors: true,
    fileName: '',
    statusCode: FileUploadStatusCode.ERROR,
    setupDetailResponse: [],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize, setPageSize] = useState(getValidPageSize(Session.getPageSizeByKey(pageSizeKey)));
  const tableApiRef = useRef<GridApi>();
  const cancelFunctions = useCancelRequest();
  const dispatch = useDispatch();
  const { localeCode } = useSelector((state: RootState) => state.publisher.configuration);

  const onFileChange = (acceptedFiles: FileSuccess[], rejectedFiles: FileRejection[]): void => {
    if (acceptedFiles.length > 0) {
      setFileList(acceptedFiles);
      setFileName(acceptedFiles[0].name);
    }

    if (rejectedFiles.length > 0) {
      dispatch(notifyError({ message: NotificationMessages.INVALID_UPLOAD }));
    }
  };

  const getTableDataSource = (): IDatasource => {
    return {
      getRows: async (params: IGetRowsParams) => {
        const pageNumber = params.startRow / pageSize;

        const { setupList, totalElements } = await fetchSetupList(
          cancelFunctions,
          `&pageNo=${pageNumber}&pageSize=${pageSize}`,
        );

        if (Number(totalElements) === 0) {
          tableApiRef.current?.showNoRowsOverlay();
        } else {
          tableApiRef.current?.hideOverlay();
        }

        params.successCallback(setupList, Number(totalElements));
      },
    };
  };

  const getTableApi = (tableApi: GridApi): void => {
    tableApiRef.current = tableApi;
    tableApi.setDatasource(getTableDataSource());
  };

  const onPageSizeChange = (numberOfItems: number): void => {
    setPageSize(numberOfItems);
    Session.setPageSizeByKey(pageSizeKey, numberOfItems);
  };

  const onUploadFiles = async (): Promise<void> => {
    if (!fileList.length) {
      dispatch(notifyError({ message: 'Please select file to upload' }));
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      formData.append('files', fileList[i]);
    }

    try {
      const result = await uploadSetupFile(formData, cancelFunctions);
      const { setupResponses } = result;

      if (setupResponses[0].uploadStatusCode === 'REJECTED') {
        dispatch(
          notifyError({
            message: `${setupResponses[0].fileName} has been ${setupResponses[0].uploadStatusCode} ${setupResponses[0].errorDetail}`,
            timeout: 0,
          }),
        );
      } else {
        dispatch(
          notifySuccess({
            message: `${setupResponses[0].fileName} successfully uploaded with status ${setupResponses[0].uploadStatusCode}`,
            timeout: 0,
          }),
        );
      }

      setFileList([]);
      setFileName('');
      getTableApi(tableApiRef.current!);
    } catch {
      dispatch(notifyError({ message: 'Error occurred while uploading file' }));
    }
  };

  const onStatusDetails = async (id: string, name: string, statusCode: FileUploadStatusCode): Promise<void> => {
    if (statusCode !== FileUploadStatusCode.PROCESSING && statusCode !== FileUploadStatusCode.WAITING) {
      try {
        const response = await fetchSetupFileDetails(id, cancelFunctions);
        const checkHasError = (element: SetupDetailedFrameInformation): boolean => element.errorRecords > 0;
        setSetupDetails({
          hasErrors: response.setupDetailResponse.some(checkHasError),
          fileName: name,
          statusCode,
          setupDetailResponse: response.setupDetailResponse,
        });
        setIsOpen(true);
      } catch {} // eslint-disable-line no-empty
    }
  };

  const columnDefs = [
    {
      headerName: 'Status',
      field: 'uploadStatusCode',
      minWidth: 150,
      maxWidth: 150,
      resizable: false,
      cellRenderer: StatusRenderer,
      cellRendererParams: {
        statuses: STATUSES,
        onClick: (id: string, name: string, statusCode: FileUploadStatusCode) => onStatusDetails(id, name, statusCode),
      },
      colId: 'status',
    },
    {
      headerName: 'File name',
      field: 'fileName',
      minWidth: 340,
    },
    {
      headerName: 'Date',
      field: 'uploadDate',
      minWidth: 270,
      cellRenderer: (params: ICellRendererParams) => {
        if (params.data) {
          return formatDateTime({ isoDateString: params.data.uploadDate, localeCode, dateStyle: 'short' });
        }
        return '';
      },
    },
    {
      headerName: 'Uploaded by',
      field: 'uploadUserName',
      minWidth: 120,
      resizable: false,
    },
    {
      headerName: '',
      cellRenderer: DownloadRenderer,
      minWidth: 50,
      resizable: false,
      hide: !Auth.hasPermission(PermissionsEnum.SETUP_DOWNLOAD),
    },
  ];

  return (
    <>
      {Auth.hasPermission(PermissionsEnum.SETUP_UPLOAD) && (
        <Card cardTheme={CardTheme.WHITE}>
          <div className="flex gap-x-7">
            <div className="w-88">
              <FileUpload
                acceptFileType={ACCEPTED_FILE_TYPE_XLS}
                maxFileSize={FILE_SIZE_10MB}
                placeholder={fileName || 'Upload asset data file'}
                onFileChange={onFileChange}
              />
            </div>
            <Button onClick={onUploadFiles} btnShape={ButtonShape.NORMAL} btnType={ButtonType.PRIMARY}>
              <SVG src={uploadSvg} />
              <span>Upload file</span>
            </Button>
          </div>
        </Card>
      )}
      <div className="flex justify-between mb-3">
        <PageSize
          dataTestId="page-size-selection"
          onPageSizeChange={onPageSizeChange}
          pageSize={pageSize}
          pageInfoText="setup files per page"
        />
        <Button
          onClick={() => getTableApi(tableApiRef.current!)}
          btnShape={ButtonShape.NORMAL}
          btnType={ButtonType.TERTIARY}
        >
          Refresh asset data
        </Button>
      </div>
      <div className={cx('h-[calc(100vh-var(--header-height)*3.5)]')}>
        <Table
          dataTestId="setup-table"
          key={pageSize}
          columnDefs={columnDefs}
          getTableApi={getTableApi}
          extraGridOptions={{
            rowModelType: 'infinite',
            rowHeight: TableRowHeight.NORMAL,
            pagination: true,
            paginationPageSize: pageSize,
            cacheBlockSize: pageSize,
            maxBlocksInCache: 1,
            enableCellTextSelection: true,
            suppressBrowserResizeObserver: true,
          }}
          tableSize={{ tableHeight: TableHeight.FIT_SCREEN }}
        />
      </div>
      <Modal isOpen={isOpen} showCloseButton onClose={() => setIsOpen(false)}>
        <FileDetails setupDetails={setupDetails} />
      </Modal>
    </>
  );
};

export default withLoader(SetupContent);
