import {
  CreativeMetaOperation,
  CreativeMetaOperationsModel,
  CreativesMetaFormModel,
} from 'components/common/types/Creative.types';

export const FormMetaDataInitialState: CreativesMetaFormModel = {
  dealId: [],
  productFormat: [],
  advertiser: null,
  brand: null,
  productCategory: null,
  agency: null,
  specialist: null,
  movement: null,
};

export const FormMetaDataOperationsInitialState: CreativeMetaOperationsModel = {
  dealId: CreativeMetaOperation.ADD,
  productFormat: CreativeMetaOperation.ADD,
};
