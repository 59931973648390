import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { Store } from 'components/common/types/Store.types';
import {
  clearCreativesMetaAndResetMotionType,
  setCreativeMeta,
  setCreativeMetaOperations,
  updateCreativesErrorStatus,
} from 'store/pages/contentManagement/creatives/reducer';
import { notifyError } from 'store/notification/reducer';
import { getAllSelectedCreativesPendingStatus } from 'store/pages/contentManagement/creatives/selectors';
import {
  updatePreviousProductCategories,
  updatePreviousFrameTags,
  updateIsUnsavedProductCategory,
  updateIsUnsavedFrameTags,
  updateIsAdditionalDataIncluded,
  resetCreativesAdditionalData,
} from 'store/pages/contentManagement/creativesAdditionalData/reducer';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { FilterAreaType } from 'components/pages/ContentManagement/Creatives/Content/Filters/Filters.types';
import {
  CreativeMetaOperation,
  CreativesMetaFormModel,
  CreativeMetaOperationsModel,
} from 'components/common/types/Creative.types';
import { editCreativesMetaDetails } from 'modules/api/bulkEditCreatives';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { resetCreativeDetails } from 'store/creative/reducer';
import ActionsPanel from './ActionsPanel';
import CreativesMetaForm from './CreativesMetaForm';
import { CreativesStatusesSummary } from './CreativesStatusesSummary/CreativesStatusesSummary';
import { FormMetaDataInitialState, FormMetaDataOperationsInitialState } from './EditPanel.types';
import { checkResponseResultErrors, getCreativeMarketId, getActiveFrameTags } from './utils';

const EditPanel: React.FC = () => {
  const [formMetadata, setFormMetadata] = useState<CreativesMetaFormModel>(FormMetaDataInitialState);
  const [formMetadataOperations, setFormMetadataOperations] = useState<CreativeMetaOperationsModel>(
    FormMetaDataOperationsInitialState,
  );
  const selectedCreatives = useSelector((state: Store) => state.pages.contentManagement.creatives.selectedCreatives);
  const selectedCreativesMetadata = useSelector((state: Store) => state.pages.contentManagement.creatives.metadata);
  const selectedCreativesOperations = useSelector((state: Store) => state.pages.contentManagement.creatives.operations);
  const areAllItemsPending = useSelector(getAllSelectedCreativesPendingStatus);
  const isUnsavedProductCategoryOperationChange = useSelector(
    (state: Store) => state.pages.contentManagement.creativesAdditionalData.isUnsavedProductCategoryChange,
  );
  const isUnsavedFrameTagsOperationChange = useSelector(
    (state: Store) => state.pages.contentManagement.creativesAdditionalData.isUnsavedFrameTagsChange,
  );
  const {
    previousProductCategories,
    newProductCategories,
    productCategoriesMode,
    newFrameTags,
    previousFrameTags,
    frameTagsMode,
  } = useSelector((state: Store) => state.pages.contentManagement.creativesAdditionalData);
  const cancelFunctions = useCancelRequest();
  const dispatch = useDispatch();

  const isProductCategoriesDataChanged = useMemo(
    () =>
      (productCategoriesMode === CreativeMetaOperation.ADD &&
        !isEqual(newProductCategories, []) &&
        !isEqual(previousProductCategories, newProductCategories)) ||
      (productCategoriesMode === CreativeMetaOperation.REPLACE &&
        !isEqual(previousProductCategories, newProductCategories)),
    [productCategoriesMode, newProductCategories, previousProductCategories],
  );

  const newFrameTagsWithActiveFrames = getActiveFrameTags(newFrameTags);
  const previousFrameTagsWithActiveFrames = getActiveFrameTags(previousFrameTags);

  const isFrameTagsDataChanged = useMemo(
    () =>
      (frameTagsMode === CreativeMetaOperation.ADD &&
        !isEqual(newFrameTagsWithActiveFrames, []) &&
        !isEqual(previousFrameTagsWithActiveFrames, newFrameTagsWithActiveFrames)) ||
      (frameTagsMode === CreativeMetaOperation.REPLACE &&
        !isEqual(previousFrameTagsWithActiveFrames, newFrameTagsWithActiveFrames)),
    [frameTagsMode, previousFrameTagsWithActiveFrames, newFrameTagsWithActiveFrames],
  );

  const isFormTouched = useMemo(
    () =>
      isUnsavedFrameTagsOperationChange ||
      isUnsavedProductCategoryOperationChange ||
      isProductCategoriesDataChanged ||
      isFrameTagsDataChanged ||
      !isEqual(selectedCreativesMetadata, formMetadata) ||
      Object.keys(formMetadataOperations).some(
        (fieldName: keyof (typeof formMetadata | typeof formMetadataOperations)) =>
          !isEqual(formMetadata[fieldName], []) &&
          !isEqual(formMetadataOperations[fieldName], selectedCreativesOperations[fieldName]),
      ),
    [
      isUnsavedFrameTagsOperationChange,
      isUnsavedProductCategoryOperationChange,
      isProductCategoriesDataChanged,
      isFrameTagsDataChanged,
      selectedCreativesMetadata,
      selectedCreativesOperations,
      formMetadata,
      formMetadataOperations,
    ],
  );

  const updateCreativesMetadata = async (): Promise<void> => {
    const transformCreativeMeta = ({
      advertiser,
      brand,
      productCategory,
      agency,
      specialist,
      movement,
      dealId,
      productFormat,
    }: CreativesMetaFormModel): object => ({
      dealData: {
        dealIds: dealId.map(({ id, code }) => id ?? code),
        operation: formMetadataOperations.dealId,
      },
      productFormatData: {
        productFormatIds: productFormat.map((product) => product.id),
        operation: formMetadataOperations.productFormat,
      },
      movement: movement || null,
      advertiserId: advertiser?.id,
      brandId: brand?.id,
      productCategoryId: productCategory?.id,
      agencyId: agency?.id,
      specialistId: specialist?.id,
    });

    try {
      checkResponseResultErrors(
        await editCreativesMetaDetails(cancelFunctions, {
          ids: selectedCreatives.map(getCreativeMarketId),
          ...transformCreativeMeta(formMetadata),
          productCategoryData: {
            productCategoryIds: newProductCategories.map((category) => category.id),
            operation: productCategoriesMode,
          },
          marketTagData: {
            marketTags: newFrameTagsWithActiveFrames.map((tag) => ({ tagId: tag.id, type: tag.type })),
            operation: frameTagsMode,
          },
        }),
      );

      dispatch(updateIsUnsavedFrameTags(false));
      dispatch(updateIsUnsavedProductCategory(false));
      dispatch(setCreativeMeta(formMetadata));
      dispatch(setCreativeMetaOperations(formMetadataOperations));
      dispatch(updateCreativesErrorStatus([]));
      dispatch(updatePreviousProductCategories(newProductCategories));
      dispatch(updatePreviousFrameTags(newFrameTags));
    } catch (err) {
      dispatch(updateCreativesErrorStatus(err.errorList));
      dispatch(notifyError({ message: err.message, timeout: NOTIFICATION_TIMEOUT.LONG }));
    }
  };

  const handleFormOperationChange =
    (field: string) =>
    (operation: CreativeMetaOperation): void =>
      setFormMetadataOperations((prev) => ({
        ...prev,
        [field]: operation,
      }));

  const handleFormChange = (field: keyof CreativesMetaFormModel, value: FilterAreaType): void => {
    setFormMetadata((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    setFormMetadata(selectedCreativesMetadata);
  }, [selectedCreativesMetadata]);

  useEffect(() => {
    setFormMetadataOperations(selectedCreativesOperations);
  }, [selectedCreativesOperations]);

  useEffect(() => {
    return () => {
      dispatch(clearCreativesMetaAndResetMotionType());
      dispatch(resetCreativesAdditionalData());
      dispatch(updateIsAdditionalDataIncluded(false));
      dispatch(resetCreativeDetails());
    };
  }, []);

  return (
    <>
      <div className="py-4 px-8 h-screen overflow-auto">
        <CreativesStatusesSummary />
        <CreativesMetaForm
          formMetadata={formMetadata}
          formMetadataOperations={formMetadataOperations}
          onFormChange={handleFormChange}
          onFormOperationChange={handleFormOperationChange}
          isDisabled={!areAllItemsPending}
        />
      </div>
      <div className="sticky">
        <ActionsPanel
          formMetadata={formMetadata}
          isFormTouched={isFormTouched}
          onClearButtonClick={() => {
            dispatch(updateIsUnsavedFrameTags(false));
            dispatch(updateIsUnsavedProductCategory(false));
            dispatch(clearCreativesMetaAndResetMotionType());
            dispatch(resetCreativesAdditionalData());
          }}
          onSaveButtonClick={() => updateCreativesMetadata()}
        />
      </div>
    </>
  );
};

export default EditPanel;
