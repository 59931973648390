import PropTypes from 'prop-types';
import cx from 'classnames';
import SVG from 'react-inlinesvg';

import closeSvg from 'assets/icons/close.svg';

const Panel = ({ isOpen, toggle, title, children }) => {
  return (
    <div
      className={cx(
        'motion-safe:transition-all motion-safe:ease-out motion-safe:duration-1000 absolute overflow-y-scroll h-full w-full rounded-t-3xl bg-neutral-100 z-1',
        {
          'top-0': isOpen,
          'top-full': !isOpen,
        },
      )}
    >
      <div className="mb-6 px-8 py-4 sticky top-0 bg-neutral-100 z-1 shadow">
        <div className="flex flex-row items-start justify-between">
          <h4 className="sub-header-lg text-neutral-950-opacity-80">{title}</h4>
          <button type="button" onClick={toggle}>
            <SVG src={closeSvg} className="text-neutral-950-opacity-60 hover:text-neutral-950-opacity-80" />
          </button>
        </div>
      </div>
      <div className="px-8 pb-4">{children}</div>
    </div>
  );
};

Panel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
};

Panel.defaultProps = { title: '', children: null };

export default Panel;
