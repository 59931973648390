import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS } from 'modules/api/constants';

export const fetchStats = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_STATS(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_STATS) cancelFunctions.FETCH_STATS();
  cancelFunctions.FETCH_STATS = cancel;

  const result = await send();

  return result.data;
};

export const fetchDspRevenueMonthChart = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_DSP_REVENUE_MONTH_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_DSP_REVENUE_MONTH_CHART) cancelFunctions.FETCH_DSP_REVENUE_MONTH_CHART();
  cancelFunctions.FETCH_DSP_REVENUE_MONTH_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchDspRevenueDayChart = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_DSP_REVENUE_DAY_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_DSP_REVENUE_DAY_CHART) cancelFunctions.FETCH_DSP_REVENUE_DAY_CHART();
  cancelFunctions.FETCH_DSP_REVENUE_DAY_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchRevenueChart = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_REVENUE_MONTH_ON_MONTH_DAY_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_REVENUE_MONTH_ON_MONTH_DAY_CHART) cancelFunctions.FETCH_REVENUE_MONTH_ON_MONTH_DAY_CHART();
  cancelFunctions.FETCH_REVENUE_MONTH_ON_MONTH_DAY_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchCPMChart = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_REVENUE_CPM_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_REVENUE_CPM_CHART) cancelFunctions.FETCH_REVENUE_CPM_CHART();
  cancelFunctions.FETCH_REVENUE_CPM_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchRevenueTable = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_REVENUE_TABLE(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_REVENUE_TABLE) cancelFunctions.FETCH_REVENUE_TABLE();
  cancelFunctions.FETCH_REVENUE_TABLE = cancel;

  const result = await send();

  return result.data;
};

export const exportRevenueReport = async (query) => {
  const { send } = createRequest({
    url: URLS().EXPORT_REVENUE_REPORT(query),
    method: HTTP_METHODS.GET,
    showLoader: true,
    responseType: 'blob',
  });

  return send();
};
