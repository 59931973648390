import { useDispatch, useSelector } from 'react-redux';

import cx from 'classnames';

import { AuctionModel, DealStatus } from 'components/common/types/Deal.types';
import { isReadOnly } from 'utils/isReadOnly';
import { changeDealData } from 'store/dealManagement/reducer';

const AuctionPriority = () => {
  const dispatch = useDispatch();
  const isEditingDisabled = useSelector((state) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);
  const auctionModel = useSelector((state) => state.dealManagement.commonDeal.auctionModel);
  const isLive = bookingStatusCode === DealStatus.LIVE;
  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled) || isLive;

  const auctionDataList = [
    { indicator: '1st', label: '1st price', value: AuctionModel.FIRST_PRICE },
    { indicator: '2nd', label: '2nd price', value: AuctionModel.SECOND_PRICE },
  ];

  return (
    <div className="bg-neutral-100 space-y-3 rounded-md p-4 w-60">
      {auctionDataList.map(({ label, value, indicator }) => (
        <button
          key={indicator}
          type="button"
          onClick={() => dispatch(changeDealData({ auctionModel: value }))}
          className={cx('flex space-x-4 rounded-md p-2 hover:bg-primary-600-opacity-10 w-full', {
            'bg-primary-900-opacity-5': auctionModel === value,
          })}
          disabled={readOnly}
        >
          <span
            className={cx('self-center sub-header-base', {
              'text-primary-900': auctionModel === value,
            })}
          >
            {indicator}
          </span>
          <span className="body-base">{label}</span>
        </button>
      ))}
    </div>
  );
};

export default AuctionPriority;
