import SVG from 'react-inlinesvg';

import splitSvg from 'assets/icons/split.svg';
import Modal from 'components/patterns/Modal';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';

interface Props {
  showLiveLineModal: boolean;
  setShowLiveLineModal: (state: boolean) => void;
  handleUpdateLiveLine: () => void;
}

const LiveLineDialog = ({
  showLiveLineModal,
  setShowLiveLineModal,
  handleUpdateLiveLine,
}: Props): React.ReactElement => {
  return (
    <Modal
      isOpen={showLiveLineModal}
      icon={<SVG src={splitSvg} className="text-purple" />}
      title="Live Line"
      actionButtons={
        <>
          <Button btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL} onClick={() => setShowLiveLineModal(false)}>
            Cancel
          </Button>
          <Button btnType={ButtonType.PRIMARY} btnShape={ButtonShape.NORMAL} onClick={handleUpdateLiveLine}>
            Confirm
          </Button>
        </>
      }
    >
      <div className="ml-8 body-base text-neutral-950-opacity-60">
        <p className="mb-4">
          Saving these changes will cause this line to split. The previous delivery will stop and go into a Terminated
          status when these changes go live.
        </p>
        <p>Do you want to continue and split the line?</p>
      </div>
    </Modal>
  );
};

export default LiveLineDialog;
