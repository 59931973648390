import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CreativesRenderer = ({ data: { panels } }) => {
  return (
    <div className="flex space-x-2" data-test-id="creative-set-thumbnails">
      {panels?.map(({ creative: { id, thumbnailUrl } }) => (
        <Link key={id} to={`/content-management/previewCreative/${id}`} data-test-id={id}>
          <div
            className="rounded-md border border-transparent bg-cover bg-center bg-no-repeat w-12 h-12"
            style={{
              backgroundImage: `url(${thumbnailUrl})`,
            }}
          />
        </Link>
      ))}
    </div>
  );
};

CreativesRenderer.propTypes = {
  data: PropTypes.shape({
    panels: PropTypes.arrayOf(PropTypes.object),
  }),
};

CreativesRenderer.defaultProps = {
  data: {},
};

export default CreativesRenderer;
