import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface DspFromMediaOwners {
  publisher: string;
  code: string;
  name: string;
}

interface ResellerState {
  availableMediaOwners: string[];
  dspsFromMediaOwners: DspFromMediaOwners[];
}

const initialState: ResellerState = {
  availableMediaOwners: [],
  dspsFromMediaOwners: [],
};

const resellerSlice = createSlice({
  name: 'reseller',
  initialState,
  reducers: {
    changeAvailableMediaOwners: (state: ResellerState, action: PayloadAction<string[]>) => {
      return {
        ...state,
        availableMediaOwners: action.payload,
      };
    },
    changeDspsFromMediaOwners: (state: ResellerState, action: PayloadAction<DspFromMediaOwners[]>) => {
      return {
        ...state,
        dspsFromMediaOwners: action.payload,
      };
    },
  },
});

export const { changeAvailableMediaOwners, changeDspsFromMediaOwners } = resellerSlice.actions;
export default resellerSlice.reducer;
