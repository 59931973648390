import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DealConfig, DealConfigStore } from './dealConfig.types';
import { isAdServerDealConfig } from './utils';

export const initialState: Partial<DealConfigStore> = {
  isAdServer: undefined,
  isDealConfigFetched: false,
  maxAllowedLines: 1,
};

const dealConfigSlice = createSlice({
  name: 'dealConfig',
  initialState,
  reducers: {
    updateDealConfig: (_state: DealConfigStore, action: PayloadAction<DealConfig>) => {
      return {
        ...action.payload,
        maxAllowedLines: isAdServerDealConfig(action.payload)
          ? action.payload.maxAllowedLinesPerDeal
          : action.payload.maxAllowedLines,
        isDealConfigFetched: true,
        isAdServer: action.payload.isAdServer,
      };
    },
    resetDealConfig: () => {
      return initialState;
    },
  },
});

export const { updateDealConfig, resetDealConfig } = dealConfigSlice.actions;
export default dealConfigSlice.reducer;
