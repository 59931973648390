import warningSvg from 'assets/icons/warning.svg';
import { DealItem } from 'components/common/types/DealItem';
import { warningDealStatuses } from 'consts/ooc';
import { useState } from 'react';
import SVG from 'react-inlinesvg';

const OutOfChargeRenderer: React.FC<DealItem> = ({ data }) => {
  if (!data?.includesDischargedFrames) return null;
  if (!data.bookingStatusCode || !warningDealStatuses.includes(data.bookingStatusCode)) return null;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div data-test-id="OutOfChargeRenderer-wrapper">
      <SVG
        onMouseOver={() => setIsTooltipVisible(true)}
        onMouseOut={() => setIsTooltipVisible(false)}
        src={warningSvg}
        className="inline-block text-center leading-tight align-middle text-warning w-5 h-5"
      />
      {isTooltipVisible ? (
        <div data-test-id="OutOfChargeRenderer-tooltip">
          <p className="fixed bg-neutral-50 top-1.5 z-50 left-24 text-left body-sm text-neutral-900 shadow-sm rounded-[6px] p-2.5">
            Frames within this deal have been marked as out of charge.
            <br /> Reallocate your deal to avoid under-pacing.
          </p>
          <div className="invisible bg-neutral-50 fixed left-[92px] top-1/2 -translate-y-1/2 h-2 w-2 before:visible before:shadow-sm before:absolute before:h-2 before:w-2 before:rotate-45 before:bg-inherit before:content-['']" />
        </div>
      ) : null}
    </div>
  );
};
export default OutOfChargeRenderer;
