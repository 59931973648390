import { ICellRendererParams } from 'ag-grid-community';
import minusSvg from 'assets/icons/minus.svg';
import plusSvg from 'assets/icons/plus.svg';
import Button, { Color, Size } from 'lib/Button';
import { useState } from 'react';

type GroupCellRendererType = (params: ICellRendererParams) => JSX.Element;

const GroupCellRenderer: GroupCellRendererType = ({ node, api, value }) => {
  const [isRowExpanded, setIsRowExpanded] = useState(node.expanded);

  const toggleSelectAll = (): void => {
    node.setSelected(!node.isSelected());
  };

  const toggleExpandCollapseRow = (): void => {
    api.setRowNodeExpanded(node, !isRowExpanded);
    setIsRowExpanded((prevState) => !prevState);
  };

  const numberOfVisualUnits = value || node.data.visualUnitIds.length;

  return (
    <div className="flex items-center">
      {!node.data.isNoChildren && (
        <Button
          onClick={toggleExpandCollapseRow}
          svg={isRowExpanded ? minusSvg : plusSvg}
          color={Color.TRANSPARENT}
          size={Size.SMALL}
        />
      )}
      <span className="ml-3 pr-4">{numberOfVisualUnits}</span>
      {isRowExpanded && (
        <Button
          onClick={toggleSelectAll}
          color={Color.TRANSPARENT}
          label={node.isSelected() ? 'Unselect all' : 'Select all'}
        />
      )}
    </div>
  );
};

export default GroupCellRenderer;
