import Header from 'components/common/Header';
import SubNavigation from 'components/common/SubNavigation';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';

const InventoryManagementHeader = () => {
  return (
    <Header
      title="Inventory management"
      subNavigation={
        <SubNavigation
          tabsConfig={[
            {
              id: 1,
              path: '/inventory-management/asset-list',
              isVisible: true,
              name: 'Asset list',
            },
            {
              id: 2,
              path: '/inventory-management/reference-data',
              isVisible: useHasFeatureAccess(FeatureFlags.REFERENCE_DATA),
              name: 'Reference data',
            },
            {
              id: 3,
              path: '/inventory-management/commercial-entities',
              isVisible: true,
              name: 'Commercial entities',
            },
            {
              id: 4,
              path: '/inventory-management/setup',
              isVisible: true,
              name: 'Setup',
            },
          ]}
        />
      }
    />
  );
};

export default InventoryManagementHeader;
