import Tooltip, { TooltipDirection } from 'components/patterns/Tooltip';

type Statuses = {
  initials: string;
  color: string;
  code: string;
  name: string;
};

type StatusRendererProps = {
  value: string;
  data: {
    fileId?: string;
    fileName?: string;
  };
  statuses: Statuses[];
  direction?: string;
  onClick: (fileId: string, fileName: string, code?: string) => void;
};

const StatusRenderer: React.FC<StatusRendererProps> = ({
  value,
  data = {},
  statuses,
  direction = TooltipDirection.RIGHT,
  onClick,
}: StatusRendererProps) => {
  const { fileId = '', fileName = '' } = data;
  const { initials, color, name, code } = statuses.find((status) => status.code === value) ?? {};

  return (
    <Tooltip tooltip={name} direction={direction}>
      <button
        type="button"
        data-test-id="status-renderer-button"
        onClick={() => onClick(fileId, fileName, code)}
        className={`text-neutral-50 rounded-full leading-tight align-middle py-1 px-2 w-9 body-sm ${color}
        }`}
      >
        {initials}
      </button>
    </Tooltip>
  );
};

export default StatusRenderer;
