import Input from 'lib/Input';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { onFilterChange } from 'store/pages/contentManagement/creativeSetDetails/reducer';

type FilterFrameCodeProps = {
  placeholder?: string;
};

const FilterFrameCode: React.FC<FilterFrameCodeProps> = ({ placeholder = 'Select frame code' }) => {
  const dispatch = useDispatch();
  const frameCode = useSelector(
    (state: RootState) => state.pages.contentManagement.creativeSetDetails.filters.frameCode,
  );

  return (
    <Input
      dataTestId="frame-code"
      name="frameCode"
      value={frameCode}
      placeholder={placeholder}
      onChange={(value) => {
        dispatch(onFilterChange({ frameCode: value as string }));
      }}
      debounceTime={1000}
    />
  );
};

export default FilterFrameCode;
