import { useSelector } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { formatPercent } from 'modules/I18N';
import SVG from 'react-inlinesvg';
import arrowUpSvg from 'assets/icons/arrow_up.svg';
import arrowDownSvg from 'assets/icons/arrow_down.svg';

const PercentageDifference = ({ percentageDifference }) => {
  const { localeCode } = useSelector((state) => state.publisher.configuration);

  switch (true) {
    case Number.isNaN(Number(percentageDifference)):
    case percentageDifference === 0:
      return <span>-</span>;
    case percentageDifference === Infinity:
      return <span>N/A</span>;
    default:
      return (
        <span
          className={cx(
            'sub-header-base',
            { 'text-danger': percentageDifference <= 0 },
            { 'text-success': percentageDifference > 0 },
          )}
        >
          <span className="mr-1">
            <SVG src={percentageDifference > 0 ? arrowUpSvg : arrowDownSvg} />
          </span>
          {formatPercent({ value: percentageDifference, localeCode })}
        </span>
      );
  }
};

PercentageDifference.propTypes = {
  percentageDifference: PropTypes.number.isRequired,
};

export default PercentageDifference;
