import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import classNames from 'classnames/bind';
import Button, { ButtonType, ButtonShape } from 'components/patterns/Button';
import closeSvg from 'assets/icons/close.svg';
import styles from './Chip.pcss';

const cx = classNames.bind(styles);

const Chip = ({ item, labelKey, excludeKey, onIncludeExcludeChange, onRemove, isDisabled }) => {
  return (
    <div
      className={cx('body-base', 'chip')}
      aria-label={item[labelKey]}
      data-test-id={`chip-container-${item[labelKey]?.replace(/\s/g, '-').toLowerCase()}`}
    >
      <div className={cx('chip-content')}>{item[labelKey] ? item[labelKey] : ''}</div>
      <div className="float-right pl-2 pr-2 flex">
        {excludeKey && (
          <button
            aria-label="chip-include"
            type="button"
            className={cx('chipBtn', { 'chipBtn-active': item[excludeKey] })}
            onClick={() => onIncludeExcludeChange(item, true)}
          >
            +
          </button>
        )}
        {excludeKey && (
          <button
            aria-label="chip-exclude"
            type="button"
            className={cx('chipBtn', { 'chipBtn-active': !item[excludeKey] })}
            onClick={() => onIncludeExcludeChange(item, false)}
          >
            -
          </button>
        )}
      </div>
      {!isDisabled && (
        <span className={cx('chip-remove')}>
          <Button
            btnShape={ButtonShape.CIRCLE}
            btnType={ButtonType.CLOSE}
            onClick={() => onRemove(item)}
            dataTestId={`chip-remove-${item[labelKey]?.replace(/\s/g, '-').toLowerCase()}`}
            label="chip-remove"
          >
            <SVG src={closeSvg} />
          </Button>
        </span>
      )}
    </div>
  );
};

Chip.propTypes = {
  labelKey: PropTypes.string.isRequired,
  item: PropTypes.shape({}),
  excludeKey: PropTypes.string,
  onIncludeExcludeChange: PropTypes.func,
  onRemove: PropTypes.func,
  isDisabled: PropTypes.bool,
};

Chip.defaultProps = {
  item: {},
  excludeKey: '',
  onIncludeExcludeChange: () => undefined,
  onRemove: () => undefined,
  isDisabled: false,
};

export default Chip;
