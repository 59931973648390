import Status from 'lib/Status';
import { CreativeStatus } from 'components/common/types/Creative.types';
import { StatusIconProps } from './StatusIcon.types';

const StatusIcon: React.FC<StatusIconProps> = ({ statusCode }) => {
  const statusCodeToBadgeConfigMap = {
    [CreativeStatus.APPROVED]: <Status initial="A" backgroundColour="bg-green" />,
    [CreativeStatus.PENDING]: <Status initial="P" backgroundColour="bg-yellow" />,
    [CreativeStatus.REJECTED]: <Status initial="R" backgroundColour="bg-pinkRed" />,
    [CreativeStatus.FLAGGED]: <Status initial="L" backgroundColour="bg-neutral-900" />,
    [CreativeStatus.INACTIVE]: <Status initial="I" backgroundColour="bg-pinkRed" />,
    [CreativeStatus.UPLOADED]: <Status initial="U" backgroundColour="bg-primary" />,
    [CreativeStatus.DELETED]: <Status initial="D" backgroundColour="bg-pinkRed" />,
    [CreativeStatus.RESTORED]: <Status initial="R" backgroundColour="bg-green" />,
    [CreativeStatus.SENT_FOR_APPROVAL]: <Status initial="S" backgroundColour="bg-yellow" />,
  };

  return <>{statusCodeToBadgeConfigMap[statusCode]}</>;
};

export default StatusIcon;
