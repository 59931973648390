import { createSelector } from '@reduxjs/toolkit';
import { Creative } from 'components/common/types/Creative.types';
import { FrameTag } from 'components/common/types/FrameTag.types';
import { RootState } from 'store';

export const getCreativeFromState = (state: { creative: Creative }): Creative => state.creative;

export const getCreativeFrameTags = (state: { creative: Creative }): FrameTag[] => state.creative.frameTags;

type DealsByOrigin = {
  inheritedDealIds: { code: string; name: string }[];
  ownDealIds: { code: string; name: string }[];
};

export const getDealsByOrigin = createSelector(
  [(state: RootState) => state.creative, (state: RootState) => state.environment],
  (creativeState: Creative, environmentState): DealsByOrigin => {
    const { market } = creativeState;
    const { environmentId } = environmentState;
    const currentMarket = market?.find(({ environment }) => environment === environmentId);

    return currentMarket?.deal
      ? currentMarket.deal.reduce(
          (acc, cur) => {
            const dealEntry = { code: cur.id, name: cur.dealId };
            if (cur.inherited) {
              acc.inheritedDealIds.push(dealEntry);
            } else {
              acc.ownDealIds.push(dealEntry);
            }
            return acc;
          },
          { ownDealIds: [], inheritedDealIds: [] } as DealsByOrigin,
        )
      : ({ ownDealIds: [], inheritedDealIds: [] } as DealsByOrigin);
  },
);
