import Input from 'lib/Input';
import { FilterCreativeIdProps } from './FilterCreativeId.types';

const FilterCreativeId: React.FC<FilterCreativeIdProps> = ({ creativeId = '', changeFilters }) => {
  return (
    <Input
      dataTestId="creative-id-input"
      name="creativeId"
      value={creativeId}
      placeholder="Creative ID"
      onChange={(value: string) => changeFilters({ creative: value })}
    />
  );
};

export default FilterCreativeId;
