import PropTypes from 'prop-types';

const TimezoneFormat = ({ data: { name, flag } }) => {
  return (
    <div className="flex items-center">
      <img src={`/assets/img/flags/${flag}`} alt={name} className="w-5 h-3.5 mr-2" />
      <span>{name}</span>
    </div>
  );
};

TimezoneFormat.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    flag: PropTypes.string,
  }).isRequired,
};

export default TimezoneFormat;
