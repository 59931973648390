import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { formatCurrency, formatNumber } from 'modules/I18N';
import { transformColumnChartData } from 'utils/formatters';
import { dateCurrencyChartOptions, dealCurrencyChartOptions } from 'utils/chartOptions';
import HeroChart from '../HeroChart';

const Revenue = ({ revenueData, isDealSelected }) => {
  const { currencyCode, localeCode } = useSelector((state) => state.publisher.configuration);
  const { totalRevenue, ecpm, revenueGraph, totalDeals } = revenueData;

  return (
    <HeroChart
      dataTestId="revenue-chart"
      chartTitle="Revenue"
      chartData={transformColumnChartData(revenueGraph, isDealSelected ? 'date' : 'dealId', 'revenue')}
      table={[
        { title: 'Total deals', value: formatNumber({ value: totalDeals, localeCode }) },
        { title: 'Gross revenue', value: formatCurrency({ value: totalRevenue, currencyCode, localeCode }) },
        { title: 'eCPM', value: formatCurrency({ value: ecpm, currencyCode, localeCode }) },
      ]}
      isSplineChart={isDealSelected}
      chartOptions={
        isDealSelected
          ? dateCurrencyChartOptions({ currencyCode, localeCode })
          : dealCurrencyChartOptions({ currencyCode, localeCode })
      }
    />
  );
};

Revenue.propTypes = {
  revenueData: PropTypes.object,
  isDealSelected: PropTypes.bool,
};

Revenue.defaultProps = {
  revenueData: {},
  isDealSelected: false,
};

export default Revenue;
