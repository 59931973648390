import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

const LinkItem = ({ name, icon, link }) => {
  return (
    <li className="border border-transparent rounded-md hover:bg-neutral-100 hover:border-neutral-300">
      <a
        href={link}
        className="flex items-center py-2 px-3 text-neutral-950-opacity-60 hover:text-neutral-950-opacity-80"
        data-test-id={`link-item-${link.replace('#', '')}`}
      >
        <SVG src={icon} className="inline mr-2.5 w-4 h-4 text-neutral-950-opacity-60" />
        <span className="sub-header-base">{name}</span>
      </a>
    </li>
  );
};

LinkItem.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default LinkItem;
