import { AgGridReact } from 'ag-grid-react';
import { VisualUnitTableProps } from './VisualUnitTable.types';
import './styles.pcss';
import { useVisualUnitTable } from './useVisualUnitTable';

const VisualUnitTable: React.FC<VisualUnitTableProps> = ({ getSelectedRow }: VisualUnitTableProps) => {
  const { columnDefs, tableData, gridOptions } = useVisualUnitTable({ getSelectedRow });

  return (
    <div className="ag-theme-material visual-unit-table" data-test-id="visual-unit-table">
      <AgGridReact columnDefs={columnDefs} rowData={tableData} gridOptions={gridOptions} rowHeight={36} />
    </div>
  );
};

export default VisualUnitTable;
