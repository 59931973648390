import { formatPercent } from 'modules/I18N';
import { ChartOptions, GetChartData, GetNetValue } from '../BidsRenderer.types';

export const getChartData: GetChartData = ({ bidRequest, bidResponse }) => [
  {
    name: 'Requests',
    y: bidRequest ?? 0,
  },
  {
    name: 'Responses',
    y: bidResponse ?? 0,
  },
];

export const getNetValue: GetNetValue = ({ bidRequest, bidResponse, wonImpressions, localeCode }) => {
  if (bidRequest && bidRequest !== 0 && wonImpressions && bidResponse)
    return formatPercent({ value: wonImpressions / bidResponse, localeCode });
  return formatPercent({ value: 0, localeCode });
};

export const getChartOptions = (): ChartOptions => ({
  chart: {
    width: 60,
    height: 60,
    margin: [0, 0, 0, 0],
    backgroundColor: 'transparent',
  },
  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: false,
        },
      },
      events: {
        click: () => false,
      },
    },
  },
  tooltip: {
    enabled: false,
  },
});
