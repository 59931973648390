import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS } from 'modules/api/constants';
import { CancelFunctions } from 'components/common/types';
import {
  FetchBidRateChartResponse,
  FetchDashboardTableResponse,
  FetchFillRateChartResponse,
  FetchRevenueChartResponse,
  FetchTradedWinRateDataResponse,
  FetchWeightedWinRateChartResponse,
} from 'components/common/types/DealDashboard.types';

export const fetchRevenueChart = async (
  cancelFunctions: CancelFunctions,
  query: string,
): Promise<FetchRevenueChartResponse> => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_REVENUE_CHART(query),
    showLoader: true,
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_REVENUE_CHART) cancelFunctions.FETCH_REVENUE_CHART();
  cancelFunctions.FETCH_REVENUE_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchRevenueCreativeChart = async (cancelFunctions: CancelFunctions, query: string): Promise<unknown> => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_REVENUE_CREATIVE_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_REVENUE_CREATIVE_CHART) cancelFunctions.FETCH_REVENUE_CREATIVE_CHART();
  cancelFunctions.FETCH_REVENUE_CREATIVE_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchBidRateChart = async (
  cancelFunctions: CancelFunctions,
  query: string,
): Promise<FetchBidRateChartResponse> => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_BID_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_BID_CHART) cancelFunctions.FETCH_BID_CHART();
  cancelFunctions.FETCH_BID_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchTradedWinRateChart = async (
  cancelFunctions: CancelFunctions,
  query: string,
): Promise<FetchTradedWinRateDataResponse> => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_TRADED_WIN_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_TRADED_WIN_CHART) cancelFunctions.FETCH_TRADED_WIN_CHART();
  cancelFunctions.FETCH_TRADED_WIN_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchWeightedWinRateChart = async (
  cancelFunctions: CancelFunctions,
  query: string,
): Promise<FetchWeightedWinRateChartResponse> => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_WEIGHTED_WIN_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_WEIGHTED_WIN_CHART) cancelFunctions.FETCH_WEIGHTED_WIN_CHART();
  cancelFunctions.FETCH_WEIGHTED_WIN_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchFillRateChart = async (
  cancelFunctions: CancelFunctions,
  query: string,
): Promise<FetchFillRateChartResponse> => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_FILL_RATE_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_FILL_RATE_CHART) cancelFunctions.FETCH_FILL_RATE_CHART();
  cancelFunctions.FETCH_FILL_RATE_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchDashboardTable = async (
  cancelFunctions: CancelFunctions,
  query: string,
): Promise<FetchDashboardTableResponse> => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_DASHBOARD_TABLE(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_DASHBOARD_TABLE) cancelFunctions.FETCH_DASHBOARD_TABLE();
  cancelFunctions.FETCH_DASHBOARD_TABLE = cancel;

  const result = await send();

  return result.data;
};

export const exportDealsReport = async (query: string): Promise<Blob> => {
  const { send } = createRequest({
    url: URLS().EXPORT_DEALS_REPORT(query),
    method: HTTP_METHODS.GET,
    showLoader: true,
    responseType: 'blob',
  });

  return send();
};
