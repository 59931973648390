import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SVG from 'react-inlinesvg';
import CsvDownload from 'react-json-to-csv';

import { transformTagFrameDetails } from 'utils/apiAdapters';
import { getTagFrameDetails } from 'modules/api/content';
import withCancelRequest from 'components/hocs/withCancelRequest';
import Table, { TableHeight } from 'components/patterns/Table';
import Modal, { ModalSize } from 'components/patterns/Modal';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import eyeSvg from 'assets/icons/eye.svg';
import tagSvg from 'assets/icons/tag.svg';

import styles from './ViewRenderer.pcss';

const cx = classNames.bind(styles);

class ViewRenderer extends Component {
  state = {
    showDialog: false,
    frames: [],
  };

  columnDefs = [
    {
      headerName: 'Frame code',
      field: 'code',
    },
    {
      headerName: 'Postcode',
      field: 'postCode',
    },
    {
      headerName: 'Coordinates',
      cellRenderer: (params) => `${params.data.latitude} Lat </br>${params.data.longitude} Lon`,
    },
    {
      headerName: 'Product format',
      field: 'productFormat',
      cellRenderer: (params) => `${params.value.name}`,
    },
    {
      headerName: 'Environment',
      field: 'environment',
      resizable: false,
    },
  ];

  openDialog = async () => {
    const { data, cancelFunctions } = this.props;

    try {
      const frames = await getTagFrameDetails(cancelFunctions, data.id);

      this.setState({ frames, showDialog: true });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  closeDialog = () => {
    this.setState({ showDialog: false });
  };

  render() {
    const { data } = this.props;
    const { showDialog, frames } = this.state;

    const isDisabled = !data.frameCount;

    return (
      <div>
        <Button
          onClick={this.openDialog}
          btnShape={ButtonShape.CIRCLE}
          btnType={ButtonType.ICON}
          isDisabled={isDisabled}
        >
          <SVG src={eyeSvg} />
        </Button>
        <span
          className={cx('ml-1 align-middle sub-header-base', {
            'text-sky': !isDisabled,
            'text-pinkRed-500 opacity-50': isDisabled,
          })}
        >
          {data.frameCount}
        </span>
        <Modal
          isOpen={showDialog}
          icon={<SVG src={tagSvg} className="text-primary" />}
          title={`${data.group.name} ${data.name} Tag (${data.frameCount})`}
          size={ModalSize.LARGE}
          showCloseButton
          onClose={this.closeDialog}
          actionButtons={
            <CsvDownload
              filename="frames-details.csv"
              data={transformTagFrameDetails(frames)}
              className="py-2.5 px-3.5 bg-primary text-neutral-50 sub-header-base rounded-md"
            >
              <p>Export list</p>
            </CsvDownload>
          }
        >
          <div className={cx('linked-frames')}>
            <Table
              dataTestId="frame-tags-table"
              columnDefs={this.columnDefs}
              rowData={frames}
              tableSize={{ tableHeight: TableHeight.NORMAL }}
              extraGridOptions={{
                enableCellTextSelection: true,
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

ViewRenderer.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  data: PropTypes.shape({
    frameCount: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    group: PropTypes.shape({ name: PropTypes.string }),
  }).isRequired,
};

export default withCancelRequest(ViewRenderer);
