import SVG from 'react-inlinesvg';

import filterSvg from 'assets/icons/filter.svg';
import Card from 'components/patterns/Card';
import { CardTheme } from 'components/patterns/Card/Card.types';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox/MultipleSwitchBox';
import Input from 'lib/Input';
import AsyncSelect from 'components/patterns/AsyncSelect';
import withCancelRequest from 'components/hocs/withCancelRequest';
import { getNetworkGroups } from 'modules/api/referenceData';
import { IN_USE_STATUS } from 'consts/inUseStatus';
import { CancelFunctions } from 'components/common/types';

export type NetworkGroup = {
  code: string;
  name: string;
};

export type Filters = {
  networkGroupCode?: string;
  networkGroupName?: NetworkGroup | null;
  inUse?: boolean | '';
};

interface FilterAreaProps {
  onFilterChange: (filters: Filters) => void;
  filters: Filters;
  cancelFunctions: CancelFunctions;
}

const FiltersArea: React.FC<FilterAreaProps> = ({ onFilterChange, filters, cancelFunctions }) => {
  const loadNetworks = async (_name: string, query: string): Promise<NetworkGroup[]> => {
    try {
      return query.length > 1 ? await getNetworkGroups(cancelFunctions, query) : [];
    } catch (error) {
      return [];
    }
  };

  return (
    <Card cardTheme={CardTheme.WHITE}>
      <div className="flex">
        <div className="self-center mr-8 ml-2.5">
          <SVG src={filterSvg} className="fill-current text-neutral-400" />
        </div>

        <div className="grid grid-cols-3 gap-x-3 flex-1">
          <AsyncSelect
            dataTestId="network-group-name-input"
            name="networkGroupName"
            textKey="name"
            loadData={loadNetworks}
            onSelect={(_name: string, value: NetworkGroup) => onFilterChange({ networkGroupName: value })}
            placeholder="Select network group name"
            selectedValue={filters.networkGroupName}
          />
          <Input
            name="networkGroupCode"
            value={filters.networkGroupCode}
            placeholder="Type network group code"
            onChange={(userInput: string) => onFilterChange({ networkGroupCode: userInput })}
            debounceTime={300}
          />
          <div className="flex justify-end w-min whitespace-nowrap">
            <MultipleSwitchBox
              dataTestId="in-use-select"
              source={[
                { name: 'All', code: '' },
                { name: 'In use', code: IN_USE_STATUS.TRUE },
              ]}
              selectedValue={filters.inUse}
              onChange={(value) => onFilterChange({ inUse: value })}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
export default withCancelRequest(FiltersArea);
