import { useDispatch, useSelector } from 'react-redux';
import ToggleSwitch, { ToggleTheme } from 'components/patterns/ToggleSwitch';
import { changeProgrammaticDealInfo } from 'store/dealManagement/actions';
import { Store } from 'components/common/types/Store.types';
import DspChip from 'components/common/Deal/DspChip';
import { DspSettings } from 'components/common/types/DspSettings.types';
import Input from 'lib/Input';
import { DealStatus } from 'components/common/types/Deal.types';
import { useDspSettings } from 'customHooks/useDspSettings';

const DspSync: React.FC<DspSettings> = ({ theme = 'LIGHT' }) => {
  const dispatch = useDispatch();
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const { dspSeatId, syncWithDsp: isWithDspSync } = useSelector((state: Store) => state.dealManagement.programmatic);
  const { isSelectedDspSupportingSync } = useDspSettings();

  const isToggleDisabled = !(isNewDeal || [DealStatus.PENDING_APPROVAL].includes(bookingStatusCode));

  if (!isSelectedDspSupportingSync) return null;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center h-8">
        {isWithDspSync ? (
          <DspChip theme={theme} label="Deal synced to DSP" testId="sync-with-dsp" />
        ) : (
          <p className="body-base">Sync deal to DSP</p>
        )}
        <ToggleSwitch
          onChange={() => dispatch(changeProgrammaticDealInfo({ syncWithDsp: !isWithDspSync }))}
          isChecked={isWithDspSync}
          isDisabled={isToggleDisabled}
          dataTestId="dsp-sync-switch"
          theme={ToggleTheme[theme]}
        />
      </div>
      {isWithDspSync && (
        <Input
          id="dsp-seat-id"
          label="DSP seat ID"
          isRequired
          placeholder="Type here..."
          value={dspSeatId}
          onBlur={(value) => dispatch(changeProgrammaticDealInfo({ dspSeatId: value?.toString() }))}
          isDisabled={!(isNewDeal || [DealStatus.PENDING_APPROVAL].includes(bookingStatusCode))}
        />
      )}
    </div>
  );
};

export default DspSync;
