import classNames from 'classnames/bind';
import SVG from 'react-inlinesvg';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import downloadSvg from 'assets/icons/download.svg';

import { DynamicCreativePreviewProps } from './DynamicCreativePreview.types';
import styles from './DynamicCreativePreview.pcss';

const cx = classNames.bind(styles);

const DynamicCreativePreview: React.FC<DynamicCreativePreviewProps> = ({ thumbnail, downloadURL, readOnly }) => (
  <>
    <img className="shadow-imageVideo max-w-full my-0 mx-auto" src={thumbnail} alt="dynamic-creative" />
    <div className="top-imageOverlay absolute w-full text-center">
      <div className="text-neutral-50 sub-header-base mb-2">File name</div>
      <div className="text-neutral-50 mb-3 pb-2 block">
        <span className="max-w-1/2 overflow-hidden inline-block overflow-ellipsis whitespace-nowrap m-auto">
          {downloadURL.split('/').pop()}
        </span>
        <span className="overflow-auto inline-block">.zip</span>
      </div>
      <a className={cx(styles.dynamicCreativeButton)} href={downloadURL}>
        <Button btnShape={ButtonShape.CIRCLE} btnType={ButtonType.SUCCESS} isDisabled={readOnly}>
          <SVG src={downloadSvg} />
        </Button>
      </a>
    </div>
  </>
);

export default DynamicCreativePreview;
