import uniqueId from 'lodash/uniqueId';

import { DOUBLE_DASH } from 'consts/placeholders';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';

import TimeModelInfo from './TimeModelInfo';
import { Button as ReadMoreButton } from '../ReadMoreSections';
import { TimeModelsProps } from './TimeModels.types';

const TimeModels: React.FC<TimeModelsProps> = ({ timeModels, toggleReadMore, maxRecordsToShow }) => {
  return (
    <div>
      <h2 className="sub-header-base mb-8">Time models</h2>
      <div className="pl-2" data-test-id="time-models">
        <Timeline>
          {timeModels && timeModels.length > 0 ? (
            timeModels
              .slice(0, maxRecordsToShow)
              .map((model) => <TimeModelInfo key={uniqueId('timeModel_')} model={model} />)
          ) : (
            <TimelineItem title={<p className="body-base">{DOUBLE_DASH}</p>} />
          )}
        </Timeline>
        {timeModels && timeModels.length > 3 && <ReadMoreButton onClick={toggleReadMore} />}
      </div>
    </div>
  );
};

export default TimeModels;
