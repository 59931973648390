import { useState, useRef } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import plusSvg from 'assets/icons/plus.svg';
import { useOutsideClick } from 'customHooks/useOutsideClick';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import Search from 'components/patterns/Search';
import selectListSvg from 'assets/icons/select_list.svg';

import type { Option } from 'components/common/types/DropdownItem.types';
import DropdownItem from './DropdownItem';
import { useDropdownButtonPosition } from './useDropdownButtonPosition';

interface MultiLevelDropdownProps {
  options: Option[];
  value?: number[] | string[];
  onChangeItem?: (node: Option, checked: boolean) => void;
  onRemoveAll?: () => void;
  isDisabled?: boolean;
  dataTestId?: string;
  isPositionFixed?: boolean;
  fixedPositionContainerId?: string;
  isScrollEnabled?: boolean;
}

export const getFilteredOptions = (options: Option[], search: string): Option[] => {
  const filteredOptions: Option[] = [];

  options.forEach((option) => {
    const { name, children } = option;

    if (name.toLowerCase().includes(search.toLowerCase())) {
      filteredOptions.push(option);
    } else if (children) {
      const childFilteredOptions = getFilteredOptions(children, search);

      if (childFilteredOptions.length) {
        filteredOptions.push({ ...option, children: childFilteredOptions });
      }
    }
  });

  return filteredOptions;
};

const filterOptions = (options: Option[], search: string): Option[] => {
  if (!search || search.length < 3) return options;

  return getFilteredOptions(options, search);
};

const MultiLevelDropdown = ({
  options,
  value,
  onChangeItem,
  onRemoveAll,
  isDisabled,
  dataTestId,
  isPositionFixed,
  fixedPositionContainerId,
  isScrollEnabled,
}: MultiLevelDropdownProps): JSX.Element => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<string | number | null>(null);
  const [search, setSearch] = useState('');
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const dropdownButtonRef: React.MutableRefObject<HTMLButtonElement | null> = useRef(null);
  const dropdownPopoverRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const multiLevelDropdownId = useRef(uuidv4());

  useOutsideClick(dropdownRef, () => {
    setOpenSubMenu(null);
    setIsDropdownOpen(false);
  });

  const filteredOptions = filterOptions(options, search);

  const { dropdownPopoverPositionTop } = useDropdownButtonPosition({
    triggerElement: dropdownButtonRef,
    popoverElement: dropdownPopoverRef,
    mainContainerId: fixedPositionContainerId || '',
  });

  return (
    <div className="relative flex items-center flex-col" ref={dropdownRef} data-test-id={dataTestId}>
      <button
        data-test-id="multi-level-dropdown-btn"
        aria-label="dropdown-open"
        type="button"
        className={cx('border border-solid text-center border-neutral-300 rounded-md p-2 hover:bg-neutral-100', {
          'readOnly readOnlyOpacity': isDisabled,
        })}
        onClick={() => {
          setIsDropdownOpen((prevState) => !prevState);
        }}
        disabled={isDisabled}
        ref={dropdownButtonRef}
      >
        <SVG className="fill-current text-neutral-950-opacity-50 w-4 h-4" src={plusSvg} />
      </button>

      <div className={cx({ invisible: !isDropdownOpen })} data-test-id="multi-level-dropdown-popover">
        <div className="absolute border-4 top-8 z-50 border-arrowTop text-neutral-100" />
        <div
          className={cx('left-0 z-51 min-w-62.5 bg-neutral-100 rounded-md shadow-dropdownMenu', {
            'fixed left-20 mt-1': isPositionFixed,
            'top-10 absolute': !isPositionFixed,
          })}
          id={multiLevelDropdownId.current}
          style={isPositionFixed ? { top: `${dropdownPopoverPositionTop}px` } : {}}
          ref={dropdownPopoverRef}
        >
          <div className="flex justify-between items-center py-3 px-5 border-b border-neutral-300">
            <Search value={search} placeholder="Search..." onChange={(e) => setSearch(e.target.value)} />
            <div className="flex items-center space-x-2">
              <Button label="remove-all" btnShape={ButtonShape.CIRCLE} btnType={ButtonType.ICON} onClick={onRemoveAll}>
                <SVG className="fill-current text-neutral-400 hover:text-primary" src={selectListSvg} />
              </Button>
            </div>
          </div>
          {filteredOptions.length ? (
            <ul
              className={cx('py-3', { 'max-h-72 overflow-y-auto overflow-x-hidden': isScrollEnabled })}
              aria-label={`${multiLevelDropdownId.current}-options-list`}
            >
              {filteredOptions.map((option) => (
                <DropdownItem
                  multiLevelDropdownId={multiLevelDropdownId.current}
                  option={option}
                  key={`${multiLevelDropdownId.current}-${option.code}`}
                  setIsSubMenuOpen={(code) => {
                    setOpenSubMenu(code);
                  }}
                  isSubMenuOpen={openSubMenu === option.code}
                  isSelectable={option.selectable}
                  selectedItems={value}
                  onChangeSelectedItem={onChangeItem}
                  isPositionFixed={isPositionFixed}
                  depth={isScrollEnabled ? 0 : 1}
                />
              ))}
            </ul>
          ) : (
            <p className="text-center px-5 py-2.5 sub-header-base">No results found</p>
          )}
        </div>
      </div>
    </div>
  );
};

MultiLevelDropdown.defaultProps = {
  value: [],
  onChangeItem: () => null,
  onRemoveAll: () => null,
  isDisabled: false,
  isPositionFixed: false,
  dataTestId: '',
  fixedPositionContainerId: '',
  isScrollEnabled: false,
};

export default MultiLevelDropdown;
