import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Store } from 'components/common/types/Store.types';
import ToggleSwitch from 'components/patterns/ToggleSwitch';
import AssignCreativeModal from './AssignCreativeModal';
import Panel from './Panel';

const PanelSet: React.FC = () => {
  const { panels, isNewSet } = useSelector((store: Store) => store.pages.contentManagement.creativeSetDetails);
  const [isCreateSequenceEnabled, setIsCreateSequenceEnabled] = useState(false);

  const panelsSummary = panels.reduce(
    (summary, panel) => {
      summary.numberOfFrames += panel.count;
      summary.appliedCreatives += panel.creative ? panel.count : 0;

      return summary;
    },
    {
      numberOfFrames: 0,
      appliedCreatives: 0,
    },
  );

  const toggleSequence = (): void => setIsCreateSequenceEnabled((prevState) => !prevState);

  return (
    <div data-test-id="panel-set" className="mt-7 mb-2 flex justify-center">
      <div className="min-w-0">
        <div className="flex gap-x-4 pb-4 overflow-x-scroll">
          {panels.map((panel) => (
            <div className="flex gap-x-4" data-test-id={`panel-set-${panel.id}`} key={`panel-set-${panel.id}`}>
              {Array(panel.count)
                .fill({})
                .map((_, frameIndex) => (
                  <Panel
                    dataTestId={`panel-${frameIndex}`}
                    frameIndex={frameIndex}
                    isNewSet={isNewSet}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`panel-${frameIndex}`}
                    panel={panel}
                    isCreateSequenceEnabled={isCreateSequenceEnabled}
                  />
                ))}
            </div>
          ))}
        </div>
        <div className="mt-2 body-base text-essential-secondary text-right flex justify-between">
          <div className="flex justify-between space-x-2">
            <ToggleSwitch
              dataTestId="create-sequence-toggle"
              isChecked={isCreateSequenceEnabled}
              onChange={toggleSequence}
              isDisabled
            />
            <span>Create sequence</span>
          </div>
          <div>{`${panelsSummary.appliedCreatives} of ${panelsSummary.numberOfFrames} creatives uploaded`}</div>
        </div>
      </div>
      <AssignCreativeModal />
    </div>
  );
};

export default PanelSet;
