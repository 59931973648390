import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupData } from 'modules/api/lookups';
import { Store } from 'components/common/types/Store.types';
import MultiSelect from 'components/patterns/MultiSelect';
import Chips from 'components/patterns/Chips';
import { setSelectedEnvironmentChannel, changeDealCurrentLineData, resetProperty } from 'store/dealManagement/reducer';
import { DealStatus, Environment as EnvironmentItem } from 'components/common/types/Deal.types';
import { isReadOnly } from 'utils/isReadOnly';
import { getResellerDealMediaOwners } from 'store/reseller/selectors';
import { Label } from 'lib/Label/Label';
import { EnvironmentProps } from './Environment.types';

type EnvironmentListItem = EnvironmentItem & {
  disabled: boolean;
};

const Environment: React.FC<EnvironmentProps> = ({ label, popoverPosition }) => {
  const dispatch = useDispatch();
  const resellerMediaOwners = useSelector(getResellerDealMediaOwners);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const selectedEnvironmentChannel = useSelector((state: Store) => state.dealManagement.selectedEnvironmentChannel);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const environments = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.environments);
  const terminated = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.terminated);

  const [availableEnvironments, setAvailableEnvironments] = useState<EnvironmentListItem[]>([]);

  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled, terminated) && !isPendingReservation;

  const onAddEnvironment = (items: EnvironmentListItem[]): void => {
    if (items.length) {
      const { audienceCategoryGroupCode, dayPartGroupCode } = items[0];
      dispatch(setSelectedEnvironmentChannel({ audienceCategoryGroupCode, dayPartGroupCode }));
    } else {
      dispatch(resetProperty('selectedEnvironmentChannel'));
    }
    dispatch(changeDealCurrentLineData({ environments: items.map(({ disabled, ...item }) => item) }));
  };

  const onRemoveEnvironment = (item: EnvironmentListItem): void => {
    if (environments.length === 1 && isNewDeal) {
      dispatch(resetProperty('selectedEnvironmentChannel'));
    }
    const newEnvironments = environments.filter(({ code }) => code !== item.code);

    dispatch(changeDealCurrentLineData({ environments: newEnvironments }));
  };

  const onRemoveAllEnvironments = (): void => {
    dispatch(changeDealCurrentLineData({ environments: [] }));
  };

  const handleAvailableChannels = (): void => {
    if (environments.length && availableEnvironments.length) {
      const selectedEnv = availableEnvironments.find((env) => env.code === environments[0].code) || {};
      const { audienceCategoryGroupCode, dayPartGroupCode } = selectedEnv as EnvironmentItem;
      if (audienceCategoryGroupCode && dayPartGroupCode) {
        dispatch(setSelectedEnvironmentChannel({ audienceCategoryGroupCode, dayPartGroupCode }));
      }
    }
  };

  useEffect(() => {
    const environmentList = availableEnvironments.map((env) => {
      const disabled = selectedEnvironmentChannel
        ? !!(
            selectedEnvironmentChannel.audienceCategoryGroupCode !== env.audienceCategoryGroupCode ||
            selectedEnvironmentChannel.dayPartGroupCode !== env.dayPartGroupCode
          )
        : false;
      return { ...env, disabled };
    });
    setAvailableEnvironments(environmentList);
  }, [selectedEnvironmentChannel]);

  useEffect(() => {
    const getEnvironments = async (): Promise<void> => {
      try {
        const result = await getLookupData({ cancelFunctions: {}, lookupURLName: 'environment', query: '' });

        setAvailableEnvironments(result);
        handleAvailableChannels();
      } catch {
        setAvailableEnvironments([]);
      }
    };

    getEnvironments();
  }, [resellerMediaOwners]);

  useEffect(() => handleAvailableChannels(), [environments]);

  return (
    <div data-test-id="environment">
      {label && <Label label={label} isRequired />}
      <div className="flex flex-row">
        <div className="mr-3">
          <MultiSelect
            dataTestId="environment-input"
            items={availableEnvironments}
            selectedItems={environments}
            onSelect={onAddEnvironment}
            onClearAll={onRemoveAllEnvironments}
            isDisabled={readOnly}
            containerPosition={popoverPosition}
          />
        </div>
        {environments.length > 0 && (
          <Chips
            dataList={environments}
            dataTestId="selected-environment"
            idKey="code"
            labelKey="name"
            onRemove={onRemoveEnvironment}
            isClearAllVisible={false}
            isDisabled={readOnly}
          />
        )}
      </div>
    </div>
  );
};

export default Environment;
