import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS } from 'modules/api/constants';
import queryString from 'query-string';
import camelCaseKeys from 'camelcase-keys';

export const fetchAssetTable = async (cancelFunctions, data, queryObj) => {
  const query = queryString.stringify(queryObj, { skipEmptyString: true, arrayFormat: 'comma' });

  const { send, cancel } = createRequest({
    url: URLS().FETCH_ASSET_TABLE(query),
    method: HTTP_METHODS.POST,
    data,
  });

  if (cancelFunctions.FETCH_ASSET_TABLE) cancelFunctions.FETCH_ASSET_TABLE();
  cancelFunctions.FETCH_ASSET_TABLE = cancel;

  const result = await send();
  const totalElements = parseInt(result.headers['total-elements']);

  return {
    data: result.data,
    totalElements: Number.isNaN(totalElements) ? result.data.length : totalElements,
  };
};

export const getAssetById = async (cancelFunctions, id) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_ASSET_BY_ID(id),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  if (cancelFunctions.FETCH_ASSET_BY_ID) cancelFunctions.FETCH_ASSET_BY_ID();
  cancelFunctions.FETCH_ASSET_BY_ID = cancel;

  const result = await send();

  return camelCaseKeys(result.data, { deep: true });
};
