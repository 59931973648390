import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './Sidebar.pcss';

const cx = classNames.bind(styles);

const Sidebar: React.FC = () => {
  const getActiveStyles = ({ isActive }: { isActive: boolean }): string =>
    isActive ? cx('text-neutral-950-opacity-80', 'active-link') : 'text-neutral-950-opacity-50';

  return (
    <div className="bg-neutral-200 h-mainContent w-full pl-8 py-7" data-test-id="reference-data-sidebar">
      <h3 className="sub-header-lg text-neutral-950-opacity-80 mb-14">Reference data</h3>
      <p className="section-header text-neutral-950-opacity-60 mb-4">Networks</p>
      <nav className="flex flex-col space-y-2">
        <NavLink to="network-groups" className={getActiveStyles}>
          <p className="sub-header-base w-52 py-2 px-4 rounded-md truncate hover:bg-primary-900-opacity-5">
            Network groups
          </p>
        </NavLink>
        <NavLink to="network-price-definitions" className={getActiveStyles}>
          <p className="sub-header-base w-52 py-2 px-4 rounded-md truncate hover:bg-primary-900-opacity-5">
            Network price definitions
          </p>
        </NavLink>
        <NavLink to="networks" className={getActiveStyles}>
          <p className="sub-header-base w-52 py-2 px-4 rounded-md truncate hover:bg-primary-900-opacity-5">Networks</p>
        </NavLink>
      </nav>
    </div>
  );
};

export default Sidebar;
