import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Timeline.pcss';

const cx = classNames.bind(styles);

const Timeline = ({ children }) => {
  return <div className={cx('timeline')}>{children}</div>;
};

Timeline.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Timeline;
