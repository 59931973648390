import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { removeAllCreatives, resetCreativeSetDetails } from 'store/pages/contentManagement/creativeSetDetails/reducer';

import Header from './Header';

const mapStateToProps = (state) => ({
  isNewSet: state.pages.contentManagement.creativeSetDetails.isNewSet,
  isPanelView: state.pages.contentManagement.creativeSetDetails.isPanelView,
  code: state.pages.contentManagement.creativeSetDetails.code,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ removeAllCreatives, resetCreativeSetDetails }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
