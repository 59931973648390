import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import Button, { ButtonShape, ButtonType, ButtonSize } from 'components/patterns/Button';
import Table, { TableHeight } from 'components/patterns/Table';
import downloadSvg from 'assets/icons/download.svg';
import { download } from 'utils/download';

export const FILE_TYPE = {
  XLSX: 'xlsx',
  ZIP: 'zip',
};

const TableWithExport = ({ columnDefs, rowData, onExportTable, dataTestId, fileType }) => {
  const onExport = async () => {
    const getFilename = (file) => file.match(/filename=(.*)/)?.[1] || '';

    try {
      const result = await onExportTable();
      download(new Blob([result.data]), getFilename(result.headers['content-disposition']));
    } catch {} // eslint-disable-line no-empty
  };

  return (
    <div data-test-id={dataTestId} className="relative">
      <div className="absolute z-10 rounded-xl right-px top-px pr-4 pt-2 bg-neutral-200 h-12">
        <Button
          onClick={onExport}
          btnType={ButtonType.PRIMARY}
          btnShape={ButtonShape.NORMAL}
          btnSize={ButtonSize.SMALL}
          dataTestId="export-button-in-table"
        >
          <SVG src={downloadSvg} className="fill-current w-3.5" />
          <span className="sub-header-base">{fileType === FILE_TYPE.ZIP ? 'reports' : fileType}</span>
        </Button>
      </div>
      <Table
        columnDefs={columnDefs}
        rowData={rowData}
        extraGridOptions={{
          enableCellTextSelection: true,
          domLayout: 'autoHeight',
          pagination: true,
          paginationPageSize: 20,
        }}
        tableSize={{ tableHeight: TableHeight.AUTO }}
      />
    </div>
  );
};

TableWithExport.propTypes = {
  dataTestId: PropTypes.string,
  columnDefs: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
  onExportTable: PropTypes.func.isRequired,
  fileType: PropTypes.string,
};

TableWithExport.defaultProps = {
  dataTestId: '',
  fileType: FILE_TYPE.XLSX,
};

export default TableWithExport;
