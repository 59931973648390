import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Button.pcss';

const cx = classNames.bind(styles);

export const ButtonShape = {
  CIRCLE: 'btn-circle',
  ROUNDED: 'btn-rounded',
  NORMAL: 'btn-normal',
  LONG: 'btn-long',
  RECTANGLE_ROUNDED: 'btn-rectangle-rounded',
};

export const ButtonType = {
  PRIMARY: 'btn-primary',
  SECONDARY: 'btn-secondary',
  TERTIARY: 'btn-tertiary',
  SUCCESS: 'btn-success',
  WARNING: 'btn-warning',
  DANGER: 'btn-danger',
  CLOSE: 'btn-close',
  ICON: 'btn-icon',
  ACTIVE: 'btn-active',
  TRANSPARENT: 'btn-transparent',
  WHITE: 'btn-white',
  LIGHT: 'btn-light',
  NAVIGATION_TAB: cx('sub-header-base text-neutral-900', 'btn-navigation-tab'),
  NAVIGATION_TAB_ACTIVE: cx('sub-header-base text-neutral-900', 'btn-navigation-tab', 'btn-navigation-tab-active'),
  INK: 'btn-ink',
  PARROT_GREEN: 'btn-lime',
  HOVER_LIGHTER_INK: 'btn-lighter-ink',
  MAGENTA_RED: 'btn-magentaRed',
  TRANSPARENT_WITH_BORDER: 'btn-transparent-border',
};

export const ButtonSize = {
  EXTRA_SMALL: cx('sub-header-sm', 'btn-extra-small'),
  EXTRA_SMALL_ROUND: cx('sub-header-sm', 'btn-extra-small-round'),
  SMALL: 'btn-small',
  MEDIUM: 'btn-medium',
  LARGE: cx('sub-header-base', 'btn-large'),
  FULL_FLEX: cx('sub-header-base', 'btn-full-flex'),
};

const Button = ({ isDisabled, btnShape, btnType, btnSize, onClick, children, dataTestId, label }) => {
  const btnStyles = cx('font-inter sub-header-base', 'btn', btnShape, btnType, btnSize, {
    'btn-disabled': isDisabled,
  });

  return (
    <button
      aria-label={label}
      data-test-id={dataTestId}
      type="button"
      onClick={onClick}
      className={btnStyles}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  dataTestId: PropTypes.string,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  btnShape: PropTypes.string,
  btnType: PropTypes.string,
  btnSize: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  label: '',
  dataTestId: '',
  isDisabled: false,
  btnShape: ButtonShape.ROUNDED,
  btnType: '',
  btnSize: ButtonSize.MEDIUM,
  children: null,
  onClick: () => undefined,
};

export default Button;
