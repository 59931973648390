import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS } from 'modules/api/constants';

export const fetchCreativesFromAllMarkets = async (cancelFunctions, filters = '', extraQuery = '') => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_CREATIVES(`${filters}${extraQuery}`),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  if (cancelFunctions.FETCH_CREATIVES) cancelFunctions.FETCH_CREATIVES();
  cancelFunctions.FETCH_CREATIVES = cancel;

  const result = await send();

  return result.data;
};

export const getAllCompatibleMarkets = async (cancelFunctions, dspsCodes) => {
  const { send, cancel } = createRequest({
    url: URLS().MARKET_LOOKUP_API(`dspCode=${dspsCodes.join(',')}`),
    method: HTTP_METHODS.GET,
  });

  cancelFunctions.MARKET_LOOKUP_API = cancel;

  const result = await send();

  return result.data;
};

export const assignMarketsToSelectedCreatives = async (
  cancelFunctions,
  selectedCreatives,
  selectedMarkets,
  commonMarkets,
  // eslint-disable-next-line consistent-return
) => {
  const selectedCreativesIds = selectedCreatives.map((creative) => creative.id);
  const marketsToDelete = selectedMarkets
    .filter(({ market, isSelected }) => commonMarkets.includes(market) && isSelected === false)
    .map(({ market }) => market);

  if (marketsToDelete.length) {
    const { send, cancel } = createRequest({
      url: URLS().UPDATE_CREATIVE_MARKETS(),
      method: HTTP_METHODS.DELETE,
      data: {
        auth0Identifier: marketsToDelete,
        creativeId: selectedCreativesIds,
      },
    });

    cancelFunctions.UPDATE_CREATIVE_MARKETS = cancel;

    await send();
  }

  const marketsToAdd = selectedMarkets
    .filter(({ market, isSelected }) => !commonMarkets.includes(market) && isSelected === true)
    .map(({ market }) => market);

  if (marketsToAdd.length) {
    const { send, cancel } = createRequest({
      url: URLS().UPDATE_CREATIVE_MARKETS(),
      method: HTTP_METHODS.PATCH,
      data: {
        auth0Identifier: marketsToAdd,
        creativeId: selectedCreativesIds,
      },
    });

    cancelFunctions.UPDATE_CREATIVE_MARKETS = cancel;

    await send();
  }

  return true;
};
