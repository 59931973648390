import { ChartSeries } from 'components/common/types/Chart.types';
import { GradientColorObject, PatternObject, SeriesOptionsType } from 'highcharts';
import { FormatTypeEnum, formatDate, formatValue } from 'modules/I18N';

export interface TooltipFormatterContext {
  y: number;
  x: number | Date;
  color: string | GradientColorObject | PatternObject;
  seriesName: string;
  userOptions: SeriesOptionsType & Pick<ChartSeries, 'additionalData'>;
  localeCode: string;
  currencyCode: string;
  customTooltipTitle?: string | number;
}

export function formatChartTooltip(context: TooltipFormatterContext): string {
  const { y, x, color, seriesName, userOptions, localeCode, currencyCode, customTooltipTitle } = context;
  const formatType = userOptions.additionalData?.formatType || FormatTypeEnum.NUMBER;
  const value = formatType === FormatTypeEnum.PERCENT ? y / 100 : y;
  const formattedTooltipTitle = customTooltipTitle ?? formatDate(new Date(x).toISOString(), localeCode);

  return `${formattedTooltipTitle}<br /><span style="color:${color}">\u25CF</span><span class="body-sm">${seriesName}: </span><span class="sub-header-sm">${formatValue(
    value,
    formatType,
    currencyCode,
    localeCode,
  )}</span>`;
}
