import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import Table from 'components/patterns/Table';
import * as roleActions from 'modules/api/role';
import Modal, { ModalSize } from 'components/patterns/Modal';
import withCancelRequest from 'components/hocs/withCancelRequest';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import Card from 'components/patterns/Card';
import { CardTheme, CardType } from 'components/patterns/Card/Card.types';
import Search from 'components/patterns/Search';
import plusSvg from 'assets/icons/plus.svg';
import filterSvg from 'assets/icons/filter.svg';
import editSvg from 'assets/icons/edit.svg';
import binSvg from 'assets/icons/bin.svg';
import workSvg from 'assets/icons/work.svg';
import Role from '../Role';
import { ActionRenderer, ViewRenderer } from '../GridCellRenderer';

class Roles extends React.Component {
  roleTableApi;

  setRoleGridApi = (tableApi) => {
    this.roleTableApi = tableApi;
  };

  isExternalFilterPresent = () => {
    const { filterRoleText } = this.state;
    return !!filterRoleText;
  };

  externalFilterPass = (node) => {
    const { filterRoleText } = this.state;
    if (filterRoleText) {
      return node.data.name.toLowerCase().includes(filterRoleText.toLowerCase());
    }
    return true;
  };

  onEditRole = (role) => {
    this.setState({
      selectedRole: role,
      isEditRoleDialogActive: true,
    });
  };

  onDeleteRole = (role) => {
    this.setState({
      selectedRole: role,
      isDeleteRoleDialogActive: true,
    });
  };

  onConfirmDeleteRole = async (role) => {
    const { cancelFunctions, notifyError, notifySuccess } = this.props;
    try {
      await roleActions.deleteRole(cancelFunctions, role.id);
      notifySuccess({ message: `Role deleted successfully.` });
      this.getRoleList();

      this.setState({
        isDeleteRoleDialogActive: false,
      });
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  columnDefs = [
    {
      headerName: '',
      field: 'userId',
      minWidth: 70,
      maxWidth: 70,
      headerTooltip: 'Action',
      resizable: false,
      cellRenderer: ActionRenderer,
      cellRendererParams: {
        onClick: this.onEditRole,
        icon: editSvg,
        hasPermission: Auth.hasPermission(PermissionsEnum.ROLES_EDIT),
      },
      hide: !Auth.hasPermission(PermissionsEnum.ROLES_EDIT),
    },
    {
      headerName: 'Role',
      field: 'name',
      minWidth: 100,
      maxWidth: 200,
      headerTooltip: 'Role',
    },
    {
      headerName: 'Permissions',
      cellRenderer: ViewRenderer,
      field: 'permissions',
      minWidth: 200,
      headerTooltip: 'Permissions',
      cellRendererParams: {
        textKey: 'name',
      },
    },
    {
      headerName: 'Description',
      field: 'description',
      minWidth: 150,
      maxWidth: 200,
      headerTooltip: 'Description',
      resizable: false,
    },
    {
      headerName: '',
      field: 'userId',
      minWidth: 70,
      maxWidth: 70,
      headerTooltip: 'Action',
      resizable: false,
      cellRenderer: ActionRenderer,
      cellRendererParams: {
        onClick: this.onDeleteRole,
        icon: binSvg,
        hasPermission: Auth.hasPermission(PermissionsEnum.ROLES_DELETE),
      },
      hide: !Auth.hasPermission(PermissionsEnum.ROLES_DELETE),
    },
  ];

  state = {
    filterRoleText: '',
    roleList: [],
    isEditRoleDialogActive: false,
    isDeleteRoleDialogActive: false,
    selectedRole: {
      name: '',
    },
  };

  async componentDidMount() {
    this.getRoleList();
  }

  async getRoleList() {
    const { cancelFunctions, notifyError } = this.props;
    try {
      const roleList = await roleActions.getRoleList(cancelFunctions);
      const permissionList = await roleActions.getPermissionList(cancelFunctions);
      const mappedRoleList = this.mapRolePermission(roleList, permissionList);
      this.setState({ roleList: mappedRoleList });
    } catch (error) {
      notifyError({ message: error.message });
    }
  }

  mapRolePermission = (roleList, permissionList) => {
    return roleList.map((role) => {
      const tempRole = { ...role };
      tempRole.permissions = permissionList.filter((permission) => role.permissions.includes(permission.id));
      return tempRole;
    });
  };

  onCreateRole = () => {
    this.setState({
      selectedRole: {
        name: '',
      },
      isEditRoleDialogActive: true,
    });
  };

  onSaveRole = async (role) => {
    const { cancelFunctions, notifyError, notifySuccess } = this.props;
    try {
      if (this.isRoleValid(role)) {
        if (role.id) {
          // update role
          await roleActions.updateRole(cancelFunctions, role);
          notifySuccess({ message: 'Role updated successfully.' });
        } else {
          // create roles
          await roleActions.createRole(cancelFunctions, role);
          notifySuccess({ message: 'Role created successfully.' });
        }
        this.setState({
          isEditRoleDialogActive: false,
        });
        this.getRoleList();
      } else {
        notifyError({ message: 'Please Enter Mandatory Details.' });
      }
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  isRoleValid = (role) => {
    let isValid = false;
    if (role && role.name && role.description) {
      isValid = true;
    }
    return isValid;
  };

  handleDialogToggle = (dialogName) => {
    const isDialogActive = this.state[dialogName];
    this.setState({
      [dialogName]: !isDialogActive,
    });
  };

  onFilterTextChange = (event) => {
    this.setState({ filterRoleText: event.target.value }, () => {
      this.roleTableApi.onFilterChanged();
    });
  };

  render() {
    const { roleList, filterRoleText, isEditRoleDialogActive, isDeleteRoleDialogActive, selectedRole } = this.state;

    return (
      <>
        <Modal
          isOpen={isEditRoleDialogActive}
          icon={<SVG src={workSvg} className="text-primary" />}
          title={selectedRole.name ? 'Update role' : 'Create new role'}
          size={ModalSize.MEDIUM}
        >
          <Role
            role={selectedRole}
            onSave={this.onSaveRole}
            onCancel={() => {
              this.handleDialogToggle('isEditRoleDialogActive');
            }}
          />
        </Modal>
        <Modal
          isOpen={isDeleteRoleDialogActive}
          icon={<SVG src={binSvg} className="text-pinkRed-500" />}
          title="Delete Role?"
          actionButtons={
            <>
              <Button
                btnType={ButtonType.PRIMARY}
                btnShape={ButtonShape.NORMAL}
                onClick={() => {
                  this.handleDialogToggle('isDeleteRoleDialogActive');
                }}
              >
                Cancel
              </Button>
              <Button
                btnType={ButtonType.DANGER}
                btnShape={ButtonShape.NORMAL}
                onClick={() => {
                  this.onConfirmDeleteRole(selectedRole);
                }}
              >
                Delete
              </Button>
            </>
          }
        >
          <p className="ml-8 body-base text-neutral-950-opacity-60">
            Are you sure you want to delete <strong>{selectedRole.name}</strong>?
          </p>
        </Modal>
        <Card cardType={CardType.FILTERS} cardTheme={CardTheme.WHITE}>
          <div className="row">
            <div className="col-1">
              <div className="filter-card horizontal-vertical-center">
                <SVG src={filterSvg} />
              </div>
            </div>
            <div className="col-9">
              <Search
                value={filterRoleText}
                onChange={this.onFilterTextChange}
                placeholder="Search role (type 3 letters)"
                backgroundColor="bg-neutral-50"
              />
            </div>
            <div className="col-12 col-md-2 text-center">
              {Auth.hasPermission(PermissionsEnum.ROLES_CREATE) && (
                <Button onClick={this.onCreateRole} btnShape={ButtonShape.NORMAL} btnType={ButtonType.PRIMARY}>
                  <SVG src={plusSvg} />
                  <span>Create role</span>
                </Button>
              )}
            </div>
          </div>
        </Card>
        <div className="row">
          <div className="col-12">
            <Table
              columnDefs={this.columnDefs}
              rowData={roleList}
              extraGridOptions={{
                isExternalFilterPresent: this.isExternalFilterPresent,
                doesExternalFilterPass: this.externalFilterPass,
                suppressScrollOnNewData: true,
                enableCellTextSelection: true,
              }}
              getTableApi={this.setRoleGridApi}
            />
          </div>
        </div>
      </>
    );
  }
}

Roles.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  notifySuccess: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
};

export default withCancelRequest(Roles);
