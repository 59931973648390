import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyError, notifySuccess } from 'store/notification/reducer';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import {
  createCreativeSet,
  deleteCreativeSet as deleteCreativeSetRequest,
  fetchCreativeSet,
  updateCreativeSet,
} from 'modules/api/creativeSets';
import {
  transformSetCreateData,
  transformSetDetails,
  transformSetUpdateData,
} from 'components/pages/ContentManagement';
import { CancelFunctions } from 'components/common/types';
import { ThunkApiConfig } from 'components/common/types/Redux.types';
import { Dsp, Advertiser, Brand } from 'components/common/types/Deal.types';
import { Panel } from 'components/common/types/Creative.types';

import { changeIsEditingDisabled, fetchUpdateSetDetails } from './reducer';

type SaveCreativeSetDetailsPayload = {
  cancelFunctions: CancelFunctions;
  data: {
    dsp: Dsp | null;
    advertiser: Advertiser | null;
    brand: Brand | null;
    panels: Panel[];
    code: string;
    visualUnitIds: string[];
  };
  id: string;
  onSuccess: Function;
};

type GetCreativeSetDetailsPayload = {
  cancelFunctions: CancelFunctions;
  id: string;
};

type DeleteCreativeSetDetailsPayload = {
  cancelFunctions: CancelFunctions;
  onSuccess: Function;
  data: {
    id: string;
    code: string;
  };
};

export const saveSetDetails = createAsyncThunk<void, SaveCreativeSetDetailsPayload, ThunkApiConfig>(
  'creativeSetDetails/saveSetDetails',
  async ({ cancelFunctions, data, id, onSuccess }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    if (!data.advertiser?.code || !data.brand?.code) {
      dispatch(
        notifyError({
          message:
            'We have been unable to save your creative set. Please ensure an Advertiser and Brand is added to all creatives before saving a new creative set.',
          timeout: NOTIFICATION_TIMEOUT.LONG,
        }),
      );
      return;
    }

    try {
      dispatch(changeIsEditingDisabled(true));
      const result = id
        ? transformSetDetails(await updateCreativeSet(cancelFunctions, transformSetUpdateData(data), id))
        : transformSetDetails(await createCreativeSet(cancelFunctions, transformSetCreateData(data)));
      dispatch(notifySuccess({ message: `Your Creative set, ${result.code}, has been saved successfully` }));
      dispatch(fetchUpdateSetDetails(result));
      if (onSuccess) onSuccess();
    } catch {
      dispatch(changeIsEditingDisabled(false));
    }
  },
);

export const getSetDetails = createAsyncThunk<void, GetCreativeSetDetailsPayload, ThunkApiConfig>(
  'creativeSetDetails/getSetDetails',
  async ({ cancelFunctions, id }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      dispatch(changeIsEditingDisabled(true));
      const result = transformSetDetails(await fetchCreativeSet(cancelFunctions, id));
      dispatch(fetchUpdateSetDetails(result));
    } catch {
      dispatch(changeIsEditingDisabled(false));
    }
  },
);

export const deleteCreativeSet = createAsyncThunk<void, DeleteCreativeSetDetailsPayload, ThunkApiConfig>(
  'creativeSetDetails/deleteCreativeSet',
  async ({ cancelFunctions, data, onSuccess }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const { id, code } = data;
      dispatch(changeIsEditingDisabled(true));
      await deleteCreativeSetRequest(cancelFunctions, id);
      dispatch(changeIsEditingDisabled(false));
      dispatch(notifySuccess({ message: `Your Creative set, ${code}, has been deleted` }));
      if (onSuccess) onSuccess();
    } catch {
      dispatch(changeIsEditingDisabled(false));
    }
  },
);
