import cx from 'classnames';
import { PropsWithChildren, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import moreSvg from 'assets/icons/more.svg';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { ShowMoreMenuProps } from './ShowMoreMenu.types';

const ShowMoreMenu: React.FC<PropsWithChildren<ShowMoreMenuProps>> = ({
  onToggle,
  children,
  svgClassName,
  isOpen = false,
  align = 'end',
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(isOpen);
  }, [isOpen]);

  const handleOpenClose = (newState: boolean): void => {
    setIsMenuOpen(newState);
    onToggle?.(newState);
  };

  return (
    <div data-test-id="show-more-menu" className="relative w-[24px] h-[24px]">
      <Popover
        containerClassName="z-20 py-1"
        align={align}
        positions={['bottom', 'top']}
        reposition
        isOpen={isMenuOpen}
        onClickOutside={() => handleOpenClose(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="var(--transparent-color)"
            arrowSize={0}
          >
            <div
              className="relative w-36 bg-neutral-50 shadow-md border border-neutral-300 rounded-md flex flex-col p-1"
              data-test-id="show-more-menu-actions-list"
            >
              {children}
            </div>
          </ArrowContainer>
        )}
      >
        <div
          className={cx(
            'w-6 h-6 flex hover:enabled:bg-primary-200 disabled:cursor-not-allowed items-center justify-center sub-header-base text-primary rounded-md',
            {
              'bg-neutral-200': isMenuOpen,
              'bg-transparent border-transparent': !isMenuOpen,
            },
          )}
          data-test-id="show-more-menu-button"
          role="presentation"
          onClick={(e) => {
            e.stopPropagation();
            handleOpenClose(!isMenuOpen);
          }}
        >
          <SVG src={moreSvg} className={cx('h-4 w-4', svgClassName)} />
        </div>
      </Popover>
    </div>
  );
};

export default ShowMoreMenu;
