import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectCreatives } from 'store/pages/contentManagement/creatives/reducer';
import { notifyError } from 'store/notification/reducer';
import { getIsReseller } from 'store/publisher/selectors';
import { getResellerDealMediaOwners } from 'store/reseller/selectors';
import Creatives from './Creatives';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      notifyError,
      selectCreatives,
    },
    dispatch,
  );

const mapStateToProps = (state) => ({
  localeCode: state.publisher.configuration.localeCode,
  isReseller: getIsReseller(state),
  resellerMediaOwners: getResellerDealMediaOwners(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Creatives);
