import React, { FunctionComponent, useEffect, useState } from 'react';
import briefcaseSvg from 'assets/icons/briefcase.svg';
import Card from 'components/patterns/Card';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Badge, { BadgeSize } from 'components/patterns/Badge';
import { getEligibleResellerMediaOwners } from 'modules/api/reseller';

const ResellerEligibleMediaOwners: FunctionComponent = () => {
  const marketId = useSelector((state: RootState) => state.creative.marketId);
  const dealId = useSelector((state: RootState) => state.creative.dealId);
  const [eligibleMediaOwners, setEligibleMediaOwners] = useState<string[]>([]);

  useEffect(() => {
    const getMediaOwners = async (): Promise<void> => {
      try {
        const result = await getEligibleResellerMediaOwners(marketId, {});
        setEligibleMediaOwners(result);
      } catch (error) {
        setEligibleMediaOwners([]);
      }
    };

    if (marketId) {
      getMediaOwners();
    }
  }, [dealId]);

  return (
    <>
      {eligibleMediaOwners.length > 0 && (
        <Card
          icon={briefcaseSvg}
          header="Media owners"
          info="Creative allowed in the following media owners"
          dataTestId="line-mediaOwners"
        >
          {eligibleMediaOwners?.map((mediaOwner) => (
            <span className="mr-1" key={mediaOwner}>
              <Badge color="bg-primary-600-opacity-10" textColor="text-primary-600" size={BadgeSize.NORMAL}>
                <div className="w-full h-full relative block">
                  <span className="inline-block">{mediaOwner}</span>
                </div>
              </Badge>
            </span>
          ))}
        </Card>
      )}
    </>
  );
};

export default ResellerEligibleMediaOwners;
