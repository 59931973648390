export enum Color {
  GREY = 'bg-neutral-950-opacity-5 text-essential-secondary hover:bg-neutral-950-opacity-10',
  PINK = 'bg-pink-650 text-neutral-50',
  PRIMARY = 'bg-primary-900 text-primary-50 hover:bg-primary-700 active:bg-primary-600 shadow-sm',
  SECONDARY = 'bg-neutral-50 border border-neutral-300 text-primary-600 hover:bg-neutral-100 active:bg-neutral-200 disabled:border-none shadow-sm',
  TERTIARY = 'bg-primary-100 text-primary-600 hover:bg-primary-200 active:bg-primary-300 disabled:border-none',
  TRANSPARENT = 'bg-transparent text-primary-600 hover:bg-primary-50 active:bg-primary-200 disabled:border-none',
  DANGER = 'bg-pinkRed-800 text-pinkRed-50 hover:bg-pinkRed-700 active:bg-pinkRed-900',
}

export enum Size {
  FIT = 'w-fit',
  WIDE = 'w-full',
  SMALL = 'p-1',
  MEDIUM = 'p-2',
  LARGE = 'p-3',
  FIT_CONTAINER = 'flex-1 p-3',
}

interface ButtonProps {
  ariaLabel?: string;
  classNames?: string;
  color?: Color;
  dataTestId?: string;
  label?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  size?: Size;
  svg?: string;
  svgClassnames?: string;
  ddActionName?: string;
}

export default ButtonProps;
