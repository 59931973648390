import { SECONDS_IN_MINUTE, SECONDS_IN_HOUR, MINUTES_IN_HOUR } from 'utils/dateFormatUtil';

export const formatSecondsToDuration = (seconds = 0): string => {
  const formatPad = (input: number): string => input.toString().padStart(2, '0');

  return [
    formatPad(Math.floor(seconds / SECONDS_IN_HOUR)),
    formatPad(Math.floor((seconds % SECONDS_IN_HOUR) / MINUTES_IN_HOUR)),
    formatPad(Math.floor(seconds % SECONDS_IN_MINUTE)),
  ]
    .join(':')
    .replace(/^00:/g, '');
};
