import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InventoryManagementAssetList } from 'components/common/types/AssetList.types';

const initialState: InventoryManagementAssetList = {
  frameCode: '',
};

const assetListSlice = createSlice({
  name: 'inventoryManagementAssetList',
  initialState,
  reducers: {
    selectFrameCode: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        frameCode: action.payload,
      };
    },
    resetAssetList: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const { selectFrameCode, resetAssetList } = assetListSlice.actions;
export default assetListSlice.reducer;
