import { useEffect } from 'react';
import { usePageFilters } from 'customHooks/usePageFilters';

export const withPageFilters = (
  WrappedComponent,
  settings = {
    pageName: '',
    defaultValues: {},
  },
) => {
  return (props) => {
    const { filters, changeFilters, resetFilters } = usePageFilters(settings.pageName);

    useEffect(() => {
      changeFilters({ ...settings.defaultValues });
    }, []);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} filters={filters} changeFilters={changeFilters} resetFilters={resetFilters} />;
  };
};
