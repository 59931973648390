import { useDispatch, useSelector } from 'react-redux';
import Session from 'modules/Session';
import {
  addAgency,
  addSpecialist,
  deleteAgency,
  deleteCreativeCommercial,
  deleteSpecialist,
  exportFrameSummary,
  getAdvertisers,
  getAgency,
  getFrameTags,
  getSpecialist,
  updateCreativeCommercial,
} from 'modules/api/content';
import { getCreativeFromState } from 'store/creative/selectors';
import {
  changeProductCategory,
  removeAgency,
  removeSpecialist,
  resetCommercialId,
  setAdvertiser,
  setAgency,
  setBrand,
  setFrameTag,
  setSpecialist,
} from 'store/creative/reducer';
import { setProductCategory } from 'store/creative/actions';
import { notifyError } from 'store/notification/reducer';
import { download } from 'utils/download';
import { Creative } from 'components/common/types/Creative.types';
import { Advertiser, Agency, BrandWithProductCategory } from 'components/common/types/Deal.types';
import { FrameTag } from 'components/common/types/FrameTag.types';
import { CodeNameModel } from 'components/common/types';
import { AsyncLookupHandler, AsyncSelectHandler, LookupHandler } from 'components/patterns/AsyncSelect';
import { useCancelRequest } from 'customHooks/useCancelRequest';

interface UseTaggingRulesReturn {
  creative: Creative;
  handleSelectProductCategory: AsyncSelectHandler<CodeNameModel>;
  handleExportSummary: () => Promise<void>;
  handleLoadAdvertiserLookupData: AsyncLookupHandler<Advertiser>;
  handleLoadAgencyLookupData: AsyncLookupHandler<Agency>;
  handleLoadBrandLookupData: LookupHandler<BrandWithProductCategory>;
  handleLoadFrameTagsLookupData: AsyncLookupHandler<FrameTag>;
  handleLoadSpecialistLookupData: AsyncLookupHandler<CodeNameModel>;
  handleSelectAdvertiser: AsyncSelectHandler<Advertiser>;
  handleSelectAgency: AsyncSelectHandler<CodeNameModel>;
  handleSelectBrand: AsyncSelectHandler<BrandWithProductCategory>;
  handleSelectFrameTag: AsyncSelectHandler<FrameTag>;
  handleSelectSpecialist: AsyncSelectHandler<CodeNameModel>;
}

export const useTaggingRules = (
  creativeId: string,
  commercialId: string,
  onTaggingRulesChange: () => Promise<void>,
): UseTaggingRulesReturn => {
  const dispatch = useDispatch();
  const creative = useSelector(getCreativeFromState);
  const cancelFunctions = useCancelRequest();

  const { advertiser, brand, marketId } = creative;

  const environmentId = Session.getEnvironmentId();

  const handleLoadAdvertiserLookupData: AsyncLookupHandler<Advertiser> = async (_dropdownName, query) => {
    if (query.length < 2) {
      return [];
    }

    try {
      return await getAdvertisers(cancelFunctions, environmentId, query);
    } catch (error) {
      return [];
    }
  };

  const handleLoadBrandLookupData: LookupHandler<BrandWithProductCategory> = (_dropdownName, query) => {
    if (query.length < 2) {
      return [];
    }

    return creative.advertiser?.brands?.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase())) || [];
  };

  const handleLoadAgencyLookupData: AsyncLookupHandler<Agency> = async (_dropdownName, query) => {
    if (query.length < 2) {
      return [];
    }

    try {
      return await getAgency(cancelFunctions, environmentId, query);
    } catch (error) {
      return [];
    }
  };

  const handleLoadSpecialistLookupData: AsyncLookupHandler<CodeNameModel> = async (_dropdownName, query) => {
    if (query.length < 2) {
      return [];
    }

    try {
      return await getSpecialist(cancelFunctions, environmentId, query);
    } catch (error) {
      return [];
    }
  };

  const handleLoadFrameTagsLookupData: AsyncLookupHandler<FrameTag> = async (_dropdownName, query) => {
    if (query.length < 2) {
      return [];
    }

    try {
      return await getFrameTags(cancelFunctions, query, marketId);
    } catch (error) {
      return [];
    }
  };

  const handleSelectAdvertiser: AsyncSelectHandler<Advertiser> = async (_dropdownName, newValue) => {
    const selectedAdvertiser = newValue || { id: '', code: '', name: '' };

    dispatch(setAdvertiser(selectedAdvertiser));

    if (commercialId && !selectedAdvertiser.id) {
      try {
        await deleteCreativeCommercial(cancelFunctions, commercialId);
        dispatch(resetCommercialId());
        onTaggingRulesChange();
      } catch {} // eslint-disable-line no-empty
    }
  };

  const handleSelectBrand: AsyncSelectHandler<BrandWithProductCategory> = async (_dropdownName, newValue) => {
    const selectedBrand = newValue || { id: '', code: '', name: '', productCategory: { code: '', name: '' } };

    dispatch(setBrand(selectedBrand));

    dispatch(
      setProductCategory({
        cancelFunctions,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        brandId: selectedBrand.id!,
        productCategory: selectedBrand.productCategory,
      }),
    );
    onTaggingRulesChange();
  };

  const handleSelectAgency: AsyncSelectHandler<CodeNameModel> = async (_dropdownName, newValue) => {
    const selectedAgency = newValue || { id: '', code: '', name: '' };

    try {
      if (selectedAgency.id) {
        await addAgency(cancelFunctions, marketId, selectedAgency.id);
        dispatch(setAgency(selectedAgency));
      } else {
        await deleteAgency(cancelFunctions, marketId);
        dispatch(removeAgency());
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleSelectSpecialist: AsyncSelectHandler<CodeNameModel> = async (_dropdownName, newValue) => {
    const selectedSpecialist = newValue || { id: '', code: '', name: '' };

    try {
      if (selectedSpecialist.id) {
        await addSpecialist(cancelFunctions, marketId, selectedSpecialist.id);
        dispatch(setSpecialist(selectedSpecialist));
      } else {
        await deleteSpecialist(cancelFunctions, marketId);
        dispatch(removeSpecialist());
      }
    } catch {} // eslint-disable-line no-empty
  };

  const handleSelectFrameTag: AsyncSelectHandler<FrameTag> = async (_dropdownName, newValue) => {
    if (!newValue) return;

    dispatch(setFrameTag(newValue));
    onTaggingRulesChange();
  };

  const handleSelectProductCategory: AsyncSelectHandler<CodeNameModel> = async (_dropdownName, selected) => {
    const selectedProductCategory = selected || { id: '', code: '', name: '' };

    try {
      if (!selectedProductCategory.id) {
        await updateCreativeCommercial(cancelFunctions, commercialId, advertiser?.id, brand?.id, null);
      } else {
        await updateCreativeCommercial(
          cancelFunctions,
          commercialId,
          advertiser?.id,
          brand?.id,
          selectedProductCategory.id,
        );
      }
    } catch {} // eslint-disable-line no-empty

    dispatch(changeProductCategory(selectedProductCategory));
    onTaggingRulesChange();
  };

  const handleExportSummary = async (): Promise<void> => {
    try {
      const result = await exportFrameSummary(cancelFunctions, creativeId);

      download(new Blob([result]), 'Creative_frames_details.xlsx');
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
    }
  };

  return {
    creative,
    handleSelectProductCategory,
    handleLoadAdvertiserLookupData,
    handleLoadAgencyLookupData,
    handleLoadBrandLookupData,
    handleLoadFrameTagsLookupData,
    handleLoadSpecialistLookupData,
    handleSelectAdvertiser,
    handleSelectAgency,
    handleSelectBrand,
    handleSelectFrameTag,
    handleSelectSpecialist,
    handleExportSummary,
  };
};
