import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SingleContent from 'components/common/layout/SingleContent';
import { resetCreativesState } from 'store/pages/contentManagement/creatives/reducer';
import EditCreativesHeader from './EditCreativesHeader';
import CreativesGallery from './CreativesGallery';
import EditPanel from './EditPanel';

const EditCreatives: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetCreativesState());
    };
  }, []);

  return (
    <div className="grid grid-cols-8 relative h-screen">
      <div className="bg-neutral-200 col-span-6 overflow-y-scroll">
        <EditCreativesHeader />
        <SingleContent content={<CreativesGallery />} />
      </div>

      <div className="bg-neutral-50 col-span-2 h-screen overflow-hidden">
        <EditPanel />
      </div>
    </div>
  );
};

export default EditCreatives;
