import SVG from 'react-inlinesvg';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import notepadSvg from 'assets/icons/notepad.svg';

interface DealFormButtonProps {
  onClick: () => void;
}

const DealFormButton: React.FC<DealFormButtonProps> = ({ onClick }) => {
  return (
    <Button
      dataTestId="create-deal"
      onClick={onClick}
      btnShape={ButtonShape.NORMAL}
      btnType={ButtonType.PRIMARY}
      label="Create deal"
    >
      <SVG src={notepadSvg} color="white" />
      <span>Create deals</span>
    </Button>
  );
};

export default DealFormButton;
