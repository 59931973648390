export enum InputTheme {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
  SEARCH = 'search',
}
export enum InputSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export enum InputShape {
  SQUARE = 'SQUARE',
  ROUNDED = 'ROUNDED',
  CIRCLE = 'CIRCLE',
}

export type AsyncChangeHandler<T> = (newValue: T, dropdownName: string, selected: T) => Promise<void>;
export type InputValue = number | string | undefined;
export type InputNumberBlurHandler = (value: InputValue) => void;
export type InputNumberChangeHandler = (value: InputValue) => void;
export type InputChangeHandler = (value: InputValue, name?: string) => void;
export type InputBlurHandler = (value: InputValue, name?: string) => void;
