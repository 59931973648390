import PropTypes from 'prop-types';
import Badge, { BadgeSize } from 'components/patterns/Badge';
import Tooltip, { TooltipDirection } from 'components/patterns/Tooltip';

const StatusRenderer = ({ value, statuses, direction, badgeSize }) => {
  const { initials, color, textColor, name } = statuses.find((status) => status.code === value) || {};
  return (
    <Tooltip tooltip={name} direction={direction}>
      <Badge color={color} textColor={textColor} size={badgeSize}>
        {initials}
      </Badge>
    </Tooltip>
  );
};

StatusRenderer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      initials: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
  direction: PropTypes.oneOf(Object.values(TooltipDirection)),
  badgeSize: PropTypes.oneOf(Object.values(BadgeSize)),
};

StatusRenderer.defaultProps = {
  value: '',
  statuses: {},
  direction: TooltipDirection.RIGHT,
  badgeSize: BadgeSize.SMALL,
};

export default StatusRenderer;
