import { Dsp } from 'components/common/types/Deal.types';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import { notifyError } from 'store/notification/reducer';
import { DspFromMediaOwners } from 'store/reseller/reducer';
import { MediaOwnerOption } from './MediaOwner.types';

const getUniqueAccessibleMediaOwners = (mediaOwnerList: DspFromMediaOwners[], dsp: Dsp): string[] => {
  const accessibleMediaOwners = mediaOwnerList
    .filter((dspOfMediaOwner) => dspOfMediaOwner.name === dsp.name)
    .map((dspOfMediaOwner) => dspOfMediaOwner.publisher);

  return [...new Set(accessibleMediaOwners)];
};

export const useDsp = (): { transformedAvailableMediaOwners: MediaOwnerOption[] } => {
  const dispatch = useDispatch();
  const availableMediaOwners: string[] = useSelector((state: RootState) => state.reseller.availableMediaOwners);
  const dspsFromMediaOwners: DspFromMediaOwners[] = useSelector(
    (state: RootState) => state.reseller.dspsFromMediaOwners,
  );
  const dsp = useSelector((state: RootState) => state.dealManagement.programmatic.dsp);
  const mediaOwners: string[] = useSelector(
    (state: RootState) => state.dealManagement.commonDeal.currentLine.mediaOwners,
  );

  const [transformedAvailableMediaOwners, setTransformedAvailableMediaOwners] = useState<MediaOwnerOption[]>([]);

  useEffect(() => {
    if (!dsp) return;

    const uniqueAccessibleMediaOwners = getUniqueAccessibleMediaOwners(dspsFromMediaOwners, dsp);

    const filteredMediaOwners = mediaOwners.filter((mediaOwner) => uniqueAccessibleMediaOwners.includes(mediaOwner));

    if (mediaOwners.length !== filteredMediaOwners.length) {
      const deselectedMediaOwners = mediaOwners.filter(
        (mediaOwner) => !uniqueAccessibleMediaOwners.includes(mediaOwner),
      );

      dispatch(
        notifyError({
          message: `${deselectedMediaOwners.join(' and ')} are not available for the current DSP selection.`,
          timeout: NOTIFICATION_TIMEOUT.LONG,
        }),
      );
    }
    dispatch(changeDealCurrentLineData({ mediaOwners: filteredMediaOwners }));
  }, [dsp]);

  useEffect(() => {
    if (!dsp) {
      setTransformedAvailableMediaOwners(availableMediaOwners.map((mo) => ({ code: mo, name: mo })));
      return;
    }

    const uniqueAccessibleMediaOwners = getUniqueAccessibleMediaOwners(dspsFromMediaOwners, dsp);

    setTransformedAvailableMediaOwners(
      availableMediaOwners.map((mo) => ({ code: mo, name: mo, disabled: !uniqueAccessibleMediaOwners.includes(mo) })),
    );
  }, [availableMediaOwners, dsp]);

  return { transformedAvailableMediaOwners };
};
