import { ReactElement } from 'react';
import DropZone, { FileRejection } from 'react-dropzone';
import SVG from 'react-inlinesvg';
import classNames from 'classnames/bind';

import { FILE_SIZE_10MB } from 'modules/api/constants';
import uploadSvg from 'assets/icons/upload.svg';
import IconButton from 'lib/IconButton';
import { FileUploadProps } from './FileUpload.types';

const cx = classNames.bind(null);

const FileUpload: React.FC<FileUploadProps> = ({
  onFileChange,
  acceptFileType,
  maxFileSize = FILE_SIZE_10MB,
  isShowDropArea = false,
  placeholder = 'Try dropping some files here, or click to select files to upload.',
  isDisabled = false,
  isMultiple = false,
  isFileIconVisibleOnly,
}) => {
  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]): void => {
    onFileChange(acceptedFiles, rejectedFiles);
  };

  const displayInputFileUpload = (isDragActive: boolean): ReactElement => {
    return (
      <div
        className={cx('grid grid-flow-col border border-neutral-300 rounded-md px-4 py-2 dis', {
          'h-[100px] border-dashed border-2 rounded-lg': isShowDropArea,
          'opacity-70': isDisabled,
        })}
      >
        {isDragActive ? (
          <p>Drop files here...</p>
        ) : (
          <div className="body-base text-neutral-600 truncate">{placeholder}</div>
        )}
        <SVG src={uploadSvg} className="fill-neutral-500 h-[18px] w-[18px] justify-self-end hover:fill-purple-800" />
      </div>
    );
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <DropZone
      onDrop={onDrop}
      accept={acceptFileType}
      maxSize={maxFileSize}
      disabled={isDisabled}
      multiple={isMultiple}
      noClick={isFileIconVisibleOnly}
    >
      {({ getRootProps, getInputProps, isDragActive, open }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} data-test-id="file-upload-input" />
          {isFileIconVisibleOnly ? (
            <IconButton
              name="file-upload"
              icon={uploadSvg}
              handleOnClick={open}
              isDisabled={isDisabled}
              dataTestId="file-upload-icon"
            />
          ) : (
            displayInputFileUpload(isDragActive)
          )}
        </div>
      )}
    </DropZone>
    /* eslint-enable react/jsx-props-no-spreading */
  );
};

export default FileUpload;
