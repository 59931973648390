import { PropsWithChildren, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import { isArray } from 'lodash';
import { ButtonThemes, SectionButtonProps } from './SectionButton.types';
import SectionButtonMenu from './SectionButtonMenu';
import SectionButtonChipList from './SectionButtonChipList';
import SectionButtonChip from './SectionButtonChip';

const SectionButton: React.FC<PropsWithChildren<SectionButtonProps>> = ({
  theme,
  dataTestId,
  icon,
  label,
  children,
  isActive,
  isDisabled,
  isRequired,
  selected,
  onClick,
  onClear,
}) => {
  const [areChipsCollapsed, setAreChipsCollapsed] = useState(false);

  const chipsLength = useMemo((): number => {
    if (!selected) return 0;

    return isArray(selected) ? selected.length : Object.keys(selected).length;
  }, [selected]);

  const buttonContent = useMemo(() => {
    if (chipsLength && areChipsCollapsed)
      return <SectionButtonChip label="Selections" value={chipsLength.toString()} />;

    if (selected) return <SectionButtonChipList selected={selected} />;

    return children;
  }, [selected, chipsLength, areChipsCollapsed, children]);

  return (
    <button
      data-test-id={dataTestId}
      className={cx('flex p-2 gap-1 border rounded-md w-full disabled:bg-neutral-200 disabled:cursor-not-allowed', {
        'ring-1 border-primary-600 ring-primary-600 bg-primary-50': isActive,
        [ButtonThemes[theme].inactive]: !isActive,
        [ButtonThemes[theme].buttonContainer]: true,
      })}
      type="button"
      disabled={isDisabled}
      onClick={onClick}
    >
      <div className="flex flex-row items-center gap-1 py-0.5">
        <SVG
          className={cx('fill-current w-4 h-4', {
            'text-neutral-500': isDisabled,
            [ButtonThemes[theme].iconColor]: !isDisabled,
          })}
          src={icon}
        />
        <span
          className={cx('sub-header-base whitespace-nowrap', {
            'text-neutral-500': isDisabled,
            [ButtonThemes[theme].textColor]: !isDisabled,
          })}
        >
          {label}
        </span>
      </div>
      <div className="flex flex-1 justify-end">{buttonContent}</div>
      {!selected && isRequired ? (
        <div className="flex h-full self-center justify-end">
          <span className="text-primary">Required</span>
        </div>
      ) : null}
      {onClear ? (
        <SectionButtonMenu
          onClear={onClear}
          onToggle={() => setAreChipsCollapsed(!areChipsCollapsed)}
          isToggleDisabled={chipsLength <= 1}
          isCollapsed={areChipsCollapsed}
        />
      ) : null}
    </button>
  );
};

export default SectionButton;
