import React from 'react';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import withLoader from 'components/hocs/withLoader';
import Tabs from 'components/patterns/Tabs/Tabs';
import Users from './Users';
import Roles from './Roles';

class UserManagement extends React.Component {
  state = {
    tabs: {
      dataList: [
        {
          id: 1,
          label: 'Users',
          content: <Users />,
        },
        {
          id: 2,
          label: 'Roles',
          content: <Roles />,
          hide: !Auth.hasPermission(PermissionsEnum.USERS_ROLES),
        },
      ],
      currentTabIndex: 0,
    },
  };

  tabChangeHandler = (index) => {
    const { tabs } = this.state;
    this.setState({
      tabs: {
        ...tabs,
        currentTabIndex: index,
      },
    });
  };

  render() {
    const { tabs } = this.state;
    return (
      <Tabs
        dataList={tabs.dataList}
        idKey="id"
        labelKey="label"
        contentKey="content"
        hiddenKey="hide"
        currentTabIndex={tabs.currentTabIndex}
        onTabChange={this.tabChangeHandler}
      />
    );
  }
}

export default withLoader(UserManagement);
