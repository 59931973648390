import { useState, useRef } from 'react';
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import uniqBy from 'lodash/uniqBy';

import { useOutsideClick } from 'customHooks/useOutsideClick';
import moreSvg from 'assets/icons/more.svg';
import { Store } from 'components/common/types/Store.types';
import { Creative } from 'components/common/types/Creative.types';

const DspPopup: React.FC = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const selectedCreatives = useSelector((state: Store) => state.pages.contentManagement.creatives.selectedCreatives);
  const selectedDsps = uniqBy(
    selectedCreatives.map((creative: Creative) => creative.dsp),
    'code',
  );

  useOutsideClick(dropdownRef, () => {
    setIsPopupOpen(false);
  });

  return (
    <div className="relative flex items-center flex-col" ref={dropdownRef} data-test-id="dsp-popup">
      <button
        type="button"
        className="text-center rounded-full bg-primary-600-opacity-10 px-2 hover:bg-primary-300"
        onClick={() => {
          setIsPopupOpen((prevState) => !prevState);
        }}
      >
        <SVG className="fill-current text-primary" src={moreSvg} />
      </button>
      {isPopupOpen ? (
        <>
          <div className="absolute border-4 top-8 z-50 border-arrowTop text-primary-900" />
          <div className="absolute top-10 right-0 z-40 min-w-36 bg-neutral-50 shadow-lg rounded-md shadow-dropdownMenu p-3">
            <p className="sub-header-base text-neutral-950">Selected DSPs</p>
            <ul className="space-y-2 pt-3.5 pb-1 body-base">
              {selectedDsps.map((dsp) => (
                <li className="text-neutral-600 flex items-center" key={dsp.code}>
                  <div className="mr-2 h-1 w-1 rounded-sm bg-neutral-300" />
                  {dsp.name}
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DspPopup;
