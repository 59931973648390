import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import { Store } from 'components/common/types/Store.types';
import { updateNewFrameTags } from 'store/pages/contentManagement/creativesAdditionalData/reducer';
import Button, { ButtonShape, ButtonSize, ButtonType } from 'components/patterns/Button';
import plusSvg from 'assets/icons/plus.svg';
import minusSvg from 'assets/icons/minus.svg';
import closeSvg from 'assets/icons/close.svg';
import { FrameTagType } from 'components/common/types/FrameTag.types';
import FrameTagCardProps from './FrameTagCard.types';

const FrameTagCard: React.FC<FrameTagCardProps> = ({ frameTag, dataTestId, isDisabled }) => {
  const dispatch = useDispatch();
  const newFrameTags = useSelector(
    (state: Store) => state.pages.contentManagement.creativesAdditionalData.newFrameTags,
  );

  const onTypeChange = (newType: FrameTagType) => (): void => {
    const tags = newFrameTags.map((tag) => ({ ...tag }));
    const targetFrameTagIndex = tags.findIndex((tag) => tag.id === frameTag.id);

    if (targetFrameTagIndex !== -1) {
      tags[targetFrameTagIndex].type = newType;
      dispatch(updateNewFrameTags(tags));
    }
  };

  const onRemoveFrameTag = (): void => {
    const tags = newFrameTags.map((tag) => ({ ...tag }));
    const targetFrameTagIndex = tags.findIndex((tag) => tag.id === frameTag.id);

    if (targetFrameTagIndex !== -1) {
      tags.splice(targetFrameTagIndex, 1);
      dispatch(updateNewFrameTags(tags));
    }
  };

  const isFrameAvailable = frameTag.frameCount > 0;

  return (
    <div
      data-test-id={dataTestId}
      className="relative w-full bg-neutral-100 border border-neutral-300 rounded-md py-1.5 px-3 my-[2px] body-base text-neutral-950-opacity-60 flex justify-between items-center"
    >
      <div className="relative flex flex-wrap">
        <p className="mr-1">{frameTag.name}</p>
        <p
          className={cx({
            'text-danger': !isFrameAvailable,
            'text-success': isFrameAvailable,
          })}
        >
          ({frameTag.frameCount} frames)
        </p>
      </div>
      <div className="flex space-x-1 mr-1.5">
        <Button
          dataTestId="frame-tag-card-button-inclusion"
          btnSize={ButtonSize.EXTRA_SMALL_ROUND}
          btnType={frameTag.type === 'inclusion' ? ButtonType.PRIMARY : ButtonType.TRANSPARENT_WITH_BORDER}
          btnShape={ButtonShape.CIRCLE}
          onClick={onTypeChange('inclusion')}
          isDisabled={isDisabled || !isFrameAvailable}
        >
          <SVG src={plusSvg} className="h-2.5 w-2.5" />
        </Button>
        <Button
          dataTestId="frame-tag-card-button-exclusion"
          btnSize={ButtonSize.EXTRA_SMALL_ROUND}
          btnType={frameTag.type === 'exclusion' ? ButtonType.PRIMARY : ButtonType.TRANSPARENT_WITH_BORDER}
          btnShape={ButtonShape.CIRCLE}
          onClick={onTypeChange('exclusion')}
          isDisabled={isDisabled || !isFrameAvailable}
        >
          <SVG src={minusSvg} className="h-2.5 w-2.5" />
        </Button>
      </div>
      <div className="absolute w-2 h-2 -top-2 right-0 duration-300 opacity-0 hover:opacity-100">
        <Button onClick={onRemoveFrameTag} btnSize={ButtonSize.EXTRA_SMALL_ROUND} btnType={ButtonType.INK}>
          <SVG src={closeSvg} className="h-2 w-2" />
        </Button>
      </div>
    </div>
  );
};

export default FrameTagCard;
