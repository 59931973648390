import { useState } from 'react';
import classNames from 'classnames/bind';
import SVG from 'react-inlinesvg';

import { DASH_SLASH } from 'consts/placeholders';
import closeSvg from 'assets/icons/close.svg';
import userSvg from 'assets/icons/user.svg';
import maximizeSvg from 'assets/icons/maximize.svg';
import StatusIcon from 'components/common/StatusIcon';
import { CreativeErrorType, CreativeType } from 'components/common/types/Creative.types';
import { CardSummary } from './CardSummary';
import { CardMedia } from './CardMedia';
import styles from './Card.pcss';
import { CardProps } from './Card.types';
import { CardVideoDuration } from './CardVideoDuration/CardVideoDuration';

const cx = classNames.bind(styles);

export const Card: React.FC<CardProps> = ({ creative, onRemove, onClick }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const previewURL = creative.type === CreativeType.BANNER ? creative.previewURL : creative.thumbnail;

  const onMouseOver = (): void => setIsHovering(true);
  const onMouseOut = (): void => setIsHovering(false);
  const onRemoveButtonClick = (): void => setIsRemoved(true);
  const onAnimationEnd = (): void => onRemove?.(creative.id);

  const errorType: { [key in CreativeErrorType as string]: string } = {
    [CreativeErrorType.FAILURE]: 'bg-pinkRed-700',
    [CreativeErrorType.PARTIAL_SUCCESS]: 'bg-yellow',
  };

  return (
    <div
      className={cx('relative text-neutral-50 p-1 overflow-visible', styles.cardContainer, {
        removed: isRemoved,
      })}
      onAnimationEnd={onAnimationEnd}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onFocus={onMouseOver}
      onBlur={onMouseOut}
    >
      <div className={cx('bg-neutral-50 rounded-lg p-1', errorType[creative.error?.result || ''])}>
        <div className="relative box-border">
          <CardMedia previewURL={previewURL} />

          <div
            className={cx(
              'absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 rounded-lg overflow-visible cursor-pointer',
              {
                isVisible: isHovering,
                invisible: !isHovering,
              },
            )}
            data-test-id={`creative-card-${creative.id}`}
            onClick={() => onClick(creative.id)}
            role="presentation"
          />

          <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
            {onRemove && (
              <div
                className={cx('absolute -right-2 -top-2 pointer-events-auto', {
                  visible: isHovering,
                  invisible: !isHovering,
                })}
              >
                <button
                  type="button"
                  data-test-id="remove-creative"
                  className="bg-pinkRed-500 rounded-2xl p-1"
                  onClick={onRemoveButtonClick}
                >
                  <SVG src={closeSvg} className="w-3.5 h-3.5" />
                </button>
              </div>
            )}

            {isHovering && (
              <>
                <div className="w-full flex flex-row items-center pt-2 px-2">
                  <SVG src={userSvg} className="w-3 h-3 mr-1.5" />
                  <div>{creative.uploadedBy || DASH_SLASH}</div>
                </div>
                <div
                  data-test-id="maximize-button"
                  className="absolute top-0 left-0 inset-y-1/2 w-full h-full flex justify-center items-center"
                >
                  <SVG src={maximizeSvg} className="text-neutral-100" />
                </div>
              </>
            )}

            <div className="absolute bottom-0 left-0 flex flex-row justify-between w-full pb-2 px-2">
              <div className="flex">
                {creative.width && creative.height && (
                  <div className="body-sm bg-neutral-950-opacity-50 px-2 py-1 rounded-2xl mr-1">
                    {creative.width}x{creative.height}
                  </div>
                )}

                {creative.type === CreativeType.VIDEO && <CardVideoDuration previewURL={creative.previewURL} />}
              </div>

              <StatusIcon statusCode={creative.statusCode} />
            </div>
          </div>
        </div>
        <CardSummary creative={creative} />
      </div>
    </div>
  );
};
