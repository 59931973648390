import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notifySuccess, notifyError } from 'store/notification/reducer';
import Roles from './Roles';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      notifySuccess,
      notifyError,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Roles);
