import { DealStatus, RouteFrameCode } from 'components/common/types/Deal.types';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import { CodeNameModel, FileList } from 'components/common/types';
import { useCallback } from 'react';

type UseRouteFrameListProps = {
  isPendingReservation: boolean;
  handleFrameSelection: (frameCodes: RouteFrameCode[]) => void;
  clearFramesSelection: VoidFunction;
  onIncludeExcludeChange: (value: CodeNameModel) => void;
  handleFrameListUpload: (newFilesList: FileList[]) => void;
};

export const useRouteFrameList = (): UseRouteFrameListProps => {
  const dispatch = useDispatch();
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const routeFrameCodes = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.routeFrameCodes);
  const listFiles = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.listFiles);

  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;

  const handleFrameSelection = (frameCodes: RouteFrameCode[]): void => {
    dispatch(changeDealCurrentLineData({ routeFrameCodes: frameCodes }));
  };

  const clearFramesSelection = (): void => {
    dispatch(changeDealCurrentLineData({ routeFrameCodes: [], listFiles: [] }));
  };

  const updateIncludeExclude = (frameCodes: CodeNameModel[], value: CodeNameModel): CodeNameModel[] => {
    return frameCodes.map((frameCode) =>
      frameCode.code === value.code ? { ...frameCode, include: value.include } : frameCode,
    );
  };

  const removeFormatByCode = (formats: CodeNameModel[], code: string): CodeNameModel[] => {
    return formats.filter((format) => format.code !== code);
  };

  const updateFrameSelection = (frameCodes: RouteFrameCode[], value: CodeNameModel): CodeNameModel[] => {
    const formatExists = frameCodes.find(({ code }) => code === value.code);

    if (formatExists) {
      if (value.include !== undefined) {
        return updateIncludeExclude(frameCodes, value);
      }

      return removeFormatByCode(frameCodes, value.code);
    }

    return [...frameCodes, value];
  };

  const onIncludeExcludeChange = useCallback(
    (value: CodeNameModel): void => {
      const frameCodes = structuredClone(routeFrameCodes);

      if (!frameCodes) return;

      const updatedFrameCodes = updateFrameSelection(frameCodes, value);
      handleFrameSelection(updatedFrameCodes);
    },
    [routeFrameCodes?.length],
  );

  const handleFrameListUpload = (newFilesList: FileList[]): void => {
    if (listFiles?.length) {
      dispatch(changeDealCurrentLineData({ listFiles: newFilesList }));
    }
  };

  return {
    isPendingReservation,
    handleFrameSelection,
    clearFramesSelection,
    onIncludeExcludeChange,
    handleFrameListUpload,
  };
};
