import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import LineInfo from './LineInfo';
import DealLines from './DealLines';
import LineSummary from './LineSummary';

const LineDetails = ({ closePopup }) => {
  const currentLine = useSelector((state) => state.dealManagement.commonDeal.currentLine);
  const lines = useSelector((state) => state.dealManagement.backupFormData.lines);

  const defaultLine = currentLine.id ? lines.find(({ id }) => id === currentLine.id) : lines[lines.length - 1];
  const [line, setLine] = useState(defaultLine);

  const handleMouseEnter = (lineId) => {
    setLine(lines.find((lineObj) => lineObj.id === lineId));
  };

  return (
    <div className="shadow-lg bg-neutral-50 border-primary border-l-4 text-neutral-900 rounded transform -translate-y-2.5 grid grid-cols-3 max-w-5xl overflow-hidden">
      <DealLines handleMouseEnter={handleMouseEnter} closePopup={closePopup} />
      <LineInfo line={line} />
      <LineSummary line={line} />
    </div>
  );
};

LineDetails.propTypes = {
  closePopup: PropTypes.func.isRequired,
};

export default LineDetails;
