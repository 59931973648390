import cx from 'classnames';

type Props = {
  isLoading?: boolean;
  isFixed?: boolean;
};

export const Preloader: React.FC<Props> = ({ isLoading = true, isFixed = false }) => {
  if (!isLoading) return null;

  return (
    <div
      className={cx('w-full h-full top-0 bottom-0 left-0 right-0 m-auto bg-neutral-950-opacity-10 z-199', {
        fixed: isFixed,
        absolute: !isFixed,
      })}
      data-test-id="loader-container"
    >
      <div className="relative w-full h-full">
        <div className="animate-spin-2s absolute w-20 h-20 top-0 bottom-0 left-0 right-0 m-auto border-6 rounded-full border-t-primary border-r-transparent border-b-primary border-l-transparent" />
        <div className="animate-reverse-spin-2s absolute w-16 h-16 top-0 bottom-0 left-0 right-0 m-auto border-6 rounded-full border-t-transparent border-r-sky border-b-transparent border-l-blueSky" />
      </div>
    </div>
  );
};
