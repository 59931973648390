import { FunctionComponent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import DealCpm from 'components/common/Deal/DealCpm';
import InfoMessage from 'components/common/Deal/InfoMessage';
import { CardTheme } from 'components/patterns/Card/Card.types';
import { RootState } from 'store';

const CpmFilter: FunctionComponent<{ isDisabled: boolean }> = ({ isDisabled }) => {
  const dispatch = useDispatch();
  const maxCPM = useSelector((state: RootState) => state.dealManagement.commonDeal.currentLine.maxCPM);
  const dealCpm = useSelector((state: RootState) => state.dealManagement.commonDeal.cpm);

  const isFilterGreaterThanDeal = maxCPM > dealCpm;
  const isDealCpmUnPopulated = !dealCpm;

  const onChangeDealLineCpm = (value: number): void => {
    dispatch(changeDealCurrentLineData({ maxCPM: Number(value) }));
  };

  const renderInfoMessage = (): ReactElement => {
    if (isDealCpmUnPopulated) {
      return (
        <div className="text-primary-600" data-test-id="cpm-filter-deal-message">
          Deal CPM pricing value must be set
        </div>
      );
    }
    if (isFilterGreaterThanDeal) {
      return (
        <div className="text-primary-600" data-test-id="cpm-filter-gt-message">
          The CPM filter cannot be greater than the CPM that you have set for this deal, this value will be
          auto-corrected to be the same as the deal CPM.
        </div>
      );
    }

    return (
      <p data-test-id="cpm-filter-info-message">
        Set a maximum value for frame CPM pricing to filter frames by their CPM rates. This ensures that only frames
        within the specific CPM range are included in the deal.
      </p>
    );
  };

  return (
    <div className="grid grid-cols-2 gap-x-7 xl:gap-x-24 px-12 py-4">
      <DealCpm
        id="cpm-filter"
        onBlur={onChangeDealLineCpm}
        value={maxCPM}
        errorMessage={isFilterGreaterThanDeal ? 'Frame CPM should not exceed the CPM ' : ''}
        isDisabled={isDisabled || isDealCpmUnPopulated}
        isRequired
      />
      <InfoMessage cardTheme={CardTheme.PRIMARY}>{renderInfoMessage()}</InfoMessage>
    </div>
  );
};

export default CpmFilter;
