import { LevelType } from 'consts/thoughtspot';

export interface DealLineType {
  id: string;
  lineId: string;
}

export enum ReportType {
  FINAL = 'FINAL',
  PROPOSAL = 'PROPOSAL',
}
export interface ThoughtSpotAllocationReportProps {
  closeModal: () => void;
  defaultLevelType?: LevelType;
  openModal: boolean;
  reportType?: ReportType;
}
