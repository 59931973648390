/* eslint-disable react/no-this-in-sfc */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ChartOptions, ChartSeries } from 'components/common/types/Chart.types';
import { TableFilters } from 'components/common/types/Table.types';
import { Chart, SETTINGS } from 'components/patterns/HighChart';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { usePageFilters } from 'customHooks/usePageFilters';
import { SeriesOptionsType } from 'highcharts';
import { formatDate, formatNumber, formatPercent, FormatTypeEnum } from 'modules/I18N';
import { fetchTradingBehaviourChart } from 'modules/api/performance';
import { RootState } from 'store';
import { notifyError } from 'store/notification/reducer';
import { formatChartTooltip, TooltipFormatterContext } from 'utils/formatChartTooltip';
import { getFilterQueryString } from 'utils/reportingQueryString';
import { TradingBehavior } from './TradingBehaviourChart.types';

const TradingBehaviourChart: React.FC = () => {
  const chartTitle = 'Trading behaviour';
  const dispatch = useDispatch();
  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const timezone = useSelector((state: RootState) => state.header.timezone);
  const { filters } = usePageFilters<TableFilters>('insights/dashboard');
  const cancelFunctions = useCancelRequest();
  const [tradingBehaviourData, setTradingBehaviourData] = useState<TradingBehavior>();

  useEffect(() => {
    const fetchTradingBehaviourChartData = async (): Promise<void> => {
      try {
        setTradingBehaviourData(
          await fetchTradingBehaviourChart(cancelFunctions, getFilterQueryString(filters, timezone)),
        );
      } catch (error) {
        dispatch(notifyError({ message: error.message, timeout: NOTIFICATION_TIMEOUT.LONG }));
        setTradingBehaviourData(undefined);
      }
    };

    fetchTradingBehaviourChartData();
  }, [filters, timezone]);

  const getSeries = (): ChartSeries[] => {
    if (!tradingBehaviourData) return [];
    const { tradingBehaviourGraph: chartData = [] } = tradingBehaviourData;

    return [
      {
        name: 'Bid requests',
        data: chartData.map((data) => data.bidRequests || 0),
        color: SETTINGS.COLORS.mintGreen,
        type: 'column',
        yAxis: 0,
      },
      {
        name: 'Bid responses',
        data: chartData.map((data) => data.bidResponses || 0),
        color: SETTINGS.COLORS.pinkRed,
        type: 'column',
        yAxis: 0,
      },
      {
        name: 'Sold trades',
        data: chartData.map((data) => data.soldTrades || 0),
        color: SETTINGS.COLORS.primary,
        type: 'column',
        yAxis: 0,
      },
      {
        name: 'Lost trades',
        data: chartData.map((data) => data.lostTrades || 0),
        color: SETTINGS.COLORS.blueSky,
        type: 'column',
        yAxis: 0,
      },
      {
        name: 'Traded win rate',
        data: chartData.map((data) => data.tradedWinRate || 0),
        color: SETTINGS.COLORS.primaryLight,
        marker: {
          enabled: false,
        },
        type: 'spline',
        yAxis: 1,
        additionalData: {
          formatType: FormatTypeEnum.PERCENT,
        },
      },
    ];
  };

  const getChartOptions = (): ChartOptions | {} => {
    if (!tradingBehaviourData) return {};
    const { tradingBehaviourGraph: chartData = [] } = tradingBehaviourData;

    return {
      chart: {
        zoomType: 'xy',
        backgroundColor: SETTINGS.COLORS.mist,
      },
      tooltip: {
        backgroundColor: SETTINGS.COLORS.lightAir,
        borderColor: SETTINGS.COLORS.lightAir,
        borderRadius: 8,
        formatter() {
          const tooltipContext: TooltipFormatterContext = {
            y: this.y,
            x: this.x,
            color: this.color,
            seriesName: this.series.name,
            userOptions: this.series.userOptions as SeriesOptionsType & Pick<ChartSeries, 'additionalData'>,
            localeCode,
            currencyCode,
          };
          return formatChartTooltip(tooltipContext);
        },
      },
      legend: {
        itemDistance: 40,
        symbolPadding: 10,
        symbolHeight: 10,
        symbolWidth: 20,
      },
      title: {
        text: chartTitle,
        align: 'left',
        margin: 50,
      },
      xAxis: [
        {
          categories: chartData.map((data) => data.date),
          title: {
            text: chartTitle,
          },
          labels: {
            formatter() {
              const isoDateString = new Date(this.value).toISOString();
              return formatDate(isoDateString, localeCode);
            },
          },
        },
      ],
      yAxis: [
        {
          labels: {
            formatter() {
              return formatNumber({ value: Number(this.value), localeCode });
            },
          },
          title: {
            text: '',
          },
          gridLineWidth: 1,
        },
        {
          labels: {
            formatter() {
              return formatPercent({ value: Number(this.value) / 100, localeCode });
            },
            style: {
              color: SETTINGS.COLORS.grayer,
            },
          },
          title: {
            text: '',
          },
          opposite: true,
          gridLineWidth: 1,
          min: 0,
          max: 100,
        },
      ],
      series: getSeries(),
    };
  };

  return <Chart dataTestId="trading-behaviour-chart" options={getChartOptions()} />;
};

export default TradingBehaviourChart;
/* eslint-enable react/no-this-in-sfc */
