import { FeatureFlags } from 'components/common/types/Features.types';
import {
  PublisherBackEndSystem,
  IsAdServerProps,
  TransformedPublisherFeatures,
  PublisherFeatures,
} from 'components/common/types/Publisher.types';

export const isAdServer = ({ backEndSystem, publisherFeatures, environmentId }: IsAdServerProps): boolean => {
  const normalizebackEndSystem = backEndSystem?.toLowerCase();

  const legacyPublisherFeature = publisherFeatures[FeatureFlags.LEGACY_AUTOMATION_PUBLISHER];
  const hasLegacyPublisherFlag = legacyPublisherFeature?.find((publisher) => publisher === environmentId);

  return normalizebackEndSystem === PublisherBackEndSystem.adServer && !hasLegacyPublisherFlag;
};

export const convertToArrayOfMarketsForIndividualFeatureFlag = (
  featuresResponse: PublisherFeatures,
): TransformedPublisherFeatures => {
  return Object.keys(featuresResponse).reduce((acc, featureName) => {
    const envIds = featuresResponse[featureName].replace(/ /g, '').split(',');
    acc[featureName] = envIds;
    return acc;
  }, {} as TransformedPublisherFeatures);
};
