import SVG from 'react-inlinesvg';
import cx from 'classnames';

import syncedSvg from 'assets/icons/synced.svg';
import excludeSvg from 'assets/icons/exclude.svg';
import { IncludeExcludeChipProps } from './IncludeExcludeChip.types';

const IncludeExcludeChip: React.FC<IncludeExcludeChipProps> = ({ item, onIncludeExcludeChange }) => {
  const chipButtonOptions = [
    { label: 'Excluded', isActive: !item.include, icon: excludeSvg, isIncluded: false },
    { label: 'Included', isActive: item.include, icon: syncedSvg, isIncluded: true },
  ];

  return (
    <div className="flex items-center justify-center rounded bg-neutral-950-opacity-5">
      {chipButtonOptions.map(({ label, isActive, icon, isIncluded }) => (
        <button
          key={label}
          type="button"
          aria-label={`Chip ${label}`}
          onClick={() => onIncludeExcludeChange(item, isIncluded)}
          className={cx('flex items-center px-2 py-1 border h-[24px] bg-neutral-100', {
            'shadow-sm rounded-sm bg-neutral-100': isActive,
            'bg-neutral-950-opacity-5 text-neutral-950 opacity-60': !isActive,
            'rounded-r': isIncluded,
            'rounded-l': !isIncluded,
          })}
        >
          <SVG src={icon} className="w-4 h-4" />
          {isActive && <span className="ml-1 pr-1">{label}</span>}
        </button>
      ))}
    </div>
  );
};

export default IncludeExcludeChip;
