import { getSeparators } from 'modules/I18N';

export const getStringFromObjectsList = (list, nameKey = 'name') =>
  list?.reduce(
    (accumulator, currentValue) =>
      accumulator ? `${accumulator}, ${currentValue[nameKey]}` : `${currentValue[nameKey]}`,
    '',
  );

export const getGraphData = (data) => data?.map((d) => [new Date(d.date).getTime(), d.value]);

export const formatUserPercentageInput = (userInput, localeCode) => {
  const { decimalSeparator } = getSeparators(localeCode);

  if (!userInput) return 0;

  return Number(userInput.toString().replace(new RegExp(`\\${decimalSeparator}`, 'gm'), '.'));
};

export const formatUserInput = (userInput, localeCode) => {
  const { groupSeparator, decimalSeparator } = getSeparators(localeCode);

  if (!userInput) return 0;

  return Number(
    userInput
      .toString()
      .replace(new RegExp(`\\${groupSeparator}`, 'gm'), '')
      .replace(new RegExp(`\\${decimalSeparator}`, 'gm'), '.'),
  );
};

export const formatMarket = (market) => {
  if (market.includes(':')) {
    const splitMarket = market.split(':');
    splitMarket.shift();

    return splitMarket.join('').includes('apgch')
      ? splitMarket.join(' ').trim()
      : splitMarket.join(' ').trim().toUpperCase();
  }

  if (market.includes('_')) {
    const splitMarket = market.split('_');

    return splitMarket.join(' ').trim().toUpperCase();
  }

  return market;
};

export const transformColumnChartData = (chartData, xAxis, yAxis) => {
  if (!chartData) {
    return [];
  }

  return chartData.map((i) => ({ x: i[xAxis], y: i[yAxis] || 0 }));
};

export const formatFilterOptionsName = (filterOptions) =>
  filterOptions.map((option) => ({ ...option, name: `${option.code}: ${option.name}` }));

export const tryToRemoveZeroAsLastDigit = (value, localeCode) => {
  const { decimalSeparator } = getSeparators(localeCode);
  const lastCharacter = value[value.length - 1];
  const isUserInputZeroAfterSeparator = lastCharacter === '0' && value.includes(decimalSeparator);
  if (isUserInputZeroAfterSeparator) {
    return value.replace(`${decimalSeparator}0`, '');
  }
  return value;
};

export const formatCompactNumber = ({ value, precision = 1, localeCode }) => {
  const suffixMap = [
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
  ];

  const foundMatchingSuffix = suffixMap.find((mapElement) => Math.abs(value) >= mapElement.threshold);

  return `${tryToRemoveZeroAsLastDigit(
    (foundMatchingSuffix ? value / foundMatchingSuffix.threshold : value).toFixed(precision),
    localeCode,
  )}${foundMatchingSuffix?.suffix ?? ''}`;
};
