/* eslint-disable sonarjs/cognitive-complexity */
import { useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import chevronRight from 'assets/icons/chevron_right.svg';
import bullhorn from 'assets/icons/bullhorn.svg';
import wayfindingSvg from 'assets/icons/wayfinding.svg';
import tagSvg from 'assets/icons/tag.svg';
import locationSvg from 'assets/icons/location.svg';
import imageSvg from 'assets/icons/image.svg';
import clockSvg from 'assets/icons/clock.svg';
import proximitySvg from 'assets/icons/proximity.svg';
import targetSvg from 'assets/icons/target.svg';
import mapSvg from 'assets/icons/map.svg';
import framePortraitSvg from 'assets/icons/frame-portrait.svg';
import moneySvg from 'assets/icons/money.svg';
import copyIcon from 'assets/icons/copy.svg';
import briefcaseSvg from 'assets/icons/briefcase.svg';

import Popup, { ARROW_COLOR, PopupAlign, PopupPosition } from 'components/patterns/Popup';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { getLineLevelCpmSettings } from 'components/pages/DealWithLines/common/Main/Deal/selectors';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import LineDetails from './LineDetails';
import LinkItem from './LinkItem';

export const links = {
  commonDeal: { name: 'Deal', icon: bullhorn, link: '#anch-deal', id: 'anch-deal' },
  schedule: { name: 'Schedule', icon: clockSvg, link: '#anch-schedule', id: 'anch-schedule' },
  mediaOwners: { name: 'Media owners', icon: briefcaseSvg, link: '#anch-mediaOwners', id: 'anch-mediaOwners' },
  environments: { name: 'Environments', icon: wayfindingSvg, link: '#anch-environments', id: 'anch-environments' },
  cpm: { name: 'CPM', icon: moneySvg, link: '#anch-cpm', id: 'anch-cpm' },
  formats: { name: 'Formats', icon: imageSvg, link: '#anch-formats', id: 'anch-formats' },
  listOfFrames: { name: 'Frames', icon: framePortraitSvg, link: '#anch-listOfFrames', id: 'anch-listOfFrames' },
  visualUnits: { name: 'Visual units', icon: copyIcon, link: '#anch-visualUnits', id: 'anch-visualUnits' },
  location: { name: 'Location', icon: locationSvg, link: '#anch-location', id: 'anch-location' },
  proximity: { name: 'Proximity', icon: proximitySvg, link: '#anch-proximity', id: 'anch-proximity' },
  target: { name: 'Target', icon: targetSvg, link: '#anch-target', id: 'anch-target' },
  tags: { name: 'Tags', icon: tagSvg, link: '#anch-tags', id: 'anch-tags' },
  map: { name: 'Map', icon: mapSvg, link: '#anch-map', id: 'anch-map' },
};

const Panel = () => {
  const popupRef = useRef();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const currentLine = useSelector((state) => state.dealManagement.commonDeal.currentLine);
  const lines = useSelector((state) => state.dealManagement.backupFormData.lines);
  const isAdServerMarket = useSelector(getIsAdServer);
  const hasVisualUnitsEnabled = useHasFeatureAccess(FeatureFlags.VISUAL_UNITS) && isAdServerMarket;
  const { isLineLevelCpmVisible } = useSelector(getLineLevelCpmSettings);
  const hasProximityEnabled = !isAdServerMarket;
  const hasGoogleMapsEnabled = useHasFeatureAccess(FeatureFlags.GOOGLEMAPS);
  const isPopupDisabled = !lines.length;
  const isReseller = useSelector(getIsReseller);

  const popupTrigger = (
    <div
      className={cx(
        'py-3.5 pl-8 pr-2 flex flex-nowrap items-center bg-neutral-100 border-t border-neutral-300 w-full',
        { 'text-neutral': isPopupDisabled },
      )}
      data-test-id="popup-trigger"
    >
      <p className="inline-block mr-2.5 w-20 truncate sub-header-base text-neutral-600">
        {currentLine.id ? `${currentLine.sortOrderNumber} ${currentLine.name}` : 'New line'}
      </p>
      <Button
        dataTestId="line-details"
        isDisabled={isPopupDisabled}
        btnShape={ButtonShape.CIRCLE}
        btnType={ButtonType.PRIMARY}
        onClick={() => setIsPopupOpen((prevState) => !prevState)}
      >
        <SVG src={chevronRight} />
      </Button>
    </div>
  );

  return (
    <section className="space-y-2.5 pl-8">
      <nav className="rounded-xl shadow-xl bg-neutral-100" data-test-id="panel-navigation">
        <ul className="py-2 px-4">
          <LinkItem name={links.commonDeal.name} icon={links.commonDeal.icon} link={links.commonDeal.link} />
        </ul>
        <div ref={popupRef} aria-label="line-details-popup" data-test-id="line-details-popup">
          <Popup
            parentElementRef={popupRef.current}
            isOpen={isPopupOpen}
            alignContent={PopupAlign.START}
            containerPosition={PopupPosition.RIGHT}
            onClickOutside={() => setIsPopupOpen(false)}
            content={<LineDetails closePopup={() => setIsPopupOpen(false)} />}
            arrowColor={ARROW_COLOR.PURPLE}
          >
            {popupTrigger}
          </Popup>
        </div>
        <ul className="p-4 space-y-2 border-t border-neutral-300">
          {Object.values(links).map(({ name, icon, link }) => {
            if (name === 'Deal') return null;
            if (name === 'Visual units' && !hasVisualUnitsEnabled) return null;
            if (name === 'CPM' && !isLineLevelCpmVisible) return null;
            if (name === 'Proximity' && !hasProximityEnabled) return null;
            if (name === 'Map' && !hasGoogleMapsEnabled) return null;
            if (name === 'Media owners' && !isReseller) return null;
            return <LinkItem key={name} name={name} icon={icon} link={link} />;
          })}
        </ul>
      </nav>
    </section>
  );
};

export default Panel;
/* eslint-enable sonarjs/cognitive-complexity */
