import PropTypes from 'prop-types';

import cx from 'classnames';

const DetailsItem = ({ label, value, icon }) => {
  return (
    <div className={cx('flex text-neutral-950-opacity-80', { 'flex-col': !icon }, { 'flex-row items-center': !!icon })}>
      {label ? <p className="body-base">{label}</p> : null}
      <div className={cx('flex', { 'sub-header-base': !icon })}>
        {icon}
        {value}
      </div>
    </div>
  );
};

DetailsItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node.isRequired,
  icon: PropTypes.node,
};

DetailsItem.defaultProps = {
  label: '',
  icon: null,
};

export default DetailsItem;
