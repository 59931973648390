import {
  PAGE_SIZE_DEFAULT,
  PAGE_SIZE_SMALL,
  DEFAULT_PAGE_SIZES,
  CONTENT_MANAGEMENT_PAGE_SIZES,
} from 'consts/pagination';

const isValidPageSize = (pageSize) => DEFAULT_PAGE_SIZES.includes(pageSize);

export const getValidPageSize = (pageSize) => {
  return isValidPageSize(Number(pageSize)) ? Number(pageSize) : PAGE_SIZE_DEFAULT;
};

const isValidContentManagementPageSize = (pageSize) => CONTENT_MANAGEMENT_PAGE_SIZES.includes(pageSize);

export const getValidContentManagementPageSize = (pageSize) => {
  return isValidContentManagementPageSize(Number(pageSize)) ? Number(pageSize) : PAGE_SIZE_SMALL;
};
