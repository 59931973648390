import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS, HEADERS_CONTENT_TYPE } from 'modules/api/constants';

export const uploadSetupFile = async (formData, cancelFunctions) => {
  const { send, cancel } = createRequest({
    url: URLS().SETUP_UPLOAD_FILE(),
    method: HTTP_METHODS.PUT,
    data: formData,
    timeout: 0,
    maxContentLength: Infinity,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.MULTIPART_FORM_DATA,
    },
  });

  if (cancelFunctions.SETUP_UPLOAD_FILE) cancelFunctions.SETUP_UPLOAD_FILE();
  cancelFunctions.SETUP_UPLOAD_FILE = cancel;

  const result = await send();

  return result.data;
};

export const fetchSetupFileDetails = async (fileId, cancelFunctions) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_FILE_ASSETS(fileId),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  if (cancelFunctions.FETCH_FILE_ASSETS) cancelFunctions.FETCH_FILE_ASSETS();
  cancelFunctions.FETCH_FILE_ASSETS = cancel;

  const result = await send();

  return result.data;
};

export const downloadSetupFile = (cancelFunctions, fileId) => {
  const { send, cancel } = createRequest({
    url: URLS().SETUP_DOWNLOAD_URL(fileId),
    method: HTTP_METHODS.GET,
    responseType: 'blob',
  });

  if (cancelFunctions.SETUP_DOWNLOAD_URL) cancelFunctions.SETUP_DOWNLOAD_URL();
  cancelFunctions.SETUP_DOWNLOAD_URL = cancel;

  return send();
};

export const fetchSetupList = async (cancelFunctions, extraQuery = '') => {
  const { send, cancel } = createRequest({
    url: URLS().SETUPLIST_DATA_URL(extraQuery),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  if (cancelFunctions.SETUPLIST_DATA_URL) cancelFunctions.SETUPLIST_DATA_URL();
  cancelFunctions.SETUPLIST_DATA_URL = cancel;

  const {
    headers,
    data: { setupListResponse },
  } = await send();

  return {
    totalElements: headers['total-elements'],
    setupList: setupListResponse.sort((a, b) => parseInt(b.fileId) - parseInt(a.fileId)),
  };
};
