import { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Auth from 'modules/Auth';
import Session from 'modules/Session';
import { PermissionsEnum } from 'consts/permissions';
import { HEADERS_CONTENT_TYPE, FILE_SIZE_100MB } from 'modules/api/constants';
import withLoader from 'components/hocs/withLoader';
import withRouter from 'components/hocs/withRouter';
import withCancelRequest from 'components/hocs/withCancelRequest';
import { getLookupData, getContentDealIdLookupData } from 'modules/api/lookups';
import { createCreative } from 'modules/api/content';
import InputWithValidation from 'components/patterns/InputWithValidation/InputWithValidation';
import FileUpload from 'components/patterns/FileUpload';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { FilterDsp } from 'components/common/FilterArea/Filters';
import { NotificationMessages } from 'consts/notifications';
import Card from 'components/patterns/Card';
import Footer from './Footer';
import styles from './UploadCreativeContent.pcss';

const cx = classNames.bind(styles);

class UploadCreativeContent extends Component {
  allowedFileTypes = '.png,.jpg,.jpeg,.avi,.mpeg,.mp4,.mov,.zip';

  creativeInputConfig = {
    elementConfig: {
      type: 'text',
      placeholder: 'Enter creative ID',
      name: 'externalId',
    },
    value: '',
    label: '',
    validations: {
      required: {
        value: true,
        error: false,
        message: 'Enter creative ID',
      },
    },
    valid: false,
    touched: false,
  };

  state = {
    dsp: {},
    externalId: this.creativeInputConfig,
    dealId: {},
    filesList: [],
    fileName: '',
    isSubmitDisabled: false,
  };

  getInput = (inputConfig) => <InputWithValidation inputConfig={inputConfig} onChange={this.inputChangedHandler} />;

  inputChangedHandler = (event, config) => {
    this.setState({
      [event.target.name]: config,
    });
  };

  onFileChange = (acceptedFiles, rejectedFiles) => {
    const { notifyError } = this.props;
    if (rejectedFiles.length > 0) {
      notifyError({ message: NotificationMessages.INVALID_UPLOAD });
    } else {
      this.setState({
        filesList: acceptedFiles,
        fileName: acceptedFiles[0].name,
      });
    }
  };

  onUploadCreativeHandler = async () => {
    const { cancelFunctions, navigate, notifyError, notifySuccess } = this.props;

    if (this.checkForRequestValidity()) {
      this.setState({ isSubmitDisabled: true });

      const formData = this.createRequestParams();

      try {
        await createCreative(cancelFunctions, formData);
        notifySuccess({ message: 'Creative Added' });
        navigate('/content-management/creatives');
      } catch (error) {
        this.setState({ isSubmitDisabled: false });
      }
    } else {
      notifyError({ message: 'Please Enter Mandatory Details.' });
    }
  };

  checkForRequestValidity = () => {
    let isValid = false;
    const { externalId, dsp, filesList } = this.state;
    if (dsp.code && externalId.value.trim() !== '' && filesList.length > 0) {
      isValid = true;
    }
    return isValid;
  };

  createRequestParams = () => {
    const { dsp, externalId, dealId, filesList } = this.state;
    const param = { auth0Identifier: Session.getEnvironmentId() };

    if (dealId.length) {
      param.dealId = dealId.map((d) => d.code);
    }

    const payload = {
      externalId: externalId.value.trim(),
      dspCode: dsp.code,
      market: [param],
    };

    const formData = new FormData();
    formData.append('file', filesList[0]);
    formData.append(
      'createCreativeDTO',
      new Blob([JSON.stringify(payload)], { type: HEADERS_CONTENT_TYPE.APPLICATION_JSON }),
    );
    return formData;
  };

  onCancelClick = () => {
    const { navigate } = this.props;
    navigate('/content-management/creatives');
  };

  onLookupSelect = (dropdownName, newValue) => {
    const selectedVal = newValue || {};
    this.setState({
      [dropdownName]: selectedVal,
    });

    if (dropdownName === 'dsp') {
      this.onLookupSelectMultiple('dealId', []);
    }
  };

  onLookupSelectMultiple = async (dropdownName, newValue) => {
    this.setState({ [dropdownName]: [...newValue] });
  };

  onLoadLookupData = async (dropdownName, query) => {
    const { cancelFunctions } = this.props;

    if (query.length >= 2) {
      try {
        if (dropdownName === 'dealId') {
          const { dsp } = this.state;
          return await getContentDealIdLookupData(cancelFunctions, Session.getEnvironmentId(), query, dsp.code);
        }

        return await getLookupData({ cancelFunctions, lookupURLName: dropdownName, query });
      } catch (error) {
        return [];
      }
    }
    return [];
  };

  render() {
    const { externalId, dealId, fileName, dsp, isSubmitDisabled } = this.state;

    return (
      <div className={cx('row')}>
        <div className="col-12">
          <Card
            dataTestId="upload-creative"
            header="Upload creative"
            footer={
              <Footer
                onSubmitClick={this.onUploadCreativeHandler}
                onCancelClick={this.onCancelClick}
                isSubmitDisabled={isSubmitDisabled}
              />
            }
          >
            <div className={cx('row', 'border-bottom', 'pt-3')}>
              {Auth.hasPermission(PermissionsEnum.CONTENT_DSP_VIEW) && (
                <div className={cx('col-12', 'col-md-6', 'col-lg-4')}>
                  <div className={cx('form-group', 'mb-3')}>
                    <span className={cx('sub-header-base', 'form-label')}>
                      DSP name <i className="body-base text-pinkRed-500">*</i>
                    </span>
                    <FilterDsp
                      dataTestId="dsp"
                      selectedValue={dsp.code ? dsp : null}
                      onChange={this.onLookupSelect}
                      placeholder="Search DSP name"
                    />
                  </div>
                </div>
              )}
              <div className={cx('col-12', 'col-md-6', 'col-lg-4')}>
                <div className={cx('form-group', 'mb-3', 'border_bottom')}>
                  {' '}
                  <label htmlFor="file">
                    Upload <i className="body-base text-pinkRed-500">*</i>
                  </label>
                  <FileUpload
                    acceptFileType={this.allowedFileTypes}
                    maxFileSize={FILE_SIZE_100MB}
                    placeholder={fileName === '' ? 'Upload creative' : fileName}
                    onFileChange={this.onFileChange}
                  />
                </div>
              </div>
              {Auth.hasPermission(PermissionsEnum.CONTENT_CREATIVE_ID_VIEW) && (
                <div className={cx('col-12', 'col-md-6', 'col-lg-4')}>
                  <div className={cx('form-group', 'mb-3')}>
                    <span className={cx('sub-header-base', 'form-label')}>
                      Creative ID <i className="body-base text-pinkRed-500">*</i>
                    </span>
                    {this.getInput(externalId)}
                  </div>
                </div>
              )}
            </div>
            <div className={cx('row', 'border-bottom', 'pt-3')}>
              <div className={cx('col-12', 'col-md-6', 'col-lg-4')}>
                <div className={cx('form-group', 'mb-3')}>
                  <span className={cx('sub-header-base', 'form-label')}>Deal ID</span>
                  <AsyncSelect
                    dataTestId="deal-id"
                    name="dealId"
                    loadData={this.onLoadLookupData}
                    selectedValue={dealId.length ? dealId : null}
                    placeholder="Search deal ID"
                    onSelect={this.onLookupSelectMultiple}
                    multiple
                    isDisabled={!dsp.code}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

UploadCreativeContent.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  navigate: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
};

export default withLoader(withCancelRequest(withRouter(UploadCreativeContent)));
