import { AuthenticationClient } from 'authing-js-sdk';
import * as config from 'config';
import axios from 'axios';
import { PermissionsEnum } from 'consts/permissions';
import { LocalStoredAuthData } from './Auth.types';

let authingInstance: AuthenticationClient;

const init = async (): Promise<void> => {
  if (authingInstance) {
    return;
  }

  // eslint-disable-next-line no-useless-catch
  try {
    authingInstance = await new AuthenticationClient({
      appId: config.authing.appId,
      appHost: config.authing.appHost,
    });
    // eslint-disable-next-line sonarjs/no-useless-catch
  } catch (error) {
    throw error;
  }
};

const getData = (): LocalStoredAuthData => {
  const emptyResponse = {
    userName: '',
    email: '',
    token: '',
    markets: [],
    // FIXME: Hardcoded for Authing as the service does not support permissions
    permissions: [
      PermissionsEnum.SETUP_PAGE_ACCESS,
      PermissionsEnum.SETUP_DOWNLOAD,
      PermissionsEnum.SETUP_UPLOAD,
      PermissionsEnum.ENVIRONMENT_SELECTION_PAGE_ACCESS,
    ],
  };

  try {
    const token = localStorage.getItem('token');

    if (!token) {
      return emptyResponse;
    }

    return {
      token,
      userName: localStorage.getItem('userName') || '',
      email: localStorage.getItem('email') || '',
      markets: JSON.parse(localStorage.getItem('markets') || '[]'),
      permissions: [
        PermissionsEnum.SETUP_PAGE_ACCESS,
        PermissionsEnum.SETUP_DOWNLOAD,
        PermissionsEnum.SETUP_UPLOAD,
        PermissionsEnum.ENVIRONMENT_SELECTION_PAGE_ACCESS,
      ],
    };
  } catch (error) {
    return emptyResponse;
  }
};

const redirectToUniversalLoginPage = async (): Promise<void> => {
  const url = `${config.authing.appHost}/oidc/auth?client_id=${
    config.authing.appId
  }&redirect_uri=${getRedirectUri()}&scope=openid%20profile%20email%20role&response_type=code`;
  window.location.href = url;
};

const isAuthenticated = async (): Promise<boolean> => {
  try {
    await authingInstance.checkLoggedIn();
    return true;
  } catch (error) {
    return false;
  }
};

const hasPermission = (permission: string): boolean => {
  const { permissions } = getData();
  return permissions.includes(permission);
};

const authenticate = async (): Promise<void> => {
  const code = new URLSearchParams(window.location.search).get('code') || '';

  const reqUrl = `${config.authing.appHost}/oidc/token`;
  const QS = new URLSearchParams({
    code,
    client_id: config.authing.appId,
    client_secret: config.authing.clientSecret,
    grant_type: 'authorization_code',
    redirect_uri: getRedirectUri(),
  }).toString();

  // eslint-disable-next-line no-useless-catch
  try {
    const code2tokenResponse = await axios.post(reqUrl, QS, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const token = code2tokenResponse.data.id_token || '';
    localStorage.setItem('token', token);
    const base64Url = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    const idTokenData = JSON.parse(atob(base64Url));

    localStorage.setItem('markets', JSON.stringify(idTokenData.role || []));
    localStorage.setItem('username', `${idTokenData.name} ${idTokenData.family_name}`);

    await authingInstance.setToken(token);
    // eslint-disable-next-line sonarjs/no-useless-catch
  } catch (error) {
    throw error;
  }
};

const logout = async (): Promise<void> => {
  await authingInstance.logout();
  window.location.href = getLogoutReturnUri();
};

export default {
  init,
  redirectToUniversalLoginPage,
  isAuthenticated,
  hasPermission,
  authenticate,
  logout,
  getData,
};

/**
 *
 * Private functions
 *
 */

function getRedirectUri(): string {
  const url = new URL(`${config.app.protocol}://${config.app.host}`);
  if (config.app.port) {
    url.port = config.app.port;
  }
  url.pathname = `auth-callback/`;
  return url.toString();
}

function getLogoutReturnUri(): string {
  const url = new URL(`${config.app.protocol}://${config.app.host}`);
  if (config.app.port) {
    url.port = config.app.port;
  }
  return url.toString();
}
