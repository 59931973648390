import { useCallback, useEffect } from 'react';
import { DeepPartial } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import {
  changeFormParams,
  changeDealLineAvailableImpressions,
  clearDisclaimer,
  resetDealLineAvailability,
  changeDealCurrentLineData,
} from 'store/dealManagement/reducer';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { Store } from 'components/common/types/Store.types';
import { getIsValidAvailabilityRequest } from 'store/dealManagement/selectors';
import { clearNotification, notifyError } from 'store/notification/reducer';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useDeepEqualMemo } from 'customHooks/useDeepEqualMemo';
import { checkAvailability } from 'modules/api/adsDeal';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import {
  Availability,
  DealStatus,
  FrontEndType,
  PreservedAvailability,
  ProgrammaticCampaign,
} from 'components/common/types/Deal.types';
import { getDealWithLineFormData } from 'components/pages/DealWithLines/AdsDealLines/transformPostData';
import { formatIndexOptimisationWithEmptyDisplayName } from 'utils/formatIndexOptimisation';
import { getIsAdServer } from 'store/publisher/selectors';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useCheckAvailability = (): void => {
  const cancelFunctions = useCancelRequest();
  const dispatch = useDispatch();
  const backupFormData = useSelector((state: Store) => state.dealManagement.backupFormData);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const programmatic = useSelector((state: Store) => state.dealManagement.programmatic);
  const campaignType = useSelector((state: Store) => state.dealManagement.campaignType);
  const commonDeal = useSelector((state: Store) => state.dealManagement.commonDeal);
  const selectedSegments = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.segment.selectedSegments,
  );
  const frontEndType = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frontEndType);
  const routeFrameCodes = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.routeFrameCodes);

  const isAdServerMarket = useSelector(getIsAdServer);
  const isValidAvailabilityRequest = useSelector(getIsValidAvailabilityRequest);

  const isDealForm = frontEndType === FrontEndType.STANDARD;
  const hasAdsDealLevelCPMEnabled = useHasFeatureAccess(FeatureFlags.ADS_DEAL_LEVEL_CPM);

  const dealData = useDeepEqualMemo<DeepPartial<ProgrammaticCampaign>>({
    ...commonDeal,
    ...programmatic,
    summary: undefined,
    currentLine: {
      ...commonDeal.currentLine,
      adjustedSot: undefined,
      deliveredImpressions: undefined,
      availability: undefined,
      preservedAvailability: undefined,
      preservedAllocation: undefined,
      routeFrameCodes: isDealForm ? routeFrameCodes : undefined,
      segment: {
        selectedSegmentDataProvider: undefined,
        selectedSegments,
      },
      isCurrentLineWithProposalAllocation: undefined,
    },
  }) as DeepPartial<ProgrammaticCampaign>;

  const getAvailability = useCallback(
    async (dealFormData: FormData): Promise<void> => {
      if (isValidAvailabilityRequest) {
        dispatch(clearNotification());
        dispatch(clearDisclaimer());
        dispatch(changeFormParams({ isForecastedAllocation: false }));
        dispatch(changeDealCurrentLineData({ isCurrentLineWithProposalAllocation: false }));
        dispatch(changeFormParams({ isFetchingAvailability: true }));

        try {
          const data: Availability & PreservedAvailability = await checkAvailability(dealFormData, cancelFunctions);

          if (!data.assets.length) {
            const message =
              'There are no frames available for the targeting criteria you have chosen. Please revise your targeting selection.';

            dispatch(notifyError({ message, timeout: NOTIFICATION_TIMEOUT.LONG }));
            throw new Error(message);
          }
          dispatch(changeDealLineAvailableImpressions(data));
        } catch {
          dispatch(resetDealLineAvailability());
        }
        dispatch(changeFormParams({ isFetchingAvailability: false }));
        return;
      }

      dispatch(resetDealLineAvailability());
    },
    [dealData, campaignType],
  );

  useEffect(() => {
    const backupLine = backupFormData.lines.find((line) => line.id === commonDeal.currentLine.id);
    const isRejected = commonDeal.bookingStatusCode === DealStatus.REJECTED;

    const backupLineOptimisations = {
      ...backupLine,
      indexOptimisation: formatIndexOptimisationWithEmptyDisplayName(backupLine?.indexOptimisation),
    };
    const currentLineOptimisations = {
      ...commonDeal.currentLine,
      indexOptimisation: formatIndexOptimisationWithEmptyDisplayName(commonDeal.currentLine.indexOptimisation),
    };

    const currentLine = {
      ...commonDeal.currentLine,
      proximity: {
        ...commonDeal.currentLine.proximity,
        plannerPoi: [],
        openStreetMapPoi: [],
      },
      routeFrameCodes: isDealForm ? routeFrameCodes : [],
    };

    if (isNewDeal || !isEqual(backupLineOptimisations, currentLineOptimisations) || isRejected) {
      getAvailability(
        getDealWithLineFormData(
          {
            ...commonDeal,
            ...programmatic,
            currentLine,
            bookingStatusCode: isRejected ? DealStatus.REJECTED : undefined,
            hasAdsDealLevelCPMEnabled,
          },
          { isAdServerMarket },
        ),
      );
    }
  }, [
    dealData.currentLine?.impressions,
    dealData.currentLine?.budget,
    dealData.currentLine?.frames,
    dealData.currentLine?.sot,
    dealData.currentLine?.indexOptimisation?.length,
    dealData.currentLine?.segment?.selectedSegmentDataProvider?.length,
    dealData.currentLine?.sweep,
    dealData.currentLine?.environments?.length,
    dealData.currentLine?.startDate,
    dealData.currentLine?.endDate,
    dealData.currentLine?.selectedDays,
    JSON.stringify(dealData.currentLine?.proximity?.plannerPoi),
    dealData.currentLine?.routeFrameCodes,
    dealData.currentLine?.tags,
  ]);
};
