import { useMemo } from 'react';
import { CodeNameModel } from 'components/common/types';
import { BIDS_LOSS_REASONS, CREATIVE_LOSS_REASONS, OTHER_LOSS_REASONS } from 'consts/diagnostics';
import { LossType } from 'consts/lossType';
import BaseSelect from 'lib/BaseSelect';
import { findOption } from 'lib/BaseSelect/utils';
import { FilterLossCodeProps, LossReasonsDropdownOptions } from './FilterLossCode.types';

export const allLossReasons = { ...BIDS_LOSS_REASONS, ...CREATIVE_LOSS_REASONS, ...OTHER_LOSS_REASONS };

export const lossReasonsMap = {
  [LossType.BID]: BIDS_LOSS_REASONS,
  [LossType.CREATIVE]: CREATIVE_LOSS_REASONS,
  [LossType.OTHER]: OTHER_LOSS_REASONS,
};

const FilterLossCode: React.FC<FilterLossCodeProps> = ({ changeFilters, lossCode = '', lossType }) => {
  const lossReasons: LossReasonsDropdownOptions[] = Object.entries(lossType ? lossReasonsMap[lossType] : allLossReasons)
    .map(([field, item]) => ({
      code: item.id,
      name: `${item.label} (${item.code})`,
      field,
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const selectedLossReason = useMemo(() => findOption(lossReasons, 'code', lossCode), [lossCode, lossReasons]);

  return (
    <BaseSelect
      dataTestId="lossCode-select"
      name="lossCode"
      options={lossReasons}
      selectedValue={selectedLossReason}
      onSelect={(_, value: CodeNameModel) => changeFilters({ lossCode: value?.code })}
      placeholder="Select loss code"
      isClearable
    />
  );
};

export default FilterLossCode;
