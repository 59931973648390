import { Code, File, TransformedFile } from './framesUtils.types';

export const getFramesListData = (
  codes: Code[] = [],
  isAdServerMarket: boolean,
): {
  incFrameList: { frames: ({ mediaOwner: string; assetId: string } | string)[] }[];
  excFrameList: { frames: ({ mediaOwner: string; assetId: string } | string)[] }[];
} => {
  const formatFrameData = ({
    code,
    mediaOwner,
  }: {
    code: string;
    mediaOwner: string;
  }): { mediaOwner: string; assetId: string } | string =>
    isAdServerMarket
      ? {
          assetId: code,
          mediaOwner,
        }
      : code;

  const incFrameList = codes
    .filter(({ include }) => include)
    .map(({ code, mediaOwner }) => formatFrameData({ code, mediaOwner }));

  const excFrameList = codes
    .filter(({ include }) => !include)
    .map(({ code, mediaOwner }) => formatFrameData({ code, mediaOwner }));

  return {
    incFrameList: incFrameList.length ? [{ frames: incFrameList }] : [],
    excFrameList: excFrameList.length ? [{ frames: excFrameList }] : [],
  };
};
const transformFile = ({ name, fileId, dynamoDbFileId }: File): TransformedFile => ({
  fileName: name,
  ...(fileId ? { fileId } : {}),
  ...(dynamoDbFileId ? { dynamoDbFileId } : {}),
});

export const getFramesFileData = (
  files: File[] = [],
): { incFileList: TransformedFile[]; excFileList: TransformedFile[] } => {
  const incFileList = files.filter(({ include }) => include).map(transformFile);
  const excFileList = files.filter(({ include }) => !include).map(transformFile);

  return {
    incFileList,
    excFileList,
  };
};

export const getVisualUnitsData = (
  visualUnitCodes: Code[],
  visualUnitFiles: File[],
  isAdServerMarket: boolean,
): {
  includeVisualUnits?: (TransformedFile | { frames: ({ assetId: string; mediaOwner: string } | string)[] })[];
  excludeVisualUnits?: (TransformedFile | { frames: ({ assetId: string; mediaOwner: string } | string)[] })[];
} => {
  const { incFrameList, excFrameList } = getFramesListData(visualUnitCodes, isAdServerMarket);
  const { incFileList, excFileList } = getFramesFileData(visualUnitFiles);

  const includeVisualUnits = [...incFrameList, ...incFileList];
  const excludeVisualUnits = [...excFrameList, ...excFileList];

  return {
    ...(includeVisualUnits.length ? { includeVisualUnits } : {}),
    ...(excludeVisualUnits.length ? { excludeVisualUnits } : {}),
  };
};

export const getVisualUnitData = (
  visualUnitCodes: Code[],
  visualUnitFiles: File[],
  isAdServerMarket: boolean,
): ReturnType<typeof getVisualUnitsData> => getVisualUnitsData(visualUnitCodes, visualUnitFiles, isAdServerMarket);
