import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getLookupData } from 'modules/api/lookups';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { isReadOnly } from 'utils/isReadOnly';
import withCancelRequest from 'components/hocs/withCancelRequest';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { DealStatus } from 'components/common/types/Deal.types';
import multiPublisherRequest from 'modules/api/multiPublisherRequest';
import { getIsReseller } from 'store/publisher/selectors';
import { getResellerDealMediaOwners } from 'store/reseller/selectors';

const Location = ({
  cancelFunctions,
  countries,
  onChangeCountries,
  streets,
  onChangeStreets,
  cities,
  onChangeCities,
  postCodes,
  onChangePostCodes,
  counties,
  onChangeCounties,
}) => {
  const isEditingDisabled = useSelector((state) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);
  const terminated = useSelector((state) => state.dealManagement.commonDeal.currentLine.terminated);
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled, terminated) && !isPendingReservation;
  const resellerMediaOwners = useSelector(getResellerDealMediaOwners);
  const isReseller = useSelector(getIsReseller);

  const onLoadLookupData = async (dropdownName, query) => {
    if (query.length < 2) return [];

    try {
      const requestArguments = { cancelFunctions, lookupURLName: dropdownName, query };
      if (isReseller) {
        const response = await multiPublisherRequest(resellerMediaOwners, getLookupData, {
          ...requestArguments,
        });
        return response.map(({ publisher, code, name }) => ({
          code,
          mediaOwner: publisher,
          name: `${publisher} : ${name}`,
        }));
      }
      return await getLookupData(requestArguments);
    } catch (error) {
      return [];
    }
  };

  return (
    <div className="grid grid-cols-2 gap-x-7 xl:gap-x-24 gap-y-11 px-12 py-6" data-test-id="location">
      {Auth.hasPermission(PermissionsEnum.DEAL_LOCATION_COUNTRY_VIEW) ? (
        <div>
          <p className="mb-2 sub-header-base text-neutral-950-opacity-60" data-test-id="country-header">
            Country
          </p>
          <AsyncSelect
            dataTestId="country"
            name="country"
            loadData={onLoadLookupData}
            selectedValue={countries}
            placeholder="Select country"
            onSelect={(_, value) => {
              onChangeCountries(value);
            }}
            multiple
            isDisabled={readOnly}
          />
        </div>
      ) : null}
      {Auth.hasPermission(PermissionsEnum.DEAL_LOCATION_STREET_VIEW) ? (
        <div>
          <p className="mb-2 sub-header-base text-neutral-950-opacity-60" data-test-id="street-header">
            Street
          </p>
          <AsyncSelect
            dataTestId="street"
            name="address"
            loadData={onLoadLookupData}
            selectedValue={streets}
            placeholder="Select street"
            onSelect={(_, value) => {
              onChangeStreets(value);
            }}
            multiple
            isDisabled={readOnly}
          />
        </div>
      ) : null}
      {Auth.hasPermission(PermissionsEnum.DEAL_LOCATION_CITY_VIEW) ? (
        <div>
          <p className="mb-2 sub-header-base text-neutral-950-opacity-60" data-test-id="city-header">
            City
          </p>
          <AsyncSelect
            dataTestId="city"
            name="town"
            loadData={onLoadLookupData}
            selectedValue={cities}
            placeholder="Select city"
            onSelect={(_, value) => {
              onChangeCities(value);
            }}
            multiple
            isDisabled={readOnly}
          />
        </div>
      ) : null}
      {Auth.hasPermission(PermissionsEnum.DEAL_LOCATION_COUNTY_VIEW) ? (
        <div>
          <p className="mb-2 sub-header-base text-neutral-950-opacity-60" data-test-id="county-header">
            County/State/Province
          </p>
          <AsyncSelect
            dataTestId="county"
            name="county"
            loadData={onLoadLookupData}
            selectedValue={counties}
            placeholder="Select address"
            onSelect={(_, value) => {
              onChangeCounties(value);
            }}
            multiple
            isDisabled={readOnly}
          />
        </div>
      ) : null}
      {Auth.hasPermission(PermissionsEnum.DEAL_LOCATION_POSTCODE_VIEW) ? (
        <div>
          <p className="mb-2 sub-header-base text-neutral-950-opacity-60" data-test-id="postcode-header">
            Postcode
          </p>
          <AsyncSelect
            dataTestId="postcode"
            name="postcode"
            loadData={onLoadLookupData}
            selectedValue={postCodes}
            placeholder="Select postcode/zipcode"
            onSelect={(_, value) => {
              onChangePostCodes(value);
            }}
            multiple
            isDisabled={readOnly}
          />
        </div>
      ) : null}
    </div>
  );
};

Location.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  countries: PropTypes.array,
  onChangeCountries: PropTypes.func.isRequired,
  streets: PropTypes.array,
  onChangeStreets: PropTypes.func.isRequired,
  cities: PropTypes.array,
  onChangeCities: PropTypes.func.isRequired,
  postCodes: PropTypes.array,
  onChangePostCodes: PropTypes.func.isRequired,
  counties: PropTypes.array,
  onChangeCounties: PropTypes.func.isRequired,
};

Location.defaultProps = {
  countries: [],
  streets: [],
  cities: [],
  postCodes: [],
  counties: [],
};

export default withCancelRequest(Location);
