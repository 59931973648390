export type AssetItemProps = {
  text: string;
  icon: React.ReactElement;
  amountOfRecords: number;
};

const AssetItem: React.FC<AssetItemProps> = ({ text, icon, amountOfRecords }) => {
  return (
    <div className="grid items-center gap-x-3.5 grid-cols-12 place-content-start">
      {icon}
      <p className="sub-header-base ml-2 col-span-2">{amountOfRecords}</p>
      <p className="body-base col-span-9 m-0">{text}</p>
    </div>
  );
};

export default AssetItem;
