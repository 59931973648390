import Input from 'lib/Input';
import { FilterCreativeSetCodeProps } from './FilterCreativeSetCode.types';

const FilterCreativeSetCode: React.FC<FilterCreativeSetCodeProps> = ({ creativeSetCode = '', changeFilters }) => {
  return (
    <Input
      dataTestId="creative-set-code"
      name="creativeId"
      value={creativeSetCode}
      placeholder="Creative set code"
      onChange={(value: string) => changeFilters({ creativeSetCode: value })}
      debounceTime={1000}
    />
  );
};

export default FilterCreativeSetCode;
