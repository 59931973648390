import { Agency } from 'components/common/types/Deal.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { FilterAgencyProps } from './FilterAgency.types';
import { useLoadLookup } from '../useLoadLookup';

const FilterAgency: React.FC<FilterAgencyProps> = ({ agency, changeFilters }) => {
  const { onLoadLookup } = useLoadLookup();

  const handleSelectAgency = (_name: string, value: Agency): void => {
    changeFilters({ agency: value });
  };

  return (
    <AsyncSelect
      name="agency"
      selectedValue={agency}
      loadData={onLoadLookup}
      onSelect={handleSelectAgency}
      placeholder="Search agency"
      dataTestId="agency-filter"
      textKey="name"
    />
  );
};

export default FilterAgency;
