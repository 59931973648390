import { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import Modal from 'components/patterns/Modal';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import binSvg from 'assets/icons/bin.svg';

class DeleteRenderer extends Component {
  state = {
    showDialog: false,
  };

  toggleDialog = () => {
    const { showDialog } = this.state;

    this.setState({ showDialog: !showDialog });
  };

  onDelete = () => {
    const { data, onDeleteFrameTag } = this.props;

    if (data.marketTagId) {
      onDeleteFrameTag(data.marketTagId);
    }
  };

  render() {
    const { data } = this.props;
    const { showDialog } = this.state;

    return (
      <>
        <Button
          onClick={this.toggleDialog}
          btnShape={ButtonShape.CIRCLE}
          btnType={ButtonType.ICON}
          isDisabled={!data.marketTagId}
        >
          <SVG src={binSvg} />
        </Button>
        <Modal
          isOpen={showDialog}
          icon={<SVG src={binSvg} className="text-pinkRed-500" />}
          title="Remove Tag?"
          actionButtons={
            <>
              <Button btnType={ButtonType.PRIMARY} btnShape={ButtonShape.NORMAL} onClick={this.toggleDialog}>
                Cancel
              </Button>
              <Button btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL} onClick={this.onDelete}>
                Remove
              </Button>
            </>
          }
        >
          <p className="ml-8 body-base text-neutral-950-opacity-60">
            Are you sure you want to remove this frame tag? This may affect the frame availability for your creative.
          </p>
        </Modal>
      </>
    );
  }
}

DeleteRenderer.propTypes = {
  data: PropTypes.shape({
    marketTagId: PropTypes.string,
  }).isRequired,
  onDeleteFrameTag: PropTypes.func,
};

DeleteRenderer.defaultProps = {
  onDeleteFrameTag: () => undefined,
};

export default DeleteRenderer;
