import { DealType, DealTypeLabel } from 'components/common/types/Deal.types';

export type DealTypesList = {
  code: DealType;
  name: DealTypeLabel;
}[];

export const dealTypesList: DealTypesList = [
  {
    code: DealType.NON_GUARANTEED_FLOOR_PRICE,
    name: DealTypeLabel.NON_GUARANTEED_FLOOR_PRICE,
  },
  {
    code: DealType.NON_GUARANTEED_FIXED_PRICE,
    name: DealTypeLabel.NON_GUARANTEED_FIXED_PRICE,
  },
  {
    code: DealType.GUARANTEED,
    name: DealTypeLabel.GUARANTEED,
  },
];
