import { convertHoursAndMinutesToSeconds, getCurrentDateWithTimezone } from 'utils/dateFormatUtil';

const getSelectedDaysWithoutPastDayParts = ({ selectedDays, latestFrameTimezone }, currentDate) => {
  const latestFrameDate = getCurrentDateWithTimezone(latestFrameTimezone);
  const latestFrameTimeInSeconds = convertHoursAndMinutesToSeconds(
    latestFrameDate.getHours(),
    latestFrameDate.getMinutes(),
  );
  const selectedDaysWithFilteredPastDayParts = {};

  Object.values(selectedDays).forEach((selectedDay) => {
    if (new Date(selectedDay.id) < currentDate) {
      return;
    }

    if (new Date(selectedDay.id) > currentDate) {
      selectedDaysWithFilteredPastDayParts[selectedDay.id] = selectedDay;
    }

    const filteredDayParts = selectedDay.dayParts.filter(({ endOffset }) => endOffset > latestFrameTimeInSeconds);

    if (filteredDayParts.length) {
      selectedDaysWithFilteredPastDayParts[selectedDay.id] = { ...selectedDay, dayParts: filteredDayParts };
    }
  });

  return selectedDaysWithFilteredPastDayParts;
};

export const getDuplicateLineState = (backupLine) => {
  const currentDateWithTimezone = getCurrentDateWithTimezone(backupLine.latestFrameTimezone);
  const currentDate = currentDateWithTimezone ? new Date(currentDateWithTimezone.setHours(0, 0, 0, 0)) : new Date();

  return {
    ...backupLine,
    id: null,
    lineId: null,
    name: `${backupLine.name} - copy`,
    startDate: backupLine.startDate < currentDate ? currentDate : backupLine.startDate,
    endDate: backupLine.endDate < currentDate ? currentDate : backupLine.endDate,
    terminated: false,
    selectedDays:
      currentDate < backupLine.startDate
        ? backupLine.selectedDays
        : getSelectedDaysWithoutPastDayParts(backupLine, currentDate),
  };
};
export const getLineById = (lines, id) => lines.find((line) => line.id === id);

export const getAssetByFrameId = (assets, frameId) => assets.find((asset) => frameId === asset.frameId);
