export enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface CheckboxProps {
  onChange: VoidFunction;
  isChecked: boolean;
  isDisabled?: boolean;
  id?: string;
  theme?: Theme;
}
