import { Store } from 'components/common/types/Store.types';
import { AUTOMATION_MARKET_CREATIVE_BID_STREAM_DSP, AUTOMATION_MARKET_DSP_WITH_ACTIVE_SYNC } from 'consts/dspSettings';
import { useSelector } from 'react-redux';
import { getAdServerDealConfigValue } from 'store/dealConfig/utils';
import { FeatureFlags } from 'components/common/types/Features.types';
import { DealType } from 'components/common/types/Deal.types';
import { useHasFeatureAccess } from './useHasFeatureAccess';

interface UseDspSettingsResult {
  isDspSettingsActive: boolean;
  isSelectedDspSupportingCreativeSubmissionInBidStream: boolean;
  isSelectedDspSupportingSync: boolean;
  isSelectedDspSupportingOpenMarketplace: boolean;
}

export const useDspSettings = (): UseDspSettingsResult => {
  const dealConfig = useSelector((state: Store) => state.dealConfig);
  const marketId = useSelector((state: Store) => state.publisher.configuration.marketId);
  const dsp = useSelector((state: Store) => state.dealManagement.programmatic.dsp);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);

  const hasCreativeBidStreamFeatureFlagEnabled = useHasFeatureAccess(FeatureFlags.CREATIVE_BID_STREAM);
  const hasDspSyncEnabled = useHasFeatureAccess(FeatureFlags.DSP_SYNC);

  const adServerCreativeSubmissionInBidStreamDsps =
    getAdServerDealConfigValue(dealConfig, 'dspsSupportingCreativeSubmissionInBidStream') || [];
  const automationCreativeSubmissionInBidStreamDsps = AUTOMATION_MARKET_CREATIVE_BID_STREAM_DSP[marketId] || [];

  const isSelectedDspSupportingCreativeSubmissionInBidStream =
    !!dsp &&
    dealType !== DealType.GUARANTEED &&
    (dealConfig.isAdServer
      ? adServerCreativeSubmissionInBidStreamDsps.includes(dsp.code)
      : hasCreativeBidStreamFeatureFlagEnabled && automationCreativeSubmissionInBidStreamDsps.includes(dsp.code));

  const adServerDspsThatSupportSync = getAdServerDealConfigValue(dealConfig, 'dspsThatSupportSynchronisation') || [];
  const automationDspsThatSupportSync = AUTOMATION_MARKET_DSP_WITH_ACTIVE_SYNC[marketId] || [];

  const isSelectedDspSupportingSync =
    !!dsp &&
    (dealConfig.isAdServer
      ? adServerDspsThatSupportSync.includes(dsp.code)
      : hasDspSyncEnabled && automationDspsThatSupportSync.includes(dsp.code));

  const adServerDspsThatSupportOpenMarketplace = getAdServerDealConfigValue(dealConfig, 'enableOpenMarketplace') || [];
  const isSelectedDspSupportingOpenMarketplace =
    !!dsp &&
    dealConfig.isAdServer &&
    dealType === DealType.NON_GUARANTEED_FLOOR_PRICE &&
    adServerDspsThatSupportOpenMarketplace.includes(dsp.code);

  const isDspSettingsActive =
    isSelectedDspSupportingSync ||
    isSelectedDspSupportingCreativeSubmissionInBidStream ||
    isSelectedDspSupportingOpenMarketplace;

  return {
    isDspSettingsActive,
    isSelectedDspSupportingCreativeSubmissionInBidStream,
    isSelectedDspSupportingSync,
    isSelectedDspSupportingOpenMarketplace,
  };
};
