import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Environment } from 'components/common/types/Environment.types';
import Session from 'modules/Session';

const initialState: Environment = {
  environmentId: Session.getEnvironmentId() || '',
};

const environmentSlice = createSlice({
  name: 'environment',
  initialState,
  reducers: {
    changeEnvironmentId: (state: Environment, action: PayloadAction<string>) => {
      return {
        ...state,
        environmentId: action.payload,
      };
    },
  },
});

export const { changeEnvironmentId } = environmentSlice.actions;
export default environmentSlice.reducer;
