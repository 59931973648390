import FilterArea from 'components/common/FilterArea';
import { FilterStatus } from 'components/common/FilterArea/Filters';
import FilterAdvertiser from 'components/common/FilterArea/Filters/FilterAdvertiser';
import FilterBrand from 'components/common/FilterArea/Filters/FilterBrand';
import FilterDateRange from 'components/common/FilterArea/Filters/FilterDateRange';
import FilterDealId from 'components/common/FilterArea/Filters/FilterDealId';
import FilterDealType from 'components/common/FilterArea/Filters/FilterDealType';
import FilterDsp from 'components/common/FilterArea/Filters/FilterDsp';
import FilterTradingModel from 'components/common/FilterArea/Filters/FilterTradingModel';
import FilterTradingTime from 'components/common/FilterArea/Filters/FilterTradingTime';
import { DealStatus, DealStatusLabel } from 'components/common/types/Deal.types';
import { FeatureFlags } from 'components/common/types/Features.types';
import { DiagnosticsFilters } from 'components/common/types/InsightsDeal';
import { dealTypesList } from 'consts/dealTypesList';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { usePageFilters } from 'customHooks/usePageFilters';

const dealStatusesList = [
  {
    code: DealStatus.LIVE,
    name: DealStatusLabel.LIVE,
  },
  {
    code: DealStatus.ENDED,
    name: DealStatusLabel.ENDED,
  },
  {
    code: DealStatus.TERMINATED,
    name: DealStatusLabel.TERMINATED,
  },
];

const Filters: React.FC = () => {
  const isStatusDropdownVisible = useHasFeatureAccess(FeatureFlags.INSIGHTS_STATUS_FILTER);

  const {
    filters: { deal, startDate, endDate, tradingModel, time, dsp, advertiser, brand, dealType, status },
    changeFilters,
  } = usePageFilters<DiagnosticsFilters>('insights/dashboard');

  return (
    <FilterArea>
      <div className="grid grid-cols-3 gap-x-4 mr-4">
        <div className="flex flex-col gap-y-4">
          <FilterDealId changeFilters={changeFilters} deal={deal} />
          <FilterAdvertiser changeFilters={changeFilters} selectedAdvertiser={advertiser} />
        </div>
        <div className="flex flex-col gap-y-4">
          <FilterDsp onChange={(name, value) => changeFilters({ [name]: value })} selectedValue={dsp} />
          <FilterBrand changeFilters={changeFilters} brand={brand} />
        </div>
        <div className="flex flex-col gap-y-4">
          <FilterDealType dealType={dealType} changeFilters={changeFilters} dealTypesList={dealTypesList} />
          {isStatusDropdownVisible ? (
            <FilterStatus changeFilters={changeFilters} statusesList={dealStatusesList} status={status} />
          ) : null}
        </div>
      </div>
      <div className="flex gap-x-4 justify-end">
        <div className="flex flex-col items-end">
          <FilterDateRange selectedStartDate={startDate} selectedEndDate={endDate} changeFilters={changeFilters} />
        </div>
        <div className="flex flex-col items-end gap-y-4">
          <FilterTradingModel selectedTradingModel={tradingModel} changeFilters={changeFilters} />
          <FilterTradingTime selectedTime={time} changeFilters={changeFilters} />
        </div>
      </div>
    </FilterArea>
  );
};

export default Filters;
