import PropTypes from 'prop-types';
import Chart from 'components/patterns/HighChart/Chart/Chart';
import merge from 'lodash/merge';
import SETTINGS from '../Settings';

function legendItemClick() {
  const value = { point: { options: { ...this.options } } };
  this.series.options.events.click(value);
  this.select();
  return false; // <== returning false will cancel the default action
}

const VioohPieChart = ({
  data,
  pointFormat,
  showInLegend,
  startAngle,
  endAngle,
  colors,
  innerSize,
  enableDataLabel,
  dataLabelFormat,
  options,
  enableExporting,
  chartTitle,
  chartSize,
  titleVerticalAlign,
  titleTextAlign,
  titleYPosition,
  titleXPosition,
  titleStyle,
  legend,
  onClick,
  callback,
  subTitle,
  chart,
  dataTestId,
}) => {
  const pieOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: chartSize,
      backgroundColor: 'transparent',
    },
    title: {
      text: chartTitle,
      align: titleTextAlign,
      verticalAlign: titleVerticalAlign,
      style: titleStyle,
      y: titleYPosition,
      x: titleXPosition,
      useHTML: true,
    },
    tooltip: {
      pointFormat,
      backgroundColor: SETTINGS.COLORS.lightAir,
      borderColor: SETTINGS.COLORS.lightAir,
      borderRadius: 8,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: enableDataLabel,
          format: dataLabelFormat,
        },
        showInLegend,
        colors,
        startAngle,
        endAngle,
        center: ['50%', '50%'],
        title: '',
        point: {
          events: {
            legendItemClick,
          },
        },
      },
    },
    legend: {
      labelFormatter() {
        /* eslint-disable-next-line @typescript-eslint/no-this-alias */
        const self = this;
        return self.legendName || self.name;
      },
      ...legend,
    },
    exporting: {
      enabled: enableExporting,
    },
    series: [
      {
        colorByPoint: true,
        innerSize,
        data,
        events: {
          click: onClick,
        },
      },
    ],
  };

  const setDynamicSubTitle = (title) => {
    if (!chart) {
      return;
    }

    const series = chart.series[0];
    const x = chart.plotLeft + series.center[0];
    const y = chart.plotTop + series.center[1];
    const innerWidth = series.center[3];
    const padding = 30;

    if (chart.subTitle) {
      chart.subTitle.destroy();
    }

    chart.subTitle = chart.renderer
      .text(title, x, y, true)
      .css({
        'text-align': 'center',
        width: innerWidth - 2 * padding,
        color: 'black',
      })
      .add();

    const box = chart.subTitle.getBBox();
    chart.subTitle.attr({
      x: x - box.width / 2,
      y: 175,
    });
  };

  const chartOptions = merge({}, pieOptions, options);

  if (subTitle) {
    setDynamicSubTitle(subTitle);
  }

  return <Chart dataTestId={dataTestId} options={chartOptions} callback={callback} />;
};

VioohPieChart.propTypes = {
  dataTestId: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
  pointFormat: PropTypes.string,
  showInLegend: PropTypes.bool,
  startAngle: PropTypes.number,
  endAngle: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.string),
  innerSize: PropTypes.string,
  dataLabelFormat: PropTypes.string,
  enableDataLabel: PropTypes.bool,
  enableExporting: PropTypes.bool,
  options: PropTypes.shape({}),
  chartTitle: PropTypes.string,
  chartSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleVerticalAlign: PropTypes.string,
  titleTextAlign: PropTypes.string,
  titleYPosition: PropTypes.number,
  titleXPosition: PropTypes.number,
  titleStyle: PropTypes.shape({}),
  legend: PropTypes.shape({}),
  onClick: PropTypes.func,
  callback: PropTypes.func,
  subTitle: PropTypes.string,
  chart: PropTypes.node,
};

VioohPieChart.defaultProps = {
  dataTestId: '',
  pointFormat: SETTINGS.PIE.POINT_FORMAT,
  dataLabelFormat: SETTINGS.PIE.DATA_LABEL_FORMAT,
  showInLegend: true,
  startAngle: 0,
  endAngle: 360,
  colors: [],
  innerSize: '0%',
  enableDataLabel: true,
  enableExporting: true,
  options: {},
  chartTitle: '',
  chartSize: '75%',
  titleVerticalAlign: undefined,
  titleTextAlign: 'center',
  titleYPosition: undefined,
  titleXPosition: 0,
  titleStyle: {},
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    itemMarginBottom: 0,
  },
  onClick: () => undefined,
  callback: () => undefined,
  subTitle: '',
  chart: undefined,
};

export default VioohPieChart;
