import { AgGridReact } from 'ag-grid-react';
import classNames from 'classnames/bind';
import { AllocationReportTableParams } from '../useAllocationReport';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const AllocationReportTable: React.FC<AllocationReportTableParams> = ({
  columnDefs,
  gridOptions,
  onGridReady,
  rowData,
}) => {
  return (
    <div className={cx('ag-theme-material allocation-report-table h-full')}>
      <AgGridReact
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={rowData}
        rowHeight={36}
      />
    </div>
  );
};
