import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { DEFAULT_PAGE_SIZES } from 'consts/pagination';
import Button, { ButtonShape } from 'components/patterns/Button';
import styles from './PageSize.pcss';

const cx = classNames.bind(styles);

const PageSize = ({ onPageSizeChange, pageSize, dataTestId, pageInfoText, listOfPageSizes }) => {
  return (
    <div className={cx('page-size', 'flex items-center text-neutral-950-opacity-60')} data-test-id={dataTestId}>
      <span className="mr-1 sub-header-sm">Display</span>
      {listOfPageSizes.map((size) => (
        <span className="mx-1" key={uuidv4()}>
          <Button
            onClick={() => {
              onPageSizeChange(size);
            }}
            btnType={cx('body-sm', { '!bg-primary-800 text-neutral-50': pageSize === size })}
            btnShape={ButtonShape.NORMAL}
          >
            {size}
          </Button>
        </span>
      ))}
      <span className="ml-1 sub-header-sm">{pageInfoText}</span>
    </div>
  );
};

PageSize.propTypes = {
  dataTestId: PropTypes.string,
  pageInfoText: PropTypes.string,
  onPageSizeChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  listOfPageSizes: PropTypes.arrayOf(PropTypes.number),
};

PageSize.defaultProps = {
  dataTestId: '',
  pageInfoText: '',
  listOfPageSizes: DEFAULT_PAGE_SIZES,
};

export default PageSize;
