import { useState } from 'react';
import SVG from 'react-inlinesvg';
import { copyToClipboard } from 'utils/copyToClipboard';
import copyIcon from 'assets/icons/copy.svg';
import checkmark from 'assets/icons/checkmark.svg';
import { DealData } from './DealIdRenderer.types';

const DealIdRenderer: React.FC<DealData> = ({ data }) => {
  const [copied, setCopied] = useState<boolean>(false);
  if (!data?.dealId) return <></>;

  return (
    <button
      type="button"
      onClick={() => {
        copyToClipboard(data.dealId);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      }}
      className="group p-1 border border-transparent hover:rounded hover:bg-purple-100 flex items-center"
    >
      <span>{data.dealId}</span>
      <div className="hidden group-hover:flex items-center w-4 h-4">
        {copied ? (
          <span data-test-id="checkmark">
            <SVG src={checkmark} />
          </span>
        ) : (
          <span data-test-id="copy-icon">
            <SVG src={copyIcon} />
          </span>
        )}
      </div>
    </button>
  );
};

export default DealIdRenderer;
