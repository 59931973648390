import closeSquareSvg from 'assets/icons/close_square.svg';
import FilterArea from 'components/common/FilterArea';
import { FilterAdvertiser, FilterBrand, FilterDsp } from 'components/common/FilterArea/Filters';
import FilterVisualUnitCode from 'components/common/FilterArea/Filters/FilterVisualUnitCode';
import { PermissionsEnum } from 'consts/permissions';
import { usePageFilters } from 'customHooks/usePageFilters';
import Button from 'lib/Button';
import Auth from 'modules/Auth';
import FilterCreativeSetCode from './FilterCreativeSetCode';
import { CreativeSetsFilters } from './Filters.types';

const Filters: React.FC = () => {
  const {
    changeFilters,
    filters: { creativeSetCode, dsp, advertiser, brand, visualUnitCode },
    resetFilters,
  } = usePageFilters<CreativeSetsFilters>('/content-management/creative-sets');

  const isFilterEmpty = !creativeSetCode && !dsp && !advertiser && !brand && !visualUnitCode;

  if (!Auth.hasPermission(PermissionsEnum.CONTENT_SEARCH)) return null;

  return (
    <FilterArea customWrapperStyles="flex flex-1 h-9 gap-4 self-center [&>*]:flex-1">
      <FilterCreativeSetCode changeFilters={changeFilters} creativeSetCode={creativeSetCode} />
      <FilterDsp onChange={(name, value) => changeFilters({ [name]: value })} selectedValue={dsp} />
      <FilterAdvertiser changeFilters={changeFilters} selectedAdvertiser={advertiser} />
      <FilterBrand changeFilters={changeFilters} brand={brand} />
      <FilterVisualUnitCode changeFilters={changeFilters} visualUnitCode={visualUnitCode} />
      <Button
        onClick={() => {
          resetFilters('/content-management/creative-sets');
        }}
        isDisabled={isFilterEmpty}
        label="Clear all"
        svg={closeSquareSvg}
        dataTestId="clear-all-button"
        classNames="!grow-0"
      />
    </FilterArea>
  );
};

export default Filters;
