import PropTypes from 'prop-types';
import Dropdown from 'lib/Dropdown';

const YearMonthForm = ({ date, localeUtils, onChange, fromMonth, toMonth, yearsBefore, yearsAfter }) => {
  const allMonths = localeUtils.getMonths();
  const months = [];
  let startMonth = 0;
  let endMonth = 11;
  const years = [];
  const currentDate = new Date();
  let startYear = currentDate.getFullYear() - yearsBefore;
  let endYear = currentDate.getFullYear() + yearsAfter;
  let selectedMonth = date.getMonth();
  let selectedYear = date.getFullYear();

  if (fromMonth && toMonth) {
    startYear = fromMonth.getFullYear();
    endYear = toMonth.getFullYear();
    if (fromMonth.getFullYear() === date.getFullYear() && toMonth.getFullYear() === date.getFullYear()) {
      startMonth = fromMonth.getMonth();
      endMonth = toMonth.getMonth();
    } else if (fromMonth.getFullYear() === date.getFullYear()) {
      startMonth = fromMonth.getMonth();
    } else if (toMonth.getFullYear() === date.getFullYear()) {
      endMonth = toMonth.getMonth();
    }
  } else if (fromMonth) {
    startYear = fromMonth.getFullYear();
    endYear = fromMonth.getFullYear() + yearsAfter;
    if (fromMonth.getFullYear() === date.getFullYear()) {
      startMonth = fromMonth.getMonth();
    }
  } else if (toMonth) {
    startYear = toMonth.getFullYear() - yearsBefore;
    endYear = toMonth.getFullYear();
    if (toMonth.getFullYear() === date.getFullYear()) {
      endMonth = toMonth.getMonth();
    }
  }

  for (let i = startYear; i <= endYear; i++) {
    years.push({ code: i, name: i });
  }

  for (let i = startMonth; i <= endMonth; i++) {
    months.push({ code: i, name: allMonths[i] });
  }

  const handleChange = function handleChange() {
    onChange(new Date(selectedYear, selectedMonth));
  };

  const setMonth = (month) => {
    selectedMonth = month;

    handleChange();
  };

  const setYear = (year) => {
    selectedYear = year;

    handleChange();
  };

  return (
    <div className="DayPicker-Caption mb-3">
      <div className="dayPicker-input">
        <div className="dayPicker-month">
          <Dropdown
            name="month"
            dataList={months}
            value={date.getMonth()}
            onChange={setMonth}
            textKey="name"
            valueKey="code"
          />
        </div>
        <div className="dayPicker-year">
          <Dropdown
            name="year"
            dataList={years}
            value={date.getFullYear()}
            onChange={setYear}
            textKey="name"
            valueKey="code"
          />
        </div>
      </div>
    </div>
  );
};

YearMonthForm.propTypes = {
  date: PropTypes.instanceOf(Date),
  localeUtils: PropTypes.shape({
    getMonths: () => undefined,
  }),
  onChange: PropTypes.func,
  fromMonth: PropTypes.instanceOf(Date),
  toMonth: PropTypes.instanceOf(Date),
  yearsBefore: PropTypes.number,
  yearsAfter: PropTypes.number,
};

YearMonthForm.defaultProps = {
  date: null,
  localeUtils: {},
  onChange: () => undefined,
  fromMonth: null,
  toMonth: null,
  yearsBefore: 10,
  yearsAfter: 10,
};

export default YearMonthForm;
