import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts';
import merge from 'lodash/merge';

import './Chart.pcss';

/* eslint-disable @typescript-eslint/no-var-requires */
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
/* eslint-enable @typescript-eslint/no-var-requires */

function onRender() {
  if (this.dataTableDiv) {
    this.hideData();
  }
}

const defaultOptions = {
  // removes Highcharts.com link from bottom right corner
  credits: {
    enabled: false,
  },
  chart: {
    events: {
      render: onRender,
    },
    style: {
      fontFamily: 'Inter',
    },
  },
  exporting: {
    sourceWidth: 1920,
    sourceHeight: 1080,
  },
};

const Chart = ({ options, callback, dataTestId }) => (
  <div data-test-id={dataTestId}>
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="chart"
      options={merge({}, defaultOptions, options)}
      callback={callback}
    />
  </div>
);

Chart.propTypes = {
  dataTestId: PropTypes.string,
  options: PropTypes.shape().isRequired,
  callback: PropTypes.func,
};

Chart.defaultProps = {
  dataTestId: '',
  callback: () => undefined,
};

export default Chart;
