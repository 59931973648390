import PropTypes from 'prop-types';

export const CreativeItemModel = PropTypes.shape({
  id: PropTypes.string,
  convertStatus: PropTypes.string,
  type: PropTypes.string,
  dspName: PropTypes.string,
  externalId: PropTypes.string,
  formatName: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  rotation: PropTypes.number,
  statusCode: PropTypes.string,
  thumbnail: PropTypes.string,
  updated: PropTypes.number,
  updatedBy: PropTypes.string,
});

export const FrameSummaryModel = PropTypes.shape({
  code: PropTypes.string,
  address: PropTypes.string,
  postCode: PropTypes.string,
  town: PropTypes.string,
  country: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  productFormat: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    environment: PropTypes.string,
  }),
  environment: PropTypes.string,
});

export const FrameSummaryListModel = PropTypes.arrayOf(FrameSummaryModel);

export const CreativeFrameSummaryModel = PropTypes.shape({
  whiteList: FrameSummaryListModel,
  blackList: FrameSummaryListModel,
  unusedList: FrameSummaryListModel,
});
