import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import { DASH_SLASH, SINGLE_DASH } from 'consts/placeholders';
import { PanelsModel } from 'components/common/prop-types/creativeSets';
import locationSvg from 'assets/icons/location.svg';
import DetailsItem from '../DetailsItem';

const VisualUnitDetails = ({ panels, visualUnitIds }) => {
  const panelStyle =
    'py-2.5 flex justify-center items-center rounded-md border body-base border-neutral-100-opacity-10 bg-primary-600-opacity-10 text-primary-600 bg-opacity-5 h-10';

  const formatObjectList = (list, name) => {
    const getStringList = list
      ?.map((item) => item[name].join(','))
      .join(',')
      .split(',');
    return [...new Set(getStringList)].join(', ');
  };

  return (
    <div className="grid grid-cols-2 px-10 py-6 gap-5" data-test-id="visual-unit-details">
      <div className="flex items-center text-neutral-950-opacity-80 space-x-7">
        <p className="text-center sub-header-base">
          {visualUnitIds?.length ? `${visualUnitIds.length} VU` : DASH_SLASH}
        </p>
        <div className="flex flex-wrap gap-x-2 gap-y-2 item-center">
          {panels.length ? (
            panels.map(({ id, count, width, height }) => (
              <div key={id} className={`${panelStyle} ${width > height ? 'w-14' : 'w-7'}`} data-test-id={`panel-${id}`}>
                {count}
              </div>
            ))
          ) : (
            <>
              <div className={`${panelStyle} w-7`}>{SINGLE_DASH}</div>
              <div className={`${panelStyle} w-14`}>{SINGLE_DASH}</div>
            </>
          )}
        </div>
      </div>
      <DetailsItem
        label="Size (WxH)"
        value={panels?.map((item) => `${item.width}x${item.height}`).join(', ') || DASH_SLASH}
      />
      <DetailsItem
        value={panels.length ? formatObjectList(panels, 'cities') : DASH_SLASH}
        icon={<SVG src={locationSvg} className="inline mr-4 text-primary" />}
      />
      <DetailsItem label="Environment" value={panels.length ? formatObjectList(panels, 'channels') : DASH_SLASH} />
    </div>
  );
};

VisualUnitDetails.propTypes = {
  visualUnitIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  panels: PanelsModel.isRequired,
};

export default VisualUnitDetails;
