import { useRef, useEffect, MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import { ICellRendererParams, IGetRowsParams, RowSelectedEvent, GridApi, IDatasource } from 'ag-grid-community';

import withCancelRequest from 'components/hocs/withCancelRequest';
import { fetchAssetTable } from 'modules/api/asset';
import Table, { TableHeight, TableRowHeight } from 'components/patterns/Table';
import { ASSET_STATUS_BADGE } from 'consts/assetStatus';
import StatusRenderer from 'components/common/StatusRenderer';
import AssetType from 'components/common/AssetType';
import { getStringFromObjectsList } from 'utils/formatters';
import { selectFrameCode } from 'store/pages/inventoryManagement/assetList/reducer';
import { formatDateObjToIsoDateString } from 'utils/dateFormatUtil';
import { AssetListFilters } from 'components/common/types/AssetList.types';
import { AssetFiltersToRequestData, AssetTableProps } from './AssetTable.types';

export const mapSortColumnToRequestData = (columnId: string): string => {
  switch (columnId) {
    case 'productFormat':
      return 'productFormatName';
    case 'channel':
      return 'channelName';
    default:
      return columnId;
  }
};

export const mapFiltersToRequestData = ({
  routeFrameCode,
  frameCode,
  visualUnit,
  channel,
  businessArea,
  productFormat,
  tag,
  network,
  startDate,
  endDate,
  assetType,
}: AssetListFilters): AssetFiltersToRequestData => {
  const dateRange = {
    ...(startDate ? { startDate: formatDateObjToIsoDateString(startDate).split('T')[0] } : {}),
    ...(endDate ? { endDate: formatDateObjToIsoDateString(endDate).split('T')[0] } : {}),
  };

  return {
    ...(routeFrameCode ? { routeFrameCode } : {}),
    ...(frameCode ? { frameCode } : {}),
    ...(visualUnit ? { visualUnitCode: visualUnit.code } : {}),
    ...(productFormat ? { productFormatCode: productFormat.code } : {}),
    ...(channel ? { channelCode: channel.code } : {}),
    ...(businessArea ? { businessAreaCode: businessArea.code } : {}),
    ...(assetType ? { assetType: assetType.code } : {}),
    ...(tag ? { tag: { tagCode: tag.code, ...dateRange } } : {}),
    ...(network ? { network: { networkCode: network?.code, ...dateRange } } : {}),
  };
};

const columnDefs = [
  {
    headerName: 'Status',
    field: 'status',
    minWidth: 90,
    headerTooltip: 'Status',
    cellRenderer: StatusRenderer,
    cellRendererParams: { statuses: ASSET_STATUS_BADGE },
    colId: 'status',
  },
  {
    headerName: 'Route frame code',
    field: 'routeFrameCode',
    minWidth: 180,
    headerTooltip: 'Route frame code',
    sortable: true,
    sort: 'asc',
  },
  {
    headerName: 'Asset type',
    field: 'assetType',
    minWidth: 100,
    headerTooltip: 'Asset type',
    cellRenderer: AssetType,
    colId: 'assetType',
    sortable: true,
  },
  {
    headerName: 'Channel',
    field: 'channel',
    minWidth: 150,
    headerTooltip: 'Channel',
    cellRenderer: (params: ICellRendererParams) => params.data?.channel?.name || '',
    sortable: true,
  },
  {
    headerName: 'Product format',
    field: 'productFormat',
    minWidth: 150,
    headerTooltip: 'Product format',
    cellRenderer: (params: ICellRendererParams) => params.data?.productFormat?.name || '',
    sortable: true,
  },
  {
    headerName: 'Tags',
    field: 'tags',
    minWidth: 200,
    headerTooltip: 'Tags',
    cellRenderer: (params: ICellRendererParams) =>
      params.data?.tags ? getStringFromObjectsList(params.data.tags.slice(0, 3), 'tagName') : '',
  },
  {
    headerName: 'Networks',
    field: 'networks',
    minWidth: 300,
    headerTooltip: 'Networks',
    cellRenderer: (params: ICellRendererParams) =>
      params.data?.networks ? getStringFromObjectsList(params.data.networks.slice(0, 3), 'networkName') : '',
    resizable: false,
  },
];

const AssetTable: React.FC<AssetTableProps> = ({ cancelFunctions, filters }: AssetTableProps) => {
  const tableApiRef: MutableRefObject<GridApi | undefined> = useRef();
  const dispatch = useDispatch();
  const pageSize = 50;

  const getDataSource = (): IDatasource => ({
    getRows: async (params: IGetRowsParams) => {
      const [sortingColumn] = params.sortModel;
      const pageNumber = parseInt((params.startRow / pageSize).toString());
      try {
        const { data, totalElements } = await fetchAssetTable(cancelFunctions, mapFiltersToRequestData(filters), {
          pageNumber,
          pageSize,
          ...(sortingColumn
            ? { sortBy: mapSortColumnToRequestData(sortingColumn.colId), sortDir: sortingColumn.sort.toUpperCase() }
            : {}),
        });

        if (totalElements === 0) {
          tableApiRef.current?.showNoRowsOverlay();
          params.successCallback(data, totalElements);
          return;
        }

        tableApiRef.current?.hideOverlay();
        params.successCallback(data, totalElements);
        tableApiRef.current?.getDisplayedRowAtIndex(0)?.setSelected(true, true);
      } catch {} // eslint-disable-line no-empty
    },
  });

  useEffect(() => {
    tableApiRef.current?.setDatasource(getDataSource());
  }, [filters]);

  const getTableApi = (tableApi: GridApi): void => {
    tableApiRef.current = tableApi;
    tableApi.setDatasource(getDataSource());
  };

  const onSelectionChanged = (event: RowSelectedEvent): void => {
    const [selectedRow] = event.api.getSelectedRows();
    if (selectedRow) {
      dispatch(selectFrameCode(selectedRow.frameCode));
    }
  };

  return (
    <Table
      columnDefs={columnDefs}
      getTableApi={getTableApi}
      dataTestId="inventory-asset-table"
      extraGridOptions={{
        pagination: true,
        paginationPageSize: pageSize,
        cacheBlockSize: pageSize,
        enableCellTextSelection: true,
        domLayout: 'autoHeight',
        rowModelType: 'infinite',
        sortingOrder: ['desc', 'asc'],
        rowHeight: TableRowHeight.NORMAL,
        rowSelection: 'single',
        onSelectionChanged,
      }}
      tableSize={{ tableHeight: TableHeight.AUTO }}
    />
  );
};

export default withCancelRequest(AssetTable);
