import { deepFind } from 'utils/deepFind';
import {
  Segment,
  SegmentsSubOptions,
  SubOption,
  TransformedSegmentFormData,
} from 'components/common/types/Segment.types';

export const findSelectedSegment = ({
  selectedSegmentDataProvider,
  segmentOptions,
  segmentId,
}: {
  selectedSegmentDataProvider: string;
  segmentOptions: SegmentsSubOptions[];
  segmentId: string;
}): SubOption | null => {
  if (!segmentOptions) return null;

  const suboptions = segmentOptions.find(
    (segmentOption) => segmentOption.name === selectedSegmentDataProvider,
  )?.suboptions;

  if (!suboptions || !segmentId) return null;

  return deepFind<SubOption>(suboptions, segmentId);
};

export const transformSelectedSegmentData = (segment: Segment): TransformedSegmentFormData | null => {
  const { selectedSegmentDataProvider, selectedSegments } = segment || {};

  let selectedSegment = null;

  if (selectedSegmentDataProvider && selectedSegments && Object.keys(selectedSegments).length) {
    const [{ cpmCurrency, cpmPercentage, cpmPremium, segmentId, segmentName }] = Object.values(selectedSegments);

    selectedSegment = {
      cpmCurrency: cpmPremium ? cpmCurrency : undefined,
      cpmPercentage,
      cpmPremium,
      dataProvider: selectedSegmentDataProvider ?? '',
      segmentId,
      segmentName,
    };
  }

  return selectedSegment;
};
