import Input from 'lib/Input';
import { FilterPersonCodeProps } from './FilterPersonCode.types';

const FilterPersonCode: React.FC<FilterPersonCodeProps> = ({ changeFilters, value }) => {
  return (
    <Input
      name="personCode"
      value={value}
      placeholder="Type person code"
      onChange={(inputValue: string) => changeFilters({ personCode: inputValue })}
      debounceTime={300}
    />
  );
};

export default FilterPersonCode;
