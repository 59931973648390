import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PageWrapper } from 'components/common/layout/PageWrapper/PageWrapper';
import PrivateRoute from 'components/common/Auth/Private';
import { PermissionsEnum } from 'consts/permissions';
import { changeFilters } from 'store/dealDashboard/reducer';
import { FeatureFlags } from 'components/common/types/Features.types';
import { DealStatus } from 'components/common/types/Deal.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { ChildRoute } from 'components/common/types/Route.types';
import InsightsHeader from './InsightsHeader';
import DealDashboard from './DealDashboard';
import Performance from './Performance';
import Revenue from './Revenue';
import Diagnostics from './Diagnostics';
import Pacing from './Pacing';
import { Labs } from './Labs/Labs';

const Insights: React.FC = () => {
  const dispatch = useDispatch();
  const hasInsightsStatusFilterEnabled = useHasFeatureAccess(FeatureFlags.INSIGHTS_STATUS_FILTER);

  useEffect(() => {
    if (hasInsightsStatusFilterEnabled) {
      dispatch(changeFilters({ status: DealStatus.LIVE }));
    }
  }, [hasInsightsStatusFilterEnabled]);

  const childRoutes: ChildRoute[] = [
    {
      id: 1,
      path: `dashboard`,
      main: DealDashboard,
      pageAccessPermission: undefined,
    },
    {
      id: 2,
      path: `pacing/*`,
      main: Pacing,
      pageAccessPermission: undefined,
    },
    {
      id: 3,
      path: `deal-performance`,
      main: Performance,
      pageAccessPermission: PermissionsEnum.DEAL_LEVEL_PERFORMANCE_PAGE_ACCESS,
    },
    {
      id: 4,
      path: `diagnostics`,
      main: Diagnostics,
      pageAccessPermission: PermissionsEnum.DIAGNOSTICS_PAGE_ACCESS,
    },
    {
      id: 5,
      path: `revenue`,
      main: Revenue,
      pageAccessPermission: undefined,
    },
    {
      id: 6,
      path: `labs`,
      main: Labs,
      pageAccessPermission: undefined,
    },
    {
      id: 7,
      path: '*',
      main: () => <Navigate to="dashboard" />,
      pageAccessPermission: undefined,
    },
  ];

  return (
    <PageWrapper>
      <InsightsHeader />
      <Routes>
        {childRoutes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={<PrivateRoute component={route.main} pageAccessPermission={route.pageAccessPermission} />}
          />
        ))}
      </Routes>
    </PageWrapper>
  );
};

export default Insights;
