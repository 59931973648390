import PostCode from './PostCode';
import Points from './Points';
import PointOfInterest from './PointOfInterest';

const Proximity: React.FC = () => {
  return (
    <div className="grid gap-y-12 mx-12 my-6" data-test-id="proximity">
      <PostCode />
      <PointOfInterest />
      <Points />
    </div>
  );
};

export default Proximity;
