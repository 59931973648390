import SVG from 'react-inlinesvg';
import cx from 'classnames';
import closeSvg from 'assets/icons/close.svg';
import IncludeExcludeChip from 'components/common/Deal/IncludeExcludeChip';
import { ChipProps, Size, Type } from './Chip.types';

const Chip = ({
  label,
  type = Type.BRAND,
  size = Size.EXTRA_LARGE,
  icon = undefined,
  isRemovable = true,
  onRemove,
  isDisabled = false,
  item,
  onIncludeExcludeChange,
}: ChipProps): React.ReactElement => (
  <div className="flex flex-wrap items-center justify-between">
    <div
      className={cx(type, size, 'flex items-center max-w-32 mb-1', {
        '!bg-neutral-950-opacity-5 !text-neutral-500 cursor-not-allowed': isDisabled,
      })}
    >
      {!!icon && <SVG src={icon} />}
      <p
        aria-label={label}
        className={cx('max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis', {
          '!text-neutral-500 cursor-not-allowed': isDisabled,
        })}
      >
        {label}
      </p>
      {!!isRemovable && (
        <button
          aria-label="Remove chip"
          type="button"
          className={cx('flex justify-center items-center', {
            '!bg-neutral-950-opacity-5 !text-neutral-500 cursor-not-allowed': isDisabled,
          })}
          onClick={!isDisabled ? () => onRemove?.(label) : () => null}
          data-test-id={`chip-remove-${label}`}
        >
          <SVG src={closeSvg} />
        </button>
      )}
    </div>

    {onIncludeExcludeChange && item && (
      <IncludeExcludeChip item={item} onIncludeExcludeChange={onIncludeExcludeChange} />
    )}
  </div>
);

export default Chip;
