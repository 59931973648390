import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import Chart from 'components/patterns/HighChart/Chart/Chart';
import SETTINGS from '../Settings';

const ColumnChart = ({ data, chartTitle, chartSubtitle, chartSize, seriesColor, options, dataTestId, marginLeft }) => {
  const isChartsOpen = useSelector((state) => state.dealDashboard.isChartsOpen);

  const getChartOptions = () => {
    const defaultOptions = {
      chart: {
        type: 'column',
        backgroundColor: SETTINGS.COLORS.mist,
        height: chartSize,
        marginLeft,
      },
      title: {
        text: chartTitle,
        align: 'left',
        margin: 50,
        x: -10,
      },
      subtitle: {
        text: chartSubtitle,
        align: 'left',
        margin: 50,
        x: -10,
        style: {
          color: 'bg-primary-600',
          fontSize: '2rem',
        },
      },
      xAxis: {
        title: '',
        categories: data.map((i) => i.x),
        gridLineWidth: 1,
      },
      yAxis: {
        title: '',
      },
      series: [
        {
          name: chartTitle,
          data: isChartsOpen ? data.map((i) => i.y) : [],
          color: seriesColor,
        },
      ],
      legend: {
        enabled: false,
      },
      tooltip: {},
      plotOptions: {
        series: {
          borderRadius: 4,
        },
      },
    };

    return merge({}, defaultOptions, options);
  };

  return <Chart dataTestId={dataTestId} options={getChartOptions()} />;
};

ColumnChart.propTypes = {
  dataTestId: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.string, y: PropTypes.number })).isRequired,
  chartTitle: PropTypes.string,
  chartSubtitle: PropTypes.string,
  chartSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seriesColor: PropTypes.string,
  options: PropTypes.object,
  marginLeft: PropTypes.number,
};

ColumnChart.defaultProps = {
  dataTestId: '',
  chartTitle: '',
  chartSubtitle: '',
  chartSize: '100%',
  seriesColor: 'var(--primary-800)',
  options: {},
  marginLeft: undefined,
};

export default ColumnChart;
