import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SingleContent from 'components/common/layout/SingleContent';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getSetDetails } from 'store/pages/contentManagement/creativeSetDetails/actions';
import { resetCreativeSetDetails } from 'store/pages/contentManagement/creativeSetDetails/reducer';

import Header from './Header';
import PanelSet from './PanelSet';
import SetDetails from './SetDetails';
import VisualUnitsGroups from './VisualUnitGroups';

const CreativeSetDetails = () => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();
  const { id } = useParams();
  const isPanelView = useSelector((state) => state.pages.contentManagement.creativeSetDetails.isPanelView);

  useEffect(() => {
    if (id) dispatch(getSetDetails({ cancelFunctions, id }));
    return () => dispatch(resetCreativeSetDetails());
  }, []);

  return (
    <>
      <Header />
      {isPanelView || id ? <SingleContent content={<PanelSet />} /> : <VisualUnitsGroups />}
      {(isPanelView || id) && <SingleContent content={<SetDetails />} />}
    </>
  );
};

export default CreativeSetDetails;
