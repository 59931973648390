import PropTypes from 'prop-types';

import { DOUBLE_DASH } from 'consts/placeholders';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';
import { TagModel } from 'components/common/prop-types/inventory';

import TagInfo from './TagInfo';
import { Button as ReadMoreButton } from '../ReadMoreSections';

const Tags = ({ tags, toggleReadMore, maxRecordsToShow }) => {
  return (
    <div>
      <h2 className="sub-header-base mb-8">Tags</h2>
      <div className="pl-2" data-test-id="tag-container">
        <Timeline>
          {tags.length ? (
            <TagInfo tagGroup={tags.slice(0, maxRecordsToShow)} />
          ) : (
            <TimelineItem title={<p className="body-base">{DOUBLE_DASH}</p>} />
          )}
        </Timeline>
        {tags.length > 3 ? <ReadMoreButton onClick={toggleReadMore} /> : null}
      </div>
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(TagModel),
  toggleReadMore: PropTypes.func,
  maxRecordsToShow: PropTypes.number.isRequired,
};

Tags.defaultProps = {
  tags: [],
  toggleReadMore: () => null,
};

export default Tags;
