import SingleContent from 'components/common/layout/SingleContent';
import PreviewHeader from 'components/pages/ContentManagement/Preview/PreviewHeader';
import PreviewContent from 'components/pages/ContentManagement/Preview/PreviewContent';

const Preview = () => (
  <>
    <PreviewHeader />
    <SingleContent content={<PreviewContent />} />
  </>
);

export default Preview;
