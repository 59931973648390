import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { URLS } from 'modules/api/constants';
import Header from 'components/common/Header';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import PlannerLabsButton from './PlannerLabsButton';
import DealFormButton from './DealFormButton';
import DealFormDropdown from './DealFormDropdown';

const DealsHeader: React.FC = () => {
  const navigate = useNavigate();

  const openPlannerNative = (): void => navigate('/planner/programmatic');
  const openPlannerLabs = (): Window | null => window.open(URLS().PLANNER(), '_blank');
  const openDealForm = (): void => navigate('/deal');
  const hasPlannerEnabled = useHasFeatureAccess(FeatureFlags.PLANNER);
  const hasPlannerNativeEnabled = useHasFeatureAccess(FeatureFlags.PLANNER_NATIVE);
  const hasDirectSalesPlannerEnabled = useHasFeatureAccess(FeatureFlags.DIRECT_SALES_PLANNER);

  const createDealButton = (): ReactElement | null => {
    if (!Auth.hasPermission(PermissionsEnum.DEAL_CREATE)) {
      return null;
    }

    if (hasPlannerNativeEnabled || hasDirectSalesPlannerEnabled) {
      return <DealFormDropdown openDealForm={openDealForm} openPlanner={openPlannerNative} />;
    }

    if (hasPlannerEnabled) {
      return (
        <>
          <PlannerLabsButton onClick={openPlannerLabs} />
          <DealFormButton onClick={openDealForm} />
        </>
      );
    }

    return <DealFormButton onClick={openDealForm} />;
  };

  return <Header title="Deal management" actionButton={createDealButton()} />;
};

export default DealsHeader;
