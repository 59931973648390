import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import arrowSvg from 'assets/icons/arrow_down.svg';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';

const Header = ({ isNewSet, isPanelView, code, removeAllCreatives, resetCreativeSetDetails }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between bg-neutral-300 pl-8 py-5 pr-14">
      <div className="flex items-center">
        <Button
          btnShape={ButtonShape.CIRCLE}
          btnType={ButtonType.PRIMARY}
          onClick={() => navigate('/content-management/creative-sets')}
          label="Back to creative sets"
        >
          <SVG className="transform rotate-90 w-4" src={arrowSvg} />
        </Button>
        <h2 className="ml-6 header-xl text-neutral-950">{`${isNewSet ? 'New' : code} creative set`}</h2>
      </div>
      {isPanelView && isNewSet ? (
        <div className="space-x-2.5">
          <Button btnShape={ButtonShape.NORMAL} btnType={ButtonType.SECONDARY} onClick={removeAllCreatives}>
            Reset Creatives
          </Button>
          <Button
            btnShape={ButtonShape.NORMAL}
            btnType={ButtonType.PRIMARY}
            onClick={() => {
              resetCreativeSetDetails();
            }}
          >
            Select new VU
          </Button>
        </div>
      ) : null}
    </div>
  );
};

Header.propTypes = {
  isNewSet: PropTypes.bool.isRequired,
  isPanelView: PropTypes.bool.isRequired,
  code: PropTypes.string.isRequired,
  removeAllCreatives: PropTypes.func.isRequired,
  resetCreativeSetDetails: PropTypes.func.isRequired,
};

export default Header;
