import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';

import markerIcon from 'assets/img/pin.png';
import config from 'config';

const GoogleMapsWrapper = (WrappedComponent) => {
  return (props) => {
    const mapProps = {
      googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${config.apiKeys.googleMap}`,
      loadingElement: <div style={{ height: '100%' }} />,
      containerElement: <div style={{ height: '100%' }} />,
      mapElement: <div style={{ height: '100%' }} />,
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} {...mapProps} />;
  };
};

const GoogleMaps = GoogleMapsWrapper(
  withScriptjs(
    withGoogleMap(
      ({
        zoom,
        center,
        markerClusterGridSize,
        markerData,
        infoWindowComponent,
        infoWindowPosition,
        onMarkerMouseOver,
        onMarkerMouseOut,
      }) => {
        const googleMapOptions = {
          clickableIcons: false,
          mapTypeControl: false,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels.icon',
              stylers: [{ visibility: 'off' }],
            },
          ],
        };

        return (
          <GoogleMap defaultZoom={zoom} defaultCenter={center} options={googleMapOptions}>
            <InfoBox
              position={
                // eslint-disable-next-line no-undef
                new google.maps.LatLng(infoWindowPosition?.lat ?? center.lat, infoWindowPosition?.lng ?? center.lng)
              }
              visible={Boolean(infoWindowPosition)}
              options={{
                closeBoxURL: ``,
                enableEventPropagation: true,
                alignBottom: true,
                boxStyle: { overflow: 'initial', 'margin-left': '16px' },
              }}
            >
              {infoWindowComponent}
            </InfoBox>
            <MarkerClusterer averageCenter enableRetinaIcons gridSize={markerClusterGridSize} defaultMaxZoom={18}>
              {markerData.map((item) => (
                <Marker
                  key={item.id}
                  icon={markerIcon}
                  position={{ lat: item.lat, lng: item.lng }}
                  onMouseOver={(eve) => onMarkerMouseOver(item, eve)}
                  onFocus={() => undefined}
                  onMouseOut={(eve) => onMarkerMouseOut(item, eve)}
                  onBlur={() => undefined}
                />
              ))}
            </MarkerClusterer>
          </GoogleMap>
        );
      },
    ),
  ),
);

GoogleMaps.propTypes = {
  zoom: PropTypes.number,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  markerClusterGridSize: PropTypes.number,
  markerData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  ),
  onMarkerMouseOver: PropTypes.func,
  onMarkerMouseOut: PropTypes.func,
  infoWindowComponent: PropTypes.node,
  infoWindowPosition: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

GoogleMaps.defaultProps = {
  zoom: 9,
  center: {
    lat: 51.509865,
    lng: -0.118092,
  },
  markerClusterGridSize: 50,
  markerData: [],
  onMarkerMouseOver: () => undefined,
  onMarkerMouseOut: () => undefined,
  infoWindowComponent: [],
  infoWindowPosition: null,
};

export default GoogleMaps;
