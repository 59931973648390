import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { DealType, DealTypeLabel } from 'components/common/types/Deal.types';
import { PublisherType } from 'components/common/types/Publisher.types';

const getAllOptions = (selectedType) => {
  const dealTypes = [];

  if (selectedType === DealType.GUARANTEED || Auth.hasPermission(PermissionsEnum.DEAL_TYPE_PRICEIMPRESSION_VIEW)) {
    dealTypes.push({
      code: DealType.GUARANTEED,
      name: DealTypeLabel.GUARANTEED,
    });
  }

  if (
    selectedType === DealType.NON_GUARANTEED_FLOOR_PRICE ||
    Auth.hasPermission(PermissionsEnum.DEAL_TYPE_NON_GUARANTEED_VIEW)
  ) {
    dealTypes.push({
      code: DealType.NON_GUARANTEED_FLOOR_PRICE,
      name: DealTypeLabel.NON_GUARANTEED_FLOOR_PRICE,
    });
  }

  if (
    selectedType === DealType.NON_GUARANTEED_FIXED_PRICE ||
    Auth.hasPermission(PermissionsEnum.DEAL_TYPE_PRICE_VIEW)
  ) {
    dealTypes.push({
      code: DealType.NON_GUARANTEED_FIXED_PRICE,
      name: DealTypeLabel.NON_GUARANTEED_FIXED_PRICE,
    });
  }

  return dealTypes;
};

export default function getDealTypes(dealType, isNewDeal, publisherType) {
  const isAllOptionsAvailable = isNewDeal;
  const isDealGuaranteed = !isNewDeal && dealType === DealType.GUARANTEED;
  const allOptions = getAllOptions(dealType);
  let dealTypes = [];

  if (publisherType === PublisherType.RESELLER) {
    dealTypes = allOptions.filter((type) => type.code === DealType.NON_GUARANTEED_FLOOR_PRICE);
  } else if (isAllOptionsAvailable) {
    dealTypes = allOptions;
  } else if (isDealGuaranteed) {
    dealTypes = allOptions.filter((type) => type.code === DealType.GUARANTEED);
  } else {
    dealTypes = allOptions.filter((type) => type.code !== DealType.GUARANTEED);
  }

  // when user has no access to any deal type, non-guaranteed should be allowed by default
  if (isNewDeal && dealTypes.length === 0) {
    dealTypes.push({
      code: DealType.NON_GUARANTEED_FLOOR_PRICE,
      name: DealTypeLabel.NON_GUARANTEED_FLOOR_PRICE,
    });
  }

  return dealTypes;
}
