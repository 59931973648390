import { formatDate, formatPercent, formatCurrency } from 'modules/I18N';

const getTooltipString = (xValue, yValue, color, seriesName) => {
  return `${xValue}<br /><span style="color:${color}">\u25CF</span><span class="body-sm">${seriesName}: </span><span class="sub-header-sm">${yValue}</span>`;
};

export const dateCurrencyChartOptions = ({ currencyCode, localeCode }) => ({
  tooltip: {
    formatter() {
      return getTooltipString(
        formatDate(new Date(this.x).toISOString(), localeCode),
        formatCurrency({ value: this.y, currencyCode, localeCode }),
        this.color,
        this.series.name,
      );
    },
  },
  xAxis: {
    labels: {
      formatter() {
        const isoDateString = new Date(this.value).toISOString();
        return formatDate(isoDateString, localeCode);
      },
    },
  },
  yAxis: {
    labels: {
      formatter() {
        return formatCurrency({ value: this.value, currencyCode, localeCode });
      },
    },
  },
});

export const datePercentChartOptions = ({ localeCode }) => ({
  tooltip: {
    formatter() {
      return getTooltipString(
        formatDate(new Date(this.x).toISOString(), localeCode),
        formatPercent({ value: this.y / 100, localeCode }),
        this.color,
        this.series.name,
      );
    },
  },
  xAxis: {
    labels: {
      formatter() {
        const isoDateString = new Date(this.value).toISOString();
        return formatDate(isoDateString, localeCode);
      },
    },
  },
  yAxis: {
    min: -2,
    startOnTick: false,
    max: 102,
    endOnTick: false,
    labels: {
      formatter() {
        return formatPercent({ value: this.value / 100, localeCode });
      },
    },
  },
});

export const dealCurrencyChartOptions = ({ currencyCode, localeCode }) => ({
  tooltip: {
    formatter() {
      return getTooltipString(
        this.x,
        formatCurrency({ value: this.y, currencyCode, localeCode }),
        this.color,
        this.series.name,
      );
    },
  },
  yAxis: {
    labels: {
      formatter() {
        return formatCurrency({ value: this.value, currencyCode, localeCode });
      },
    },
  },
});

export const calculateDealPercentChartOptions = (isChartsOpen, localeCode) => {
  return isChartsOpen
    ? {
        tooltip: {
          formatter() {
            return getTooltipString(
              this.x,
              formatPercent({ value: this.y / 100, localeCode }),
              this.color,
              this.series.name,
            );
          },
        },
        yAxis: {
          min: 0,
          max: 100,
        },
        labels: {
          formatter() {
            return formatPercent({ value: this.value / 100, localeCode });
          },
        },
      }
    : {
        yAxis: {
          min: 0,
          max: [],
        },
      };
};
