/* eslint-disable @typescript-eslint/no-explicit-any */
const isFulfilledStatus = <T>(requestResult: PromiseSettledResult<T>): requestResult is PromiseFulfilledResult<T> =>
  requestResult.status === 'fulfilled';

const multiPublisherRequest = async (
  publishers: string[],
  request: (...args: any[]) => Promise<any[]>,
  requestParams: { [paramName: string]: any },
): Promise<unknown> => {
  const result: PromiseSettledResult<any>[] = await Promise.allSettled(
    publishers.map(async (publisher: string) => {
      try {
        requestParams.envId = publisher;
        requestParams.cancelable = false;
        const results = await request({ ...requestParams });

        return Array.isArray(results)
          ? results.map((item) => ({ ...item, publisher }))
          : [{ publisher, ...(results as Object) }];
      } catch (err) {
        return [];
      }
    }),
  );

  return result.reduce((acc, cur) => {
    if (isFulfilledStatus(cur)) {
      acc.push(...cur.value);
    }
    return acc;
  }, [] as any[]);
};

export default multiPublisherRequest;
/* eslint-enable @typescript-eslint/no-explicit-any */
