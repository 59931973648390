import { useEffect, useRef, useCallback } from 'react';

const usePreviousWithReset = <T>(value: T): [T | undefined, () => void] => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  const reset = useCallback(() => {
    ref.current = undefined;
  }, []);

  return [ref.current, reset];
};

export default usePreviousWithReset;
