import { MOTION_TYPE_INFO } from 'consts/motionType';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import MotionTypeProps from './MotionType.types';

const MotionType: React.FC<MotionTypeProps> = ({ value, onChange, isDisabled }) => {
  const onMotionTypeChange = (motionType: string): void => {
    onChange('movement', motionType);
  };

  return (
    <div className="border-current px-6 py-5 bg-neutral-50 rounded-xl mb-2">
      <MultipleSwitchBox
        dataTestId="motion-type-select"
        source={[MOTION_TYPE_INFO.STILL, MOTION_TYPE_INFO.MOVING]}
        selectedValue={value}
        textKey="name"
        valueKey="code"
        onChange={onMotionTypeChange}
        isDisabled={isDisabled}
        isFullWidth
      />
    </div>
  );
};

export default MotionType;
