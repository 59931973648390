import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import PercentageDifference from 'components/patterns/StatBlock/PercentageDifference';
import styles from './StatBlock.pcss';

const cx = classNames.bind(styles);

const StatBlock = ({
  id,
  title,
  currentStat,
  previousStat,
  percentageDifference,
  showPreviousStat,
  compact,
  noHeaders,
}) => {
  return (
    <li className={cx('stat', { 'stat--compact': compact })}>
      {noHeaders ? null : (
        <label htmlFor={id} className={cx('stat-title', 'sub-header-sm text-neutral-600')}>
          {title}
        </label>
      )}

      {showPreviousStat && (
        <h2 className={cx('stat-previous')}>
          <PercentageDifference percentageDifference={percentageDifference} />
        </h2>
      )}

      <h2
        className={cx('stat-current', 'header-xl text-neutral-900', {
          'stat-current--compact header-lg text-neutral-900': compact,
        })}
      >
        {currentStat}
      </h2>

      {showPreviousStat && (
        <h2 className={cx('stat-previous', 'body-base text-neutral-600')}>
          <span>{previousStat}</span>
        </h2>
      )}
    </li>
  );
};

StatBlock.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  currentStat: PropTypes.string.isRequired,
  previousStat: PropTypes.string,
  percentageDifference: PropTypes.number,
  showPreviousStat: PropTypes.bool,
  compact: PropTypes.bool,
  noHeaders: PropTypes.bool,
};

StatBlock.defaultProps = {
  previousStat: '',
  percentageDifference: 0,
  showPreviousStat: true,
  compact: false,
  noHeaders: false,
};

export default StatBlock;
