import { URLS, HTTP_METHODS } from 'modules/api/constants';
import handleRequest from './common';

export const getProductFormats = (cancelFunctions, query) => {
  const reqOptions = {
    url: URLS().FETCH_PRODUCT_FORMATS(),
    method: HTTP_METHODS.POST,
    showLoader: true,
    data: { lookupName: query },
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_PRODUCT_FORMATS');
};

export const getChannels = (cancelFunctions, query) => {
  const reqOptions = {
    url: URLS().FETCH_CHANNELS(),
    method: HTTP_METHODS.POST,
    showLoader: true,
    data: { lookupName: query },
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_CHANNELS');
};

export const getBusinessAreas = (cancelFunctions, query) => {
  const reqOptions = {
    url: URLS().FETCH_BUSINESS_AREAS(),
    method: HTTP_METHODS.POST,
    showLoader: true,
    data: { lookupName: query },
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_BUSINESS_AREA');
};

export const getTags = (cancelFunctions, query) => {
  const reqOptions = {
    url: URLS().FETCH_TAGS(),
    method: HTTP_METHODS.POST,
    showLoader: true,
    data: { lookupName: query },
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_TAGS');
};

export const getNetworks = (cancelFunctions, query) => {
  const reqOptions = {
    url: URLS().FETCH_NETWORKS(),
    method: HTTP_METHODS.POST,
    showLoader: true,
    data: { lookupName: query },
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_NETWORKS');
};

export const getVisualUnitCodes = async (cancelFunctions, query) => {
  const reqOptions = {
    url: URLS().FETCH_VISUAL_UNIT_CODES(),
    method: HTTP_METHODS.POST,
    showLoader: true,
    data: { lookupCode: query },
  };

  const data = await handleRequest(reqOptions, cancelFunctions, 'FETCH_VISUAL_UNIT_CODES');

  return data.lookupData;
};

export const getAssetTypes = (cancelFunctions) => {
  const reqOptions = {
    url: URLS().FETCH_ASSET_TYPES(),
    showLoader: true,
    method: HTTP_METHODS.GET,
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_ASSET_TYPES');
};
