import PropTypes from 'prop-types';

import { DASH_SLASH } from 'consts/placeholders';
import { CodeNameModel } from 'components/common/prop-types/creativeSets';
import { CREATIVE_STATUS_BADGE } from 'consts/creative';
import { BadgeSize } from 'components/patterns/Badge';
import StatusRenderer from 'components/common/StatusRenderer';
import DetailsItem from '../DetailsItem';

const CreativeDetails = ({ creativeDetails: { dsp, brand, advertiser, status } }) => {
  return (
    <div className="grid grid-cols-2 px-10 py-6 gap-5" data-test-id="creative-details-section">
      <DetailsItem
        label="Status"
        value={
          status ? (
            <>
              <StatusRenderer value={status} statuses={CREATIVE_STATUS_BADGE} badgeSize={BadgeSize.NORMAL} />
              <span className="ml-2 capitalize">{status.toLowerCase()}</span>
            </>
          ) : (
            DASH_SLASH
          )
        }
      />
      <DetailsItem label="DSP" value={dsp?.name || DASH_SLASH} />
      <DetailsItem label="Advertiser" value={advertiser?.name || DASH_SLASH} />
      <DetailsItem label="Brand" value={brand?.name || DASH_SLASH} />
    </div>
  );
};

CreativeDetails.propTypes = {
  creativeDetails: PropTypes.shape({
    status: PropTypes.string.isRequired,
    dsp: CodeNameModel,
    brand: CodeNameModel,
    advertiser: CodeNameModel,
  }).isRequired,
};

export default CreativeDetails;
