// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable sonarjs/cognitive-complexity */

import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { CreativeStatus } from 'components/common/types/Creative.types';
import infoSvg from 'assets/icons/info.svg';
import tagSvg from 'assets/icons/tag.svg';
import listSvg from 'assets/icons/list.svg';
import Card from 'components/patterns/Card';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import AsyncSelect from 'components/patterns/AsyncSelect';
import BaseSelect from 'lib/BaseSelect';
import Input from 'lib/Input';
import { TableHeight } from 'components/patterns/Table';
import ProductFormats from 'components/pages/ContentManagement/Preview/PreviewContent/TaggingRules/ProductFormats';
import { useSelector } from 'react-redux';
import { getIsReseller } from 'store/publisher/selectors';
import AdditionalProductCategories from './AdditionalProductCategories';
import { TaggingRulesTable } from './TaggingRulesTable/TaggingRulesTable';
import { useTaggingRules } from './useTaggingRules';
import { TaggingRulesProps } from './TaggingRules.types';

const TaggingRules: React.FC<TaggingRulesProps> = ({
  commercialId,
  creativeId,
  creativeStatus,
  frameSummary,
  isDisabled,
  isFetchingCreativeDetails,
  marketId,
  onTaggingRulesChange,
}) => {
  const {
    creative,
    handleSelectProductCategory,
    handleExportSummary,
    handleLoadAdvertiserLookupData,
    handleLoadAgencyLookupData,
    handleLoadBrandLookupData,
    handleLoadFrameTagsLookupData,
    handleLoadSpecialistLookupData,
    handleSelectAdvertiser,
    handleSelectAgency,
    handleSelectBrand,
    handleSelectFrameTag,
    handleSelectSpecialist,
  } = useTaggingRules(creativeId, commercialId, onTaggingRulesChange);

  const {
    advertiser,
    agency,
    brand,
    externalAdvertiserName,
    clickThroughUrl,
    externalAgencyId,
    frameTags,
    productCategory,
    productCategoriesOptions,
    specialist,
    uploadedBy,
  } = creative;

  const isFramesAvailable = frameSummary.inclusionListCount || frameSummary.exclusionListCount;
  const isApproved = creativeStatus === CreativeStatus.APPROVED;
  const isReseller = useSelector(getIsReseller);

  const showFrameAvailability =
    Auth.hasPermission(PermissionsEnum.CONTENT_CREATIVE_CATEGORIES_VIEW) &&
    Auth.hasPermission(PermissionsEnum.CONTENT_WHITELIST_BACKLIST);
  const showExportButton =
    showFrameAvailability && Auth.hasPermission(PermissionsEnum.CONTENT_WHITELIST_BACKLIST_EXPORT);
  const showFramesSummary = !isFetchingCreativeDetails && isFramesAvailable;
  const showAdditionalProductCategories = Auth.hasPermission(PermissionsEnum.CONTENT_CREATIVE_CATEGORIES_VIEW);

  return (
    <>
      <Card>
        {showFrameAvailability && (
          <div className="text-neutral-600">
            <div className="mb-1 body-sm">
              Frame availability is based on the targeting rules you have assigned to the creative
            </div>
            {showExportButton && (
              <Button
                onClick={handleExportSummary}
                btnShape={ButtonShape.NORMAL}
                btnType={ButtonType.PRIMARY}
                isDisabled={isDisabled}
              >
                Export frames list
              </Button>
            )}
            {showFramesSummary ? (
              <div className="mt-1 body-base">
                <p>
                  <b>Included: </b>
                  {frameSummary.inclusionListCount} frames
                </p>
                <p>
                  <b>Excluded: </b>
                  {frameSummary.exclusionListCount} frames
                </p>
              </div>
            ) : (
              <div className="mt-1 body-base" data-test-id="no-frames-available-info">
                Please check your creative <span>Motion</span> type, link a <span>Deal ID</span>, assign the advertiser{' '}
                <span>Product category</span>, and add a <span>Frame tag</span> to validate the eligible frames.
              </div>
            )}
          </div>
        )}
      </Card>
      {!isReseller && (
        <>
          <Card
            header="Creative details"
            icon={infoSvg}
            info="Provide the Advertiser, Brand & Product category details of the creative"
          >
            <div className="grid grid-cols-2 gap-x-24 gap-y-6 mb-3">
              <div className="w-full" data-test-id="advertiser">
                <span className="inline-block mb-2.5 sub-header-base text-neutral-600">
                  Advertiser <i className="body-base text-pinkRed-500">*</i>
                </span>
                <AsyncSelect
                  dataTestId="advertiser-input"
                  name="advertiser"
                  loadData={handleLoadAdvertiserLookupData}
                  selectedValue={advertiser?.code ? advertiser : null}
                  placeholder="Select advertiser"
                  onSelect={handleSelectAdvertiser}
                  isDisabled={isApproved || isDisabled}
                />
              </div>
              <div className="w-full" data-test-id="brand">
                <span className="inline-block mb-2.5 sub-header-base text-neutral-600">
                  Brand <i className="body-base text-pinkRed-500">*</i>
                </span>
                <AsyncSelect
                  dataTestId="brand-input"
                  name="brand"
                  loadData={handleLoadBrandLookupData}
                  selectedValue={brand?.code ? brand : null}
                  placeholder="Select brand"
                  onSelect={handleSelectBrand}
                  defaultOptions={advertiser?.code ? advertiser.brands : []}
                  isDisabled={!advertiser?.code || isApproved || isDisabled}
                />
              </div>
              <div className="w-full" data-test-id="product-category">
                <span className="inline-block mb-2.5 sub-header-base text-neutral-600">Product category</span>
                <BaseSelect
                  dataTestId="product-category-select"
                  name="productCategory"
                  options={productCategoriesOptions}
                  selectedValue={productCategory}
                  onSelect={handleSelectProductCategory}
                  isDisabled={!brand?.code || isApproved || isDisabled}
                />
              </div>
              <div className="w-full" data-test-id="agency">
                <span className="inline-block mb-2.5 sub-header-base text-neutral-600">Agency</span>
                <AsyncSelect
                  dataTestId="agency-input"
                  name="agency"
                  loadData={handleLoadAgencyLookupData}
                  selectedValue={agency?.code ? agency : null}
                  placeholder="Select agency"
                  onSelect={handleSelectAgency}
                  defaultOptions={[]}
                  isDisabled={isDisabled}
                />
              </div>
              <div className="w-full" data-test-id="specialist">
                <span className="inline-block mb-2.5 sub-header-base text-neutral-600">Specialist</span>
                <AsyncSelect
                  dataTestId="specialist-input"
                  name="specialist"
                  loadData={handleLoadSpecialistLookupData}
                  selectedValue={specialist?.code ? specialist : null}
                  placeholder="Select specialist"
                  onSelect={handleSelectSpecialist}
                  defaultOptions={[]}
                  isDisabled={isDisabled}
                />
              </div>
              {uploadedBy === 'Exchange Submission' && (
                <>
                  <div className="w-full" data-test-id="external-advertiser-name">
                    <span className="inline-block mb-2.5 sub-header-base text-neutral-600">DSP advertiser name</span>
                    <div className="opacity-70 cursor-not-allowed pointer-events-none">
                      <Input
                        dataTestId="readonly-external-advertiser-name-input"
                        name="externalAdvertiserName"
                        value={externalAdvertiserName}
                        isDisabled
                      />
                    </div>
                  </div>
                  <div className="w-full col-start-2" data-test-id="external-agency-id">
                    <span className="inline-block mb-2.5 sub-header-base text-neutral-600">DSP seat ID</span>
                    <div className="opacity-70 cursor-not-allowed pointer-events-none">
                      <Input
                        dataTestId="readonly-external-agency-id-input"
                        name="externalAgencyId"
                        value={externalAgencyId}
                        isDisabled
                      />
                    </div>
                  </div>
                  <div className="w-full col-start-2" data-test-id="click-through-url">
                    <span className="inline-block mb-2.5 sub-header-base text-neutral-600">DSP advertiser domain</span>
                    <div className="opacity-70 cursor-not-allowed pointer-events-none">
                      <Input
                        dataTestId="readonly-click-through-url-input"
                        name="clickThroughUrl"
                        value={clickThroughUrl}
                        isDisabled
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </Card>
          <Card
            header="Product formats"
            info="Attribute your creative with a Product format to determine frame availability"
            icon={listSvg}
          >
            <ProductFormats isDisabled={isApproved || isDisabled} onTaggingRulesChange={onTaggingRulesChange} />
          </Card>
          {showAdditionalProductCategories && (
            <Card
              header="Additional product categories"
              info="Additional categories can optionally be assigned to the creative"
              icon={listSvg}
            >
              <AdditionalProductCategories
                marketId={marketId}
                creativeStatus={creativeStatus}
                onTaggingRulesChange={onTaggingRulesChange}
                isDisabled={isDisabled}
              />
            </Card>
          )}
          <Card header="Frame tagging rules" icon={tagSvg} info="Assign tags for additional frame targeting">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="form-group mb-3" data-test-id="tags">
                      <span className="sub-header-base form-label">Frame tags</span>
                      <AsyncSelect
                        dataTestId="tags-input"
                        name="tags"
                        loadData={handleLoadFrameTagsLookupData}
                        selectedValue={null}
                        placeholder="Select a frame tag"
                        onSelect={handleSelectFrameTag}
                        textKey="compositeName"
                        isDisabled={isDisabled}
                      />
                    </div>
                  </div>
                </div>
                {frameTags.length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <TaggingRulesTable
                        frameTags={frameTags}
                        tableHeight={TableHeight.AUTO as keyof typeof TableHeight}
                        onTaggingRulesChange={onTaggingRulesChange}
                        marketId={marketId}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default TaggingRules;
