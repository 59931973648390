import Overlay from 'lib/Overlay';
import { AllocationReportModalProps } from './AllocationReportModal.types';
import { ToggleAllocationReportView } from './ToggleAllocationReportView';

export const AllocationReportModal: React.FC<AllocationReportModalProps> = ({ children, onClose }) => {
  return (
    <Overlay
      header={
        <header className="w-full flex justify-between items-center pr-6">
          <h3 className="sub-header-lg">Allocation report</h3>
          <ToggleAllocationReportView />
        </header>
      }
      onClose={onClose}
      isOpen
    >
      {children}
    </Overlay>
  );
};
