import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getFormattedDateTime, formatCurrency, formatNumber } from 'modules/I18N';

const LineInfo = ({ line: { name, createdDate, availability, sortOrderNumber } }) => {
  const { currencyCode, localeCode } = useSelector((state) => state.publisher.configuration);

  return (
    <div className="p-5 space-y-9">
      <div className="flex justify-between items-center space-x-1.5">
        <p className="sub-header-base">
          {sortOrderNumber} <span>{name}</span>
        </p>
        <p className="body-base">
          {getFormattedDateTime(new Date(createdDate), {
            dateStyle: 'medium',
          })}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-x-11">
        <p className="col-span-2 mb-3.5 body-base text-neutral-400">Price</p>
        <p className="col-span-2 mb-2.5 header-2xl">
          {formatCurrency({ value: availability.totalCost, currencyCode, localeCode })}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-x-11 gap-y-5">
        <p className="col-span-2 sub-header-base text-neutral">Stats</p>
        <div>
          <p className="mb-1 body-base text-neutral">Frames</p>
          <p className="sub-header-lg">{availability.allocatedFrames}</p>
        </div>
        <div>
          <p className="mb-1 body-base text-neutral">Available frames</p>
          <p className="sub-header-lg">{availability.availableFrames}</p>
        </div>
        <div>
          <p className="mb-1 body-base text-neutral">Impressions</p>
          <p className="sub-header-lg">{formatNumber({ value: availability.allocatedImpressions, localeCode })}</p>
        </div>
        <div>
          <p className="mb-1 body-base text-neutral">Available impressions</p>
          <p className="sub-header-lg">{formatNumber({ value: availability.availableImpressions, localeCode })}</p>
        </div>
        <div>
          <p className="mb-1 body-base text-neutral">Average CPM</p>
          <p className="sub-header-lg">{availability.assetCpmDistribution.weightedMean}</p>
        </div>
        <div>
          <p className="mb-1 body-base text-neutral">Min/Max CPM</p>
          <p className="sub-header-lg">{`${availability.assetCpmDistribution.min} / ${availability.assetCpmDistribution.max}`}</p>
        </div>
      </div>
    </div>
  );
};

LineInfo.propTypes = {
  line: PropTypes.object.isRequired,
};

export default LineInfo;
