import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import restoreSvg from 'assets/icons/restore.svg';
import { RootState } from 'store';
import { restoreCreative } from 'modules/api/content';
import { useToggle } from 'customHooks/useToggle';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import Modal from 'components/patterns/Modal';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import { DeleteRestoreCreativeProps } from './ButtonRenderer.types';

export const RestoreCreative: React.FC<DeleteRestoreCreativeProps> = ({ data, onComplete }) => {
  const restoreDialog = useToggle();
  const cancelFunctions = useCancelRequest();
  const environmentId = useSelector((state: RootState) => state.environment.environmentId);

  const onRestore = async (): Promise<void> => {
    try {
      const marketId = data.market.find(({ environment }) => environment === environmentId)?.id;
      await restoreCreative(cancelFunctions, marketId);

      onComplete();
    } finally {
      restoreDialog.close();
    }
  };

  return (
    <>
      <Button
        onClick={restoreDialog.open}
        btnShape={ButtonShape.NORMAL}
        btnType={ButtonType.SUCCESS}
        dataTestId={`restore-creative-button-${data.id}`}
      >
        <SVG src={restoreSvg} />
      </Button>

      <Modal
        dataTestId="restore-creative-modal"
        isOpen={restoreDialog.isOpen}
        icon={<SVG src={restoreSvg} className="text-primary" />}
        title="Restore Creative?"
        actionButtons={
          <>
            <Button btnType={ButtonType.PRIMARY} btnShape={ButtonShape.NORMAL} onClick={restoreDialog.close}>
              Cancel
            </Button>
            <Button btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL} onClick={onRestore}>
              Restore
            </Button>
          </>
        }
      >
        <p className="ml-8 body-base text-neutral-950-opacity-60">Are you sure you want to restore this creative?</p>
      </Modal>
    </>
  );
};
