import cx from 'classnames';

import { CheckboxProps, Theme } from './Checkbox.types';

const commonCheckedStyle =
  'relative before:flex before:justify-center before:items-center before:h-4.5 before:w-4.5 before:body-base before:absolute before:content-checkmark';

export const CheckboxStyles = {
  [Theme.DARK]: {
    checked: `${commonCheckedStyle} border border-primary-700 bg-primary-700 text-neutral-50`,
    unchecked: 'border-2 border-neutral-300 bg-neutral-50',
  },
  [Theme.LIGHT]: {
    checked: `${commonCheckedStyle} bg-primary-600 border-primary-600 text-neutral-50`,
    unchecked: 'border-2 border-neutral',
  },
};

const Checkbox: React.FC<CheckboxProps> = ({ onChange, id, isChecked, isDisabled = false, theme = Theme.DARK }) => {
  return (
    <div>
      <input
        className={cx(
          'appearance-none rounded-sm w-4.5 h-4.5 outline-none flex items-center justify-center',
          { [CheckboxStyles[theme].checked]: isChecked },
          { [CheckboxStyles[theme].unchecked]: !isChecked },
          { 'cursor-not-allowed': isDisabled },
          { 'cursor-pointer': !isDisabled },
        )}
        data-test-id={id}
        id={id}
        onChange={onChange}
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
      />
    </div>
  );
};

export default Checkbox;
