import cx from 'classnames';
import SVG from 'react-inlinesvg';
import check from 'assets/icons/check.svg';

export const multiSelectCheckboxStyle = {
  notSelected: 'bg-neutral-50 border-neutral-200',
  selected: 'bg-primary-700 border-transparent text-white',
};

export const checkboxStyle = {
  base: 'border border-neutral-300 rounded-sm shadow-inner-sm',
  size: 'w-4 h-4',
  icon: 'w-3 h-3 ml-px mt-px',
};

type CheckboxProps = {
  isSelected: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({ isSelected }: CheckboxProps) => {
  return (
    <div
      role="checkbox"
      aria-checked={isSelected}
      className={cx(checkboxStyle.base, checkboxStyle.size, {
        [multiSelectCheckboxStyle.notSelected]: !isSelected,
        [multiSelectCheckboxStyle.selected]: isSelected,
      })}
    >
      {isSelected ? (
        <span title="checked">
          <SVG src={check} className={checkboxStyle.icon} />
        </span>
      ) : null}
    </div>
  );
};

export default Checkbox;
