import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const getResellerDealMediaOwners = createSelector(
  [
    (state: RootState) => state.reseller.availableMediaOwners,
    (state: RootState) => state.dealManagement.commonDeal.currentLine.mediaOwners,
  ],
  (availableMediaOwners, mediaOwners) => {
    return mediaOwners?.length > 0 ? mediaOwners : availableMediaOwners;
  },
);
