import { getSeparators } from 'modules/I18N';
import { formatUserInput } from './formatters';

const validate = (value, rules) => {
  let isValid = true;
  const errors = {};

  if (!rules) {
    return { isValid, errors };
  }

  if (rules.required) {
    errors.required = value.trim() === '';
  }

  if (rules.minLength) {
    errors.minLength = value.length < rules.minLength;
  }

  if (rules.maxLength) {
    errors.maxLength = value.length > rules.maxLength;
  }

  isValid = !Object.values(errors).filter((error) => error).length;
  return { isValid, errors };
};

export const isValidNumber = (userInput, maxValue, maximumFractionDigits, localeCode) => {
  const { groupSeparator, decimalSeparator } = getSeparators(localeCode);

  const splitUserInput = userInput.split('');
  const decimalIndex = splitUserInput.findIndex((value) => value === decimalSeparator);
  const groupIndex = splitUserInput.findIndex((value) => value === groupSeparator);
  if (groupIndex > decimalIndex && decimalIndex !== -1) return false;

  const userInputNumber = formatUserInput(userInput, localeCode);
  if (Number.isNaN(userInputNumber)) return false;

  const [, digitValues = ''] = userInput.split(decimalSeparator);
  const areValidFractionDigits = digitValues.length <= maximumFractionDigits;
  if (!areValidFractionDigits) return false;

  const isUserInputDecimalSeparator = userInput[userInput.length - 1] === decimalSeparator;
  if (isUserInputDecimalSeparator && !maximumFractionDigits) return false;

  return userInputNumber >= 0 && userInputNumber <= maxValue;
};

export default validate;
