/* eslint-disable sonarjs/cognitive-complexity */
import { DealType, DealStatus } from 'components/common/types/Deal.types';
import { formatISODateToDateObj, ONE_DAY_IN_MS } from 'utils/dateFormatUtil';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import {
  getListFrames,
  getTags,
  getLocationData,
  getSweepVisibility,
  getVisualUnitFrames,
  getProximity,
  getVenueTaxonomies,
} from 'components/pages/DealWithLines/common/transformDealDetails';
import { transformPattern } from 'components/pages/DealWithLines/common/Main/Line/Schedule/Pattern/daysInfo';
import { PRD_DEPLOYMENT_DATE_FOR_SUMMARY_PANEL_CHANGES } from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/consts';
import { SOURCE_SYSTEM } from 'consts/sourceSystem';
import { formatIndexOptimisationWithEmptyDisplayName } from 'utils/formatIndexOptimisation';
import { isAfter } from 'date-fns';

export const formatAssets = (assets) =>
  assets.map(({ address: street, postcode: postCode, mediaOwnerAssetId, ...asset }) => ({
    ...asset,
    street,
    postCode,
    mediaOwner: mediaOwnerAssetId.mediaOwner,
    frameId: mediaOwnerAssetId ? mediaOwnerAssetId.assetId : asset.frameId,
  }));

export const transformAvailability = ({
  totalFrames,
  availableFrames,
  totalImpressions,
  availableImpressions,
  totalCost,
  assets,
  assetCpmDistribution,
}) => ({
  allocatedFrames: totalFrames,
  availableFrames,
  allocatedImpressions: totalImpressions,
  availableImpressions,
  totalCost,
  assets: formatAssets(assets),
  assetCpmDistribution,
});

export const getDealSummary = (
  {
    startDate,
    endDate,
    totalFrames,
    availableFrames,
    totalImpressions,
    availableImpressions,
    impressions,
    deliveredImpressions,
    budget,
    pgDistributionType,
    frameCount,
    sot,
    minTargetSot,
    maxTargetSot,
    adjustedSots,
    totalCost,
    includeFrames,
    excludeFrames,
    assets,
    campaignCpm,
    environments,
    tags,
    countries,
    cities,
    streets,
    counties,
    postcodes,
    productFormats,
    currentRemainingImpressions,
  },
  dealTypeCode,
  hasSweepTargetingEnabled,
  hasNonGuaranteedExtendedTargetEnabled,
) => ({
  startDate: formatISODateToDateObj(startDate, Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC)),
  endDate: formatISODateToDateObj(endDate, Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC)),
  availability: transformAvailability({
    totalFrames,
    availableFrames,
    totalImpressions,
    availableImpressions,
    totalCost,
    assets,
    assetCpmDistribution: campaignCpm,
  }),
  ...getListFrames(includeFrames, excludeFrames),
  ...(tags ? { tags } : {}),
  ...getLocationData(countries, cities, streets, counties, postcodes),
  ...(!!impressions || impressions === 0 ? { impressions } : {}),
  ...(!!deliveredImpressions || deliveredImpressions === 0 ? { deliveredImpressions } : {}),
  ...(!!budget || budget === 0 ? { budget } : {}),
  ...(!!frameCount || frameCount === 0 ? { frames: frameCount } : {}),
  ...(sot ? { sot } : {}),
  ...(minTargetSot ? { minTargetSot } : {}),
  ...(maxTargetSot ? { maxTargetSot } : {}),
  ...(adjustedSots ? { adjustedSots } : {}),
  ...(getSweepVisibility(dealTypeCode, hasSweepTargetingEnabled, hasNonGuaranteedExtendedTargetEnabled) &&
  pgDistributionType
    ? { sweeps: pgDistributionType }
    : {}),
  ...(environments ? { environments } : {}),
  ...(productFormats ? { productFormats } : {}),
  ...(currentRemainingImpressions ? { currentRemainingImpressions } : {}),
});

const transformSegment = (segmentData) => {
  if (!segmentData || !segmentData.length)
    return {
      segment: {
        selectedSegmentDataProvider: '',
        selectedSegments: {},
      },
    };

  const [{ cpmCurrency, cpmPremium, cpmPercentage, dataProvider, segmentId, segmentName }] = segmentData;

  return {
    segment: {
      selectedSegmentDataProvider: dataProvider,
      selectedSegments: {
        [segmentId]: {
          cpmCurrency: cpmPremium ? cpmCurrency : undefined,
          cpmPercentage,
          cpmPremium,
          dataProvider,
          segmentId,
          segmentName,
        },
      },
    },
  };
};

const transformLine = (
  {
    internalId: id,
    dealName,
    bookingStatusCode,
    startDate: startDateString,
    endDate,
    createdDate,
    dischargedFrameIds,
    floorCPM,
    country,
    town,
    county,
    address,
    postcode,
    environment,
    excludeFrames,
    excludeVisualUnits,
    frameCount: frames,
    includeFrames,
    includeVisualUnits,
    pattern,
    productFormat,
    proximity,
    pgDistributionType,
    sot,
    impressions,
    budget,
    tags,
    assetCpmDistribution,
    totalCost,
    assets,
    availableFrames,
    availableImpressions,
    totalFrames,
    totalImpressions,
    deliveredImpressions,
    venueTaxonomyEnumerationIds,
    earliestAssetTimezone,
    latestAssetTimezone,
    startDateTimeForEarliestAsset,
    endDateTimeForLatestAsset,
    lineId,
    frontEndType,
    ceilingSot,
    floorSot,
    adjustedSot,
    indexOptimization,
    secondaryAudience,
    lineImpressions,
    maxCPM,
    mediaOwners,
  },
  dealTypeCode,
  hasSweepTargetingEnabled,
  hasNonGuaranteedExtendedTargetEnabled,
) => {
  const startDate = formatISODateToDateObj(
    startDateString,
    Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC),
  );
  const { currentRemainingImpressions } = lineImpressions;

  return {
    id,
    name: dealName,
    bookingStatusCode,
    lineId,
    cpm: floorCPM,
    createdDate: formatISODateToDateObj(createdDate),
    dischargedFrameIds,
    startDate,
    endDate: formatISODateToDateObj(endDate, Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC)),
    terminated: bookingStatusCode === DealStatus.TERMINATED,
    frontEndType,
    indexOptimisation: formatIndexOptimisationWithEmptyDisplayName(indexOptimization),
    availability: transformAvailability({
      totalFrames,
      availableFrames,
      totalImpressions,
      availableImpressions,
      totalCost,
      assets,
      assetCpmDistribution,
    }),
    preservedAllocation: {
      allocatedFrames: totalFrames,
      allocatedImpressions: totalImpressions,
      availableFrames,
      availableImpressions,
      budget,
      deliveredImpressions,
      frames,
      impressions,
      totalCost,
    },
    ...(secondaryAudience ? transformSegment(secondaryAudience) : {}),
    ...getListFrames(includeFrames, excludeFrames),
    ...getVisualUnitFrames(includeVisualUnits, excludeVisualUnits),
    ...getLocationData(country, town, address, county, postcode),
    ...getTags(tags),
    ...getProximity(proximity),
    ...(getSweepVisibility(dealTypeCode, hasSweepTargetingEnabled, hasNonGuaranteedExtendedTargetEnabled) &&
    pgDistributionType
      ? { sweep: pgDistributionType }
      : {}),
    ...(impressions ? { impressions } : {}),
    ...(deliveredImpressions ? { deliveredImpressions } : {}),
    ...(budget ? { budget } : {}),
    ...(frames ? { frames } : {}),
    ...(sot ? { sot } : {}),
    ...(ceilingSot ? { ceilingSot } : {}),
    ...(floorSot ? { floorSot } : {}),
    ...(adjustedSot ? { adjustedSot } : {}),
    ...(pattern
      ? { selectedDays: transformPattern(pattern.pattern, startDate), patternLength: pattern.patternLength }
      : {}),
    ...(environment ? { environments: environment } : {}),
    ...(productFormat ? { productFormats: productFormat } : {}),
    ...getVenueTaxonomies(venueTaxonomyEnumerationIds),
    ...(earliestAssetTimezone ? { earliestFrameTimezone: earliestAssetTimezone } : {}),
    ...(latestAssetTimezone ? { latestFrameTimezone: latestAssetTimezone } : {}),
    ...(startDateTimeForEarliestAsset ? { earliestFrameStartDate: new Date(startDateTimeForEarliestAsset) } : {}),
    ...(endDateTimeForLatestAsset ? { latestFrameEndDate: new Date(endDateTimeForLatestAsset) } : {}),
    ...(currentRemainingImpressions ? { currentRemainingImpressions } : {}),
    ...(maxCPM ? { maxCPM } : {}),
    ...(mediaOwners ? { mediaOwners } : {}),
  };
};

export const getSummaryMinMaxTargetSot = (dealLines) => {
  const DEFAULT_MIN_MAX = { minTargetSot: 0, maxTargetSot: 0 };

  if (!dealLines?.length) return DEFAULT_MIN_MAX;

  const sotValues = dealLines.reduce((acc, { sot, floorSot, ceilingSot }) => {
    acc.push(...[...(sot ? [sot] : []), ...(floorSot ? [floorSot] : []), ...(ceilingSot ? [ceilingSot] : [])]);

    return acc;
  }, []);

  return sotValues.length
    ? { minTargetSot: Math.min(...sotValues), maxTargetSot: Math.max(...sotValues) }
    : DEFAULT_MIN_MAX;
};

export const transformDealLineDetails = ({
  campaignID,
  dealTypeCode,
  sourceSystem,
  advertiserName,
  advertiserCode,
  brandName,
  brandCode,
  productCategoryName,
  productCategoryCode,
  dspName,
  dspCode,
  specialistName,
  specialistCode,
  agencyName,
  agencyCode,
  salesPersonName,
  salesPersonCode,
  salesTeamName,
  salesTeamCode,
  priority,
  auctionModel,
  name,
  externalReference,
  bookingStatusCode,
  syncWithDsp,
  dspSeatId,
  enableCreativeSubmissionInBidStream,
  enableOpenMarketplace,
  enableLineLevelTrading,
  // ---summary---
  adjustedSots,
  totalCost,
  totalFrames,
  totalImpressions,
  frameCount,
  availableFrames,
  availableImpressions,
  startDate,
  endDate,
  impressions,
  sot,
  pgDistributionType,
  budget,
  includeFrames,
  excludeFrames,
  assets,
  campaignCpm,
  environments,
  tags,
  countries,
  cities,
  streets,
  counties,
  postcodes,
  productFormats,
  bids,
  deliveredImpressions,
  campaignImpressions,
  // ---lines---
  dealLines,
  hasSweepTargetingEnabled,
  hasNonGuaranteedExtendedTargetEnabled,
}) => {
  const { minTargetSot, maxTargetSot } = getSummaryMinMaxTargetSot(dealLines);
  const { currentRemainingImpressions } = campaignImpressions;

  const dealSummary = {
    totalCost,
    totalFrames,
    totalImpressions,
    frameCount,
    availableFrames,
    availableImpressions,
    startDate,
    endDate,
    impressions,
    sot,
    adjustedSots,
    minTargetSot,
    maxTargetSot,
    pgDistributionType,
    budget,
    includeFrames,
    excludeFrames,
    assets,
    campaignCpm,
    environments,
    tags,
    countries,
    cities,
    streets,
    counties,
    postcodes,
    productFormats,
    deliveredImpressions,
    currentRemainingImpressions,
  };

  const commonDeal = {
    dealId: campaignID,
    dealType: dealTypeCode,
    sourceSystem,
    advertiser: { name: advertiserName, code: advertiserCode },
    brand: { name: brandName, code: brandCode },
    productCategory: { name: productCategoryName, code: productCategoryCode },
    ...(specialistName ? { specialist: { name: specialistName, code: specialistCode } } : {}),
    ...(agencyName ? { agency: { name: agencyName, code: agencyCode } } : {}),
    ...(salesPersonName ? { salesPerson: { name: salesPersonName, code: salesPersonCode } } : {}),
    ...(salesTeamName ? { salesTeam: { name: salesTeamName, code: salesTeamCode } } : {}),
    dealPriority: priority,
    dealName: name,
    ...(externalReference ? { externalReference } : {}),
    ...(dealTypeCode === DealType.NON_GUARANTEED_FLOOR_PRICE && auctionModel ? { auctionModel } : {}),
    bookingStatusCode,
    summary: {
      ...(deliveredImpressions ? { deliveredImpressions } : {}),
      ...getDealSummary(dealSummary, dealTypeCode, hasSweepTargetingEnabled, hasNonGuaranteedExtendedTargetEnabled),
    },
    bids: bids
      ? {
          bidRequests: bids.bidRequest,
          bidResponses: bids.bidResponse,
          bookedImpressions: bids.bookedImpressions,
          fillRate: bids.fillRate,
          revenue: bids.revenue,
          soldImpressions: bids.soldImpressions,
          wonImpressions: bids.wonImpressions,
        }
      : undefined,
    // TODO: ExpirationDate should come from backend. Will integrate it once API is finalized. Created https://projectcyclone.atlassian.net/browse/PP-3613.
    ...(sourceSystem === SOURCE_SYSTEM.DV360 ? { expirationDate: new Date(new Date().getTime() + ONE_DAY_IN_MS) } : {}),
  };

  const lines = dealLines.map((line) =>
    transformLine(line, dealTypeCode, hasSweepTargetingEnabled, hasNonGuaranteedExtendedTargetEnabled),
  );
  const lineWithOOC = lines.findIndex((line) => line.dischargedFrameIds?.length);

  const currentLine = lineWithOOC > -1 ? lines[lineWithOOC] : lines[lines.length - 1];

  const programmaticData = {
    programmatic: {
      dsp: { name: dspName, code: dspCode },
      syncWithDsp,
      dspSeatId,
      enableCreativeSubmissionInBidStream,
      enableOpenMarketplace,
      enableLineLevelTrading,
    },
  };

  return {
    commonDeal: {
      ...commonDeal,
      currentLine,
    },
    ...programmaticData,
    backupFormData: {
      commonDeal,
      ...programmaticData,
      lines,
    },
  };
};

export const combineProposalAllocationAndDealSummary = (
  isNewDeal,
  isNewLine,
  preservedCurrentLineSummary,
  currentLineAllocation,
  currentDealSummary,
  backupDealSummary,
  dealType,
  hasSweepTargetingEnabled,
  hasNonGuaranteedExtendedTargetEnabled,
) => {
  let {
    availableFrames,
    availableImpressions,
    deliveredImpressions,
    summary: { budget, frameCount: frames, impressions },
    totalCost,
    totalFrames,
    totalImpressions,
  } = currentLineAllocation;

  const dealSummary = isNewDeal ? currentDealSummary : backupDealSummary;

  if (!isNewDeal && isNewLine) {
    totalFrames = dealSummary.availability.allocatedFrames + totalFrames;
    availableFrames = dealSummary.availability.availableFrames + availableFrames;
    totalImpressions = dealSummary.availability.allocatedImpressions + totalImpressions;
    availableImpressions = dealSummary.availability.availableImpressions + availableImpressions;
    deliveredImpressions = dealSummary.deliveredImpressions + deliveredImpressions;
    totalCost = dealSummary.availability.totalCost + totalCost;
    frames = dealSummary.frames + frames;
    impressions = dealSummary.impressions + impressions;
    budget = dealSummary.budget + budget;
  }

  if (!isNewDeal && !isNewLine) {
    totalFrames = dealSummary.availability.allocatedFrames - preservedCurrentLineSummary.allocatedFrames + totalFrames;
    availableFrames =
      dealSummary.availability.availableFrames - preservedCurrentLineSummary.availableFrames + availableFrames;
    totalImpressions =
      dealSummary.availability.allocatedImpressions -
      preservedCurrentLineSummary.allocatedImpressions +
      totalImpressions;
    availableImpressions =
      dealSummary.availability.availableImpressions -
      preservedCurrentLineSummary.availableImpressions +
      availableImpressions;
    deliveredImpressions =
      dealSummary.deliveredImpressions - preservedCurrentLineSummary.deliveredImpressions + deliveredImpressions;
    totalCost = dealSummary.availability.totalCost - preservedCurrentLineSummary.totalCost + totalCost;
    frames = dealSummary.frames - preservedCurrentLineSummary.frames + frames;
    impressions = dealSummary.impressions - preservedCurrentLineSummary.impressions + impressions;
    budget = dealSummary.budget - preservedCurrentLineSummary.budget + budget;
  }

  return getDealSummary(
    {
      ...dealSummary,
      ...currentLineAllocation.summary,
      availableFrames,
      availableImpressions,
      budget,
      deliveredImpressions,
      frames,
      impressions,
      totalCost,
      totalFrames,
      totalImpressions,
    },
    dealType,
    hasSweepTargetingEnabled,
    hasNonGuaranteedExtendedTargetEnabled,
  );
};

export const transformLineAllocationProposal = (currentLineAllocation) => ({
  allocatedFrames: currentLineAllocation.totalFrames,
  allocatedImpressions: currentLineAllocation.totalImpressions,
  availableFrames: currentLineAllocation.availableFrames,
  availableImpressions: currentLineAllocation.availableImpressions,
  budget: currentLineAllocation.summary.budget,
  deliveredImpressions: currentLineAllocation.deliveredImpressions,
  frames: currentLineAllocation.frameCount,
  impressions: currentLineAllocation.summary.impressions,
  totalCost: currentLineAllocation.totalCost,
});

export const isCreatedAfterSummaryPanelChanges = ({ dealType, lines }) => {
  return (
    dealType === DealType.GUARANTEED &&
    isAfter(new Date(), PRD_DEPLOYMENT_DATE_FOR_SUMMARY_PANEL_CHANGES) &&
    (!lines ||
      lines.every(({ createdDate }) => isAfter(new Date(createdDate), PRD_DEPLOYMENT_DATE_FOR_SUMMARY_PANEL_CHANGES)))
  );
};
/* eslint-enable sonarjs/cognitive-complexity */
