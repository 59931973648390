export const BIDS_LOSS_REASONS = {
  outbid: {
    id: 'LOST_TO_HIGHER_BID',
    label: 'Lost to higher bid',
    code: 102,
  },
  belowFloor: {
    id: 'BID_BELOW_DEAL_FLOOR',
    label: 'Bid below deal floor',
    code: 101,
  },
  invalidBidReq: {
    id: 'MISSING_BID_PRICE',
    label: 'Missing bid price',
    code: 9,
  },
  noBid: {
    id: 'NO_BID_REASON',
    label: 'No bid',
    code: -1,
  },
  internalError: {
    id: 'INTERNAL_ERROR',
    label: 'Internal error',
    code: 1,
  },
  bidTimeOut: {
    id: 'BID_TIME_OUT',
    label: 'Bid timeout',
    code: 1000,
  },
  missingMarkup: {
    code: 7,
    id: 'MISSING_MARKUP',
    label: 'Missing markup',
  },
  buyerSeatBlocked: {
    code: 104,
    id: 'BUYER_SEAT_BLOCKED',
    label: 'Buyer seat blocked',
  },
  impressionOpportunityExpired: {
    code: 2,
    id: 'IMPRESSION_OPPORTUNITY_EXPIRED',
    label: 'Impression opportunity expired',
  },
  bidWon: {
    code: 0,
    id: 'BID_WON',
    label: 'Bid won',
  },
  invalidBidResponse: {
    code: 3,
    id: 'INVALID_BID_RESPONSE',
    label: 'Invalid bid response',
  },
  invalidDealId: {
    code: 4,
    id: 'INVALID_DEAL_ID',
    label: 'Invalid deal ID',
  },
  invalidAuctionId: {
    code: 5,
    id: 'INVALID_AUCTION_ID',
    label: 'Invalid auction ID',
  },
  bidBelowAuctionFloor: {
    code: 100,
    id: 'BID_BELOW_AUCTION_FLOOR',
    label: 'Bid below auction floor',
  },
  lostToBidPmpDeal: {
    code: 103,
    id: 'LOST_TO_BID_PMP_DEAL',
    label: 'Lost to bid PMP deal',
  },
  bidPriceFormatError: {
    code: 1001,
    id: 'BID_PRICE_FORMAT_ERROR',
    label: 'Bid responses > 6 decimal places',
  },
};

export const CREATIVE_LOSS_REASONS = {
  wrongCreativeType: {
    id: 'CREATIVE_FILTERED_INCORRECT_FORMAT',
    label: 'Incorrect format',
    code: 204,
  },
  wrongCreativeSize: {
    id: 'CREATIVE_FILTERED_SIZE_NOT_ALLOWED',
    label: 'Creative size not allowed',
    code: 203,
  },
  pendingProcessing: {
    id: 'CREATIVE_FILTERED_PENDING_PROCESSING',
    label: 'Creative pending processing',
    code: 201,
  },
  notApproved: {
    id: 'CREATIVE_FILTERED_DISAPPROVED',
    label: 'Creative not approved',
    code: 202,
  },
  missingMinCreativeApprovalData: {
    id: 'MISSING_MIN_CREATIVE_APPROVAL_DATA',
    label: 'Default/Approved creative missing',
    code: 10,
  },
  creativeFiltered: {
    id: 'CREATIVE_FILTERED',
    label: 'Creative filtered',
    code: 200,
  },
  advertiserExclusion: {
    id: 'CREATIVE_FILTERED_ADVERTISER_EXCLUSIONS',
    label: 'Creative filtered advertiser exclusions',
    code: 205,
  },
  appBundleExclusion: {
    id: 'CREATIVE_FILTERED_APP_BUNDLE_EXCLUSIONS',
    label: 'Creative filtered app bundle exclusions',
    code: 206,
  },
  creativeNotSecure: {
    id: 'CREATIVE_FILTERED_NOT_SECURE',
    label: 'Creative filtered not secure',
    code: 207,
  },
  languageExclusion: {
    id: 'CREATIVE_FILTERED_LANGUAGE_EXCLUSIONS',
    label: 'Creative filtered language exclusions',
    code: 208,
  },
  attributeExclusion: {
    id: 'CREATIVE_FILTERED_ATTRIBUTE_EXCLUSIONS',
    label: 'Creative filtered attribute exclusions',
    code: 210,
  },
  adTypeExclusion: {
    id: 'CREATIVE_FILTERED_AD_TYPE_EXCLUSIONS',
    label: 'Creative filtered ad type exclusions',
    code: 211,
  },
  animationTooLongExclusion: {
    id: 'CREATIVE_FILTERED_ANIMATION_TOO_LONG_EXCLUSIONS',
    label: 'Creative filtered animation too long exclusions',
    code: 212,
  },
  creativeNotAllowedInPmpDeal: {
    id: 'CREATIVE_FILTERED_NOT_ALLOWED_IN_PMP_DEAL',
    label: 'Creative filtered not allowed in PMP deal',
    code: 213,
  },
  invalidAdvertiserDomain: {
    id: 'INVALID_ADVERTISER_DOMAIN',
    label: 'Invalid advertiser domain',
    code: 6,
  },
  missingCreativeId: {
    id: 'MISSING_CREATIVE_ID',
    label: 'Missing creative ID',
    code: 8,
  },
  prohibited: {
    id: 'CREATIVE_FILTERED_CATEGORY_EXCLUSIONS',
    label: 'Creative filtered - category exclusions',
    code: 209,
  },
};

export const OTHER_LOSS_REASONS = {
  readTimeoutError: {
    id: 'READ_TIMEOUT_ERROR',
    label: 'Read timeout error',
    code: 2001,
  },
  connectionPrematureError: {
    id: 'CONNECTION_PREMATURE_ERROR',
    label: 'Connection premature error',
    code: 2002,
  },
  connectionResetError: {
    id: 'CONNECTION_RESET_ERROR',
    label: 'Connection reset error',
    code: 2003,
  },
  sslError: {
    id: 'SSL_ERROR',
    label: 'SSL error',
    code: 2004,
  },
  timeoutError: {
    id: 'TIMEOUT_ERROR',
    label: 'Timeout error',
    code: 2005,
  },
  badGatewayError: {
    id: 'BAD_GATEWAY_ERROR',
    label: 'Bad gateway error',
    code: 2006,
  },
  serviceUnavailableError: {
    id: 'SERVICE_UNAVAILABLE_ERROR',
    label: 'Service unavailable error',
    code: 2007,
  },
  internalServerError: {
    id: 'INTERNAL_SERVER_ERROR',
    label: 'Internal server error',
    code: 2009,
  },
  badRequest: {
    id: 'BAD_REQUEST',
    label: 'Bad request',
    code: 2010,
  },
};
