import { formatDate, formatPercent } from 'modules/I18N';
import { ICellRendererParams } from 'ag-grid-community';

export const percentageCellRenderer = (params: ICellRendererParams, localeCode: string): string =>
  `${formatPercent({ value: params.value / 100, localeCode })}`;

export const dateCellRenderer = ({
  params,
  localeCode,
}: {
  params: ICellRendererParams;
  localeCode?: string;
}): string => {
  if (!params.value) return '';

  return formatDate(params.value, localeCode);
};

export const textCellRenderer = (params: ICellRendererParams): string => {
  if (!params.value) return 'Not available';

  return params.value;
};
