import Input from 'lib/Input';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { onFilterChange } from 'store/pages/contentManagement/creativeSetDetails/reducer';

type FilterLocationProps = {
  placeholder?: string;
};

const FilterLocation: React.FC<FilterLocationProps> = ({ placeholder = 'Location' }) => {
  const dispatch = useDispatch();
  const location = useSelector((state: RootState) => state.pages.contentManagement.creativeSetDetails.filters.location);

  return (
    <Input
      dataTestId="location"
      name="location"
      value={location}
      placeholder={placeholder}
      onChange={(value) => {
        dispatch(onFilterChange({ location: value as string }));
      }}
      debounceTime={1000}
    />
  );
};

export default FilterLocation;
