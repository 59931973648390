import SVG from 'react-inlinesvg';
import infoSvg from 'assets/icons/info.svg';

type Props = {
  frameId: string;
  isDischarged: boolean;
};

export const FrameIdRenderer: React.FC<Props> = ({ frameId, isDischarged }) => {
  return (
    <div className="flex space-x-1.5">
      {isDischarged && <SVG className="fill-current text-pinkRed-700" src={infoSvg} />}
      <span>{frameId}</span>
    </div>
  );
};
