import Input from 'lib/Input';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { onFilterChange } from 'store/pages/contentManagement/creativeSetDetails/reducer';

export type FilterEnvironmentProps = {
  placeholder?: string;
};

const FilterEnvironment: React.FC<FilterEnvironmentProps> = ({ placeholder = 'Select environment' }) => {
  const dispatch = useDispatch();
  const environment = useSelector(
    (state: RootState) => state.pages.contentManagement.creativeSetDetails.filters.environment,
  );

  return (
    <Input
      dataTestId="filter-area-environment"
      name="environment"
      value={environment}
      placeholder={placeholder}
      onChange={(value) => {
        dispatch(onFilterChange({ environment: value as string }));
      }}
      debounceTime={1000}
    />
  );
};

export default FilterEnvironment;
