import { FeatureFlags } from 'components/common/types/Features.types';
import { PublisherFeatures } from 'components/common/types/Publisher.types';
import features from 'constants/features';

const localEnvironmentFeatures = features();
const negativeFlags = [FeatureFlags.VISUAL_UNITS];

export const mergePublisherAndEnvironmentFeatures = (
  environmentId: string,
  publisherFeatures: PublisherFeatures,
  environmentFeatures = localEnvironmentFeatures,
): PublisherFeatures => {
  const encryptEnvironmentFeatures = Object.keys(environmentFeatures).reduce((acc, key) => {
    const encryptedKey = btoa(key);
    acc[encryptedKey] = environmentId;

    return acc;
  }, {} as PublisherFeatures);

  const addNegativeFlags = negativeFlags.reduce((acc, flag) => {
    const encryptFlag = btoa(flag);
    if (publisherFeatures[encryptFlag]) {
      delete publisherFeatures[encryptFlag];
    } else if (environmentFeatures[flag] !== false) {
      acc[encryptFlag] = environmentId;
    }

    return acc;
  }, {} as PublisherFeatures);

  Object.keys(encryptEnvironmentFeatures).forEach((key) => {
    const decryptFeature = atob(key);

    if (publisherFeatures[key]) {
      if (environmentFeatures[decryptFeature] === false) {
        delete publisherFeatures[key];
        delete encryptEnvironmentFeatures[key];
      }
    } else if (environmentFeatures[decryptFeature] === false) {
      delete encryptEnvironmentFeatures[key];
    }
  });

  return { ...publisherFeatures, ...encryptEnvironmentFeatures, ...addNegativeFlags };
};
