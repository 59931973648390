import { PageLoader } from 'components/patterns/Loader';

const withLoader = (WrappedComponent) => {
  return (props) => {
    return (
      <>
        <PageLoader />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withLoader;
