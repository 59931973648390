import RequiredAsterisk from 'components/common/RequiredAsterisk';
import { SingleFieldSetProps, MultipleFieldSetProps } from './FieldSet.types';

export const SingleFieldSet = ({
  id,
  label,
  children,
  isRequired,
  isSingleFieldSet,
}: SingleFieldSetProps): React.ReactElement => {
  return (
    <div className={`${isSingleFieldSet ? 'grid border-current px-6 py-5 bg-neutral-50 rounded-xl mb-2' : 'mb-4'}`}>
      <label htmlFor={id} className="sub-header-base text-neutral-950-opacity-60 block mb-1.5">
        {label}
        {isRequired ? <RequiredAsterisk /> : null}
      </label>
      {children}
    </div>
  );
};

export const MultipleFieldSet = ({ children }: MultipleFieldSetProps): React.ReactElement => {
  return <div className="grid border-current px-6 py-5 mb-2 bg-neutral-50 rounded-xl">{children}</div>;
};

SingleFieldSet.defaultProps = {
  isRequired: false,
  isSingleFieldSet: false,
};
