import { isEqual } from 'lodash';
import { useRef } from 'react';

export const useDeepEqualMemo = <T>(value: T): T | undefined => {
  const ref = useRef<T | undefined>(undefined);

  if (!isEqual(ref.current, value)) {
    ref.current = value;
  }

  return ref.current;
};
