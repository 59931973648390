import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SVG from 'react-inlinesvg';
import checkmarkSvg from 'assets/icons/checkmark.svg';
import minusSvg from 'assets/icons/minus.svg';

import styles from './RadioCheckBox.pcss';

const cx = classNames.bind(styles);

export const types = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
};

export const alignment = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

const RadioCheckBox = ({
  isChecked,
  groupName,
  type,
  onChange,
  value,
  label,
  isDisabled,
  labelAlignment,
  isIndeterminate,
  dataTestId,
}) => (
  <>
    <input
      className={cx('radioinput', 'inputSelector', { checkBoxinput: type === types.CHECKBOX })}
      name={groupName}
      type={type}
      id={`${groupName}_${value}`}
      checked={isChecked}
      value={value}
      data-test-id={dataTestId}
      onChange={(event) => onChange(value, event.target.checked, event)}
      disabled={isDisabled}
      indeterminate={isIndeterminate.toString()}
    />
    <label className="body-base" htmlFor={`${groupName}_${value}`}>
      {labelAlignment === alignment.LEFT && label}
      <span
        className={cx({
          'ml-1': labelAlignment === alignment.LEFT,
          'mr-1': labelAlignment === alignment.RIGHT,
        })}
      >
        <SVG src={checkmarkSvg} className={cx('svg-checkmark')} />
        {isIndeterminate ? <SVG src={minusSvg} className={cx('svg-indeterminate')} /> : null}
      </span>
      {labelAlignment === alignment.RIGHT && label}
    </label>
  </>
);

RadioCheckBox.propTypes = {
  groupName: PropTypes.string,
  isChecked: PropTypes.bool,
  type: PropTypes.oneOf([types.CHECKBOX, types.RADIO]),
  label: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  labelAlignment: PropTypes.oneOf(Object.values(alignment)),
  isIndeterminate: PropTypes.bool,
  dataTestId: PropTypes.string,
};

RadioCheckBox.defaultProps = {
  groupName: '',
  isChecked: false,
  type: types.RADIO,
  label: null,
  value: '',
  onChange: () => undefined,
  isDisabled: false,
  labelAlignment: alignment.RIGHT,
  isIndeterminate: false,
  dataTestId: null,
};

export default RadioCheckBox;
