import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { MinMax } from 'components/common/types/Range.types';
import { Line } from 'components/common/types/Deal.types';

const useCpmRange = (currentCpm: number): MinMax => {
  const isCpmCampaignLevel = useSelector((state: Store) => state.dealManagement.isCpmCampaignLevel);
  const lineId = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.lineId);
  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);

  return useMemo(() => {
    if (isCpmCampaignLevel) return { min: currentCpm, max: currentCpm };

    const cpms = (lines as Line[]).reduce(
      (acc, curr) => {
        acc.push(Number(curr.cpm));
        return acc;
      },
      [currentCpm],
    );

    return { min: Math.min(...cpms), max: Math.max(...cpms) };
  }, [currentCpm, lineId, lines, isCpmCampaignLevel]);
};

export default useCpmRange;
