import notepadSvg from 'assets/icons/notepad.svg';
import DropdownMenu from 'lib/DropdownMenu';

interface DealFormDropdownProps {
  openPlanner: () => void;
  openDealForm: () => void;
}

const DealFormDropdown: React.FC<DealFormDropdownProps> = ({ openPlanner, openDealForm }) => (
  <DropdownMenu
    dropdownMenuOptions={[
      {
        id: 'deal-form',
        onClick: openDealForm,
        label: 'Deal form',
      },
      {
        id: 'planner-native',
        onClick: openPlanner,
        label: 'Planner',
      },
    ]}
    icon={notepadSvg}
    label="Create deal"
  />
);

export default DealFormDropdown;
