import SVG from 'react-inlinesvg';

import Modal, { ModalSize } from 'components/patterns/Modal';
import { TableHeight } from 'components/patterns/Table';
import { FrameTag } from 'components/common/types/FrameTag.types';

import tagSvg from 'assets/icons/tag.svg';

import { TaggingRulesTable } from '../TaggingRulesTable/TaggingRulesTable';

interface Props {
  frameTags: FrameTag[];
  onClose: VoidFunction;
  isOpen: boolean;
  marketId: string;
}

export const TaggingRulesModal: React.FC<Props> = ({ isOpen = false, onClose, frameTags, marketId }) => {
  return (
    <Modal
      isOpen={isOpen}
      size={ModalSize.LARGE}
      icon={<SVG src={tagSvg} className="text-primary" />}
      title="Frame tagging rules"
      onClose={onClose}
      showCloseButton
    >
      <TaggingRulesTable
        frameTags={frameTags}
        tableHeight={TableHeight.NORMAL as keyof typeof TableHeight}
        marketId={marketId}
      />
    </Modal>
  );
};
