import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';

import { DealStatus } from 'components/common/types/Deal.types';
import { formatValue, FormatTypeEnum } from 'modules/I18N';
import { MAX_SAFE_INTEGER_ALLOWED_IN_BACKEND } from 'modules/api/constants';
import warningSvg from 'assets/icons/warning.svg';

import { changeDealCurrentLineData } from 'store/dealManagement/reducer';

import InputNumber from 'lib/InputNumber';
import FieldWithInfoMessage from 'components/patterns/FieldWithInfoMessage';
import { notifyError } from 'store/notification/reducer';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';

const Frames = ({ disabled }) => {
  const dispatch = useDispatch();
  const backupFormData = useSelector((state) => state.dealManagement.backupFormData);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);
  const id = useSelector((state) => state.dealManagement.commonDeal.currentLine.id);
  const frames = useSelector((state) => state.dealManagement.commonDeal.currentLine.frames);
  const availability = useSelector((state) => state.dealManagement.commonDeal.currentLine.availability);
  const { currencyCode, localeCode } = useSelector((state) => state.publisher.configuration);
  const { allocatedFrames } = availability;
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const backupLine = backupFormData.lines.find((line) => line.id === id);

  const [showFramesMessage, setShowFramesMessage] = useState(false);

  useEffect(() => {
    setShowFramesMessage(allocatedFrames && frames && allocatedFrames !== frames);
  }, [frames, allocatedFrames]);

  const changeFrames = (value) => {
    if (Number.isInteger(value) && Number(value) <= MAX_SAFE_INTEGER_ALLOWED_IN_BACKEND) {
      dispatch(changeDealCurrentLineData({ frames: value }));
    }
  };

  const onBlur = () => {
    if (Number(frames) && isPendingReservation && Number(frames) > backupLine?.frames) {
      dispatch(changeDealCurrentLineData({ frames: backupLine?.frames }));
      dispatch(notifyError({ message: 'Error - Cannot Increase Frames Of DV360 Deal' }));
    }
  };

  if (!useHasFeatureAccess(FeatureFlags.FRAME_TARGETING)) return null;

  return (
    <div>
      <label
        htmlFor="frames"
        className="sub-header-base text-neutral-950-opacity-60 mb-2 inline-block"
        data-test-id="frames-header"
      >
        Frames
      </label>
      <FieldWithInfoMessage
        field={
          <InputNumber
            name="frames"
            placeholder="Add number of frames…"
            value={frames}
            onBlur={onBlur}
            onChange={changeFrames}
            maximumFractionDigits={0}
            isDisabled={disabled}
            dataTestId="frames-input"
          />
        }
        icon={<SVG src={warningSvg} className="fill-current text-yellow" />}
        message={
          showFramesMessage
            ? `Your frame target of ${formatValue(frames, FormatTypeEnum.NUMBER, currencyCode, localeCode)} is ${
                frames < allocatedFrames ? 'less' : 'more'
              } than the allocated number of frames ${formatValue(
                allocatedFrames,
                FormatTypeEnum.NUMBER,
                currencyCode,
                localeCode,
              )}. Please refer to your deal summary for your booked solution`
            : ''
        }
      />
    </div>
  );
};

Frames.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default Frames;
