import { OpenStreetMapCountries } from './openStreetMapCountries';

const hkTimezone = 'Asia/Hong_Kong';
const sgTimezone = 'Asia/Singapore';
const frTimezone = 'Europe/Paris';
const gbTimezone = 'Europe/London';
const fiTimezone = 'Europe/Helsinki';
const esTimezone = 'Europe/Madrid';
const deTimezone = 'Europe/Berlin';
const nlTimezone = 'Europe/Amsterdam';
const itTimezone = 'Europe/Rome';
const osloTimezone = 'Europe/Oslo';
const atTimezone = 'Europe/Vienna';
const beTimezone = 'Europe/Brussels';
const ptTimezone = 'Europe/Lisbon';

const MARKET_WISE_LOCALE = {
  'market:jcd:gb': {
    localeCode: 'en-gb',
    currencyCode: 'GBP',
    timezone: gbTimezone,
    latitude: 51.509865,
    longitude: -0.118092,
    openStreetMapCountryName: OpenStreetMapCountries.UK,
  },
  'market:jcdqa:gb': {
    localeCode: 'en-gb',
    currencyCode: 'GBP',
    timezone: gbTimezone,
    latitude: 51.509865,
    longitude: -0.118092,
    openStreetMapCountryName: OpenStreetMapCountries.UK,
  },
  'market:jcd:us': {
    localeCode: 'en-us',
    currencyCode: 'USD',
    timezone: 'US/Eastern',
    latitude: 40.71278,
    longitude: -74.00594,
    openStreetMapCountryName: OpenStreetMapCountries.US,
  },
  BRANDEDCITIES_US: {
    localeCode: 'en-us',
    currencyCode: 'USD',
    timezone: 'US/Eastern',
    latitude: 40.71278,
    longitude: -74.00594,
    openStreetMapCountryName: OpenStreetMapCountries.US,
  },
  'market:jcd:nl': {
    localeCode: 'nl-nl',
    currencyCode: 'EUR',
    timezone: nlTimezone,
    latitude: 52.37022,
    longitude: 4.89517,
    openStreetMapCountryName: OpenStreetMapCountries.NL,
  },
  'market:jcdqa:nl': {
    localeCode: 'nl-nl',
    currencyCode: 'EUR',
    timezone: nlTimezone,
    latitude: 52.37022,
    longitude: 4.89517,
    openStreetMapCountryName: OpenStreetMapCountries.NL,
  },
  'market:jcd:fi': {
    localeCode: 'fi',
    currencyCode: 'EUR',
    timezone: fiTimezone,
    latitude: 60.17332,
    longitude: 24.94102,
    openStreetMapCountryName: OpenStreetMapCountries.FI,
  },
  'market:jcdqa:fi': {
    localeCode: 'fi',
    currencyCode: 'EUR',
    timezone: fiTimezone,
    latitude: 60.17332,
    longitude: 24.94102,
    openStreetMapCountryName: OpenStreetMapCountries.FI,
  },
  'market:jcd:fi:hive:rf': {
    localeCode: 'fi',
    currencyCode: 'EUR',
    timezone: fiTimezone,
    latitude: 60.17332,
    longitude: 24.94102,
    openStreetMapCountryName: OpenStreetMapCountries.FI,
  },
  JCDECAUX_FI: {
    localeCode: 'fi',
    currencyCode: 'EUR',
    timezone: fiTimezone,
    latitude: 60.17332,
    longitude: 24.94102,
    openStreetMapCountryName: OpenStreetMapCountries.FI,
  },
  'market:jcd:es': {
    localeCode: 'es-es',
    currencyCode: 'EUR',
    timezone: esTimezone,
    latitude: 40.41678,
    longitude: -3.70379,
    openStreetMapCountryName: OpenStreetMapCountries.ES,
  },
  'market:jcdqa:es': {
    localeCode: 'es-es',
    currencyCode: 'EUR',
    timezone: esTimezone,
    latitude: 40.41678,
    longitude: -3.70379,
    openStreetMapCountryName: OpenStreetMapCountries.ES,
  },
  'market:jcd:es:hive:2': {
    localeCode: 'es-es',
    currencyCode: 'EUR',
    timezone: esTimezone,
    latitude: 40.41678,
    longitude: -3.70379,
    openStreetMapCountryName: OpenStreetMapCountries.ES,
  },
  'market:jcd:it': {
    localeCode: 'it-it',
    currencyCode: 'EUR',
    timezone: itTimezone,
    latitude: 41.90278,
    longitude: 12.49637,
    openStreetMapCountryName: OpenStreetMapCountries.IT,
  },
  JCDECAUX_IT: {
    localeCode: 'it-it',
    currencyCode: 'EUR',
    timezone: itTimezone,
    latitude: 41.90278,
    longitude: 12.49637,
    openStreetMapCountryName: OpenStreetMapCountries.IT,
  },
  'market:jcdqa:it': {
    localeCode: 'it-it',
    currencyCode: 'EUR',
    timezone: itTimezone,
    latitude: 41.90278,
    longitude: 12.49637,
    openStreetMapCountryName: OpenStreetMapCountries.IT,
  },
  'market:jcd:de': {
    localeCode: 'de-de',
    currencyCode: 'EUR',
    timezone: deTimezone,
    latitude: 52.52001,
    longitude: 13.40495,
    openStreetMapCountryName: OpenStreetMapCountries.DE,
  },
  'market:jcdqa:de': {
    localeCode: 'de-de',
    currencyCode: 'EUR',
    timezone: deTimezone,
    latitude: 52.52001,
    longitude: 13.40495,
    openStreetMapCountryName: OpenStreetMapCountries.DE,
  },
  JCDECAUX_DE: {
    localeCode: 'de-de',
    currencyCode: 'EUR',
    timezone: deTimezone,
    latitude: 52.52001,
    longitude: 13.40495,
    openStreetMapCountryName: OpenStreetMapCountries.DE,
  },
  MEDIAFRANKFURT_DE: {
    localeCode: 'de-de',
    currencyCode: 'EUR',
    timezone: deTimezone,
    latitude: 50.1109,
    longitude: 8.6821,
    openStreetMapCountryName: OpenStreetMapCountries.DE,
  },
  'market:jcd:hk:airport': {
    localeCode: 'zh-hk',
    currencyCode: 'HKD',
    timezone: hkTimezone,
    latitude: 22.39643,
    longitude: 114.1095,
    openStreetMapCountryName: OpenStreetMapCountries.HK,
  },
  'market:jcdqa:hk:airport': {
    localeCode: 'zh-hk',
    currencyCode: 'HKD',
    timezone: hkTimezone,
    latitude: 22.39643,
    longitude: 114.1095,
    openStreetMapCountryName: OpenStreetMapCountries.HK,
  },
  'market:jcd:hk:cityscape': {
    localeCode: 'zh-hk',
    currencyCode: 'HKD',
    timezone: hkTimezone,
    latitude: 22.39643,
    longitude: 114.1095,
    openStreetMapCountryName: OpenStreetMapCountries.HK,
  },
  'market:jcdqa:hk:cityscape': {
    localeCode: 'zh-hk',
    currencyCode: 'HKD',
    timezone: hkTimezone,
    latitude: 22.39643,
    longitude: 114.1095,
    openStreetMapCountryName: OpenStreetMapCountries.HK,
  },
  'market:jcd:hk:metro': {
    localeCode: 'zh-hk',
    currencyCode: 'HKD',
    timezone: hkTimezone,
    latitude: 22.39643,
    longitude: 114.1095,
    openStreetMapCountryName: OpenStreetMapCountries.HK,
  },
  'market:jcd:sg': {
    localeCode: 'en-sg',
    currencyCode: 'SGD',
    timezone: sgTimezone,
    latitude: 1.3521,
    longitude: 103.8198,
    openStreetMapCountryName: OpenStreetMapCountries.SG,
  },
  JCDECAUX_SG: {
    localeCode: 'en-sg',
    currencyCode: 'SGD',
    timezone: sgTimezone,
    latitude: 1.3521,
    longitude: 103.8198,
    openStreetMapCountryName: OpenStreetMapCountries.SG,
  },
  'market:jcdqa:sg': {
    localeCode: 'en-sg',
    currencyCode: 'SGD',
    timezone: sgTimezone,
    latitude: 1.3521,
    longitude: 103.8198,
    openStreetMapCountryName: OpenStreetMapCountries.SG,
  },
  'market:jcd:be': {
    localeCode: 'nl-be',
    currencyCode: 'EUR',
    timezone: beTimezone,
    latitude: 50.85034,
    longitude: 4.35171,
    openStreetMapCountryName: OpenStreetMapCountries.BE,
  },
  'market:jcdqa:be': {
    localeCode: 'nl-be',
    currencyCode: 'EUR',
    timezone: beTimezone,
    latitude: 50.85034,
    longitude: 4.35171,
    openStreetMapCountryName: OpenStreetMapCountries.BE,
  },
  'market:jcd:fr': {
    localeCode: 'fr-fr',
    currencyCode: 'EUR',
    timezone: frTimezone,
    latitude: 48.85661,
    longitude: 2.35222,
    openStreetMapCountryName: OpenStreetMapCountries.FR,
  },
  JCDECAUX_FR_AIRPORT: {
    localeCode: 'fr-fr',
    currencyCode: 'EUR',
    timezone: frTimezone,
    latitude: 48.85661,
    longitude: 2.35222,
    openStreetMapCountryName: OpenStreetMapCountries.FR,
  },
  JCDECAUX_FR_MU: {
    localeCode: 'fr-fr',
    currencyCode: 'EUR',
    timezone: frTimezone,
    latitude: 48.85661,
    longitude: 2.35222,
    openStreetMapCountryName: OpenStreetMapCountries.FR,
  },
  MEDIATRANSPORTS_FR: {
    localeCode: 'fr-fr',
    currencyCode: 'EUR',
    timezone: frTimezone,
    latitude: 48.85661,
    longitude: 2.35222,
    openStreetMapCountryName: OpenStreetMapCountries.FR,
  },
  FILLUPMEDIA_FR: {
    localeCode: 'fr-fr',
    currencyCode: 'EUR',
    timezone: frTimezone,
    latitude: 48.85661,
    longitude: 2.35222,
    openStreetMapCountryName: OpenStreetMapCountries.FR,
  },
  'market:apg:ch': {
    localeCode: 'fr-ch',
    currencyCode: 'CHF',
    timezone: 'Europe/Zurich',
    latitude: 47.36865,
    longitude: 8.53918,
    openStreetMapCountryName: OpenStreetMapCountries.CH,
  },
  JCDECAUX_AU: {
    localeCode: 'en-au',
    currencyCode: 'AUD',
    timezone: 'Australia/Sydney',
    latitude: -33.86749,
    longitude: 151.20699,
    openStreetMapCountryName: OpenStreetMapCountries.AU,
  },
  TONICMEDIA_AU: {
    localeCode: 'en-au',
    currencyCode: 'AUD',
    timezone: 'Australia/Sydney',
    latitude: -33.86749,
    longitude: 151.20699,
    openStreetMapCountryName: OpenStreetMapCountries.AU,
  },
  JCDECAUX_NZ: {
    localeCode: 'en-nz',
    currencyCode: 'NZD',
    timezone: 'Pacific/Auckland',
    latitude: -36.84846,
    longitude: 174.76333,
    openStreetMapCountryName: OpenStreetMapCountries.NZ,
  },
  VIOOH_EXTERNAL: {
    localeCode: 'en-gb',
    currencyCode: 'GBP',
    timezone: gbTimezone,
    latitude: 51.509865,
    longitude: -0.118092,
    openStreetMapCountryName: OpenStreetMapCountries.UK,
  },
  'market:jcdqa:us': {
    localeCode: 'en-gb',
    currencyCode: 'GBP',
    timezone: gbTimezone,
    latitude: 51.509865,
    longitude: -0.118092,
    openStreetMapCountryName: OpenStreetMapCountries.UK,
  },
  'market:jcd:no': {
    localeCode: 'no-no',
    currencyCode: 'NOK',
    timezone: osloTimezone,
    latitude: 59.9139,
    longitude: 10.7522,
    openStreetMapCountryName: OpenStreetMapCountries.NO,
  },
  'market:jcdqa:no': {
    localeCode: 'no-no',
    currencyCode: 'NOK',
    timezone: osloTimezone,
    latitude: 59.9139,
    longitude: 10.7522,
    openStreetMapCountryName: OpenStreetMapCountries.NO,
  },
  'market:jcd:no:hive': {
    localeCode: 'no-no',
    currencyCode: 'NOK',
    timezone: osloTimezone,
    latitude: 59.9139,
    longitude: 10.7522,
    openStreetMapCountryName: OpenStreetMapCountries.NO,
  },
  JCDECAUX_BR: {
    localeCode: 'pt-br',
    currencyCode: 'BRL',
    timezone: 'Brazil/East',
    latitude: -23.533773,
    longitude: -46.62529,
    openStreetMapCountryName: OpenStreetMapCountries.BR,
  },
  'market:jcdqa:br': {
    localeCode: 'pt-br',
    currencyCode: 'BRL',
    timezone: 'America/Sao_Paulo',
    latitude: -23.533773,
    longitude: -46.62529,
    openStreetMapCountryName: OpenStreetMapCountries.BZ,
  },
  'market:jcdqa:dk': {
    localeCode: 'da-da',
    currencyCode: 'DKK',
    timezone: 'Europe/Copenhagen',
    latitude: 55.676098,
    longitude: 12.568337,
    openStreetMapCountryName: OpenStreetMapCountries.DK,
  },
  CATMOUSE_AU: {
    localeCode: 'en-gb',
    currencyCode: 'GBP',
    timezone: gbTimezone,
    latitude: 51.509865,
    longitude: -0.118092,
    openStreetMapCountryName: OpenStreetMapCountries.UK,
  },
  'market:jcd:at': {
    localeCode: 'de-at',
    currencyCode: 'EUR',
    timezone: atTimezone,
    latitude: 48.2006,
    longitude: 16.3689,
    openStreetMapCountryName: OpenStreetMapCountries.AT,
  },
  'market:jcdqa:at': {
    localeCode: 'de-at',
    currencyCode: 'EUR',
    timezone: atTimezone,
    latitude: 48.2006,
    longitude: 16.3689,
    openStreetMapCountryName: OpenStreetMapCountries.AT,
  },
  VIOOH_E2E_ADSERVER: {
    localeCode: 'en-gb',
    currencyCode: 'GBP',
    timezone: gbTimezone,
    latitude: 51.509865,
    longitude: -0.118092,
    openStreetMapCountryName: OpenStreetMapCountries.UK,
  },
  'market:jcdqa:hk:metro': {
    localeCode: 'zh-hk',
    currencyCode: 'HKD',
    timezone: hkTimezone,
    latitude: 22.39643,
    longitude: 114.1095,
    openStreetMapCountryName: OpenStreetMapCountries.HK,
  },
  JCDECAUX_NL: {
    localeCode: 'nl-nl',
    currencyCode: 'EUR',
    timezone: nlTimezone,
    latitude: 52.37022,
    longitude: 4.89517,
    openStreetMapCountryName: OpenStreetMapCountries.NL,
  },
  DOOHYOULIKE_FR: {
    localeCode: 'fr-fr',
    currencyCode: 'EUR',
    timezone: frTimezone,
    latitude: 48.85661,
    longitude: 2.35222,
    openStreetMapCountryName: OpenStreetMapCountries.FR,
  },
  INSTOREMEDIA_FR: {
    localeCode: 'fr-fr',
    currencyCode: 'EUR',
    timezone: frTimezone,
    latitude: 48.85661,
    longitude: 2.35222,
    openStreetMapCountryName: OpenStreetMapCountries.FR,
  },
  JCDECAUX_MX: {
    localeCode: 'es-mx',
    currencyCode: 'MXN',
    timezone: 'America/Mexico_City',
    latitude: 19.432608,
    longitude: 99.1332,
    openStreetMapCountryName: OpenStreetMapCountries.MX,
  },
  'market:viooh:qa:internal': {
    localeCode: 'en-gb',
    currencyCode: 'GBP',
    timezone: gbTimezone,
    latitude: 51.509865,
    longitude: -0.118092,
    openStreetMapCountryName: OpenStreetMapCountries.UK,
  },
  APG_CH: {
    localeCode: 'fr-ch',
    currencyCode: 'CHF',
    timezone: 'Europe/Zurich',
    latitude: 47.36865,
    longitude: 8.53918,
    openStreetMapCountryName: OpenStreetMapCountries.CH,
  },
  FRAMEN_DE: {
    localeCode: 'de-de',
    currencyCode: 'EUR',
    timezone: deTimezone,
    latitude: 52.50809,
    longitude: 13.39119,
    openStreetMapCountryName: OpenStreetMapCountries.DE,
  },
  JCDECAUX_AT: {
    localeCode: 'de-at',
    currencyCode: 'EUR',
    timezone: atTimezone,
    latitude: 48.2082,
    longitude: 16.3738,
    openStreetMapCountryName: OpenStreetMapCountries.AT,
  },
  JCDECAUX_NL_HIVE: {
    localeCode: 'nl-nl',
    currencyCode: 'EUR',
    timezone: nlTimezone,
    latitude: 52.37022,
    longitude: 4.89517,
    openStreetMapCountryName: OpenStreetMapCountries.NL,
  },
  JCDECAUX_NL_HIVE2: {
    localeCode: 'nl-nl',
    currencyCode: 'EUR',
    timezone: nlTimezone,
    latitude: 52.37022,
    longitude: 4.89517,
    openStreetMapCountryName: OpenStreetMapCountries.NL,
  },
  JCDECAUX_NL_HIVE3: {
    localeCode: 'nl-nl',
    currencyCode: 'EUR',
    timezone: nlTimezone,
    latitude: 52.37022,
    longitude: 4.89517,
    openStreetMapCountryName: OpenStreetMapCountries.NL,
  },
  JCDECAUX_HK_CITYSCAPE: {
    localeCode: 'zh-hk',
    currencyCode: 'HKD',
    timezone: hkTimezone,
    latitude: 22.39643,
    longitude: 114.1095,
    openStreetMapCountryName: OpenStreetMapCountries.HK,
  },
  JCDECAUX_BE: {
    localeCode: 'nl-be',
    currencyCode: 'EUR',
    timezone: beTimezone,
    latitude: 50.85034,
    longitude: 4.35171,
    openStreetMapCountryName: OpenStreetMapCountries.BE,
  },
  'market:jcd:at:sbx': {
    localeCode: 'de-at',
    currencyCode: 'EUR',
    timezone: atTimezone,
    latitude: 48.2082,
    longitude: 16.3738,
    openStreetMapCountryName: OpenStreetMapCountries.AT,
  },
  JCDECAUX_ES: {
    localeCode: 'es-es',
    currencyCode: 'EUR',
    timezone: esTimezone,
    latitude: 40.41678,
    longitude: -3.70379,
    openStreetMapCountryName: OpenStreetMapCountries.ES,
  },
  JCDECAUX_ES_HIVE_2: {
    localeCode: 'es-es',
    currencyCode: 'EUR',
    timezone: esTimezone,
    latitude: 40.41678,
    longitude: -3.70379,
    openStreetMapCountryName: OpenStreetMapCountries.ES,
  },
  JCDECAUX_PT: {
    localeCode: 'pt-pt',
    currencyCode: 'EUR',
    timezone: ptTimezone,
    latitude: 38.736946,
    longitude: -9.142685,
    openStreetMapCountryName: OpenStreetMapCountries.PT,
  },
  JCDECAUX_PT_HIVE: {
    localeCode: 'pt-pt',
    currencyCode: 'EUR',
    timezone: ptTimezone,
    latitude: 38.736946,
    longitude: -9.142685,
    openStreetMapCountryName: OpenStreetMapCountries.PT,
  },
  JCDECAUX_PT_HIVE_3: {
    localeCode: 'pt-pt',
    currencyCode: 'EUR',
    timezone: ptTimezone,
    latitude: 38.736946,
    longitude: -9.142685,
    openStreetMapCountryName: OpenStreetMapCountries.PT,
  },
  JCDECAUX_ZA: {
    localeCode: 'en-za',
    currencyCode: 'ZAR',
    timezone: 'Africa/Johannesburg',
    latitude: -28.284535,
    longitude: 24.402177,
    openStreetMapCountryName: OpenStreetMapCountries.ZA,
  },
  JCDECAUX_CN_SH_METRO: {
    localeCode: 'zh-cn',
    currencyCode: 'CNY',
    timezone: 'Asia/Shanghai',
    latitude: 31.2304,
    longitude: 121.4737,
    openStreetMapCountryName: OpenStreetMapCountries.CN,
  },
  JCDECAUX_NO: {
    localeCode: 'no-no',
    currencyCode: 'NOK',
    timezone: osloTimezone,
    latitude: 59.9139,
    longitude: 10.7522,
    openStreetMapCountryName: OpenStreetMapCountries.NO,
  },
  JCDECAUX_NO_HIVE: {
    localeCode: 'no-no',
    currencyCode: 'NOK',
    timezone: osloTimezone,
    latitude: 59.9139,
    longitude: 10.7522,
    openStreetMapCountryName: OpenStreetMapCountries.NO,
  },
  HELLOO_BR: {
    localeCode: 'pt-br',
    currencyCode: 'BRL',
    timezone: 'America/Sao_Paulo',
    latitude: -23.533773,
    longitude: -46.62529,
    openStreetMapCountryName: OpenStreetMapCountries.BR,
  },
  MOP_PT: {
    localeCode: 'pt-pt',
    currencyCode: 'EUR',
    timezone: ptTimezone,
    latitude: 38.736946,
    longitude: -9.142685,
  },
  BJ_METRO_CN: {
    localeCode: 'zh-cn',
    currencyCode: 'CNY',
    timezone: 'Asia/Shanghai',
    latitude: 39.9042,
    longitude: 116.4074,
    openStreetMapCountryName: OpenStreetMapCountries.CN,
  },
  'market:jcd:us:hive': {
    localeCode: 'en-us',
    currencyCode: 'USD',
    timezone: 'US/Eastern',
    latitude: 40.71278,
    longitude: -74.00594,
    openStreetMapCountryName: OpenStreetMapCountries.US,
  },
  JCDECAUX_CO: {
    localeCode: 'es-co',
    currencyCode: 'COP',
    timezone: 'America/Bogota',
    latitude: 4.5709,
    longitude: 74.2973,
    openStreetMapCountryName: OpenStreetMapCountries.CO,
  },
};

export default MARKET_WISE_LOCALE;
