import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPerformanceData } from 'modules/api/performance';

import { useCancelRequest } from 'customHooks/useCancelRequest';
import { usePageFilters } from 'customHooks/usePageFilters';
import { RootState } from 'store';
import { DealDashboardFilters } from 'store/dealDashboard/reducer';
import { notifyError } from 'store/notification/reducer';
import { getFilterQueryString } from 'utils/reportingQueryString';
import Accordion from './Accordion';
import { PerformanceSidebarResponse } from './PerformanceSidebar.types';
import { getPerformanceSidebarConfig } from './utils/getPerformanceSidebarConfig';

const PerformanceSidebar: React.FC = () => {
  const dispatch = useDispatch();

  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const timezone = useSelector((state: RootState) => state.header.timezone);

  const {
    filters: { deal, endDate, startDate, time },
  } = usePageFilters<DealDashboardFilters>('insights/dashboard');
  const cancelFunctions = useCancelRequest();

  const [performanceSidebarResponse, setPerformanceSidebarResponse] = useState<PerformanceSidebarResponse | {}>({});

  const config = getPerformanceSidebarConfig({
    performanceData: performanceSidebarResponse as PerformanceSidebarResponse,
    currencyCode,
    localeCode,
  });

  useEffect(() => {
    if (deal) {
      const fetchPerformanceSidebarData = async (): Promise<void> => {
        try {
          const result = await fetchPerformanceData(
            cancelFunctions,
            getFilterQueryString({ deal, endDate, startDate, time }, timezone),
          );
          setPerformanceSidebarResponse(result);
        } catch (error) {
          dispatch(notifyError({ message: error.message }));
          setPerformanceSidebarResponse({});
        }
      };

      fetchPerformanceSidebarData();
    } else {
      setPerformanceSidebarResponse({});
    }
  }, [deal, endDate, startDate, time, timezone]);

  return (
    <div className="-mt-px">
      {config.map((header) => {
        if (!header.isVisible) {
          return null;
        }

        return (
          <Accordion
            dataTestId={header.dataTestId}
            key={header.title}
            title={header.title}
            expanded={header.isExpanded}
          >
            {header.data.map((item) => (
              <div key={item.label} className="grid grid-cols-2 mt-2 mb-2">
                <div className="body-base text-neutral-600">{item.label}</div>
                <div className="body-base text-neutral-900">{item.value}</div>
              </div>
            ))}
          </Accordion>
        );
      })}
    </div>
  );
};

export default PerformanceSidebar;
