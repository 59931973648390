import { Store, PreloadedState, AnyAction, Dispatch } from 'redux';
import { DefaultRootState } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import creative from './creative/reducer';
import dealDashboard from './dealDashboard/reducer';
import dealManagement from './dealManagement/reducer';
import environment from './environment/reducer';
import header from './header/reducer';
import notification from './notification/reducer';
import pages from './pages/reducer';
import publisher from './publisher/reducer';
import pagesFilters from './pagesFilters/reducer';
import carto from './carto/reducer';
import reseller from './reseller/reducer';
import userOptions from './userOptions/reducer';
import dealConfig from './dealConfig/reducer';
import { deepOverrideObject } from '../utils/deepOverrideObject';

const reducer = {
  creative,
  dealConfig,
  dealDashboard,
  dealManagement,
  environment,
  header,
  notification,
  pages,
  publisher,
  pagesFilters,
  carto,
  reseller,
  userOptions,
};

const asyncFunctionMiddleware = (storeAPI: Store) => (next: Dispatch) => (action: Function | AnyAction) => {
  return typeof action === 'function' ? action(storeAPI.dispatch, storeAPI.getState) : next(action);
};

type PersistedStateWrappedProps = PreloadedState<DefaultRootState> & {
  persistStore?: boolean;
};

type PreloadedStateWithPersistStore = {
  preloadedState: PersistedStateWrappedProps;
};

export const preloadedStateWithPersistedStore = (
  preloadedState: PreloadedState<DefaultRootState>,
): PreloadedStateWithPersistStore => ({
  preloadedState: {
    persistStore: true,
    ...preloadedState,
  },
});

export const setupStore = (
  preloadedState: PreloadedState<DefaultRootState> | PersistedStateWrappedProps = {},
): Store => {
  const mergedState: PreloadedState<DefaultRootState> | {} = (preloadedState as PersistedStateWrappedProps)
    ?.persistStore
    ? Object.keys(reducer).reduce((result, key) => {
        if (preloadedState[key as keyof PreloadedState<DefaultRootState>] !== undefined) {
          return {
            ...result,
            [key]: deepOverrideObject(
              reducer[key as keyof typeof reducer](undefined, { type: 'INIT' }),
              preloadedState[key as keyof PreloadedState<DefaultRootState>],
            ),
          };
        }
        return {
          ...result,
          [key]: reducer[key as keyof typeof reducer](undefined, { type: 'INIT' }),
        };
      }, {})
    : (preloadedState ?? {});

  return configureStore({
    reducer,
    middleware: [asyncFunctionMiddleware],
    preloadedState: mergedState,
    devTools: process.env.NODE_ENV !== 'production',
  });
};
const store = setupStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
