import PropTypes from 'prop-types';
import ReactTabs from 'react-toolbox/lib/tabs/Tabs';
import ReactTab from 'react-toolbox/lib/tabs/Tab';
import style from './Tabs.pcss';

const Tabs = ({
  idKey,
  labelKey,
  disabledKey,
  hiddenKey,
  contentKey,
  onActiveTabKey,
  dataList,
  currentTabIndex,
  onTabChange,
}) => {
  const getPropertyData = (item, key, defaultValue = null) => (key !== '' && item[key] ? item[key] : defaultValue);

  const getTabList = () =>
    dataList.map((item) => (
      <ReactTab
        label={getPropertyData(item, labelKey)}
        onActive={getPropertyData(item, onActiveTabKey)}
        key={item[idKey]}
        hidden={getPropertyData(item, hiddenKey, false)}
        disabled={getPropertyData(item, disabledKey, false)}
        theme={style}
      >
        {getPropertyData(item, contentKey, <></>)}
      </ReactTab>
    ));

  return (
    <ReactTabs index={currentTabIndex} onChange={onTabChange} theme={style} className="sub-header-base">
      {getTabList()}
    </ReactTabs>
  );
};

Tabs.propTypes = {
  idKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  labelKey: PropTypes.string,
  disabledKey: PropTypes.string,
  hiddenKey: PropTypes.string,
  contentKey: PropTypes.string,
  onActiveTabKey: PropTypes.string,
  dataList: PropTypes.arrayOf(PropTypes.object),
  currentTabIndex: PropTypes.number,
  onTabChange: PropTypes.func,
};

Tabs.defaultProps = {
  labelKey: '',
  dataList: [],
  disabledKey: '',
  hiddenKey: '',
  contentKey: '',
  onActiveTabKey: '',
  currentTabIndex: 0,
  onTabChange: () => undefined,
};

export default Tabs;
