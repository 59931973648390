import { DOUBLE_DASH } from 'consts/placeholders';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';
import StatusRenderer from 'components/common/StatusRenderer';
import AssetType from 'components/common/AssetType';
import { ASSET_STATUS_BADGE } from 'consts/assetStatus';
import { TooltipDirection } from 'components/patterns/Tooltip';
import { Asset } from 'components/pages/InventoryManagement/AssetList/AssetSidebar/AssetSidebar.types';
import { BadgeSize } from 'components/patterns/Badge';

const AssetForm: React.FC<Asset> = ({ asset }: Asset) => {
  const assetStatus = asset?.statuses?.find(({ startDate, endDate }) => {
    const currentDate = new Date();
    return currentDate >= new Date(startDate) && currentDate <= new Date(endDate);
  });

  return (
    <div>
      <h2 className="sub-header-base mb-8">Asset form</h2>
      <div className="pl-2">
        <Timeline>
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 body-sm">
                <p>Status</p>
                <p>Furniture code</p>
                <p>Furniture index</p>
              </div>
            }
          >
            <div className="grid grid-cols-3 gap-x-4">
              {assetStatus?.status ? (
                <div data-test-id="asset-status">
                  <StatusRenderer
                    value={assetStatus?.status}
                    statuses={ASSET_STATUS_BADGE}
                    direction={TooltipDirection.TOP}
                    badgeSize={BadgeSize.NORMAL}
                  />
                </div>
              ) : (
                <p className="">{DOUBLE_DASH}</p>
              )}
              <p className="body-base" data-test-id="furniture-code">
                {asset?.furnitureCode ?? DOUBLE_DASH}
              </p>
              <p className="body-base" data-test-id="furniture-index">
                {asset?.furnitureIndex ?? DOUBLE_DASH}
              </p>
            </div>
          </TimelineItem>
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 body-sm">
                <p>Frame alt code</p>
                <p className="col-span-2">Coordinates (lat, long)</p>
              </div>
            }
          >
            <div className="grid grid-cols-3 gap-x-4 body-base">
              <p data-test-id="frame-alt-code">{asset?.frameAltCode ?? DOUBLE_DASH}</p>
              <p className="col-span-2" data-test-id="asset-latitude-longitude">{`${asset?.latitude ?? DOUBLE_DASH}, ${
                asset?.longitude ?? DOUBLE_DASH
              }`}</p>
            </div>
          </TimelineItem>
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 body-sm">
                <p>Asset type</p>
                <p>Site code</p>
                <p>Visual unit code</p>
              </div>
            }
          >
            <div className="grid grid-cols-3 gap-x-4 body-base">
              <div data-test-id="asset-type">
                <AssetType value={asset?.assetType ?? DOUBLE_DASH} />
              </div>
              <p data-test-id="asset-site-code">{asset?.siteCode ?? DOUBLE_DASH}</p>
              <p data-test-id="asset-visual-unit-code">{asset?.visualUnitCode ?? DOUBLE_DASH}</p>
            </div>
          </TimelineItem>
        </Timeline>
      </div>
    </div>
  );
};

export default AssetForm;
