import intersection from 'lodash/intersection';

export const getCommonMarkets = (selectedCreatives) => {
  const marketsFromFirstCreative = selectedCreatives[0].market.map((m) => m.environment);

  if (marketsFromFirstCreative.length === 0 || marketsFromFirstCreative[0] === '') return [];

  return selectedCreatives.reduce(
    (commonMarkets, currentCreative) =>
      intersection(
        currentCreative.market.map((m) => m.environment),
        commonMarkets,
      ),
    marketsFromFirstCreative,
  );
};
