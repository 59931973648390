import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';

import { removeSelectedCreative } from 'store/pages/contentManagement/creatives/reducer';
import CreativesGallery from './CreativesGallery';
import { CreativesGalleryDispatchToProps } from './CreativesGallery.types';

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): CreativesGalleryDispatchToProps =>
  bindActionCreators(
    {
      removeSelectedCreative,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(CreativesGallery);
