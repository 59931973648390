import { DealFilter } from 'components/common/types/PageFilters';
import { DealIdFilters } from 'components/common/FilterArea/Filters/FilterDealId/FilterDealId.types';
import { UsePageFilters } from 'store/pagesFilters/types';
import { DealDashboardFilters } from './DealDashboard/DealDashboardContent/Filters/Filters.types';

const onDealIdChange = (
  _name: string,
  value: DealFilter,
  changeFilters: UsePageFilters<DealDashboardFilters>['changeFilters'],
): void => {
  const newFilters: Partial<DealIdFilters> = {};

  newFilters.deal = value;

  if (value) {
    newFilters.startDate = undefined;
    newFilters.endDate = undefined;
  }

  if (!value) {
    newFilters.startDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0));
    newFilters.endDate = new Date(new Date().setHours(23, 59, 59, 999));
  }

  changeFilters(newFilters);
};

export default onDealIdChange;
