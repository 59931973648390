import { AuthType, init } from '@thoughtspot/visual-embed-sdk';

import Auth from 'modules/Auth';
import { getAuthenticationToken } from 'modules/api/thoughtspots';
import { thoughtspot } from 'config';
import { CancelFunctions } from 'components/common/types';
import { getLocaleObject } from 'modules/I18N';

export const initialiseThoughtspot = (
  cancelFunctions: CancelFunctions,
  localeCode: string = getLocaleObject().localeCode,
): void => {
  const { email } = Auth.getData();

  init({
    thoughtSpotHost: thoughtspot.host,
    authType: AuthType.TrustedAuthToken,
    username: email,
    getAuthToken: () => getAuthenticationToken(cancelFunctions, localeCode),
    disableLoginRedirect: true,
    autoLogin: true,
    callPrefetch: true,
  });
};
