import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import desktopSvg from 'assets/icons/desktop.svg';
import eyeSvg from 'assets/icons/eye.svg';
import briefcaseSvg from 'assets/icons/briefcase.svg';
import styles from './FrameInfo.pcss';

const FrameInfo = ({ config, isReseller }) => (
  <div className="transform -translate-x-1/2 -translate-y-11 bg-black rounded-md -ml-4">
    <div className={styles.tooltipText}>
      <div className="text-neutral-50 p-2.5">
        {isReseller && (
          <div className="flex items-center mb-1">
            <SVG src={briefcaseSvg} className="fill-current" />
            <div className="ml-4 whitespace-nowrap body-sm">{config.mediaOwner}</div>
          </div>
        )}
        <div className="flex items-center mt-1">
          <SVG src={desktopSvg} className="fill-current" />
          <div className="ml-4 whitespace-nowrap body-sm">{config.frameId}</div>
        </div>
        {config.impressions ? (
          <div className="flex items-center mt-1">
            <SVG src={eyeSvg} className="fill-current" />
            <div className="ml-4 whitespace-nowrap body-sm">{config.impressions}</div>
          </div>
        ) : null}
      </div>
    </div>
  </div>
);

FrameInfo.propTypes = {
  config: PropTypes.shape({
    frameId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    impressions: PropTypes.number,
    mediaOwner: PropTypes.string,
  }),
  isReseller: PropTypes.bool,
};

FrameInfo.defaultProps = {
  config: { frameId: 1 },
  isReseller: false,
};

export default FrameInfo;
