import PropTypes from 'prop-types';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';

const Footer = ({ onSubmitClick, onCancelClick, isSubmitDisabled }) => {
  return (
    <>
      <Button
        onClick={onSubmitClick}
        btnShape={ButtonShape.NORMAL}
        btnType={ButtonType.PRIMARY}
        isDisabled={isSubmitDisabled}
      >
        Submit
      </Button>
      <span className="float-right">
        <Button onClick={onCancelClick} btnShape={ButtonShape.NORMAL} btnType={ButtonType.DANGER}>
          Cancel
        </Button>
      </span>
    </>
  );
};

Footer.propTypes = {
  onSubmitClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  isSubmitDisabled: PropTypes.bool,
};

Footer.defaultProps = {
  onSubmitClick: () => undefined,
  onCancelClick: () => undefined,
  isSubmitDisabled: false,
};

export default Footer;
