import SVG from 'react-inlinesvg';

import filterSvg from 'assets/icons/filter.svg';
import Card from 'components/patterns/Card';
import MultipleSwitchBox, { MultipleSwitchBoxTheme } from 'components/patterns/MultipleSwitchBox/MultipleSwitchBox';
import Input from 'lib/Input';
import AsyncSelect from 'components/patterns/AsyncSelect';
import withCancelRequest from 'components/hocs/withCancelRequest';
import { getNetworkPriceDefinition } from 'modules/api/referenceData';
import { IN_USE_STATUS } from 'consts/inUseStatus';
import { CancelFunctions } from 'components/common/types';

type CodeName = {
  code: string;
  name: string;
};

export type Filters = {
  networkPriceDefinitionCode?: string;
  networkPriceDefinition?: CodeName | null;
  inUse?: boolean | '';
};

interface FilterAreaProps {
  onFilterChange: (filters: Filters) => void;
  filters: Filters;
  cancelFunctions: CancelFunctions;
}

const FiltersArea: React.FC<FilterAreaProps> = ({ onFilterChange, filters, cancelFunctions }) => {
  const loadNetworkPriceDefinitions = async (_name: string, query: string): Promise<CodeName[]> => {
    try {
      return query.length > 1 ? await getNetworkPriceDefinition(cancelFunctions, query) : [];
    } catch (error) {
      return [];
    }
  };

  return (
    <Card>
      <div className="flex">
        <div className="self-center mr-8 ml-2.5">
          <SVG src={filterSvg} className="fill-current text-neutral-400" />
        </div>

        <div className="grid grid-cols-3 gap-3 flex-1">
          <Input
            dataTestId="network-price-definition-code"
            name="networkPriceDefinitionCode"
            value={filters.networkPriceDefinitionCode}
            placeholder="Type price definition code"
            onChange={(userInput: string) => onFilterChange({ networkPriceDefinitionCode: userInput })}
            debounceTime={300}
          />
          <div>
            <AsyncSelect
              dataTestId="network-price-definition-name"
              name="networkPriceDefinition"
              textKey="name"
              loadData={loadNetworkPriceDefinitions}
              onSelect={(_name: string, value: CodeName) => onFilterChange({ networkPriceDefinition: value })}
              placeholder="Select price definition name"
              selectedValue={filters.networkPriceDefinition}
            />
          </div>
          <div className="flex justify-end">
            <MultipleSwitchBox
              dataTestId="network-price-definition-in-use-select"
              source={[
                { name: 'All', code: '' },
                { name: 'In use', code: IN_USE_STATUS.TRUE },
              ]}
              selectedValue={filters.inUse}
              onChange={(value) => onFilterChange({ inUse: value })}
              theme={MultipleSwitchBoxTheme.LIGHT}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
export default withCancelRequest(FiltersArea);
