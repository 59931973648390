import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';

import chevronDown from 'assets/icons/chevron_down.svg';
import { getInputBorderStyle, getInputStyle } from 'lib/BaseInput';
import { Label } from 'lib/Label';
import { InputSize, InputTheme } from 'components/common/types/Input.types';
import { DropdownProps } from './Dropdown.types';

export const TextColorStyles = {
  light: {
    default: 'text-neutral-950-opacity-60',
    hover: 'group-hover:text-neutral-950-opacity-80',
    focus: 'focus:text-neutral-950-opacity-80 group-focus:text-neutral-950-opacity-80',
    active: 'group-active:text-neutral-950-opacity-80',
    disabled: 'text-neutral-950-opacity-10',
  },
  dark: {
    default: 'text-neutral-100-opacity-50',
    hover: 'group-hover:text-neutral-100',
    focus: 'focus:text-neutral-100 group-focus:text-neutral-100',
    active: 'group-active:text-neutral-100',
    disabled: 'text-neutral-100-opacity-40',
  },
};

const Dropdown = ({
  textKey = 'label',
  valueKey = 'value',
  icon = chevronDown,
  dataList,
  defaultOption = '',
  id,
  dataTestId,
  name,
  label = '',
  value = '',
  isDisabled = false,
  isRequired = false,
  theme = InputTheme.LIGHT,
  size = InputSize.MEDIUM,
  onChange,
  onChangeAsync,
}: DropdownProps): React.ReactElement => {
  const [selectedValue, setSelectedValue] = useState(value);

  const optionList = dataList.map((item: { [valueKey: string]: string }) => (
    <option
      className={getInputStyle({
        size,
        theme,
      })}
      disabled={!!item.isDisabled}
      value={item[valueKey]}
      key={item[valueKey].toString()}
    >
      {item[textKey]}
    </option>
  ));

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const onChangeHandle = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const newValue = event.target.value;
    const selected = dataList.find(
      (item: { [valueKey: string]: string }) => item[valueKey].toString() === newValue.toString(),
    ) || { [valueKey]: '', [textKey]: '' };
    setSelectedValue(newValue);
    onChangeAsync?.({ [name]: newValue }, name, selected);
    onChange?.(newValue, name, selected);
  };

  return (
    <>
      {label && <Label id={id} label={label} isRequired={isRequired} />}
      <div
        className={getInputBorderStyle({
          isDisabled,
        })}
      >
        <select
          className={getInputStyle({
            size,
            theme,
            classNames: 'px-4 mr-3',
          })}
          id={id}
          data-test-id={dataTestId ?? id}
          name={name}
          aria-label={name}
          value={selectedValue}
          onChange={onChangeHandle}
          disabled={isDisabled}
        >
          {defaultOption ? <option value="">{defaultOption}</option> : null}
          {optionList}
        </select>
        <SVG
          src={icon ?? chevronDown}
          tabIndex={0}
          className={`inline-block leading-none absolute right-3.5 h-4 w-4 pointer-events-none
            ${
              isDisabled
                ? TextColorStyles[theme].disabled
                : `${TextColorStyles[theme].default} ${TextColorStyles[theme].hover} ${TextColorStyles[theme].focus} ${TextColorStyles[theme].active}`
            }
          `}
        />
      </div>
    </>
  );
};

export default Dropdown;
