import queryString from 'query-string';

import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS } from 'modules/api/constants';
import Session from 'modules/Session';

export const fetchCreativeSetTable = async (cancelFunctions, queryObj) => {
  const query = queryString.stringify(
    { environment: Session.getEnvironmentId(), ...queryObj },
    { skipEmptyString: true, arrayFormat: 'comma' },
  );

  const { send, cancel } = createRequest({
    url: URLS().FETCH_CREATIVE_SETS_TABLE(query),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  if (cancelFunctions.FETCH_CREATIVE_SETS_TABLE) cancelFunctions.FETCH_CREATIVE_SETS_TABLE();
  cancelFunctions.FETCH_CREATIVE_SETS_TABLE = cancel;

  const result = await send();

  return result.data;
};

export const fetchVisualUnitGroups = async (cancelFunctions, queryObj) => {
  const query = queryString.stringify(
    { environment: Session.getEnvironmentId(), ...queryObj },
    { skipEmptyString: true, arrayFormat: 'comma' },
  );

  const { send, cancel } = createRequest({
    url: URLS().FETCH_VISUAL_UNIT_GROUPS(query),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  if (cancelFunctions.FETCH_VISUAL_UNIT_GROUPS) cancelFunctions.FETCH_VISUAL_UNIT_GROUPS();
  cancelFunctions.FETCH_VISUAL_UNIT_GROUPS = cancel;

  const result = await send();

  return result.data;
};

export const createCreativeSet = async (cancelFunctions, data) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATIVE_SET(),
    method: HTTP_METHODS.POST,
    data: { environment: Session.getEnvironmentId(), ...data },
    showLoader: true,
  });

  if (cancelFunctions.CREATE_CREATIVE_SET) cancelFunctions.CREATE_CREATIVE_SET();
  cancelFunctions.CREATE_CREATIVE_SET = cancel;

  const result = await send();

  return result.data;
};

export const updateCreativeSet = async (cancelFunctions, data, id) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATIVE_SET(id),
    method: HTTP_METHODS.PATCH,
    data,
    showLoader: true,
  });

  if (cancelFunctions.UPDATE_CREATIVE_SET) cancelFunctions.UPDATE_CREATIVE_SET();
  cancelFunctions.UPDATE_CREATIVE_SET = cancel;

  const result = await send();

  return result.data;
};

export const fetchCreativeSet = async (cancelFunctions, id) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATIVE_SET(id),
    method: HTTP_METHODS.GET,
    showLoader: true,
  });

  if (cancelFunctions.FETCH_CREATIVE_SET) cancelFunctions.FETCH_CREATIVE_SET();
  cancelFunctions.FETCH_CREATIVE_SET = cancel;

  const result = await send();

  return result.data;
};

export const deleteCreativeSet = async (cancelFunctions, id) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATIVE_SET(id),
    method: HTTP_METHODS.DELETE,
    showLoader: true,
  });

  if (cancelFunctions.DELETE_CREATIVE_SET) cancelFunctions.DELETE_CREATIVE_SET();
  cancelFunctions.DELETE_CREATIVE_SET = cancel;

  await send();
};
