import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { CreativeItemModel } from 'components/common/prop-types/content';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import editSvg from 'assets/icons/edit.svg';

const ActionRenderer = (props) => {
  const { navigate, data } = props;

  if (!data.id) return '';

  const onClick = () => {
    const path = `/content-management/previewCreative/${data.id}`;
    navigate(path);
  };
  return (
    <span className="table-action-button">
      <Button
        btnShape={ButtonShape.CIRCLE}
        btnType={ButtonType.ICON}
        onClick={onClick}
        label="Edit Creative"
        dataTestId={`edit-creative-button-${data.id}`}
      >
        <SVG src={editSvg} />
      </Button>
    </span>
  );
};

ActionRenderer.propTypes = {
  navigate: PropTypes.func.isRequired,
  data: CreativeItemModel,
};

ActionRenderer.defaultProps = {
  data: {},
};

export default ActionRenderer;
