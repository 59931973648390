import PropTypes from 'prop-types';

export const BadgeSize = {
  SMALL: 'py-1 px-2 w-9 body-sm',
  NORMAL: 'py-0.5 px-3 body-base',
  SMALL_FIT: 'py-1 px-2 body-sm',
};

export const TextColor = {
  LIGHT: 'text-neutral-50',
  DARK: 'text-primary-600',
};

export const BadgeColor = {
  PURPLE: 'bg-primary',
  YELLOW: 'bg-yellow',
  GREEN: 'bg-green',
  RED: 'bg-pinkRed',
  BLUE: 'bg-sky',
  GRAY: 'bg-neutral',
  SURFACE_LIGHT_100: 'bg-neutral-100',
};

const Badge = ({ color, textColor, size, children, dataTestId }) => {
  return (
    <span
      className={`inline-block text-center rounded-full align-middle ${size} ${color} ${textColor}`}
      data-test-id={dataTestId}
    >
      {children}
    </span>
  );
};

Badge.propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
  dataTestId: PropTypes.string,
};

Badge.defaultProps = {
  color: BadgeColor.PURPLE,
  textColor: TextColor.LIGHT,
  size: BadgeSize.SMALL,
  children: null,
  dataTestId: '',
};

export default Badge;
