import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { formatValue } from 'modules/I18N';
import StatBlock from 'components/patterns/StatBlock';
import styles from './SummaryBar.pcss';

const cx = classNames.bind(styles);

const SummaryBar = ({ stats, showPreviousStat, compact, noHeaders }) => {
  const { currencyCode, localeCode } = useSelector((state) => state.publisher.configuration);
  return (
    <ul className={cx('summary-bar')}>
      {stats.map(({ id, header, currentValue, previousValue, valueType }) => (
        <StatBlock
          key={id}
          id={id}
          title={header}
          currentStat={formatValue(currentValue, valueType, currencyCode, localeCode)}
          previousStat={formatValue(previousValue, valueType, currencyCode, localeCode)}
          percentageDifference={(currentValue - previousValue) / previousValue}
          showPreviousStat={showPreviousStat}
          compact={compact}
          noHeaders={noHeaders}
        />
      ))}
    </ul>
  );
};

SummaryBar.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      header: PropTypes.string,
      currentValue: PropTypes.number,
      previousValue: PropTypes.number,
      valueType: PropTypes.string,
    }),
  ).isRequired,
  showPreviousStat: PropTypes.bool,
  compact: PropTypes.bool,
  noHeaders: PropTypes.bool,
};

SummaryBar.defaultProps = {
  showPreviousStat: true,
  compact: false,
  noHeaders: false,
};

export default SummaryBar;
