import { PropsWithChildren } from 'react';
import ReactDraggable from 'react-draggable';
import { DEFAULT_DRAGGABLE_BOUNDS, DEFAULT_DRAGGABLE_POSITION } from 'consts/draggable';
import { DraggableProps } from './Draggable.types';

const Draggable: React.FC<PropsWithChildren<DraggableProps>> = ({
  children,
  onPositionChange,
  onDragStart,
  onDragStop,
  grid,
  scale,
  className = '',
  axis = 'both',
  draggedElementPosition = DEFAULT_DRAGGABLE_POSITION,
  containerBounds = DEFAULT_DRAGGABLE_BOUNDS,
}) => {
  return (
    <ReactDraggable
      defaultClassName={className}
      axis={axis}
      grid={grid}
      scale={scale}
      position={draggedElementPosition}
      bounds={containerBounds}
      onDrag={onPositionChange}
      onStart={onDragStart}
      onStop={onDragStop}
    >
      {children}
    </ReactDraggable>
  );
};

export default Draggable;
