import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './ButtonLink.pcss';

const cx = classNames.bind(styles);

export const ButtonLinkColor = {
  LIGHT_BLUE: 'light-blue',
  RED: 'red',
  BLUE: 'blue',
  PURPLE: 'purple',
};

function ButtonLink({ children, btnColor, onClick, dataTestId }) {
  const btnStyles = cx('sub-header-base', btnColor);

  return (
    <button data-test-id={dataTestId} type="button" className={btnStyles} onClick={onClick}>
      {children}
    </button>
  );
}

ButtonLink.propTypes = {
  dataTestId: PropTypes.string,
  children: PropTypes.node,
  btnColor: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonLink.defaultProps = {
  dataTestId: '',
  children: null,
  btnColor: '',
  onClick: () => undefined,
};

export default ButtonLink;
