import cx from 'classnames';
import SVG from 'react-inlinesvg';

import { ButtonSize, IconButtonProps, IconSize, Theme } from './IconButton.types';

const IconButtonTheme = {
  PLAIN: {
    buttonStyle:
      'hover:bg-neutral-950-opacity-5 active:bg-neutral-950-opacity-5 disabled:bg-neutral-950-opacity-5 rounded-md',
    iconColor: 'text-neutral-950-opacity-70',
    disabled: 'text-neutral-950-opacity-20',
  },
};

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  name,
  isDisabled = false,
  handleOnClick,
  theme = Theme.PLAIN,
  buttonSize = ButtonSize.SMALL,
  iconSize = IconSize.SMALL,
  dataTestId,
}) => {
  return (
    <button
      type="button"
      aria-label={name}
      className={cx('p-1 flex justify-center items-center', buttonSize, IconButtonTheme[theme].buttonStyle)}
      onClick={handleOnClick}
      disabled={isDisabled}
      data-test-id={dataTestId}
    >
      <SVG
        src={icon}
        className={cx(iconSize, {
          [IconButtonTheme[theme].disabled]: isDisabled,
          [IconButtonTheme[theme].iconColor]: !isDisabled,
        })}
      />
    </button>
  );
};

export default IconButton;
