import * as lookups from 'modules/api/lookups';
import { useCancelRequest } from 'customHooks/useCancelRequest';

export const useLoadLookup = (): { onLoadLookup: typeof onLoadLookup; onLoadBrands: typeof onLoadBrands } => {
  const cancelFunctions = useCancelRequest();

  const onLoadLookup = async (lookupName: string, query: string): Promise<[]> => {
    if (query.length <= 1) return [];
    try {
      return await lookups.getLookupData({ cancelFunctions, lookupURLName: lookupName, query });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Failed to fetch ${lookupName}:`, error);
      return [];
    }
  };

  const onLoadBrands = async (_name: string, query: string): Promise<[]> => {
    if (query.length <= 1) return [];
    try {
      return await lookups.getBrandLookupData(cancelFunctions, query);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch brands:', error);
      return [];
    }
  };

  return { onLoadLookup, onLoadBrands };
};
