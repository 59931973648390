import PropTypes from 'prop-types';

const BlankCell = ({ span }) => {
  if (!span) return null;
  const columnSpan = {
    1: 'col-span-1',
    2: 'col-span-2',
    3: 'col-span-3',
    4: 'col-span-4',
    5: 'col-span-5',
    6: 'col-span-6',
  };
  return <div className={`${columnSpan[span]} bg-neutral-300`} />;
};

BlankCell.propTypes = {
  span: PropTypes.number.isRequired,
};

export default BlankCell;
