import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { formatFilterOptionsName } from 'utils/formatters';
import {
  getChannels,
  getBusinessAreas,
  getVisualUnitCodes,
  getTags,
  getNetworks,
  getAssetTypes,
} from 'modules/api/inventory';
import { DATE_FORMAT } from 'modules/api/constants';

import withCancelRequest from 'components/hocs/withCancelRequest';
import AsyncSelect from 'components/patterns/AsyncSelect';
import Dropdown from 'lib/Dropdown';
import DayPicker from 'components/patterns/DayPicker';
import { usePageFilters } from 'customHooks/usePageFilters';

const AdvancedFilters = ({ cancelFunctions }) => {
  const {
    filters: { channel, businessArea, visualUnit, tag, network, assetType, startDate, endDate },
    changeFilters,
  } = usePageFilters();
  const [assetTypes, setAssetTypes] = useState([]);

  useEffect(() => {
    const fetchAssetTypes = async () => {
      try {
        setAssetTypes(await getAssetTypes(cancelFunctions));
      } catch (error) {
        setAssetTypes([]);
      }
    };

    fetchAssetTypes();
  }, []);

  const fetchFilterOptions = async (getFilterOptions = async () => null, query) => {
    if (query.length >= 3) {
      try {
        return await getFilterOptions(cancelFunctions, query);
      } catch (error) {
        return [];
      }
    }
    return [];
  };

  const fetchVisualUnits = async (_, query) => {
    try {
      return await getVisualUnitCodes(cancelFunctions, query);
    } catch {
      return [];
    }
  };

  return (
    <>
      <div className="grid grid-cols-4 gap-4 my-4" data-test-id="advanced-filters">
        <AsyncSelect
          dataTestId="channel-input"
          name="channel"
          loadData={async (_, query) => formatFilterOptionsName(await fetchFilterOptions(getChannels, query))}
          onSelect={(_, value) => changeFilters({ channel: value })}
          placeholder="Select channel"
          selectedValue={channel}
        />
        <AsyncSelect
          dataTestId="business-area-input"
          name="businessArea"
          loadData={async (_, query) => formatFilterOptionsName(await fetchFilterOptions(getBusinessAreas, query))}
          onSelect={(_, value) => {
            changeFilters({ businessArea: value });
          }}
          placeholder="Select business area"
          selectedValue={businessArea}
        />
        <AsyncSelect
          dataTestId="visual-unit-code-input"
          name="visualUnit"
          textKey="code"
          loadData={fetchVisualUnits}
          onSelect={(_, value) => {
            changeFilters({ visualUnit: value });
          }}
          placeholder="Select visual unit code"
          selectedValue={visualUnit}
        />
        <Dropdown
          name="assetType"
          dataList={assetTypes}
          textKey="code"
          valueKey="code"
          value={assetType?.code}
          defaultOption="Select asset type"
          onChange={(_, _dropdownName, value) => changeFilters({ assetType: value.code ? value : null })}
        />
      </div>
      <hr className="my-4 text-neutral-100-opacity-10" />
      <div className="grid grid-cols-4 gap-4 my-4">
        <AsyncSelect
          dataTestId="tag-input"
          name="tag"
          loadData={async (_, query) => formatFilterOptionsName(await fetchFilterOptions(getTags, query))}
          onSelect={(_, value) => {
            changeFilters({ tag: value });
          }}
          placeholder="Tag"
          selectedValue={tag}
        />
        <AsyncSelect
          dataTestId="network-input"
          name="network"
          loadData={async (_, query) => formatFilterOptionsName(await fetchFilterOptions(getNetworks, query))}
          onSelect={(_, value) => {
            changeFilters({ network: value });
          }}
          placeholder="Network"
          selectedValue={network}
        />
        <DayPicker
          onDayChange={(day) => changeFilters({ startDate: day })}
          placeholder="Start date"
          format={DATE_FORMAT}
          selectedDay={startDate}
          isDisabled={!tag && !network}
          disabledDays={[{ after: endDate }]}
          isClearable
        />
        <DayPicker
          onDayChange={(day) => changeFilters({ endDate: day })}
          placeholder="End date"
          format={DATE_FORMAT}
          selectedDay={endDate}
          isDisabled={!tag && !network}
          disabledDays={[{ before: startDate }]}
          isClearable
        />
      </div>
    </>
  );
};

AdvancedFilters.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
};

export default withCancelRequest(AdvancedFilters);
