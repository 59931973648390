import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  isEmpty?: boolean;
}

export const EmptyCreativesRedirect: React.FC<PropsWithChildren<Props>> = ({ isEmpty, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isEmpty) {
      navigate('/content-management/creatives');
    }
  }, [isEmpty]);

  return <>{children}</>;
};

EmptyCreativesRedirect.defaultProps = {
  isEmpty: false,
};
