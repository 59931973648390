export const getSotFloor = (targeted, allocated, preservedAvailability, config) => {
  const { totalSot, totalSpans } = preservedAvailability;
  const { sotSwing, floorCeilingPrecision } = config;

  const averageSot = totalSpans === 0 ? 0 : totalSot / totalSpans;
  const computation = parseFloat(((targeted / allocated) * averageSot - sotSwing).toFixed(floorCeilingPrecision));
  const calculateFloor = computation > 100 ? 100 : computation;

  return Math.max(1, Number.isNaN(calculateFloor) ? null : calculateFloor);
};

export const getSotCeiling = (sotFloor, config) => {
  const { sotSwing, floorCeilingPrecision } = config;

  const computation = parseFloat((sotFloor + 2 * sotSwing).toFixed(floorCeilingPrecision));
  return computation > 100 ? 100 : computation;
};
