import SVG from 'react-inlinesvg';
import cx from 'classnames';

import syncedSvg from 'assets/icons/synced.svg';
import { DspChipProps } from './DspChip.types';

const DspChip: React.FC<DspChipProps> = ({ theme = 'LIGHT', label, testId }) => {
  return (
    <div
      data-test-id={`${testId}-success-chip`}
      className={cx('flex items-center bg-neutral-100 rounded-full px-4 py-1 space-x-2 border-neutral-300', {
        'text-primary-900 border-2': theme === 'DARK',
        'text-neutral-600 border': theme === 'LIGHT',
      })}
    >
      <SVG src={syncedSvg} />
      <p className="relative top-[1px] select-none body-base">{label}</p>
    </div>
  );
};

export default DspChip;
