import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useCancelRequest } from 'customHooks/useCancelRequest';

import { Store } from 'components/common/types/Store.types';
import {
  addDealLineVenueTaxonomy,
  changeDealLineVenueTaxonomy,
  clearDealLineVenueTaxonomies,
  removeDealLineVenueTaxonomy,
} from 'store/dealManagement/reducer';
import withCancelRequest from 'components/hocs/withCancelRequest';
import Chips from 'components/patterns/Chips';
import MultiLevelDropdown, { getLeafNodes, getSelectedAncestors } from 'components/patterns/MultiLevelDropdown';
import { Option, OptionCode } from 'components/common/types/DropdownItem.types';
import { getVenueTaxonomyLookup } from 'modules/api/lookups';
import { deepFind } from 'utils/deepFind';
import { isEqual } from 'lodash';
import { VenueTaxonomyProps, VenueTaxonomyResponse } from './VenueTaxonomy.types';

const mapVenueTaxonomyToMultiLevelData = (data: VenueTaxonomyResponse[]): Option[] =>
  data.map((item) => {
    const children = item.children?.length
      ? item.children.map((child) => {
          const grandChildren = child.grandChildren?.length
            ? child.grandChildren.map((grandChild) => ({
                code: grandChild.enumerationId,
                name: grandChild.categoryCode,
                selectable: grandChild.selectable,
                parentCode: child.enumerationId,
              }))
            : undefined;

          return {
            children: grandChildren,
            code: child.enumerationId,
            name: child.categoryCode,
            selectable: child.selectable,
          };
        })
      : undefined;

    return {
      children,
      code: item.enumerationId,
      name: item.categoryCode,
      selectable: item.selectable,
    };
  });

const VenueTaxonomy: React.FC<VenueTaxonomyProps> = ({
  readOnly = false,
  isMultiLevelDropdownPositionFixed = false,
  isTitle = true,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();
  const venueTaxonomies = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.venueTaxonomies);

  const [venueTaxonomyOptions, setVenueTaxonomyOptions] = useState<Option[]>([]);

  useEffect(() => {
    const getVenueTaxonomyData = async (): Promise<void> => {
      try {
        const result: VenueTaxonomyResponse[] = await getVenueTaxonomyLookup(cancelFunctions);
        setVenueTaxonomyOptions(mapVenueTaxonomyToMultiLevelData(result));
      } catch {} // eslint-disable-line no-empty
    };
    getVenueTaxonomyData();
  }, []);

  const selectedChips = venueTaxonomyOptions.flatMap((option) => getSelectedAncestors(option, venueTaxonomies));

  const addVenueTaxonomy = (venueTaxonomy: Option): void => {
    dispatch(addDealLineVenueTaxonomy(venueTaxonomy.code as number));
  };

  const removeVenueTaxonomy = (venueTaxonomy: Option): void => {
    dispatch(removeDealLineVenueTaxonomy(venueTaxonomy.code as number));
  };

  useEffect(() => {
    if (!venueTaxonomyOptions.length || !venueTaxonomies.length) return;

    const selectedVenueTaxonomyOptions = venueTaxonomies.map((venueTaxonomyCode) => {
      return deepFind(venueTaxonomyOptions, venueTaxonomyCode);
    });

    const selectedVenueTaxonomyCodesAndParentCodes = selectedVenueTaxonomyOptions.reduce<OptionCode[]>(
      (allCodes, selectedVenueTaxonomy) => {
        if (selectedVenueTaxonomy && !selectedVenueTaxonomy.children) {
          allCodes.push(selectedVenueTaxonomy.code);
        }

        if (
          selectedVenueTaxonomy &&
          selectedVenueTaxonomy.parentCode &&
          !allCodes.includes(selectedVenueTaxonomy.parentCode)
        ) {
          allCodes.push(selectedVenueTaxonomy.parentCode);
        }

        return allCodes;
      },
      [],
    );

    if (!isEqual(selectedVenueTaxonomyCodesAndParentCodes, venueTaxonomies)) {
      dispatch(changeDealLineVenueTaxonomy(selectedVenueTaxonomyCodesAndParentCodes as number[]));
    }
  }, [venueTaxonomyOptions, venueTaxonomies]);

  return (
    <div
      data-test-id="venue-taxonomy"
      className={cx('px-12 py-6', {
        relative: isMultiLevelDropdownPositionFixed,
      })}
    >
      {isTitle && <p className="sub-header-base text-neutral-950-opacity-60 mb-2.5">Select venue taxonomy</p>}
      <div className="flex flex-row space-x-3.5" data-test-id="venue-taxonomy-chips-container">
        <MultiLevelDropdown
          options={venueTaxonomyOptions}
          value={venueTaxonomies}
          onChangeItem={(node, checked) => (checked ? addVenueTaxonomy(node) : removeVenueTaxonomy(node))}
          onRemoveAll={() => dispatch(clearDealLineVenueTaxonomies())}
          isDisabled={readOnly}
          isPositionFixed={isMultiLevelDropdownPositionFixed}
          dataTestId="venue-taxonomy-multi-level-dropdown"
        />
        {selectedChips.length ? (
          <Chips
            dataTestId="environment-chips"
            dataList={selectedChips}
            idKey="code"
            labelKey="name"
            onRemove={(item) => getLeafNodes(item).forEach((node) => removeVenueTaxonomy(node))}
            isClearAllVisible={false}
            isDisabled={readOnly}
          />
        ) : null}
      </div>
    </div>
  );
};

export default withCancelRequest(VenueTaxonomy);
