import React, { PropsWithChildren, useRef } from 'react';
import Card from 'lib/Card';
import { CardTheme } from 'lib/Card/Card.types';

interface Props {
  header: string;
  isVisible?: boolean;
  className?: string;
  theme?: CardTheme;
}

export const DealSummaryCard: React.FC<PropsWithChildren<Props>> = ({
  header,
  isVisible = true,
  className,
  theme = CardTheme.DEFAULT,
  children,
}) => {
  const cardRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const cardHeight = cardRef.current?.getBoundingClientRect().height;
  const cardAnimation = {
    transition: `transition-[height,margin,opacity] ease-in-out duration-150 ${
      isVisible ? `opacity-100 ${className}` : 'opacity-0 invisible -mt-3'
    }`,
    height: `${isVisible ? cardHeight : 0}px`,
  };

  return (
    <div
      data-test-id={`${header.toLowerCase()}-card-container`}
      className={cardAnimation.transition}
      style={{ height: cardAnimation.height }}
    >
      <Card cardRef={cardRef} header={header} theme={theme}>
        {children}
      </Card>
    </div>
  );
};
