import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS } from 'modules/api/constants';

export const fetchTradingBehaviourChart = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_PERFORMANCE_TRADING_BEHAVIOUR_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_PERFORMANCE_TRADING_BEHAVIOUR_CHART)
    cancelFunctions.FETCH_PERFORMANCE_TRADING_BEHAVIOUR_CHART();
  cancelFunctions.FETCH_PERFORMANCE_TRADING_BEHAVIOUR_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchImpressionsTradedChart = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_PERFORMANCE_IMPRESSIONS_TRADED_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_PERFORMANCE_IMPRESSIONS_TRADED_CHART)
    cancelFunctions.FETCH_PERFORMANCE_IMPRESSIONS_TRADED_CHART();
  cancelFunctions.FETCH_PERFORMANCE_IMPRESSIONS_TRADED_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchRevenueChart = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_PERFORMANCE_REVENUE_CHART(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_PERFORMANCE_REVENUE_CHART) cancelFunctions.FETCH_PERFORMANCE_REVENUE_CHART();
  cancelFunctions.FETCH_PERFORMANCE_REVENUE_CHART = cancel;

  const result = await send();

  return result.data;
};

export const fetchPerformanceData = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_PERFORMANCE_DATA(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_PERFORMANCE_DATA) cancelFunctions.FETCH_PERFORMANCE_DATA();
  cancelFunctions.FETCH_PERFORMANCE_DATA = cancel;

  const result = await send();

  return result.data;
};

export const fetchPerformanceTable = async (cancelFunctions, query) => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_PERFORMANCE_TABLE(query),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.FETCH_PERFORMANCE_TABLE) cancelFunctions.FETCH_PERFORMANCE_TABLE();
  cancelFunctions.FETCH_PERFORMANCE_TABLE = cancel;

  const result = await send();

  return result.data;
};
