import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addProductFormats, deleteProductFormats } from 'store/creative/reducer';
import { notifyError } from 'store/notification/reducer';
import ProductFormats from './ProductFormats';

const mapStateToProps = (state) => ({
  marketId: state.creative.marketId,
  productFormat: state.creative.productFormat,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      notifyError,
      addProductFormats,
      deleteProductFormats,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductFormats);
