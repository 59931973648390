import { MutableRefObject } from 'react';

export interface CardProps {
  id?: string;
  header?: string;
  footer?: React.ReactElement;
  children?: React.ReactNode;
  theme?: CardTheme;
  cardRef?: MutableRefObject<HTMLDivElement>;
}

export enum CardTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  DEFAULT_NOT_ROUNDED = 'default_not_rounded',
  TRANSPARENT = 'transparent',
}
