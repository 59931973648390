import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { isEqual } from 'lodash';
import { formatIndexOptimisationWithEmptyDisplayName } from 'utils/formatIndexOptimisation';

export const useCheckProgrammaticLineLevelChange = (): boolean => {
  const backupDeal = useSelector((state: Store) => state.dealManagement.backupFormData.commonDeal);
  const backupLines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const commonDeal = useSelector((state: Store) => state.dealManagement.commonDeal);
  const currentLine = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);

  const currentBackupLine = backupLines.find((line) => line.id === commonDeal.currentLine.id) ?? backupLines[0] ?? {};

  const { dealName: backupDealName } = backupDeal;
  const { dealName } = commonDeal;
  const {
    availability: backupAvailability,
    startDate: backupStartDate,
    endDate: backupEndDate,
    environments: backupEnvironments,
    impressions: backupImpressions,
    budget: backupBudget,
    frames: backupFrames,
    selectedDays: backupSelectedDays,
    sot: backupSot,
    sweep: backupSweep,
    venueTaxonomies: backupVenueTaxonomies,
    indexOptimisation: backupIndexOptimisation,
    proximity: backupProximity,
    segment: backupSegment,
  } = currentBackupLine;

  const {
    availability,
    startDate,
    endDate,
    environments,
    impressions,
    budget,
    frames,
    selectedDays,
    sot,
    sweep,
    venueTaxonomies,
    indexOptimisation,
    proximity: { openStreetMapPoi, plannerPoi },
    segment: { selectedSegments },
  } = currentLine;

  return useMemo(
    () =>
      !isNewDeal &&
      !isEqual(
        {
          assets: backupAvailability?.assets,
          startDate: backupStartDate,
          endDate: backupEndDate,
          dealName: backupDealName,
          environments: backupEnvironments,
          impressions: backupImpressions,
          budget: backupBudget,
          frames: backupFrames,
          selectedDays: backupSelectedDays,
          sot: backupSot,
          sweep: backupSweep,
          venueTaxonomies: backupVenueTaxonomies,
          indexOptimisation: formatIndexOptimisationWithEmptyDisplayName(backupIndexOptimisation),
          plannerPoi: backupProximity?.plannerPoi,
          openStreetMapPoi: backupProximity?.openStreetMapPoi,
          selectedSegments: backupSegment?.selectedSegments,
        },
        {
          assets: availability.assets,
          startDate,
          endDate,
          dealName,
          environments,
          impressions,
          budget,
          frames,
          selectedDays,
          sot,
          sweep,
          venueTaxonomies,
          indexOptimisation: formatIndexOptimisationWithEmptyDisplayName(indexOptimisation),
          plannerPoi,
          openStreetMapPoi,
          selectedSegments,
        },
      ),
    [backupLines, currentLine.id, commonDeal, isNewDeal],
  );
};
