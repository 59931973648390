import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Chips from 'components/patterns/Chips';
import FileUpload from 'components/patterns/FileUpload';
import { Label } from 'lib/Label';
import { NotificationMessages } from 'consts/notifications';
import { FILE_SIZE_10MB } from 'modules/api/constants';
import { ACCEPTED_FILE_TYPE_XLS } from 'consts/fileType';
import { notifyError } from 'store/notification/reducer';

const ListUpload = ({ readOnly, listFiles, onChangeListFiles }) => {
  const dispatch = useDispatch();

  const onFileChange = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      dispatch(notifyError({ message: NotificationMessages.INVALID_UPLOAD }));
      return;
    }

    const isDuplicateFile = acceptedFiles.find((file) => listFiles.find((item) => item.name === file.name));
    if (isDuplicateFile) {
      return;
    }

    const files = [...listFiles];
    acceptedFiles.forEach((file) => files.push({ include: true, file, name: file.name }));
    if (files.length > 2) {
      dispatch(notifyError({ message: 'Maximum 2 files can be uploaded.' }));
      return;
    }

    onChangeListFiles(files);
  };

  return (
    <div data-test-id="list-upload-container">
      <Label label="List upload" />
      <div className="mb-1.5">
        <FileUpload
          acceptFileType={ACCEPTED_FILE_TYPE_XLS}
          maxFileSize={FILE_SIZE_10MB}
          placeholder="Choose file to upload"
          onFileChange={onFileChange}
          isDisabled={readOnly}
        />
      </div>
      {listFiles.length ? (
        <Chips
          dataTestId="list-files-chips"
          dataList={listFiles}
          idKey="name"
          labelKey="name"
          onRemoveAll={() => onChangeListFiles([])}
          onRemove={(item) => onChangeListFiles(listFiles.filter((file) => file.name !== item.name))}
          excludeKey="include"
          onIncludeExcludeChange={(item, include) => {
            onChangeListFiles(listFiles.map((file) => (file.name === item.name ? { ...file, include } : file)));
          }}
          isDisabled={readOnly}
        />
      ) : null}
    </div>
  );
};

ListUpload.propTypes = {
  readOnly: PropTypes.bool,
  listFiles: PropTypes.array,
  onChangeListFiles: PropTypes.func,
};

ListUpload.defaultProps = {
  readOnly: false,
  listFiles: [],
  onChangeListFiles: () => undefined,
};

export default ListUpload;
