import cx from 'classnames';
import { StatusProps } from './Status.types';

const Status = ({ initial, backgroundColour }: StatusProps): JSX.Element => (
  <span
    className={cx(
      'inline-flex justify-center items-center text-center truncate rounded-full min-w-7.5 py-1 px-2.5 sub-header-sm text-neutral-50',
      backgroundColour,
    )}
  >
    {initial}
  </span>
);

export default Status;
