import { IHeaderParams } from 'ag-grid-community';

export enum Sort {
  ASC = 'asc',
  DESC = 'desc',
}

export enum HeaderBackgroundColor {
  PRIMARY = 'bg-primary-100',
  SECONDARY = 'bg-neutral-100',
  TRANSPARENT = 'bg-transparent',
  WHITE = 'bg-white',
}

export enum TextColor {
  PRIMARY = 'text-neutral-950',
  SECONDARY = 'text-essential-tertiary',
}

export interface HeaderProps extends IHeaderParams {
  backgroundColor?: HeaderBackgroundColor;
  label: string;
  sortable?: boolean;
  textColor?: TextColor;
}
