import { useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';

import DAY_PART from 'consts/dayPart';
import { DOUBLE_DASH } from 'consts/placeholders';
import { PriceModel } from 'components/common/prop-types/inventory';
import { formatDate, formatCurrency } from 'modules/I18N';
import { TimelineItem } from 'components/patterns/Timeline';

const PriceInfo = ({
  price: { price: CPM, days = [], startDate, endDate, startOffset, endOffset, priceType, priceCover },
}) => {
  const { currencyCode, localeCode } = useSelector((state) => state.publisher.configuration);
  const type =
    startOffset === DAY_PART.fullDay.startOffset && endOffset === DAY_PART.fullDay.endOffset
      ? DAY_PART.fullDay.displayName
      : DAY_PART.dayPart.displayName;
  const { startLabel } = DAY_PART.hours.find((hour) => startOffset === hour.startOffset) ?? {};
  const { endLabel } = DAY_PART.hours.find((hour) => endOffset === hour.endOffset) ?? {};

  return (
    <TimelineItem
      title={
        <div className="grid grid-cols-3 gap-x-4 body-sm">
          <p>CPM</p>
          <p>Days</p>
        </div>
      }
    >
      <div className="grid grid-cols-3 gap-x-4 gap-y-2">
        <p className="body-base" data-test-id="asset-price-info">
          {formatCurrency({ value: CPM, currencyCode, localeCode })}
          <br />
          <span className="body-sm text-neutral-950-opacity-60">{`${formatDate(startDate, localeCode)} - ${formatDate(
            endDate,
            localeCode,
          )}`}</span>
        </p>
        <p className="body-base col-span-2" data-test-id="asset-price-days">
          {days.map((day) => capitalize(day)).join(', ')}
        </p>
        <div>
          <p className="body-sm">Daypart</p>
          <p className="body-base mt-2 mb-0.5" data-test-id="asset-price-daypart">
            {type}
          </p>
          <p className="body-sm text-neutral-950-opacity-60">{`${startLabel ?? DOUBLE_DASH}-${
            endLabel ?? DOUBLE_DASH
          }`}</p>
        </div>
        <div>
          <p className="body-sm">Price type</p>
          <p className="mt-2 body-base" data-test-id="asset-price-type">
            {priceType}
          </p>
        </div>
        <div>
          <p className="body-sm">Price cover</p>
          <p className="mt-2 body-base" data-test-id="asset-price-cover">
            {priceCover}
          </p>
        </div>
      </div>
    </TimelineItem>
  );
};

PriceInfo.propTypes = {
  price: PriceModel,
};

PriceInfo.defaultProps = {
  price: {},
};

export default PriceInfo;
