/* eslint-disable sonarjs/cognitive-complexity */
import { SWEEP_OPTIONS_CODES } from 'consts/deal';
import { DealStatus } from 'components/common/types/Deal.types';
import { getFramesListData, getFramesFileData } from 'utils/framesUtils';

export const getCodes = (list) => list.map((item) => item.code);

export const getListData = (routeFrameCodes, listFiles, isAdServerMarket) => {
  const { incFrameList, excFrameList } = getFramesListData(routeFrameCodes, isAdServerMarket);
  const { incFileList, excFileList } = getFramesFileData(listFiles);

  const includeFrames = [...incFrameList, ...incFileList];
  const excludeFrames = [...excFrameList, ...excFileList];

  return {
    ...(includeFrames.length ? { includeFrames } : {}),
    ...(excludeFrames.length ? { excludeFrames } : {}),
  };
};

export const getTargetData = ({
  impressions,
  budget,
  sot,
  sotCeiling,
  sotFloor,
  sweep,
  frames,
  ceilingSot = null,
  floorSot = null,
  isAdServerMarket,
}) => {
  return {
    ...(impressions ? { impressions } : {}),
    ...(budget ? { budget } : {}),
    ...(frames ? { frames } : {}),
    ...(sot ? { sot } : {}),
    ...(ceilingSot ? { ceilingSot } : {}),
    ...(floorSot ? { floorSot } : {}),
    ...((impressions || budget) && sotCeiling ? { sotCeiling } : {}),
    ...((impressions || budget) && sotFloor ? { sotFloor } : {}),
    ...(sweep && !isAdServerMarket ? { distributionType: SWEEP_OPTIONS_CODES[sweep] } : {}),
    ...(sweep && isAdServerMarket ? { pgDistributionType: sweep } : {}),
  };
};

const getProximityPostCodeData = ({ unit, distance, include, tags, files = [] }) => {
  const [file] = files;

  if (!distance || (!tags.length && !file)) return {};

  return {
    postCode: {
      ...(tags.length ? { postCodes: tags.map((item) => item.code) } : {}),
      ...(file ? { fileName: file.name } : {}),
      ...(file?.fileId ? { fileId: file.fileId } : {}),
      distance,
      unit,
      include,
    },
  };
};

const getProximityPOIData = ({ tags, dataSourceCode, attributeCode, distance, unit, include }) => {
  if (!distance || !dataSourceCode || !attributeCode || !tags.length) return {};

  return {
    poi: {
      ...(tags.length ? { selectedCodes: tags.map(({ code }) => code) } : {}),
      dataSourceCode,
      attributeCode,
      distance,
      unit,
      include,
    },
  };
};

const getProximityPointsData = ({ unit, distance, include, latitude, longitude, files = [] }) => {
  const [file] = files;

  if (!distance || (!(latitude && longitude) && !file)) return {};

  return {
    points: {
      ...(latitude && longitude ? { selected: [{ latitude, longitude }] } : {}),
      ...(file ? { fileName: file.name } : {}),
      ...(file?.fileId ? { fileId: file.fileId } : {}),
      distance,
      unit,
      include,
    },
  };
};

export const getProximityData = (proximity) => {
  const proximityRequestData = {
    ...getProximityPostCodeData(proximity.postCode),
    ...getProximityPOIData(proximity.poi),
    ...getProximityPointsData(proximity.points),
  };

  return Object.keys(proximityRequestData).length ? { proximity: proximityRequestData } : {};
};

export const getFilesOfCurrentLine = (currentLine) => [
  ...currentLine.listFiles,
  ...currentLine.visualUnitFiles,
  ...currentLine.proximity.postCode.files,
  ...currentLine.proximity.points.files,
];

export const getFilesToUpload = (files = []) => {
  const filesToUpload = {};

  files.forEach(({ name, fileId, file }) => {
    if (fileId) return;
    filesToUpload[name] = file;
  });

  return filesToUpload;
};

export const transformBookingStatusCode = (bookingStatusCode) => {
  switch (bookingStatusCode) {
    case DealStatus.LIVE:
    case DealStatus.APPROVED:
      return 'CONFIRMED';
    case DealStatus.CANCELLED:
      return 'CANCEL';
    default:
      return bookingStatusCode;
  }
};
/* eslint-enable sonarjs/cognitive-complexity */
