import { useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import Card from 'components/patterns/Card';
import { CardTheme } from 'components/patterns/Card/Card.types';
import filterSvg from 'assets/icons/filter.svg';
import chevronDownSvg from 'assets/icons/chevron_down.svg';
import classNames from 'classnames/bind';
import Button, { Color } from 'lib/Button';

const cx = classNames.bind(null);

const FiltersCard = ({ children, advancedFilters, containerClassName, dataTestId }) => {
  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);

  return (
    <Card cardTheme={CardTheme.WHITE}>
      <div className="flex justify-center items-start" data-test-id={dataTestId}>
        <div className="mr-6 ml-3 my-1.5">
          <SVG src={filterSvg} className="text-neutral-400" />
        </div>
        <div className={`flex-1 ${containerClassName || ''}`}>
          {children}
          {isAdvancedFiltersOpen ? advancedFilters : null}
        </div>
        {advancedFilters ? (
          <Button
            dataTestId="advanced-filter-button"
            color={Color.TRANSPARENT}
            classNames="mx-1 my-0.5"
            svg={chevronDownSvg}
            svgClassnames={cx('transform text-neutral-950-opacity-70', { 'rotate-180': isAdvancedFiltersOpen })}
            onClick={() => setIsAdvancedFiltersOpen((prevState) => !prevState)}
          />
        ) : null}
      </div>
    </Card>
  );
};

FiltersCard.propTypes = {
  children: PropTypes.node,
  advancedFilters: PropTypes.node,
  containerClassName: PropTypes.string,
  dataTestId: PropTypes.string,
};

FiltersCard.defaultProps = {
  children: null,
  advancedFilters: null,
  containerClassName: null,
  dataTestId: '',
};

export default FiltersCard;
