import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

import Auth from 'modules/Auth';
import { MAX_SAFE_INTEGER_ALLOWED_IN_BACKEND } from 'modules/api/constants';
import { DealType, DealStatus } from 'components/common/types/Deal.types';
import { formatValue, FormatTypeEnum } from 'modules/I18N';
import { PermissionsEnum } from 'consts/permissions';
import warningSvg from 'assets/icons/warning.svg';
import { RootState } from 'store';

import InputNumber from 'lib/InputNumber';
import FieldWithInfoMessage from 'components/patterns/FieldWithInfoMessage';
import { Store } from 'components/common/types/Store.types';
import { changeDealCurrentLineData, changeFormParams } from 'store/dealManagement/reducer';
import { isFutureDate } from 'utils/dateUtil';
import RequiredAsterisk from 'components/common/RequiredAsterisk';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { getIsAdServer } from 'store/publisher/selectors';
import { getAutomationDealConfigValue } from 'store/dealConfig/utils';

type ImpressionsProps = {
  autoCalculateFloorAndCeiling: (arg1: number, arg2: number) => void;
  disabled: boolean;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const Impressions: React.FC<ImpressionsProps> = ({ autoCalculateFloorAndCeiling, disabled }: ImpressionsProps) => {
  const dispatch = useDispatch();
  const isActionsDisabled = useSelector((state: Store) => state.dealManagement.isActionsDisabled);
  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const dealConfig = useSelector((state: Store) => state.dealConfig);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const id = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.id);
  const impressions = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.impressions);
  const budget = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.budget);
  const preservedAvailability = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.preservedAvailability,
  );
  const deliveredImpressions = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.deliveredImpressions,
  );
  const allocatedImpressions = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.availability.allocatedImpressions,
  );
  const startDate = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.startDate);

  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);

  const isCurrentLineFutureStartDate = startDate && isFutureDate(startDate);
  const backupLine = lines.find((line) => line.id === id);
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const isLive = bookingStatusCode === DealStatus.LIVE;
  const isDealTypeGuaranteed = dealType === DealType.GUARANTEED;
  const isAdServerMarket = useSelector(getIsAdServer);

  const [impressionsInput, setImpressionsInput] = useState<number>(impressions || 0);
  const [showImpressionsMessage, setShowImpressionsMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setShowImpressionsMessage(!!allocatedImpressions && !!impressions && allocatedImpressions !== impressions);
    setImpressionsInput(impressions || 0);
  }, [impressions, allocatedImpressions]);

  const changeImpressions = (value: number): void => {
    const parsedValue = Number(value);

    if (Number.isInteger(parsedValue) && parsedValue <= MAX_SAFE_INTEGER_ALLOWED_IN_BACKEND) {
      setImpressionsInput(parsedValue);
      dispatch(changeDealCurrentLineData({ impressions: parsedValue }));
    }
  };

  const onChange = (value: number): void => {
    setImpressionsInput(Number(value));

    if (!isActionsDisabled) {
      dispatch(changeFormParams({ isActionsDisabled: true }));
    }
  };

  const checkImpressionsChange = async (): Promise<string | boolean> =>
    new Promise((resolve, reject) => {
      if ([DealStatus.APPROVED, DealStatus.PENDING_APPROVAL, DealStatus.REJECTED].includes(bookingStatusCode)) {
        return resolve(false);
      }
      if (!isAdServerMarket && !backupLine?.impressions) {
        return resolve(false);
      }
      if (
        !isAdServerMarket &&
        !!backupLine?.impressions &&
        impressionsInput > backupLine?.impressions &&
        isPendingReservation
      ) {
        return reject(new Error('Cannot Increase Impressions Of DV360 Deal'));
      }
      if (
        isLive &&
        (isDealTypeGuaranteed || hasNonGuaranteedExtendedTargetEnabled) &&
        impressionsInput === Math.ceil(deliveredImpressions) &&
        !isCurrentLineFutureStartDate
      ) {
        return reject(
          new Error(
            'The impression goal you entered has already been met. Please increase your impression target above the traded impressions in order to save your changes.',
          ),
        );
      }
      if (
        isAdServerMarket &&
        isLive &&
        (isDealTypeGuaranteed || hasNonGuaranteedExtendedTargetEnabled) &&
        impressionsInput <= Math.ceil(deliveredImpressions) &&
        !isCurrentLineFutureStartDate
      ) {
        return reject(new Error('Cannot decrease the impression goal below the number of traded impressions'));
      }
      return resolve(!!getAutomationDealConfigValue(dealConfig, 'autoCalculateFloorAndCeiling'));
    });

  const onBlur = async (): Promise<void> => {
    try {
      const isAutoCalculateFloorAndCeiling = await checkImpressionsChange();
      changeImpressions(impressionsInput);
      dispatch(changeFormParams({ isActionsDisabled: false }));
      setErrorMessage('');

      if (isAutoCalculateFloorAndCeiling) {
        autoCalculateFloorAndCeiling(impressionsInput, preservedAvailability.allocatedImpressions);
      }
    } catch (error) {
      setErrorMessage(error.toString());
    }
  };

  const getFieldInfoMessage = (): string => {
    return (
      errorMessage ||
      (showImpressionsMessage
        ? `Your impression target of ${formatValue(
            impressionsInput,
            FormatTypeEnum.NUMBER,
            currencyCode,
            localeCode,
          )} is ${
            impressionsInput < allocatedImpressions ? 'less' : 'more'
          } than the allocated number of impressions ${formatValue(
            allocatedImpressions,
            FormatTypeEnum.NUMBER,
            currencyCode,
            localeCode,
          )}. Please refer to your deal summary for your booked solution`
        : '')
    );
  };

  if (!Auth.hasPermission(PermissionsEnum.DEAL_IMPRESSIONS_VIEW)) return null;

  return (
    <div>
      <label
        htmlFor="impressions"
        className="sub-header-base text-neutral-950-opacity-60 mb-2 inline-block"
        data-test-id="impressions-header"
      >
        Impressions
        {isDealTypeGuaranteed ? <RequiredAsterisk /> : null}
      </label>
      <FieldWithInfoMessage
        field={
          <InputNumber
            dataTestId="impressions-input"
            name="impressions"
            value={impressionsInput}
            onChange={onChange}
            onBlur={onBlur}
            maximumFractionDigits={0}
            isDisabled={disabled || !!budget}
          />
        }
        icon={
          <SVG
            src={warningSvg}
            className={errorMessage ? 'fill-current text-pinkRed-700' : 'fill-current text-yellow'}
          />
        }
        message={getFieldInfoMessage()}
      />
    </div>
  );
};

export default Impressions;
