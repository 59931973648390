import { DealStatus } from 'components/common/types/Deal.types';
import Auth from 'modules/Auth';
import { formatDate, formatDateNoUTC } from 'modules/I18N';
import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux';

import googleSvg from 'assets/icons/google_schedule.svg';
import { DealItemData } from 'components/common/types/DealItem';
import { PermissionsEnum } from 'consts/permissions';
import useScheduledDaysLeft from 'customHooks/useScheduledDaysLeft';
import { Store } from 'components/common/types/Store.types';

const DAYS_LEFT_TEXT_COLORS = {
  RED: 'text-pinkRed-500',
  YELLOW: 'text-yellow',
  GREY: 'text-neutral-950-opacity-60',
};

type ScheduledRendererProps = {
  data?: DealItemData;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const ScheduledRenderer: React.FC<ScheduledRendererProps> = ({ data = {} }) => {
  const localeCode = useSelector((state: Store) => state.publisher.configuration.localeCode);
  const [daysLeftTextColor, setDaysLeftTextColor] = useState(DAYS_LEFT_TEXT_COLORS.GREY);
  const daysLeft = useScheduledDaysLeft(data.endDate ?? '');
  let startDate = '';
  let endDate = '';

  const isExpireDataVisible = data.endDate && daysLeft > 0 && data.bookingStatusCode === DealStatus.PENDING_RESERVATION;

  if (data.startDate) {
    startDate = Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC)
      ? formatDate(data.startDate, localeCode)
      : formatDateNoUTC(data.startDate, localeCode);
  }
  if (data.endDate) {
    endDate = Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC)
      ? formatDate(data.endDate, localeCode)
      : formatDateNoUTC(data.endDate, localeCode);
  }

  useEffect(() => {
    if (daysLeft <= 15 && daysLeft > 5) {
      setDaysLeftTextColor(DAYS_LEFT_TEXT_COLORS.YELLOW);
    } else if (daysLeft <= 5) {
      setDaysLeftTextColor(DAYS_LEFT_TEXT_COLORS.RED);
    } else {
      setDaysLeftTextColor(DAYS_LEFT_TEXT_COLORS.GREY);
    }
  }, [daysLeft]);

  return (
    <div>
      <div data-test-id="scheduled-renderer-date-range">
        {startDate}
        {startDate && endDate && <> to </>}
        {endDate}
      </div>
      {isExpireDataVisible && (
        <div className="flex items-center space-x-1 body-base text-neutral-950-opacity-60">
          <SVG src={googleSvg} className="inline-block fill-current" />
          <p>
            Expire in{' '}
            <strong data-test-id="scheduled-renderer-expire-days" className={daysLeftTextColor}>
              {daysLeft} {daysLeft > 1 ? 'days' : 'day'}
            </strong>
          </p>
        </div>
      )}
    </div>
  );
};

export default ScheduledRenderer;
