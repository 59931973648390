import { useEffect, useState } from 'react';
import { getElementBoundingBox } from 'utils/getElementBoundingBox';
import { ElementsRefsHeights, RefElementType } from './MasonryGallery.types';

export const useElementsRefsHeights = (elementsRefs: RefElementType[]): ElementsRefsHeights => {
  const [elementsRefsHeights, setRefsHeights] = useState<number[]>([]);

  const resizeObserver = new ResizeObserver(() =>
    setRefsHeights(
      elementsRefs.map((ref) => {
        if (!ref.current) {
          return 0;
        }

        return getElementBoundingBox(ref.current).height;
      }),
    ),
  );

  useEffect(() => {
    resizeObserver.disconnect();
    elementsRefs.forEach((elementRef) => elementRef.current && resizeObserver.observe(elementRef.current));
  }, [elementsRefs]);

  return { elementsRefsHeights };
};
