/* eslint-disable sonarjs/cognitive-complexity */

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const deepOverrideObject = <T>(original: T, overrides: DeepPartial<T>): DeepPartial<T> | Object => {
  if (Object.keys(original).length === 0) {
    return overrides as T;
  }

  const result = {} as T;

  Object.keys(original).forEach((key: Extract<keyof T, string>) => {
    const deepPartialOverride = (overrides as DeepPartial<T>)[key];

    if (Array.isArray(original[key])) {
      result[key as keyof T] = deepPartialOverride !== undefined ? (deepPartialOverride as T[keyof T]) : original[key];
    } else if (original[key] instanceof Date) {
      result[key as keyof T] =
        deepPartialOverride !== undefined
          ? (new Date(deepPartialOverride as unknown as Date) as unknown as T[keyof T])
          : original[key];
    } else if (typeof original[key] === 'object' && original[key] !== null) {
      result[key as keyof T] = deepOverrideObject(original[key], overrides[key as never] || {}) as T[keyof T];
    } else {
      result[key as keyof T] = deepPartialOverride !== undefined ? (deepPartialOverride as T[keyof T]) : original[key];
    }
  });

  return result;
};

/* eslint-enable sonarjs/cognitive-complexity */
