import PropTypes from 'prop-types';
import { formatNumber } from 'modules/I18N';

const NumberRenderer = ({ value, localeCode }) => {
  return value === null ? null : formatNumber({ value, localeCode });
};

NumberRenderer.propTypes = {
  value: PropTypes.number,
  localeCode: PropTypes.string,
};

NumberRenderer.defaultProps = {
  value: null,
  localeCode: null,
};

export default NumberRenderer;
