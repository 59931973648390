import { FillRateDealData } from 'components/common/types/InsightsDeal';

export const handleFillRate = (deals: FillRateDealData[]): FillRateDealData[] => {
  return deals.map((deal) => {
    const isRequiredData = deal.offeredImpressions && deal.soldImpressions;

    return {
      ...deal,
      fillRate: isRequiredData ? (deal.soldImpressions / deal.offeredImpressions) * 100 : 0,
    };
  });
};
