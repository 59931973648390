const SETTINGS = {
  PIE: {
    POINT_FORMAT: '<b>{point.percentage:.2f}%</b>',
    DATA_LABEL_FORMAT: '<b>{point.name}</b>: {point.percentage:.2f} %',
  },
  COLORS: {
    primary: '#272199',
    primaryLight: '#6E2EE4',
    primaryHover: '#5a52a9', // TODO
    inkColor: '#42414E',
    darkInk: '#262541',
    yellow: '#F8BC1A',
    mintGreen: '#2EE49B',
    pinkRed: '#E35770',
    blueSky: '#2EADE4',
    blueSecondary: '#128ABE',
    lightAir: '#F8F8F9',
    mist: '#F8F8F9',
    softSilver: '#F3F3F5',
    silver: '#E1E1E5',
    gray: '#A09FAA',
    grayer: '#7C7B87',
    softGray: '#BAB9C3',
    softGrayDark: '#A09FAA',
    softGrayLight: '#A09FAA',
    transparent: 'transparent',
    white: '#FFF',
    flavescentYellow: '#FCE9BA',
    pistachioGreen: '#C7F698',
    mintGreenDark: '#12BE79',
    oceanGreen: '#129F67',
  },
};

export default SETTINGS;
