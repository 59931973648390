import SVG from 'react-inlinesvg';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import labsSvg from 'assets/icons/labs.svg';

interface PlannerLabsButtonProps {
  onClick: () => void;
}

const PlannerLabsButton: React.FC<PlannerLabsButtonProps> = ({ onClick }) => (
  <Button
    dataTestId="planner-labs"
    onClick={onClick}
    btnShape={ButtonShape.NORMAL}
    btnType={ButtonType.MAGENTA_RED}
    label="Planner LABS"
  >
    <SVG src={labsSvg} color="white" />
    <span>Planner LABS</span>
  </Button>
);

export default PlannerLabsButton;
