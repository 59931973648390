import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './DealWithRightSidebar.pcss';

const cx = classNames.bind(styles);

const DealWithRightSidebar = ({ sidebar, header, leftContent, rightContent }) => {
  return (
    <main className={cx('deal')}>
      <div className={cx('content', 'gap-y-5 h-screen overflow-auto')}>
        <div className={cx('header', 'col-span-full')}>{header}</div>
        <div className="sticky top-5 z-1 place-self-start">{leftContent}</div>
        <div className="px-8">{rightContent}</div>
      </div>
      <div className="h-screen overflow-auto bg-neutral-100 border-l border-neutral-300">{sidebar}</div>
    </main>
  );
};

DealWithRightSidebar.propTypes = {
  sidebar: PropTypes.element.isRequired,
  header: PropTypes.element.isRequired,
  leftContent: PropTypes.element.isRequired,
  rightContent: PropTypes.element.isRequired,
};

export default DealWithRightSidebar;
