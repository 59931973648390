import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import cx from 'classnames';
import SVG from 'react-inlinesvg';

import { notifyError } from 'store/notification/reducer';
import { changeSelectedDays } from 'store/dealManagement/actions';
import { PermissionsEnum } from 'consts/permissions';
import Auth from 'modules/Auth';

import Dropdown from 'lib/Dropdown';

import fullDayIcon from 'assets/icons/sun.svg';
import clockIcon from 'assets/icons/clock.svg';
import plusIcon from 'assets/icons/plus.svg';
import minusIcon from 'assets/icons/minus.svg';

import DAY_PART from 'consts/dayPart';

// eslint-disable-next-line  sonarjs/cognitive-complexity
const DayPart = ({ readOnly }) => {
  const dispatch = useDispatch();
  const selectedDays = useSelector((state) => state.dealManagement.commonDeal.currentLine.selectedDays);

  const getCurrentlySelectedDays = (days) => Object.values(days).filter((day) => day.currentSelection);

  const getCurrentEditableDay = () => {
    const currentSelectedDays = getCurrentlySelectedDays(selectedDays);

    if (!currentSelectedDays.length) return null;

    return currentSelectedDays.reduce((prevSelectedDay, currentDay) =>
      currentDay.dayParts.length > prevSelectedDay.dayParts.length ? currentDay : prevSelectedDay,
    );
  };

  const addDayPart = () => {
    const currentDay = getCurrentEditableDay();

    if (!currentDay) {
      dispatch(notifyError({ message: 'Error adding day. No day selected' }));
      return;
    }

    if (currentDay.isFullDay) {
      dispatch(notifyError({ message: 'Selected day which have Full Day already selected are not updated' }));
      return;
    }

    const prevSelectedDays = cloneDeep(selectedDays);
    const currentlySelectedDays = getCurrentlySelectedDays(prevSelectedDays);
    currentlySelectedDays.forEach(({ id }) => {
      if (prevSelectedDays[id].isFullDay) return;
      prevSelectedDays[id].dayParts.push(DAY_PART.fullDay);
      prevSelectedDays[id].isFullDay = true;
    });
    dispatch(changeSelectedDays(prevSelectedDays));
  };

  const removeDayPart = (valueIndex) => {
    const prevSelectedDays = cloneDeep(selectedDays);
    const currentlySelectedDays = getCurrentlySelectedDays(prevSelectedDays);
    currentlySelectedDays.forEach(({ id }) => {
      if (!prevSelectedDays[id].dayParts[valueIndex]) return;
      const [removedValue] = prevSelectedDays[id].dayParts.splice(valueIndex, 1);
      if (removedValue.id === DAY_PART.fullDay.id) prevSelectedDays[id].isFullDay = false;
      if (!prevSelectedDays[id].dayParts.length) delete prevSelectedDays[id];
    });
    dispatch(changeSelectedDays(prevSelectedDays));
  };

  const dayPartChange = (currentDayId, valueIndex, newValue, dropdownName) => {
    const currentDay = cloneDeep(selectedDays[currentDayId]);

    if (currentDay.isFullDay) {
      currentDay.tempCustomHours[dropdownName] = newValue;
    } else if (!currentDay.isFullDay) {
      currentDay.dayParts[valueIndex][dropdownName] = newValue;
    }

    if (currentDay.tempCustomHours.startOffset && currentDay.tempCustomHours.endOffset) {
      currentDay.dayParts[valueIndex] = {
        id: new Date().getTime(),
        dayPartId: 0,
        startOffset: Number(currentDay.tempCustomHours.startOffset),
        endOffset: Number(currentDay.tempCustomHours.endOffset),
      };
      currentDay.tempCustomHours = {};
      currentDay.isFullDay = false;
    }

    const prevSelectedDays = cloneDeep(selectedDays);
    const currentlySelectedDays = getCurrentlySelectedDays(prevSelectedDays);

    if (currentDay.isFullDay) {
      dispatch(changeSelectedDays({ ...prevSelectedDays, [currentDayId]: currentDay }));
      return;
    }

    currentlySelectedDays.forEach(({ id, date, isFullDay }, index) => {
      const { dayParts } = prevSelectedDays[id];
      const valPosition = dayParts[valueIndex] ? valueIndex : dayParts.length;
      if (id !== currentDay.id && isFullDay && !dayParts[valueIndex]) return;
      dayParts[valPosition] = {
        ...currentDay.dayParts[valueIndex],
        id: date.getTime() + currentDay.dayParts[valueIndex].id + index,
      };
      prevSelectedDays[id].isFullDay = currentDay.isFullDay;
      prevSelectedDays[id].tempCustomHours = currentDay.tempCustomHours;
    });
    dispatch(changeSelectedDays(prevSelectedDays));
  };

  const dayPartTypeChange = (currentDayId, valueIndex, selectedObj) => {
    if (selectedObj.id === DAY_PART.dayPart.id) return;

    if (selectedDays[currentDayId].isFullDay) {
      dispatch(notifyError({ message: 'Full day option has already been selected for this day' }));
    }

    const prevSelectedDays = cloneDeep(selectedDays);
    const currentlySelectedDays = getCurrentlySelectedDays(prevSelectedDays);

    currentlySelectedDays.forEach(({ id }) => {
      const { dayParts, isFullDay } = prevSelectedDays[id];
      const valPosition = dayParts[valueIndex] ? valueIndex : dayParts.length;
      if (isFullDay) return;
      prevSelectedDays[id].isFullDay = true;
      dayParts[valPosition] = DAY_PART.fullDay;
    });
    dispatch(changeSelectedDays(prevSelectedDays));
  };

  if (!Auth.hasPermission(PermissionsEnum.DEAL_PATTERNS_TIME_VIEW)) return null;

  const currentSelectedDay = getCurrentEditableDay();

  return (
    <div data-test-id="daypart-container" className={`mt-8 ${readOnly ? 'readOnly readOnlyOpacity' : ''}`}>
      <div
        className={cx('grid grid-cols-4 gap-x-5 py-2', {
          'mb-2': !currentSelectedDay,
        })}
      >
        <p className="flex items-center h-7 text-neutral-950-opacity-60">
          <SVG src={fullDayIcon} className="mt-0.5 text-neutral-950-opacity-50" />
          <span className="sub-header-base mx-1.5">Day Part</span>
        </p>
        <p className="flex items-center h-7 col-span-2 text-neutral-950-opacity-60">
          <SVG src={clockIcon} className="w-4 h-4 text-neutral-950-opacity-50" />
          <span className="sub-header-base mx-2">Custom</span>
        </p>
        <button
          type="button"
          data-test-id="day-part-add-button"
          className="flex items-center justify-center border border-neutral-300 rounded-md text-neutral w-8 h-8 place-self-start"
          onClick={readOnly ? null : addDayPart}
        >
          <SVG src={plusIcon} className="w-4 h-4 text-neutral-950-opacity-50" />
        </button>
      </div>

      <div className="grid grid-cols-4 gap-y-3 gap-x-5 py-2">
        {currentSelectedDay
          ? currentSelectedDay.dayParts.map((value, index) => {
              const isFullDayOption = value.id === DAY_PART.fullDay.id;
              return (
                <Fragment key={value.id}>
                  <Dropdown
                    dataTestId="daypart-option-dropdown"
                    name="daypart"
                    onChange={(_, __, selectedObj) => dayPartTypeChange(currentSelectedDay.id, index, selectedObj)}
                    dataList={[DAY_PART.fullDay, DAY_PART.dayPart]}
                    value={isFullDayOption ? DAY_PART.fullDay.id : DAY_PART.dayPart.id}
                    textKey="displayName"
                    valueKey="id"
                    isDisabled={readOnly}
                  />
                  <Dropdown
                    dataTestId="daypart-start-dropdown"
                    onChange={(newValue, dropdownName) =>
                      dayPartChange(currentSelectedDay.id, index, newValue, dropdownName)
                    }
                    dataList={DAY_PART.hours}
                    value={isFullDayOption ? currentSelectedDay.tempCustomHours.startOffset : value.startOffset}
                    name="startOffset"
                    textKey="startLabel"
                    valueKey="startOffset"
                    defaultOption="Start Time"
                    isDisabled={readOnly}
                  />
                  <Dropdown
                    dataTestId="daypart-end-dropdown"
                    onChange={(newValue, dropdownName) =>
                      dayPartChange(currentSelectedDay.id, index, newValue, dropdownName)
                    }
                    dataList={DAY_PART.hours}
                    value={isFullDayOption ? currentSelectedDay.tempCustomHours.endOffset : value.endOffset}
                    name="endOffset"
                    textKey="endLabel"
                    valueKey="endOffset"
                    defaultOption="End Time"
                    isDisabled={readOnly}
                  />
                  <button
                    type="button"
                    data-test-id="day-part-remove-button"
                    onClick={() => (readOnly ? null : removeDayPart(index))}
                    className="flex items-center justify-center place-self-start text-neutral-950-opacity-50 border border-neutral-300 rounded-md w-8 h-8 ml-px"
                  >
                    <SVG src={minusIcon} />
                  </button>
                </Fragment>
              );
            })
          : null}
      </div>
    </div>
  );
};

DayPart.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};

export default DayPart;
