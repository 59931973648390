import SVG from 'react-inlinesvg';
import linkSvg from 'assets/icons/link.svg';

const LinkChip: React.FC = () => {
  return (
    <div
      className="inline-flex items-center text-orange-600 body-sm px-1 bg-orange-100 rounded-full"
      data-test-id="link-chip"
    >
      <SVG className="w-3 h-3 mr-0.5" src={linkSvg} />
      <span>linked</span>
    </div>
  );
};

export default LinkChip;
