import PropTypes from 'prop-types';

export const FrameStyle = {
  DARK: 'border-neutral-950-opacity-60 text-neutral-100 bg-neutral-100-opacity-5',
  LIGHT: 'border-neutral-100-opacity-10 bg-primary-600-opacity-10 text-primary-600',
};

const FramesRenderer = ({ theme, data: { panels } }) => {
  return (
    <div className="flex space-x-2" data-test-id="frames">
      {panels?.map(({ id, width, height, count }) => (
        <div
          key={id}
          className={`py-2.5 text-center rounded-md border body-base ${theme}
            ${width > height ? 'w-14 h-10' : 'w-7 h-10'}
          }`}
        >
          {count}
        </div>
      ))}
    </div>
  );
};

FramesRenderer.propTypes = {
  theme: PropTypes.string,
  data: PropTypes.shape({
    panels: PropTypes.arrayOf(PropTypes.object),
  }),
};

FramesRenderer.defaultProps = {
  data: {},
  theme: FrameStyle.LIGHT,
};
export default FramesRenderer;
