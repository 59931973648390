import {
  MultipleFieldSet,
  SingleFieldSet,
} from 'components/pages/ContentManagement/EditCreatives/EditPanel/CreativesMetaForm/FieldSet';
import AdditionalProductCategories from 'components/pages/ContentManagement/EditCreatives/EditPanel/CreativesMetaForm/AdditionalData/AdditionalProductCategories';
import FrameTags from 'components/pages/ContentManagement/EditCreatives/EditPanel/CreativesMetaForm/AdditionalData/FrameTags';

const AdditionalData: React.FC = () => {
  return (
    <>
      <MultipleFieldSet>
        <SingleFieldSet label="Additional product categories" id="additional-product-categories">
          <AdditionalProductCategories />
        </SingleFieldSet>
      </MultipleFieldSet>
      <MultipleFieldSet>
        <SingleFieldSet label="Frame tagging rules" id="frame-tagging-rules">
          <FrameTags />
        </SingleFieldSet>
      </MultipleFieldSet>
    </>
  );
};

export default AdditionalData;
