import { lazy, Suspense } from 'react';
import Card from 'components/patterns/Card';
import { Preloader } from 'components/patterns/Loader';
import Filters from './Filters';
import Stats from './Stats';

const RevenueTable = lazy(() => import('./RevenueTable'));
const RevenueChart = lazy(() => import('./RevenueChart'));
const CPMChart = lazy(() => import('./CPMChart'));
const DspRevenueMonthChart = lazy(() => import('./DspRevenueMonthChart'));
const DspRevenueDayChart = lazy(() => import('./DspRevenueDayChart'));

const RevenueContent: React.FC = () => {
  return (
    <>
      <Stats />
      <Filters />
      <Suspense fallback={<Preloader />}>
        <Card>
          <RevenueChart />
        </Card>
      </Suspense>
      <Suspense fallback={<Preloader />}>
        <Card>
          <CPMChart />
        </Card>
      </Suspense>
      <Suspense fallback={<Preloader />}>
        <Card>
          <DspRevenueMonthChart />
        </Card>
      </Suspense>
      <Suspense fallback={<Preloader />}>
        <Card>
          <DspRevenueDayChart />
        </Card>
      </Suspense>
      <Suspense fallback={<Preloader />}>
        <RevenueTable />
      </Suspense>
    </>
  );
};

export default RevenueContent;
