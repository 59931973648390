import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

import arrowSvg from 'assets/icons/arrow_down.svg';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import { Store } from 'components/common/types/Store.types';
import { Creative, CreativeCountSummary, CreativeType } from 'components/common/types/Creative.types';
import CreativeCount from '../../CreativeCount';
import DspPopup from './DspPopup';

const EditCreativesHeader: React.FC = () => {
  const navigate = useNavigate();

  const selectedCreatives = useSelector((state: Store) => state.pages.contentManagement.creatives.selectedCreatives);

  const handleBackToClick = (): void => {
    navigate('/content-management');
  };

  const creativeCountSummary = useMemo<CreativeCountSummary>(
    () => ({
      staticCreatives: selectedCreatives.filter(
        (creative: Creative) => creative.type === CreativeType.BANNER || creative.type === CreativeType.DYNAMIC,
      ).length,
      motionCreatives: selectedCreatives.filter((creative: Creative) => creative.type === CreativeType.VIDEO).length,
    }),
    [selectedCreatives],
  );

  return (
    <div className="flex items-center justify-between px-6 pt-5">
      <div className="flex items-center">
        <Button
          btnShape={ButtonShape.CIRCLE}
          btnType={ButtonType.PRIMARY}
          onClick={handleBackToClick}
          dataTestId="back-to-creatives"
        >
          <SVG className="transform rotate-90 w-4" src={arrowSvg} />
        </Button>
        <h2 className="ml-6 header-xl text-neutral-950">Edit creatives</h2>
      </div>
      <div className="flex space-x-2 justify-between items-center">
        <CreativeCount
          totalCreatives={selectedCreatives?.length > 0 ? selectedCreatives.length : 0}
          creativeCountSummary={creativeCountSummary}
          theme="DARK"
        />
        <span className="ml-2 text-neutral-100">&bull;</span>
        <DspPopup />
      </div>
    </div>
  );
};

export default EditCreativesHeader;
