import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { clearNotification } from 'store/notification/reducer';
import Notifications from './Notifications';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any): any => ({
  message: state.notification.message,
  type: state.notification.type,
  timeout: state.notification.timeout,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) =>
  bindActionCreators({ clearNotification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
