import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DirectSalesOption } from 'components/common/types/DirectSalesCampaign.types';
import { PoiOption } from 'components/common/types/Planner.types';
import { ReferenceOptions, UserOptions } from './reducer.types';

const initialState: UserOptions = {
  referenceOptions: {
    location: {
      address: [],
      conurbation: [],
      country: [],
      county: [],
      district: [],
      geographicalAreaLevel1: [],
      geographicalAreaLevel2: [],
      geographicalAreaLevel3: [],
      postcode: [],
      routeTown: [],
      town: [],
      tvArea: [],
    },
    format: { businessArea: [], frameSize: [], productFormatMarketing: [], revenueStream: [], productFormat: [] },
    tags: {},
  },
  segmentOptions: [],
  locationOptions: [],
  poiOptions: [],
};

export const userOptionsSlice = createSlice({
  name: 'userOptions',
  initialState,
  reducers: {
    changeSegmentOptions: (state, action) => {
      state.segmentOptions = action.payload;
    },
    addLocationUserOptions: (state, action: PayloadAction<DirectSalesOption[]>) => {
      state.locationOptions = action.payload;
    },
    changePoiOptions: (state, action: PayloadAction<PoiOption[]>) => {
      state.poiOptions = action.payload;
    },
    changeReferenceData: (state, action: PayloadAction<ReferenceOptions>) => {
      state.referenceOptions = action.payload;
    },
  },
});

export const { changeSegmentOptions, addLocationUserOptions, changePoiOptions, changeReferenceData } =
  userOptionsSlice.actions;

export default userOptionsSlice.reducer;
