import { createSelector } from '@reduxjs/toolkit';
import { Store } from 'components/common/types/Store.types';
import { getAdServerDealConfigValue } from 'store/dealConfig/utils';
import { getIsAdServer } from 'store/publisher/selectors';

export const getLineLevelCpmSettings = createSelector(
  getIsAdServer,
  (state: Store) => state.dealConfig,
  (state: Store) => state.dealManagement.isNewDeal,
  (state: Store) => state.dealManagement.programmatic?.enableLineLevelTrading,
  (isAdServer, dealConfig, isNewDeal, enableLineLevelTradingCampaignResponse) => {
    const enableLineLevelTradingPublisherConfig = getAdServerDealConfigValue(dealConfig, 'enableLineLevelTrading');

    if (!isAdServer) return { isLineLevelCpmVisible: false, isLineLevelCpmEditable: false };

    if (!enableLineLevelTradingPublisherConfig) {
      if (!isNewDeal && enableLineLevelTradingCampaignResponse) {
        return { isLineLevelCpmVisible: true, isLineLevelCpmEditable: true };
      }

      return { isLineLevelCpmVisible: false, isLineLevelCpmEditable: false };
    }

    if (enableLineLevelTradingPublisherConfig) {
      if (!isNewDeal && !enableLineLevelTradingCampaignResponse) {
        return { isLineLevelCpmVisible: true, isLineLevelCpmEditable: false };
      }

      return { isLineLevelCpmVisible: true, isLineLevelCpmEditable: true };
    }

    return { isLineLevelCpmVisible: false, isLineLevelCpmEditable: false };
  },
);
