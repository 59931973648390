import { useMemo } from 'react';
import InputNumber from 'lib/InputNumber';
import debounce from 'lodash/debounce';

type DistanceProps = {
  value: string;
  isDisabled?: boolean;
  dataTestId?: string;
  onChange?: (value: string) => void;
};

const Distance: React.FC<DistanceProps> = ({ value, onChange, isDisabled = false, dataTestId = '' }) => {
  const debouncedOnChange = useMemo(() => (onChange ? debounce(onChange, 1000) : undefined), [onChange]);

  return (
    <div className="w-32" data-test-id={dataTestId}>
      <p className="sub-header-base text-neutral-950-opacity-60 mb-2">Distance</p>
      <InputNumber
        dataTestId={`${dataTestId}-input`}
        placeholder="Enter value"
        value={value}
        onChange={debouncedOnChange}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default Distance;
