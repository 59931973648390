import { IndexOptimisation } from 'components/common/types/Deal.types';

export const formatIndexOptimisationWithEmptyDisplayName = (
  data: (Omit<IndexOptimisation, 'dataProvider' | 'displayName'> & {
    dataProvider?: string;
    displayName?: string;
  })[] = [],
): IndexOptimisation[] => {
  if (!data || !data.length) return [];

  return data.map(({ secondaryAudienceKey, type, target, threshold }) => ({
    dataProvider: '',
    displayName: '',
    secondaryAudienceKey,
    type,
    target,
    threshold,
  }));
};
