import config from 'config';
import Session from 'modules/Session';
import { MockURL } from 'tests-setup/jestUtils';

const getAutomationUrlPart = () => {
  let automationUrlPart = '';
  const envId = Session.getEnvironmentId();
  const envValues = envId.split(':');

  if (envValues.length > 1) {
    automationUrlPart = envValues.splice(1).join('');
  }

  return automationUrlPart;
};

// Apparently destructuring doesn't work as expected with this variable.
// eslint-disable-next-line prefer-destructuring
export const HTTP_METHODS = config.HTTP_METHODS;
export const HEADERS_CONTENT_TYPE = {
  APPLICATION_JSON: 'application/json',
  APPLICATION_X_WWW_FORM_URLENCODED: 'application/x-www-form-urlencoded',
  MULTIPART_FORM_DATA: 'multipart/form-data',
};
export const URLS = () => config.URLS(config.api.BASE_URL, getAutomationUrlPart, config.planner?.URL, config.dsm);
export const MOCK_URLS = new MockURL(URLS).URLS;

export const POLLING_INTERVAL = 60 * 1000; // number of millisecond to wait before making another call in polling
export const DATE_FORMAT = 'DD/MM/YYYY';
export const MAX_SAFE_INTEGER_ALLOWED_IN_BACKEND = 2147483647;
export const FILE_SIZE_10MB = 10485760;
export const FILE_SIZE_20MB = 20971520;
export const FILE_SIZE_50MB = 52428800;
export const FILE_SIZE_100MB = 104857600;
