import { DealStatus, SweepOptions } from 'components/common/types/Deal.types';

export const DEAL_STATUS_BADGE = [
  {
    code: '',
    name: 'New',
    initials: 'N',
    color: 'bg-primary-900-opacity-5',
    textColor: 'text-primary-600',
  },
  {
    code: DealStatus.PENDING_APPROVAL,
    name: 'Pending approval',
    initials: 'PA',
    color: 'bg-yellow',
    textColor: 'text-neutral-50',
  },
  {
    code: DealStatus.APPROVED,
    name: 'Approved',
    initials: 'A',
    color: 'bg-green',
    textColor: 'text-neutral-50',
  },
  {
    code: DealStatus.REJECTED,
    name: 'Rejected',
    initials: 'R',
    color: 'bg-pinkRed',
    textColor: 'text-neutral-50',
  },
  {
    code: DealStatus.LIVE,
    name: 'Live',
    initials: 'L',
    color: 'bg-sky',
    textColor: 'text-neutral-50',
  },
  {
    code: DealStatus.ENDED,
    name: 'Ended',
    initials: 'E',
    color: 'bg-neutral-900',
    textColor: 'text-neutral-50',
  },
  {
    code: DealStatus.CANCELLED,
    name: 'Cancelled',
    initials: 'C',
    color: 'bg-neutral-900',
    textColor: 'text-neutral-50',
  },
  {
    code: DealStatus.TERMINATED,
    name: 'Terminated',
    initials: 'T',
    color: 'bg-neutral-900',
    textColor: 'text-neutral-50',
  },
  {
    code: DealStatus.FAILED,
    name: 'Failed',
    initials: 'F',
    color: 'bg-pinkRed',
    textColor: 'text-neutral-50',
  },
  {
    code: DealStatus.PENDING_RESERVATION,
    name: 'Pending Reservation',
    initials: 'PR',
    color: 'bg-yellow',
    textColor: 'text-neutral-50',
  },
  {
    code: DealStatus.RESERVED,
    name: 'Reserved',
    initials: 'R',
    color: 'bg-lime',
    textColor: 'text-neutral-50',
  },
];

export const SWEEP_OPTIONS_CODES = {
  [SweepOptions.SWEEP_TARGET_EXACT_SOT]: 1,
  [SweepOptions.SWEEP_TO_FILL]: 3,
  [SweepOptions.SWEEP_TO_HOUR]: 8,
  [SweepOptions.SWEEP_TO_DAYPART]: 7,
  [SweepOptions.SWEEP_TO_DAY]: 6,
  [SweepOptions.SWEEP_WEIGHTED_DISTRIBUTION]: 2,
};

export const SWEEP_OPTIONS_NAMES = {
  [SweepOptions.SWEEP_TARGET_EXACT_SOT]: 'Target exact SOT',
  [SweepOptions.SWEEP_TO_FILL]: 'Sweep-to-fill',
  [SweepOptions.SWEEP_TO_HOUR]: 'All hour sweep',
  [SweepOptions.SWEEP_TO_DAYPART]: 'All day part sweep',
  [SweepOptions.SWEEP_TO_DAY]: 'All day sweep',
  [SweepOptions.SWEEP_WEIGHTED_DISTRIBUTION]: 'Weighted distribution',
};

export const SWEEP_OPTIONS_INFO = {
  [SweepOptions.SWEEP_TARGET_EXACT_SOT]: 'No sweep has been applied. The frame count will not be affected.',
  [SweepOptions.SWEEP_TO_FILL]:
    "Sweep-to-fill will maximize the number of trading opportunities across your frames. This option will take all of the available SoT, up to the frame's default or your targeted SoT.",
  [SweepOptions.SWEEP_TO_HOUR]:
    'All hour sweep has been applied. Frames with the selected share of time per hour that is available have been added to your frame availability.',
  [SweepOptions.SWEEP_TO_DAYPART]:
    'All day part sweep has been applied. Frames with the selected share of time per day part that is available have been added to your frame availability.',
  [SweepOptions.SWEEP_TO_DAY]:
    'All day sweep has been applied. Frames with the selected share of time per day that is available have been added to your frame availability.',
  [SweepOptions.SWEEP_WEIGHTED_DISTRIBUTION]:
    'Weighted distribution is applied. Weighted distribution optimises your targeting by finding you the best hours per frame based on your define deal schedule.',
};
