import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PublisherConfigurationResponse,
  PublisherFeatures,
  PublisherStore,
} from 'components/common/types/Publisher.types';
import { convertToArrayOfMarketsForIndividualFeatureFlag } from './utils';

const initialState = {
  configuration: {},
  publisherFeatures: {},
} as PublisherStore;

const publisherSlice = createSlice({
  name: 'publisherSlice',
  initialState,
  reducers: {
    changeConfiguration: (state, action: PayloadAction<PublisherConfigurationResponse>) => {
      state.configuration = {
        backEndSystem: action.payload.backEndSystem,
        marketId: action.payload.identifier,
        currencyCode: action.payload.currencyCode,
        flag: action.payload.applicationConfiguration.flag || '',
        iso2CountryCode: action.payload.iso2CountryCode,
        latitude: Number(action.payload.applicationConfiguration.latitude),
        localeCode: action.payload.applicationConfiguration.localeCode,
        longitude: Number(action.payload.applicationConfiguration.longitude),
        openStreetMapCountryName: action.payload.applicationConfiguration.countryName,
        timezone: action.payload.timeZone,
        type: action.payload.type,
      };
    },
    changeFeatures: (state, action: PayloadAction<PublisherFeatures>) => {
      state.publisherFeatures = convertToArrayOfMarketsForIndividualFeatureFlag(action.payload);
    },
    clearPublisherData: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const { changeConfiguration, changeFeatures, clearPublisherData } = publisherSlice.actions;

export default publisherSlice.reducer;
