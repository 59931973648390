import { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import isEqual from 'lodash/isEqual';

import Auth from 'modules/Auth';
import Session from 'modules/Session';
import { PermissionsEnum } from 'consts/permissions';
import { getValidContentManagementPageSize } from 'utils/pageSize';
import withRouter from 'components/hocs/withRouter';
import withLoader from 'components/hocs/withLoader';
import Table, { TableHeight, TableRowHeight } from 'components/patterns/Table';
import Modal from 'components/patterns/Modal';
import StatusRenderer from 'components/common/StatusRenderer';
import PageSize from 'components/patterns/PageSize';
import { formatDateTimeNoUTC } from 'modules/I18N';
import { CREATIVE_STATUS_BADGE } from 'consts/creative';
import { CreativeType, ConvertStatus } from 'components/common/types/Creative.types';
import { CONTENT_MANAGEMENT_PAGE_SIZES } from 'consts/pagination';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import ImageRenderer from 'components/common/ImageRenderer';
import HeaderRenderer from 'components/common/HeaderRenderer';
import VideoDurationRenderer from 'components/common/VideoDurationRenderer';
import editIcon from 'assets/icons/edit.svg';
import restoreSvg from 'assets/icons/restore.svg';
import { restoreCreatives } from 'modules/api/bulkEditCreatives';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import withCancelRequest from 'components/hocs/withCancelRequest';
import {
  checkResponseResultErrors,
  getCreativeMarketId,
} from 'components/pages/ContentManagement/EditCreatives/EditPanel/utils';
import CreativeCount from 'components/pages/ContentManagement/CreativeCount';
import ResellerCreativeStatus from 'components/common/ResellerCreativeStatus/ResellerCreativeStatus';
import ActionRenderer from './ActionRenderer';
import ButtonRenderer from './ButtonRenderer';
import DaysRemainingRenderer from './DaysRemainingRenderer';

const DEFAULT_CREATIVE_COUNT = {
  staticCreatives: 0,
  motionCreatives: 0,
};

class Creatives extends Component {
  pageSizeKey = 'content';

  state = {
    pageSize: getValidContentManagementPageSize(Session.getContentManagementPageSizeByKey(this.pageSizeKey)),
    bulkSelectedCreatives: [],
    totalCreativesSelected: 0,
    creativeCountSummary: DEFAULT_CREATIVE_COUNT,
    isRestoreModalOpen: false,
  };

  componentDidUpdate = (prevProps) => {
    const { filterData, setSelectedCreatives } = this.props;

    if (!isEqual(prevProps.filterData, filterData) && this.tableApi) {
      this.tableApi.setDatasource(this.getDataSource());
      setSelectedCreatives([]);
    }
  };

  videoConversionStatusRenderer = ({ data, value }) => {
    if (!data) return '';

    if (data.type !== CreativeType.VIDEO) {
      return 'N/A';
    }

    return ConvertStatus[value];
  };

  updatedRenderer = ({ data }) => {
    const { localeCode } = this.props;

    if (!data) return '';

    if (data.updateStamp) {
      return (
        <>
          <p className="body-base text-neutral-600">{data?.updatedBy ?? ''}</p>
          <p className="body-sm">{formatDateTimeNoUTC(data.updateStamp, localeCode)}</p>
        </>
      );
    }

    return '-';
  };

  statusColumn = () => {
    const { isReseller } = this.props;
    const {
      filterData: { deleted },
    } = this.props;
    if (!isReseller) {
      return deleted
        ? [
            {
              headerName: 'Days to restore',
              field: 'deletedAt',
              minWidth: 170,
              maxWidth: 220,
              resizable: false,
              cellRenderer: DaysRemainingRenderer,
              hide: !Auth.hasPermission(PermissionsEnum.CONTENT_REVIEW),
            },
          ]
        : [
            {
              headerName: 'Status',
              field: 'statusCode',
              minWidth: 100,
              maxWidth: 300,
              headerTooltip: 'Status',
              cellRenderer: StatusRenderer,
              cellRendererParams: { statuses: CREATIVE_STATUS_BADGE },
              colId: 'status',
            },
          ];
    }
    return [
      {
        headerName: 'Status',
        field: 'statusCode',
        cellClass: '!overflow-visible [&>div]:!overflow-visible',
        minWidth: 250,
        maxWidth: 400,
        headerTooltip: 'Status',
        cellRenderer: ResellerCreativeStatus,
        pinned: 'left',
        cellRendererParams: (data) => {
          const currentMarket = data?.data?.market?.find(
            ({ environment }) => environment === Session.getEnvironmentId(),
          );
          return {
            marketId: currentMarket?.id || '',
            portalAttachId: 'creatives-table',
            statusCode: currentMarket?.moderation[0].status,
          };
        },
        colId: 'status',
      },
    ];
  };

  resetCreativeCount = () => {
    this.setState({
      totalCreativesSelected: 0,
      creativeCountSummary: DEFAULT_CREATIVE_COUNT,
    });
  };

  onSelectionChanged = (event) => {
    const { setSelectedCreatives } = this.props;
    const getSelectedRows = event.api.getSelectedRows();
    this.setState({
      bulkSelectedCreatives: getSelectedRows,
      totalCreativesSelected: getSelectedRows.length > 0 ? getSelectedRows.length : 0,
      creativeCountSummary: {
        staticCreatives: getSelectedRows.filter(
          (item) => item.type === CreativeType.BANNER || item.type === CreativeType.DYNAMIC,
        ).length,
        motionCreatives: getSelectedRows.filter((item) => item.type === CreativeType.VIDEO).length,
      },
    });

    setSelectedCreatives(getSelectedRows);
  };

  checkboxSelectionColumn = [
    {
      headerName: '',
      minWidth: 70,
      maxWidth: 70,
      resizable: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      headerComponent: HeaderRenderer,
      pinned: this.props.isReseller ? 'left' : false,
      headerComponentParams: {
        onSelectionChanged: this.onSelectionChanged,
      },
      hide: !Auth.hasPermission(PermissionsEnum.CONTENT_REVIEW),
    },
  ];

  editCreativeColumn = [
    {
      headerName: '',
      minWidth: 60,
      maxWidth: 60,
      resizable: false,
      pinned: this.props.isReseller ? 'left' : false,
      cellRenderer: ActionRenderer,
      cellRendererParams: { navigate: this.props.navigate },
      hide: !Auth.hasPermission(PermissionsEnum.CONTENT_REVIEW),
    },
  ];

  creativeDataColumns = [
    {
      headerName: 'Creative',
      field: 'thumbnail',
      minWidth: 150,
      headerTooltip: 'Creative',
      cellRenderer: ImageRenderer,
      cellRendererParams: (params) => ({
        link: params.data?.id ? `/content-management/previewCreative/${params.data.id}` : '',
      }),
    },
    {
      headerName: 'ID',
      field: 'externalId',
      minWidth: 160,
      headerTooltip: 'ID',
      sortable: true,
    },
    {
      headerName: 'DSP',
      field: 'dspName',
      minWidth: 190,
      headerTooltip: 'DSP',
      sortable: true,
    },
    {
      headerName: 'Size (W x H)',
      minWidth: 180,
      headerTooltip: 'SIZE (Width X Height)',
      sortable: true,
      colId: 'size',
      cellRenderer: (params) => {
        if (!params.data || !params.data.width || !params.data.height) return '';
        return `${params.data.width.toString()} x ${params.data.height.toString()}`;
      },
    },
    {
      headerName: 'Video duration',
      field: 'duration',
      minWidth: 150,
      headerTooltip: 'Video duration',
      cellRenderer: VideoDurationRenderer,
      sortable: true,
    },
    {
      headerName: 'Advertiser',
      field: 'advertiser',
      minWidth: 120,
      headerTooltip: 'Advertiser',
      sortable: true,
    },
    {
      headerName: 'Brand',
      field: 'brand',
      minWidth: 120,
      headerTooltip: 'Brand',
      sortable: true,
    },
    {
      headerName: 'Product category',
      field: 'productCategory',
      minWidth: 160,
      headerTooltip: 'Product category',
      sortable: true,
    },
    {
      headerName: 'Formats',
      field: 'formatName',
      minWidth: 120,
      headerTooltip: 'Formats',
      sortable: true,
    },
    {
      headerName: 'Creative type',
      field: 'type',
      minWidth: 140,
      headerTooltip: 'Creative type',
      sortable: true,
    },
    {
      headerName: 'Video conversion',
      field: 'convertStatus',
      minWidth: 190,
      headerTooltip: 'Video conversion',
      cellRenderer: this.videoConversionStatusRenderer,
      hide: !Auth.hasPermission(PermissionsEnum.CONTENT_VIDEO_CONVERSION_STATUS_VIEW),
    },
    {
      headerName: 'Updated',
      field: 'updatedBy',
      minWidth: 220,
      headerTooltip: 'Updated',
      resizable: false,
      cellRenderer: this.updatedRenderer,
      sortable: true,
    },
    {
      headerName: '',
      cellRenderer: ButtonRenderer,
      cellRendererParams: {
        onStatusUpdate: () => this.getTableApi(this.tableApi),
      },
      minWidth: 100,
      resizable: false,
      hide:
        !Auth.hasPermission(PermissionsEnum.ADMINISTRATION_CREATIVE_DELETE) &&
        !Auth.hasPermission(PermissionsEnum.ADMINISTRATION_CREATIVE_RESTORE),
    },
  ];

  mapSortColumnToRequestData = (columnId) => {
    switch (columnId) {
      case 'advertiser':
        return 'ADVERTISER';
      case 'brand':
        return 'BRAND';
      case 'dspName':
        return 'DSP';
      case 'externalId':
        return 'EXTERNAL_ID';
      case 'formatName':
        return 'PRODUCT_FORMAT';
      case 'productCategory':
        return 'PRODUCT_CATEGORY';
      case 'size':
        return 'WIDTH';
      case 'type':
        return 'TYPE';
      case 'updated':
        return 'UPDATED_AT';
      case 'duration':
        return 'DURATION';
      default:
        return 'UPDATED_AT';
    }
  };

  getDataSource = () => {
    const { onFetchCreatives } = this.props;
    const { pageSize } = this.state;

    this.resetCreativeCount();
    return {
      getRows: async (params) => {
        const [sortingColumn] = params.sortModel;

        try {
          const { content, totalElements } = await onFetchCreatives({
            page: parseInt(params.startRow / pageSize),
            size: pageSize,
            ...(sortingColumn
              ? {
                  sortBy: this.mapSortColumnToRequestData(sortingColumn.colId),
                  sortDir: sortingColumn.sort.toUpperCase(),
                }
              : {}),
          });

          if (totalElements === 0) {
            this.tableApi.showNoRowsOverlay();
          } else {
            this.tableApi.hideOverlay();
          }

          params.successCallback(content, totalElements);
          this.reApplyBulkSelection();
        } catch {} // eslint-disable-line no-empty
      },
    };
  };

  reApplyBulkSelection = () => {
    if (this.state.bulkSelectedCreatives.length) {
      this.tableApi.forEachNode((node) => {
        if (this.state.bulkSelectedCreatives.some((creative) => creative.id === node.data.id)) {
          node.setSelected(true, null, true);
        }
      });
    }
  };

  getColumnApi = (columnApi) => {
    this.columnApi = columnApi;
  };

  getTableApi = (tableApi) => {
    this.tableApi = tableApi;
    this.tableApi.setDatasource(this.getDataSource());
  };

  onPageSizeChange = (numberOfItems) => {
    const { setSelectedCreatives } = this.props;
    this.setState(
      {
        pageSize: numberOfItems,
        totalCreativesSelected: 0,
        creativeCountSummary: {
          staticCreatives: 0,
          motionCreatives: 0,
        },
      },
      () => {
        Session.setContentManagementPageSizeByKey(this.pageSizeKey, numberOfItems);
        setSelectedCreatives([]);
      },
    );
  };

  handleBulkEdit = () => {
    const { navigate, selectCreatives } = this.props;
    const { bulkSelectedCreatives } = this.state;

    navigate('/content-management/edit-creatives');

    selectCreatives(bulkSelectedCreatives);
  };

  handleBulkRestore = async () => {
    const { bulkSelectedCreatives } = this.state;
    const { cancelFunctions, notifyError } = this.props;

    try {
      checkResponseResultErrors(
        await restoreCreatives(cancelFunctions, {
          ids: bulkSelectedCreatives.map(getCreativeMarketId),
        }),
      );

      this.setState({ bulkSelectedCreatives: [] });
      this.getTableApi(this.tableApi);
    } catch (err) {
      notifyError({ message: err.message, timeout: NOTIFICATION_TIMEOUT.LONG });
    } finally {
      this.setState({ isRestoreModalOpen: false });
    }
  };

  render() {
    const { pageSize, totalCreativesSelected, creativeCountSummary, isRestoreModalOpen } = this.state;
    const {
      isCreativesSelected,
      filterData: { deleted },
    } = this.props;

    return (
      <>
        <div className="flex justify-between items-center mb-3">
          <PageSize
            dataTestId="page-size-selection"
            onPageSizeChange={this.onPageSizeChange}
            pageSize={pageSize}
            pageInfoText="creatives per page"
            listOfPageSizes={CONTENT_MANAGEMENT_PAGE_SIZES}
          />
          <div className="flex items-center space-x-3">
            {!deleted && (
              <>
                <CreativeCount totalCreatives={totalCreativesSelected} creativeCountSummary={creativeCountSummary} />
                <Button
                  dataTestId="bulk-edit-button"
                  onClick={this.handleBulkEdit}
                  btnShape={ButtonShape.NORMAL}
                  btnType={ButtonType.PRIMARY}
                  isDisabled={!isCreativesSelected}
                >
                  <SVG className="fill-current text-neutral-50" src={editIcon} />
                  Bulk edit
                </Button>
              </>
            )}
            {deleted && isCreativesSelected && Auth.hasPermission(PermissionsEnum.ADMINISTRATION_CREATIVE_RESTORE) && (
              <Button
                dataTestId="bulk-restore-button"
                onClick={() => this.setState({ isRestoreModalOpen: true })}
                btnShape={ButtonShape.NORMAL}
                btnType={ButtonType.SUCCESS}
              >
                <SVG src={restoreSvg} />
                Restore Creatives
              </Button>
            )}
          </div>
        </div>
        <Table
          dataTestId="creatives-table"
          id="creatives-table"
          key={pageSize}
          columnDefs={[
            ...this.checkboxSelectionColumn,
            ...this.editCreativeColumn,
            ...this.statusColumn(),
            ...this.creativeDataColumns,
          ]}
          getTableApi={this.getTableApi}
          getColumnApi={this.getColumnApi}
          extraGridOptions={{
            rowModelType: 'infinite',
            rowHeight: TableRowHeight.NORMAL,
            pagination: true,
            paginationPageSize: pageSize,
            cacheBlockSize: pageSize,
            maxBlocksInCache: 1,
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            suppressRowTransform: true,
            onSelectionChanged: this.onSelectionChanged,
            enableCellTextSelection: true,
            domLayout: 'autoHeight',
          }}
          tableSize={{ tableHeight: TableHeight.AUTO }}
        />
        <Modal
          isOpen={isRestoreModalOpen}
          icon={<SVG src={restoreSvg} className="text-primary" />}
          title="Restore Creatives?"
          actionButtons={
            <>
              <Button
                btnType={ButtonType.PRIMARY}
                btnShape={ButtonShape.NORMAL}
                onClick={() => {
                  this.setState({ isRestoreModalOpen: false });
                }}
                label="Cancel"
              >
                Cancel
              </Button>
              <Button
                btnType={ButtonType.DANGER}
                btnShape={ButtonShape.NORMAL}
                onClick={this.handleBulkRestore}
                label="Restore"
              >
                Restore
              </Button>
            </>
          }
        >
          <p className="ml-8 body-base text-neutral-950-opacity-60">
            Are you sure you want to restore these creatives?
          </p>
        </Modal>
      </>
    );
  }
}

Creatives.propTypes = {
  onFetchCreatives: PropTypes.func.isRequired,
  filterData: PropTypes.shape({
    deleted: PropTypes.bool,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  setSelectedCreatives: PropTypes.func.isRequired,
  isCreativesSelected: PropTypes.bool.isRequired,
  selectCreatives: PropTypes.func.isRequired,
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  notifyError: PropTypes.func.isRequired,
  localeCode: PropTypes.string,
  isReseller: PropTypes.bool,
};

Creatives.defaultProps = {
  localeCode: undefined,
  isReseller: false,
};

export default withLoader(withRouter(withCancelRequest(Creatives)));
