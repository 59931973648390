import Snackbar from 'react-toolbox/lib/snackbar';
import cx from 'classnames';
import { NotificationProps, SnackbarType } from './Notification.types';
import styles from './Notification.pcss';

export const snackbarType: Record<string, string> = {
  ERROR: 'cancel',
  INFO: 'info',
  SUCCESS: 'accept',
};

const Notification: React.FC<NotificationProps> = ({ message, onClick, onTimeout, timeout = 2000, type }) => {
  return (
    <Snackbar
      action="Dismiss"
      className={cx('body-base', { info: type === snackbarType.INFO })}
      label={message}
      onClick={onClick}
      timeout={timeout}
      onTimeout={onTimeout}
      active={!!message.length}
      type={(snackbarType[type] as SnackbarType) || snackbarType.SUCCESS}
      theme={styles}
    />
  );
};

export default Notification;
