import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { DealType } from 'components/common/types/Deal.types';
import useCampaignType from './useCampaignType';
import { MandatoryFieldsCheckResult } from './useCheckDirectSalesMandatoryFields';

export const useCheckProgrammaticMandatoryFields = (): MandatoryFieldsCheckResult => {
  const dsp = useSelector((state: Store) => state.dealManagement.programmatic.dsp);
  const dealName = useSelector((state: Store) => state.dealManagement.commonDeal.dealName);
  const advertiser = useSelector((state: Store) => state.dealManagement.commonDeal.advertiser);
  const brand = useSelector((state: Store) => state.dealManagement.commonDeal.brand);
  const productCategory = useSelector((state: Store) => state.dealManagement.commonDeal.productCategory);
  const cpm = useSelector((state: Store) => state.dealManagement.commonDeal.cpm);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const startDate = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.startDate);
  const endDate = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.endDate);
  const environments = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.environments);
  const impressions = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.impressions);
  const budget = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.budget);

  const { isMtbCampaignType, isProgrammaticCampaignType } = useCampaignType();

  const areDealInfoMandatoryFieldsFilled = useMemo(() => {
    return Object.values({
      startDate,
      endDate,
      advertiser,
      brand,
      productCategory,
      haveEnvironments: environments.length > 0,
      ...(isProgrammaticCampaignType ? { dealName } : {}),
      ...(isProgrammaticCampaignType ? { cpm } : {}),
      ...(isProgrammaticCampaignType || isMtbCampaignType ? { dealType } : {}),
      ...(isProgrammaticCampaignType || isMtbCampaignType ? { dsp } : {}),
    }).every(Boolean);
  }, [
    dealName,
    dealType,
    startDate,
    endDate,
    advertiser,
    brand,
    productCategory,
    dsp,
    environments,
    cpm,
    isMtbCampaignType,
  ]);

  const areAllocationMandatoryFieldsFilled = useMemo(() => {
    return Object.values({
      impressionsOrBudgetForGuaranteed: dealType !== DealType.GUARANTEED || Boolean(impressions || budget),
    }).every(Boolean);
  }, [dealType, impressions, budget]);

  const areAllMandatoryFieldsFilled = useMemo(() => {
    return Object.values({
      areDealInfoMandatoryFieldsFilled,
      areAllocationMandatoryFieldsFilled,
    }).every(Boolean);
  }, [areDealInfoMandatoryFieldsFilled, areAllocationMandatoryFieldsFilled]);

  return {
    areDealInfoMandatoryFieldsFilled,
    isAllocationTabEnabled: areDealInfoMandatoryFieldsFilled,
    isDataFiltersTabEnabled: areDealInfoMandatoryFieldsFilled,
    areAllocationMandatoryFieldsFilled,
    areAllMandatoryFieldsFilled,
  };
};
