import { DealType } from 'components/common/types/Deal.types';
import { getPattern } from 'components/pages/DealWithLines/common/Main/Line/Schedule/Pattern/daysInfo';
import { getDateInRequestFormat } from 'utils/dateUtil';
import { getVisualUnitData } from 'utils/framesUtils';
import {
  getFilesOfCurrentLine,
  getFilesToUpload,
  getTargetData,
  getCodes,
  getListData,
  getProximityData,
} from '../common/transformPostData';

export const getLineData = ({
  id,
  name,
  startDate,
  endDate,
  cpm,
  impressions,
  budget,
  sot,
  sotCeiling,
  sotFloor,
  sweep,
  frames,
  countries,
  counties,
  cities,
  streets,
  postCodes,
  environments,
  productFormats,
  selectedDays,
  patternLength,
  tags,
  listFiles,
  routeFrameCodes,
  visualUnitCodes,
  visualUnitFiles,
  proximity,
  venueTaxonomies,
  ceilingSot = null,
  floorSot = null,
}) => {
  return {
    ...(id ? { id } : {}),
    ...getTargetData({ impressions, budget, sot, sotCeiling, sotFloor, sweep, frames, ceilingSot, floorSot }),
    ...(countries?.length ? { countryCodes: getCodes(countries) } : {}),
    ...(counties?.length ? { countyCodes: getCodes(counties) } : {}),
    ...(cities?.length ? { cityCodes: getCodes(cities) } : {}),
    ...(streets?.length ? { streetCodes: getCodes(streets) } : {}),
    ...(postCodes?.length ? { postCodes: getCodes(postCodes) } : {}),
    ...(environments.length ? { environmentCodes: getCodes(environments) } : {}),
    ...(productFormats?.length ? { productFormatCodes: getCodes(productFormats) } : {}),
    ...(Object.values(selectedDays).length
      ? { pattern: { patternLength, pattern: getPattern(selectedDays, startDate), patternType: 1 } }
      : {}),
    ...(tags.length ? { tags: tags.map(({ code: tagCode, include }) => ({ tagCode, include })) } : {}),
    ...getListData(routeFrameCodes, listFiles, false),
    ...getVisualUnitData(visualUnitCodes, visualUnitFiles, false),
    ...(name ? { name } : {}),
    startDate: getDateInRequestFormat(startDate, [0, 0, 0, 0]),
    endDate: getDateInRequestFormat(endDate, [23, 59, 59, 999]),
    ...(cpm ? { floorCPM: cpm } : {}),
    ...getProximityData(proximity),
    ...(venueTaxonomies.length ? { venueTaxonomyEnumerationIds: venueTaxonomies } : {}),
  };
};

export const getDealData = ({
  internalId,
  dealName,
  cpm,
  dealType: dealTypeCode,
  dealPriority,
  auctionModel,
  bookingStatusCode,
  advertiser,
  brand,
  productCategory,
  dsp,
  agency,
  specialist,
  externalReference,
}) => {
  return {
    dealTypeCode,
    dealPriority,
    bookingStatusCode,
    advertiserCode: advertiser?.code,
    brandCode: brand?.code,
    productCategoryCode: productCategory?.code,
    ...(cpm ? { floorCPM: cpm } : {}),
    dspCode: dsp?.code,
    ...(internalId ? { internalId } : {}),
    ...(specialist?.code ? { specialistCode: specialist.code } : {}),
    ...(agency?.code ? { agencyCode: agency.code } : {}),
    ...(dealTypeCode === DealType.NON_GUARANTEED_FLOOR_PRICE ? { auctionModel } : {}),
    ...(dealName ? { dealName } : {}),
    ...(externalReference ? { externalReference } : {}),
  };
};

export const getDealWithLineFormData = (formData) => {
  const filesToUpload = getFilesToUpload(getFilesOfCurrentLine(formData.currentLine));

  const requestData = new FormData();

  Object.entries(filesToUpload).forEach(([fileName, file]) => {
    requestData.append(fileName, file);
  });

  requestData.append(
    'data',
    JSON.stringify({
      ...getDealData(formData),
      dealLines: [getLineData(formData.currentLine)],
    }),
  );

  return requestData;
};

export const getDealFormData = (formData) => {
  const requestData = new FormData();
  requestData.append('data', JSON.stringify(getDealData(formData)));

  return requestData;
};

export const getLineIdFormData = (id) => {
  const requestData = new FormData();
  requestData.append('data', JSON.stringify({ id }));

  return requestData;
};

export const getLineFormData = (formData) => {
  const filesToUpload = getFilesToUpload(getFilesOfCurrentLine(formData.currentLine));

  const requestData = new FormData();

  Object.entries(filesToUpload).forEach(([fileName, file]) => {
    requestData.append(fileName, file);
  });

  requestData.append(
    'data',
    JSON.stringify({
      internalId: formData.internalId,
      ...getLineData(formData.currentLine),
    }),
  );

  return requestData;
};

export const getLiveLineFormData = (formData) => {
  const filesToUpload = getFilesToUpload(getFilesOfCurrentLine(formData.currentLine));

  const requestData = new FormData();

  Object.entries(filesToUpload).forEach(([fileName, file]) => {
    requestData.append(fileName, file);
  });

  requestData.append(
    'data',
    JSON.stringify({
      terminate: {
        id: formData.currentLine.id,
      },
      create: {
        internalId: formData.internalId,
        ...getLineData(formData.currentLine),
        startDate: getDateInRequestFormat(new Date(), [0, 0, 0, 0]),
      },
    }),
  );

  return requestData;
};
