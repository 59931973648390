/* eslint-disable sonarjs/cognitive-complexity */
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import Badge, { BadgeSize } from 'components/patterns/Badge';
import SVG from 'react-inlinesvg';
import chevron_down from 'assets/icons/chevron_down.svg';
import clockSvg from 'assets/icons/clock.svg';
import checkmark from 'assets/icons/checkmark.svg';
import crossSvg from 'assets/icons/close.svg';
import cx from 'classnames';
import { formatDateTimeNoUTC } from 'modules/I18N';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { createPortal } from 'react-dom';
import { getResellerMediaOwnersCreativeStatus } from 'modules/api/reseller';
import { CreativeStatus } from '../types/Creative.types';

const ResellerCreativeStatus: FunctionComponent<{
  marketId: string;
  statusCode: string;
  portalAttachId?: string;
}> = ({ marketId, statusCode, portalAttachId }) => {
  const { localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const moderationLength = useSelector((state: RootState) => state.creative.moderation.length);
  const [statuses, setStatuses] = useState<{ publisher: string; status: CreativeStatus; updateStamp: number }[]>([]);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [yPosition, setYPosition] = useState(42);
  const [xPosition, setXPosition] = useState(0);
  const isDisabled = statuses.length === 0;
  const portalAttachElement = portalAttachId ? document.getElementById(portalAttachId) : null;

  const findButtonPosition = (element: Element): DOMRect => {
    if (element.tagName === 'BUTTON') {
      return element.getBoundingClientRect();
    }
    return findButtonPosition((element as Element).parentNode! as Element);
  };

  const configurePopover = (e: React.MouseEvent): void => {
    setIsDropDownOpen(!isDropDownOpen);
    if (portalAttachId) {
      const { bottom, left } = findButtonPosition(e.target as Element);
      setYPosition(bottom + window.scrollY);
      setXPosition(left);
    }
  };

  const statusCount = statuses?.reduce(
    (acc, cur) => {
      if (cur.status !== CreativeStatus.APPROVED && cur.status !== CreativeStatus.REJECTED) {
        acc[CreativeStatus.PENDING] += 1;
      } else if (cur.status === CreativeStatus.APPROVED) {
        acc[CreativeStatus.APPROVED] += 1;
      } else {
        acc[CreativeStatus.REJECTED] += 1;
      }
      return acc;
    },
    { [CreativeStatus.PENDING]: 0, [CreativeStatus.APPROVED]: 0, [CreativeStatus.REJECTED]: 0 },
  );

  const statusConfigs = {
    [CreativeStatus.PENDING]: { bgColor: 'bg-neutral-200', textColor: 'text-neutral-600', icon: clockSvg },
    [CreativeStatus.APPROVED]: { bgColor: 'bg-green-100', textColor: 'text-green-600', icon: checkmark },
    [CreativeStatus.REJECTED]: { bgColor: 'bg-red-100', textColor: 'text-red-600', icon: crossSvg },
  };

  const statusBadge = (status: keyof typeof statusConfigs): ReactElement => {
    return (
      <Badge
        color={statusConfigs[status].bgColor}
        textColor={statusConfigs[status].textColor}
        size={BadgeSize.SMALL_FIT}
      >
        <div className="w-full h-full relative block">
          <SVG src={statusConfigs[status].icon} className={cx('w-4 h-4 inline-block mr-1', {})} />
          {status}
        </div>
      </Badge>
    );
  };

  const dropdownElement = (): ReactElement => (
    <div
      className={cx(
        'absolute z-199 w-[550px] block bg-white p-3 text-left rounded-2xl mt-2 shadow-[0px_0px_0px_1px_rgba(0,0,0,0.02),0px_1px_1px_-0.5px_rgba(0,0,0,0.02),0px_3px_3px_-1.5px_rgba(0,0,0,0.02),_0px_6px_6px_-3px_rgba(0,0,0,0.02),0px_12px_12px_-6px_rgba(0,0,0,0.02),0px_24px_24px_-12px_rgba(0,0,0,0.02)]',
      )}
      style={{ top: `${yPosition}px`, left: `${xPosition}px` }}
    >
      <p className="text-essential-primary sub-header-lg px-2 pb-2">Creative ID {marketId}</p>
      <p className="text-essential-tertiary sub-header-base px-2 pb-2">Approval status</p>
      <table className="shadow-none">
        <tr className="border-b sub-header-sm text-essential-tertiary">
          <th className="p-2">Media owner</th>
          <th className="p-2">Status</th>
          <th className="p-2">Updated</th>
        </tr>
        {statuses.map((mediaOwnerStatus) => (
          <tr key={mediaOwnerStatus.updateStamp}>
            <td className="border-r w-1/3 p-2">{mediaOwnerStatus.publisher}</td>
            <td className="border-r w-1/3 p-2">{statusBadge(mediaOwnerStatus.status as keyof typeof statusConfigs)}</td>
            <td className="p-2">{formatDateTimeNoUTC(mediaOwnerStatus.updateStamp, localeCode)}</td>
          </tr>
        ))}
      </table>
    </div>
  );

  useEffect(() => {
    if (marketId) {
      (async () => {
        try {
          const response = await getResellerMediaOwnersCreativeStatus(marketId, {});
          setStatuses(response);
        } catch {
          setStatuses([]);
        }
      })();
    }
  }, [marketId, statusCode, moderationLength]);

  return (
    <div className="relative block h-full overflow-visible" onBlur={() => setIsDropDownOpen(false)}>
      <button
        type="button"
        className={cx('border-dashed border-2 rounded-xl flex relative', {
          'bg-primary-300 border-primary-400': isDropDownOpen,
          'bg-slate-50 border-neutral-300': !isDropDownOpen,
        })}
        onClick={(e) => configurePopover(e)}
        disabled={isDisabled}
      >
        <div className="dashed p-1 block">
          <span className="mr-2 ml-2 inline-block align-middle">
            {!isDisabled ? (
              <SVG
                src={chevron_down}
                className={cx('w-4 h-4 transition-all', {
                  'transform rotate-180': isDropDownOpen,
                  'transform rotate-0': !isDropDownOpen,
                })}
              />
            ) : (
              <div className="w-4 h-4" />
            )}
          </span>
          {Object.keys(statusConfigs).map((status: keyof typeof statusConfigs) => (
            <span className="mr-1" key={status}>
              <Badge
                color={statusConfigs[status].bgColor}
                textColor={statusConfigs[status].textColor}
                size={BadgeSize.SMALL_FIT}
              >
                <div className="w-full h-full relative block">
                  <SVG src={statusConfigs[status].icon} className="w-4 h-4 inline-block mr-1" />
                  <span className="inline-block" data-test-id={`status-count-${status}`}>
                    {statusCount[status]}
                  </span>
                </div>
              </Badge>
            </span>
          ))}
        </div>
      </button>
      {isDropDownOpen &&
        (portalAttachElement ? createPortal(dropdownElement(), portalAttachElement) : dropdownElement())}
    </div>
  );
};

export default ResellerCreativeStatus;
/* eslint-enable sonarjs/cognitive-complexity */
