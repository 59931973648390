import { v4 as uuidv4 } from 'uuid';

const transformPanelsData = (panels) => panels.map((panel) => ({ id: uuidv4(), ...panel }));

export const transformSetCreateData = ({ code, panels, dsp, advertiser, brand, visualUnitIds }) => {
  return {
    creativeSetCode: code,
    creativeIds: panels.map((panel) => panel.creative.id),
    dspCode: dsp?.code,
    advertiserCode: advertiser?.code,
    brandCode: brand?.code,
    visualUnitIds,
  };
};

export const transformSetUpdateData = ({ panels }) => ({ creativeIds: panels.map((panel) => panel.creative.id) });

export const transformVisualUnitData = (vuGroups) =>
  vuGroups
    .map((visualUnitGroup) => {
      const mappedVisualUnits =
        visualUnitGroup.visualUnits && visualUnitGroup.visualUnits.length > 0
          ? visualUnitGroup.visualUnits.map((visualUnit, idx) => ({
              panels: transformPanelsData(visualUnitGroup.panels),
              visualUnitCode: visualUnit.code,
              visualUnitIds: [visualUnit.id],
              visualUnitCodes: [visualUnit.code],
              checksum: [visualUnitGroup.checksum, visualUnit.id],
              isParent: false,
              isLastRow: idx === visualUnitGroup.visualUnits.length - 1,
              isFirstRow: idx === 0,
            }))
          : [];

      return [
        {
          panels: transformPanelsData(visualUnitGroup.panels),
          visualUnitCode: visualUnitGroup.visualUnits ? visualUnitGroup.visualUnits.length : 0,
          checksum: [visualUnitGroup.checksum],
          visualUnitIds: visualUnitGroup.visualUnitIds,
          isParent: true,
          isNoChildren: !mappedVisualUnits.length,
        },
        ...mappedVisualUnits,
      ];
    })
    .flat();

export const transformCreativeSetsData = (sets) =>
  sets.map((set) => ({ ...set, panels: transformPanelsData(set.panels) }));

export const transformSetDetails = ({ id, code, status, visualUnitIds, panels, dsp, advertiser, brand }) => {
  return {
    id,
    code,
    status,
    visualUnitIds,
    panels: transformPanelsData(panels),
    dsp,
    advertiser,
    brand,
  };
};
