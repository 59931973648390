import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ContentWithRightSidebar, { SIDEBAR_WIDTH } from 'components/common/layout/ContentWithRightSidebar';
import { resetAssetList } from 'store/pages/inventoryManagement/assetList/reducer';

import AssetContent from './AssetContent';
import AssetSidebar from './AssetSidebar';

const AssetList: React.FC = () => {
  const dispatch = useDispatch();

  useEffect((): void => {
    dispatch(resetAssetList());
  }, []);

  return (
    <ContentWithRightSidebar content={<AssetContent />} sidebar={<AssetSidebar />} sidebarWidth={SIDEBAR_WIDTH.LARGE} />
  );
};

export default AssetList;
