import { Timezone } from 'store/header/reducer';
import { DealDashboardFilters } from 'components/pages/Insights/DealDashboard/DealDashboardContent/Filters/Filters.types';
import {
  FetchBidRateChartResponse,
  FetchFillRateChartResponse,
  FetchRevenueChartResponse,
  FetchTradedWinRateDataResponse,
  FetchWeightedWinRateChartResponse,
} from 'components/common/types/DealDashboard.types';
import { CancelFunctions } from 'components/common/types';

export interface HeroChartsProps {
  filters: Partial<DealDashboardFilters>;
  getQueryString: (filters: Partial<DealDashboardFilters>, timezone: Timezone) => string;
  fetchRevenueChart: (cancelFunctions: CancelFunctions, query: string) => Promise<FetchRevenueChartResponse>;
  fetchBidRateChart: (cancelFunctions: CancelFunctions, query: string) => Promise<FetchBidRateChartResponse>;
  fetchTradedWinRateChart: (cancelFunctions: CancelFunctions, query: string) => Promise<FetchTradedWinRateDataResponse>;
  fetchWeightedWinRateChart: (
    cancelFunctions: CancelFunctions,
    query: string,
  ) => Promise<FetchWeightedWinRateChartResponse>;
  fetchFillRateChart: (cancelFunctions: CancelFunctions, query: string) => Promise<FetchFillRateChartResponse>;
  size?: Size;
  isCarousel?: boolean;
}

export enum Size {
  SMALL = 'lg:grid-cols-4',
  MEDIUM = 'lg:grid-cols-2',
}
