import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { notifyError } from 'store/notification/reducer';
import { FillRateDealData } from 'components/common/types/InsightsDeal';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { exportDealsReport } from 'modules/api/dealDashboard';
import { fetchPerformanceTable } from 'modules/api/performance';
import { dateCellRenderer, percentageCellRenderer } from 'utils/cellRenderer';
import CurrencyRenderer from 'components/common/CurrencyRenderer';
import NumberRenderer from 'components/common/NumberRenderer';
import { getFilterQueryString } from 'utils/reportingQueryString';
import { usePageFilters } from 'customHooks/usePageFilters';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { handleFillRate } from 'utils/fillRate';
import TableWithExport from 'components/common/TableWithExport';
import { ColumnDef, TableFilters } from 'components/common/types/Table.types';

const columnDefs = (currencyCode: string, localeCode: string): ColumnDef[] => [
  {
    headerName: 'Day',
    field: 'date',
    headerTooltip: 'Day',
    minWidth: 150,
    cellRenderer: (params) => dateCellRenderer({ params, localeCode }),
    sortable: true,
    sort: 'desc',
    sortingOrder: ['desc', 'asc'],
  },
  {
    headerName: 'Gross revenue',
    field: 'revenue',
    headerTooltip: 'Gross revenue',
    minWidth: 150,
    cellRenderer: ({ value }) => CurrencyRenderer({ value, currencyCode, localeCode }),
    sortable: true,
  },
  {
    headerName: 'Bid requests',
    field: 'bidRequests',
    headerTooltip: 'Bid requests',
    minWidth: 150,
    cellRenderer: ({ value }) => NumberRenderer({ value, localeCode }),
    sortable: true,
  },
  {
    headerName: 'Bid responses',
    field: 'bidResponses',
    headerTooltip: 'Bid responses',
    minWidth: 150,
    cellRenderer: ({ value }) => NumberRenderer({ value, localeCode }),
    sortable: true,
  },
  {
    headerName: 'Bid rate',
    field: 'bidRate',
    headerTooltip: 'Bid rate',
    cellRenderer: (params) => percentageCellRenderer(params, localeCode),
    minWidth: 150,
    sortable: true,
  },
  {
    headerName: 'Sold trades',
    field: 'soldTrades',
    headerTooltip: 'Sold trades',
    minWidth: 120,
    cellRenderer: ({ value }) => NumberRenderer({ value, localeCode }),
    sortable: true,
  },
  {
    headerName: 'Traded win rate',
    field: 'tradedWinRate',
    headerTooltip: 'Traded win rate',
    minWidth: 150,
    cellRenderer: (params) => percentageCellRenderer(params, localeCode),
    sortable: true,
  },
  {
    headerName: 'Lost trades',
    field: 'lostTrades',
    headerTooltip: 'Lost trades',
    minWidth: 150,
    cellRenderer: ({ value }) => NumberRenderer({ value, localeCode }),
    sortable: true,
  },
  {
    headerName: 'Lost trade %',
    field: 'lostTradesPercentage',
    headerTooltip: 'Lost trade %',
    minWidth: 150,
    cellRenderer: (params) => percentageCellRenderer(params, localeCode),
    sortable: true,
  },
  {
    headerName: 'Supply CPM',
    field: 'supplyCpm',
    headerTooltip: 'Supply CPM',
    minWidth: 150,
    cellRenderer: ({ value }) => CurrencyRenderer({ value, currencyCode, localeCode }),
    sortable: true,
  },
  {
    headerName: 'Demand CPM',
    field: 'demandCpm',
    headerTooltip: 'Demand CPM',
    minWidth: 150,
    cellRenderer: ({ value }) => CurrencyRenderer({ value, currencyCode, localeCode }),
    sortable: true,
  },
  {
    headerName: 'eCPM',
    field: 'ecpm',
    headerTooltip: 'eCPM',
    minWidth: 150,
    cellRenderer: ({ value }) => CurrencyRenderer({ value, currencyCode, localeCode }),
    sortable: true,
  },
  {
    headerName: 'Offered impressions',
    field: 'offeredImpressions',
    headerTooltip: 'Offered impressions',
    minWidth: 200,
    cellRenderer: ({ value }) => NumberRenderer({ value, localeCode }),
    sortable: true,
  },
  {
    headerName: 'Responded impressions',
    field: 'respondedImpressions',
    headerTooltip: 'Responded impressions',
    minWidth: 200,
    cellRenderer: ({ value }) => NumberRenderer({ value, localeCode }),
    sortable: true,
  },
  {
    headerName: 'Sold impressions',
    field: 'soldImpressions',
    headerTooltip: 'Sold impressions',
    minWidth: 150,
    cellRenderer: ({ value }) => NumberRenderer({ value, localeCode }),
    sortable: true,
  },
  {
    headerName: 'Fill rate',
    field: 'fillRate',
    headerTooltip: 'Fill rate',
    minWidth: 200,
    cellRenderer: (params) => percentageCellRenderer(params, localeCode),
    sortable: true,
  },
  {
    headerName: 'Impression bid rate',
    field: 'impressionBidRate',
    headerTooltip: 'Impression bid rate',
    minWidth: 200,
    cellRenderer: (params) => percentageCellRenderer(params, localeCode),
    sortable: true,
  },
  {
    headerName: 'Weighted win rate',
    field: 'weightedWinRate',
    headerTooltip: 'Weighted win rate',
    minWidth: 150,
    cellRenderer: (params) => percentageCellRenderer(params, localeCode),
    sortable: true,
  },
  {
    headerName: 'Lost impressions',
    field: 'lostImpressions',
    headerTooltip: 'Lost impressions',
    minWidth: 150,
    sortable: true,
    cellRenderer: ({ value }) => NumberRenderer({ value, localeCode }),
    resizable: true,
  },
  {
    headerName: 'Lost impressions %',
    field: 'lostImpressionsPercentage',
    headerTooltip: 'Lost impressions %',
    minWidth: 250,
    sortable: true,
    cellRenderer: (params) => percentageCellRenderer(params, localeCode),
    resizable: false,
  },
];

const PerformanceTable: React.FC = () => {
  const dispatch = useDispatch();
  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const timezone = useSelector((state: RootState) => state.header.timezone);
  const { filters } = usePageFilters<TableFilters>('insights/dashboard');
  const cancelFunctions = useCancelRequest();

  const [tableData, setTableData] = useState<FillRateDealData[]>([]);

  useEffect(() => {
    const fetchPerformanceTableData = async (): Promise<void> => {
      try {
        const { data } = await fetchPerformanceTable(cancelFunctions, getFilterQueryString(filters, timezone));

        setTableData(handleFillRate(data));
      } catch (error) {
        dispatch(notifyError({ message: error.message, timeout: NOTIFICATION_TIMEOUT.LONG }));
        setTableData([]);
      }
    };

    fetchPerformanceTableData();
  }, [filters, timezone]);

  return (
    <div className="mb-4">
      <TableWithExport
        dataTestId="performance-table"
        columnDefs={columnDefs(currencyCode, localeCode)}
        rowData={tableData}
        onExportTable={() => exportDealsReport(getFilterQueryString(filters, timezone))}
      />
    </div>
  );
};

export default PerformanceTable;
