import { ReactElement } from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';
import IconButton from 'lib/IconButton';
import close from 'assets/icons/close.svg';
import { OverlayProps } from './Overlay.types';

export const OverlayStyle = {
  dark: {
    backgroundColor: 'bg-primary-500',
    textColor: 'text-neutral-100',
    contentBackgroundColor: 'bg-neutral-100-opacity-10',
  },
  light: {
    backgroundColor: 'bg-neutral-200',
    textColor: 'text-neutral-950',
    contentBackgroundColor: 'bg-neutral-50',
  },
};

const Overlay = ({
  isOpen,
  onClose,
  theme = 'light',
  children,
  header,
  overflowYScroll = false,
}: OverlayProps): ReactElement => {
  return (
    <ReactModal
      className={cx(
        'absolute flex flex-col gap-4 p-4 w-[95%] h-[95%] transform -translate-y-2/4 -translate-x-2/4 top-1/2 left-1/2 rounded-lg bg-clip-border',
        OverlayStyle[theme].backgroundColor,
        OverlayStyle[theme].textColor,
        {
          'overflow-y-scroll': overflowYScroll,
        },
      )}
      onRequestClose={onClose}
      isOpen={isOpen}
      overlayClassName="bg-modalOverlay-dark"
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <div className={cx('w-full flex justify-between items-center', OverlayStyle[theme].backgroundColor)}>
        {header}
        <IconButton name="close" icon={close} handleOnClick={onClose} />
      </div>
      <div className={cx('h-full', OverlayStyle[theme].contentBackgroundColor)}>{children}</div>
    </ReactModal>
  );
};

export default Overlay;
