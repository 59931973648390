import PropTypes from 'prop-types';

import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import Chip from './Chip/Chip';

const Chips = ({
  idKey,
  labelKey,
  dataList,
  isClearAllVisible,
  excludeKey,
  onIncludeExcludeChange,
  onRemove,
  onRemoveAll,
  isDisabled,
  dataTestId,
}) => {
  const getChips = () =>
    dataList.map((item) => (
      <Chip
        key={item[idKey]}
        labelKey={labelKey}
        item={item}
        onRemove={onRemove}
        excludeKey={excludeKey}
        onIncludeExcludeChange={onIncludeExcludeChange}
        isDisabled={isDisabled}
      />
    ));

  return (
    <div className={`row min-w-0 ${isDisabled ? 'readOnly readOnlyOpacity' : ''}`} data-test-id={dataTestId}>
      <div className="col">{getChips()}</div>
      {isClearAllVisible && !isDisabled ? (
        <div className="col-auto">
          <Button onClick={() => onRemoveAll()} btnShape={ButtonShape.NORMAL} btnType={ButtonType.LIGHT}>
            Clear
          </Button>
        </div>
      ) : null}
    </div>
  );
};

Chips.propTypes = {
  idKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  labelKey: PropTypes.string.isRequired,
  dataList: PropTypes.arrayOf(PropTypes.object),
  isClearAllVisible: PropTypes.bool,
  excludeKey: PropTypes.string,
  onIncludeExcludeChange: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  isDisabled: PropTypes.bool,
  dataTestId: PropTypes.string,
};

Chips.defaultProps = {
  dataList: [],
  isClearAllVisible: true,
  excludeKey: '',
  onIncludeExcludeChange: () => undefined,
  onRemove: () => undefined,
  onRemoveAll: () => undefined,
  isDisabled: false,
  dataTestId: '',
};

export default Chips;
