import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { FormatTypeEnum } from 'modules/I18N';
import { getIsAdServer } from 'store/publisher/selectors';
import { getBackupCurrentLineAllocation } from 'store/dealManagement/selectors';
import { handleValueFormat } from 'components/pages/DealWithLines/common/RightSidebar/utils';
import { DealStatus, DealType } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import { useCheckProgrammaticLineLevelChange } from 'components/pages/Planner/hooks/useCheckProgrammaticLineLevelChange';
import { DealSummaryCard } from '../DealSummary/DealSummaryCard/DealSummaryCard';
import DealSummaryCardSection from '../DealSummary/DealSummaryCardSection';
import { DealSummaryCardSectionLineType } from '../DealSummary/DealSummaryCardSection/DealSummaryCardSection.types';
import CheckAllocationButton from '../DealSummary/CheckAllocationButton';
import { DeliveryCard } from '../Cards/DeliveryCard/DeliveryCard';
import { AvailabilityCard } from '../Cards/AvailabilityCard/AvailabilityCard';
import { ProposalCard } from '../Cards/ProposalCard/ProposalCard';
import AllocationCard from '../Cards/AllocationCard';
import { getDealSummaryCardVisibility, getLineSummaryCardVisibility } from '../Cards/selectors';

export const DealSummaryLineInfo: React.FC = () => {
  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const backupCurrentLineAllocation = useSelector(getBackupCurrentLineAllocation);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const availability = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.availability);
  const impressions = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.impressions);
  const budget = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.budget);
  const frames = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frames);
  const deliveredImpressions = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.deliveredImpressions,
  );
  const currentRemainingImpressions = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.currentRemainingImpressions,
  );
  const currentLineAssets = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.availability.assets,
  );

  const isAdServerMarket = useSelector(getIsAdServer);
  const isGuaranteedAds = dealType === DealType.GUARANTEED && isAdServerMarket;
  const { isCheckAllocationButtonVisible } = useSelector(getDealSummaryCardVisibility);
  const isLineLevelChange = useCheckProgrammaticLineLevelChange();
  const { isLineProposalCardVisible, isLineAvailabilityCardVisible, isLineDeliveryCardVisible } =
    useSelector(getLineSummaryCardVisibility);
  const showGoalCard = isGuaranteedAds;

  return (
    <div className="flex flex-col gap-3 mb-[12px]" data-test-id="deal-summary-line-info">
      <AllocationCard
        allocatedFrames={backupCurrentLineAllocation.allocatedFrames}
        allocatedImpressions={backupCurrentLineAllocation.allocatedImpressions}
        assets={backupCurrentLineAllocation.assets}
        totalCost={backupCurrentLineAllocation.totalCost}
      />
      <ProposalCard
        allocatedFrames={availability.allocatedFrames}
        allocatedImpressions={availability.allocatedImpressions}
        assets={availability.assets}
        availableFrames={availability.availableFrames}
        availableImpressions={availability.availableImpressions}
        totalCost={availability.totalCost}
        isAvailabilityHidden={bookingStatusCode === DealStatus.PENDING_APPROVAL && !isLineLevelChange}
        isVisible={isLineProposalCardVisible}
      />
      <AvailabilityCard
        assets={currentLineAssets}
        availableImpressions={availability.availableImpressions}
        availableFrames={availability.availableFrames}
        totalCost={availability.totalCost}
        isVisible={isLineAvailabilityCardVisible}
      />
      {isCheckAllocationButtonVisible ? <CheckAllocationButton /> : null}
      <DeliveryCard
        currentRemainingImpressions={currentRemainingImpressions}
        deliveredImpressions={deliveredImpressions}
        isVisible={isLineDeliveryCardVisible}
      />
      <DealSummaryCard header="GOAL" isVisible={showGoalCard}>
        <DealSummaryCardSection
          id="budget-or-impressions"
          lines={[
            {
              label: budget ? 'Budget' : 'Impressions',
              value:
                (!!budget &&
                  handleValueFormat({
                    valueType: FormatTypeEnum.ROUNDED_NUMBER,
                    value: budget,
                    currencyCode,
                    localeCode,
                  })) ||
                (!!impressions &&
                  handleValueFormat({
                    valueType: FormatTypeEnum.ROUNDED_NUMBER,
                    value: impressions,
                    currencyCode,
                    localeCode,
                  })) ||
                '--',
              lineType: DealSummaryCardSectionLineType.SUBHEADER,
            },
          ]}
        />
        <DealSummaryCardSection
          id="frames"
          lines={[
            {
              label: 'Frames',
              value:
                ((!!impressions || !!budget) &&
                  !!frames &&
                  handleValueFormat({
                    valueType: FormatTypeEnum.ROUNDED_NUMBER,
                    value: frames,
                    currencyCode,
                    localeCode,
                  })) ||
                '--',
              lineType: DealSummaryCardSectionLineType.SUBHEADER,
            },
          ]}
        />
      </DealSummaryCard>
    </div>
  );
};
