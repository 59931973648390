/* eslint-disable sonarjs/cognitive-complexity */
import { Component } from 'react';
import PropTypes from 'prop-types';
import Auth from 'modules/Auth';
import Session from 'modules/Session';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import SVG from 'react-inlinesvg';
import classNames from 'classnames/bind';
import { PermissionsEnum } from 'consts/permissions';
import withRouter from 'components/hocs/withRouter';
import withLoader from 'components/hocs/withLoader';
import Modal from 'components/patterns/Modal';
import createRequest from 'modules/api/request';
import { formatDateNoUTC, formatDateTime, formatDateTimeNoUTC, formatTime } from 'modules/I18N';
import { getContentDealIdLookupData } from 'modules/api/lookups';
import {
  addCreativeDeal,
  deleteCreative,
  fetchCreativeDetails,
  getAllFrameTags,
  getCreativeCategories,
  getCreativeFrameSummary,
  removeCreativeDeal,
  restoreCreative,
} from 'modules/api/content';
import Input from 'lib/Input';
import withCancelRequest from 'components/hocs/withCancelRequest';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { HEADERS_CONTENT_TYPE, HTTP_METHODS, URLS } from 'modules/api/constants';
import TaggingRules from 'components/pages/ContentManagement/Preview/PreviewContent/TaggingRules';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import Card from 'components/patterns/Card';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import { CreativeStatus } from 'components/common/types/Creative.types';
import { MOTION_TYPE_INFO } from 'consts/motionType';
import checkmarkSvg from 'assets/icons/checkmark.svg';
import flagSvg from 'assets/icons/flag.svg';
import binSvg from 'assets/icons/bin.svg';
import restoreSvg from 'assets/icons/restore.svg';
import handSvg from 'assets/icons/hand.svg';
import heartSvg from 'assets/icons/heart.svg';
import hourglassSvg from 'assets/icons/hourglass.svg';
import closeSvg from 'assets/icons/close.svg';
import arrowRightSvg from 'assets/icons/arrow_right.svg';
import ResellerCreativeStatus from 'components/common/ResellerCreativeStatus/ResellerCreativeStatus';
import RequiredAsterisk from 'components/common/RequiredAsterisk';
import Chips from 'components/patterns/Chips';
import CreativePreview from './CreativePreview/CreativePreview';
import ResellerEligibleMediaOwners from './ResellerEligibleMediaOwners/ResellerEligibleMediaOwners';

import styles from './PreviewContent.pcss';

const cx = classNames.bind(styles);

const getModerationStatusIcon = (status, type) => {
  let moderationStatusHTML = '';

  const modIcon = (color, icon) => (
    <figure className={`${color} w-10 h-10 mr-2 rounded-full inline-block align-top relative`}>
      <SVG src={icon} className="fill-current text-neutral-50 absolute top-2.5 right-2.5" />
    </figure>
  );

  switch (status || type) {
    case CreativeStatus.APPROVED:
      moderationStatusHTML = modIcon('bg-green', checkmarkSvg);
      break;
    case CreativeStatus.REJECTED:
      moderationStatusHTML = modIcon('bg-pinkRed', handSvg);
      break;
    case CreativeStatus.FLAGGED:
      moderationStatusHTML = modIcon('bg-yellow', flagSvg);
      break;
    case CreativeStatus.PENDING:
      moderationStatusHTML = modIcon('bg-neutral-600', hourglassSvg);
      break;
    case CreativeStatus.SENT_FOR_APPROVAL:
      moderationStatusHTML = modIcon('bg-neutral-600', hourglassSvg);
      break;
    case CreativeStatus.DELETED:
      moderationStatusHTML = modIcon('bg-pinkRed', binSvg);
      break;
    case CreativeStatus.RESTORED:
      moderationStatusHTML = modIcon('bg-green', restoreSvg);
      break;
    default:
      break;
  }

  return moderationStatusHTML;
};

const getModerationStatusHTML = (status, type, updatedBy, user) => {
  let moderationStatusHTML = '';

  switch (status || type) {
    case CreativeStatus.APPROVED:
      moderationStatusHTML = (
        <p>
          <span className="sub-header-base text-success">Approved</span>
          <span className="body-base text-secondary"> by </span>
          <span className="body-base">{updatedBy.code}</span>
        </p>
      );
      break;
    case CreativeStatus.REJECTED:
      moderationStatusHTML = (
        <p>
          <span className="sub-header-base text-danger">Rejected</span>
          <span className="body-base text-secondary"> by </span>
          <span className="body-base">{updatedBy.code}</span>
        </p>
      );
      break;
    case CreativeStatus.FLAGGED:
      moderationStatusHTML = (
        <p>
          <span className="sub-header-base text-warning">Flagged</span>
          <span className="body-base text-secondary"> by </span>
          <span className="body-base">{updatedBy.code}</span>
        </p>
      );
      break;
    case CreativeStatus.PENDING:
      moderationStatusHTML = (
        <p>
          <span className="sub-header-base text-secondary">Pending approval</span>
          <span className="body-base text-secondary"> by </span>
          <span className="body-base">{updatedBy.code}</span>
        </p>
      );
      break;
    case CreativeStatus.SENT_FOR_APPROVAL:
      moderationStatusHTML = (
        <p>
          <span className="sub-header-base text-secondary">Sent for approval</span>
          <span className="body-base text-secondary"> by </span>
          <span className="body-base">{updatedBy.code}</span>
        </p>
      );
      break;
    case CreativeStatus.DELETED:
      moderationStatusHTML = (
        <p>
          <span className="sub-header-base text-secondary">Deleted</span>
          <span className="body-base text-secondary"> by </span>
          <span className="body-base">{user.code}</span>
        </p>
      );
      break;
    case CreativeStatus.RESTORED:
      moderationStatusHTML = (
        <p>
          <span className="sub-header-base text-secondary">Restored</span>
          <span className="body-base text-secondary"> by </span>
          <span className="body-base">{user.code}</span>
        </p>
      );
      break;
    default:
      break;
  }

  return moderationStatusHTML;
};

const descSorted = (combineAuditDetails = []) =>
  combineAuditDetails.sort((a, b) => new Date(b.updateStamp || b.createdAt) - new Date(a.updateStamp || a.createdAt));

const combineAuditDetails = (moderation = [], auditLogs = []) => descSorted([...moderation, ...auditLogs]);

class PreviewContent extends Component {
  state = {
    showModal: false,
    showDeleteDialog: false,
    showRestoreDialog: false,
    rejectReason: '',
    isFetchingCreativeDetails: false,
    combinedAuditDetails: [],
  };

  componentDidMount = async () => {
    await this.fetchCreativesDetails();
  };

  componentWillUnmount = () => {
    const { resetCreativeDetails } = this.props;

    resetCreativeDetails();
  };

  fetchCreativesDetails = async () => {
    const {
      params: { id: creativeId },
      cancelFunctions,
      setCreativeDetails,
      localeCode,
    } = this.props;

    this.setState({
      isFetchingCreativeDetails: true,
    });

    try {
      const creativeDetails = await fetchCreativeDetails(cancelFunctions, creativeId);
      const currentMarket = creativeDetails?.market.find(
        ({ environment }) => environment === Session.getEnvironmentId(),
      );

      const combinedAuditDetails = combineAuditDetails(currentMarket.moderation, currentMarket.auditLogs);

      this.setState({
        combinedAuditDetails,
      });

      const additionalProductCategoriesOptions = await getCreativeCategories();
      const productCategory = currentMarket?.commercial?.productCategory;
      const processedAdditionalProductCategoriesOptions = productCategory?.id
        ? additionalProductCategoriesOptions.filter((category) => category.id !== productCategory.id)
        : [...additionalProductCategoriesOptions];
      const commercialId = currentMarket?.commercial?.id;
      const brand = currentMarket?.commercial?.brand;
      const marketId = currentMarket?.id;
      const tags = marketId ? await getAllFrameTags(cancelFunctions, marketId) : [];

      const processedCreativeDetails = {
        marketId,
        deleted: currentMarket?.deleted,
        deletedAt: currentMarket?.deletedAt,
        convertStatus: creativeDetails?.convertStatus,
        statusCode: currentMarket?.moderation[0].status,
        dealId: currentMarket?.deal?.map((d) => ({ code: d.id, name: d.dealId, inherited: d.inherited })) ?? null,
        productFormat: currentMarket?.productFormat,
        moderation: currentMarket?.moderation,
        commercialId,
        externalAdvertiserName: creativeDetails.externalAdvertiserName,
        clickThroughUrl: creativeDetails.clickThroughUrl,
        externalAgencyId: creativeDetails.externalAgencyId,
        advertiser: currentMarket?.commercial?.advertiser,
        frameTags: tags?.map((tag) => ({ ...tag.tag, type: tag.type, marketTagId: tag.id })),
        brand,
        productCategory,
        additionalProductCategories: currentMarket?.productCategory,
        agency: currentMarket?.agency,
        specialist: currentMarket?.specialist,
        externalId: creativeDetails.externalId,
        dsp: creativeDetails.dsp,
        width: creativeDetails.width,
        height: creativeDetails.height,
        uploaded: creativeDetails.uploaded,
        uploadedBy: creativeDetails.uploadedBy?.name,
        uploadedDate: formatDateNoUTC(creativeDetails.uploaded, localeCode),
        uploadedTime: formatTime(creativeDetails.uploaded, localeCode),
        movement: creativeDetails.movement,
        contentURL: creativeDetails.contentURL,
        downloadURL: creativeDetails.downloadURL,
        thumbnail: creativeDetails.thumbnail,
        type: creativeDetails.type,
        additionalProductCategoriesOptions: processedAdditionalProductCategoriesOptions,
        allAdditionalProductCategoriesOptions: additionalProductCategoriesOptions,
        market: creativeDetails.market,
      };

      if (commercialId) {
        processedCreativeDetails.productCategoriesOptions = productCategory ? [productCategory] : [];
      }

      setCreativeDetails(processedCreativeDetails);

      await this.getFrameSummary();
    } catch (error) {
      const { notifyError } = this.props;
      notifyError({ message: error.message });
    }

    this.setState({
      isFetchingCreativeDetails: false,
    });
  };

  openModal = () => {
    this.setState({
      showModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  onDelete = async () => {
    const { cancelFunctions, marketId } = this.props;

    try {
      await deleteCreative(cancelFunctions, marketId);
      this.fetchCreativesDetails();
    } finally {
      this.setState({ showDeleteDialog: false });
    }
  };

  onRestore = async () => {
    const { cancelFunctions, marketId } = this.props;

    try {
      await restoreCreative(cancelFunctions, marketId);
      this.fetchCreativesDetails();
    } finally {
      this.setState({ showRestoreDialog: false });
    }
  };

  onRejectCreative = async () => {
    const { cancelFunctions, notifySuccess, notifyError, marketId } = this.props;
    const { rejectReason } = this.state;

    try {
      const { send, cancel } = createRequest({
        url: URLS().REJECT_CREATIVE(marketId),
        method: HTTP_METHODS.POST,
        headers: {
          'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
        },
        data: JSON.stringify({
          auth0Identifier: Session.getEnvironmentId(),
          reason: rejectReason,
        }),
      });

      cancelFunctions.REJECT_CREATIVE = cancel;

      await send();

      notifySuccess({ message: 'Creative Rejected' });
      const { navigate } = this.props;
      // eslint-disable-next-line sonarjs/no-duplicate-string
      navigate('/content-management/creatives');
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  onApproveCreative = async () => {
    const { cancelFunctions, notifySuccess, notifyError, marketId } = this.props;

    try {
      const { send, cancel } = createRequest({
        url: URLS().APPROVE_CREATIVE(marketId),
        method: HTTP_METHODS.POST,
        headers: {
          'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
        },
        data: JSON.stringify({
          auth0Identifier: Session.getEnvironmentId(),
        }),
      });

      cancelFunctions.APPROVE_CREATIVE = cancel;

      await send();

      notifySuccess({ message: 'Creative Approved' });
      const { navigate } = this.props;
      navigate('/content-management/creatives');
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  isPendingApprovalOrRejected = () => {
    const { statusCode } = this.props;

    return statusCode === CreativeStatus.PENDING || statusCode === CreativeStatus.REJECTED;
  };

  onFlagCreative = async () => {
    const { cancelFunctions, notifySuccess, notifyError, marketId } = this.props;

    try {
      const { send, cancel } = createRequest({
        url: URLS().FLAG_CREATIVE(marketId),
        method: HTTP_METHODS.POST,
      });

      cancelFunctions.FLAG_CREATIVE = cancel;

      await send();

      notifySuccess({ message: 'Creative Flagged' });

      await this.fetchCreativesDetails();
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  onSendForApproval = async () => {
    const { cancelFunctions, notifySuccess, notifyError, marketId } = this.props;

    try {
      const { send, cancel } = createRequest({
        url: URLS().SEND_CREATIVE_FOR_APPROVAL(marketId),
        method: HTTP_METHODS.POST,
      });

      cancelFunctions.SEND_CREATIVE_FOR_APPROVAL = cancel;

      await send();

      notifySuccess({ message: 'Creative sent for approval' });

      await this.fetchCreativesDetails();
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  onPendingApprovalCreative = async () => {
    const { cancelFunctions, notifySuccess, notifyError, marketId } = this.props;

    try {
      const { send, cancel } = createRequest({
        url: URLS().PENDING_APPROVE_CREATIVE(marketId),
        method: HTTP_METHODS.POST,
        headers: {
          'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
        },
        data: JSON.stringify({
          auth0Identifier: Session.getEnvironmentId(),
        }),
      });

      cancelFunctions.PENDING_APPROVE_CREATIVE = cancel;

      await send();

      notifySuccess({ message: 'Creative Pending approval' });
      const { navigate } = this.props;
      navigate('/content-management/creatives');
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  onChangeRejectReason = (value) => {
    this.setState({
      rejectReason: value,
    });
  };

  getButtons(readOnly) {
    const { marketId, moderation, advertiser, brand, isReseller, dealId } = this.props;
    const status = moderation[0]?.status ?? CreativeStatus.PENDING;

    if (!marketId) {
      return null;
    }

    if (isReseller) {
      if (status === CreativeStatus.PENDING || status === CreativeStatus.SENT_FOR_APPROVAL) {
        return (
          <>
            <div className={cx('status-buttons', 'mt-2')}>
              <Button
                onClick={this.onSendForApproval}
                btnShape={ButtonShape.NORMAL}
                btnType={ButtonType.PRIMARY}
                isDisabled={readOnly || dealId.length === 0}
                dataTestId="creative-status-send-for-approval-btn"
              >
                <SVG src={arrowRightSvg} />
                Send for approval
              </Button>
            </div>
            <div className={cx('status-buttons', 'mt-2')}>
              <Button
                onClick={this.openModal}
                btnShape={ButtonShape.NORMAL}
                btnType={ButtonType.DANGER}
                isDisabled={readOnly || status === CreativeStatus.SENT_FOR_APPROVAL}
              >
                <SVG src={handSvg} />
                Reject
              </Button>
            </div>
          </>
        );
      }
      if (status === CreativeStatus.REJECTED) {
        return (
          <div className={cx('status-buttons', 'mt-2')}>
            <Button
              onClick={() => {
                this.setState({ showDeleteDialog: true });
              }}
              btnShape={ButtonShape.NORMAL}
              btnType={ButtonType.PRIMARY}
            >
              <SVG src={binSvg} />
              Delete creative
            </Button>
          </div>
        );
      }
      return null;
    }

    let buttonsHTML = '';

    switch (status) {
      case CreativeStatus.APPROVED:
      case CreativeStatus.REJECTED:
        buttonsHTML = (
          // eslint-disable-next-line sonarjs/no-duplicate-string
          <div className={cx('status-buttons')}>
            {Auth.hasPermission(PermissionsEnum.CONTENT_REVERT_STATUS) && (
              <Button
                onClick={this.onPendingApprovalCreative}
                btnShape={ButtonShape.NORMAL}
                btnType={ButtonType.WARNING}
                isDisabled={readOnly}
                dataTestId="creative-status-pending-btn"
              >
                <SVG src={hourglassSvg} />
                Pending approval
              </Button>
            )}
          </div>
        );
        break;
      default:
        buttonsHTML = (
          <>
            {Auth.hasPermission(PermissionsEnum.CONTENT_APPROVE) && (
              <div className={cx('status-buttons')}>
                <Button
                  onClick={this.openModal}
                  btnShape={ButtonShape.NORMAL}
                  btnType={ButtonType.DANGER}
                  isDisabled={readOnly}
                  dataTestId="creative-status-reject-btn"
                >
                  <SVG src={handSvg} />
                  Reject
                </Button>
                <Button
                  onClick={this.onApproveCreative}
                  btnShape={ButtonShape.NORMAL}
                  btnType={ButtonType.SUCCESS}
                  isDisabled={!advertiser.id || !brand.id || readOnly}
                >
                  <SVG src={heartSvg} />
                  Approve
                </Button>
              </div>
            )}
            <div className={cx('status-buttons', 'mt-2')}>
              {status !== CreativeStatus.FLAGGED && Auth.hasPermission(PermissionsEnum.CONTENT_FLAG) && (
                <Button
                  onClick={this.onFlagCreative}
                  btnShape={ButtonShape.NORMAL}
                  btnType={ButtonType.WARNING}
                  isDisabled={readOnly}
                  dataTestId="creative-status-flag-btn"
                >
                  <SVG src={flagSvg} />
                  Flag
                </Button>
              )}
              {status !== CreativeStatus.PENDING && Auth.hasPermission(PermissionsEnum.CONTENT_REVERT_STATUS) && (
                <Button
                  onClick={this.onPendingApprovalCreative}
                  btnShape={ButtonShape.NORMAL}
                  btnType={ButtonType.WARNING}
                  isDisabled={readOnly}
                >
                  <SVG src={hourglassSvg} />
                  Pending approval
                </Button>
              )}
            </div>
          </>
        );
        break;
    }

    return buttonsHTML;
  }

  onLoadDealIdLookupData = async (_dropdownName, query) => {
    const { cancelFunctions, dsp } = this.props;

    if (query.length >= 2) {
      try {
        return await getContentDealIdLookupData(cancelFunctions, Session.getEnvironmentId(), query, dsp?.code);
      } catch {
        return [];
      }
    }

    return [];
  };

  onSelectDealId = async (_name, selectedValue) => {
    const { cancelFunctions, marketId, ownDealIds, inheritedDealIds, updateCreativeProperties, market } = this.props;

    const dealIdsToRemove = differenceWith(ownDealIds, selectedValue, isEqual).map((deal) => deal.code);
    const dealIdToAdd = differenceWith(selectedValue, ownDealIds, isEqual)[0]?.code;

    try {
      if (dealIdToAdd) {
        const result = await addCreativeDeal(cancelFunctions, marketId, dealIdToAdd);
        const currentMarketUpdated = result.market.find(
          (marketItem) => marketItem.environment === Session.getEnvironmentId(),
        );
        const newDealId = currentMarketUpdated.deal?.map((d) => ({ code: d.id, name: d.dealId })) ?? null;

        updateCreativeProperties({ dealId: newDealId, market: result.market });
      } else if (dealIdsToRemove.length) {
        await Promise.all(
          dealIdsToRemove.map(async (id) => {
            await removeCreativeDeal(cancelFunctions, id);
          }),
        );

        const updatedMarket = market.map((marketObj) => {
          let updatedDeal = [...marketObj.deal];
          if (marketObj.environment === Session.getEnvironmentId()) {
            updatedDeal = marketObj.deal.filter((d) => !dealIdsToRemove.includes(d.id));
          }
          return { ...marketObj, deal: updatedDeal };
        });
        updateCreativeProperties({ dealId: [...inheritedDealIds, ...selectedValue], market: updatedMarket });
      }
    } catch {} // eslint-disable-line no-empty
  };

  getFrameSummary = async () => {
    const {
      params: { id: creativeId },
      cancelFunctions,
      notifyError,
      setFrameSummary,
    } = this.props;

    if (!Auth.hasPermission(PermissionsEnum.CONTENT_WHITELIST_BACKLIST)) {
      return;
    }

    this.setState({
      isFetchingCreativeDetails: true,
    });

    try {
      const frameSummary = await getCreativeFrameSummary(cancelFunctions, creativeId, Session.getEnvironmentId());

      setFrameSummary(frameSummary);
    } catch (error) {
      notifyError({ message: error.message });
    }

    this.setState({
      isFetchingCreativeDetails: false,
    });
  };

  onMotionChange = async (value) => {
    const {
      params: { id: creativeId },
      cancelFunctions,
      notifyError,
      movement,
      setMovement,
    } = this.props;

    try {
      if (movement === value) {
        return;
      }

      const { send, cancel } = createRequest({
        url: URLS().UPDATE_CREATIVE_MOVEMENT(creativeId),
        method: HTTP_METHODS.PATCH,
        headers: {
          'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
        },
        data: JSON.stringify({
          movement: value,
        }),
      });

      cancelFunctions.UPDATE_CREATIVE_MOVEMENT = cancel;

      await send();

      setMovement(value);

      await this.getFrameSummary();
    } catch (error) {
      notifyError({ message: error.message });
    }
  };

  render() {
    const {
      params: { id: creativeId },
      marketId,
      statusCode,
      externalId,
      dsp,
      width,
      height,
      uploadedBy,
      uploadedDate,
      uploadedTime,
      movement,
      frameSummary,
      commercialId,
      deleted,
      localeCode,
      isReseller,
      inheritedDealIds,
      ownDealIds,
    } = this.props;
    const {
      showModal,
      combinedAuditDetails,
      showDeleteDialog,
      showRestoreDialog,
      rejectReason,
      isFetchingCreativeDetails,
    } = this.state;
    const readOnly = deleted;

    return (
      <div className={cx('row')}>
        <div className={cx('col-12', 'col-md-8')}>
          <div className={cx('row')}>
            <div className="col-12">
              <div data-test-id="preview-info" className={cx('row')}>
                <div className="col-12">
                  <Card>
                    <div className={cx('row', 'border-bottom')}>
                      <div className={cx('col-auto')}>
                        <p className="body-sm text-neutral-600">Status</p>
                        {isReseller && creativeId ? (
                          <span className="block relative mt-2 mb-2">
                            <ResellerCreativeStatus marketId={marketId} statusCode={statusCode} />
                          </span>
                        ) : (
                          <p className="body-base text-neutral-900">{statusCode}</p>
                        )}
                      </div>
                      <div className={cx('col-auto', 'border-left')}>
                        <p className="body-sm text-neutral-600">Creative ID</p>
                        <p className="body-base text-neutral-900">{externalId}</p>
                      </div>
                      <div className={cx('col-auto', 'border-left')}>
                        <p className="body-sm text-neutral-600">DSP</p>
                        <p className="body-base text-neutral-900">{dsp?.name}</p>
                      </div>
                      {width && height ? (
                        <div className={cx('col-auto', 'border-left')}>
                          <p className="body-sm text-neutral-600">Size</p>
                          <p className="body-base text-neutral-900">
                            {width}X{height}
                          </p>
                        </div>
                      ) : null}
                      <div className={cx('col-auto', 'border-left')}>
                        <p className="body-sm text-neutral-600">User</p>
                        <p className="body-base text-neutral-900">{uploadedBy}</p>
                      </div>
                      <div className={cx('col-auto', 'border-left')}>
                        <p className="body-sm text-neutral-600">Date</p>
                        <p className="body-base text-neutral-900">{uploadedDate}</p>
                      </div>
                      <div className={cx('col-auto', 'border-left')}>
                        <p className="body-sm text-neutral-600">Time</p>
                        <p className="body-base text-neutral-900">{uploadedTime}</p>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
          <Card>
            <div className={cx('row', 'border-bottom')}>
              <div className={cx('col-12', 'col-md-8')}>
                <div className={cx('form-group')}>
                  <span className={cx('sub-header-base', 'form-label')}>
                    Deal ID {isReseller && <RequiredAsterisk />}
                  </span>
                  <AsyncSelect
                    name="dealId"
                    loadData={this.onLoadDealIdLookupData}
                    onSelect={this.onSelectDealId}
                    placeholder="Search deal ID"
                    selectedValue={ownDealIds}
                    multiple
                    dataTestId="creative-deal-search"
                    isDisabled={statusCode === CreativeStatus.REJECTED || readOnly}
                  />
                  {inheritedDealIds?.length > 0 && (
                    <div className="mt-2">
                      <Chips
                        dataList={inheritedDealIds}
                        dataTestId="creative-inherited-deal-ids"
                        idKey="code"
                        labelKey="name"
                        isDisabled
                        isClearAllVisible={false}
                      />
                    </div>
                  )}
                </div>
              </div>
              {Auth.hasPermission(PermissionsEnum.CONTENT_MOTION_TOGGLE) && (
                <div className={cx('col-12', 'col-md-4', 'multiple-switch-box')}>
                  <MultipleSwitchBox
                    source={[MOTION_TYPE_INFO.STILL, MOTION_TYPE_INFO.MOVING]}
                    selectedValue={movement}
                    textKey="name"
                    valueKey="code"
                    onChange={(value) => this.onMotionChange(value)}
                    isDisabled={readOnly}
                    dataTestId="creative-motion-switch"
                  />
                </div>
              )}
              <Modal
                isOpen={showModal}
                icon={<SVG src={closeSvg} className="text-pinkRed-500" />}
                title="Reject Creative?"
                dataTestId="creative-reject-modal"
                actionButtons={
                  <>
                    <Button btnType={ButtonType.PRIMARY} btnShape={ButtonShape.NORMAL} onClick={this.closeModal}>
                      Cancel
                    </Button>
                    <Button
                      btnType={ButtonType.DANGER}
                      btnShape={ButtonShape.NORMAL}
                      onClick={this.onRejectCreative}
                      isDisabled={!rejectReason}
                    >
                      Reject
                    </Button>
                  </>
                }
              >
                <div className="ml-8">
                  <Input placeholder="Reject Reason…" value={rejectReason} onChange={this.onChangeRejectReason} />
                </div>
              </Modal>
              <Modal
                isOpen={showDeleteDialog}
                icon={<SVG src={binSvg} className="text-pinkRed-500" />}
                title="Delete Creative?"
                dataTestId="creative-delete-modal"
                actionButtons={
                  <>
                    <Button
                      btnType={ButtonType.PRIMARY}
                      btnShape={ButtonShape.NORMAL}
                      onClick={() => this.setState({ showDeleteDialog: false })}
                    >
                      Cancel
                    </Button>
                    <Button btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL} onClick={this.onDelete}>
                      Delete
                    </Button>
                  </>
                }
              >
                <p className="ml-8 body-base text-neutral-950-opacity-60">
                  Are you sure you want to delete this creative?
                </p>
              </Modal>
              <Modal
                isOpen={showRestoreDialog}
                icon={<SVG src={restoreSvg} className="text-primary" />}
                title="Restore Creative?"
                dataTestId="creative-restore-modal"
                actionButtons={
                  <>
                    <Button
                      btnType={ButtonType.PRIMARY}
                      btnShape={ButtonShape.NORMAL}
                      onClick={() => {
                        this.setState({ showRestoreDialog: false });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL} onClick={this.onRestore}>
                      Restore
                    </Button>
                  </>
                }
              >
                <p className="ml-8 body-base text-neutral-950-opacity-60">
                  Are you sure you want to restore this creative?
                </p>
              </Modal>
            </div>
          </Card>

          {isReseller && <ResellerEligibleMediaOwners />}

          <TaggingRules
            marketId={marketId}
            creativeId={creativeId}
            commercialId={commercialId}
            creativeStatus={statusCode}
            isFetchingCreativeDetails={isFetchingCreativeDetails}
            frameSummary={frameSummary}
            onTaggingRulesChange={this.getFrameSummary}
            isDisabled={readOnly}
          />
          <Card header="Audit log">
            <ul className={cx('timeline')} data-test-id="moderation-log">
              {combinedAuditDetails?.map((mod) => {
                return (
                  <li key={mod.updateStamp}>
                    {getModerationStatusIcon(mod.status, mod.type)}
                    <div className={cx('description')}>
                      {getModerationStatusHTML(mod.status, mod.type, mod.updatedBy, mod.user)}
                      {mod.comment && (
                        <p className={cx('body-base', 'text-secondary')}>
                          <i>{mod.comment}</i>{' '}
                        </p>
                      )}
                      {mod.updateStamp ? (
                        <p className={cx('body-sm', 'text-secondary')}>
                          {formatDateTimeNoUTC(mod.updateStamp, localeCode)}
                        </p>
                      ) : (
                        <p className={cx('body-sm', 'text-secondary')}>
                          {formatDateTime({ isoDateString: mod.createdAt, localeCode })}
                        </p>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </Card>
        </div>

        <div className={cx('col-12', 'col-md-4')}>
          <Card>
            <div className={cx('sidebar-right')}>
              <div className={cx('row', 'mb-4')}>
                <div className="col-12">
                  <CreativePreview />
                </div>
              </div>
              <div className={cx('row', 'buttons')}>
                <div className="col-12">
                  <Card>
                    {this.getButtons(readOnly)}
                    {!deleted &&
                    !isReseller &&
                    Auth.hasPermission(PermissionsEnum.ADMINISTRATION_CREATIVE_DELETE) &&
                    this.isPendingApprovalOrRejected() ? (
                      <div className={cx('status-buttons', 'mt-2')}>
                        <Button
                          onClick={() => {
                            this.setState({ showDeleteDialog: true });
                          }}
                          btnShape={ButtonShape.NORMAL}
                          btnType={ButtonType.PRIMARY}
                        >
                          <SVG src={binSvg} />
                          Delete Creative
                        </Button>
                      </div>
                    ) : null}
                    {deleted && !isReseller && Auth.hasPermission(PermissionsEnum.ADMINISTRATION_CREATIVE_RESTORE) ? (
                      <div className={cx('status-buttons', 'mt-2')}>
                        <Button
                          onClick={() => {
                            this.setState({ showRestoreDialog: true });
                          }}
                          btnShape={ButtonShape.NORMAL}
                          btnType={ButtonType.SUCCESS}
                        >
                          <SVG src={restoreSvg} />
                          Restore Creative
                        </Button>
                      </div>
                    ) : null}
                  </Card>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

PreviewContent.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  setCreativeDetails: PropTypes.func.isRequired,
  setFrameSummary: PropTypes.func.isRequired,
  setMovement: PropTypes.func.isRequired,
  resetCreativeDetails: PropTypes.func.isRequired,
  updateCreativeProperties: PropTypes.func.isRequired,
  marketId: PropTypes.string.isRequired,
  deleted: PropTypes.bool,
  moderation: PropTypes.arrayOf(PropTypes.shape({ status: PropTypes.string })).isRequired,
  dealId: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string, name: PropTypes.string })).isRequired,
  dsp: PropTypes.shape({ code: PropTypes.string, name: PropTypes.string }).isRequired,
  movement: PropTypes.string.isRequired,
  statusCode: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  uploadedBy: PropTypes.string.isRequired,
  uploadedDate: PropTypes.string.isRequired,
  uploadedTime: PropTypes.string.isRequired,
  commercialId: PropTypes.string,
  frameSummary: PropTypes.shape({
    whiteList: PropTypes.array,
    blackList: PropTypes.array,
    unusedList: PropTypes.array,
  }),
  productCategory: PropTypes.shape({ code: PropTypes.string, name: PropTypes.string }).isRequired,
  advertiser: PropTypes.shape({ id: PropTypes.string }).isRequired,
  brand: PropTypes.shape({ id: PropTypes.string }).isRequired,
  localeCode: PropTypes.string,
  isReseller: PropTypes.bool,
  market: PropTypes.arrayOf(
    PropTypes.shape({
      mediaOwners: PropTypes.arrayOf(
        PropTypes.shape({ status: PropTypes.string, updateStamp: PropTypes.number, publisher: PropTypes.string }),
      ),
    }),
  ),
  inheritedDealIds: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, code: PropTypes.string })),
  ownDealIds: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, code: PropTypes.string })),
};

PreviewContent.defaultProps = {
  frameSummary: { inclusionListCount: 0, exclusionListCount: 0 },
  commercialId: '',
  deleted: false,
  localeCode: undefined,
};

export default withRouter(withLoader(withCancelRequest(PreviewContent)));
/* eslint-enable sonarjs/cognitive-complexity */
