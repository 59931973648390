import { OptionProps, components } from 'react-select';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { Label } from 'lib/Label';
import { UseAllocationReport } from '../useAllocationReport';
import { SelectedLine } from '../AllocationReport.types';

type DropdownDealLineProps = Pick<ReturnType<UseAllocationReport>, 'dealLines' | 'handleChangeLine' | 'selectedLine'>;

export const DropdownDealLine: React.FC<DropdownDealLineProps> = ({ dealLines, handleChangeLine, selectedLine }) => {
  const handleSearchLines = (_dropdownName: string, query: string): SelectedLine[] => {
    return dealLines.filter(({ lineId, lineName }) => lineName.includes(query) || lineId.includes(query));
  };

  return (
    <div className="flex flex-col w-70 z-10" data-dd-action-name="Allocation report / Deal line dropdown">
      <Label label="Deal line" />
      <AsyncSelect
        dataTestId="lines"
        name="lines"
        textKey="lineName"
        valueKey="lineName"
        defaultOptions={dealLines}
        loadData={handleSearchLines}
        debounceTime={0}
        onSelect={handleChangeLine}
        selectedValue={selectedLine}
        isClearable={false}
        hideSelectedOptions={false}
        customComponents={{
          Option: ({ data, ...props }: OptionProps & { data: SelectedLine }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <components.Option data={data} {...props}>
              <div className="flex justify-between space-x-2">
                <div data-test-id="option-deal-info">
                  <p className="body-lg text-neutral-950 break-all" data-test-id="option-deal-info-name">
                    {data.lineName}
                  </p>
                  <p className="body-base text-neutral-600" data-test-id="option-deal-info-id">
                    {data.lineId}
                  </p>
                </div>
              </div>
            </components.Option>
          ),
        }}
      />
    </div>
  );
};
