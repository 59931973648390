import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface Timezone {
  code: string;
  flag: string;
  iso2CountryCode: string;
  name: string;
}

export const initialState = {
  timezone: {
    code: '',
    flag: '',
    iso2CountryCode: '',
    name: '',
    selectedPublisher: '',
  },
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setTimezone(state, action: PayloadAction<Timezone & { selectedPublisher: string }>) {
      state.timezone = { ...action.payload };
    },
  },
});

export const { setTimezone } = headerSlice.actions;
export default headerSlice.reducer;
