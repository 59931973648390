import DealIdRenderer from 'components/common/DealIdRenderer/index';

export type DealData = {
  data: {
    dealId: string;
    dealName: string;
  };
};

const DealRenderer: React.FC<DealData> = ({ data }: DealData) => {
  if (!data?.dealId && !data?.dealName) return <></>;

  return (
    <>
      <p className="mt-0 mb-0 sub-header-base" data-test-id="DealRenderer-subHeader">
        <DealIdRenderer data={{ dealId: data.dealName }} />
      </p>
      <p className="mt-0 mb-0 text-neutral-600 body-base" data-test-id="DealRenderer-body">
        <DealIdRenderer data={{ dealId: data.dealId }} />
      </p>
    </>
  );
};

export default DealRenderer;
