import SVG from 'react-inlinesvg';

import { MOTION_TYPE_INFO } from 'consts/motionType';
import { MotionTypeModel } from 'components/common/prop-types/creativeSets';

const MotionChip = ({ motionType }) => {
  return (
    <p className="flex gap-x-2 items-center body-base text-neutral-600 bg-neutral-100 rounded-xl w-min px-2 py-1">
      <SVG src={MOTION_TYPE_INFO[motionType].icon} className="h-3.5 w-3.5" />
      {MOTION_TYPE_INFO[motionType].name}
    </p>
  );
};

MotionChip.propTypes = {
  motionType: MotionTypeModel.isRequired,
};

export default MotionChip;
