import { PieChart } from 'components/patterns/HighChart';
import { DASH_SLASH } from 'consts/placeholders';
import { EmptyPieChartProps } from './EmptyPieChart.types';

const EmptyPieChart: React.FC<EmptyPieChartProps> = ({ onChartClick }) => {
  const ctPieChartColor = ['#c0c0c0'];

  const chartOptions = {
    chart: {
      width: 60,
      height: 60,
      margin: [0, 0, 0, 0],
      backgroundColor: 'transparent',
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        events: {
          click: () => false,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const getChartData = (): { name: string; y: number }[] => {
    return [
      {
        name: 'Empty',
        y: 1,
      },
    ];
  };

  const chartData = getChartData();

  return (
    <div className="relative flex items-center body-sm text-neutral-600">
      <PieChart
        dataTestId="empty-pie-chart"
        colors={ctPieChartColor}
        data={chartData}
        options={chartOptions}
        enableDataLabel={false}
        enableExporting={false}
        showInLegend={false}
        onClick={onChartClick}
      />
      <div className="relative w-40 inline-block">
        <div className="mb-1">
          <span className="h-2.5 w-2.5 inline-block mr-2 rounded-sm" style={{ backgroundColor: ctPieChartColor[0] }} />
          {DASH_SLASH}
        </div>
        <div>
          <span className="h-2.5 w-2.5 inline-block mr-2 rounded-sm" style={{ backgroundColor: ctPieChartColor[0] }} />
          {DASH_SLASH}
        </div>
      </div>
      <div className="relative w-60 inline-block pl-2.5 border-l border-neutral-300 border-solid">
        <div className="mb-1">{DASH_SLASH}</div>
        <div>{DASH_SLASH}</div>
      </div>
    </div>
  );
};

export default EmptyPieChart;
