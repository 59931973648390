import PropTypes from 'prop-types';
import { components } from 'react-select';
import TimezoneFormat from '../TimezoneFormat';

const SingleValue = ({ data, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.SingleValue {...props}>
      <TimezoneFormat data={data} />
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    flag: PropTypes.string,
  }).isRequired,
};

export default SingleValue;
