export enum PopupPosition {
  BOTTOM = 'bottom',
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum PopupAlign {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}
