import PropTypes from 'prop-types';

import Header from 'components/common/Header';
import SubNavigation from 'components/common/SubNavigation';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';

const ContentManagementHeader = ({ actionButton }) => {
  const tabsConfig = [
    {
      id: 1,
      path: '/content-management/creatives',
      isVisible: true,
      name: 'Creatives',
    },
    ...(useHasFeatureAccess(FeatureFlags.CREATIVE_SETS)
      ? [{ id: 2, path: '/content-management/creative-sets', isVisible: true, name: 'Creative sets' }]
      : []),
  ];

  return (
    <Header
      title="Content management"
      subNavigation={<SubNavigation tabsConfig={tabsConfig} />}
      actionButton={actionButton}
    />
  );
};

ContentManagementHeader.propTypes = {
  actionButton: PropTypes.element,
};

ContentManagementHeader.defaultProps = {
  actionButton: null,
};

export default ContentManagementHeader;
