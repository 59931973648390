import { InputValue } from 'components/common/types/Input.types';
import Input from 'lib/Input';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { onFilterChange } from 'store/pages/contentManagement/creativeSetDetails/reducer';
import { FilterVisualUnitCodeProps } from './FilterVisualUnitCode.types';

const FilterVisualUnitCode: React.FC<FilterVisualUnitCodeProps> = ({
  visualUnitCode,
  changeFilters,
  placeholder = 'Select VU code',
}) => {
  const dispatch = useDispatch();
  const storeVisualUnitCode = useSelector(
    (state: RootState) => state.pages.contentManagement.creativeSetDetails.filters.visualUnitCode,
  );

  const handleChangeValue = (value: InputValue): void => {
    if (changeFilters) {
      changeFilters({ visualUnitCode: value as string });
      return;
    }
    dispatch(onFilterChange({ visualUnitCode: value as string }));
  };

  return (
    <Input
      dataTestId="visual-unit-code"
      name="visualUnitCode"
      value={visualUnitCode ?? storeVisualUnitCode}
      placeholder={placeholder}
      onChange={handleChangeValue}
      debounceTime={1000}
    />
  );
};

export default FilterVisualUnitCode;
