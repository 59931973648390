import { useEffect, useState } from 'react';

import { formatDate } from 'modules/I18N';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useScheduledDaysLeft from 'customHooks/useScheduledDaysLeft';
import { DaysRemainingRendererProps } from './DaysRemainingRenderer.types';

const DAYS_LEFT_TEXT_COLORS = {
  RED: 'text-pinkRed-500',
  YELLOW: 'text-yellow',
  GREY: 'text-neutral-950-opacity-60',
};

const DaysRemainingRenderer: React.FC<DaysRemainingRendererProps> = ({ data }) => {
  if (!data?.deletedAt || !data?.expiredAt) return null;

  const { localeCode } = useSelector((storeState: RootState) => storeState.publisher.configuration);
  const [daysLeftTextColor, setDaysLeftTextColor] = useState(DAYS_LEFT_TEXT_COLORS.GREY);

  const daysLeft = useScheduledDaysLeft(data?.expiredAt);

  const handleTextColor = (days: number): void => {
    if (days <= 5) {
      setDaysLeftTextColor(DAYS_LEFT_TEXT_COLORS.RED);
    } else if (days <= 15 && days > 5) {
      setDaysLeftTextColor(DAYS_LEFT_TEXT_COLORS.YELLOW);
    } else {
      setDaysLeftTextColor(DAYS_LEFT_TEXT_COLORS.GREY);
    }
  };

  useEffect(() => {
    handleTextColor(daysLeft);
  }, [daysLeft]);

  return (
    <div>
      {!data.isExpired && daysLeft > 0 && (
        <div>
          <p data-test-id="days-left-expire-text" className="body-base text-neutral-950-opacity-60 mb-[-4px]">
            Expires in{' '}
            <strong data-test-id="days-left-text" className={`${daysLeftTextColor}`}>
              {daysLeft} {daysLeft > 1 ? 'days' : 'day'}
            </strong>
          </p>
          <p data-test-id="days-left-range" className="body-base text-neutral-950-opacity-80">
            on {formatDate(data.expiredAt, localeCode)}
          </p>
        </div>
      )}
    </div>
  );
};

export default DaysRemainingRenderer;
