export enum CardType {
  REGULAR = '',
  TAGS = 'tags-card',
  PLAIN = 'plain-card',
  ELEVATED = 'elevated-card',
  FILTERS = 'filters-card',
  FULL_HEIGHT = 'full-height',
  NONE = 'none',
  TRANSPARENT = 'transparent',
}

export enum CardTheme {
  PRIMARY = 'card-primary',
  WHITE = 'card-white',
  DARK = 'card-dark',
  LIGHT = 'card-light',
  TRANSPARENT_DARK_SURFACE = 'card-transparent-dark-surface',
  TRANSPARENT_LIGHT_SURFACE = 'card-transparent-light-surface',
}

export enum BorderTheme {
  BASIC = 'border-neutral-300',
  WARNING = 'border-yellow',
  DARK = 'border-neutral-100-opacity-30',
}

export interface CardProps {
  icon?: string;
  header?: React.ReactNode;
  info?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  cardType?: CardType;
  mandatory?: boolean;
  id?: string;
  cardTheme?: CardTheme;
  dataTestId?: string;
  borderTheme?: BorderTheme;
}
