import AsyncSelect from 'components/patterns/AsyncSelect';
import { CodeNameModel } from 'components/common/types';
import { useLoadLookup } from '../useLoadLookup';
import { FilterSpecialistProps } from './FilterSpecialist.type';

const FilterSpecialist: React.FC<FilterSpecialistProps> = ({ specialist, changeFilters }) => {
  const { onLoadLookup } = useLoadLookup();

  const onSelect = (name: string, value: string | Pick<CodeNameModel, 'code' | 'name'>): void => {
    changeFilters({ [name]: value });
  };

  return (
    <AsyncSelect
      name="specialist"
      selectedValue={specialist}
      loadData={onLoadLookup}
      onSelect={onSelect}
      placeholder="Search specialist"
      dataTestId="specialist-filter"
      textKey="name"
    />
  );
};

export default FilterSpecialist;
