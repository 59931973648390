// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-undef */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-const */
/* eslint-disable no-multi-assign */

/* eslint-disable func-names */
export const loadPendo = (subscriptionKey) => {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      let v;
      let w;
      let x;
      let y;
      let z;
      // eslint-disable-next-line no-param-reassign
      o = p[d] = p[d] || {};
      // eslint-disable-next-line no-underscore-dangle
      o._q = [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w) {
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              // eslint-disable-next-line no-underscore-dangle
              o._q[m === v[0] ? 'unshift' : 'push'](
                [m].concat(
                  [].slice.call(
                    // eslint-disable-next-line prefer-rest-params
                    arguments,
                    0,
                  ),
                ),
              );
            };
        })(v[w]);
      }

      y = e.createElement(n);
      y.async = !0;
      y.src = `https://content.programmatic.analytics.viooh.com/agent/static/${apiKey}/pendo.js`;
      // eslint-disable-next-line prefer-destructuring
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })(subscriptionKey);
};

export const updatePendoOptions = (emailID, marketID, session, language = 'en-gb') => {
  if (pendo && session) {
    pendo.updateOptions({
      visitor: {
        id: emailID,
        email: emailID,
        full_name: emailID,
        lang: language,
      },
      account: {
        id: marketID,
      },
      disableGuides: false,
    });
  } else {
    pendo.initialize({
      visitor: {
        id: emailID,
        email: emailID,
        full_name: emailID,
        lang: language,
      },
      account: {
        id: marketID,
      },
      disableGuides: false,
    });
  }
};
