import { useSelector } from 'react-redux';
import { FormatTypeEnum, formatPercent, formatValue as formatWithCurrency } from 'modules/I18N';
import SVG from 'react-inlinesvg';
import infoSvg from 'assets/icons/info.svg';

import { RootState } from 'store';
import Card from 'lib/Card';
import { CpmCardProps } from './CpmCard.types';
import DealSummaryCardSection from '../DealSummary/DealSummaryCardSection';
import { DealSummaryCardSectionLineType } from '../DealSummary/DealSummaryCardSection/DealSummaryCardSection.types';

const CpmCard: React.FC<CpmCardProps> = ({
  currentValue,
  average,
  minDistribution,
  maxDistribution,
  range,
  isCampaignLevel = false,
}) => {
  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const formatValue = (value?: number): string => {
    if (Number.isNaN(value) || !value) return '--';

    return formatWithCurrency(value, FormatTypeEnum.CURRENCY, currencyCode, localeCode);
  };

  const getCpmDescription = (cpmValue: string | number, averageValue: string | number): string => {
    if (Number.isNaN(cpmValue) || Number.isNaN(averageValue)) return '';

    const parsedCpm = Number(cpmValue);
    const parsedAverage = Number(averageValue);

    if (parsedCpm === parsedAverage) return 'equal to';

    const percentage = formatPercent({ value: Math.abs((parsedCpm - parsedAverage) / parsedAverage), localeCode });

    return parsedCpm > parsedAverage ? `${percentage} above` : `${percentage} below`;
  };

  const getCpmPrice = (): string => {
    const isRange = isCampaignLevel && range && range.min !== range.max;

    if (isRange) return `${formatValue(range.min)} - ${formatValue(range.max)}`;
    return formatValue(currentValue);
  };

  const cpmFooter = (
    <div data-test-id="deal-info-description" className="flex">
      <div className="mt-[2px]">
        <SVG className="fill-current text-primary-600" src={infoSvg} height={16} />
      </div>
      <p className="body-sm ml-1" data-test-id="cpm-card-tooltip">
        Your entered CPM is {getCpmDescription(currentValue, average)} the Average CPM for your frames
      </p>
    </div>
  );

  return (
    <div data-test-id="cpm-card" className="relative mb-3">
      <Card footer={!!currentValue && !!average ? cpmFooter : undefined}>
        <DealSummaryCardSection
          lines={[
            { label: 'CPM Price', value: getCpmPrice(), lineType: DealSummaryCardSectionLineType.HEADER },
            {
              label: 'Min',
              value: formatValue(minDistribution),
              lineType: DealSummaryCardSectionLineType.SUBHEADER_SECONDARY,
            },
            {
              label: 'Max',
              value: formatValue(maxDistribution),
              lineType: DealSummaryCardSectionLineType.SUBHEADER_SECONDARY,
            },
            {
              label: 'Average',
              value: formatValue(average),
              lineType: DealSummaryCardSectionLineType.SUBHEADER_SECONDARY,
            },
          ]}
        />
      </Card>
    </div>
  );
};

export default CpmCard;
