import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formatNumber, formatPercent } from 'modules/I18N';
import { transformColumnChartData } from 'utils/formatters';
import { datePercentChartOptions, calculateDealPercentChartOptions } from 'utils/chartOptions';
import HeroChart from '../HeroChart';

const FillRate = ({ fillRateData, isDealSelected }) => {
  const { localeCode } = useSelector((state) => state.publisher.configuration);
  const isChartsOpen = useSelector((state) => state.dealDashboard.isChartsOpen);
  const { fillRateGraph, soldImpressions, fillRate, bookedImpressions } = fillRateData;

  return (
    <HeroChart
      dataTestId="fill-rate-chart"
      chartTitle="Fill rate"
      chartData={transformColumnChartData(fillRateGraph, isDealSelected ? 'date' : 'dealId', 'fillRate')}
      table={[
        { title: 'Sold imp.', value: formatNumber({ value: soldImpressions, localeCode }) },
        { title: 'Booked imp.', value: formatNumber({ value: bookedImpressions, localeCode }) },
        { title: 'Fill rate %', value: formatPercent({ value: fillRate / 100, localeCode }) },
      ]}
      isSplineChart={isDealSelected}
      chartOptions={
        isDealSelected
          ? datePercentChartOptions({ localeCode })
          : calculateDealPercentChartOptions(isChartsOpen, localeCode)
      }
    />
  );
};

FillRate.propTypes = {
  fillRateData: PropTypes.object,
  isDealSelected: PropTypes.bool,
};

FillRate.defaultProps = {
  fillRateData: {},
  isDealSelected: false,
};

export default FillRate;
