import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Timeline, { TimelineItem } from 'components/patterns/Timeline';
import { FormatTypeEnum, formatValue, getFormattedDateTime } from 'modules/I18N';

const LineSummary = ({ line }) => {
  const { currencyCode, localeCode } = useSelector((state) => state.publisher.configuration);
  return (
    <div className="p-5 max-w-sm bg-neutral-100 overflow-y-scroll">
      <p className="mb-8 sub-header-base">Summary</p>
      <Timeline>
        <TimelineItem title={<p className="body-sm">Schedule</p>}>
          <span className="body-base">
            {`${getFormattedDateTime(new Date(line.startDate), {
              dateStyle: 'medium',
            })} - ${getFormattedDateTime(new Date(line.endDate), {
              dateStyle: 'medium',
            })}`}
          </span>
        </TimelineItem>
        <TimelineItem title={<p className="body-sm">Environment</p>}>
          <p className="divide-x space-x-1 body-base">
            {line.environments.map((environment, index) => (
              <span key={environment.code} className={index === 0 ? 'pr-1' : 'pl-2'}>
                {environment.name}
              </span>
            ))}
          </p>
        </TimelineItem>
        <TimelineItem title={<p className="body-sm">Format</p>}>
          <p className="divide-x space-x-1 body-base">
            {line.productFormats.map((format, index) => (
              <span key={format.code} className={index === 0 ? 'pr-1' : 'pl-2'}>
                {format.name}
              </span>
            ))}
          </p>
        </TimelineItem>
        <TimelineItem title={<p className="body-sm">Target</p>}>
          <p className="body-base">{`SOT: ${line.sot || 0} | Impressions: ${formatValue(
            line.impressions,
            FormatTypeEnum.ROUNDED_NUMBER,
            currencyCode,
            localeCode,
          )} | Frames: ${line.frames}`}</p>
        </TimelineItem>
        <TimelineItem title={<p className="body-sm">Tags</p>}>
          {line.tags.map((tag) => (
            <span key={tag.code} className="body-base">
              #{tag.name}
            </span>
          ))}
        </TimelineItem>
      </Timeline>
    </div>
  );
};

LineSummary.propTypes = {
  line: PropTypes.object.isRequired,
};

export default LineSummary;
