import { useNavigate } from 'react-router-dom';

import Header from 'components/common/Header';

const UploadCreativeHeader = () => {
  const navigate = useNavigate();

  return <Header title="Content management" onBackClick={() => navigate('/content-management/')} />;
};

export default UploadCreativeHeader;
