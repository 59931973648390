import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CodeNameModel } from 'components/common/types';
import { FrameTag } from 'components/common/types/FrameTag.types';
import { CreativeMetaOperation } from 'components/common/types/Creative.types';

interface CreativesAdditionalData {
  additionalProductCategoriesOptions: CodeNameModel[];
  productCategoriesMode: CreativeMetaOperation;
  previousProductCategories: CodeNameModel[];
  newProductCategories: CodeNameModel[];
  frameTagsMode: CreativeMetaOperation;
  previousFrameTags: FrameTag[];
  newFrameTags: FrameTag[];
  isAdditionalDataIncluded: boolean;
  isUnsavedProductCategoryChange: boolean;
  isUnsavedFrameTagsChange: boolean;
}

const initialState: CreativesAdditionalData = {
  additionalProductCategoriesOptions: [],
  productCategoriesMode: CreativeMetaOperation.ADD,
  previousProductCategories: [],
  newProductCategories: [],
  frameTagsMode: CreativeMetaOperation.ADD,
  previousFrameTags: [],
  newFrameTags: [],
  isAdditionalDataIncluded: false,
  isUnsavedProductCategoryChange: false,
  isUnsavedFrameTagsChange: false,
};

const creativesAdditionalDataSlice = createSlice({
  name: 'contentManagementCreativesAdditionalDataSlice',
  initialState,
  reducers: {
    updateAdditionalProductCategoriesOptions: (state, action: PayloadAction<CodeNameModel[]>) => {
      state.additionalProductCategoriesOptions = action.payload;
    },
    updateNewProductCategories: (state, action: PayloadAction<CodeNameModel[]>) => {
      state.newProductCategories = action.payload;
    },
    updatePreviousProductCategories: (state, action: PayloadAction<CodeNameModel[]>) => {
      state.previousProductCategories = action.payload;
    },
    updateNewFrameTags: (state, action: PayloadAction<FrameTag[]>) => {
      state.newFrameTags = action.payload;
    },
    updatePreviousFrameTags: (state, action: PayloadAction<FrameTag[]>) => {
      state.previousFrameTags = action.payload;
    },
    updateProductCategoriesMode: (state, action: PayloadAction<CreativeMetaOperation>) => {
      state.productCategoriesMode = action.payload;
    },
    updateFrameTagsMode: (state, action: PayloadAction<CreativeMetaOperation>) => {
      state.frameTagsMode = action.payload;
    },
    updateIsAdditionalDataIncluded: (state, action: PayloadAction<boolean>) => {
      state.isAdditionalDataIncluded = action.payload;
    },
    updateIsUnsavedProductCategory: (state, action: PayloadAction<boolean>) => {
      state.isUnsavedProductCategoryChange = action.payload;
    },
    updateIsUnsavedFrameTags: (state, action: PayloadAction<boolean>) => {
      state.isUnsavedFrameTagsChange = action.payload;
    },
    resetCreativesAdditionalData: (state) => {
      return {
        ...initialState,
        additionalProductCategoriesOptions: state.additionalProductCategoriesOptions,
        isAdditionalDataIncluded: state.isAdditionalDataIncluded,
      };
    },
  },
});

export { CreativesAdditionalData };
export const {
  updateAdditionalProductCategoriesOptions,
  updateNewProductCategories,
  updatePreviousProductCategories,
  updateNewFrameTags,
  updatePreviousFrameTags,
  updateProductCategoriesMode,
  updateFrameTagsMode,
  updateIsAdditionalDataIncluded,
  updateIsUnsavedProductCategory,
  updateIsUnsavedFrameTags,
  resetCreativesAdditionalData,
} = creativesAdditionalDataSlice.actions;
export default creativesAdditionalDataSlice.reducer;
