import { useState } from 'react';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import warningSvg from 'assets/icons/warning.svg';
import { PermissionsEnum } from 'consts/permissions';
import { DealStatus } from 'components/common/types/Deal.types';
import { isReadOnly } from 'utils/isReadOnly';
import Auth from 'modules/Auth';

import InfoMessage from 'components/common/Deal/InfoMessage';
import { BorderTheme } from 'components/patterns/Card/Card.types';
import Days from './Days';
import DayPart from './DayPart';
import DateSelector from '../DateSelector';

const Pattern = () => {
  const isEditingDisabled = useSelector((state) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);
  const id = useSelector((state) => state.dealManagement.commonDeal.currentLine.id);
  const terminated = useSelector((state) => state.dealManagement.commonDeal.currentLine.terminated);
  const selectedDays = useSelector((state) => state.dealManagement.commonDeal.currentLine.selectedDays);

  const [isPatternInfoMessage, setIsPatternInfoMessage] = useState(false);
  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled, terminated);
  const isPendingReservation = DealStatus.PENDING_RESERVATION === bookingStatusCode;

  const handlePatternInfoMessage = () => {
    const isPattern = Object.values(selectedDays).some((day) => !day.isFullDay);
    setIsPatternInfoMessage(isPattern);
  };

  if (!Auth.hasPermission(PermissionsEnum.DEAL_PATTERNS_VIEW)) return null;

  return (
    <div className="flex-grow">
      <p className="sub-header-base text-neutral-950-opacity-60 mb-3.5">Pattern</p>
      <div className="grid grid-cols-1 gap-y-4 2xl:gap-y-0 2xl:grid-cols-2 2xl:gap-x-8">
        <Days readOnly={readOnly} key={id} />
        <div>
          <DateSelector
            handlePatternInfoMessage={handlePatternInfoMessage}
            isPatternInfoMessage={isPatternInfoMessage}
          />
          <DayPart readOnly={readOnly || isPendingReservation} />
          {isPatternInfoMessage ? (
            <div className="pr-20 mt-4">
              <InfoMessage
                borderTheme={BorderTheme.WARNING}
                icon={<SVG src={warningSvg} className="w-5 h-5 mr-2 text-warning" />}
              >
                Please extend the day pattern to have the new end date applied.
              </InfoMessage>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Pattern;
