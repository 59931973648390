export const download = (blobObject, fileName) => {
  const url = window.URL.createObjectURL(blobObject);
  const link = document.createElement('a');
  link.href = url;

  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
