import Notification from 'components/patterns/Notification';

type Props = {
  message?: string;
  type?: string;
  timeout?: number;
  clearNotification?: () => void;
};

const Notifications: React.FC<Props> = ({
  message = '',
  type = '',
  timeout = 0,
  clearNotification = () => undefined,
}) => (
  <Notification
    message={message}
    type={type}
    onClick={clearNotification}
    timeout={timeout}
    onTimeout={clearNotification}
  />
);

Notifications.defaultProps = {
  message: '',
  type: '',
  timeout: 0,
  clearNotification: () => undefined,
};

export default Notifications;
