import React from 'react';
import PropTypes from 'prop-types';
import RadioCheckBox, { types } from 'components/patterns/RadioCheckBox';
import * as roleActions from 'modules/api/role';
import withCancelRequest from 'components/hocs/withCancelRequest';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import { Label } from 'lib/Label';
import Input from 'lib/Input';

class Role extends React.Component {
  state = {
    role: { ...this.props.role },
    isEditMode: !!this.props.role.id,
    permissions: [],
  };

  async componentDidMount() {
    const { cancelFunctions } = this.props;
    const permissions = await roleActions.getPermissionList(cancelFunctions);
    this.setState({ permissions });
  }

  onSaveButtonClick = () => {
    const { onSave } = this.props;
    const { role } = this.state;
    onSave(role);
  };

  onCancelButtonClick = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  getInput = (value, name, placeholder) => {
    return <Input type="text" value={value} name={name} placeholder={placeholder} onChange={this.onTextChange} />;
  };

  onTextChange = (value, name) => {
    const { role } = this.state;
    this.setState({
      role: {
        ...role,
        [name]: value,
      },
    });
  };

  permissionChange = (value, checked) => {
    const { permissions, role } = this.state;
    let selectedPermissions = role.permissions ? [...role.permissions] : [];
    if (checked) {
      const currentSelected = permissions.filter((item) => item.id === value);
      selectedPermissions.push(...currentSelected);
    } else {
      selectedPermissions = selectedPermissions.filter((item) => item.id !== value);
    }
    this.setState({
      role: {
        ...role,
        permissions: [...selectedPermissions],
      },
    });
  };

  getPermissionCheckList = () => {
    const { permissions, role } = this.state;
    const selectedPermissionsCode = role.permissions ? role.permissions.map((item) => item.id) : [];
    return permissions.map((value) => (
      <div key={value.id}>
        <RadioCheckBox
          value={value.id}
          label={<p className="text-neutral-950-opacity-80 body-base inline break-words ml-2">{value.name}</p>}
          type={types.CHECKBOX}
          isChecked={selectedPermissionsCode.indexOf(value.id) !== -1}
          groupName={`permissionchk-${value.id}`}
          onChange={this.permissionChange}
        />
      </div>
    ));
  };

  render() {
    const { role, isEditMode } = this.state;

    return (
      <>
        <div className="mx-8 space-y-5">
          <div>
            <Label id="name" label="Role name" isRequired />
            {this.getInput(role.name, 'name', 'Enter role name')}
          </div>
          <div>
            <Label id="description" label="Description" isRequired />
            {this.getInput(role.description, 'description', 'Enter role description')}
          </div>
          <div>
            <Label id="permissions" label="Select permissions" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-neutral-100 -mx-16 px-16 py-3 border border-neutral-100-opacity-10 max-h-60 overflow-y-auto">
              {this.getPermissionCheckList()}
            </div>
          </div>
          <div className="flex justify-end space-x-3">
            <Button onClick={this.onCancelButtonClick} btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL}>
              Cancel
            </Button>
            <Button onClick={this.onSaveButtonClick} btnShape={ButtonShape.NORMAL} btnType={ButtonType.PRIMARY}>
              {isEditMode ? 'Update' : 'Create'}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

Role.propTypes = {
  role: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    permissions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
};

Role.defaultProps = {
  role: { name: '', description: '', permissions: [] },
  onSave: () => undefined,
  onCancel: () => undefined,
};

export default withCancelRequest(Role);
