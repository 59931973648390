import PropTypes from 'prop-types';
import merge from 'lodash/merge';

import Chart from 'components/patterns/HighChart/Chart/Chart';
import SETTINGS from '../Settings';

const SpineChart = ({ data, chartTitle, chartSize, seriesColor, options, dataTestId }) => {
  const getChartOptions = () => {
    const defaultOptions = {
      chart: {
        type: 'spline',
        backgroundColor: SETTINGS.COLORS.mist,
        height: chartSize,
      },
      title: {
        text: chartTitle,
        align: 'left',
        margin: 50,
      },
      xAxis: {
        title: '',
        categories: data.map((i) => i.x),
        gridLineWidth: 1,
      },
      yAxis: {
        title: '',
      },
      series: [
        {
          name: chartTitle,
          data: data.map((i) => i.y),
          color: seriesColor,
        },
      ],
      legend: {
        enabled: false,
      },
      tooltip: {
        backgroundColor: SETTINGS.COLORS.lightAir,
        borderColor: SETTINGS.COLORS.lightAir,
        borderRadius: 8,
      },
      plotOptions: {
        series: {
          borderRadius: 4,
          lineWidth: 3,
        },
        spline: {
          marker: {
            enabled: false,
          },
        },
      },
    };

    return merge({}, defaultOptions, options);
  };

  return <Chart dataTestId={dataTestId} options={getChartOptions()} />;
};

SpineChart.propTypes = {
  dataTestId: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.string, y: PropTypes.number })).isRequired,
  chartTitle: PropTypes.string,
  chartSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seriesColor: PropTypes.string,
  options: PropTypes.object,
};

SpineChart.defaultProps = {
  dataTestId: '',
  chartTitle: '',
  chartSize: '100%',
  seriesColor: SETTINGS.COLORS.primary,
  options: {},
};

export default SpineChart;
