import { useMemo } from 'react';
import BaseSelect from 'lib/BaseSelect';
import { findOption } from 'lib/BaseSelect/utils';
import { DealTypesList } from 'consts/dealTypesList';
import { FilterDealTypeProps } from './FilterDealType.types';

const FilterDealType: React.FC<FilterDealTypeProps> = ({ dealType, dealTypesList, changeFilters }) => {
  const selectedDealType = useMemo(() => findOption(dealTypesList, 'code', dealType), [dealType, dealTypesList]);

  return (
    <BaseSelect
      dataTestId="deal-type-select"
      name="dealType"
      options={dealTypesList}
      onSelect={(_, value: DealTypesList[number]) => {
        changeFilters({ dealType: value?.code });
      }}
      placeholder="Select deal type"
      selectedValue={selectedDealType}
      isClearable
    />
  );
};
export default FilterDealType;
