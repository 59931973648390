/* eslint-disable sonarjs/cognitive-complexity */
import SVG from 'react-inlinesvg';
import * as lookups from 'modules/api/lookups';
import Session from 'modules/Session';
import { getProductCategories } from 'modules/api/content';
import Input from 'lib/Input';
import { InputType } from 'components/common/types/Input.types';
import { CodeNameModel } from 'components/common/types';
import withCancelRequest from 'components/hocs/withCancelRequest';
import AsyncSelect from 'components/patterns/AsyncSelect';
import closeSquareSvg from 'assets/icons/close_square.svg';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import { useSelector } from 'react-redux';
import { getIsReseller } from 'store/publisher/selectors';
import { FilterAdvertiser, FilterBrand } from 'components/common/FilterArea/Filters';
import AdvancedFiltersProps from './AdvancedFilters.types';

const AdvancedFilters: React.FC<AdvancedFiltersProps> = ({
  onChange,
  cancelFunctions,
  productFormatOptions,
  advancedFilterData: { advertiser, brand, productCategory, productFormat, dealIds, width, height },
  onClearAll,
  changeFilters,
}) => {
  const isReseller = useSelector(getIsReseller);
  const onLoadLookupData = async (dropdownName: string, query: string): Promise<[]> => {
    try {
      if (dropdownName === 'dealIds') {
        if (query.length < 2) {
          return [];
        }
        return await lookups.getCreativeAdminDealIdLookupData(cancelFunctions, Session.getEnvironmentId(), query);
      }

      return await lookups.getLookupData({ cancelFunctions, lookupURLName: dropdownName, query });
    } catch (error) {
      return [];
    }
  };

  const onProductCategoriesLookup = async (_dropdownName: string, query: string): Promise<[]> => {
    if (query.length < 3) return [];
    try {
      return await getProductCategories(cancelFunctions, query);
    } catch {
      return [];
    }
  };

  return (
    <div className="contents">
      {!isReseller && (
        <>
          <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100 mr-4">
            <FilterAdvertiser changeFilters={changeFilters} selectedAdvertiser={advertiser} />
          </div>
          <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100 ">
            <FilterBrand changeFilters={changeFilters} brand={brand} />
          </div>
          <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100 ">
            <AsyncSelect
              dataTestId="productCategories-input"
              name="productCategory"
              loadData={onProductCategoriesLookup}
              placeholder="Select product category"
              selectedValue={productCategory}
              onSelect={(dropdownName: string, value: CodeNameModel) => onChange(value, dropdownName)}
            />
          </div>
          <div className="flex-grow flex-shrink-0 min-w-62.5  max-w-100 ">
            <AsyncSelect
              dataTestId="product-select"
              name="productFormat"
              loadData={onLoadLookupData}
              defaultOptions={productFormatOptions}
              selectedValue={productFormat?.code ? productFormat : null}
              placeholder="Format"
              onSelect={(name: string, value: string) => onChange(value, name)}
            />
          </div>
        </>
      )}
      <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100 xl:max-w-88">
        <AsyncSelect
          dataTestId="deal-filter"
          name="dealIds"
          loadData={onLoadLookupData}
          selectedValue={dealIds}
          placeholder="Deal ID"
          onSelect={(name: string, value: string) => onChange(value, name)}
          multiple
        />
      </div>

      <div className="flex space-x-4 flex-grow flex-shrink-0 self-start max-w-100">
        <Input
          name="width"
          value={width}
          onChange={(value: string) => onChange(value, 'width')}
          placeholder="Width"
          type={InputType.NUMBER}
        />
        <Input
          name="height"
          value={height}
          onChange={(value: string) => onChange(value, 'height')}
          placeholder="Height"
          type={InputType.NUMBER}
        />
      </div>

      <div className="flex-grow text-right">
        <Button onClick={onClearAll} btnShape={ButtonShape.NORMAL} btnType={ButtonType.LIGHT}>
          <SVG src={closeSquareSvg} className="text-primary" />
          <span>Clear all</span>
        </Button>
      </div>
    </div>
  );
};

export default withCancelRequest(AdvancedFilters);

/* eslint-enable sonarjs/cognitive-complexity */
