import { useRef } from 'react';

import Deal from 'components/pages/DealWithLines/common/Main/Deal';
import Line from 'components/pages/DealWithLines/common/Main/Line';
import ControllerBar from './ControllerBar';

const Main = () => {
  const dealRef = useRef(null);
  return (
    <>
      <Deal ref={dealRef} />
      <Line />
      <ControllerBar dealRef={dealRef} />
    </>
  );
};

export default Main;
