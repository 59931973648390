import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import binSvg from 'assets/icons/bin.svg';
import { RootState } from 'store';
import { deleteCreative } from 'modules/api/content';
import { useToggle } from 'customHooks/useToggle';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import Modal from 'components/patterns/Modal';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import { DeleteRestoreCreativeProps } from './ButtonRenderer.types';

export const DeleteCreative: React.FC<DeleteRestoreCreativeProps> = ({ data, onComplete }) => {
  const deleteDialog = useToggle();
  const cancelFunctions = useCancelRequest();
  const environmentId = useSelector((state: RootState) => state.environment.environmentId);

  const onDelete = async (): Promise<void> => {
    try {
      const marketId = data.market.find(({ environment }) => environment === environmentId)?.id;
      await deleteCreative(cancelFunctions, marketId);

      onComplete();
    } finally {
      deleteDialog.close();
    }
  };

  return (
    <>
      <Button
        onClick={deleteDialog.open}
        btnShape={ButtonShape.NORMAL}
        btnType={ButtonType.PRIMARY}
        label="Delete Creative"
        dataTestId={`delete-creative-button-${data.id}`}
      >
        <SVG src={binSvg} />
      </Button>

      <Modal
        dataTestId="delete-creative-modal"
        isOpen={deleteDialog.isOpen}
        icon={<SVG src={binSvg} className="text-pinkRed-500" />}
        title="Delete Creative?"
        actionButtons={
          <>
            <Button
              btnType={ButtonType.PRIMARY}
              btnShape={ButtonShape.NORMAL}
              onClick={deleteDialog.close}
              label="Cancel"
            >
              Cancel
            </Button>
            <Button btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL} onClick={onDelete} label="Delete">
              Delete
            </Button>
          </>
        }
      >
        <p className="ml-8 body-base text-neutral-950-opacity-60">Are you sure you want to delete this creative?</p>
      </Modal>
    </>
  );
};
