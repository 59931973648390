const utcPlusOneHour = 'CET';
const TIMEZONES = [
  {
    tzAbr: 'GMT',
    code: 'Europe/London',
    iso2CountryCode: 'GB',
  },
  {
    tzAbr: 'ET',
    code: 'US/Eastern',
    iso2CountryCode: 'US',
  },
  {
    tzAbr: 'CT',
    code: 'US/Central',
    iso2CountryCode: 'US',
  },
  {
    tzAbr: 'PT',
    code: 'US/Pacific',
    iso2CountryCode: 'US',
  },
  {
    tzAbr: 'WET',
    code: 'Europe/Lisbon',
    iso2CountryCode: 'PT',
  },
  {
    tzAbr: utcPlusOneHour,
    code: 'Europe/Amsterdam',
    iso2CountryCode: 'NL',
  },
  {
    tzAbr: 'EET',
    code: 'Europe/Helsinki',
    iso2CountryCode: 'FI',
  },
  {
    tzAbr: utcPlusOneHour,
    code: 'Europe/Madrid',
    iso2CountryCode: 'ES',
  },
  {
    tzAbr: utcPlusOneHour,
    code: 'Europe/Rome',
    iso2CountryCode: 'IT',
  },
  {
    tzAbr: utcPlusOneHour,
    code: 'Europe/Berlin',
    iso2CountryCode: 'DE',
  },
  {
    tzAbr: 'HKT',
    code: 'Asia/Hong_Kong',
    iso2CountryCode: 'CN',
  },
  {
    tzAbr: 'SGT',
    code: 'Asia/Singapore',
    iso2CountryCode: 'SG',
  },
  {
    tzAbr: utcPlusOneHour,
    code: 'Europe/Brussels',
    iso2CountryCode: 'BE',
  },
  {
    tzAbr: utcPlusOneHour,
    code: 'Europe/Paris',
    iso2CountryCode: 'FR',
  },
  {
    tzAbr: utcPlusOneHour,
    code: 'Europe/Zurich',
    iso2CountryCode: 'CH',
  },
  {
    tzAbr: 'AEST',
    code: 'Australia/Sydney',
    iso2CountryCode: 'AU',
  },
  {
    tzAbr: 'NZST',
    code: 'Pacific/Auckland',
    iso2CountryCode: 'NZ',
  },
  {
    tzAbr: 'CHAST',
    code: 'Pacific/Chatham',
    iso2CountryCode: 'NZ',
  },
  {
    tzAbr: utcPlusOneHour,
    code: 'Europe/Copenhagen',
    iso2CountryCode: 'DK',
  },
  {
    tzAbr: 'IST',
    code: 'Asia/Kolkata',
    iso2CountryCode: 'IN',
  },
  {
    tzAbr: 'BRT',
    code: 'America/Sao_Paulo',
    iso2CountryCode: 'BR',
  },
  {
    tzAbr: 'BRT',
    code: 'Brazil/East',
    iso2CountryCode: 'BR',
  },
  {
    tzAbr: utcPlusOneHour,
    code: 'Europe/Oslo',
    iso2CountryCode: 'NO',
  },
  {
    tzAbr: utcPlusOneHour,
    code: 'Europe/Vienna',
    iso2CountryCode: 'AT',
  },
  {
    tzAbr: 'SAST',
    code: 'Africa/Johannesburg',
    iso2CountryCode: 'ZA',
  },
  {
    tzAbr: 'CST',
    code: 'Asia/Shanghai',
    iso2CountryCode: 'CN',
  },
  {
    tzAbr: 'CST',
    code: 'America/Mexico_City',
    iso2CountryCode: 'MX',
  },
  {
    tzAbr: 'COT',
    code: 'America/Bogota',
    iso2CountryCode: 'CO',
  },
];

export default TIMEZONES;
