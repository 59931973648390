import { TradingModel } from 'consts/tradingModel';
import { tradingModelOptions } from 'consts/tradingModelOptions';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import { FilterTradingModelProps, TradingModelFilters } from './FilterTradingModel.types';

const FilterTradingModel: React.FC<FilterTradingModelProps> = ({ selectedTradingModel, changeFilters }) => {
  return (
    <MultipleSwitchBox
      dataTestId="trading-switch"
      source={tradingModelOptions}
      selectedValue={selectedTradingModel}
      textKey="label"
      valueKey="value"
      onChange={(value: TradingModel) => changeFilters({ tradingModel: value } as Partial<TradingModelFilters>)}
    />
  );
};

export default FilterTradingModel;
