import { useSelector } from 'react-redux';

import { isFormValid } from 'components/pages/DealWithLines/common/getActionConditions';
import DealSummary from '../../DealSummary';

const DealAvailabilityInfo = () => {
  const dealManagementState = useSelector((state) => state.dealManagement);
  const {
    temporaryDealId,
    isNewDeal,
    commonDeal: {
      dealType,
      summary: { availability },
      bids: { bidRequests, bidResponses },
    },
    isForecastedAllocation,
  } = dealManagementState;

  return (
    <DealSummary
      availability={availability}
      bidRequests={bidRequests}
      bidResponses={bidResponses}
      showNetGrossValue
      isForecastedAllocation={isForecastedAllocation}
      dealType={dealType}
      isAvailabilityReportDisabled={(isNewDeal && temporaryDealId === '') || !isFormValid(dealManagementState)}
      isFrameListDisabled={!isFormValid(dealManagementState)}
    />
  );
};

export default DealAvailabilityInfo;
