import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CodeNameModel } from 'components/common/types';
import { Advertiser, BrandWithProductCategory, DealStatus, DealType } from 'components/common/types/Deal.types';
import { LossType } from 'consts/lossType';
import { TradingModel, TradingTimeModel } from 'consts/tradingModel';

export interface DealDashboardFilters {
  advertiser: Advertiser | null;
  brand: (BrandWithProductCategory & { advertisers: Advertiser[] }) | null;
  creative: string;
  deal: {
    code: string;
    internalId: string;
    name: string;
  } | null;
  dealType: DealType;
  dsp: CodeNameModel | null;
  endDate: Date;
  lossCode: string;
  lossType: LossType;
  startDate: Date;
  status: DealStatus;
  time: TradingTimeModel;
  tradingModel: TradingModel;
}

export interface DealDashboardState {
  filters: DealDashboardFilters;
  isChartsOpen: boolean;
}

export const initialState: DealDashboardState = {
  filters: {
    advertiser: null,
    brand: null,
    creative: '',
    deal: null,
    dealType: '' as DealType,
    dsp: null,
    endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    lossCode: '',
    lossType: '' as LossType,
    startDate: new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0)),
    status: '' as DealStatus,
    time: TradingTimeModel.PLAY,
    tradingModel: '' as TradingModel,
  },
  isChartsOpen: true,
};

export const dealDashboardSlice = createSlice({
  name: 'dealDashboard',
  initialState,
  reducers: {
    changeFilters: (state, action: PayloadAction<Partial<DealDashboardFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state) => {
      state.filters = { ...initialState.filters };
    },
    resetDealDashboardState: () => ({ ...initialState }),
    toggleAllCharts: (state) => {
      state.isChartsOpen = !state.isChartsOpen;
    },
  },
});

export const { changeFilters, resetFilters, resetDealDashboardState, toggleAllCharts } = dealDashboardSlice.actions;

export default dealDashboardSlice.reducer;
