import { dealTypesList } from 'consts/dealTypesList';
import { usePageFilters } from 'customHooks/usePageFilters';
import { FeatureFlags } from 'components/common/types/Features.types';
import { DealStatus } from 'components/common/types/Deal.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import {
  FilterAdvertiser,
  FilterBrand,
  FilterDealId,
  FilterDealType,
  FilterDateRange,
  FilterStatus,
  FilterTradingModel,
  FilterTradingTime,
  FilterSpecialist,
  FilterAgency,
  FilterDsp,
} from 'components/common/FilterArea/Filters';
import FilterArea from 'components/common/FilterArea';
import { DealDashboardFilters } from './Filters.types';

const STATUSES = [
  {
    code: DealStatus.LIVE,
    name: 'Live',
  },
  {
    code: DealStatus.ENDED,
    name: 'Ended',
  },
  {
    code: DealStatus.TERMINATED,
    name: 'Terminated',
  },
];

const Filters: React.FC = () => {
  const {
    changeFilters,
    filters: {
      deal,
      status,
      dealType,
      startDate,
      endDate,
      tradingModel,
      time,
      dsp,
      brand,
      advertiser,
      agency,
      specialist,
    },
  } = usePageFilters<DealDashboardFilters>();

  return (
    <FilterArea>
      <div className="grid grid-cols-3 gap-x-4 mr-4">
        <div className="flex flex-col gap-y-4">
          <FilterDealId changeFilters={changeFilters} deal={deal} />
          <FilterBrand changeFilters={changeFilters} brand={brand} />
        </div>
        <div className="flex flex-col gap-y-4">
          <FilterDsp selectedValue={dsp} onChange={(name, value) => changeFilters({ [name]: value })} />
          <FilterSpecialist specialist={specialist} changeFilters={changeFilters} />
        </div>
        <div className="flex flex-col gap-y-4">
          <FilterDealType dealType={dealType} changeFilters={changeFilters} dealTypesList={dealTypesList} />
          {useHasFeatureAccess(FeatureFlags.INSIGHTS_STATUS_FILTER) ? (
            <FilterStatus statusesList={STATUSES} changeFilters={changeFilters} status={status} />
          ) : null}
        </div>
      </div>
      <div className="flex flex-row gap-x-4 justify-between">
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-row gap-x-4 justify-start">
            <FilterAgency changeFilters={changeFilters} agency={agency} />
            <FilterAdvertiser changeFilters={changeFilters} selectedAdvertiser={advertiser} />
          </div>
          <FilterDateRange changeFilters={changeFilters} selectedStartDate={startDate} selectedEndDate={endDate} />
        </div>

        <div className="flex flex-col justify-end items-end gap-y-4">
          <FilterTradingModel selectedTradingModel={tradingModel} changeFilters={changeFilters} />
          <FilterTradingTime changeFilters={changeFilters} selectedTime={time} />
        </div>
      </div>
    </FilterArea>
  );
};

export default Filters;
