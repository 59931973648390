import { useDispatch, useSelector } from 'react-redux';

import { useCancelRequest } from 'customHooks/useCancelRequest';
import { NotificationMessages } from 'consts/notifications';
import withCancelRequest from 'components/hocs/withCancelRequest';
import AsyncSelect from 'components/patterns/AsyncSelect';
import FileUpload from 'components/patterns/FileUpload';
import { isReadOnly } from 'utils/isReadOnly';
import { getLookupData } from 'modules/api/lookups';
import { FILE_SIZE_10MB } from 'modules/api/constants';
import { ACCEPTED_FILE_TYPE_XLS } from 'consts/fileType';
import { notifyError } from 'store/notification/reducer';
import { Store } from 'components/common/types/Store.types';
import { changeDealLineProximityPostcode } from 'store/dealManagement/reducer';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox/MultipleSwitchBox';
import { DISTANCES } from 'consts/distances';
import RadioCheckBox, { types } from 'components/patterns/RadioCheckBox';
import { DealStatus, Tag } from 'components/common/types/Deal.types';
import { FileError, FileSuccess } from 'components/common/types/File.types';

import Distance from '../Distance';

const PostCode: React.FC = () => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const terminated = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.terminated);
  const { tags, files, distance, unit, include, isFileUploadActive } = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.proximity.postCode,
  );

  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled, terminated) && !isPendingReservation;

  const fetchPostCodes = async (dropdownName: string, query: string): Promise<[] | Tag[]> => {
    if (query.length < 2) return [];

    try {
      return await getLookupData({
        cancelFunctions,
        lookupURLName: dropdownName,
        query,
        proximityMode: true,
      });
    } catch (error) {
      return [];
    }
  };

  const onFileChange = (acceptedFiles: FileSuccess[], rejectedFiles: FileError[]): void => {
    if (rejectedFiles.length) {
      dispatch(notifyError({ message: NotificationMessages.INVALID_UPLOAD }));
      return;
    }

    const [uploadedFile] = acceptedFiles;
    uploadedFile.file = uploadedFile;
    if (uploadedFile) {
      dispatch(changeDealLineProximityPostcode({ files: [uploadedFile] }));
    }
  };

  return (
    <div data-test-id="postCode">
      <p className="sub-header-base text-neutral-900 mb-4">Postcode</p>
      <div className="flex items-end gap-5">
        <div className="inline-flex flex-1 items-end">
          <div className="mb-2">
            <RadioCheckBox
              type={types.RADIO}
              value="tags"
              isChecked={!isFileUploadActive}
              groupName="postCodes"
              onChange={() => {
                dispatch(changeDealLineProximityPostcode({ tags: [], files: [], isFileUploadActive: false }));
              }}
              isDisabled={readOnly}
              dataTestId="postcode-text-radio"
            />
          </div>
          <div className="w-full">
            <p className="sub-header-base text-neutral-950-opacity-60 mb-2">Postcode</p>
            <AsyncSelect
              name="postcode"
              loadData={fetchPostCodes}
              selectedValue={tags}
              placeholder="Postcode"
              onSelect={(_name: string, value: Tag[]) => {
                dispatch(changeDealLineProximityPostcode({ tags: value }));
              }}
              multiple
              isDisabled={readOnly || isFileUploadActive}
              dataTestId="postcode-text-input"
            />
          </div>
        </div>
        <div className="inline-flex flex-1 items-end">
          <div className="mb-2">
            <RadioCheckBox
              type={types.RADIO}
              value="fileUpload"
              isChecked={isFileUploadActive}
              groupName="postCodes"
              onChange={() => {
                dispatch(changeDealLineProximityPostcode({ tags: [], files: [], isFileUploadActive: true }));
              }}
              isDisabled={readOnly}
              dataTestId="postcode-file-upload-radio"
            />
          </div>
          <div className="w-full">
            <p className="sub-header-base text-neutral-950-opacity-60 mb-2">List upload</p>
            <FileUpload
              acceptFileType={ACCEPTED_FILE_TYPE_XLS}
              maxFileSize={FILE_SIZE_10MB}
              placeholder={(files && files[0]?.name) || 'List name'}
              onFileChange={onFileChange}
              isDisabled={readOnly || !isFileUploadActive}
            />
          </div>
        </div>
        <Distance
          value={distance}
          onChange={(value) => {
            dispatch(changeDealLineProximityPostcode({ distance: value }));
          }}
          isDisabled={readOnly}
          dataTestId="postcode-distance"
        />
        <MultipleSwitchBox
          source={Object.entries(DISTANCES).map(([name, code]) => ({ name, code }))}
          selectedValue={unit}
          onChange={(value) => dispatch(changeDealLineProximityPostcode({ unit: value }))}
          isDisabled={readOnly}
          dataTestId="postcode-distance-switchbox"
        />
        <MultipleSwitchBox
          source={[
            { code: 'include', name: 'Include' },
            { code: 'exclude', name: 'Exclude' },
          ]}
          selectedValue={include ? 'include' : 'exclude'}
          onChange={(value) => dispatch(changeDealLineProximityPostcode({ include: value === 'include' }))}
          isDisabled={readOnly}
          dataTestId="postcode-include-switchbox"
        />
      </div>
    </div>
  );
};

export default withCancelRequest(PostCode);
