import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import editIcon from 'assets/icons/edit.svg';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';

const EditRenderer = ({ data }) => {
  const navigate = useNavigate();

  if (!data.id) return '';

  return (
    <div className="table-action-button">
      <Button
        dataTestId={`edit-button-${data.code}`}
        btnShape={ButtonShape.CIRCLE}
        btnType={ButtonType.ICON}
        onClick={() => navigate(`/content-management/creative-set-details/${data.id}`)}
      >
        <SVG src={editIcon} />
      </Button>
    </div>
  );
};

EditRenderer.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
  }),
};

EditRenderer.defaultProps = {
  data: {},
};

export default EditRenderer;
