import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Action, RuntimeFilterOp } from '@thoughtspot/visual-embed-sdk';

import { RootState } from 'store';
import labsSvg from 'assets/icons/labs.svg';
import Button, { Color, Size } from 'lib/Button';
import Overlay from 'lib/Overlay';
import LiveboardVisuals from 'components/patterns/LiveboardVisuals';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { initialiseThoughtspot } from 'utils/thoughtspotInit';
import { getDealHealthLiveboardId } from './selectors';

export const DealHealthLabsReport: React.FC = () => {
  const cancelFunctions = useCancelRequest();

  const dealHealthLiveboardId = useSelector(getDealHealthLiveboardId);
  const { localeCode, marketId } = useSelector((state: RootState) => state.publisher.configuration);

  const [showLiveboard, setShowLiveboard] = useState(false);

  initialiseThoughtspot(cancelFunctions, localeCode);

  const filterByMarket = [
    {
      columnName: 'Market',
      operator: RuntimeFilterOp.EQ,
      values: [marketId],
    },
  ];

  return (
    <>
      <Button
        label="Deal Health LABS"
        color={Color.PINK}
        size={Size.LARGE}
        onClick={() => setShowLiveboard(true)}
        svg={labsSvg}
        svgClassnames="mr-1"
      />

      {showLiveboard && (
        <Overlay
          onClose={() => setShowLiveboard(false)}
          isOpen={showLiveboard}
          overflowYScroll
          header={
            <header>
              <h3 className="sub-header-lg text-neutral-900">Deal Health</h3>
            </header>
          }
        >
          <LiveboardVisuals
            liveboardId={dealHealthLiveboardId}
            customizations={{
              style: {
                customCSS: {
                  rules_UNSTABLE: {
                    "[data-tooltip-content*='Column name: Market']": {
                      display: 'none',
                    },
                  },
                },
              },
            }}
            visibleActions={[Action.CreateMonitor, Action.PersonalisedViewsDropdown]}
            filterByOptions={filterByMarket}
          />
        </Overlay>
      )}
    </>
  );
};
