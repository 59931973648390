import { useDispatch, useSelector } from 'react-redux';

import { changeDealCurrentLineData } from 'store/dealManagement/reducer';

import { isReadOnly } from 'utils/isReadOnly';
import Impressions from 'components/common/Deal/Target/Impressions';
import Budget from 'components/common/Deal/Target/Budget';
import Frames from 'components/common/Deal/Target/Frames';
import Sweep from 'components/common/Deal/Target/Sweep';
import ShareOfTime from 'components/common/Deal/Target/ShareOfTime';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { DealType, DealStatus } from 'components/common/types/Deal.types';
import { getIsAdServer } from 'store/publisher/selectors';
import { getSotFloor, getSotCeiling } from './getSotCalculations';

const Target = () => {
  const dispatch = useDispatch();
  const isEditingDisabled = useSelector((state) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);
  const dealType = useSelector((state) => state.dealManagement.commonDeal.dealType);
  const sot = useSelector((state) => state.dealManagement.commonDeal.currentLine.sot);
  const preservedAvailability = useSelector(
    (state) => state.dealManagement.commonDeal.currentLine.preservedAvailability,
  );
  const terminated = useSelector((state) => state.dealManagement.commonDeal.currentLine.terminated);
  const isAdServerMarket = useSelector(getIsAdServer);
  const dealConfig = useSelector((state) => state.dealConfig);

  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled, terminated);

  const autoCalculateFloorAndCeiling = (targeted, allocated) => {
    if (sot) {
      dispatch(changeDealCurrentLineData({ sotFloor: Number(sot), sotCeiling: Number(sot) }));
    } else {
      const sotFloor = getSotFloor(targeted, allocated, preservedAvailability, dealConfig);
      const sotCeiling = getSotCeiling(sotFloor, dealConfig);
      dispatch(changeDealCurrentLineData({ sotFloor, sotCeiling }));
    }
  };

  return (
    <div className="grid grid-cols-12 px-12 py-6">
      {useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET) ||
      dealType === DealType.GUARANTEED ||
      bookingStatusCode === DealStatus.PENDING_RESERVATION ||
      !isAdServerMarket ? (
        <div className="col-span-7 pr-28 grid grid-flow-row gap-11 content-start">
          <div>
            <Impressions autoCalculateFloorAndCeiling={autoCalculateFloorAndCeiling} disabled={readOnly} />
            <Budget autoCalculateFloorAndCeiling={autoCalculateFloorAndCeiling} disabled={readOnly} />
          </div>
          <Frames disabled={readOnly} />
        </div>
      ) : null}
      <div className="col-span-5 grid grid-flow-row gap-11 content-start">
        {useHasFeatureAccess(FeatureFlags.SOT_TARGETING) && (
          <ShareOfTime autoCalculateFloorAndCeiling={autoCalculateFloorAndCeiling} disabled={readOnly} />
        )}
        <Sweep disabled={readOnly} />
      </div>
    </div>
  );
};

export default Target;
