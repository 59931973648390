import { URLS, HTTP_METHODS } from 'modules/api/constants';
import createRequest from 'modules/api/request';

export const getRoleList = async (cancelFunctions) => {
  const { send, cancel } = createRequest({
    url: URLS().GET_ROLE_LIST(),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.GET_ROLE_LIST) cancelFunctions.GET_ROLE_LIST();
  cancelFunctions.GET_ROLE_LIST = cancel;

  const result = await send();

  return result.data;
};

const getRoleRequestObj = (roleObj) => {
  return {
    ...roleObj,
    permissions: roleObj.permissions.map((item) => {
      return item.id;
    }),
  };
};

export const createRole = async (cancelFunctions, roleObj) => {
  const { send, cancel } = createRequest({
    url: URLS().CREATE_ROLE(),
    method: HTTP_METHODS.POST,
    data: getRoleRequestObj(roleObj),
  });

  if (cancelFunctions.CREATE_ROLE) cancelFunctions.CREATE_ROLE();
  cancelFunctions.CREATE_ROLE = cancel;

  const result = await send();

  return result.data;
};

export const updateRole = async (cancelFunctions, roleObj) => {
  const { send, cancel } = createRequest({
    url: URLS().UPDATE_ROLE(roleObj.id),
    method: HTTP_METHODS.PUT,
    data: getRoleRequestObj(roleObj),
  });

  if (cancelFunctions.UPDATE_ROLE) cancelFunctions.UPDATE_ROLE();
  cancelFunctions.UPDATE_ROLE = cancel;

  const result = await send();

  return result.data;
};

export const deleteRole = async (cancelFunctions, roleId) => {
  const { send, cancel } = createRequest({
    url: URLS().DELETE_ROLE(roleId),
    method: HTTP_METHODS.DELETE,
  });

  if (cancelFunctions.DELETE_ROLE) cancelFunctions.DELETE_ROLE();
  cancelFunctions.DELETE_ROLE = cancel;

  const result = await send();

  return result.data;
};

export const getPermissionList = async (cancelFunctions) => {
  const { send, cancel } = createRequest({
    url: URLS().GET_PERMISSIONS_LIST(),
    method: HTTP_METHODS.GET,
  });

  if (cancelFunctions.GET_PERMISSIONS_LIST) cancelFunctions.GET_PERMISSIONS_LIST();
  cancelFunctions.GET_PERMISSIONS_LIST = cancel;

  const result = await send();

  return result.data;
};
