import caretDown from 'assets/icons/chevron_down.svg';
import cx from 'classnames';
import { useState } from 'react';
import SVG from 'react-inlinesvg';
import { HeaderBackgroundColor, HeaderProps, Sort, TextColor } from './TableHeader.types';

export const TableHeader: React.FC<HeaderProps> = ({
  backgroundColor = HeaderBackgroundColor.PRIMARY,
  label,
  sortable = false,
  textColor = TextColor.PRIMARY,
  ...headerParams
}) => {
  const [activeSort, setActiveSort] = useState<Sort | null>(null);

  const handleSortColumn = (): void => {
    const sort = headerParams.column.getSort();

    if (sort === Sort.ASC) {
      headerParams.setSort(Sort.DESC);
      setActiveSort(Sort.DESC);
    } else if (sort === Sort.DESC) {
      headerParams.setSort(null);
      setActiveSort(null);
    } else {
      headerParams.setSort(Sort.ASC);
      setActiveSort(Sort.ASC);
    }
  };

  return (
    <div
      className={`flex items-center justify-between px-2 py-1.5 text-neutral-950 rounded-sm m-[2px] w-full ${backgroundColor}`}
    >
      <span className={`sub-header-sm ${textColor}`}>{label}</span>
      {sortable && (
        <button
          className="flex flex-col"
          onClick={handleSortColumn}
          type="button"
          data-dd-action-name={`Allocation report / Sort frame ID by ${
            activeSort === 'desc' ? 'Ascending' : 'Descending'
          } order`}
        >
          <SVG
            src={caretDown}
            className={cx('rotate-180 -mb-1 h-3 w-3', {
              'text-neutral-950': activeSort === Sort.DESC,
              'text-neutral-600': !activeSort || activeSort === Sort.ASC,
            })}
          />
          <SVG
            src={caretDown}
            className={cx('h-3 w-3', {
              'text-neutral-950': activeSort === Sort.ASC,
              'text-neutral-600': !activeSort || activeSort === Sort.DESC,
            })}
          />
        </button>
      )}
    </div>
  );
};
