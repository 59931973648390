import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TradingTimeModel } from 'consts/tradingModel';
import { ChangeFilterPayload, PagesFilters } from './types';

const initialState: PagesFilters = {
  'insights/dashboard': {
    startDate: new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0)),
    endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    time: TradingTimeModel.PLAY,
    tradingModel: '',
  },
  'insights/deal-performance': {
    startDate: new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0)),
    endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    time: TradingTimeModel.PLAY,
  },
};

const pagesFiltersSlice = createSlice({
  name: 'pagesFilters',
  initialState,
  reducers: {
    changeFilterValue: (state: PagesFilters, action: PayloadAction<ChangeFilterPayload>) => {
      return {
        ...state,
        ...Object.entries(action.payload).reduce((result, [pageName, filters]) => {
          return {
            ...result,
            [pageName]: {
              ...(state[pageName] ?? {}),
              ...filters,
            },
          };
        }, {}),
      };
    },
    resetPageFilters: (state: PagesFilters, action: PayloadAction<string>) => {
      return {
        ...state,
        [action.payload]: {},
      };
    },
  },
});

export const { changeFilterValue, resetPageFilters } = pagesFiltersSlice.actions;

export default pagesFiltersSlice.reducer;
