import { useEffect } from 'react';

export const useOutsideClick = (ref: React.MutableRefObject<HTMLDivElement>, callback: () => void): void => {
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent): void => {
      if (ref.current && !ref.current.contains(e.target as Element)) callback();
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });
};
