import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client.d';
import * as config from 'config';
import { PermissionsEnum } from 'consts/permissions';
import { LocalStoredAuthData } from './Auth.types';

let auth0Instance: Auth0Client;

const init = async (): Promise<void> => {
  if (auth0Instance) {
    return;
  }

  // eslint-disable-next-line no-useless-catch
  try {
    auth0Instance = await createAuth0Client({
      audience: config.auth0.audience,
      client_id: config.auth0.clientID,
      domain: config.auth0.domain,
      redirect_uri: getRedirectUri(),
      scope: permissionScope(),
      cacheLocation: 'localstorage',
    });
    // eslint-disable-next-line sonarjs/no-useless-catch
  } catch (error) {
    throw error;
  }
};

const getData = (): LocalStoredAuthData => {
  const emptyResult = {
    userName: undefined,
    email: undefined,
    token: undefined,
    markets: [],
    permissions: [],
  };
  try {
    const auth0Key = Object.keys(localStorage).find((key) => key.includes('auth0')) || '';
    const data = JSON.parse(localStorage.getItem(auth0Key) || '{}');
    if (Object.keys(data).length) {
      return {
        token: data.body.access_token,
        userName: data.body.decodedToken.user.name,
        email: data.body.decodedToken.user.email,
        markets: data.body.decodedToken.user['https://auth.viooh.com/authentication/groups'] || [],
        permissions: data.body.decodedToken.user['https://auth.viooh.com/authentication/permissions'] || [],
      };
    }
    return emptyResult;
  } catch (error) {
    return emptyResult;
  }
};

const redirectToUniversalLoginPage = async (): Promise<void> => {
  await auth0Instance.loginWithRedirect();
};

const isAuthenticated = async (): Promise<boolean> => {
  return auth0Instance.isAuthenticated();
};

const hasPermission = (permission: string): boolean => {
  const { permissions } = getData();
  return permissions.includes(permission);
};

const authenticate = async (): Promise<void> => {
  // eslint-disable-next-line no-useless-catch
  try {
    await auth0Instance.handleRedirectCallback();
    // eslint-disable-next-line sonarjs/no-useless-catch
  } catch (error) {
    throw error;
  }
};

const logout = (): void => {
  auth0Instance.logout({
    returnTo: getLogoutReturnUri(),
  });
};

export default {
  init,
  redirectToUniversalLoginPage,
  isAuthenticated,
  hasPermission,
  authenticate,
  logout,
  getData,
};

/**
 *
 * Private functions
 *
 */

function permissionScope(): string {
  const permissions = Object.values(PermissionsEnum).join(' ');

  return `${config.auth0.scope} ${permissions}`;
}

function getRedirectUri(): string {
  const url = new URL(`${config.app.protocol}://${config.app.host}`);
  if (config.app.port) {
    url.port = config.app.port;
  }
  url.pathname = `auth-callback/`;
  return url.toString();
}

function getLogoutReturnUri(): string {
  const url = new URL(`${config.app.protocol}://${config.app.host}`);
  if (config.app.port) {
    url.port = config.app.port;
  }
  return url.toString();
}
