import PropTypes from 'prop-types';

const Button = ({ onClick }) => (
  <button type="button" className="sub-header-base text-primary mt-2.5 ml-2.5" onClick={onClick}>
    Read more
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func,
};

Button.defaultProps = {
  onClick: () => null,
};

export default Button;
