import { combineReducers } from 'redux';

import creativesAdditionalData from './creativesAdditionalData/reducer';
import creativeSets from './creativeSets/reducer';
import creativeSetDetails from './creativeSetDetails/reducer';
import creatives from './creatives/reducer';

export default combineReducers({
  creativesAdditionalData,
  creativeSets,
  creativeSetDetails,
  creatives,
});
