import { useDispatch, useSelector } from 'react-redux';
import ToggleSwitch, { ToggleTheme } from 'components/patterns/ToggleSwitch';
import { changeProgrammaticDealInfo } from 'store/dealManagement/actions';
import { Store } from 'components/common/types/Store.types';
import { DealStatus } from 'components/common/types/Deal.types';
import DspChip from 'components/common/Deal/DspChip';
import { DspSettings } from 'components/common/types/DspSettings.types';
import { useDspSettings } from 'customHooks/useDspSettings';

const OpenMarketplace: React.FC<DspSettings> = ({ theme = 'LIGHT' }) => {
  const dispatch = useDispatch();
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const isOpenMarketplaceEnabled = useSelector(
    (state: Store) => state.dealManagement.programmatic.enableOpenMarketplace,
  );
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const { isSelectedDspSupportingOpenMarketplace } = useDspSettings();

  if (!isSelectedDspSupportingOpenMarketplace) return null;

  const isToggleDisabled = !(isNewDeal || [DealStatus.PENDING_APPROVAL].includes(bookingStatusCode));

  return (
    <div className="flex justify-between items-center h-8">
      {isOpenMarketplaceEnabled ? (
        <DspChip theme={theme} label="Open marketplace enabled" testId="open-marketplace" />
      ) : (
        <p className="body-base">Enable open marketplace</p>
      )}
      <ToggleSwitch
        onChange={() =>
          dispatch(
            changeProgrammaticDealInfo({
              enableOpenMarketplace: !isOpenMarketplaceEnabled,
            }),
          )
        }
        isChecked={isOpenMarketplaceEnabled}
        isDisabled={isToggleDisabled}
        dataTestId="open-marketplace-switch"
        theme={ToggleTheme[theme]}
      />
    </div>
  );
};

export default OpenMarketplace;
