import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import { changeDealCurrentLineData, toggleDealFormOpen } from 'store/dealManagement/reducer';
import { Store } from 'components/common/types/Store.types';
import DealCpm from 'components/common/Deal/DealCpm';
import ButtonLink from 'components/patterns/ButtonLink/ButtonLink';
import InfoMessage from 'components/common/Deal/InfoMessage';
import chevronRightSvg from 'assets/icons/chevron_right.svg';
import { CardTheme } from 'components/patterns/Card/Card.types';

interface AdsDealLineLevelCpmProps {
  isDisabled: boolean;
}

const AdsDealLineLevelCpm: React.FC<AdsDealLineLevelCpmProps> = ({ isDisabled }) => {
  const dispatch = useDispatch();
  const isCpmCampaignLevel = useSelector((state: Store) => state.dealManagement.isCpmCampaignLevel);
  const isDealFormOpen = useSelector((state: Store) => state.dealManagement.isDealFormOpen);
  const cpm = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.cpm);

  const [shouldScrollIntoDealCpm, setShouldScrollIntoDealCpm] = useState(false);

  const onChangeDealLineCpm = (value: number): void => {
    dispatch(changeDealCurrentLineData({ cpm: value }));
  };

  const onActionButtonClick = (): void => {
    if (!isDealFormOpen) {
      dispatch(toggleDealFormOpen(true));
    }

    const dealForm = document.getElementById('anch-deal');

    if (dealForm) {
      dealForm.scrollIntoView({ behavior: 'smooth' });
    }

    setShouldScrollIntoDealCpm(true);
  };

  useEffect(() => {
    const dealCpm = document.getElementById('anch-deal-cpm');

    if (isDealFormOpen && shouldScrollIntoDealCpm && dealCpm) {
      dealCpm.scrollIntoView({ behavior: 'smooth' });
    }

    setShouldScrollIntoDealCpm(false);
  }, [shouldScrollIntoDealCpm]);

  return (
    <div className="grid grid-cols-2 gap-x-7 xl:gap-x-24 px-12 py-4">
      <DealCpm
        id="deal-line-cpm"
        isDisabled={isDisabled || isCpmCampaignLevel}
        onChange={onChangeDealLineCpm}
        value={cpm}
      />
      <InfoMessage cardTheme={CardTheme.PRIMARY}>
        {isCpmCampaignLevel ? (
          <div className="text-primary-600">
            The CPM has been entered at the Deal level. In order to add a unique CPM for this Line, please de-select the
            CPM at the Deal level
            <div className="flex ml-auto w-fit mt-1">
              <ButtonLink btnColor="text-primary-600" onClick={onActionButtonClick}>
                Take me there
                <SVG src={chevronRightSvg} className="fill-current text-primary ml-2 inline-flex" />
              </ButtonLink>
            </div>
          </div>
        ) : (
          <p>You can add a unique CPM for each of the lines within this deal</p>
        )}
      </InfoMessage>
    </div>
  );
};

export default AdsDealLineLevelCpm;
