import { MotionType } from 'consts/motionType';
import { CodeNameModel, RequiredWith } from './index';
import { Advertiser, BrandWithProductCategory, Dsp } from './Deal.types';
import { FrameTag } from './FrameTag.types';

export interface CreativeMarket {
  agency: CodeNameModel | null;
  assignedTo: null;
  auditLogs: { id: string; createdAt: number }[];
  comments: [];
  commercial: {
    id: string;
    brand: {
      id: string;
      name: string;
    };
    advertiser: {
      id: string;
      environment: string;
      code: string;
      name: string;
    };
  };
  deal: {
    dealId: string;
    id: string;
    inherited?: boolean;
  }[];
  deleted: boolean | null;
  deletedAt: string | null;
  environment: string;
  id: string;
  moderation: Moderation[];
  productCategory: CodeNameModel[];
  productFormat: CodeNameModel[];
  publisher: string;
  specialist: CodeNameModel | null;
  suggestedProductCategory: [];
}

export type CreativeId = string | number;

export enum CreativeErrorType {
  FAILURE = 'FAILURE',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
}

export interface CreativeError {
  result: string;
  reason: string;
}

export interface UpdateCreativeResponseError extends CreativeError {
  id: string;
}

export interface Moderation {
  comment: string;
  id: string;
  status: CreativeStatus;
  updateStamp: number;
  updatedBy: CodeNameModel;
}

export interface FrameSummary {
  inclusionListCount: number;
  exclusionListCount: number;
}

export interface Creative {
  additionalProductCategories: CodeNameModel[];
  additionalProductCategoriesOptions: CodeNameModel[];
  advertiser: Advertiser | null;
  agency: CodeNameModel | null;
  allAdditionalProductCategoriesOptions: CodeNameModel[];
  brand: BrandWithProductCategory | null;
  commercialId: string;
  contentURL: string;
  convertStatus: ConvertStatus;
  dealId: CodeNameModel[];
  deleted: boolean | null;
  deletedAt?: string | null;
  downloadURL: string;
  downloadS3URL: string;
  dsp: Dsp;
  duration: number;
  error?: CreativeError;
  expiredAt?: string;
  externalAdvertiserName: string;
  clickThroughUrl: string;
  externalAgencyId: string;
  externalId: string;
  frameSummary: FrameSummary;
  frameTags: FrameTag[];
  height: number;
  id: CreativeId;
  isExpired?: boolean;
  market: CreativeMarket[];
  marketId: string;
  moderation: Moderation[];
  movement: MotionType;
  previewURL: string;
  productCategoriesOptions: CodeNameModel[];
  productCategory: CodeNameModel | null;
  productFormat: CodeNameModel[];
  specialist: CodeNameModel | null;
  statusCode: CreativeStatus;
  thumbnail: string;
  type: CreativeType;
  uploaded?: number;
  uploadedBy?: string;
  uploadedDate: string;
  uploadedTime: string;
  width: number;
}

export interface CreativesFilters extends AdvancedFilters {
  status: string;
  dsp?: CodeNameModel;
  dealIds?: CodeNameModel[] | [];
  advertiser?: CodeNameModel;
  brand?: CodeNameModel;
  productCategory?: CodeNameModel;
  deleted: boolean;
}

export interface AdvancedFilters {
  status: string;
  startDate: Date | null;
  endDate: Date | null;
  creativeType: string;
  productFormat: CodeNameModel | null;
  externalId: string;
  width: string;
  height: string;
  dealIds?: CodeNameModel[];
  advertiser?: CodeNameModel;
  brand?: CodeNameModel;
  productCategory?: CodeNameModel;
}

export interface CreativesMetaFormModel {
  dealId: CodeNameModel[];
  advertiser: RequiredWith<Advertiser, 'brands'> | null;
  brand: BrandWithProductCategory | null;
  productCategory: CodeNameModel | null;
  agency: CodeNameModel | null;
  specialist: CodeNameModel | null;
  productFormat: CodeNameModel[];
  movement: MotionType | null;
}

export interface CreativeMetaOperationsModel {
  dealId: CreativeMetaOperation;
  productFormat: CreativeMetaOperation;
}

export interface CreativeCountSummary {
  staticCreatives: number;
  motionCreatives: number;
}

export enum CreativeMetaOperation {
  ADD = 'ADD',
  REPLACE = 'REPLACE',
}

export class UpdateCreativesError extends Error {
  constructor(
    message: string,
    readonly errorList: UpdateCreativeResponseError[],
  ) {
    super(message);
    this.name = 'UpdateCreativesError';
  }
}

export type PanelCreative = {
  id: string;
  previewUrl: string;
  status: CreativeStatus;
  thumbnailUrl: string;
  type: CreativeType;
};

export type Panel = {
  channels: string[];
  cities: string[];
  count: number;
  creative: PanelCreative;
  height: number;
  id: string;
  motionType: MotionType;
  width: number;
  order?: number;
  frameCodes?: string[];
};

export enum CreativeType {
  VIDEO = 'VIDEO',
  BANNER = 'BANNER',
  DYNAMIC = 'DYNAMIC',
}

export enum ConvertStatus {
  REQUESTED = 'REQUESTED',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  NOT_STARTED = 'NOT_STARTED',
}

export enum CreativeStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  FLAGGED = 'FLAGGED',
  REJECTED = 'REJECTED',
  INACTIVE = 'INACTIVE',
  UPLOADED = 'UPLOADED',
  DELETED = 'DELETED',
  RESTORED = 'RESTORED',
  SENT_FOR_APPROVAL = 'SENT_FOR_APPROVAL',
}
