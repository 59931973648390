export type ColumnIds = {
  advertiserName: 'ADVERTISER';
  agencyName: 'AGENCY';
  brandName: 'BRAND';
  allocatedImpressions: 'ALLOCATED_IMPRESSIONS';
  cpm: 'CPM';
  dealId: 'DEAL_ID';
  dealTypeCode: 'DEAL_TYPE_CODE';
  dspName: 'DSP_NAME';
  internalId: 'INTERNAL_ID';
  productCategoryName: 'PRODUCT_CATEGORY';
  remainingImpressions: 'REMAINING_IMPRESSIONS';
  startDate: 'START_DATE';
};

export const columnIds: ColumnIds = {
  advertiserName: 'ADVERTISER',
  agencyName: 'AGENCY',
  brandName: 'BRAND',
  allocatedImpressions: 'ALLOCATED_IMPRESSIONS',
  cpm: 'CPM',
  dealId: 'DEAL_ID',
  dealTypeCode: 'DEAL_TYPE_CODE',
  dspName: 'DSP_NAME',
  internalId: 'INTERNAL_ID',
  productCategoryName: 'PRODUCT_CATEGORY',
  remainingImpressions: 'REMAINING_IMPRESSIONS',
  startDate: 'START_DATE',
};

export const sortColumnsBy = (columnId?: keyof ColumnIds): string => {
  if (!columnId) return 'DEAL_ID';
  return columnIds[columnId];
};
