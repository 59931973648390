import { useState } from 'react';
import { AllocationReports } from 'components/common/AllocationReport/AllocationReports/AllocationReports';
import { DealSummaryAllocationReportProps } from './DealSummaryAllocationReport.types';
import { ButtonColor } from '../DealSummaryFrameList/DealSummaryFrameList.types';

export const DealSummaryAllocationReport: React.FC<DealSummaryAllocationReportProps> = ({
  actionButton,
  buttonLabel,
  defaultLevelType,
  isDisabled = false,
  reportType,
}) => {
  const [isAllocationReportOpen, setIsAllocationReportOpen] = useState(false);

  const handleAllocationModalClose = (): void => {
    setIsAllocationReportOpen(false);
  };

  const onActionButtonClick = (): void => setIsAllocationReportOpen(true);

  return (
    <>
      {actionButton?.(onActionButtonClick) ?? (
        <button
          type="button"
          className={ButtonColor.PRIMARY}
          onClick={onActionButtonClick}
          disabled={isDisabled}
          data-test-id="deal-summary-allocation-report-btn"
        >
          {buttonLabel}
        </button>
      )}

      <AllocationReports
        isAllocationReportOpen={isAllocationReportOpen}
        handleAllocationModalClose={handleAllocationModalClose}
        reportType={reportType}
        defaultLevelType={defaultLevelType}
      />
    </>
  );
};
