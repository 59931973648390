import SingleContent from 'components/common/layout/SingleContent';

import UploadCreativeHeader from './UploadCreativeHeader';
import UploadCreativeContent from './UploadCreativeContent';

const UploadCreative = () => (
  <>
    <UploadCreativeHeader />
    <SingleContent content={<UploadCreativeContent />} />
  </>
);

export default UploadCreative;
