import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SVG from 'react-inlinesvg';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import eyeSvg from 'assets/icons/eye.svg';
import styles from './ViewRenderer.pcss';

const cx = classNames.bind(styles);

class ViewRenderer extends Component {
  onViewBtnClick = () => {
    const { onBtnClick, data } = this.props;
    onBtnClick(data);
  };

  getViewBtn = () => {
    return (
      <span className="table-action-button">
        <Button onClick={this.onViewBtnClick} btnShape={ButtonShape.CIRCLE} btnType={ButtonType.ICON}>
          <SVG src={eyeSvg} />
        </Button>
      </span>
    );
  };

  getConcatedValue = () => {
    const { value, textKey } = this.props;
    if (value.length > 0) {
      const names = value.map((item) => item[textKey]);
      return names.join(', ');
    }
    return '';
  };

  getDisplayText = () => {
    return <div className={cx('cell-cont')}>{this.getConcatedValue()}</div>;
  };

  render() {
    const { value } = this.props;
    if (value) {
      return this.getDisplayText();
    }
    return this.getViewBtn();
  }
}

ViewRenderer.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})),
  textKey: PropTypes.string,
  onBtnClick: PropTypes.func,
  data: PropTypes.shape({}),
};

ViewRenderer.defaultProps = {
  value: null,
  textKey: 'name',
  data: {},
  onBtnClick: () => undefined,
};

export default ViewRenderer;
