import { DASH_SLASH } from 'consts/placeholders';
import { CreativeType } from 'components/common/types/Creative.types';
import { formatSecondsToDuration } from 'utils/formatSecondsToDuration';
import { VideoDurationRendererProps } from './VideoDurationRenderer.types';

const VideoDurationRenderer: React.FC<VideoDurationRendererProps> = ({ data }) => {
  if (!data?.duration || data?.type !== CreativeType.VIDEO) return <p>{DASH_SLASH}</p>;

  return (
    <>
      <p>{formatSecondsToDuration(data.duration)}</p>
    </>
  );
};

export default VideoDurationRenderer;
