import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { usePageFilters } from 'customHooks/usePageFilters';
import {
  getCreativeAdminDealIdLookupData,
  getCreativeIdsLookupSuggestions,
  getDspLookupSuggestions,
  getMarketLookupSuggestions,
} from 'modules/api/lookups';
import withCancelRequest from 'components/hocs/withCancelRequest';
import AsyncSelect from 'components/patterns/AsyncSelect';
import FiltersCard from 'components/patterns/FiltersCard';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox/MultipleSwitchBox';

const FilterArea = ({ cancelFunctions }) => {
  const {
    filters,
    filters: { marketStatus, market, dsps, creativeIds, dealIds },
    changeFilters,
  } = usePageFilters();

  const prepareNewFilters = (fieldName, newValue) => {
    return {
      ...filters,
      [fieldName]: newValue,
    };
  };

  const onLoadLookupData = async (fieldName, query) => {
    if (query.length < 2) {
      return [];
    }

    let result;

    try {
      switch (fieldName) {
        case 'market':
          result = await getMarketLookupSuggestions(cancelFunctions, query);
          return result;
        case 'dsps':
          result = await getDspLookupSuggestions(cancelFunctions, query);
          return result;
        case 'creativeIds':
          result = await getCreativeIdsLookupSuggestions(cancelFunctions, query);
          return result;
        case 'dealIds':
          result = await getCreativeAdminDealIdLookupData(cancelFunctions, market.name, query);
          return result;
        default:
          break;
      }
    } catch {
      return [];
    }

    return [];
  };

  const handleSetFilters = (fieldName, newValue) => {
    const newFilters = prepareNewFilters(fieldName, newValue);

    if (!isEqual(market, newFilters.market)) {
      newFilters.dealIds = [];
    }

    changeFilters(newFilters);
  };

  return (
    <FiltersCard dataTestId="admin-filter-area">
      <div className="gap-4 w-full flex flex-wrap">
        <div className="flex-initial mr-4 w-min whitespace-nowrap">
          <MultipleSwitchBox
            source={[
              { name: 'All', value: '' },
              { name: 'Missing market', value: 'false' },
              { name: 'Market assigned', value: 'true' },
            ]}
            selectedValue={marketStatus}
            textKey="name"
            valueKey="value"
            onChange={(value) => {
              handleSetFilters('marketStatus', value);
            }}
          />
        </div>
        <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100">
          <AsyncSelect
            name="creativeIds"
            loadData={onLoadLookupData}
            selectedValue={creativeIds}
            placeholder="Creative ID"
            onSelect={handleSetFilters}
            multiple
          />
        </div>
        <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100">
          <AsyncSelect
            name="dsps"
            loadData={onLoadLookupData}
            selectedValue={dsps}
            placeholder="DSP"
            onSelect={handleSetFilters}
            multiple
          />
        </div>
        <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100">
          <AsyncSelect
            dataTestId="market-filter"
            name="market"
            loadData={onLoadLookupData}
            selectedValue={market}
            placeholder="Search market"
            onSelect={handleSetFilters}
          />
        </div>
        <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100">
          <AsyncSelect
            dataTestId="deal-filter"
            name="dealIds"
            loadData={onLoadLookupData}
            selectedValue={dealIds}
            placeholder="Deal ID"
            onSelect={handleSetFilters}
            isDisabled={!market}
            multiple
          />
        </div>
      </div>
    </FiltersCard>
  );
};

FilterArea.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
};

export default withCancelRequest(FilterArea);
