import { useCallback, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import Chips from 'components/patterns/Chips';
import { getCreativeCategories } from 'modules/api/content';
import { CodeNameModel } from 'components/common/types';
import {
  updateAdditionalProductCategoriesOptions,
  updateNewProductCategories,
  updateProductCategoriesMode,
  updateIsUnsavedProductCategory,
} from 'store/pages/contentManagement/creativesAdditionalData/reducer';
import { getIsInsideProductCategories } from 'components/pages/ContentManagement/EditCreatives/EditPanel/utils';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import { CreativeMetaOperation } from 'components/common/types/Creative.types';
import plusSvg from 'assets/icons/plus.svg';
import crossSvg from 'assets/icons/close.svg';
import { getAllSelectedCreativesPendingStatus } from 'store/pages/contentManagement/creatives/selectors';

const AdditionalProductCategories: React.FC = () => {
  const dispatch = useDispatch();
  const areAllItemsPending = useSelector(getAllSelectedCreativesPendingStatus);
  const additionalProductCategoriesOptions = useSelector(
    (state: Store) => state.pages.contentManagement.creativesAdditionalData.additionalProductCategoriesOptions,
  );
  const newProductCategories = useSelector(
    (state: Store) => state.pages.contentManagement.creativesAdditionalData.newProductCategories,
  );
  const productCategoriesMode = useSelector(
    (state: Store) => state.pages.contentManagement.creativesAdditionalData.productCategoriesMode,
  );

  useEffect(() => {
    const getCategories = async (): Promise<void> => {
      try {
        const result = await getCreativeCategories();
        dispatch(updateAdditionalProductCategoriesOptions(result));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    getCategories();
  }, []);

  const filterProductCategories = useCallback(
    (_dropdownName, filterValue) => {
      if (filterValue.length >= 1) {
        return additionalProductCategoriesOptions.filter(
          (item) => item.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1,
        );
      }

      return additionalProductCategoriesOptions;
    },
    [additionalProductCategoriesOptions],
  );

  const onAddProductCategories = (_dropdownName: string, values: CodeNameModel[]): void => {
    const categoryId = values[values.length - 1].id;

    if (!getIsInsideProductCategories(newProductCategories, categoryId)) {
      dispatch(updateNewProductCategories(values));
    }
  };

  const onRemoveProductCategories = (selectedChip: CodeNameModel): void => {
    const updatedProductCategoriesToAdd = newProductCategories.filter((category) => selectedChip.id !== category.id);
    dispatch(updateNewProductCategories(updatedProductCategoriesToAdd));
  };

  const onModeChange = (mode: CreativeMetaOperation): void => {
    dispatch(updateProductCategoriesMode(mode));

    const isUnsavedData = newProductCategories.length > 0;
    const isAddOperation = mode === CreativeMetaOperation.ADD;
    const isUnsavedOperationChange = !isAddOperation || (isAddOperation && isUnsavedData);
    dispatch(updateIsUnsavedProductCategory(isUnsavedOperationChange));
  };

  return (
    <div data-test-id="additional-product-categories" className="flex flex-col">
      <AsyncSelect
        dataTestId="additional-product-categories-input"
        name="categories"
        defaultOptions={additionalProductCategoriesOptions}
        loadData={filterProductCategories}
        selectedValue={newProductCategories}
        placeholder="Select a category"
        onSelect={onAddProductCategories}
        isClearable={false}
        multiple
        isMultiValueVisible={false}
        debounceTime={0}
        hideSelectedOptions={false}
        isDisabled={!areAllItemsPending}
        closeMenuOnSelect={false}
      />
      <div
        className={cx('mt-2 w-min flex justify-start', {
          'mb-2': !!newProductCategories.length,
        })}
      >
        <MultipleSwitchBox
          source={[
            { code: CreativeMetaOperation.ADD, name: 'Add', icon: plusSvg },
            { code: CreativeMetaOperation.REPLACE, name: 'Replace', icon: crossSvg },
          ]}
          selectedValue={productCategoriesMode}
          textKey="name"
          valueKey="code"
          onChange={onModeChange}
          isDisabled={!areAllItemsPending}
          dataTestId="additional-product-categories-switch"
          hideBorder
        />
      </div>
      <Chips
        dataTestId="additional-product-categories-chips"
        dataList={newProductCategories}
        idKey="id"
        labelKey="name"
        isClearAllVisible={false}
        onRemove={onRemoveProductCategories}
        isDisabled={!areAllItemsPending}
      />
    </div>
  );
};

export default AdditionalProductCategories;
