import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { DOUBLE_DASH } from 'consts/placeholders';
import { formatDate } from 'modules/I18N';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';
import { Asset } from 'components/pages/InventoryManagement/AssetList/AssetSidebar/AssetSidebar.types';
import { RootState } from 'store';

const Schedule: React.FC<Asset> = ({ asset }: Asset) => {
  const { localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  return (
    <div>
      <h2 className="sub-header-base mb-8">Schedule</h2>
      <div className="pl-2">
        <Timeline>
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 items-center">
                <p className="body-sm">Loop policy</p>
                <p className="body-base col-span-2 break-all" data-test-id="loop-policy-name">
                  {asset?.loopPolicyName ?? DOUBLE_DASH}
                </p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4">
                <p className="body-sm">Schedule</p>
                <p className="body-base col-span-2" data-test-id="schedule">{`${asset?.schedule ?? DOUBLE_DASH}`}</p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4">
                <p className="body-sm">Smart content</p>
                <p className="body-base col-span-2" data-test-id="smart-content">{`${
                  asset?.smartContent ?? DOUBLE_DASH
                }`}</p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4">
                <p className="body-sm">Display offset</p>
                <p className="body-base col-span-2" data-test-id="display-offset">
                  {asset?.displayOffset ?? DOUBLE_DASH}
                </p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4">
                <p className="body-sm">Saturate</p>
                <p className="body-base col-span-2" data-test-id="saturate">{`${asset?.saturate ?? DOUBLE_DASH}`}</p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4">
                <p className="body-sm">Digital spec</p>
                <p className="body-base col-span-2" data-test-id="digital-spec-name">
                  {asset?.digitalSpecName ?? DOUBLE_DASH}
                </p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 items-center">
                <p className="body-sm">Linked asset</p>
                <p className="body-base col-span-2 break-all" data-test-id="linked-asset-code">
                  {asset?.linkedAssetCode ?? DOUBLE_DASH}
                </p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4">
                <p className="body-sm">Offer mask</p>
                <p className="body-sm">Default offer</p>
                <p className="body-sm">Override sot</p>
              </div>
            }
          >
            <div className="grid grid-cols-3 gap-x-4 gap-y-2">
              {asset?.offers?.map((offer) => (
                <Fragment key={`${offer.startDate}${offer.endDate}`}>
                  <p className="body-base">
                    {offer.offerMask ?? DOUBLE_DASH}
                    <br />
                    <span className="body-sm text-neutral-950-opacity-60">{`${formatDate(
                      offer.startDate,
                      localeCode,
                    )} - ${formatDate(offer.endDate, localeCode)}`}</span>
                  </p>
                  <p className="body-base">{offer.defaultOffers}</p>
                  <p className="body-base">{offer.overrideSot ?? DOUBLE_DASH}</p>
                </Fragment>
              ))}
            </div>
          </TimelineItem>
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4">
                <p className="body-sm">Display unit ID</p>
                <p className="body-sm">Digital player ID</p>
                <p className="body-sm">Backup</p>
              </div>
            }
          >
            <div className="grid grid-cols-3 gap-x-4 gap-y-2">
              {asset?.digitalPlayers?.map((player) => (
                <Fragment key={player.digitalUnitUuid}>
                  <p className="body-base">{player.digitalUnitUuid}</p>
                  <p className="body-base">{player.digitalPlayerUuid}</p>
                  <p className="body-base">{`${player.backup}`}</p>
                </Fragment>
              ))}
            </div>
          </TimelineItem>
        </Timeline>
      </div>
    </div>
  );
};

export default Schedule;
