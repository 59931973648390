import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notifyError } from 'store/notification/reducer';
import { thoughtspot } from 'config';
import { initialiseThoughtspot } from 'utils/thoughtspotInit';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import SingleContent from 'components/common/layout/SingleContent';
import LiveboardVisuals from 'components/patterns/LiveboardVisuals';
import { RootState } from 'store';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';

export const Labs: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cancelFunction = useCancelRequest();
  const { localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const hasInsightsLabsEnabled = useHasFeatureAccess(FeatureFlags.INSIGHTS_LABS);

  useEffect(() => {
    if (!hasInsightsLabsEnabled) {
      navigate('/accessdenied');
      dispatch(notifyError({ message: "Looks like we don't have access to that liveboard" }));
    }
  }, []);

  initialiseThoughtspot(cancelFunction, localeCode);

  return hasInsightsLabsEnabled ? (
    <SingleContent content={<LiveboardVisuals liveboardId={thoughtspot.labsId} />} isOverflowHidden />
  ) : null;
};
