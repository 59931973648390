import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  setCreativeDetails,
  setFrameSummary,
  setMovement,
  resetCreativeDetails,
  updateCreativeProperties,
} from 'store/creative/reducer';
import { notifyError, notifySuccess } from 'store/notification/reducer';
import { getIsReseller } from 'store/publisher/selectors';
import { getDealsByOrigin } from 'store/creative/selectors';
import PreviewContent from './PreviewContent';

const mapStateToProps = (state) => ({
  marketId: state.creative.marketId,
  deleted: state.creative.deleted,
  statusCode: state.creative.statusCode,
  externalId: state.creative.externalId,
  dsp: state.creative.dsp,
  width: state.creative.width,
  height: state.creative.height,
  productFormat: state.creative.productFormat,
  uploadedBy: state.creative.uploadedBy,
  uploadedDate: state.creative.uploadedDate,
  uploadedTime: state.creative.uploadedTime,
  movement: state.creative.movement,
  dealId: state.creative.dealId,
  contentURL: state.creative.contentURL,
  downloadURL: state.creative.downloadURL,
  moderation: state.creative.moderation,
  frameSummary: state.creative.frameSummary,
  type: state.creative.type,
  commercialId: state.creative.commercialId,
  productCategory: state.creative.productCategory,
  advertiser: state.creative.advertiser,
  brand: state.creative.brand,
  thumbnail: state.creative.thumbnail,
  localeCode: state.publisher.configuration.localeCode,
  market: state.creative.market,
  isReseller: getIsReseller(state),
  inheritedDealIds: getDealsByOrigin(state).inheritedDealIds,
  ownDealIds: getDealsByOrigin(state).ownDealIds,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      notifyError,
      notifySuccess,
      setCreativeDetails,
      setFrameSummary,
      setMovement,
      resetCreativeDetails,
      updateCreativeProperties,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PreviewContent);
