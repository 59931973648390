import { FormatTypeEnum, formatValue } from 'modules/I18N';
import { isArray } from 'lodash';

export const handleValueFormat = ({
  currencyCode,
  localeCode,
  value,
  valueType,
}: {
  currencyCode: string;
  localeCode: string;
  valueType: FormatTypeEnum;
  value?: number | [number, number];
}): string => {
  let text = formatValue(value as number, valueType, currencyCode, localeCode, 0);

  if (!value) return text;

  if (isArray(value)) {
    const areAllValuesPositive = value.every((val) => val > 0);
    const valuesOtherThanZero = value.filter((val) => val !== 0);
    const isMinMaxEqual = value[0] === value[1];

    if (areAllValuesPositive && !isMinMaxEqual) {
      text = `${formatValue(value[0], valueType, currencyCode, localeCode, 0)} - ${formatValue(
        value[1],
        valueType,
        currencyCode,
        localeCode,
        0,
      )}`;
    } else if (valuesOtherThanZero.length === 1 || isMinMaxEqual) {
      text = formatValue(valuesOtherThanZero[0], valueType, currencyCode, localeCode, 0);
    }
  }

  return text;
};
