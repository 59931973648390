import DynamicCreativePreview from 'components/common/DynamicCreativePreview';
import Lightbox from 'components/patterns/Lightbox';
import { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCreativeFromState } from 'store/creative/selectors';
import maximizeSvg from 'assets/icons/maximize.svg';
import SVG from 'react-inlinesvg';
import { CreativeType } from 'components/common/types/Creative.types';

const CreativePreview: FunctionComponent = () => {
  const creative = useSelector(getCreativeFromState);
  const { deletedAt, downloadURL, contentURL, thumbnail, type } = { ...creative };
  const readOnly = !!deletedAt;

  const [isOpen, setIsOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const onMouseOver = (): void => setIsHovering(true);
  const onMouseOut = (): void => setIsHovering(false);
  const toggleIsOpen = (): void => setIsOpen(!isOpen);

  return (
    <>
      <div className="relative block">
        {type !== CreativeType.DYNAMIC ? (
          <div
            className="relative block cursor-pointer"
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onFocus={onMouseOver}
            onBlur={onMouseOut}
            onClick={toggleIsOpen}
            role="presentation"
          >
            {type === CreativeType.VIDEO && (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video controls autoPlay data-test-id="creative-preview-video">
                <source src={contentURL} type="video/mp4" />
              </video>
            )}
            {type === CreativeType.BANNER && (
              // eslint-disable-next-line jsx-a11y/alt-text
              <img className="rounded-lg" src={downloadURL} data-test-id="creative-preview-banner" />
            )}
            {isHovering && (
              <div
                data-test-id="creative-preview-expand"
                className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 rounded-lg overflow-visible pointer-events-none"
              >
                <div className="absolute top-0 left-0 inset-y-1/2 w-full h-full flex justify-center items-center">
                  <SVG src={maximizeSvg} className="text-neutral-100" />
                </div>
              </div>
            )}
          </div>
        ) : (
          <DynamicCreativePreview thumbnail={thumbnail} downloadURL={downloadURL} readOnly={readOnly} />
        )}
      </div>
      <Lightbox isOpen={isOpen} creatives={[creative]} creativeIndex={0} onClose={toggleIsOpen} />
    </>
  );
};

export default CreativePreview;
