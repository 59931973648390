import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './TimelineItem.pcss';

const cx = classNames.bind(styles);

const TimelineItem = ({ title, children, dataTestId }) => (
  <div className={`text-neutral-950-opacity-80 last:pb-0 ${children ? 'pb-4' : 'pb-2'}`} data-test-id={dataTestId}>
    <div className="mb-1">{title}</div>
    {children ? <div className={cx('content')}>{children}</div> : null}
  </div>
);

TimelineItem.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

TimelineItem.defaultProps = {
  title: null,
  children: null,
  dataTestId: '',
};

export default TimelineItem;
