import cx from 'classnames';
import SVG from 'react-inlinesvg';
import { IHeaderGroupParams } from 'ag-grid-community';
import caretLeft from 'assets/icons/caret-left.svg';
import caretRight from 'assets/icons/caret-right.svg';
import { HeaderBackgroundColor } from 'components/common/TableHeader/TableHeader.types';

export interface HeaderProps extends IHeaderGroupParams {
  backgroundColor?: HeaderBackgroundColor;
  collapsedBackgroundColor?: HeaderBackgroundColor;
  expandedBackgroundColor?: HeaderBackgroundColor;
  hideDuplicateWhenPinned?: boolean;
  label: string;
  headerLevel?: string;
}

export const HeaderGroupComponent: React.FC<HeaderProps> = ({
  backgroundColor,
  collapsedBackgroundColor = HeaderBackgroundColor.PRIMARY,
  expandedBackgroundColor = HeaderBackgroundColor.SECONDARY,
  hideDuplicateWhenPinned,
  label,
  headerLevel,
  ...headerGroupParams
}) => {
  const isExpanded = headerGroupParams.columnGroup.getProvidedColumnGroup().isExpanded();

  const handleExpandOrCollapse = (): void => {
    headerGroupParams.setExpanded(!isExpanded);
  };

  if (hideDuplicateWhenPinned && headerGroupParams.columnGroup.getPartId() === 1) {
    return null;
  }

  return (
    <button
      className={cx('flex items-center px-2 py-1.5 text-neutral-950 rounded-sm w-full m-0.5', {
        'justify-between': isExpanded,
        [`${backgroundColor}`]: !!backgroundColor,
        [`${expandedBackgroundColor}`]: isExpanded && !backgroundColor,
        [`${collapsedBackgroundColor}`]: !isExpanded && !backgroundColor,
      })}
      onClick={handleExpandOrCollapse}
      type="button"
      data-dd-action-name={`Allocation report / Expand or collapse ${headerLevel}`}
    >
      <span className="sub-header-sm">{label}</span>
      <SVG
        src={isExpanded ? caretLeft : caretRight}
        className={cx('', {
          'ml-2': isExpanded,
        })}
      />
    </button>
  );
};
