import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ColumnChart, SplineChart, SETTINGS } from 'components/patterns/HighChart';

const HeroChart = ({ chartTitle, chartData, table, seriesColor, isSplineChart, chartOptions, dataTestId }) => {
  const isChartsOpen = useSelector((state) => state.dealDashboard.isChartsOpen);

  const chartSize = isChartsOpen ? 300 : 80;

  return (
    <div data-test-id={dataTestId}>
      <div className="p-2 bg-neutral-100 border-l border-t border-r border-neutral-300 rounded-t-xl">
        {isSplineChart ? (
          <SplineChart
            dataTestId="spline-chart"
            data={chartData}
            chartTitle={chartTitle}
            chartSize={chartSize}
            seriesColor={seriesColor}
            options={chartOptions}
          />
        ) : (
          <ColumnChart
            dataTestId="column-chart"
            data={chartData}
            chartTitle={chartTitle}
            chartSize={chartSize}
            seriesColor={seriesColor}
            options={chartOptions}
          />
        )}
      </div>

      <div className="px-6 pt-2 pb-4 bg-neutral-100 border border-neutral-300 rounded-b-xl">
        {table.length ? (
          <table className="m-0 bg-transparent shadow-none">
            <tbody>
              <tr className="text-left text-neutral-600 mb-1">
                {table.map((i) => (
                  <th key={i.title} className="body-sm" title={i.titleFullText}>
                    {i.title}
                  </th>
                ))}
              </tr>
              <tr className="body-base text-neutral-900">
                {table.map((i) => (
                  <td key={i.title}>{i.value}</td>
                ))}
              </tr>
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};

HeroChart.propTypes = {
  chartTitle: PropTypes.string.isRequired,
  chartData: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.string, y: PropTypes.number })),
  table: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) }),
  ),
  seriesColor: PropTypes.string,
  isSplineChart: PropTypes.bool,
  chartOptions: PropTypes.object,
  dataTestId: PropTypes.string,
};

HeroChart.defaultProps = {
  chartData: [],
  seriesColor: SETTINGS.COLORS.primary,
  table: [],
  isSplineChart: false,
  chartOptions: {},
  dataTestId: '',
};

export default HeroChart;
