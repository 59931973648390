import { createSelector } from '@reduxjs/toolkit';
import { PublisherType } from 'components/common/types/Publisher.types';
import { RootState } from 'store';
import { isAdServer } from './utils';

export const getIsReseller = createSelector([(state: RootState) => state.publisher], (publisher) => {
  const { type } = publisher.configuration;
  return type === PublisherType.RESELLER;
});

export const getIsAdServer = createSelector(
  [(state: RootState) => state.publisher, (state: RootState) => state.environment],
  (publisher, environment) => {
    const { backEndSystem } = publisher.configuration;
    const { publisherFeatures } = publisher;
    const { environmentId } = environment;

    return isAdServer({ backEndSystem, publisherFeatures, environmentId });
  },
);
