import { Component } from 'react';
import withLoader from 'components/hocs/withLoader';
import Tabs from 'components/patterns/Tabs/Tabs';
import CreativeAdministration from './CreativeAdministration';

class Administration extends Component {
  state = {
    tabs: {
      dataList: [
        {
          id: 1,
          label: 'Creatives',
          content: <CreativeAdministration />,
        },
      ],
      currentTabIndex: 0,
    },
  };

  tabChangeHandler = (index) => {
    const { tabs } = this.state;
    this.setState({
      tabs: {
        ...tabs,
        currentTabIndex: index,
      },
    });
  };

  render() {
    const { tabs } = this.state;

    return (
      <div>
        <Tabs
          dataList={tabs.dataList}
          idKey="id"
          labelKey="label"
          contentKey="content"
          hiddenKey="hide"
          currentTabIndex={tabs.currentTabIndex}
          onTabChange={this.tabChangeHandler}
        />
      </div>
    );
  }
}

export default withLoader(Administration);
