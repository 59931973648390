import { URLS, HTTP_METHODS } from 'modules/api/constants';
import handleRequest from 'modules/api/common';
import { CancelFunctions } from 'components/common/types';
import { CreativeStatus } from 'components/common/types/Creative.types';

export const getResellerMediaOwners = (
  reseller: string,
  cancelFunctions: CancelFunctions,
): Promise<{ mediaOwners: string[] }> => {
  const reqOptions = {
    url: URLS().FETCH_RESELLER_MEDIA_OWNERS(reseller),
    method: HTTP_METHODS.GET,
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_RESELLER_MEDIA_OWNERS');
};

export const getEligibleResellerMediaOwners = (
  marketId: string,
  cancelFunctions: CancelFunctions,
): Promise<string[]> => {
  const reqOptions = {
    url: URLS().FETCH_ELIGIBLE_RESELLER_MEDIA_OWNERS(marketId),
    method: HTTP_METHODS.GET,
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_ELIGIBLE_RESELLER_MEDIA_OWNERS');
};

export const getResellerMediaOwnersCreativeStatus = (
  marketId: string,
  cancelFunctions: CancelFunctions,
): Promise<{ publisher: string; status: CreativeStatus; updateStamp: number }[]> => {
  const reqOptions = {
    url: URLS().FETCH_RESELLER_MEDIA_OWNERS_CREATIVE_STATUS(marketId),
    method: HTTP_METHODS.GET,
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_RESELLER_MEDIA_OWNERS_CREATIVE_STATUS');
};
