import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formatNumber, formatPercent } from 'modules/I18N';
import { transformColumnChartData } from 'utils/formatters';
import { SETTINGS } from 'components/patterns/HighChart';
import { datePercentChartOptions, calculateDealPercentChartOptions } from 'utils/chartOptions';
import HeroChart from '../HeroChart';

const BidRate = ({ bidData, isDealSelected }) => {
  const { localeCode } = useSelector((state) => state.publisher.configuration);
  const isChartsOpen = useSelector((state) => state.dealDashboard.isChartsOpen);
  const { totalBidResponses, bidRate, totalBidRequests, bidRateGraph } = bidData;

  return (
    <HeroChart
      dataTestId="bid-rate-chart"
      chartTitle="Bid rate"
      chartData={transformColumnChartData(bidRateGraph, isDealSelected ? 'date' : 'dspName', 'bidRate')}
      table={[
        {
          title: 'Bid req.',
          titleFullText: 'Bid requests',
          value: formatNumber({ value: totalBidRequests, localeCode }),
        },
        {
          title: 'Bid res.',
          titleFullText: 'Bid responses',
          value: formatNumber({ value: totalBidResponses, localeCode }),
        },
        { title: 'Bid rate', value: formatPercent({ value: bidRate / 100, localeCode }) },
      ]}
      seriesColor={SETTINGS.COLORS.primaryLight}
      isSplineChart={isDealSelected}
      chartOptions={
        isDealSelected
          ? datePercentChartOptions({ localeCode })
          : calculateDealPercentChartOptions(isChartsOpen, localeCode)
      }
    />
  );
};

BidRate.propTypes = {
  bidData: PropTypes.object,
  isDealSelected: PropTypes.bool,
};

BidRate.defaultProps = {
  bidData: {},
  isDealSelected: false,
};

export default BidRate;
