import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ModalSize } from 'components/patterns/Modal';
import { LightboxProps } from './Lightbox.types';
import LightboxHeader from './LightboxHeader';
import LightboxContent from './LightboxContent';

const Lightbox: React.FC<LightboxProps> = ({ creatives, creativeIndex, onClose, onCreativeIndexChange, isOpen }) => {
  const [isZoomedIn, setIsZoomedIn] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) setIsZoomedIn(false);
  }, [isOpen]);

  const onNextCreative = (): void => {
    setIsZoomedIn(false);
    const nextIndex = (creativeIndex + 1) % creatives.length;
    const nextCreativeId = creatives[nextIndex].id;

    onCreativeIndexChange?.(nextCreativeId);
  };

  const onPreviousCreative = (): void => {
    setIsZoomedIn(false);
    const previousIndex = (creativeIndex + creatives.length - 1) % creatives.length;
    const previousCreativeId = creatives[previousIndex].id;

    onCreativeIndexChange?.(previousCreativeId);
  };

  const onZoomIn = (): void => setIsZoomedIn(true);

  const onZoomOut = (): void => setIsZoomedIn(false);

  const currentCreative = creatives[creativeIndex];

  return (
    <div>
      <ReactModal
        className={`bg-primary absolute transform -translate-y-2/4 -translate-x-2/4 top-1/2 left-1/2 rounded-md ${ModalSize.LARGE}`}
        onRequestClose={onClose}
        isOpen={isOpen}
        overlayClassName="bg-modalOverlay-darker"
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      >
        <div data-test-id="lightbox-gallery" className="flex">
          <div className="overflow-auto h-180 pt-20 pb-4 leading-normal px-8 flex-1 bg-neutral-50">
            <LightboxHeader
              creative={currentCreative}
              onClose={onClose}
              onNext={creatives.length > 1 ? onNextCreative : undefined}
              onPrevious={creatives.length > 1 ? onPreviousCreative : undefined}
              onZoomIn={onZoomIn}
              onZoomOut={onZoomOut}
              isZoomedIn={isZoomedIn}
            />
            <LightboxContent creative={currentCreative} isZoomedIn={isZoomedIn} />
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default Lightbox;
