import { MetricOption, SelectedLine } from './AllocationReport.types';
import TIMEZONES from '../Header/Timezone/timezones';

export const generateFileName = ({
  isSelectedLineWithProposalReport,
  selectedMetricType,
  selectedLine,
  timezone,
}: {
  isSelectedLineWithProposalReport: boolean;
  selectedMetricType: MetricOption;
  selectedLine: SelectedLine;
  timezone: string;
}): string => {
  const reportType = isSelectedLineWithProposalReport ? 'Prop' : 'Alloc';
  const reportMetric = selectedMetricType.name.slice(0, 3);
  const dealLineName = selectedLine.lineName.slice(0, 35);

  const localeCodeThatIsInYYYYMMDDFormat = 'en-CA';
  const currentDate = new Date();
  const reportExportDate = currentDate
    .toLocaleDateString(localeCodeThatIsInYYYYMMDDFormat, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replaceAll('-', '');

  const localeCodeThatIsInHHMMFormat = 'en-GB';
  const reportExportTime = currentDate
    .toLocaleTimeString(localeCodeThatIsInHHMMFormat, {
      hour: '2-digit',
      minute: '2-digit',
    })
    .replaceAll(':', '')
    .slice(0, 4);

  const timezoneAbbreviation = TIMEZONES.find((config) => config.code === timezone)?.tzAbr ?? '';

  return `${reportType}_Report_${reportMetric}_${dealLineName}_${reportExportDate}_${reportExportTime}_${timezoneAbbreviation}`;
};
