import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { UseCampaignType } from './useCampaignType.types';
import { CampaignType } from '../PlannerSections/types';

const useCampaignType = (): UseCampaignType => {
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const campaignType = useSelector((state: Store) => state.dealManagement.campaignType);

  const hasMtbExportFeature = useHasFeatureAccess(FeatureFlags.MTB_EXPORT);
  const hasDirectSalesFeature = useHasFeatureAccess(FeatureFlags.DIRECT_SALES_PLANNER);

  const isMtbCampaignType = useMemo(
    () => hasMtbExportFeature && isNewDeal && campaignType === CampaignType.DIRECT_MTB_EXPORT,
    [isNewDeal, campaignType, hasMtbExportFeature],
  );

  const isDirectSalesCampaignType = useMemo(
    () => hasDirectSalesFeature && campaignType === CampaignType.DIRECT,
    [campaignType, hasDirectSalesFeature],
  );

  const isProgrammaticCampaignType = useMemo(
    () => !isMtbCampaignType && !isDirectSalesCampaignType,
    [isMtbCampaignType, isDirectSalesCampaignType],
  );

  return {
    hasMtbExportFeature,
    hasDirectSalesFeature,
    isMtbCampaignType,
    isProgrammaticCampaignType,
    isDirectSalesCampaignType,
  };
};

export default useCampaignType;
