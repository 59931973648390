import React from 'react';
import PropTypes from 'prop-types';
import * as userActions from 'modules/api/user';
import * as roleActions from 'modules/api/role';
import Input from 'lib/Input';
import { Label } from 'lib/Label';
import { InputPassword } from 'lib/InputPassword';
import { InputType } from 'components/common/types/Input.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import withCancelRequest from 'components/hocs/withCancelRequest';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';

class User extends React.Component {
  state = {
    user: { name: '', email: '', password: '' },
    isEditMode: !!this.props.userId,
    roles: [],
  };

  async componentDidMount() {
    const { userId, cancelFunctions } = this.props;
    if (userId) {
      const user = await userActions.getUserDetail(cancelFunctions, userId);
      this.setState({ user });
    }
    const roles = await roleActions.getRoleList(cancelFunctions);
    this.setState({ roles });
  }

  onSaveButtonClick = () => {
    const { onSave } = this.props;
    const { user } = this.state;
    onSave(user);
  };

  getInput = (type, value, name, placeholder, disabled = false) => {
    return (
      <Input
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={this.onTextChange}
        isDisabled={disabled}
        type={type}
      />
    );
  };

  getPasswordInput = (value, name, placeholder) => {
    return <InputPassword name={name} placeholder={placeholder} value={value} onChange={this.onTextChange} />;
  };

  onTextChange = (value, name) => {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  };

  onLoadLookupData = (_dropdownName, qry) => {
    const { roles } = this.state;
    return roles.filter((item) => item.name.toLowerCase().indexOf(qry.toLowerCase()) !== -1);
  };

  onLookupSelect = async (dropdownName, newValue) => {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [dropdownName]: newValue || [],
      },
    });
  };

  render() {
    const { user, isEditMode, roles } = this.state;
    const { onCancel } = this.props;

    return (
      <div className="mx-8 space-y-5">
        <div>
          <Label id="name" label="User name" isRequired />
          {this.getInput(InputType.TEXT, user.name, 'name', 'Enter user name')}
        </div>
        <div>
          <Label id="email" label="Email address" isRequired />
          {this.getInput(InputType.TEXT, user.email, 'email', 'Enter email address', isEditMode)}
        </div>
        {!isEditMode ? (
          <div>
            <Label id="password" label="Password" isRequired />
            {this.getPasswordInput(user.password, 'password', 'Enter password')}
          </div>
        ) : null}
        <div>
          <Label id="roles" label="Select role" />
          <AsyncSelect
            name="roles"
            valueKey="id"
            loadData={this.onLoadLookupData}
            selectedValue={user.roles}
            placeholder="Search role"
            onSelect={this.onLookupSelect}
            defaultOptions={roles}
            menuPlacement="top"
            multiple
          />
        </div>
        <div className="flex justify-end space-x-3">
          <Button onClick={onCancel} btnShape={ButtonShape.NORMAL} btnType={ButtonType.DANGER}>
            Cancel
          </Button>
          <Button onClick={this.onSaveButtonClick} btnShape={ButtonShape.NORMAL} btnType={ButtonType.PRIMARY}>
            {isEditMode ? 'Update User' : 'Create user'}
          </Button>
        </div>
      </div>
    );
  }
}

User.propTypes = {
  userId: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
};

User.defaultProps = {
  userId: '',
  onSave: () => undefined,
  onCancel: () => undefined,
};

export default withCancelRequest(User);
