import FilterArea from 'components/common/FilterArea';
import FilterDateRange from 'components/common/FilterArea/Filters/FilterDateRange';
import FilterDealId from 'components/common/FilterArea/Filters/FilterDealId';
import FilterTradingTime from 'components/common/FilterArea/Filters/FilterTradingTime';
import { usePageFilters } from 'customHooks/usePageFilters';
import { PerformanceFilters } from './Filters.types';

const Filters: React.FC = () => {
  const {
    filters: { deal, startDate, endDate, time },
    changeFilters,
  } = usePageFilters<PerformanceFilters>('insights/dashboard');
  return (
    <FilterArea>
      <div className="flex">
        <div className="w-96 mr-4">
          <FilterDealId deal={deal} changeFilters={changeFilters} />
        </div>
      </div>
      <div className="place-self-end">
        <FilterDateRange selectedEndDate={endDate} selectedStartDate={startDate} changeFilters={changeFilters} />
      </div>
      <div className="mt-3">
        <FilterTradingTime selectedTime={time} changeFilters={changeFilters} />
      </div>
    </FilterArea>
  );
};

export default Filters;
