import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as ReactDOM from 'react-dom';
import { pendo, agGrid } from 'config';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LicenseManager } from 'ag-grid-enterprise';

import Auth from 'modules/Auth';

import 'stylesheets/tailwind.css';

import store from 'store';
import App from 'components/common/App';

import { loadPendo } from 'vendor/pendo';

import '!style-loader!css-loader!normalize.css'; /* eslint-disable-line import/no-webpack-loader-syntax */
import 'react-day-picker/lib/style.css';
import 'stylesheets/global.pcss';

import { dataDogInitialise } from 'modules/DataDog';

LicenseManager.setLicenseKey(agGrid.enterpriseKey);

const render = async (): Promise<void> => {
  try {
    await Auth.init();
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>,
      document.getElementById('root'),
    );
  } catch {
    ReactDOM.render(<div>Authentication error</div>, document.getElementById('root'));
  }
};

render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./components/common/App', () => {
    render();
  });
}

if (process.env.NODE_ENV === 'production') {
  loadPendo(pendo.subscriptionKey);
}

dataDogInitialise();
