import { CancelFunctions } from 'components/common/types';
import { Dsp } from 'components/common/types/Deal.types';
import { getLookupData } from 'modules/api/lookups';
import multiPublisherRequest from 'modules/api/multiPublisherRequest';
import { DspFromMediaOwners } from 'store/reseller/reducer';

export const getResellerDSPLookup = async (
  resellerMediaOwners: string[],
  requestArguments: {
    cancelFunctions: CancelFunctions;
    lookupURLName: string;
    query: string;
  },
  updateMediaOwners: (results: DspFromMediaOwners[]) => void,
): Promise<Dsp[]> => {
  const allDspsFromMediaOwners = (await multiPublisherRequest(resellerMediaOwners, getLookupData, {
    ...requestArguments,
  })) as DspFromMediaOwners[];

  const dspsCodeOccurrences = allDspsFromMediaOwners.reduce(
    (acc, cur) => {
      if (!acc[cur.code]) acc[cur.code] = 0;
      ++acc[cur.code];
      return acc;
    },
    {} as { [dspCode: string]: number },
  );

  updateMediaOwners(allDspsFromMediaOwners);

  return Object.keys(dspsCodeOccurrences).map((dspCode) =>
    allDspsFromMediaOwners.find((dsp) => dsp.code === dspCode),
  ) as Dsp[];
};

export const useResellerDSPLookup = (
  isReseller: boolean,
  dsps: Dsp[],
  resellerMediaOwners: string[] | undefined,
  previousResellerMediaOwners: string[] | undefined,
  loadDSPData: () => void,
): void => {
  if (
    isReseller &&
    dsps.length === 0 &&
    resellerMediaOwners &&
    resellerMediaOwners?.length > 0 &&
    resellerMediaOwners !== previousResellerMediaOwners
  ) {
    loadDSPData();
  }
};
