import PropTypes from 'prop-types';

import { DOUBLE_DASH } from 'consts/placeholders';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';

const Format = ({ asset }) => {
  return (
    <div>
      <h2 className="sub-header-base mb-8">Format</h2>
      <div className="pl-2">
        <Timeline>
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4">
                <p className="body-sm">Site type code</p>
                <p className="body-sm">Frame orientation</p>
              </div>
            }
          >
            <div className="grid grid-cols-3 gap-x-4">
              <p className="body-base" data-test-id="site-type-uuid">
                {asset?.siteTypeUuid ?? DOUBLE_DASH}
              </p>
              <p className="body-base col-span-2" data-test-id="frame-orientation-name">
                {asset?.frameOrientationName ?? DOUBLE_DASH}
              </p>
            </div>
          </TimelineItem>
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 items-center">
                <p className="body-sm">Model</p>
                <p className="body-base col-span-2 break-all" data-test-id="model-uuid-name">{`${
                  asset?.modelUuid ?? DOUBLE_DASH
                } - ${asset?.modelName ?? DOUBLE_DASH}`}</p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 items-center">
                <p className="body-sm">Frame size</p>
                <p className="body-base col-span-2 break-all" data-test-id="frame-size-uuid-name">{`${
                  asset?.frameSizeUuid ?? DOUBLE_DASH
                } - ${asset?.frameSizeName ?? DOUBLE_DASH}`}</p>
              </div>
            }
          />
          <TimelineItem
            title={
              <div className="grid grid-cols-3 gap-x-4 items-center">
                <p className="body-sm">Product format</p>
                <p className="body-base col-span-2 break-all" data-test-id="product-frame-size-uuid-name">{`${
                  asset?.productFormatUuid ?? DOUBLE_DASH
                } - ${asset?.productFormatName ?? DOUBLE_DASH}`}</p>
              </div>
            }
          />
        </Timeline>
      </div>
    </div>
  );
};

Format.propTypes = {
  asset: PropTypes.shape({
    siteTypeUuid: PropTypes.string,
    frameOrientationName: PropTypes.string,
    modelUuid: PropTypes.string,
    modelName: PropTypes.string,
    frameSizeUuid: PropTypes.string,
    frameSizeName: PropTypes.string,
    productFormatUuid: PropTypes.string,
    productFormatName: PropTypes.string,
  }),
};

Format.defaultProps = {
  asset: {},
};

export default Format;
