import { ItemType } from './MenuItem/MenuItem.types';

export const getActiveMenuId = (menuItems: ItemType[], locationPathName: string): number | null => {
  let activeMenuId = null;
  const selectedItem = menuItems.filter(
    (val) => locationPathName.toLowerCase().includes(val.navigationPath.toLowerCase()) && val.isVisible,
  );

  if (selectedItem.length > 0) {
    activeMenuId = selectedItem[0].id;
  } else if (locationPathName.indexOf('/deal/details') > -1) {
    activeMenuId = menuItems[1].id;
  }

  return activeMenuId;
};
