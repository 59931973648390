import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import Auth from 'modules/Auth';
import { formatValue, FormatTypeEnum, getCurrency } from 'modules/I18N';
import { PermissionsEnum } from 'consts/permissions';
import warningSvg from 'assets/icons/warning.svg';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';

import { changeDealCurrentLineData, changeFormParams } from 'store/dealManagement/reducer';

import InputNumber from 'lib/InputNumber';
import { DealType, DealStatus } from 'components/common/types/Deal.types';
import FieldWithInfoMessage from 'components/patterns/FieldWithInfoMessage';
import { isFutureDate } from 'utils/dateUtil';
import RequiredAsterisk from 'components/common/RequiredAsterisk';
import { getIsAdServer } from 'store/publisher/selectors';

// eslint-disable-next-line sonarjs/cognitive-complexity
const Budget = ({ autoCalculateFloorAndCeiling, disabled }) => {
  const dispatch = useDispatch();
  const dealConfig = useSelector((state) => state.dealConfig);
  const isActionsDisabled = useSelector((state) => state.dealManagement.isActionsDisabled);
  const backupFormData = useSelector((state) => state.dealManagement.backupFormData);
  const dealType = useSelector((state) => state.dealManagement.commonDeal.dealType);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);
  const id = useSelector((state) => state.dealManagement.commonDeal.currentLine.id);
  const impressions = useSelector((state) => state.dealManagement.commonDeal.currentLine.impressions);
  const budget = useSelector((state) => state.dealManagement.commonDeal.currentLine.budget);
  const availability = useSelector((state) => state.dealManagement.commonDeal.currentLine.availability);
  const preservedAvailability = useSelector(
    (state) => state.dealManagement.commonDeal.currentLine.preservedAvailability,
  );
  const startDate = useSelector((state) => state.dealManagement.commonDeal.currentLine.startDate);
  const deliveredBudget = useSelector((state) => state.dealManagement.commonDeal.bids.revenue);
  const { currencyCode, localeCode } = useSelector((state) => state.publisher.configuration);
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);

  const { totalCost } = availability;
  const backupLine = backupFormData.lines.find((line) => line.id === id);
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const isLive = bookingStatusCode === DealStatus.LIVE;
  const isDealTypeGuaranteed = dealType === DealType.GUARANTEED;
  const isAdServerMarket = useSelector(getIsAdServer);
  const isCurrentlLineFutureStartDate = startDate && isFutureDate(startDate);

  const [showBudgetMessage, setShowBudgetMessage] = useState(false);
  const [budgetInput, setBudgetInput] = useState(budget);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setShowBudgetMessage(totalCost && budget && totalCost !== budget);
    setBudgetInput(budget);
  }, [budget, totalCost]);

  const changeBudget = (value) => dispatch(changeDealCurrentLineData({ budget: value }));

  const onChange = (value) => {
    setBudgetInput(Number(value));

    if (!isActionsDisabled) {
      dispatch(changeFormParams({ isActionsDisabled: true }));
    }
  };

  const checkBudgetChange = async () =>
    new Promise((resolve, reject) => {
      if ([DealStatus.APPROVED, DealStatus.PENDING_APPROVAL, DealStatus.REJECTED].includes(bookingStatusCode)) {
        return resolve();
      }
      if (!isAdServerMarket && budgetInput && isPendingReservation && budgetInput > backupLine?.budget) {
        return reject(new Error('Error - Cannot Increase Budget Of DV360 Deal'));
      }
      if (
        isAdServerMarket &&
        isLive &&
        (isDealTypeGuaranteed || useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET)) &&
        budgetInput < deliveredBudget &&
        !isCurrentlLineFutureStartDate
      ) {
        return reject(new Error('Cannot decrease the budget goal below the number of delivered budget'));
      }
      return resolve(dealConfig.autoCalculateFloorAndCeiling);
    });

  const onBlur = async () => {
    try {
      const isAutoCalculateFloorAndCeiling = await checkBudgetChange();
      changeBudget(budgetInput);
      dispatch(changeFormParams({ isActionsDisabled: false }));
      setErrorMessage('');

      if (isAutoCalculateFloorAndCeiling) {
        autoCalculateFloorAndCeiling(budgetInput, preservedAvailability.totalCost);
      }
    } catch (error) {
      setErrorMessage(error.toString());
    }
  };

  const getFieldInfoMessage = () => {
    return (
      errorMessage ||
      (showBudgetMessage
        ? `Your budget target of ${formatValue(budget, FormatTypeEnum.CURRENCY, currencyCode, localeCode)} is ${
            budget < totalCost ? 'less' : 'more'
          } than the total allocated cost of ${formatValue(
            totalCost,
            FormatTypeEnum.CURRENCY,
            currencyCode,
            localeCode,
          )}. Please refer to your deal summary for your booked solution`
        : '')
    );
  };

  if (
    !Auth.hasPermission(PermissionsEnum.DEAL_BUDGET_VIEW) ||
    (dealType !== DealType.GUARANTEED && !hasNonGuaranteedExtendedTargetEnabled)
  )
    return null;

  return (
    <div>
      {Auth.hasPermission(PermissionsEnum.DEAL_IMPRESSIONS_VIEW) && (
        <div className="grid grid-flow-row justify-center place-items-center mt-2 -mb-3">
          <div className="w-px h-4 bg-neutral-300" />
          <span className="body-base text-neutral-600">or</span>
          <div className="w-px h-4 bg-neutral-300" />
        </div>
      )}
      <label
        htmlFor="budget"
        className="sub-header-base text-neutral-950-opacity-60 mb-2 inline-block"
        data-test-id="budget-header"
      >
        Budget
        {isDealTypeGuaranteed ? <RequiredAsterisk /> : null}
      </label>
      <FieldWithInfoMessage
        field={
          <InputNumber
            name="budget"
            value={budgetInput?.toString() || ''}
            onChange={onChange}
            onBlur={onBlur}
            icon={getCurrency(localeCode, currencyCode)}
            isDisabled={disabled || !!impressions}
            dataTestId="budget-input"
          />
        }
        icon={
          <SVG
            src={warningSvg}
            className={errorMessage ? 'fill-current text-pinkRed-700' : 'fill-current text-yellow'}
          />
        }
        message={getFieldInfoMessage()}
      />
    </div>
  );
};

Budget.propTypes = {
  autoCalculateFloorAndCeiling: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Budget;
