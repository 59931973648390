import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';

const ActionRenderer = ({ data, onClick, icon, hasPermission }) => {
  const onButtonClick = () => onClick(data);

  return (
    <>
      {hasPermission && (
        <span className="table-action-button">
          <Button onClick={onButtonClick} btnShape={ButtonShape.CIRCLE} btnType={ButtonType.ICON}>
            <SVG src={icon} />
          </Button>
        </span>
      )}
    </>
  );
};

ActionRenderer.propTypes = {
  data: PropTypes.shape({}),
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  hasPermission: PropTypes.bool.isRequired,
};

ActionRenderer.defaultProps = {
  data: {},
  onClick: () => undefined,
};

export default ActionRenderer;
