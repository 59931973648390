import { useDispatch, useSelector } from 'react-redux';
import { useCancelRequest } from 'customHooks/useCancelRequest';

import { Store } from 'components/common/types/Store.types';
import { changeDealLineProximityPoi } from 'store/dealManagement/reducer';

import withCancelRequest from 'components/hocs/withCancelRequest';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { isReadOnly } from 'utils/isReadOnly';
import { getPOILookupData } from 'modules/api/lookups';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox/MultipleSwitchBox';
import { DealStatus, Tag } from 'components/common/types/Deal.types';
import { DISTANCES } from 'consts/distances';

import Dropdown from 'lib/Dropdown';
import BaseSelect from 'lib/BaseSelect';
import { CodeNameModel } from 'components/common/types';
import Distance from '../Distance';

const PointOfInterest: React.FC = () => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const terminated = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.terminated);
  const { attributeCode, dataSourceCode, distance, include, tags, unit } = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.proximity.poi,
  );
  const { datasources, attributes } = useSelector((state: Store) => state.dealManagement.meta.poi);

  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled, terminated) && !isPendingReservation;

  const fetchPOIs = async (_name: string, query: string): Promise<[] | Tag[]> => {
    if (query.length < 2) return [];

    try {
      return await getPOILookupData(cancelFunctions, query, attributeCode, dataSourceCode);
    } catch (error) {
      return [];
    }
  };

  return (
    <div data-test-id="point-of-interest">
      <p className="sub-header-base text-neutral-900 mb-4">Point of interest</p>
      <div className="flex items-end gap-5">
        <div className="w-52">
          <p className="sub-header-base text-neutral-950-opacity-60 mb-2">Data source</p>
          <BaseSelect
            name="dataSource"
            dataTestId="poi-dataSource"
            isDisabled={readOnly}
            placeholder="Select data source"
            selectedValue={{ code: dataSourceCode, name: dataSourceCode }}
            options={datasources}
            onSelect={(_, value: CodeNameModel) => {
              dispatch(changeDealLineProximityPoi({ dataSourceCode: value.name, attributeCode: '', tags: [] }));
            }}
          />
        </div>
        <div className="flex-1">
          <p className="sub-header-base text-neutral-950-opacity-60 mb-2">Attribute</p>
          <Dropdown
            name="attribute"
            dataTestId="poi-attribute"
            dataList={attributes}
            value={attributeCode}
            onChange={(value) => {
              dispatch(changeDealLineProximityPoi({ attributeCode: value, tags: [] }));
            }}
            textKey="name"
            valueKey="code"
            defaultOption="Select attribute"
            isDisabled={readOnly}
          />
        </div>
        <Distance
          value={distance}
          dataTestId="poi-distance"
          onChange={(value) => {
            dispatch(changeDealLineProximityPoi({ distance: value }));
          }}
          isDisabled={readOnly}
        />
        <MultipleSwitchBox
          source={Object.entries(DISTANCES).map(([name, code]) => ({ name, code }))}
          selectedValue={unit}
          dataTestId="poi-unit"
          onChange={(value) => dispatch(changeDealLineProximityPoi({ unit: value }))}
          isDisabled={readOnly}
        />
        <MultipleSwitchBox
          dataTestId="poi-include"
          source={[
            { code: 'include', name: 'Include' },
            { code: 'exclude', name: 'Exclude' },
          ]}
          selectedValue={include ? 'include' : 'exclude'}
          onChange={(value) => dispatch(changeDealLineProximityPoi({ include: value === 'include' }))}
          isDisabled={readOnly}
        />
      </div>
      <div className="w-52 mt-5">
        <p className="sub-header-base text-neutral-950-opacity-60 mb-2">Value</p>
        <AsyncSelect
          dataTestId="poi-input"
          name="poi"
          loadData={fetchPOIs}
          selectedValue={tags}
          placeholder="Add tag"
          onSelect={(_name: string, value: Tag[]) => {
            dispatch(changeDealLineProximityPoi({ tags: value }));
          }}
          multiple
          isDisabled={readOnly}
        />
      </div>
    </div>
  );
};

export default withCancelRequest(PointOfInterest);
