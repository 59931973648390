export default class Session {
  static getEnvironmentId(): string {
    return sessionStorage.getItem('environmentId') || '';
  }

  static setEnvironmentId(environmentId: string): void {
    sessionStorage.setItem('environmentId', environmentId);
  }

  static getPublisherId(): string {
    return sessionStorage.getItem('publisherId') || '';
  }

  static setPublisherId(publisherId: string): void {
    sessionStorage.setItem('publisherId', publisherId);
  }

  static getPageSizeByKey(key: string): string {
    const data = JSON.parse(sessionStorage.getItem('pageSize') || '{}');
    return data[key] || '';
  }

  static setPageSizeByKey(key: string, pageSize: number): void {
    const data = JSON.parse(sessionStorage.getItem('pageSize') || '{}');
    sessionStorage.setItem('pageSize', JSON.stringify({ ...data, [key]: pageSize }));
  }

  static getContentManagementPageSizeByKey(key: string): string {
    const data = JSON.parse(sessionStorage.getItem('contentManagementPageSize') || '{}');
    return data[key] || '';
  }

  static setContentManagementPageSizeByKey(key: string, pageSize: number): void {
    const data = JSON.parse(sessionStorage.getItem('contentManagementPageSize') || '{}');
    sessionStorage.setItem('contentManagementPageSize', JSON.stringify({ ...data, [key]: pageSize }));
  }

  static clear(): void {
    sessionStorage.removeItem('environmentId');
    sessionStorage.removeItem('contentPageSize');
    sessionStorage.removeItem('publisherId');
  }
}
