import React from 'react';

function withCancelRequest(WrappedComponent) {
  return class extends React.Component {
    cancelFunctions = {};

    componentWillUnmount() {
      Object.values(this.cancelFunctions).forEach((cancel) => {
        cancel();
      });
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent cancelFunctions={this.cancelFunctions} {...this.props} />;
    }
  };
}

export default withCancelRequest;
