import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { Link } from 'react-router-dom';
import { CreativeItemModel } from 'components/common/prop-types/content';
import styles from './ImageRenderer.pcss';

const cx = classNames.bind(styles);

export const TARGET = {
  SELF: '_self',
  BLANK: '_blank',
};

const ImageRenderer = ({ data, target, hoverIcon, link, isExternalLink }) => {
  if (!data) return '';

  const thumbnail = (
    <div
      className={cx('image-wrap', 'bg-center bg-cover bg-no-repeat')}
      style={{ backgroundImage: `url(${data.thumbnail})` }}
    >
      {Auth.hasPermission(PermissionsEnum.CONTENT_REVIEW) ? (
        <div className="h-full text-neutral-100 flex justify-center items-center opacity-0 hover:opacity-100">
          {hoverIcon}
        </div>
      ) : null}
    </div>
  );

  if (!Auth.hasPermission(PermissionsEnum.CONTENT_REVIEW)) return thumbnail;

  if (isExternalLink) {
    return (
      <a href={link} target={target} rel="noopener noreferrer" data-test-id="thumbnail-external-link">
        {thumbnail}
      </a>
    );
  }

  return (
    <Link to={link} target={target} data-test-id="thumbnail-link">
      {thumbnail}
    </Link>
  );
};

ImageRenderer.propTypes = {
  data: CreativeItemModel,
  target: PropTypes.oneOf(Object.values(TARGET)),
  hoverIcon: PropTypes.node,
  link: PropTypes.string,
  isExternalLink: PropTypes.bool,
};

ImageRenderer.defaultProps = {
  data: {},
  target: TARGET.SELF,
  hoverIcon: null,
  link: '',
  isExternalLink: false,
};

export default ImageRenderer;
