import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash';
import { Preloader } from 'components/patterns/Loader';
import usePreviousWithReset from 'customHooks/usePreviousWithReset';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { notifyError } from 'store/notification/reducer';
import Revenue from './Revenue';
import BidRate from './BidRate';
import TradedWinRate from './TradedWinRate';
import WeightedWinRate from './WeightedWinRate';
import FillRate from './FillRate';
import CollapseButton from './CollapseButton';
import { Store } from '../types/Store.types';
import { HeroChartsProps, Size } from './HeroCharts.types';

const Carousel = lazy(() => import('../Carousel'));

const HeroCharts: React.FC<HeroChartsProps> = ({
  filters,
  getQueryString,
  fetchRevenueChart,
  fetchBidRateChart,
  fetchTradedWinRateChart,
  fetchWeightedWinRateChart,
  fetchFillRateChart,
  size = Size.SMALL,
  isCarousel = false,
}) => {
  const timezone = useSelector((state: Store) => state.header.timezone);
  const cancelFunctions = useCancelRequest();
  const dispatch = useDispatch();
  const [revenueData, setRevenueData] = useState({});
  const [bidData, setBidData] = useState({});
  const [tradedWinData, setTradedWinData] = useState({});
  const [weightedWinData, setWeightedWinData] = useState({});
  const [fillRateData, setFillRateData] = useState({});

  const [previousFilters, resetPreviousFilters] = usePreviousWithReset(filters);

  useEffect(() => {
    if (!timezone.code) {
      resetPreviousFilters();
    }
  }, [timezone]);

  useEffect(() => {
    if (!timezone.code || isEqual(filters, previousFilters)) return;

    Promise.all([
      fetchRevenueChart(cancelFunctions, getQueryString(filters, timezone)),
      fetchBidRateChart(cancelFunctions, getQueryString(filters, timezone)),
      fetchTradedWinRateChart(cancelFunctions, getQueryString(filters, timezone)),
      fetchWeightedWinRateChart(cancelFunctions, getQueryString(filters, timezone)),
      fetchFillRateChart(cancelFunctions, getQueryString(filters, timezone)),
    ])
      .then((response) => {
        setRevenueData(response[0]);
        setBidData(response[1]);
        setTradedWinData(response[2]);
        setWeightedWinData(response[3]);
        setFillRateData(response[4]);
      })
      .catch((error) => {
        dispatch(notifyError({ message: error.message }));
      });
  }, [filters, timezone, previousFilters]);

  const isDealSelected = !!filters?.deal;

  return isCarousel ? (
    <>
      <Suspense
        fallback={
          <div className="relative">
            <Preloader />
          </div>
        }
      >
        <Carousel className="mt-8 mb-2">
          <Revenue revenueData={revenueData} isDealSelected={isDealSelected} />
          <BidRate bidData={bidData} isDealSelected={isDealSelected} />
          <TradedWinRate tradedWinData={tradedWinData} isDealSelected={isDealSelected} />
          <WeightedWinRate weightedWinData={weightedWinData} isDealSelected={isDealSelected} />
          <FillRate fillRateData={fillRateData} isDealSelected={isDealSelected} />
        </Carousel>
        <CollapseButton />
      </Suspense>
    </>
  ) : (
    <>
      <div className={`grid grid-cols-2 ${size} gap-4 mb-5`}>
        <Revenue revenueData={revenueData} isDealSelected={isDealSelected} />
        <BidRate bidData={bidData} isDealSelected={isDealSelected} />
        <TradedWinRate tradedWinData={tradedWinData} isDealSelected={isDealSelected} />
        <WeightedWinRate weightedWinData={weightedWinData} isDealSelected={isDealSelected} />
        <FillRate fillRateData={fillRateData} isDealSelected={isDealSelected} />
      </div>
      <CollapseButton />
    </>
  );
};

export default HeroCharts;
