import SingleContent from 'components/common/layout/SingleContent';
import AdministrationHeader from 'components/pages/Administration/AdministrationHeader';
import AdministrationContent from 'components/pages/Administration/AdministrationContent';
import { PageWrapper } from 'components/common/layout/PageWrapper/PageWrapper';

const Administration = () => (
  <PageWrapper>
    <AdministrationHeader />
    <SingleContent content={<AdministrationContent />} />
  </PageWrapper>
);

export default Administration;
